class PlanBuilderAPI {

  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listPrograms(filterPublic = true) {
    if (filterPublic) {
      return this.api.get(`${this.version}/programs/?template=true`);
    }

    return this.api.get(`${this.version}/programs/`);
  }

  getActiveProgram() {
    return this.api.get(`${this.version}/programs/active_program/`);
  }

  getActiveProgramTree() {
    return this.api.get(`${this.version}/programs/active_program_tree/`);
  }

  createProgram(payload) {
    return this.api.post(`${this.version}/programs/`, payload);
  }

  createPlan(payload) {
    return this.api.post(`${this.version}/plans/`, payload);
  }

  getActivePlan() {
    return this.api.get(`${this.version}/plans/get_active_plan/`);
  }

  //getActiveProgram() {
  //  return this.api.get(`${this.version}/programs/get_active_program/`);
  //}

  retrievePlan(pk) {
    return this.api.get(`${this.version}/plans/${pk}/`);
  }

  deletePlan(pk) {
    return this.api.delete(`${this.version}/plans/${pk}/`);
  }

  retrieveProgram(pk) {
    return this.api.get(`${this.version}/programs/${pk}/`);
  }

  deleteProgram(pk) {
    return this.api.delete(`${this.version}/programs/${pk}/`);
  }

  listNodes(program_pk = null) {
    if (program_pk) {
      return this.api.get(`${this.version}/nodes/?program=${program_pk}`);
    }

    return this.api.get(`${this.version}/nodes/?program=${program_pk}`);
  }

  deleteNode(node) {
    return this.api.delete(`${this.version}/nodes/${node.id}/`);
  }

  retrieveNode(pk) {
    return this.api.get(`${this.version}/nodes/${pk}/`);
  }

  updateNodes(nodes, program_pk) {
    if (program_pk == null) {
      return;
    }

    return this.api.put(
      `${this.version}/nodes/update_list/?program=${program_pk}`, nodes);
  }
  
  getProgramStandards(program) {
    return this.api.get(`${this.version}/program_standards/?program=${program}`)
  }

  listStandards(group) {
    return this.api.get(`${this.version}/program_standards/?header=${group.id}`);
  }

  createStandard(standard) {
    return this.api.post(`${this.version}/program_standards/`, standard);
  }

  retrieveStandard(pk) {
    return this.api.get(`${this.version}/program_standards/${pk}/`);
  }

  updateStandard(standard) {
    return this.api.put(`${this.version}/program_standards/${standard.pk}/`, standard);
  }

  deleteStandard(pk) {
    return this.api.delete(`${this.version}/program_standards/${pk}/`);
  }

  listPlans() {
    return this.api.get(`${this.version}/plans/`);
  }
}


export default PlanBuilderAPI;


