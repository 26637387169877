import React, { useEffect, useState } from "react"

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

import API from "../../Api/Api";
import { DataGrid } from '@material-ui/data-grid';

import CancelIcon from '@material-ui/icons/Cancel';

export default function AddTasksDialog(props) {
  const [availableTasks, setAvailableTasks] = useState([]);
  const [tasksSelected, setTasksSelected] = useState([]);

  const api = new API();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.open) {
      api.getTaskAPI().getAvailableTasks().then(response => {
        setAvailableTasks(response.data);
        setLoading(false);
      }).catch(error => {
        console.log(error);
      });
    }
  }, [props.open]);

  function addTasksToEvent() {
    // get all tasks that have the pks and set them using props, and then get the event again 
    if (props.addTasks) {
      props.addTasks(tasksSelected);
    }
  }

  return (
    <Dialog open={props.open} maxWidth="md" fullWidth>
      <DialogTitle style={{ borderBottom: '1px solid #eaeaea', padding: '16px' }}>
        <Grid container alignItems="center">
          <Grid item xs={8}>
            Add Tasks
          </Grid>
          <Grid item container xs={4} justify="flex-end">
            <IconButton onClick={() => { props.setOpen(false) }}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {loading && <Grid item container xs={12} justify="center" alignItems="center"><CircularProgress /></Grid>}

        {!loading && !availableTasks.length &&
          <DialogContentText style={{ padding: '16px' }}>
            No available tasks were found. A task can only be linked to one event at a time.
        </DialogContentText>
        }

        {!loading && availableTasks.length > 0 &&
          <DialogContentText style={{ padding: '16px' }}>
            Select available tasks from the list below to add them to the event.
        </DialogContentText>
        }

        <Grid container item xs={12} style={{ height: '500px' }}>
          {availableTasks.length > 0 &&
            <DataGrid
              checkboxSelection
              disableColumnMenu
              rows={[...availableTasks]}
              columns={[{ field: 'name', headerName: "Task", flex: 1 }]}
              onSelectionModelChange={(newSelection) => {
                setTasksSelected(newSelection.selectionModel);
              }}
            />
          }
        </Grid>
      </DialogContent>
      <DialogActions style={{ borderTop: '1px solid #eaeaea' }}>
        <Grid container item xs={12} alignItems="center" justify="flex-end" >
          <Button variant="outlined" color="secondary" style={{ margin: '4px' }} onClick={() => { props.setOpen(false) }}>
            Cancel
          </Button>
          <Button
            disabled={!tasksSelected.length}
            variant="contained" color="primary" style={{ margin: '4px' }}
            onClick={() => { props.setOpen(false); props.addTasks(tasksSelected) }}
          >
            Add Tasks
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
