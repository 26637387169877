//Todo I believe this can be scrapped, BE uses batches now.
class ProductionSheetAPI {
  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listProductionSheets(production_sheet_pk) {
    if (typeof production_sheet_pk === 'number') {
      return this.api.get(`${this.version}/production_sheets/${production_sheet_pk}/`);
    }

    return this.api.get(`${this.version}/production_sheets/`)
  }

  //'Active' in a production sheet sense just means that it doesn't have a batch sheet yet.
  listActiveProductionSheets() {

    return this.api.get(`${this.version}/production_sheets_to_edit/list_active`)
  }

  createProductionSheet(payload) {
    return this.api.post(`${this.version}/production_sheets/`, payload);
  }

  updateProductionSheet(payload) {
    return this.api.put(`${this.version}/production_sheets/${payload.pk}/`, payload);
  }

  /**
   * Sets the status of the production sheet following the pattern Draft -> Scheduled -> Ongoing -> On hold -> Complete
   * @param {*} pk - The primary key of the order to update.
   */
  updateProductionSheetStatus(pk) {
    return this.api.get(`${this.version}/production_sheets/${pk}/update_status/`);
  }

  deleteProductionSheet(pk) {
    return this.api.delete(`${this.version}/production_sheets/${pk}/`);
  }

  listProductionSheetsToEdit(production_sheet_pk) {
    if (typeof production_sheet_pk === 'number') {
      return this.api.get(`${this.version}/production_sheets_to_edit/${production_sheet_pk}/`);
    }

    return this.api.get(`${this.version}/production_sheets_to_edit/`)
  }

}

export default ProductionSheetAPI;