import React, { useEffect, useState } from 'react'


import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const fontStyle = {
  fontFamily: 'Roboto', 
  fontStyle: 'normal', 
  fontWeight: 500, 
  fontSize: '16px', 
  lineHeight: '21px'
};

export default function Section(props) {

  function onNodeTitleChange(event) {
    setTitle(event.target.value);
    props.setNodeData(props.node.treeID, "title", event.target.value);
  }

  useEffect(() => {
    setTitle(props.node.title);
  }, [props.node]);


  const [title, setTitle] = useState(props.node.title);

  function deleteSection(event) {
    props.deleteSection(props.node);
  }

  return (
    <Grid item xs={12}>
      <Paper elevation={0} style={{margin: '6px 0px 6px 0px'}}>
        <Grid container style={{ padding: '16px', }}>
          <Grid item xs={9}>
            {!props.readOnly &&
              <TextField
                label="Section"
                value={title}
                onChange={onNodeTitleChange}
                disabled={false}
                fullWidth
              />
            }
            {props.readOnly &&
              <Typography variant="h5" style={fontStyle}>
                {title || "Section"}
              </Typography>
            }
          </Grid>

          {!props.readOnly &&
            <Grid item xs={3} container justify='flex-end'>
              <IconButton aria-label="delete" onClick={deleteSection} style={{ marginRight: '3px' }}>
                <CloseIcon />
              </IconButton>
            </Grid>
          }
        </Grid>
      </Paper>
    </Grid>
  )
}