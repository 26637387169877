class SkuAPI {
  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listSkus(sku_pk) {
    if (typeof sku_pk === 'number') {
      return this.api.get(`${this.version}/skus/${sku_pk}/`);
    }

    return this.api.get(`${this.version}/skus/`)
  }

  listSkusDisplay(sku_pk) {
    if (typeof sku_pk === 'number') {
      return this.api.get(`${this.version}/skus_display/${sku_pk}/`);
    }

    return this.api.get(`${this.version}/skus_display/`)
  }

  listSkusWithInternalLotCodes() {
    return this.api.get(`${this.version}/skus/list_skus_with_internal_lot_code/`)
  }

  listRawSkus() {
    return this.api.get(`${this.version}/skus/list_raw_skus/`)
  }

  listFinishedSkus() {
    return this.api.get(`${this.version}/skus/list_finished_skus/`)
  }

  listFormulaSkus() {
    return this.api.get(`${this.version}/skus/list_formula_skus/`)
  }

  listFormulaSkusDetailedFormula() {
    return this.api.get(`${this.version}/skus/list_formula_skus_detailed_formula/`)
  }

  createSku(payload) {
    return this.api.post(`${this.version}/skus/`, payload);
  }

  updateSku(payload) {
    return this.api.put(`${this.version}/skus/${payload.pk}/`, payload);
  }

  softDeleteSku(payload) {
    return this.api.put(`${this.version}/skus/${payload.pk}/soft_delete_action/`, payload);
  }

  deleteSku(pk) {
    return this.api.delete(`${this.version}/skus/${pk}/`);
  }
}

export default SkuAPI;