import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/Button"
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import AvatarComponent from "../../../Components/AvatarComponent";
import AuditAPI from "./api/AuditAPI";
import { saveAs } from 'file-saver';
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

export default function AuditCard({ audit, setAuditSelected, removeAudit }) {

	const history = useHistory();
	const [alertOpen, setAlertOpen] = useState({ message: "", open: false });
	const [errorOpen, setErrorOpen] = useState({ message: "", open: false });
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [hideDownload, setHideDownload] = React.useState(false);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDownload = () => {
		// setAnchorEl(null);
		getJobResponse(audit.jobId)
	};

	function getJobResponse(jobId) {
		if (!jobId) {
			throw new Error("Please provide a job id.");
		}

		// if (!intervalId) {
		// 	throw new Error("Please provide an interval id.");
		// }

		new AuditAPI().getJobStatus(jobId).then(response => {
			if (response.data === 'finished') {
				new AuditAPI().getJobResult(jobId).then(response => {
					let blob = new Blob([response.data], { type: "application/zip" });
					saveAs(blob, "audit.zip");
					localStorage.removeItem("audit_download_job_" + audit.id);
					audit.jobId = undefined;
					setHideDownload(true)

					// clearInterval(intervalId);
					// setBackdropOpen(false);
				}).catch(error => {
					localStorage.removeItem("audit_download_job_" + audit.id);
					audit.jobId = undefined;
					setHideDownload(true)
					setErrorOpen({ message: `An error has occurred (500)`, open: true });
					// clearInterval(intervalId);
					// setBackdropOpen(false);
				});
			} else if (response.data === 'queued' || response.data === 'started') {
				setAlertOpen({ message: `The archive is being prepared. Please wait...`, open: true });
			} else if (response.data === 'failed') {
				localStorage.removeItem("audit_download_job_" + audit.id);
				audit.jobId = undefined;
				setHideDownload(true)
				setAlertOpen({ message: `Something went wrong. Please request the export again.`, open: true });
			}

		}).catch(error => {
			localStorage.removeItem("audit_download_job_" + audit.id);
			audit.jobId = undefined;
			setHideDownload(true)
			// setBackdropOpen(false);
			setErrorOpen({ message: `An error has occurred (500)`, open: true });
			// clearInterval(intervalId);
		})
	}

	function deleteAudit() {
		removeAudit(audit);
		handleClose();
	}

	return (
		<Grid item container xs={12} justify='center'>
			<Paper elevation={0} style={{ width: '100%', minHeight: '200px', maxHeight: '200px', maxWidth: '1500px', margin: '16px 0px' }}>
				<Grid className="audit_header" item container xs={12} alignItems="center" justify="flex-start" style={{ padding: '16px', borderBottom: '1px solid lightgrey' }}>
					<Grid item container xs={8} alignItems="center">

						<Snackbar
							open={alertOpen.open}
							autoHideDuration={6000}
							onClose={() => { setAlertOpen({ message: "", open: false }) }}
							anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						>
							<Alert severity="info" onClose={() => { setAlertOpen({ message: "", open: false }); }}>
								{alertOpen.message}
							</Alert>
						</Snackbar>
						<Snackbar
							open={errorOpen.open}
							autoHideDuration={6000}
							onClose={() => { setErrorOpen({ message: "", open: false }) }}
							anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						>
							<Alert severity="error" onClose={() => { setErrorOpen({ message: "", open: false }); }}>
								{errorOpen.message}
							</Alert>
						</Snackbar>


						<AvatarComponent />
						<Typography style={{ fontWeight: 500, fontSize: '14px' }}>
							{audit.title}
						</Typography>
						<Typography style={{ fontSize: '12px', opacity: 0.5, marginLeft: '8px' }}>
							{audit.last_updated}
						</Typography>
					</Grid>
					<Grid item container xs={4} justify="flex-end">
						{audit.jobId && !hideDownload ? <IconButton onClick={handleDownload} color="default">
							<GetAppRoundedIcon />
						</IconButton> : ''}
						<Button color="primary" onClick={() => { history.push(`audit/${audit.id}/edit/`) }}>Edit Audit</Button>
						<IconButton onClick={handleClick} color="default">
							<MoreHorizIcon />
						</IconButton>
					</Grid>
				</Grid>
				<Grid className="audit_body" item container xs={12} style={{ padding: '16px', height: '100%' }}>
					<Typography style={{ fontSize: '12px' }}>
						{audit.description}
					</Typography>
				</Grid>
			</Paper>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem onClick={deleteAudit}>Delete</MenuItem>
			</Menu>
		</Grid>
	)
}