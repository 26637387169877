import axios from "axios";


class ProcessFlowDiagramAPI {

  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listProcessFlowDiagrams(haccp_plan_pk = null) {
    if (typeof haccp_plan_pk === 'number') { // Get process_flow_diagram in formulation
      return this.api.get(`${this.version}/process_flow_diagrams/?haccp_plan_pk=${haccp_plan_pk}`)
    }

    return this.api.get(`${this.version}/process_flow_diagrams/`)
  }

  getCCPs(pk) {
    return this.api.get(`${this.version}/process_flow_diagrams/${pk}/get_ccps/`)
  }

  getProcesses(pk) {
    return this.api.get(`${this.version}/process_flow_diagrams/${pk}/get_processes/`)
  }

  createProcessFlowDiagram(payload) {
    return this.api.post(`${this.version}/process_flow_diagrams/`, payload, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  retrieveProcessFlowDiagram(pk) {
    return this.api.get(`${this.version}/process_flow_diagrams/${pk}/`);
  }

  updateProcessFlowDiagram(payload) {
    return this.api.put(`${this.version}/process_flow_diagrams/${payload.pk}/`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  updateProcessFlowDiagramImage(pk, formData) {
    return this.api.put(`${this.version}/process_flow_diagrams/${pk}/`, formData);
  }

  deleteProcessFlowDiagram(pk) {
    return this.api.delete(`${this.version}/process_flow_diagrams/${pk}/`);
  }

  selectTemplate(pk, template) {
    return this.api.post(`${this.version}/process_flow_diagrams/${pk}/select_template/`, template);
  }

  getHistory(pk) {
    return this.api.get(`${this.version}/process_flow_diagrams/${pk}/get_history/`);
  }

  getRevertedProcessFlowDiagram(pk, date) {
    return this.api.post(`${this.version}/process_flow_diagrams/${pk}/get_reverted_process_flow_diagram/`, date);
  }
}


export default ProcessFlowDiagramAPI;
