import React, { useEffect, useState } from 'react';
import Paper from "@material-ui/core/Paper";
import DetailRow from "./DetailRow";
import DetailHeader from "./DetailHeader";
import { StylesContext } from "../../App";

import PropTypes from "prop-types";

export default function DetailTable(props) {

  const classes = React.useContext(StylesContext);
  const [updatedTableData, setUpdatedTableData] = useState(props.tableData);

  useEffect(() => {
    setUpdatedTableData(props.tableData);
  }, [props.tableData]);

  //Return a closure.
  function returnFunction(i) {
    return function (newData) {
      let newTableData = [...updatedTableData];
      newTableData[i] = newData;

      setUpdatedTableData(newTableData);
    }
  }

  //Create an array of functions to set the data in each row, to be passed to each row.
  function getRowDataFunctions() {
    var funcArray = [];

    let length = updatedTableData ? updatedTableData.length : 0

    //Using a for loop here for clarity and to be able to use "let" for the iterator.
    for (let i = 0; i < length; i++) {
      funcArray.push(returnFunction(i));
    }

    return funcArray;
  }

  const handleRowUpdate = (updatedRowData) => {
    // console.log("handleRowUpdate >> updatedRowData >> ",updatedRowData);
    const newUpdatedTableData = updatedTableData.map((row) => {
      // console.log("handleRowUpdate >> row >> ",row);
      return row.name === updatedRowData.name ? updatedRowData : row
    }
    );
    // console.log("updatedTableData >> ",newUpdatedTableData);
    setUpdatedTableData(newUpdatedTableData);
  };

  //Create each row of the table.
  function makeHeader() {
    return (
      <DetailHeader
        key={0}
        colInfo={props.colInfo}
        detailInfo={props.detailInfo}
        disabled={props.disabled}
        faded={props.faded}
      />
    )
  }

  //Create each row of the table.
  function makeRows() {
    let funcArray = getRowDataFunctions();
    return updatedTableData.map((rowData, i) => {
      if (!rowData) {
        return (<Paper />);
      }
      return (
        <DetailRow
          key={i}
          rowData={rowData}
          manOrderData={props.manOrderData}
          setManOrderData={props.setManOrderData}
          setRowData={funcArray[i]}
          setLineData={props.setLineData}
          colInfo={props.colInfo}
          detailInfo={props.detailInfo}
          onDetailClick={props.onDetailClick}
          disabled={props.disabled}
          faded={props.faded}
          units={props.units}
          onRowUpdate={handleRowUpdate}
        />
      )
    })
  }

  return (
    <>
      {props.colInfo &&
        <Paper elevation={0} className={classes.detailFormContainer} style={{ backgroundColor: props.faded ? 'grey' : '#FFF' }}>
          {makeHeader()}
          {makeRows()}
        </Paper>
      }
    </>
  )
}

DetailTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object),
  setRowData: PropTypes.func,
  setLineData: PropTypes.func,
  // setData: PropTypes.func,
  colInfo: PropTypes.object,
  units: PropTypes.objectOf(PropTypes.object),
  detailInfo: PropTypes.object,
  onDetailClick: PropTypes.func,
  disabled: PropTypes.bool,
  faded: PropTypes.bool,
  onRowUpdate: PropTypes.func,
}