import React from "react";
import { useRouteMatch, Route, Switch } from "react-router-dom";

import MonitoringRecords from "./MonitoringRecords";
import VerificationRecords from "./VerificationRecords";
import DeviationRecords from "./DeviationRecords";

export default function Records(props) {
    const match = useRouteMatch();
    return (
        <>
            <Switch>
                <Route path={`/records/monitoring_records/`}>
                    <MonitoringRecords user={props.user} recordType={"monitoring"} selectMode />
                </Route>
            </Switch>
            {/* <Switch>
                <Route path={`/records/verification_records/`}>
                    <VerificationRecords user={props.user} recordType={"verification"} selectMode />
                </Route>
            </Switch> */}
            <Switch>
                <Route path={`/records/deviation_records/`}>
                    <DeviationRecords user={props.user} recordType={"deviation"} selectMode />
                </Route>
            </Switch>
        </>
    );
}
