import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import API from "../../Api/Api";
import VerticalDotMenu from "../../Components/VerticalDotMenu";
import Message from "../../Components/Message";
import Divider from "@material-ui/core/Divider";
import { BackButton } from "../../Components/BackButton";

// Edit crossContaminationPoint
function CrossContaminationPoint(props) {
  const [crossContaminationPoint, setCrossContaminationPoint] = useState(null);
  let [shouldClose, setShouldClose] = useState(false);
  const api = new API();
  let params = useParams();
  let history = useHistory();

  function getCrossContaminationPointAPI() {
    if (props.templateMode) {
      return api.getCrossContaminationPointTemplateAPI();
    }

    return api.getCrossContaminationPointAPI();
  }

  useEffect(() => {
    let pk = props.pk || params.pk;

    getCrossContaminationPoint(pk);
  }, []);

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState({
    message: "",
    status: "info",
  }); // error, warning, info, info
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function setReturnStatus(message, status) {
    setMessage({ message: message, status: status });
    setOpen(true);
  }

  function getCrossContaminationPoint(pk) {
    getCrossContaminationPointAPI()
      .retrieveCrossContaminationPoint(pk)
      .then((e) => {
        setCrossContaminationPoint(e.data);
      })
      .catch((e) => {
        setReturnStatus("Could not contact server.", "error");
      });
  }

  function handleChange(event) {
    setShouldClose(true);
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }
    const value = event.target.value;
    setCrossContaminationPoint({
      ...crossContaminationPoint,
      [event.target.name]: value,
    });
  }

  function handleSubmit(e) {
    setShouldClose(false);
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }
    if (props.save) {
      props.save(crossContaminationPoint);
      updateCrossContaminationPoint(crossContaminationPoint);
    } else {
      e.preventDefault();
      updateCrossContaminationPoint(crossContaminationPoint);
    }
  }

  function saveAndContinue(e) {
    setShouldClose(false);
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }
    e.preventDefault();
    if (props.saveAndContinue) {
      props.saveAndContinue(crossContaminationPoint);
    } else {
      updateCrossContaminationPoint(crossContaminationPoint);
      history.goBack();
    }
  }

  function updateCrossContaminationPoint(crossContaminationPoint) {
    getCrossContaminationPointAPI()
      .updateCrossContaminationPoint(crossContaminationPoint)
      .then((e) => {
        setCrossContaminationPoint(e.data);
        setReturnStatus("Cross Contamination Point updated!", "info");
      })
      .catch((e) => {
        setReturnStatus("Could not update crossContaminationPoint.", "error");
      });
  }

  function createTemplate() {
    api
      .getCrossContaminationPointTemplateAPI()
      .createCrossContaminationPoint(crossContaminationPoint)
      .then((e) => {
        setReturnStatus("Template created!", "info");
      })
      .catch((e) => {
        setReturnStatus("Could not create template.", "error");
      });
  }

  function getTemplates() {
    return api
      .getCrossContaminationPointTemplateAPI()
      .listCrossContaminationPoints();
  }

  function selectTemplate(template) {
    api
      .getCrossContaminationPointAPI()
      .selectTemplate(crossContaminationPoint.pk, template)
      .then((e) => {
        setReturnStatus("Template selected!", "info");
        setCrossContaminationPoint(e.data);
      })
      .catch((e) => {
        setReturnStatus("Could not select template.", "error");
      });
  }

  window.onbeforeunload = function (e) {
    if (shouldClose) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  function cancel() {
    if (props.setOpen) {
      props.setOpen(false);
    }
  }
  return (
    <div>
      {/* <Prompt
        when={shouldClose}
        message="You have unsaved changes, are you sure you want to leave?"
      /> */}
      {crossContaminationPoint && (
        <form onSubmit={handleSubmit} style={{ padding: "16px" }}>
          <Grid container style={{ padding: "16px" }} spacing={3}>
            {!props.hasButton && <BackButton />}
            <Grid item xs={10}>
              <Typography variant="h5" noWrap>
                Cross Contamination Point {props.templateMode && "Template"}
              </Typography>
              Edit/view this Cross Contamination Point.
            </Grid>
            {!props.templateMode && (
              <Grid item xs={1}>
                <VerticalDotMenu
                  getTemplates={getTemplates}
                  createTemplate={createTemplate}
                  selectTemplate={selectTemplate}
                  setCCP={setCrossContaminationPoint}
                  save={props.save}
                  setStatus={setReturnStatus}
                  api={api.getCrossContaminationPointAPI()}
                  instance={crossContaminationPoint}
                  template_title="Cross Contamination Point Templates."
                  template_cols={[
                    { title: "Name", field: "name" },
                    {
                      title: "Description",
                      field: "description",
                    },
                    {
                      title: "Last Modified",
                      field: "last_modified",
                      editable: "never",
                    },
                  ]}
                />
              </Grid>
            )}
            <Grid item xs>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Details</Typography>
              <Typography>
                Enter information about this Cross Contamination Point.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Name"
                name="name"
                variant="outlined"
                value={crossContaminationPoint.name}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                name="description"
                variant="outlined"
                value={crossContaminationPoint.description}
                multiline
                rows={3}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <div style={{ float: "right" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "8px" }}
                  onClick={handleSubmit}
                >
                  Save Changes
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={saveAndContinue}
                >
                  Save and Continue
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ margin: "8px" }}
                  onClick={cancel}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      )}
      <Message
        open={open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="right"
        handleClose={handleClose}
      />
    </div>
  );
}

export default CrossContaminationPoint;
