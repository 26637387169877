import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TraceabilityUtils from "../TraceabilityUtils";
import {StylesContext} from "../../App";


export default function RecallDistributionList(props) {
  const utils = new TraceabilityUtils();

  function makeFulfillmentLines(fulfillmentRecords) {
    return fulfillmentRecords.map((fulfillmentRecord, i) => {
      return(
        <TableRow key={i}>
          <TableCell>{fulfillmentRecord.sku} </TableCell>
          <TableCell>{fulfillmentRecord.sku_id} </TableCell>
          <TableCell>{fulfillmentRecord.internal_lot_code_code} </TableCell>
          <TableCell>{fulfillmentRecord.unit_value + ' ' + props.units[fulfillmentRecord.unit_type].abbreviation} </TableCell>
          <TableCell>{utils.dateObjToString(fulfillmentRecord.shipping_date)} </TableCell>
        </TableRow>
      )
    })
  }

  function makeFulfillmentRecordTables(fulfillmentRecords, shippingRecord) {
    return(<>
      <Grid item container justify='center' alignItems='center' xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{'Shipment ' +  shippingRecord.shipping_id} </TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell>{'Shipment Date ' + shippingRecord.expected_delivery} </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell>SKU ID </TableCell>
                <TableCell>SKU Name </TableCell>
                <TableCell>Lot Code </TableCell>
                <TableCell>QTY Shipped </TableCell>
                <TableCell>Date Shipped </TableCell>
              </TableRow>
              {makeFulfillmentLines(fulfillmentRecords)}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>)
  }

  function makeDistributionTables() {
    //This object comes in with a "count" key, will always be min size of one.
    if(Object.keys(props.distributions).length === 1) {
      return (
        <Grid item container key={1} justify='center' alignItems='center' xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No Affected Distributions</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Grid>
      )
    }

    return Object.keys(props.distributions).map((distributionKey, i) => {
      let shippingRecord = props.distributions[distributionKey].shipping_record;
      let affectedFulfillmentRecords = props.distributions[distributionKey].fulfillment_records;

      if(!shippingRecord) {
        return;
      }

      return(<> 
        <Grid item xs={12}>
          <Divider/>
        </Grid>

        <Grid item container key={i} justify='center' alignItems='flex-start' xs={6}>
          <Paper style={{width: '100%'}}>
            <Table style={{margin: '0px auto 8px'}}>
              <TableBody>
                <TableRow>
                  <TableCell> Customer Name </TableCell>
                  <TableCell> {shippingRecord.customer.company} </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell> Customer Address </TableCell>
                  <TableCell> {shippingRecord.customer.address} </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell> Customer ID </TableCell>
                  <TableCell> {shippingRecord.customer.customer_id} </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item container key={i} justify='center' alignItems='flex-start' xs={6}>
          <Paper style={{width: '100%'}}>
            <Table style={{margin: '0px auto 8px'}}>
              <TableBody>
                <TableRow>
                  <TableCell> Contact Name </TableCell>
                  <TableCell> {shippingRecord.customer.contact_first_name + ' ' + shippingRecord.customer.contact_last_name} </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell> Contact Phone # </TableCell>
                  <TableCell> {shippingRecord.customer.phone_number} </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell> Contact Email </TableCell>
                  <TableCell> {shippingRecord.customer.email} </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>

        { makeFulfillmentRecordTables(affectedFulfillmentRecords, shippingRecord) }
      </>)
    })
  }

  return(
    <Grid container spacing={3}>
      <Grid item container xs={12} alignItems="left" justify="flex-start">
        <Typography variant='h6'>
          Distribution List
        </Typography>
      </Grid>
      <Grid item container xs={12} alignItems="left" justify="flex-start">
        <Typography>
          A list of all shipments involving the recalled lot codes, broken down by supplier and shipment.
        </Typography>
      </Grid>
      {makeDistributionTables()}
    </Grid>
  )
}

RecallDistributionList.propTypes = {
  distributions: PropTypes.objectOf(PropTypes.object),
  skus: PropTypes.objectOf(PropTypes.object),
  units: PropTypes.objectOf(PropTypes.object),
  disabled: PropTypes.bool,
  setShouldClose: PropTypes.func,
  closeModal: PropTypes.func,
}