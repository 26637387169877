import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import API from "../../Api/Api";
import Divider from "@material-ui/core/Divider";
import { DialogContent, DialogActions, IconButton } from "@material-ui/core";

import Message from "../../Components/Message";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import { OrgContext } from "hooks/useOrganization"
import PropTypes from "prop-types";

HaccpPlanDetailsModal.propTypes = {
  setShouldClose: PropTypes.func,
  setIsBlocking: PropTypes.func,
  save: PropTypes.func,
  cancel: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};


function HaccpPlanDetailsModal(props) {
  const [haccpPlanNameError, setHaccpPlanNameError] = useState(false);
  const [haccpDetails, setHaccpDetails] = useState({name: "", description:""});
  const [isBlocking, setIsBlocking] = useState(false);
  const org = useContext(OrgContext);

  function handleChange(event) {
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }
    if(props.setIsBlocking){
        props.setIsBlocking(true);
    }
    setIsBlocking(true);
    const value = event.target.value;
    if(event.target.name === "name"){
        setHaccpPlanNameError(false);
    }
    setHaccpDetails({ ...haccpDetails, [event.target.name]: value });
  }

  async function save(e) {
    setIsBlocking(false);
    if (props.setIsBlocking) {
      props.setIsBlocking(false);
    }
    if (props.setShouldClose) {
      props.setShouldClose(true);
    }
    if (props.save) {
        if(haccpDetails.name != ""){
        props.save(haccpDetails.name, haccpDetails.description);
        }else{
            setHaccpPlanNameError(true);
        }
    }
  }

  window.onbeforeunload = function (e) {
    if (isBlocking) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  function cancel() {
    if (props.setShouldClose) {
      props.setShouldClose(true);
    }
    setIsBlocking(false);
    if (props.setIsBlocking) {
      props.setIsBlocking(false);
    }
    if (props.cancel) {
      props.cancel();
    }
  }

  useEffect(() => {
      if(!props.open){
          setHaccpDetails({name: "", description:""});
          setHaccpPlanNameError(false);
      }
  }, [props.open])

  const [loading, setLoading] = useState(false);

  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose} maxWidth={'md'}>
        <DialogContent>
        {!loading && (
          <form onSubmit={save}>
            <Grid
              container
              spacing={3}
              style={{ width: "100%", margin: 0}}
            >
              <Grid item xs={11}>
                <Typography variant="h5" noWrap>
                  Create HACCP Plan
                </Typography>
                Create a new HACCP Plan.
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="HACCP Plan Name"
                  error={haccpPlanNameError}
                  name="name"
                  variant="outlined"
                  value={haccpDetails ? haccpDetails.name : ""}
                  onChange={handleChange}
                  fullWidth
                />
                {haccpPlanNameError &&
                <Typography variant="body2" style={{color:"red", marginLeft:3, padding: 2}}>Please Enter A Name</Typography>
                } 
              </Grid>
           
              <Grid item xs={12}>
                <TextField
                  label="HACCP Plan Description"
                  name="description"
                  variant="outlined"
                  value={haccpDetails ? haccpDetails.description : ""}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
          </form>
        )}
        
        {loading &&
          <Grid container direction='row' justify='center' alignItems='center'>
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          </Grid>
        }
        </DialogContent>
        
        <Divider/>

        {!loading && (  
        <DialogActions style={{justifyContent: 'flex-end', paddingRight: '24px'}}>
               <Button variant='contained' color='secondary' style={{ margin: '8px' }} onClick={cancel}>
              Cancel
            </Button>
             <Button
              variant="contained"
              color="primary"
              style={{ margin: "8px" }}
              onClick={save}
            >
              Create
            </Button>
       
        </DialogActions>
        )}
        </Dialog>
    </div>
  );
}

export default HaccpPlanDetailsModal;
