import React, { useEffect, useState } from "react";

import TabbableGroupedTable from "../../Components/GroupedTable/TabbableGroupTable";
import API from "../../Api/Api";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Traceability from "../Traceability";
import TraceabilityUtils from "../TraceabilityUtils";
import Message from "../../Components/Message";
import ManOrderStatusManager from "./ManOrderStatusManager";
import Slide from '@material-ui/core/Slide';
import PropTypes from "prop-types";
import { StylesContext } from "../../App";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EMPTY_MESSAGE = {
  open: false,
  message: '',
  status: 'info',
};

export default function ManOrderList(props) {
  const utils = new TraceabilityUtils();
  const classes = React.useContext(StylesContext);

  const tabStatuses = {
    counted: false,
    column: 'status',
    tabs: [
      { title: 'All', value: 'All' },
      { title: 'DRAFT', value: 'Draft' },
      { title: 'Ongoing', value: 'Ongoing' },
      { title: 'Complete', value: 'Complete' },
    ]
  }

  // const [formulaSkus, setFormulaSkus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productionModalOpen, setProductionModalOpen] = useState(false);
  const [batchModalOpen, setBatchModalOpen] = useState(false);
  const [manOrderStatusManagerOpen, setManOrderStatusManagerOpen] = useState(false);
  const [shouldClose, setShouldClose] = useState(true);
  const [message, setMessage] = useState({ ...EMPTY_MESSAGE });
  const [user, setUser] = useState(null);
  const [units, setUnits] = useState([]);
  const [formulations, setFormulations] = useState([]);
  const [production, setProduction] = useState([]);
  const [formualtionSelected, setFormulationSelected] = useState(-1);
  const [manOrderSelected, setManOrderSelected] = useState({});
  const [batchSheets, setBatchSheets] = useState(false);
  const [dummyKey, setDummyKey] = useState(0);
  const [productionLotCodeDict, setProductionLotCodeDict] = useState({});

  const createProductionDict = (productionData) => {
    let dict = {};
    productionData.forEach(production => {
      dict[production.id] = production.prod_lot_code;
    });
    setProductionLotCodeDict(dict);
  };

  useEffect(() => {
    const unitApi = new Traceability().getUnitsAPI();
    const authApi = new API().getAuthAPI();
    const formulaApi = new Traceability().getFFormulationsAPI();
    const fproductionsApi = new Traceability().getFProductionsAPI();

    if (props.user) {
      setUser(props.user)
    }
    else {
      authApi.getAuthedProfile().then(e => {
        setUser(e.data);
      }).catch(e => {
        //console.log(e);
      })
    }

    if (props.production) {
      setProduction(props.production)
    }
    else {
      fproductionsApi.listFProduction().then(e => {
        setProduction(e.data);
        createProductionDict(e.data);
      }).catch(e => {
        //console.log(e);
      })
      // utils.idIndexObjectsFromApi(fproductionsApi.listFProduction.bind(fproductionsApi), setProduction);
    }

    if (props.formulations) {
      setFormulations(props.formulations)
    }
    else {
      // utils.idIndexObjectsFromApi(formulaApi.listFFormulations.bind(formulaApi), setFormulations);
      formulaApi.listFFormulations().then(e => {
        const filteredData = e.data.filter(item => !item.soft_delete);
        setFormulations(filteredData);
      }).catch(e => {
        console.log(e);
      })
    }

    if (props.units) {
      setUnits(props.units)
    }
    else {
      utils.pkIndexObjectsFromApi(unitApi.listUnits.bind(unitApi), setUnits);
    }

    setLoading(false);
  }, []);

  const productionColumns = [
    { title: "Manufacturing Order ID", field: "prod_lot_code" },
    { title: "Formulation", field: "formulation.name" },
    { title: "Status", field: "status" }
  ];


  function cancel() {
    setShouldClose(true);
    setProductionModalOpen(false);
    setBatchModalOpen(false);
    setManOrderStatusManagerOpen(false);
  }

  /**
   * Handles keeping the list up to date with the back end, includingupdating all skus.
   * 
   * @param {JSON} response - a response object from the server
   */
  function onSave(response) {
    // console.log("onsave >> response >> ", response);
    let index = production.findIndex(prodObj => prodObj.id === response.id);

    if (index !== -1) {
      // Replace production object with response object
      production[index] = response;
    } else{
      production.push(response);
    }
    setProduction(production);
    setMessage({
      open: true,
      message: 'Saved Successfully',
      status: 'success',
    });
    createProductionDict(production);
  }

  function openStatusManager(event, rowData) {

    setFormulationSelected({ ...formulations[rowData.formulation], sku: rowData.pk });
    setManOrderSelected(rowData);
    setManOrderStatusManagerOpen(true);
  }

  function closeModal() {
    if (!shouldClose) {
      if (!window.confirm("Are you sure you want to close without saving?")) {
        return;
      }
    }
    cancel();
  }
  
  return (
    <>
      {(loading || !production || !user || !formulations || !units) &&
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginTop: "250px" }}>
            <Typography>
              <CircularProgress />
            </Typography>
          </Grid>
        </Grid>
      }

      {(!loading && production && user && formulations && units && !batchModalOpen && !productionModalOpen && !manOrderStatusManagerOpen) && <>
        <Paper elevation={0} square className={classes.generalListPaperContainer} >
          <Grid container spacing={0} style={{ padding: '0px' }}>
            <Grid item xs={12}>
              <TabbableGroupedTable
                key={dummyKey}
                tabStatuses={tabStatuses}

                isLoading={loading}
                title={"Production"}
                columns={productionColumns}
                data={production}

                style={{ width: "100%" }}
                localization={{ body: { editRow: { deleteText: 'Are you sure you want to delete this process? This action cannot be reversed.' } } }}

                onRowClick={(event, rowData, togglePanel) => {

                  openStatusManager(event, rowData);
                }}

                actions={
                  [
                    {
                      icon: (props) => (
                        <Button size="small" color="primary" variant="outlined" aria-label="add" className={classes.tabTableFreeAction}>
                          New M/O
                        </Button>
                      ),
                      tooltip: 'Create a Manufacturing Order',
                      isFreeAction: true,
                      onClick: (event) => {
                        setManOrderSelected({ status: 'Draft' });
                        setFormulationSelected({});
                        setManOrderStatusManagerOpen(true);
                      }
                    },
                    rowData => ({
                      icon: (props) => (
                        <Button size="small" color="primary" variant="outlined" aria-label="add">
                          {utils.statusActionCipher(rowData.status, false)}
                        </Button>
                      ),
                      tooltip: 'Open Manager',
                      onClick: (event, rowData) => openStatusManager(event, rowData)
                    }),
                  ]
                }

                options={{
                  actionsColumnIndex: -1,
                  //exportButton: true,
                  pageSize: 10,
                }}

                icons={{
                  Export: MoreVertIcon,
                }}
              />

            </Grid>
          </Grid>
        </Paper>

        <Message
          open={message.open}
          message={message.message}
          severity={message.status}
          vertical="bottom"
          horizontal="left"
          handleClose={() => { setMessage({ ...message, open: false }) }}
        />
      </>}

      {!loading &&
        <Dialog fullScreen open={manOrderStatusManagerOpen} onClose={() => { setManOrderStatusManagerOpen(false) }} TransitionComponent={Transition}>
          <DialogContent className={classes.generalFormDialogueContainer}>
            <ManOrderStatusManager
              onSave={onSave}
              setShouldClose={setShouldClose}
              closeModal={closeModal}
              cancel={cancel}
              user={user}
              units={units}
              formulation={formualtionSelected}
              formulations={formulations}
              production={production}
              manOrderSheet={manOrderSelected}
              activeTraceability={props.activeTraceability}
              indexedFormulations={formulations}
              productionLotCodeDict={productionLotCodeDict}
              setReturnMessage={setMessage}
              setManOrderSelected={setManOrderSelected}
              setFormulationSelected={setFormulationSelected}
            />
          </DialogContent>
        </Dialog>
      }
    </>
  )
}

ManOrderList.propTypes = {
  activeTraceability: PropTypes.object.isRequired,
  units: PropTypes.objectOf(PropTypes.object),
  formulations: PropTypes.objectOf(PropTypes.object),
  ingredients: PropTypes.objectOf(PropTypes.object),
  user: PropTypes.object,
}
