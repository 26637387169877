import React, { useState, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import ContentHeader from "../../../Components/Layouts/ContentHeader";

import Audit from "./Audit";
import AuditCard from "./AuditCard";
import AuditAPI from "./api/AuditAPI";

import NewAuditDialog from "./NewAuditDialog";

export default function Audits(props) {

	const [searchResults, setSearchResults] = useState([]);
	const [audits, setAudits] = useState(null);

	const history = useHistory();

	useEffect(() => {
		getAudits();

		const unlisten = history.listen(() => {
			getAudits();
		});
		return () => {
			unlisten();
		};
	}, [history]);

	function getAudits() {
		new AuditAPI().getAudits().then(response => {

			response.data.forEach((audit, index) => {
				response.data[index].jobId = localStorage.getItem("audit_download_job_" + audit.id)
			})
			setAudits(response.data);

		}).catch(error => {
			console.error(error);
		});
	}

	function deleteAudit(audit) {
		return new AuditAPI().deleteAudit(audit);
	}

	function newAudit() {
		history.push(`audit/new/`);
	}

	function removeAudit(auditToRemove) {
		deleteAudit(auditToRemove).then(response => {
			const newAudits = audits.filter(audit => audit.id !== auditToRemove.id)
			setAudits(newAudits);
		}).catch(error => {
			console.log(error);
		});
	}

	return (
		<Grid container>
			<Switch>
				<Route path="/records/audit/:id/edit">
					<Audit getAudits={getAudits} />
				</Route>

				<Route path="/records/audit/:id/view">
					<Audit getAudits={getAudits} />
				</Route>

				<Route path="/records/audit">
					<ContentHeader search searchData={audits} searchKeys={['title', 'last_updated', 'description']} setSearchResults={setSearchResults} title="Audits" />
					<Grid container justify="flex-end" style={{ padding: '16px 32px 0px 32px' }}>
						<Button variant="contained" color="primary" size="small" onClick={newAudit}>New Audit</Button>
					</Grid>
					<Grid container style={{ padding: '16px', margin: '16px', height: '75vh', overflow: 'auto' }}>
						{searchResults.map(audit => {
							return (
								<AuditCard audit={audit} removeAudit={removeAudit} />
							)
						})}
					</Grid>

					<Switch>
						<Route path="/records/audit/new/">
							<NewAuditDialog cancel={() => history.replace("/records/audit")} />
						</Route>
					</Switch>
				</Route>
			</Switch>
		</Grid>
	)
}