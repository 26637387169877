class CCPsAPI {

  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listCCPs() {
    return this.api.get(`${this.version}/ccps/`)
  }

  createCCP(payload) {
    return this.api.post(`${this.version}/ccps/`, payload);
  }

  retrieveCCP(pk) {
    return this.api.get(`${this.version}/ccps/${pk}/`);
  }

  updateCCP(payload) {
    return this.api.put(`${this.version}/ccps/${payload.pk}/`, payload);
  }

  deleteCCP(pk) {
    return this.api.delete(`${this.version}/ccps/${pk}/`);
  }

  getHistory(pk) {
    return this.api.get(`${this.version}/ccps/${pk}/get_history/`);
  }

  getProcessCCPs(haccp_plan_pk) {
    return this.api.get(`${this.version}/ccps/get_process_flow_diagram_ccps/?haccp_plan_pk=${haccp_plan_pk}`);
  }
}


export default CCPsAPI;
