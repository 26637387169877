import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import API from "../Api/Api";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

export default function AvatarComponent(props) {
  const [lastName, setLastName] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  let api = new API();

  useEffect(() => {
    if (props.user) {
      if (props.user.user) {
        if (props.user.user.first_name) {
          setFirstName(props.user.user.first_name.charAt(0).toUpperCase());
        }
        if (props.user.user.last_name) {
          setLastName(props.user.user.last_name.charAt(0).toUpperCase());
        }
      } else {
        if (props.user && props.user.first_name) {
          setFirstName(props.user.first_name.charAt(0).toUpperCase());
        }
        if (props.user && props.user.last_name) {
          setLastName(props.user.last_name.charAt(0).toUpperCase());
        }
      }
    }
    if (props.initials) {
      setFirstName(props.initials.charAt(0).toUpperCase());
      if (props.initials.indexOf(" ") >= 0) {
        setLastName(
          props.initials.split(" ").slice(-1).join(" ").charAt(0).toUpperCase()
        );
      } else {
        setLastName(null);
      }
    }
  }, [props]);

  useEffect(() => {
    if (props.mapped && props.user) {
      api
        .getOrganizationAPI()
        .getSelectedUser(props.user)
        .then((user) => {
          if (user.data.user) {
            if (user.data.user.first_name) {
              setFirstName(user.data.user.first_name.charAt(0).toUpperCase());
            }
            if (user.data.user.last_name) {
              setLastName(user.data.user.last_name.charAt(0).toUpperCase());
            }
            if (user.data.profile_image) {
              setImageURL(user.data.profile_image);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.mapped]);

  if (props.user) {
    return (
      <div>
        {imageURL && (
          <Avatar
            alt="Avatar"
            src={imageURL ? imageURL : ""}
            variant={props.rounded ? "rounded" : null}
          />
        )}
        {props.user.profile_image && !imageURL && (
          <Avatar
            alt="Avatar"
            src={props.user.profile_image ? props.user.profile_image : ""}
            variant={props.rounded ? "rounded" : null}
          />
        )}
        {!props.user.profile_image && !firstName && !lastName && !imageURL && (
          <Avatar
            alt="Avatar"
            variant={props.rounded ? "rounded" : null} /*src={FoodbyteLogo}*/
          />
        )}
        {!props.user.profile_image && firstName && lastName && !imageURL && (
          <Avatar alt="Avatar" variant={props.rounded ? "rounded" : null}>
            {firstName}
            {lastName}
          </Avatar>
        )}
        {!props.user.profile_image && firstName && !lastName && !imageURL && (
          <Avatar alt="Avatar" variant={props.rounded ? "rounded" : null}>
            {firstName}
          </Avatar>
        )}
      </div>
    );
  } else {
    return (
      <div>
        {firstName && !lastName && (
          <Avatar alt="Avatar" variant={props.rounded ? "rounded" : null}>
            {firstName}
          </Avatar>
        )}
        {firstName && lastName && (
          <Avatar alt="Avatar" variant={props.rounded ? "rounded" : null}>
            {firstName}
            {lastName}
          </Avatar>
        )}
      </div>
    );
  }
}
