import React, { useState, useEffect } from "react"
import { DialogContent } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import API from "../../Api/Api";
import Divider from "@material-ui/core/Divider";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

const DarkerDisabledTextField = withStyles({
    root: {
        marginRight: 8,
        "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.7)" // (default alpha is 0.38)
        }
    }
})(TextField);
function HazardModal(props) {

    let api = new API();

    function modelClose() {
        props.handleModalClosed();
    }

    function getAPI() {
        return api.getHazardAPI();
    }

    function save(hazard) {
        getAPI().updateHazard(hazard).then(e => {
            props.setHazard(e.data);
            props.handleModalClosed();
            props.setStatus(
                "Hazard updated!",
                "info"
            );
        }).catch(e => {
            console.log("error", e);
        });
    }

    return (
        <Dialog
            maxWidth="md"
            open={props.open}
            onClose={props.handleModalClosed}
        >
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <form style={{ padding: "16px" }}>
                            <Grid
                                container
                                spacing={3}
                                style={{ padding: "16px", margin: 0, width: "100%" }}
                            >
                                <Grid item xs={10}>
                                    <Typography variant="h5" noWrap>
                                        Old Hazard
                                     </Typography>
                                    <Typography>
                                        View information about this hazard.
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        disabled
                                        label="Name"
                                        name="name"
                                        variant="outlined"
                                        disabled
                                        value={props.beforeHazard.name}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        label="Description"
                                        name="description"
                                        variant="outlined"
                                        disabled
                                        value={props.beforeHazard.description}
                                        multiline
                                        rows={3}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel>Type: </InputLabel>
                                    <Select
                                        disabled
                                        name="type"
                                        value={props.beforeHazard.type}
                                    >
                                        <MenuItem value="B">Biological</MenuItem>
                                        <MenuItem value="C">Chemical</MenuItem>
                                        <MenuItem value="P">Physical</MenuItem>
                                    </Select>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled
                                                checked={props.beforeHazard.significant}
                                                name="significant"
                                                value={props.beforeHazard.significant}
                                                color="primary"
                                            />
                                        }
                                        label="Is the Potential Hazard Significant?"
                                        labelPlacement="start"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        label="Justification for Inclusion or Exclusion as a significant hazard"
                                        name="justification"
                                        variant="outlined"
                                        disabled
                                        value={props.beforeHazard.justification}
                                        multiline
                                        rows={3}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        label="Preventative Measures of the Significant Hazards"
                                        name="preventative_measures"
                                        variant="outlined"
                                        disabled
                                        value={props.beforeHazard.preventative_measures}
                                        multiline
                                        rows={3}
                                        fullWidth
                                    />
                                </Grid>

                            </Grid>
                        </form>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => save(props.beforeHazard)}
                        >
                            Revert
                        </Button>
                    </Grid>

                    <Grid item xs={6}>
                        <form style={{ padding: "16px" }}>
                            <Grid
                                container
                                spacing={3}
                                style={{ padding: "16px", margin: 0, width: "100%" }}
                            >

                                <Grid item xs={10}>
                                    <Typography variant="h5" noWrap>
                                        Current Hazard
                                    </Typography>
                                    <Typography>
                                        View information about this hazard.
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        label="Name"
                                        name="name"
                                        variant="outlined"
                                        disabled
                                        value={props.oldHazard.name}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        label="Description"
                                        name="description"
                                        variant="outlined"
                                        disabled
                                        value={props.oldHazard.description}
                                        multiline
                                        rows={3}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel>Type: </InputLabel>
                                    <Select
                                        disabled
                                        name="type"
                                        value={props.oldHazard.type}
                                    >
                                        <MenuItem value="B">Biological</MenuItem>
                                        <MenuItem value="C">Chemical</MenuItem>
                                        <MenuItem value="P">Physical</MenuItem>
                                    </Select>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled
                                                checked={props.oldHazard.significant}
                                                name="significant"
                                                value={props.oldHazard.significant}
                                                color="primary"
                                            />
                                        }
                                        label="Is the Potential Hazard Significant?"
                                        labelPlacement="start"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        label="Justification for Inclusion or Exclusion as a significant hazard"
                                        name="justification"
                                        variant="outlined"
                                        disabled
                                        value={props.oldHazard.justification}
                                        multiline
                                        rows={3}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        label="Preventative Measures of the Significant Hazards"
                                        name="preventative_measures"
                                        variant="outlined"
                                        disabled
                                        value={props.oldHazard.preventative_measures}
                                        multiline
                                        rows={3}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </form>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={modelClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>

        </Dialog >
    )
}
export default HazardModal;
