import React, { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import Formulation from "./Formulation";
import API from "../../Api/Api";
import Message from "../../Components/Message";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { StylesContext } from "../../App";
import { IconButton } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ContentHeader from "../../Components/Layouts/ContentHeader";
import { OrgContext } from "hooks/useOrganization";
import AddIcon from "@material-ui/icons/Add";
import EditHazardModal from "../Templates/Hazards/EditHazardModal";
import FormulationSelectTable from "./FormulationSelectTable";
import DialogActions from "@material-ui/core/DialogActions";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import TextField from "@material-ui/core/TextField";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EMPTY_MESSAGE = {
    open: false,
    message: "",
    status: "info",
};

NewFormulations.propTypes = {
    setShouldClose: PropTypes.func,
    haccpPlan: PropTypes.object,
};

const useStyles = makeStyles({
    accordionNoLine: {
        marginBottom: 8,
        marginTop: 8,
        "&.MuiAccordion-root:before": {
            backgroundColor: "white",
        },
    },
});

function NewFormulations(props) {
    const nativeClasses = useStyles();
    const organization = React.useContext(OrgContext);
    const classes = React.useContext(StylesContext);
    const [formulations, setFormulations] = useState([]);
    const [hazards, setHazards] = useState([]);
    const [user, setUser] = useState(false);
    const [loading, setLoading] = useState(true);
    const [formulationModalOpen, setFormulationModalOpen] = useState(false);
    const [formulationSelected, setFormulationSelected] = useState(-1);
    const [shouldClose, setShouldClose] = useState(true);
    const [shouldUpdate, setShouldUpdate] = useState(false);
    const [message, setMessage] = useState({ ...EMPTY_MESSAGE });

    const api = new API();

    let history = useHistory();
    let { params } = useParams();
    function getFormulations() {
        api.getHaccpPlanAPI()
            .getFormulations(props.haccpPlan.pk)
            .then((e) => {
                setFormulations(e.data);
            })
            .catch((e) => {
                console.log(e);
            });
    }
    useEffect(() => {
        api.getHaccpPlanAPI()
            .getFormulations(props.haccpPlan.pk)
            .then((e) => {
                setFormulations(e.data);
            })
            .catch((e) => {
                console.log(e);
            });

        api.getAuthAPI()
            .getAuthedProfile()
            .then((e) => {
                setUser(e.data);
            })
            .catch((e) => {
                console.log(e);
            });

        setLoading(false);
    }, []);

    useEffect(() => {
        if (loading) {
            return;
        }

        setFormulationModalOpen(true);
    }, [formulationSelected]);

    useEffect(() => {
        getAllIngredients();
        getHazards();
    }, [formulations]);

    function getHazards() {
        api.getHazardAPI()
            .listAllIngredientHazards(props.haccpPlan.pk)
            .then((e) => {
                setHazards(e.data);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    function AddHazard() {
        setHazardSelected(null);
        setHazardModalOpen(true);
    }

    const formulation_columns = [
        { title: "Name", field: "name" },
        { title: "Description", field: "description" },
        { title: "Weight", field: "weight", editable: "never" },
        { title: "Volume", field: "volume", editable: "never" },
        // {title: "Last Modified", field: "last_modified", editable: 'never'},
    ];

    /**
     * Handles keeping the list up to date with the back end, including updating all lines.
     *
     * @param {JSON} response - a response object from the server
     */
    function onSave(response, setReturnMessage, index = false) {
        setLoading(true);

        let updatedFormulationPk = response.data.pk;
        let updatedFormulations = [...formulations];
        let newFormulation = { ...response.data };

        let itemIndex;
        if (!index && index !== 0) {
            itemIndex = updatedFormulations.findIndex((formulation) => {
                return formulation.pk === updatedFormulationPk;
            });
        } else {
            itemIndex = index;
        }

        if (itemIndex > -1) {
            updatedFormulations.splice(itemIndex, 1, newFormulation);
        } else {
            updatedFormulations.push(newFormulation);
        }

        setFormulations(updatedFormulations);
        if (setReturnMessage) {
            setMessage({
                open: true,
                message: "Saved Successfully",
                status: "info",
            });
        } else {
            setLoading(false);
        }
    }

    const [allIngredients, setAllIngredients] = useState([]);
    let ingredientIndex = 0;
    function getAllIngredients() {
        api.getHaccpPlanAPI()
            .getAllIngredients(props.haccpPlan.pk)
            .then((e) => {
                //removes duplicate names
                let filteredIngredients = e.data.filter(
                    (thing, index, self) =>
                        index === self.findIndex((t) => t.name.toLowerCase() === thing.name.toLowerCase())
                );
                setAllIngredients(filteredIngredients);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    function AddFormulation() {
        setFormulationSelectOpen(true);
    }
    const [hazardModalOpen, setHazardModalOpen] = useState(false);
    const [hazardSelected, setHazardSelected] = useState(null);
    function handleHazardModalClosed() {
        if (!shouldClose) {
            alert("Save changes before closing.");
        } else {
            setHazardModalOpen(false);
        }
    }

    function modelClose() {
        if (!shouldClose) {
            return;
        } else {
            setHazardModalOpen(false);
        }
        setHazardModalOpen(false);
    }

    function hazardClicked(pk) {
        setHazardSelected(pk);
        setHazardModalOpen(true);
    }

    function hazardSave() {
        getHazards();
        setShouldClose(true);
    }

    function hazardSaveAndContinue() {
        getHazards();
        setHazardModalOpen(false);
        setShouldClose(true);
        setReturnStatus("Hazard Saved!", "info");
    }

    let match = useRouteMatch();

    function saveAndContinue() {
        history.push(`significant_hazards`);
    }

    function cancel() {
        setShouldClose(true);
        if (props.setShouldClose) {
            props.setShouldClose(true);
        }

        setHazardModalOpen(false);
    }

    const [open, setOpen] = React.useState(false);

    function setReturnStatus(message, status) {
        setMessage({ message: message, status: status });
        setOpen(true);
    }

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    function deleteHazard(hazard) {
        //This should be unlinking hazard from plan, once that api call is made by Ant.
        api.getHazardAPI()
            .deleteHazard(hazard.pk)
            .then((e) => {
                getHazards();
                setReturnStatus("Hazard Removed!", "info");
                setAnchorElHazard(null);
            })
            .catch((e) => {
                console.log(e);
            });

        setDeleteHazardWarningOpen(false);
        setHazardToRemove(null);
    }

    const [formulationSelectOpen, setFormulationSelectOpen] = useState(false);
    const [selectformulation, setSelectformulation] = useState(false);

    function Selectformulation(formulation) {
        setSelectformulation(formulation);
        api.getHaccpPlanAPI()
            .addFormulationToHaccpPlan(props.haccpPlan.pk, formulation)
            .then((e) => {
                setFormulations(e.data);
                setReturnStatus("Formulation Linked!", "info");
            })
            .catch((e) => {
                console.log(e);
            });

        setFormulationSelectOpen(false);
    }

    function setFormulationSelectClosed() {
        setFormulationSelectOpen(false);
    }

    const handleClick = (event, formulation) => {
        setAnchorEl(event.currentTarget);
        setFormulationToDelete(formulation);
    };
    const [modalOpen, setModalOpen] = useState(false);
    const handleMenuClose = () => {
        setAnchorEl(null);
        handleModalClosed();
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleClickHazard = (event, hazard) => {
        setAnchorElHazard(event.currentTarget);
        setHazardToDelete(hazard);
        setHazardToRemove(hazard);
    };
    const handleHazardMenuModalClosed = () => {
        setAnchorElHazard(null);
        handleModalClosed();
    };
    const handleHazardMenuClose = () => {
        setAnchorElHazard(null);
        handleHazardMenuModalClosed();
    };
    const [hazardToDelete, setHazardToDelete] = useState(null);
    const [anchorElHazard, setAnchorElHazard] = useState(null);
    const menuOpenHazard = Boolean(anchorElHazard);

    function handleModalClosed() {
        setModalOpen(false);
    }

    const [formulationName, setFormulationName] = React.useState("");
    const [deleteWarningOpen, setDeleteWarningOpen] = React.useState(false);
    const [formulationToDelete, setFormulationToDelete] = useState(null);

    function setWarningOpen(formulation) {
        setDeleteWarningOpen(true);
        setFormulationName(formulation.name);
        setFormulationSelected(formulation.pk);
    }

    function setWarningClosed() {
        setDeleteWarningOpen(false);
        setAnchorEl(null);
        setFormulationSelected(null);
    }

    function deleteFormulation(formulation) {
        setWarningOpen(formulationToDelete);
    }
    function deleteWarnedFormulation() {
        setAnchorEl(null);
        setFormulationSelected(null);
        api.getHaccpPlanAPI()
            .removeFormulationFromHaccpPlan(props.haccpPlan.pk, formulationToDelete)
            .then((e) => {
                setReturnStatus("Formulation Removed!", "info");
                setFormulations(e.data);
            })
            .catch((e) => {
                console.log(e);
            });
        setFormulationSelected(null);
        setDeleteWarningOpen(false);
    }

    function handleFormulationClosed() {
        if (!shouldClose) {
            setFormulationModalOpen(false);
        } else {
            alert("Save changes before closing.");
        }
    }

    const [deleteHazardWarningOpen, setDeleteHazardWarningOpen] = React.useState(false);
    const [hazardToRemove, setHazardToRemove] = useState(null);

    function setHazardWarningOpen(hazard) {
        setDeleteHazardWarningOpen(true);
        setHazardToRemove(hazard);
    }

    function setHazardWarningClosed() {
        setDeleteHazardWarningOpen(false);
        setHazardToRemove(null);
        setAnchorElHazard(null);
    }

    const [expanded, setExpanded] = React.useState(false);
    //could potentially just use components from hazard.components
    let processNames = "";
    const handleAccordianChange = (panel) => (event, isExpanded) => {
        if (panel.processes_read) {
            panel.processes_read.forEach((name) => {
                if (processNames === "") {
                    processNames = name.name;
                } else {
                    processNames = processNames + ", " + name.name;
                }
            });
            setProcessesRead(processNames);
        }
        setExpanded(isExpanded ? panel : false);
    };

    const [processesRead, setProcessesRead] = React.useState("null");
    const [newFormulationModelOpen, setNewFormulationModelOpen] = React.useState(false);

    function createFormulation() {
        setNewFormulationModelOpen(true);
    }
    function closeNewFormulation() {
        setNewFormulationModelOpen(false);
    }

    return (
        <div style={{ width: "100%" }}>
            <Dialog
                open={deleteHazardWarningOpen}
                onClose={setHazardWarningClosed}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title-delete-hazard">{"Unlink this?"}</DialogTitle>
                <DialogContent>
                    <Typography
                        style={{
                            fontFamily: "Roboto",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#687895",
                            marginBottom: "0px",
                        }}
                    >
                        Are you sure you want to delete this Hazard?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={setHazardWarningClosed} color="primary">
                        No
                    </Button>
                    <Button onClick={() => deleteHazard(hazardToRemove)} color="primary" autoFocus>
                        Yes, Remove
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={deleteWarningOpen}
                onClose={setWarningClosed}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Un-link this Formulation?"}</DialogTitle>
                <DialogContent>
                    <Typography
                        style={{
                            fontFamily: "Roboto",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#687895",
                            marginBottom: "0px",
                        }}
                    >
                        Are you sure you want to un-link this Formulation?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={setWarningClosed} color="primary">
                        No
                    </Button>
                    <Button onClick={deleteWarnedFormulation} color="primary" autoFocus>
                        Yes, Remove
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={formulationSelectOpen}
                onClose={setFormulationSelectClosed}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={"md"}
            >
                <DialogContent>
                    <Grid
                        container
                        style={{
                            marginRight: "20px",
                            marginBottom: "14px",
                        }}
                    >
                        <Grid item xs={6} container style={{ alignItems: "center" }}>
                            <Typography
                                style={{
                                    fontFamily: "Roboto",
                                    fontWeight: "500",
                                    fontSize: "24px",
                                    lineHeight: "26px",
                                    color: "#687895",
                                    marginLeft: "0px",
                                    marginBottom: "0px",
                                }}
                            >
                                Select A Formulation
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            container
                            style={{
                                alignItems: "center",
                                justifyContent: "flex-end",
                            }}
                        >
                            {/* <Button
                onClick={() => {
                  createFormulation();
                }}
                style={{ float: "right" }}
                variant="contained"
                color="primary"
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "21px",
                    color: "white",
                  }}
                >
                  Create Formulation
                </Typography>
              </Button> */}
                        </Grid>
                    </Grid>
                    <FormulationSelectTable
                        createFormulation={createFormulation}
                        selectformulation={Selectformulation}
                        cancel={setFormulationSelectClosed}
                    ></FormulationSelectTable>
                </DialogContent>
            </Dialog>

            <EditHazardModal
                allIngredients={allIngredients}
                open={hazardModalOpen}
                handleModalClosed={handleHazardModalClosed}
                modelClose={modelClose}
                haccpPlanPk={props.haccpPlan.pk}
                user={user}
                cancel={cancel}
                setShouldUpdate={setShouldUpdate}
                setShouldClose={setShouldClose}
                hazard_pk={hazardSelected}
                saveAndContinue={hazardSaveAndContinue}
                save={hazardSave}
            />

            <ContentHeader title="Formulations" />
            <Grid container spacing={2} className={classes.generalContainerGrid}>
                <Grid item md={6} xs={12} container style={{ alignItems: "center" }}>
                    <Typography
                        style={{
                            fontFamily: "Roboto",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#687895",
                            marginLeft: "28px",
                            marginBottom: "0px",
                        }}
                    >
                        Linked Formulations
                    </Typography>
                    <Typography
                        style={{
                            fontFamily: "Roboto",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "blue",
                            marginLeft: "4px",
                            marginBottom: "0px",
                        }}
                    >
                        ({formulations.length})
                    </Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                    <Grid container style={{ width: "auto", float: "right", alignItems: "center" }}>
                        <IconButton
                            disableRipple
                            style={{
                                backgroundColor: "transparent",
                                float: "right",
                                marginRight: "28px",
                                marginRight: "16px",
                            }}
                            disabled={props.readOnlyMode}
                            onClick={AddFormulation}
                        >
                            <AddIcon></AddIcon>
                            <Typography
                                style={{
                                    fontFamily: "Roboto",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    lineHeight: "21px",
                                    color: "#687895",
                                    marginBottom: "0px",
                                    float: "right",
                                }}
                            >
                                Add Link to Formulation
                            </Typography>
                        </IconButton>
                    </Grid>
                </Grid>

                {formulations.map((formulation) => {
                    return (
                        <Paper
                            key={formulation.id}
                            elevation={0}
                            style={{
                                width: "100%",
                                height: "64px",
                                margin: "32px",
                                marginTop: "12px",
                                marginBottom: "0px",
                            }}
                        >
                            <Grid container style={{ margin: "12px", alignItems: "center" }}>
                                <Grid
                                    item
                                    xs={6}
                                    container
                                    style={{ width: "auto", float: "left", alignItems: "center" }}
                                >
                                    <Typography
                                        style={{
                                            fontFamily: "Roboto",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            lineHeight: "21px",
                                            color: "#687895",
                                        }}
                                    >
                                        {formulation.name}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    container
                                    style={{
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <IconButton
                                        disableRipple
                                        style={{
                                            backgroundColor: "transparent",
                                            float: "right",
                                            marginRight: "24px",
                                        }}
                                        disabled={props.readOnlyMode}
                                        onClick={(event) => handleClick(event, formulation)}
                                    >
                                        <MoreHorizIcon></MoreHorizIcon>
                                    </IconButton>

                                    <Menu
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={menuOpen}
                                        // onChange={handleClose}
                                        onClose={handleMenuClose}
                                        PaperProps={{
                                            style: {
                                                maxHeight: 48 * 4.5,
                                                width: 200,
                                            },
                                        }}
                                    >
                                        <MenuItem key={formulation} onClick={() => deleteFormulation(formulation)}>
                                            Remove
                                        </MenuItem>
                                    </Menu>
                                </Grid>
                            </Grid>
                        </Paper>
                    );
                })}

                <Paper
                    elevation={0}
                    style={{
                        width: "100%",
                        margin: "32px",
                        marginTop: "16px",
                        marginBottom: "32px",
                        padding: "19px",
                    }}
                >
                    <Grid container>
                        <Grid item xs={12} container>
                            <Typography
                                style={{
                                    fontFamily: "Roboto",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    lineHeight: "21px",
                                    color: "#1F203D",
                                }}
                            >
                                Ingredients List
                            </Typography>
                        </Grid>
                        <Grid item xs={12} container>
                            <Typography
                                style={{
                                    fontFamily: "Roboto",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    marginTop: "9px",
                                    lineHeight: "21px",
                                    color: "#687895",
                                }}
                            >
                                A list of ingredients automatically generated from the linked formulations. To change
                                this list, you must update/link formulations above.
                            </Typography>
                        </Grid>{" "}
                        {allIngredients && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "16px",
                                    padding: "14px",
                                    backgroundColor: "#F8F8F8",
                                    display: "flex",
                                    flexFlow: "wrap",
                                }}
                            >
                                {allIngredients.map((ingredient) => {
                                    ingredientIndex++;
                                    if (ingredientIndex !== allIngredients.length) {
                                        return (
                                            <Typography
                                                key={ingredient.pk}
                                                style={{
                                                    fontFamily: "Roboto",
                                                    fontWeight: "500",
                                                    fontSize: "14px",
                                                    lineHeight: "21px",
                                                    color: "#9798B0",
                                                    marginRight: "4px",
                                                }}
                                            >
                                                {ingredient.name},
                                            </Typography>
                                        );
                                    } else {
                                        return (
                                            <Typography
                                                key={ingredient.pk}
                                                style={{
                                                    fontFamily: "Roboto",
                                                    fontWeight: "500",
                                                    fontSize: "14px",
                                                    lineHeight: "21px",
                                                    color: "#9798B0",
                                                    marginRight: "4px",
                                                }}
                                            >
                                                {ingredient.name}
                                            </Typography>
                                        );
                                    }
                                })}
                            </div>
                        )}
                    </Grid>
                </Paper>

                <Grid item md={6} xs={12} container style={{ alignItems: "center" }}>
                    <Typography
                        style={{
                            fontFamily: "Roboto",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#687895",
                            marginLeft: "28px",
                            marginBottom: "0px",
                        }}
                    >
                        Ingredient Hazards
                    </Typography>
                    <Typography
                        style={{
                            fontFamily: "Roboto",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "blue",
                            marginLeft: "4px",
                            marginBottom: "0px",
                        }}
                    >
                        ({hazards.length})
                    </Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                    <Grid container style={{ width: "auto", float: "right", alignItems: "center" }}>
                        <IconButton
                            disableRipple
                            style={{
                                backgroundColor: "transparent",
                                float: "right",
                                marginRight: "28px",
                                marginLeft: "16px",
                            }}
                            disabled={props.readOnlyMode}
                            onClick={AddHazard}
                        >
                            <AddIcon></AddIcon>
                            <Typography
                                style={{
                                    fontFamily: "Roboto",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    lineHeight: "21px",
                                    color: "#687895",
                                    marginBottom: "0px",
                                    float: "right",
                                }}
                            >
                                Add New Ingredient Hazard
                            </Typography>
                        </IconButton>
                    </Grid>
                </Grid>

                {hazards.map((hazard) => {
                    return (
                        <Accordion
                            key={hazard.pk}
                            elevation={0}
                            style={{
                                margin: "32px",
                                marginTop: "8px",
                                marginBottom: "8px",
                                width: "100%",
                            }}
                            className={nativeClasses.accordionNoLine}
                            expanded={expanded === hazard}
                            onChange={handleAccordianChange(hazard)}
                        >
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <Grid container style={{ margin: "8px", alignItems: "center" }}>
                                    <Grid
                                        item
                                        xs={7}
                                        md={5}
                                        container
                                        style={{
                                            width: "auto",
                                            float: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                fontFamily: "Roboto",
                                                fontWeight: "500",
                                                fontSize: "14px",
                                                lineHeight: "21px",
                                                color: "#687895",
                                            }}
                                        >
                                            {hazard.name}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                        md={2}
                                        container
                                        style={{
                                            width: "auto",
                                            float: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        {hazard.significant && (
                                            <Tooltip title="Significant Hazard">
                                                <ErrorOutlineIcon
                                                    style={{
                                                        marginLeft: "12px",
                                                        color: "#687895",
                                                    }}
                                                ></ErrorOutlineIcon>
                                            </Tooltip>
                                        )}
                                        {hazard.type === "B" && (
                                            <Chip
                                                style={{
                                                    marginLeft: "12px",
                                                    color: "#18BFF6",
                                                    background: "rgba(24, 191, 247, 0.1)",
                                                }}
                                                label="BIOLOGICAL"
                                            />
                                        )}
                                        {hazard.type === "P" && (
                                            <Chip
                                                style={{
                                                    marginLeft: "12px",
                                                    color: "#18BFF6",
                                                    background: "rgba(24, 191, 247, 0.1)",
                                                }}
                                                label="PHYSICAL"
                                            />
                                        )}
                                        {hazard.type === "C" && (
                                            <Chip
                                                style={{
                                                    marginLeft: "12px",
                                                    color: "#18BFF6",
                                                    background: "rgba(24, 191, 247, 0.1)",
                                                }}
                                                label="CHEMICAL"
                                            />
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        md={0}
                                        container
                                        style={{
                                            width: "auto",
                                            float: "left",
                                            alignItems: "center",
                                        }}
                                    ></Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        md={3}
                                        container
                                        style={{
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <Button
                                            variant="text"
                                            style={{ marginRight: "16px" }}
                                            onClick={() => hazardClicked(hazard.pk)}
                                            disabled={props.readOnlyMode}
                                        >
                                            <Typography
                                                style={{
                                                    fontFamily: "Roboto",
                                                    fontWeight: "500",
                                                    fontSize: "14px",
                                                    lineHeight: "21px",
                                                    color: "#18BFF6",
                                                    marginBottom: "0px",
                                                    opacity: 1,
                                                }}
                                            >
                                                Edit Hazard
                                            </Typography>
                                        </Button>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={6}
                                        md={1}
                                        container
                                        style={{
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <IconButton
                                            disableRipple
                                            style={{
                                                backgroundColor: "transparent",
                                                float: "right",
                                            }}
                                            disabled={props.readOnlyMode}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleClickHazard(event, hazard);
                                            }}
                                        >
                                            <MoreHorizIcon></MoreHorizIcon>
                                        </IconButton>

                                        <Menu
                                            anchorEl={anchorElHazard}
                                            keepMounted
                                            open={menuOpenHazard}
                                            // onChange={handleClose}
                                            onClose={handleHazardMenuClose}
                                            PaperProps={{
                                                style: {
                                                    maxHeight: 48 * 4.5,
                                                    width: 200,
                                                },
                                            }}
                                        >
                                            <MenuItem
                                                key={hazard}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setDeleteHazardWarningOpen(true);
                                                }}
                                            >
                                                Delete
                                            </MenuItem>
                                        </Menu>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails style={{ marginLeft: "8px", marginRight: "8px" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography
                                            style={{
                                                fontFamily: "Roboto",
                                                fontWeight: "500",
                                                fontSize: "14px",
                                                lineHeight: "21px",
                                                color: "#1F203D",
                                                marginBottom: "0px",
                                                opacity: 1,
                                            }}
                                        >
                                            Hazard Description
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {hazard.description && (
                                            <TextField
                                                disabled
                                                name="Hazard Description"
                                                placeholder=""
                                                className={classes.textfieldbackground}
                                                variant="outlined"
                                                defaultValue={hazard.description}
                                                fullWidth
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            style={{
                                                fontFamily: "Roboto",
                                                fontWeight: "500",
                                                fontSize: "14px",
                                                lineHeight: "21px",
                                                color: "#1F203D",
                                                marginBottom: "0px",
                                                opacity: 1,
                                            }}
                                        >
                                            Hazard Type
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            style={{
                                                fontFamily: "Roboto",
                                                fontWeight: "500",
                                                fontSize: "14px",
                                                lineHeight: "21px",
                                                color: "#1F203D",
                                                marginBottom: "0px",
                                                opacity: 1,
                                            }}
                                        >
                                            Is this potential hazard significant?
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {hazard.type && (
                                            <TextField
                                                disabled
                                                name="Hazard Description"
                                                placeholder=""
                                                className={classes.textfieldbackground}
                                                variant="outlined"
                                                defaultValue={
                                                    hazard.type === "C"
                                                        ? "Chemical"
                                                        : hazard.type === "B"
                                                        ? "Biological"
                                                        : "Physical"
                                                }
                                                fullWidth
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {hazard && (
                                            <TextField
                                                disabled
                                                name="Hazard Description"
                                                placeholder=""
                                                className={classes.textfieldbackground}
                                                variant="outlined"
                                                defaultValue={hazard.significant ? "True" : "False"}
                                                fullWidth
                                            />
                                        )}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography
                                            style={{
                                                fontFamily: "Roboto",
                                                fontWeight: "500",
                                                fontSize: "14px",
                                                lineHeight: "21px",
                                                color: "#1F203D",
                                                marginBottom: "0px",
                                                opacity: 1,
                                            }}
                                        >
                                            Justification for Inclusion or Exclusion as a Significant Hazard
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {hazard && (
                                            <TextField
                                                disabled
                                                name="Hazard Description"
                                                placeholder=""
                                                className={classes.textfieldbackground}
                                                variant="outlined"
                                                defaultValue={hazard.justification}
                                                fullWidth
                                            />
                                        )}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography
                                            style={{
                                                fontFamily: "Roboto",
                                                fontWeight: "500",
                                                fontSize: "14px",
                                                lineHeight: "21px",
                                                color: "#1F203D",
                                                marginBottom: "0px",
                                                opacity: 1,
                                            }}
                                        >
                                            Preventative Measures of the Significant Hazard
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {hazard && (
                                            <TextField
                                                disabled
                                                name="Hazard Description"
                                                placeholder=""
                                                className={classes.textfieldbackground}
                                                variant="outlined"
                                                defaultValue={hazard.preventative_measures}
                                                fullWidth
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            style={{
                                                fontFamily: "Roboto",
                                                fontWeight: "500",
                                                fontSize: "14px",
                                                lineHeight: "21px",
                                                color: "#1F203D",
                                                marginBottom: "0px",
                                                opacity: 1,
                                            }}
                                        >
                                            Linked Processes ({hazard.processes.length})
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {processesRead && (
                                            <TextField
                                                disabled
                                                name="Hazard Description"
                                                placeholder=""
                                                className={classes.textfieldbackground}
                                                variant="outlined"
                                                value={processesRead}
                                                fullWidth
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            style={{
                                                fontFamily: "Roboto",
                                                fontWeight: "500",
                                                fontSize: "14px",
                                                lineHeight: "21px",
                                                color: "#1F203D",
                                                marginBottom: "0px",
                                                opacity: 1,
                                            }}
                                        >
                                            Linked to Ingredients?
                                        </Typography>
                                    </Grid>
                                    {hazard.ingredients.length > 0 && (
                                        <Grid item xs={12}>
                                            <TextField
                                                disabled
                                                name="Linked to Ingredients_hazard"
                                                placeholder="Linked to Ingredients"
                                                className={classes.textfieldbackground}
                                                variant="outlined"
                                                defaultValue={"Yes"}
                                                fullWidth
                                            />
                                        </Grid>
                                    )}
                                    {!hazard.ingredients.length && (
                                        <Grid item xs={12}>
                                            <TextField
                                                disabled
                                                name="Linked to Ingredients_hazard"
                                                placeholder="Linked to Ingredients"
                                                className={classes.textfieldbackground}
                                                variant="outlined"
                                                defaultValue={"No"}
                                                fullWidth
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Grid>

            <Dialog
                fullScreen
                open={newFormulationModelOpen}
                onClose={() => {
                    closeNewFormulation();
                }}
                TransitionComponent={Transition}
            >
                <DialogContent>
                    <Formulation />
                </DialogContent>
            </Dialog>

            <Message
                position={true}
                open={open}
                message={message.message}
                severity={message.status}
                style={{ zIndex: 999999 }}
                vertical="bottom"
                horizontal="right"
                handleClose={handleClose}
            />
        </div>
    );
}

export default NewFormulations;
