import React, { useEffect, useState } from "react";

import MaterialTable from "material-table";
import API from "../../Api/Api";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Message from "../../Components/Message";
import CustomerForm from "./CustomerForm";
import DialogContent from "@material-ui/core/DialogContent";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Traceability from "../Traceability";
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import TraceabilityUtils from "../TraceabilityUtils";
import {StylesContext} from "../../App";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomerList(props) {
  const utils = new TraceabilityUtils();
  const classes = React.useContext(StylesContext);

  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [shouldClose, setShouldClose] = useState(true);
  const [customerSelected, setCustomerSelected] = useState(-1);
  const [user, setUser] = useState(null);
  const [message, setMessage] = useState( utils.emptyMessage() );

  useEffect(() => {
    //this skips the initial render pulling the modal up.
    if(loading) {
      return;
    }

    setCustomerModalOpen(true);
  }, [customerSelected])

  useEffect(() => {
    const api = new Traceability().getCustomerAPI();
    const authApi = new API().getAuthAPI();

    authApi.getAuthedProfile().then(e => {
      setUser(e.data);
    }).catch(e => {
      //console.log(e);
    })

    if(props.customers) {
      let customerObjects = props.customers;

      setCustomers(customerObjects);
      setLoading(false);
      return;
    }

    api.listCustomers().then(response => {
      let customerObjects = response.data;

      setCustomers(customerObjects);
      setLoading(false);
    }).catch(e => {
      //console.log(JSON.stringify(e));
    })

  }, []);

  const customerColumns = [
    { title: "Company", field: "company" },
    { title: "Email", field: "email" },
    { title: "Phone Number", field: "phone_number" },
    { title: "Fax Number",  field: "fax_number" },
  ];

  function addCustomer() {
    //it needs to be different to register, so just flip it back and forth.
    setCustomerSelected(customerSelected == -1 ? -2 : -1);
  }

  function handleCustomerClosed() {
    if (shouldClose) {
      setCustomerModalOpen(false);
    } else {
      alert("Save changes before closing.")
    }
  }

  function closeModal() {
    if(!shouldClose) {
      if(!window.confirm("Are you sure you want to close without saving?")) {
        return;
      }
    }

    cancel();
  }

  function cancel() {
    setShouldClose(true);
    setCustomerModalOpen(false);
  }

  function onSave(response) {
    let updatedCustomerPk = response.data.pk;
    let updatedCustomers = [...customers];

    let customerIndex = updatedCustomers.findIndex(customer => {
      return customer.pk === updatedCustomerPk;
    });

    if (customerIndex > -1) {
      updatedCustomers.splice(customerIndex, 1, response.data);
    }
    else {
      updatedCustomers.push(response.data);
    }

    setCustomers(updatedCustomers);
    setMessage({
      open: true,
      message: 'Saved Successfully',
      status: 'success',
    });
  }

  function deleteCustomer(customer, resolve, reject) {
    const api = new Traceability().getCustomerAPI();

    api.deleteCustomer(customer.pk).then(response => {
      let updatedCustomers = [...customers];
      let customerIndex = updatedCustomers.findIndex(updatedCustomer => {
        return updatedCustomer.pk === customer.pk;
      });

      if (customerIndex > -1) {
        updatedCustomers.splice(customerIndex, 1);
      }

      setMessage({
        open: true,
        message: 'Deleted Successfully',
        status: 'success',
      });

      setCustomers(updatedCustomers);
      resolve();
    }).catch(error => {
      //console.log(error);
      reject();
    })
  }


  return (
    <>
      { loading &&
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginTop: "250px" }}>
            <Typography>
              <CircularProgress />
            </Typography>
          </Grid>
        </Grid>
      }

      { (!loading && customers) && <>
        <Paper elevation={0} variant="outlined" className={classes.generalListPaperContainer} square>
          <Grid container spacing={3} className={classes.generalContainerGrid}>
            <Grid item xs={12}>

              <MaterialTable
                components={{
                  Container: props => <Paper {...props} elevation={0} />
                }}

                isLoading={loading} 
                title="Customers" 
                columns={customerColumns} 
                data={customers}

                style={{ width: "100%" }}
                localization={{ body: { editRow: { deleteText: 'Are you sure you want to delete this Customer? This action cannot be reversed.' } } }}

                editable={{
                  onRowDelete: (newData) => new Promise((resolve, reject) => {
                    deleteCustomer(newData, resolve, reject);
                  })
                }}

                actions={
                  [
                    {
                      icon: (props) => (
                        <Button size="small" color="primary" variant="contained" aria-label="add" className={classes.tabTableFreeAction}>
                          Add Customer
                        </Button>
                      ),
                      tooltip: 'Add Customer',
                      isFreeAction: true,
                      onClick: (event) => addCustomer()
                    },
                  ]
                }

                onRowClick={(event, rowData, togglePanel) => {
                  setCustomerSelected(rowData.tableData.id);

                  if(customerSelected != rowData.tableData.id) {
                    setCustomerSelected(rowData.tableData.id);
                  }
                  else {
                    setCustomerModalOpen(true);
                  }
                }}

                options={{
                  actionsColumnIndex: -1,
                  //exportButton: true,
                  pageSize: 10,
                }}

                icons={{
                  Export: MoreVertIcon,
                }}
              />

            </Grid>
          </Grid>
        </Paper>

        <Dialog fullScreen open={customerModalOpen} onClose={handleCustomerClosed} TransitionComponent={Transition}>
          <DialogContent className={classes.generalFormDialogueContainer}>
            <CustomerForm
              customer={customerSelected >= 0 ? customers[customerSelected] : {}}
              setShouldClose={setShouldClose}
              closeModal={closeModal}
              cancel={cancel}
              onSave={onSave}
              user={user}
              activeTraceability={props.activeTraceability}
            />
          </DialogContent>
        </Dialog>
        

        <Message
          open={message.open}
          message={message.message}
          severity={message.status}
          vertical="bottom"
          horizontal="left"
          handleClose={() => {setMessage( {...message, open:false} )}}
        />
      </>}
    </>
  )
}

CustomerList.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.object),
  activeTraceability: PropTypes.object.isRequired,
}
