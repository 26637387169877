
export default class MonitoringAPI {

  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  getEventManager() {
    return this.api.get(`${this.version}/event_manager/get_event_manager/`);
  }

  getAllEvents() {
    return this.api.get(`${this.version}/event_manager/all_events/`);
  }

  getAllTasks() {
    return this.api.get(`${this.version}/event_manager/all_tasks/`);
  }

  getMyTasks(pk) {
    return this.api.get(`${this.version}/event_manager/my_tasks/`);
  }
  getMyIncompleteTasks() {
    return this.api.get(`${this.version}/event_manager/my_incomplete_tasks/`);
  }
  getMyCompletedTasks() {
    return this.api.get(`${this.version}/event_manager/my_completed_tasks/`);
  }

  getTeamIncompleteTasks() {
    return this.api.get(`${this.version}/event_manager/team_tasks_incomplete/`);
  }
  getTeamCompletedTasks() {
    return this.api.get(`${this.version}/event_manager/team_tasks_completed/`);
  }
  getTeamTasksThisMonth() {
    return this.api.get(`${this.version}/event_manager/team_tasks_this_month/`);
  }

  getMyDeviations() {
    return this.api.get(`${this.version}/event_manager/deviations/`);
  }

  getMyVerificationTasks() {
    return this.api.get(`${this.version}/event_manager/my_verifications/`);
  }

  searchVerificationTasks(payload, pageNumber, pageSize) {
    return this.api.post(`${this.version}/verification_search/list_between_dates/?page=${pageNumber}&page_size=${pageSize}`, payload);
  }

  getVerificationNames(haccp_plan_pk) {
    if (typeof haccp_plan_pk === 'number') {
      return this.api.get(`${this.version}/verification/get_event_names/?haccp_plan_pk=${haccp_plan_pk}`);
    }

    return this.api.post(`${this.version}/verification/get_event_names/`)
  }

  getVerificationTask(pk) {
    return this.api.get(`${this.version}/verification/${pk}/`);
  }

  updateVerificationTask(verification) {
    return this.api.put(`${this.version}/verification/${verification.id}/`, verification);
  }

  startMonitoring(pk) {
    return this.api.put(`${this.version}/event_manager/${pk}/start_monitoring/`);
  }

  pauseMonitoring(pk) {
    return this.api.put(`${this.version}/event_manager/${pk}/pause_monitoring/`);
  }

  runJob(formData) {
    return this.api.put(`${this.version}/event_manager/run_job/`, formData);
  }

  generateForms(pk, formData) {
    return this.api.put(`${this.version}/event_manager/${pk}/generate_forms/`, formData);
  }

  resetMonitoring(pk) {
    return this.api.put(`${this.version}/event_manager/${pk}/reset_monitoring/`);
  }

  generateVerificationForms(pk, formData) {
    return this.api.put(`${this.version}/event_manager/${pk}/generate_verification_forms/`, formData);
  }

  changeSettings(pk, data) {
    return this.api.put(`${this.version}/event_manager/${pk}/change_settings/`, data);
  }

  getForm(pk) {
    return this.api.get(`${this.version}/monitoring/${pk}/`);
  }

  updateForm(pk) {
    return this.api.put(`${this.version}/monitoring/${pk}/`);
  }

  deleteForm(pk) {
    return this.api.delete(`${this.version}/monitoring/${pk}/`);
  }

  retrieveEventRecord(pk) {
    return this.api.get(`${this.version}/event_record/${pk}/`);
  }

  listEventRecords() {
    return this.api.get(`${this.version}/event_record/`);
  }

  searchEventRecords(payload, pageNumber, pageSize) {
    return this.api.post(`${this.version}/event_record_search/list_between_dates/?page=${pageNumber}&page_size=${pageSize}`, payload);
  }
  export(params) {
    return this.api.get(`${this.version}/event_record_search/export${this.objectToQueryParams(params)}`, { responseType: 'arraybuffer' });
  }

  submitRecord(record) {
    return this.api.put(`${this.version}/event_record/${record.id}/`, record);
  }

  getSupportedTimezones() {
    return this.api.get(`${this.version}/event_manager/get_supported_timezones/`);
  }
  objectToQueryParams(obj) {
		const queryParams = [];
		for (const key in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, key)) {
				queryParams.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
			}
		}
		return queryParams.length ? '?' + queryParams.join('&') : '';
	}
}
