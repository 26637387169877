import React, { useState } from "react"

import { DialogContent } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import TextField from "@material-ui/core/TextField";
import API from "../../Api/Api";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";

const DarkerDisabledTextField = withStyles({
    root: {
        marginRight: 8,
        "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.7)" // (default alpha is 0.38)
        }
    }
})(TextField);
function ProductDescriptionModal(props) {

    let api = new API();

    function modelClose() {
        props.handleModalClosed();
    }

    function getAPI() {
        return api.getProductDescriptionAPI();
    }

    function save(productDescription) {
        getAPI().updateProductDescription(productDescription).then(e => {
            props.setProductDescription(e.data);
            props.handleModalClosed();
            props.setStatus("Updated Product Description!", "info");
        }).catch(e => {
            console.log("error", e);
        });
    }

    return (
        <Dialog
            maxWidth="md"
            open={props.open}
            onClose={props.handleModalClosed}
        >
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <form>
                            <Grid container spacing={3}>
                                <Grid item xs={11}>
                                    <Typography variant="h5" noWrap>
                                        Old Product Description{" "}
                                    </Typography>
                                    View this product description
                                    </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        name="product_names"
                                        label="Product name(s)"
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        value={props.beforeProductDescription.product_names}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        name="sources"
                                        label="Source of raw material"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        disabled
                                        rows={3}
                                        value={props.beforeProductDescription.sources}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        name="product_characteristics"
                                        label="Important final product characteristics"
                                        fullWidth
                                        multiline
                                        disabled
                                        rows={3}
                                        variant="outlined"
                                        value={
                                            props.beforeProductDescription.product_characteristics
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        name="packaging"
                                        label="Packaging"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        disabled
                                        rows={3}
                                        value={props.beforeProductDescription.packaging}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        name="usage"
                                        label="How the end product is to be used"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        disabled
                                        rows={3}
                                        value={props.beforeProductDescription.usage}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        name="shelf_life"
                                        label="Shelf life"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        disabled
                                        rows={3}
                                        value={props.beforeProductDescription.shelf_life}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        name="where_sold"
                                        label="Where the product will be sold"
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        multiline
                                        rows={3}
                                        value={props.beforeProductDescription.where_sold}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        name="labelling_instructions"
                                        label="Special labelling instructions"
                                        fullWidth
                                        multiline
                                        disabled
                                        rows={3}
                                        variant="outlined"
                                        value={
                                            props.beforeProductDescription.labelling_instructions
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        name="distribution_controls"
                                        label="Special distribution control"
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        multiline
                                        rows={3}
                                        value={props.beforeProductDescription.distribution_controls}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => save(props.beforeProductDescription)}
                        >
                            Revert
                        </Button>
                    </Grid>


                    <Grid item xs={6}>
                        <form>
                            <Grid container spacing={3}>
                                <Grid item xs={11}>
                                    <Typography variant="h5" noWrap>
                                        Current Product Description{" "}
                                    </Typography>
                                    View this product description
                                    </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        name="product_names"
                                        label="Product name(s)"
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                        value={props.oldProductDescription.product_names}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        name="sources"
                                        label="Source of raw material"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        disabled
                                        rows={3}
                                        value={props.oldProductDescription.sources}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        name="product_characteristics"
                                        label="Important final product characteristics"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        disabled
                                        variant="outlined"
                                        value={
                                            props.oldProductDescription.product_characteristics
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        name="packaging"
                                        label="Packaging"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        disabled
                                        value={props.oldProductDescription.packaging}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        name="usage"
                                        label="How the end product is to be used"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        disabled
                                        rows={3}
                                        value={props.oldProductDescription.usage}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        name="shelf_life"
                                        label="Shelf life"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        disabled
                                        value={props.oldProductDescription.shelf_life}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        name="where_sold"
                                        label="Where the product will be sold"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        disabled
                                        rows={3}
                                        value={props.oldProductDescription.where_sold}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        name="labelling_instructions"
                                        label="Special labelling instructions"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        disabled
                                        variant="outlined"
                                        value={
                                            props.oldProductDescription.labelling_instructions
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        name="distribution_controls"
                                        label="Special distribution control"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        disabled
                                        rows={3}
                                        marginBottom={5}
                                        value={props.oldProductDescription.distribution_controls}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={modelClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>

        </Dialog >
    )
}


export default ProductDescriptionModal;
