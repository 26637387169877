import React from "react";
import sanitizeHtml from "sanitize-html";

export default function SanitizeHtml(props) {

	function createMarkup() {
		return {__html: sanitizeHtml(props.value, {
			allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img' ]),
			allowedAttributes: {
				'img': [ 'src' ],
				'iframe': ['src'],
			},
			allowedSchemes: [ 'data', 'http' ],
			allowedIframeHostnames: ['www.youtube.com', 'player.vimeo.com'],
			allowedClasses: false,
		})};
	}

	return <div style={props.style} className="ql-editor" dangerouslySetInnerHTML={createMarkup()} />
}