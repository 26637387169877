import API from "../../../../Api/Api";


export default class AuditAPI extends API {
	constructor() {
		super();
	}

	getAudit(id) {
		return this.api.get(`${this.version}/audits/${id}/`);
	}

	getAudits() {
		return this.api.get(`${this.version}/audits/`);
	}

	createAudit(audit) {
		return this.api.post(`${this.version}/audits/`, audit);
	}

	updateAudit(audit) {
		return this.api.put(`${this.version}/audits/${audit.id}/`, audit);
	}

	deleteAudit(audit) {
		return this.api.delete(`${this.version}/audits/${audit.id}/`);
	}

	getNumSelectedRecords(payload) {
		return this.api.post(`${this.version}/audits/num_records/`, payload);
	}

	exportAudit(audit) {
		return this.api.post(`${this.version}/audits/${audit.id}/export/`, audit);
	}

	getJobStatus(jobId) {
		return this.api.post(`${this.version}/audits/export_job_status/`, { job_id: jobId }, { responseType: 'json' });
	}

	getJobResult(jobId) {
		return this.api.get(`${this.version}/audits/job_result/?job_id=${jobId}`, { responseType: 'arraybuffer' });
	}
	
}