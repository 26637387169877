import React, { useEffect, useState } from "react";

import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import useDesktop from "hooks/useDesktop";

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '0px solid white',
        backgroundColor: 'rgba(0, 0, 0, 0.055)',
      },
    },
  },
})(TextField);

export default function Search(props) {
    const [searchText, setSearchText] = useState(""); 

    const userOnDesktop = useDesktop();

    useEffect(() => {
        let filteredResults;

        if (props.filterFunc) {
            props.filterFunc(searchText);
        }
        else {
            filteredResults = filterIt(props.searchData, searchText, props.searchKeys);
            props.setSearchResults(filteredResults);
        }

    }, [searchText, props.searchData]);

    return (
        <CssTextField 
            fullWidth={props.fullWidth}
            autoFocus={false}
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            placeholder={"Search"}
            variant='outlined'
            size="small"
            style={{...props.style}}
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <Tooltip title={"Search"}>
                        <SearchIcon fontSize="small" aria-label="search" />
                    </Tooltip>
                </InputAdornment>
                ),
                endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                        disabled={!searchText}
                        onClick={() => (setSearchText(""))}
                        aria-label={'clear-search'}
                    >
                    <ClearIcon
                        fontSize="small"
                        aria-label="clear-search-icon"
                    />
                    </IconButton>
                </InputAdornment>
                ),

                style: {
                //...props.searchFieldStyle,
                border: '0px solid white'
                },

                inputProps: {
                "aria-label": "Search",
                },
            }}
        />
    )
}

function filterIt(arr, searchKey, allowedSearchKeys) {
    return arr.filter(obj => {
        return Object.keys(obj).filter(key => {
            return allowedSearchKeys.includes(key)
        }).some(key => obj[key].toLowerCase().includes(searchKey.toLowerCase()))
    });
}