import React, { useState, useEffect } from 'react';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Traceability from "../Traceability";
import IconButton from '@material-ui/core/IconButton';
import Message from "../../Components/Message";
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import SkuFields from "../FormulationSkus/SkuFields"
import {StylesContext} from "../../App";
import TraceabilityUtils from "../TraceabilityUtils";
import PropTypes from "prop-types";

import Card from "@material-ui/core/Card";

import BulkLogo from '../FormulationSkus/weight.svg';
import PrepackagedLogo from '../FormulationSkus/inventory_2_black_24dp.svg';

import { makeStyles } from "@material-ui/core/styles";



const EMPTY_MESSAGE = {
  open: false,
  message: '',
  status: 'info',
};

const useStyles = makeStyles({
  root: {
    '&:hover': {
      border: '1px solid #18BFF6',
    }   
  }
});

export default function SKUForm(props) {
  const classes = React.useContext(StylesContext);
  const localClasses = useStyles();

  const utils = new TraceabilityUtils();
  const [sku, setSKU] = useState(props.sku ? props.sku : {});
  const [isBlocking, setIsBlocking] = useState(false);
  const [message, setMessage] = useState({ ...EMPTY_MESSAGE });
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [unitsSelected, setUnitsSelected] = useState([]);
  const [lockUpdate, setLockUpdate] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (!props.sku || sku == {} || lockUpdate) {
      return;
    }

    let newSku = { ...props.sku };

    if (newSku.pallets_based_on_case) {
      newSku.pallet_unit_type = 'Case';
    }
    else {
      newSku.pallet_unit_type = 'Unit';
    }

    if (newSku.single_unit_value) {
      let skuUnitObj = props.units.find(unit => unit.pk == newSku.unit_type);
      newSku.single_unit_value = newSku.single_unit_value / skuUnitObj.ratio_to_base;
    }

    setSKU(newSku);

  }, [props.sku])

  function validateForm() {
    setSubmitAttempted(true);
    if(!sku.sku_type) {
      setMessage({ open: true, message: 'SKUS require a type.', status: 'error' });
      return false;
    }

    if (!sku.name) {
      setMessage({ open: true, message: 'Please enter a name.', status: 'error' });
      return false;
    }

    if (!sku.sku_id) {
      setMessage({ open: true, message: 'Please enter a SKU ID.', status: 'error' });
      return false;
    }

    if (!sku.unit_type && !sku.unit_type_placeholder) {
      setMessage({ open: true, message: 'Please choose a Unit Type.', status: 'error' });
      return false;
    }

    if(sku.sku_type == 'BULK') {
      //nothing for this yet.
    }

    if(sku.sku_type == 'PREP') {
      if (!sku.unit_value) {
        setMessage({ open: true, message: 'Please enter a value for a single unit of this SKU.', status: 'error' });
        return false;
      }

      if (!sku.single_unit_type) {
        setMessage({ open: true, message: 'Please choose a Unit Type. for a single unit of this SKU.', status: 'error' });
        return false;
      }

      if (sku.pallet_unit_value && !sku.case_unit_value) {
        setMessage({ open: true, message: 'Must specify units per case if specifying case per pallet.', status: 'error' });
        return false;
      }
    }

    return true;
  }

  function handleSubmit(saveAndContinue) {
    setSubmitting(true);
    if (!validateForm()) {
      setSubmitting(false);
      return;
    }

    /*let unitSelectedPks = unitsSelected.map((unit) => {
      return unit.pk;
    })*/

    let skuObject = { ...sku};
    //skuObject.single_unit_type = skuObject.unit_type
    //todo back end ingredient auto making.

    const relatedIngredient = props.ingredients.find((ingredient) => ingredient.name == skuObject.ingredient_name);

    if(!relatedIngredient) {
      let shouldContinue = window.confirm('This will add the ingredient ' + skuObject.ingredient_name + ' to the system. Continue?');

      if(!shouldContinue) {
        setSubmitting(false);
        return;
      }
    }

    skuObject.pallets_based_on_case = false;
    skuObject.single_unit_value = skuObject.unit_value;
    //skuObject.single_unit_type = formulaPayload.sku.unit_type;

    if (skuObject.case_unit_value) {
      skuObject.case_unit_value = skuObject.case_unit_value * skuObject.single_unit_value;
    }

    if (skuObject.pallet_unit_value && skuObject.case_unit_value) {
      skuObject.pallet_unit_value = skuObject.pallet_unit_value * skuObject.case_unit_value;
    }

    let organization = JSON.parse(localStorage.getItem("organization"));
    skuObject.organization = organization.pk;
    skuObject.traceability = props.activeTraceability.pk;

    const api = new Traceability().getSkuApi();

    setLockUpdate(true);
    if (skuObject.pk) {
      api.updateSku(skuObject).then(response => {
        let newSku = { ...response.data };
        if (props.setShouldClose) {
          props.setShouldClose(true);
        }

        if (newSku.pallets_based_on_case) {
          newSku.pallet_unit_type = 'Case';
        }
        else {
          newSku.pallet_unit_type = 'Unit';
        }
        
        //let skuUnitObj = props.units.find(unit => unit.pk == newSku.unit_type);
        //newSku.single_unit_value = newSku.single_unit_value / skuUnitObj.ratio_to_base;//todo pull units

        setSKU({...newSku, type: 'SKU'});
        //setSKU({...response.data});
        setSubmitAttempted(false);
        if (props.onSave) {
          props.onSave(response, {...newSku, type: 'SKU'}, saveAndContinue);
        }
        setSubmitting(false);
        setMessage({ open: true, message: 'Saved Successfully', status: 'success' });
      }).catch(error => {
        setSubmitting(false);
        setMessage({ open: true, message: JSON.stringify(error.response.data).replace('{', '').replace('}', ''), status: 'error' });
      });
    }
    else {
      api.createSku(skuObject).then(response => {
        if (props.setShouldClose) {
          props.setShouldClose(true);
        }
        let newSku = { ...response.data };

        if (newSku.pallets_based_on_case) {
          newSku.pallet_unit_type = 'Case';
        }
        else {
          newSku.pallet_unit_type = 'Unit';
        }


        //let skuUnitObj = props.units.find(unit => unit.pk == newSku.unit_type);
        //newSku.single_unit_value = newSku.single_unit_value / skuUnitObj.ratio_to_base;
        setSKU({...newSku, type: 'SKU'});
        //setSKU(response.data);
        setSubmitAttempted(false);
        if (props.onSave) {
          props.onSave(response, {...newSku, type: 'SKU'}, saveAndContinue);
        }
        setSubmitting(false);
        setMessage({ open: true, message: 'Saved Successfully', status: 'success' });
      }).catch(error => {
        setSubmitting(false);
        setMessage({ open: true, message: JSON.stringify(error.response.data).replace('{', '').replace('}', ''), status: 'error' });
      });
    }
  }

  function handleSaveAndContinue() {
    setIsBlocking(false);

    if (props.cancel) {
      props.cancel();
    }
  }

  function handleTextFieldChange(event) {
    setIsBlocking(true);
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }
    const property = event.target.name;
    const value = event.target.value;

    setSKU({ ...sku, [property]: value });
  }

  function cancel() {
    setSKU(props.sku ? props.sku : {});

    setIsBlocking(false);
    if (props.setShouldClose) {
      props.setShouldClose(true);
    }
  }

  function handleDelete() {
    let skuName = sku.name || sku.sku_id || 'this SKU';

    if(!window.confirm('Are you sure you want to delete ' + skuName + '?')){
      return;
    }

    if(!sku || !sku.pk) {
      props.closeModal ? props.closeModal() : cancel();
    }
    //let pkToDelete = customer.pk;
    const skuAPI = new Traceability().getSkuApi();

    skuAPI.softDeleteSku(sku).then(response => {
      
      if (props.onDelete) {
        props.onDelete(response, {pk: sku.pk, type: 'SKU', soft_delete: true});
      }
      setMessage({ open: true, message: 'Deleted Successfully', status: 'success' });
      props.closeModal();
    }).catch(error => {
      setMessage({ open: true, message: 'Server Error: ' + utils.formatError(error), status: 'error' });
    });
  }

  return (
    <form onSubmit={handleSubmit} style={{height: '100%'}}>
      { (!sku.sku_type || sku.sku_type == '') &&
      
        <Grid container alignItems="center" justify="center" style={{width: '100%', height: '75%'}}>
          <Grid item xs={12}>
            <Grid item container xs={12} alignItems="center" justify="center" style={{paddingBottom: '36px'}}>
              <Typography style={{fontWeight: 500, fontSize: '34px', textAlign: 'center', cursor: 'default'}}>
                Choose a type for your new SKU 
              </Typography>
            </Grid>
            <Grid item container xs={12} alignItems="center" justify="center">
              <Card elevation={1} style={{width: '260px', height: '260px', margin: '8px'}} classes={{root: localClasses.root}} onClick={() => { setSKU({ ...sku, sku_type: 'PREP' }) }}>
                <Grid container alignItems="center" justify="center"  style={{height: '100%'}}>
                  <Grid item container justify="center" alignItems="center" xs={12}>
                    <Grid item container justify="center" alignItems="center" xs={12} style={{paddingBottom: '26px'}}>
                      <img src={PrepackagedLogo} width="60" height="60"></img>
                    </Grid>
                    <Grid item container xs={12} alignItems="center" justify="center" style={{padding: '0px 8px 8px 8px'}}>
                      <Typography style={{fontWeight: 700, fontSize: '16px', textAlign: 'center', cursor: 'default'}}>PRE-PACKAGED</Typography>
                    </Grid>
                    <Grid item container xs={12} alignItems="center" justify="center" style={{maxWidth: '175px'}}>
                      <Typography style={{opacity: 0.50, textAlign: 'center', fontSize: '12px', fontWeight: 400, cursor: 'default'}}>
                        Pre-packaged SKUs are ordered by single units or cases
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Card> 
              <Card elevation={1} style={{width: '260px', height: '260px', margin: '8px'}} classes={{root: localClasses.root}} onClick={() => { setSKU({ ...sku, sku_type: 'BULK' }) }}>
                <Grid container alignItems="center" justify="center" style={{height: '100%'}}>
                  <Grid item container xs={12} justify="center" alignItems="center" xs={12} >
                    <Grid item container justify="center" alignItems="center" xs={12} style={{paddingBottom: '26px'}}>
                      <img src={BulkLogo} width="60" height="60"></img>
                    </Grid>
                    <Grid item container xs={12} alignItems="center" justify="center" style={{padding: '0px 8px 8px 8px'}}>
                      <Typography style={{fontWeight: 700, fontSize: '16px', textAlign: 'center', cursor: 'default'}}>BULK</Typography>
                    </Grid>
                    <Grid item container xs={12} alignItems="center" justify="center">
                      <Typography style={{opacity: 0.50, textAlign: 'center', fontSize: '12px', fontWeight: 400, maxWidth: '175px', cursor: 'default'}} >
                        Bulk SKUs are ordered by weight or volume units
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid> 
              </Card>
            </Grid>
          </Grid>
        </Grid>
      }
      { sku.sku_type && 
        /* Header Section */
        <>
          <Grid container spacing={3} className={classes.generalContainerGridHead}>
            <Grid item xs={11}>
              <Typography variant="h6" noWrap className={classes.generalFormHeaderTypography}>
                Edit SKU
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton style={{ float: 'right' }} onClick={props.closeModal}>
                <CancelIcon style={{ float: 'right' }} />
              </IconButton>
            </Grid>
          </Grid>

          <Grid container spacing={3} className={classes.generalContainerGridBody} style={{marginTop: '24px'}}>
            <Grid item container xs={12} alignItems="center" justify="left">
              <Typography variant="h5" noWrap>
                SKU Details
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid container spacing={3} style={{marginTop: '8px'}}>
              <Paper elevation={0} className={classes.generalFormPaperContainerSkuFields}>
                <Grid item container xs={12} alignItems="center" justify="center">
                  <SkuFields
                    sku={sku}
                    setSku={setSKU}
                    units={props.units}
                    setShouldClose={props.setShouldClose}
                    suppliers={props.suppliers}
                    ingredients={props.ingredients}
                    inventoryLocations={props.inventoryLocations}
                    submitAttempted={submitAttempted}
                  />
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <Paper elevation={0} square className={classes.generalFormPaperStickyFooter}>
            <Grid container spacing={3} className={classes.generalContainerGridFoot}>
              <Grid item container xs={12} alignItems="center" justify="flex-end">
                {sku.pk &&
                  <Button
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                    style={{ marginLeft: "8px" }}
                    onClick={() => {handleDelete()} }
                    disabled={submitting}
                  >
                    Delete
                  </Button>
                }
                <Button
                  //variant="outlined"
                  color="secondary"
                  style={{ marginLeft: "8px" }}
                  onClick={props.closeModal ? props.closeModal : cancel}
                  disabled={submitting}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: "8px" }}
                  onClick={() => { handleSubmit(false) }}
                  disabled={submitting}
                >
                  Save Changes
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: "8px" }}
                  onClick={() => { handleSubmit(true) }}
                  disabled={submitting}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </>
      }

      <Message
        open={message.open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="left"
        handleClose={() => { setMessage({ ...message, open: false }) }}
      />

    </form>
  )
}

SKUForm.propTypes = {
  sku: PropTypes.object,
  units: PropTypes.objectOf(PropTypes.object),
  formulations: PropTypes.objectOf(PropTypes.object),
  weightOptions: PropTypes.arrayOf(PropTypes.string),
  volumeOptions: PropTypes.arrayOf(PropTypes.string),
  handleSubmit: PropTypes.func,
  user: PropTypes.object.isRequired,
  activeTraceability: PropTypes.object.isRequired,
  setShouldClose: PropTypes.func,
  onSave: PropTypes.func,
  closeModal: PropTypes.func,
  cancel: PropTypes.func,
}