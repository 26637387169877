import React, {useEffect, useState, useContext} from "react";
import { useHistory, useRouteMatch, Route, Switch } from "react-router-dom";
import { OrgContext } from "hooks/useOrganization"

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import useDesktop from "hooks/useDesktop";
import MobileMyTasks from "./MobileMonitoringTasks";
import TabbableGroupedTable from "Components/GroupedTable/TabbableGroupTable";

import EventMonitoringForm from "Monitoring/Events/EventRecordForm";
import API from "Api/Api";
import { Typography } from "@material-ui/core";

const statuses = {
  counted: false,
  column: 'status',
  tabs:[
    {title: 'Due', value: 'DUE'},
    {title: 'Upcoming', value: 'UPCOMING'},
    {title: 'Completed', value: 'COMPLETE'},
  ]
}

const columns = [
  { title: "Event Name", field: "event_name"},
  { title: "Due", field: "days_due"},
  { title: "Status", field: "formatted_status"},
  { title: "Assigned", field: "responsible"},
  { title: "Added", field: "added"},
  { title: "Completed", field: "completed"},
];

export default function MonitoringTasks(props) {
  const [allTasks, setAllTasks] = useState([]);
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const match = useRouteMatch();

  const org = useContext(OrgContext); 

  useEffect(() => {
    getAllTasks();  
  }, []);
  
  function getAllTasks() {
    let monitoringApi =  new API().getMonitoringAPI()
    if (props.myTasks) {
      // monitoringApi.getMyTasks().then(response => {
      //   setAllTasks(response.data);
      //   setLoading(false);
      // }).catch(error => {
      //   console.log(error);
      //   setLoading(false);
      // });
      monitoringApi.getMyIncompleteTasks().then(response => {
        setAllTasks(response.data.results);
        setLoading(false);
        monitoringApi.getMyCompletedTasks().then(res => {
          setAllTasks([...response.data.results,...res.data.results]);
        }).catch(error => {
          console.log(error);
          setLoading(false);
        })

      }).catch(error => {
        console.log(error);
        setLoading(false);
      })
    }
    else if (props.allTasks) {
      monitoringApi.getTeamIncompleteTasks().then(response => {
        setAllTasks(response.data.results);

        setLoading(false);
        monitoringApi.getTeamCompletedTasks().then(res => {
          setAllTasks([...response.data.results,...res.data.results]);
        }).catch(error => {
          console.log(error);
          setLoading(false);
        })

      }).catch(error => {
        console.log(error);
        setLoading(false);
      })
    }
  }

  function getActionButtonName(rowData) {
    if (rowData.status === "COMPLETE" || rowData.status === "DEVIATION") {
      return "View";
    }
    else {
      return "Open";
    }
  }
  const getTitle = () => {
    if (!props.allTasks) {
      const first_name = props.user.user.first_name;
      if (first_name) {
        return `${first_name}'s Tasks`;
      } else {
        return "My Tasks";
      }
    }
    return "All Tasks";
  }

  let title = getTitle();

  let onDesktop = useDesktop();

  return (
    <>
      <>
        {loading &&
          <Grid container alignItems="center" justify="center" style={{width: '100%', height: '100%'}}>
            <Grid container item xs={12} alignItems="center" justify="center">
              <CircularProgress />
            </Grid>
          </Grid>
        }

        {props.user && !onDesktop && !loading && 
          <MobileMyTasks user={props.user} tasks={allTasks} title={title} searchKeys={['event_name', 'responsible']} />
        }

        {props.user && onDesktop && !loading &&
          <TabbableGroupedTable
            noMinWidth={true}
            tabStatuses={statuses}
            loading={loading}
            columns={columns}
            data={allTasks}
            components={{
              Container: props => <Paper {...props} elevation={0} />
            }}
            title={title}
            emptyDataMessage="You have no monitoring tasks."
            actions={ 
              [
                rowData => ({
                  icon: (props) => {
                    return (
                      <div style={{padding:"4px", paddingTop: "0px", paddingBottom: "0px", background: "#18bff6", borderRadius: "4px"}}>
                        <Typography style={{margin: '4px', color: "white", fontWeight: "400", lineHeight: "1.5rem", fontFamily: "Roboto"}}>
                          { getActionButtonName(rowData) }
                        </Typography>
                      </div>
                    )
                  },
                  onClick: (event, rowData) => history.push(`/monitoring/${rowData.id}/`),
                }),
                
              ]
            }

            onRowClick={(event, rowData) => {
              history.push(`${match.path}/monitoring/${rowData.id}/`);
            }}
          />
        }
      </>
      <Switch>
        <Route path={`${match.path}/monitoring/:pk/`}>
          <EventMonitoringForm onComplete={() => getAllTasks()} user={props.user} />
        </Route>
      </Switch>
    </>

  )
}
