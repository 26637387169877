import React, { useEffect, useState, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import API from "../../Api/Api";
import VerticalDotMenu from "../../Components/VerticalDotMenu";
import Message from "../../Components/Message";
import Divider from "@material-ui/core/Divider";
import { Dialog, DialogContent} from "@material-ui/core";
import ProcessDatasetTable from "./ProcessDatasetTable";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import { OrgContext } from "hooks/useOrganization"
import useDesktop from "../../hooks/useDesktop";

function Process(props) {
  const [process, setProcess] = useState(null);
  const [shouldClose, setShouldClose] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const api = new API();
  const onDesktop = useDesktop();

	const org = useContext(OrgContext);

  function getProcessAPI() {
    if (props.templateMode) {
      return api.getProcessTemplateAPI();
    }

    return api.getProcessAPI();
  }

  const [ccps, setCCPs] = useState([]);
  const [isNewProcess, setIsNewProcess] = useState(props.pk != null);

  useEffect(() => {
    let pk = props.pk;
    setLoading(true);

    if (pk != null) {
      setIsNewProcess(false);
      getProcess(props.pk);
      getProcessAPI()
        .getCCPs(props.pk)
        .then((response) => {
          setCCPs(response.data);
        });
    } else {
      setProcess({
        name: "",
        description: "",
        haccp_plan: props.haccpPlan,
        organization: props.organization,
      });
      setIsNewProcess(true);
      setLoading(false);
    }
  }, [props.pk]);

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState({ message: "", status: "info" }); // error, warning, info, info
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function setReturnStatus(message, status) {
    setMessage({ message: message, status: status });
    setOpen(true);
  }

  function getProcess(pk) {
    getProcessAPI()
      .retrieveProcess(pk)
      .then((e) => {
        setProcess(e.data);
        setLoading(false);
      })
      .catch((e) => {
        setReturnStatus("Could not contact server.", "error");
      });
  }

  function handleChange(event) {
    setShouldClose(true);
    if (props.setShouldClose) {
      props.setShouldClose(true);
    }
    const value = event.target.value;
    setProcess({ ...process, [event.target.name]: value });
  }

  function handleSubmit(e) {
    if (!process.name) {
      setFieldError(true);
      setReturnStatus("Please Enter All Fields", "info");
    } else {
      setFieldError(false);
      setShouldClose(false);
      if (props.setShouldClose) {
        props.setShouldClose(false);
      }
      e.preventDefault();
      if (!isNewProcess) {
        setReturnStatus("Process Saved!", "info");
      }
      if (props.save) {
        props.save(process);
      } else {
        updateProcess(process);
      }
    }
  }

  async function save(e) {
    setShouldClose(false);

    if (props.setShouldClose) {
      props.setShouldClose(false);
    }

    if (isNewProcess) {
      await addNewProcess();
    } else {
      await updateProcess(process);
    }
    if (props.save) {
      props.save(process);
    }
  }

  async function saveAndContinue(e) {
    if (!process.name) {
      setFieldError(true);
      setReturnStatus("Please enter all fields", "info");
    } else {
      setFieldError(false);
      setShouldClose(false);

      if (props.setShouldClose) {
        props.setShouldClose(false);
      }

      if (isNewProcess) {
        if (props.save) {
          props.save({...process, haccp_plan: props.haccpPlanPk});
        }
      } else {
        await updateProcess(process);
      }

      if (!isNewProcess) {
        if (props.saveAndContinue) {
          setProcess(null);
					handleCFIADatabaseClosed();
          props.saveAndContinue(process);
        }
      }
    }
  }

  async function addNewProcess(processSelected=null) {
		let newProcess;
		if (processSelected) {
			newProcess = {
      	...processSelected,
      	haccp_plan: props.haccpPlanPk,
      	organization: org.pk,
    	};
		} else {
			newProcess = {
				...process,
				haccp_plan: props.haccpPlanPk,
				organization: org.pk,
			};
		}
    
    return api
      .getProcessAPI()
      .createProcess(newProcess)
      .then((response) => {
        setProcess(response.data);
        setIsNewProcess(false);
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function cancel() {
    setShouldClose(false);
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }

    if (props.cancel) {
      props.cancel();
    }
  }

  async function updateProcess(updatedProcess) {
    return getProcessAPI()
      .updateProcess(updatedProcess)
      .then((e) => {
        setProcess(e.data);
      })
      .catch((e) => {
        setReturnStatus("Could not update process.", "error");
      });
  }

  window.onbeforeunload = function (e) {
    if (shouldClose) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  const [cfiaDatabaseOpen, setCFIADatabaseOpen] = useState(false);

  function handleCFIADatabaseClosed() {
    setCFIADatabaseOpen(false);
  }

  function handleCFIADatabaseOpen() {
    setCFIADatabaseOpen(true);
  }

  async function selectCFIAProcessAndHazards(processSelected, hazardsSelected) {
    if (processSelected == null) {
      return;
    }

    setLoading(true);

		let newProcess = {
			...processSelected,
			haccp_plan: props.haccpPlanPk,
			organization: org.pk,
		};

    if (isNewProcess) {
      new API().getProcessAPI().createProcess(newProcess).then((response) => {
        let process = response.data;
        let processDataset = { ...processSelected };

        getProcessAPI()
          .selectDatasetProcess(process, processDataset, hazardsSelected)
          .then((response) => {
            handleCFIADatabaseClosed();
            setProcess(response.data);
						setIsNewProcess(false);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      });
    } else {
      let processDataset = { ...processSelected };

      getProcessAPI()
        .selectDatasetProcess(process, processDataset, hazardsSelected)
        .then((response) => {
          handleCFIADatabaseClosed();
          setProcess(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }

  const [loading, setLoading] = useState(true);

  return (
    <div>
      {process && !loading && (
        <form onSubmit={handleSubmit} style={{overflow: "hidden"}}  >
          <Grid container style={{ padding: "16px", overflow: "hidden"}} spacing={3}>
            <Grid item xs={10}>
              <Typography variant="h5" noWrap>
                Process {props.templateMode && "Template"}
              </Typography>
              Edit/view this process.
            </Grid>

            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
              {!props.deleteMode && (
                <IconButton
                  style={{ float: "right" }}
                  onClick={props.closeModal ? props.closeModal : cancel}
                >
                  <HighlightOffOutlinedIcon style={{ float: "right" }} />
                </IconButton>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            {props.loadProcessCCPs && ccps.length > 0 && props.deleteMode && (
              <Grid item xs={12}>
                <Button
                  onClick={props.loadProcessCCPs}
                  variant="contained"
                  color="primary"
                >
                  Add CCP names to diagram
                </Button>
              </Grid>
            )}
            {!props.deleteMode && (
              <Grid item xs={6}>
                <Button
                  onClick={handleCFIADatabaseOpen}
                  variant={"contained"}
                  color={"primary"}
                >
                  Add from CFIA Database
                </Button>
                <Dialog
                  open={cfiaDatabaseOpen}
                  onClose={handleCFIADatabaseClosed}
                  maxWidth={onDesktop ? "lg" : false}
                  fullScreen={!onDesktop}
                >
                  <DialogContent>
                    <ProcessDatasetTable
                      selectProcess={selectCFIAProcessAndHazards}
                      setCFIADatabaseOpen={setCFIADatabaseOpen}
                      handleCFIADatabaseClosed={handleCFIADatabaseClosed}
                    />
                  </DialogContent>
                </Dialog>
              </Grid>
            )}
            <Grid item xs={11}>
              <Typography variant="h6">Details</Typography>
              <Typography>Enter information about this process.</Typography>
            </Grid>
            <Grid item xs={1}>
              {!props.deleteMode && (
                <VerticalDotMenu
                  style={{ float: "right" }}
                  api={api.getProcessAPI()}
                  setProcess={setProcess}
                  save={props.save}
                  updateProcess={updateProcess}
                  instance={process}
                  template_title="Select a process template from the list below."
                  template_cols={[
                    { title: "Name", field: "name" },
                    { title: "Description", field: "description" },
                  ]}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Name"
                name="name"
                variant="outlined"
                disabled={props.deleteMode ? true : false}
                error={process.name ? false : fieldError}
                value={process.name}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                name="description"
                disabled={props.deleteMode ? true : false}
                variant="outlined"
                value={process.description}
                multiline
                rows={3}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            {!props.deleteMode && (
              <Grid item xs={12}>
                <div style={{ float: "right" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ margin: "8px" }}
                    onClick={cancel}
                  >
                    Cancel
                  </Button>
                  <Button
										disabled={isNewProcess}
                    variant="contained"
                    color="primary"
                    style={{ margin: "8px" }}
                    onClick={handleSubmit}
                  >
                    Save Changes
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ margin: "8px" }}
                    onClick={saveAndContinue}
                  >
                    Save and Continue
                  </Button>
                </div>
              </Grid>
            )}
          </Grid>
        </form>
      )}

      {loading && <CircularProgress />}

      {/*<Dialog*/}
      {/*  open={clearHazardsDialog}*/}
      {/*  onClose={handleDeleteDialogClose}*/}
      {/*  aria-labelledby="alert-dialog-title"*/}
      {/*  aria-describedby="alert-dialog-description"*/}
      {/*>*/}
      {/*  <DialogTitle id="alert-dialog-title">Are you sure you want to delete this process? </DialogTitle>*/}
      {/*  <DialogContent>*/}
      {/*    <DialogContentText id="alert-dialog-description">*/}
      {/*      This action will save your diagram and cannot be reversed.*/}
      {/*    </DialogContentText>*/}
      {/*  </DialogContent>*/}
      {/*  <DialogActions>*/}
      {/*    <Button onClick={handleDeleteDialogClose} color="primary">*/}
      {/*      Cancel*/}
      {/*    </Button>*/}
      {/*    <Button onClick={deleteSelectedProcess} color="secondary" autoFocus>*/}
      {/*      Delete*/}
      {/*    </Button>*/}
      {/*  </DialogActions>*/}
      {/*</Dialog>*/}

      <Message
        position={true}
        open={open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="right"
        handleClose={handleClose}
      />
    </div>
  );
}

export default Process;
