class TaskAPI {

    constructor(api, version) {
      this.api = api;
      this.version = version;
    }
  
    listTasks(haccp_plan_pk) {
      if (typeof haccp_plan_pk === 'number') {
        return this.api.get(`${this.version}/task/?haccp_plan_pk=${haccp_plan_pk}`);
      }
  
      return this.api.get(`${this.version}/task/`)
    }
  
    listTasksMinimizedJoins(haccp_plan_pk) {
      if (typeof haccp_plan_pk === 'number') {
        return this.api.get(`${this.version}/task/get_min_list/?haccp_plan_pk=${haccp_plan_pk}`);
      }
  
      return this.api.get(`${this.version}/task/get_min_list/`)
    }
  
    getAvailableTasks() {
      return this.api.get(`${this.version}/task/available/`);
    }

    createTask(payload) {
      return this.api.post(`${this.version}/task/`, payload);
    }
  
    retrieveTask(pk) {
      return this.api.get(`${this.version}/task/${pk}/`);
    }
  
    updateTask(payload) {
      return this.api.put(`${this.version}/task/${payload.id}/`, payload);
    }
  
    deleteTask(pk) {
      return this.api.delete(`${this.version}/task/${pk}/`);
    }
  
    addHazardToTask(Task_pk, hazard) {
      return this.api.post(`${this.version}/task/${Task_pk}/add_hazard/`, hazard);
    }
  
    removeHazardFromTask(Task_pk, hazard) {
      return this.api.post(`${this.version}/task/${Task_pk}/remove_hazard/`, hazard);
    }
  
    getHistory(pk) {
      return this.api.get(`${this.version}/task/${pk}/get_history/`);
    }
  
    getCCPs(pk) {
      return this.api.get(`${this.version}/task/${pk}/get_ccps/`);
    }
  
    getRevertedTask(pk, date) {
      return this.api.post(`${this.version}/task/${pk}/get_reverted_Task/`, date);
    }
  }
  
  
  export default TaskAPI;
  
