import React, { useState, useEffect } from "react"
import { DialogContent } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import API from "../../Api/Api";
import Divider from "@material-ui/core/Divider";
import IngredientList from "../Ingredients/IngredientList";
import { withStyles } from "@material-ui/core/styles";

const DarkerDisabledTextField = withStyles({
    root: {
        marginRight: 8,
        "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.7)" // (default alpha is 0.38)
        }
    }
})(TextField);
function FormulationModal(props) {

    let api = new API();

    function modelClose() {
        props.handleModalClosed();
    }

    function getAPI() {
        return api.getFormulationsAPI();
    }

    function save(formulation) {
        getAPI().updateFormulation(formulation).then(e => {
            props.setFormulation(e.data);
            props.handleModalClosed();
            props.setStatus("Updated Formulation!", "info");
        }).catch(e => {
            console.log("error", e);
        });
    }

    return (
        <Dialog
            maxWidth="md"
            open={props.open}
            onClose={props.handleModalClosed}
        >
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <form>
                            <Grid container spacing={3}>
                                <Grid item xs={11}>
                                    <Typography variant="h5" noWrap>
                                        Old Formulation
                                     </Typography>
                                      View this formulation.
                                 </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField label="Name" disabled name="name" variant="outlined" fullWidth
                                        value={props.beforeFormulation.name}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DarkerDisabledTextField label="Description" disabled name="description" variant="outlined"
                                        fullWidth value={props.beforeFormulation.description} multiline rows={3}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <DarkerDisabledTextField label="Weight" disabled name="weight" variant="outlined" fullWidth
                                        value={props.beforeFormulation.weight}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <DarkerDisabledTextField label="Volume" disabled name="volume" variant="outlined" fullWidth
                                        value={props.beforeFormulation.volume}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => save(props.beforeFormulation)}
                        >
                            Revert
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <form>
                            <Grid container spacing={3}>
                                <Grid item xs={11}>
                                    <Typography variant="h5" noWrap>
                                        Current Formulation
                                     </Typography>
                                      View this formulation.
                                 </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField label="Name" disabled name="name" variant="outlined" fullWidth
                                        value={props.oldFormulation.name}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <DarkerDisabledTextField label="Description" disabled name="description" variant="outlined"
                                        fullWidth value={props.oldFormulation.description} multiline rows={3}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <DarkerDisabledTextField label="Weight" disabled name="weight" variant="outlined" fullWidth
                                        value={props.oldFormulation.weight}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <DarkerDisabledTextField label="Volume" disabled name="volume" variant="outlined" fullWidth
                                        value={props.oldFormulation.volume}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={modelClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>

        </Dialog >
    )
}
export default FormulationModal;
