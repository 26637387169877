import React, { forwardRef, useEffect, useState } from "react";

import MaterialTable from "material-table";
import API from "../../Api/Api";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import UnitsForm from "./UnitsForm";
import DialogContent from "@material-ui/core/DialogContent";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Traceability from "../Traceability";
import Message from "../../Components/Message";
import PropTypes from "prop-types";
import {StylesContext} from "../../App";

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Slide from '@material-ui/core/Slide';
import ReceiptIcon from '@material-ui/icons/Receipt';

//Done so that storybook has the correct symbols
const tableIcons = {
  Add: AddBox,
  Check: Check,
  Clear: Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit: Edit,
  Export: MoreVertIcon,
  Filter: FilterList,
  FirstPage: FirstPage,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search: Search,
  SortArrow: ArrowUpward,
  ThirdStateCheck: Remove,
  ViewColumn: ViewColumn
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EMPTY_MESSAGE = {
  open: false,
  message: '',
  status: 'info',
};

export default function UnitList(props) {
  const classes = React.useContext(StylesContext);

  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [unitModalOpen, setUnitModalOpen] = useState(false);
  const [shouldClose, setShouldClose] = useState(true);
  const [unitSelected, setUnitSelected] = useState(-1);
  const [message, setMessage] = useState( {...EMPTY_MESSAGE} );
  const [user, setUser] = useState(props.user);
  const [baseUnits, setBaseUnits] = useState({VOL: null, WEI: null})

  useEffect(() => {
    if(loading) {
      return;
    }

    setUnitModalOpen(true);
  }, [unitSelected])

  useEffect(() => {
    const api = new Traceability().getUnitsAPI();
    const authApi = new API().getAuthAPI();

    authApi.getAuthedProfile().then(e => {
      setUser(e.data);
    }).catch(e => {
      console.log(e);
    })

    if(props.units) {
      let unitObjects = props.units;

      setUnits(unitObjects);
      setLoading(false);
      return;
    }

    api.listGlobalUnits().then(e => {
      let unitObjects = e.data;

      let newBase = {...baseUnits}
      unitObjects.forEach((unit) => {
        if(unit.is_base){
          newBase[unit.unit_type] = unit.full_name;
        }
      });
      setBaseUnits({...newBase});

      setUnits(unitObjects);
      setLoading(false);

    }).catch(e => {
      console.log(e);
    })

  }, []);

  const unitColumns = [
    { title: "Type", field: "unit_type", defaultGroupOrder: 0},
    { title: "Name", field: "full_name" },
    { title: "Abbreviation", field: "abbreviation" },
    //{ title: "Spoilage", field: "spoilage", type: 'numeric' },
    { title: "Ratio to Base Unit", field: "ratio_to_base", type: "numeric"},
    { title: "Base Unit?",  field: "is_base", type: 'boolean' },
  ];

  function addUnit() {
    //it needs to be different to register, so just flip it back and forth.
    setUnitSelected(unitSelected == -1 ? -2 : -1);
  }

  function handleUnitClosed() {
    if (shouldClose) {
      setUnitModalOpen(false);
    } else {
      alert("Save changes before closing.")
    }
  }

  function cancel() {
    setShouldClose(true);
    setUnitModalOpen(false);
  }

  /**
   * Handles keeping the list up to date with the back end, includingupdating all units.
   * 
   * @param {JSON} response - a response object from the server
   */
  function onSave(response) {
    const api = new Traceability().getUnitsAPI();
    api.listUnits().then(e => {
      let unitObjects = e.data;
  
      setUnits(unitObjects);
      setLoading(false);

      setMessage({
        open: true,
        message: 'Saved Successfully',
        status: 'success',
      });
  
    }).catch(e => {
      console.log(e);
    })
  }

  function deleteUnit(unit, resolve, reject) {
    const api = new Traceability().getUnitsAPI();

    api.deleteUnit(unit.pk).then(response => {
      let updatedUnits = [...units];
      let unitIndex = updatedUnits.findIndex(updatedUnit => {
        return updatedUnit.pk === unit.pk;
      });

      if (unitIndex > -1) {
        updatedUnits.splice(unitIndex, 1);
      }

      setMessage({
        open: true,
        message: 'Deleted Successfully',
        status: 'success',
      });

      setUnits(updatedUnits);
      resolve();
    }).catch(error => {
      console.log(error);
      reject();
    })
  }

  return (
    <>
      { loading &&
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginTop: "250px" }}>
            <Typography>
              <CircularProgress />
            </Typography>
          </Grid>
        </Grid>
      }

      { (!loading && units && user) && <>
        <Paper elevation={0} square className={classes.generalListPaperContainer} >
          <Grid container spacing={3}>
            <Grid item xs={12}>

              <MaterialTable
                icons={tableIcons}
                components={{
                  Container: props => <Paper {...props} elevation={0} />
                }}

                isLoading={loading} 
                title="Units of Measure" 
                columns={unitColumns} 
                data={units}

                style={{ width: "100%" }}
                localization={{ body: { editRow: { deleteText: 'Are you sure you want to delete this process? This action cannot be reversed.' } } }}

                editable={{
                  onRowDelete: (newData) => new Promise((resolve, reject) => {
                    deleteUnit(newData, resolve, reject);
                  })
                }}

                actions={
                  [
                    {
                      icon: (props) => (
                        <Button size="small" color="primary" variant="outlined" aria-label="add">
                          Add Unit of Measure
                        </Button>
                      ),
                      tooltip: 'Add Ingredient',
                      isFreeAction: true,
                      onClick: (event) => addUnit()
                    },
                  ]
                }

                onRowClick={(event, rowData, togglePanel) => {
                  if(unitSelected != rowData.tableData.id) {
                    setUnitSelected(rowData.tableData.id);
                  }
                  else {
                    setUnitModalOpen(true);
                  }
                }}

                options={{
                  actionsColumnIndex: -1,
                  //exportButton: true,
                  pageSize: 10,
                  grouping: true,
                }}
              />

            </Grid>
          </Grid>
        </Paper>

        <Dialog fullScreen open={unitModalOpen} onClose={handleUnitClosed} maxWidth={"lg"} TransitionComponent={Transition}>
          <DialogContent className={classes.generalFormDialogueContainer}>
            <UnitsForm 
              onSave={onSave}
              unit={unitSelected >= 0 ? units[unitSelected] : {spoilage: 0}}
              prepackaged={unitSelected >= 0 ? units[unitSelected].container_type == 'PREP' : false}
              setShouldClose={setShouldClose}
              closeModal={() => { setUnitModalOpen(false) }}
              cancel={cancel}
              user={user}
              activeTraceability={props.activeTraceability}
              weightBase={baseUnits.WEI}
              volumeBase={baseUnits.VOL}
              quantityBase={baseUnits.QUA}
            />
          </DialogContent>
        </Dialog>

        <Message
          open={message.open}
          message={message.message}
          severity={message.status}
          vertical="bottom"
          horizontal="right"
          handleClose={() => {setMessage( {...message, open:false} )}}
        />
      </>}
    </>
  )
}

UnitList.propTypes = {
  inventoryLocations: PropTypes.arrayOf(PropTypes.object),
  activeTraceability: PropTypes.object.isRequired,
}
