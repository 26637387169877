import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import API from "../../Api/Api";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import PropTypes from 'prop-types';
import GeneralApp404 from "../../Main/GeneralApp404"

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © FoodByte '}
            {/*<Link color="inherit" href="https://material-ui.com/">*/}
            {/*</Link>{' '}*/}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: 32,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function PasswordReset(props) {
  const classes = useStyles();
  const api = new API();
  const history = useHistory();
  const location = useLocation();
  const currentParams = useParams();

  const [email, setEmail] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [emailErrors, setEmailErrors] = useState(null);

  function handleSubmit(){
    setEmailErrors(null);
    api.getAuthAPI().resetPasswordRequest({email: email}).then(() => {
      setShowSuccess(true);
    }).catch(e => {
      setShowSuccess(false);
      setEmailErrors(e.response.data.email);
    });

  }

  function handleEmailChange(event){
    setEmail(event.target.value);
  }

  return (
    <React.Fragment>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
              <Avatar className={classes.avatar} alt="FoodByte Logo" src="logos/FB_Logo_Reversed_RGB_Icon.png"/>
              <Typography component="h1" variant="h5">
                  FoodByte
              </Typography>
              {showSuccess ? 
              <Typography component="h1" variant="h6" align="center" style={{marginTop: "20px"}}>
                 A link will be sent to your email if an account for that email is found.
             </Typography> 
              :
              <form className={classes.form}>
              <Typography align="center">
                  Enter your email below to receive a reset link if a matching account is found.
              </Typography>
                <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="email"
                      label="Email"
                      id="email"
                      autoComplete="email"
                      onChange={handleEmailChange}
                      error={emailErrors ? true : false}
                      helperText={emailErrors}
                  />

                  <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handleSubmit}
                  >
                      Reset Password
                  </Button>
              </form>}
          </div>
          <Box mt={8}>
              <Copyright />
          </Box>
      </Container>
  
    
    </React.Fragment>
  );
}

