class FProductionsAPI {
    constructor(api, version) {
      this.api = api;
      this.version = version;
    }
  
    listFProduction(id) {
      if (typeof id === 'number') {
        return this.api.get(`${this.version}/f_production/${id}/`);
      }
      return this.api.get(`${this.version}/f_production/`)
    }
  
    createFProduction(payload) {
      return this.api.post(`${this.version}/f_production/`, payload);
    }
  
    updateFProduction(payload) {
      return this.api.put(`${this.version}/f_production/${payload.id}/`, payload);
    }
  
    // deleteFProduct(id) {
    //   return this.api.put(`${this.version}/f_production/${id}/soft_delete_action/`);
    // }
  
  }
  
  export default FProductionsAPI;