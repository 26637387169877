class EventAPI {

    constructor(api, version) {
      this.api = api;
      this.version = version;
      this.endpoint = `${this.version}/events`;
    }
  
    listEvents(haccp_plan_pk) {
      if (typeof haccp_plan_pk === 'number') {
        return this.api.get(`${this.endpoint}/?haccp_plan_pk=${haccp_plan_pk}`);
      }
  
      return this.api.get(`${this.endpoint}/`)
    }

    getEventNames(haccp_plan_pk) {
      if (typeof haccp_plan_pk === 'number') {
        return this.api.get(`${this.endpoint}/get_event_names/?haccp_plan_pk=${haccp_plan_pk}`);
      }
  
      return this.api.post(`${this.endpoint}/get_event_names/`)
    }
  
    listEventsMinimizedJoins(haccp_plan_pk) {
      if (typeof haccp_plan_pk === 'number') {
        return this.api.get(`${this.endpoint}/get_min_list/?haccp_plan_pk=${haccp_plan_pk}`);
      }
  
      return this.api.get(`${this.endpoint}/get_min_list/`)
    }
  
    createEvent(payload) {
      return this.api.post(`${this.endpoint}/`, payload);
    }
  
    retrieveEvent(pk) {
      return this.api.get(`${this.endpoint}/${pk}/`);
    }
  
    updateEvent(payload) {
      return this.api.put(`${this.endpoint}/${payload.id}/`, payload);
    }

    listTasks(pk){
      return this.api.get(`${this.version}/task/?event_pk=${pk}`);
    }
    
    listLogRecords(log, page, page_size = 100, incompletedFilterApplied) {
      //return this.api.get(`${this.version}/log_records/?log=${log}&page=${page}&page_size=${page_size}`);
      return this.api.get(`${this.version}/log_records/?log=${log}&page=${page}&page_size=${page_size}${incompletedFilterApplied ?'&is_completed=False' : ''}`);
    }
    exportLogRecords(log_id) {
      return this.api.get(`${this.version}/log_records/export_csv/?log=${log_id}`);
    }

    retrieveLogRecord(id) {
      return this.api.get(`${this.version}/log_records/${id}/`)
    }

    listLogs() {
      return this.api.get(`${this.version}/events/logs`);
    }

    deleteEvent(pk) {
      return this.api.delete(`${this.endpoint}/${pk}/`);
    }
  
    getHistory(pk) {
      return this.api.get(`${this.endpoint}/${pk}/get_history/`);
    }
  }
  
  
  export default EventAPI;
  
