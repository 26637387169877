class HazardTemplateAPI {

  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listHazards() {
    return this.api.get(`${this.version}/hazard_templates/`)
  }

  listIngredientTemplates(ingredient_pk) {
    return this.api.get(`${this.version}/hazard_templates/?ingredient_pk=${ingredient_pk}`);
  }

  listProcessHazards(process_pk) {
    return this.api.get(`${this.version}/hazard_templates/?process_pk=${process_pk}`);
  }

  listCrossContaminationPointHazards(cross_contamination_point_pk) {
    return this.api.get(`${this.version}/hazard_templates/?cross_contamination_point_pk=${cross_contamination_point_pk}`);
  }

  createHazard(payload) {
    return this.api.post(`${this.version}/hazard_templates/`, payload);
  }

  retrieveHazard(pk) {
    return this.api.get(`${this.version}/hazard_templates/${pk}/`);
  }

  updateHazard(payload) {
    return this.api.put(`${this.version}/hazard_templates/${payload.pk}/`, payload);
  }

  deleteHazard(pk) {
    return this.api.delete(`${this.version}/hazard_templates/${pk}/`);
  }

  getHistory(pk) {
    return this.api.get(`${this.version}/hazard_templates/${pk}/get_history/`);
  }
}


export default HazardTemplateAPI;
