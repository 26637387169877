import React from "react";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import SanitizeHtml from "Components/SanitizeHtml";
import DialogTemplate from "Components/DialogTemplate";


export default function SOPDialog({task, setTaskSelected}) {
  function getFormContents() {
    return (
      <Grid container spacing={3}>
        {task && <>
        <Grid item xs={12}>
          <Typography variant="h6">
            Task Details
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            {task.name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            Task SOP
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SanitizeHtml value={task.procedures}/>
        </Grid>
        </>}
      </Grid>
    )
  }

  return (
    <DialogTemplate
      open={task != null}
      title={'View SOP'}
      formContents={getFormContents()}
      formActions={null}
      cancel={() => { setTaskSelected(null) }}
    />
  )
}
