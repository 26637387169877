import React, { useState, useEffect } from "react";
import API from "../../../Api/Api";
import { useRouteMatch, useHistory, Link } from "react-router-dom";

import Chip from "@material-ui/core/Chip";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ContentHeader from "../../../Components/Layouts/ContentHeader";
import Message from "../../../Components/Message";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { StylesContext } from "../../../App";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { IconButton } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";

import CCPDeterminationWorksheet from "./CCPDeterminationWorksheet";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    generalContainerGrid: {
        padding: "16px",
        marginBottom: "96px",
    },
    hazardContainer: { margin: "8px", alignItems: "center" },
    accordionNoLine: {
        margin: "32px",
        marginTop: "8px",
        marginBottom: "8px",
        width: "100%",
        "&.MuiAccordion-root:before": {
            backgroundColor: "white",
        },
    },
    textfieldbackground: {
        background: "#fafafc",
    },
    defaultTextContainer: {
        width: "auto",
        float: "left",
        alignItems: "center",
    },
    defaultText: {
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#687895",
    },
    accordionTextContainer: { margin: "8px", alignItems: "center" },
    accordionTextItem: {
        width: "auto",
        float: "left",
        alignItems: "center",
    },
    hazardNameText: {
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#687895",
    },
    blueChip: {
        marginLeft: "12px",
        color: "#18BFF6",
        background: "rgba(255,62,62,0.1)",
    },
    notCCPblueChip: {
        marginLeft: "12px",
        color: "#18BFF6",
        background: "rgba(24, 191, 247, 0.1)",
    },
    buttonContainer: {
        alignItems: "center",
        justifyContent: "flex-end",
    },
    buttonText: {
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#18BFF6",
        marginBottom: "0px",
        opacity: 1,
    },
    horizButtonContainer: {
        alignItems: "center",
        justifyContent: "flex-end",
    },
    accordionDetails: { marginLeft: "8px", marginRight: "8px" },
    hazardDescriptionText: {
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#1F203D",
        marginBottom: "0px",
        opacity: 1,
    },
}));

NewSignificantHazards.propTypes = {
    haccpPlanPk: PropTypes.number,
};

let ccpPk;
function NewSignificantHazards(props) {
    const [hazards, setHazards] = useState([]);
    const api = new API();
    const classes = useStyles();

    let match = useRouteMatch();
    let history = useHistory();
    const [isActive, setIsActive] = useState(false);

    useEffect((e) => {
        setIsActive(true);
        getHazards(props.haccpPlanPk);
    }, []); // run when mounted

    function getHazards(pk) {
        api.getHazardAPI()
            .listSignificantHazards(pk)
            .then((e) => {
                setHazards(e.data);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const hazard_columns = [
        { title: "Name", field: "name" },
        { title: "Description", field: "description", editable: "never" },
        { title: "Type", field: "type", editable: "never" },
        { title: "Last Modified", field: "last_modified", editable: "never" },
    ];

    function createNewCCPDetermination(hazard) {
        if (hazard != null) {
            setCcpDetOpen(true);
            setCCPpk(null);
            //setCcpDetOpen(true);
        }
        // api
        //   .getCcpDeterminationWorksheetAPI()
        //   .createCCPDeterminationWorksheet({
        //     organization: hazard.organization,
        //     hazard: hazard.pk,
        //     new: "yes",
        //   })
        //   .then((e) => {
        //     // history.push(`${match.url}/ccp_determination/${e.data.pk}`);
        //     ccpPk = e.data.pk;
        //     setCCPpk(e.data.pk);
        //     setCcpDetOpen(true);
        //   })
        //   .catch((e) => {
        //     console.log(e.data);
        //   });
    }
    function deleteHazard(hazard) {
        api.getHazardAPI()
            .deleteHazard(hazard.pk)
            .then((response) => {
                let updatedHazards = [...hazards];

                let hazardIndex = updatedHazards.findIndex((updatedHazard) => {
                    return updatedHazard.pk === hazard.pk;
                });

                if (hazardIndex > -1) {
                    updatedHazards.splice(hazardIndex, 1);
                }

                setHazards(updatedHazards);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function navigateToCCPDetermination(hazard) {
        if (hazard != null) {
            setCcpDetOpen(true);
            setCCPpk(hazard.ccp_determination[0]);
            //setCcpDetOpen(true);
        }
    }

    const [hazardModalOpen, setHazardModalOpen] = useState(false);
    const [hazardSelected, setHazardSelected] = useState(null);
    const [shouldClose, setShouldClose] = useState(true);

    function hazardSave() {
        getHazards(props.haccpPlanPk);
    }

    function hazardSaveAndContinue() {
        getHazards(props.haccpPlanPk);
        setHazardModalOpen(false);
    }

    function hazardClicked(pk) {
        setHazardSelected(pk);
        setHazardModalOpen(true);
    }

    function handleHazardModalClosed() {
        if (!shouldClose) {
            alert("Save changes before closing.");
        } else {
            setHazardModalOpen(false);
        }
    }

    function modelClose() {
        if (!shouldClose) {
            return;
        }

        setHazardModalOpen(false);
    }
    function cancel() {
        setHazardModalOpen(false);
    }
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState({
        message: "",
        status: "info",
    }); // error, warning, info, info

    function setReturnStatus(message, status) {
        setMessage({ message: message, status: status });
        setOpen(true);
    }

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
    const [expanded, setExpanded] = React.useState(false);
    //could potentially just use components from hazard.components
    let processNames = "";
    const handleAccordianChange = (panel) => (event, isExpanded) => {
        if (panel.processes_read) {
            panel.processes_read.forEach((name) => {
                if (processNames === "") {
                    processNames = name.name;
                } else {
                    processNames = processNames + ", " + name.name;
                }
            });
            setProcessesRead(processNames);
        }
        setExpanded(isExpanded ? panel : false);
    };

    const [processesRead, setProcessesRead] = React.useState("null");
    const [ccpDetOpen, setCcpDetOpen] = useState(false);
    const [newCCP, setNewCCP] = useState(false);
    const [CCPpk, setCCPpk] = useState(null);

    function EditCCPDetermination(hazard) {
        setCcpDetOpen(true);
        setNewCCP(false);
        navigateToCCPDetermination(hazard);
        setCCPHazard(hazard);
    }

    function OpenCCPDetermination(hazard) {
        setNewCCP(true);
        setCCPHazard(hazard);
        createNewCCPDetermination(hazard);
    }

    function setMenuClosed() {
        setDeleteMenuOpen(false);
        setDeleteWarningOpen(false);
        setExpanded(false);
    }
    function handleCCPClose() {
        getHazards(props.haccpPlanPk);
        setCcpDetOpen(false);
        setAnchorEl(null);
    }
    function saveAndContinue() {
        getHazards(props.haccpPlanPk);
        setCcpDetOpen(false);
        setAnchorEl(null);
        setReturnStatus("CCP Determination Saved!", "info");
    }
    const [CCPHazard, setCCPHazard] = useState(null);

    const [deleteWarningOpen, setDeleteWarningOpen] = React.useState(false);

    function setWarningOpen(rowData) {
        setDeleteWarningOpen(true);
        setHazardSelected(rowData);
    }

    function setWarningClosed() {
        setDeleteWarningOpen(false);
        setAnchorEl(null);
    }

    function deleteWarnedHazard() {
        deleteHazard(hazardToDelete);
        setAnchorEl(null);
        setDeleteWarningOpen(false);
        setReturnStatus("Hazard Deleted!", "info");
    }
    const [deleteMenuOpen, setDeleteMenuOpen] = useState(false);

    const handleClick = (event, hazard) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
        setHazardToDelete(hazard);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setMenuClosed();
    };
    const [hazardToDelete, setHazardToDelete] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [ccpDeterminationWorksheet, setCcpDeterminationWorksheet] = useState(null);
    const menuOpen = Boolean(anchorEl);

    return (
        <div>
            <Dialog
                open={ccpDetOpen}
                fullScreen
                onClose={() => {
                    setCcpDetOpen(false);
                }}
            >
                <DialogContent>
                    <CCPDeterminationWorksheet
                        open={ccpDetOpen}
                        newCCP={newCCP}
                        ccpDeterminationWorksheet={setCcpDeterminationWorksheet}
                        pk={CCPpk}
                        newCCPpk={ccpPk}
                        close={handleCCPClose}
                        hazard={CCPHazard}
                        saveAndContinue={saveAndContinue}
                        message={message}
                        setShouldClose={setShouldClose}
                    />
                </DialogContent>
            </Dialog>
            <div style={{ width: "100%" }}>
                <ContentHeader title="Significant Hazards" />
                <Grid container spacing={2} className={classes.generalContainerGrid}>
                    <div>
                        <Dialog
                            open={deleteWarningOpen}
                            onClose={setWarningClosed}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Delete this hazard?"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to delete this hazard? This action can not be reversed.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={setWarningClosed} color="primary">
                                    No
                                </Button>
                                <Button onClick={deleteWarnedHazard} color="primary" autoFocus>
                                    Yes, Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    {hazards.length < 1 && (
                        <Grid container className={classes.hazardContainer}>
                            <Grid item xs={12} container className={classes.defaultTextContainer}>
                                <Typography className={classes.defaultText}>
                                    There are no significant hazards.
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    {hazards.map((hazard) => {
                        return (
                            <Accordion
                                key={hazard.pk}
                                elevation={0}
                                style={{
                                    margin: "32px",
                                    marginTop: "8px",
                                    marginBottom: "8px",
                                    width: "100%",
                                }}
                                className={classes.accordionNoLine}
                                expanded={expanded === hazard}
                                onChange={handleAccordianChange(hazard)}
                            >
                                <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                    <Grid container className={classes.accordionTextContainer}>
                                        <Grid item xs={7} md={5} container className={classes.accordionTextItem}>
                                            <Typography className={classes.hazardNameText}>{hazard.name}</Typography>
                                        </Grid>{" "}
                                        <Grid
                                            item
                                            xs={4}
                                            md={2}
                                            container
                                            style={{
                                                width: "auto",
                                                float: "left",
                                                alignItems: "center",
                                            }}
                                        >
                                            {hazard.ccps.length > 0 && (
                                                <Chip className={classes.blueChip} label="CCP" />
                                            )}
                                            {hazard.ccps.length < 1 && hazard.ccp_determination.length >= 1 && (
                                                <Chip className={classes.notCCPblueChip} label="Not a CCP" />
                                            )}
                                            {hazard.ccp_determination.length < 1 && hazard.ccps.length < 1 && (
                                                <Chip className={classes.notCCPblueChip} label="Undetermined" />
                                            )}
                                        </Grid>
                                        <Grid item xs={6} md={3} container className={classes.buttonContainer}>
                                            {hazard.ccp_determination.length > 0 && (
                                                <Button
                                                    variant="text"
                                                    style={{ marginRight: "16px" }}
                                                    onClick={() => EditCCPDetermination(hazard)}
                                                    disabled={props.readOnlyMode}
                                                >
                                                    <Typography className={classes.buttonText}>
                                                        Edit CCP Determination
                                                    </Typography>
                                                </Button>
                                            )}
                                            {hazard.ccp_determination.length <= 0 && (
                                                <Button
                                                    variant="text"
                                                    style={{ marginRight: "16px" }}
                                                    onClick={() => OpenCCPDetermination(hazard)}
                                                    disabled={props.readOnlyMode}
                                                >
                                                    <Typography className={classes.buttonText}>
                                                        Determine CCP
                                                    </Typography>
                                                </Button>
                                            )}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            md={0}
                                            container
                                            style={{
                                                width: "auto",
                                                float: "left",
                                                alignItems: "center",
                                            }}
                                        ></Grid>
                                        <Grid item xs={6} md={1} container className={classes.horizButtonContainer}>
                                            <IconButton
                                                disableRipple
                                                style={{
                                                    backgroundColor: "transparent",
                                                    float: "right",
                                                }}
                                                disabled={props.readOnlyMode}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleClick(event, hazard);
                                                }}
                                            >
                                                <MoreHorizIcon></MoreHorizIcon>
                                            </IconButton>
                                            <Menu
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={menuOpen}
                                                onClose={handleMenuClose}
                                                PaperProps={{
                                                    style: {
                                                        maxHeight: 48 * 4.5,
                                                        width: 200,
                                                    },
                                                }}
                                            >
                                                <MenuItem key={hazard} onClick={() => setDeleteWarningOpen(true)}>
                                                    Delete
                                                </MenuItem>
                                            </Menu>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails className={classes.accordionDetails}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography className={classes.hazardDescriptionText}>
                                                Hazard Description
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {hazard.description && (
                                                <TextField
                                                    disabled
                                                    name="Hazard Description"
                                                    placeholder="Hazard Description"
                                                    className={classes.textfieldbackground}
                                                    variant="outlined"
                                                    defaultValue={hazard.description}
                                                    fullWidth
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography className={classes.hazardDescriptionText}>
                                                Hazard Type
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography className={classes.hazardDescriptionText}>
                                                Is this potential hazard significant?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {hazard.type && (
                                                <TextField
                                                    disabled
                                                    name="Hazard Description"
                                                    placeholder="Hazard Description"
                                                    className={classes.textfieldbackground}
                                                    variant="outlined"
                                                    defaultValue={
                                                        hazard.type === "C"
                                                            ? "Chemical"
                                                            : hazard.type === "B"
                                                            ? "Biological"
                                                            : "Physical"
                                                    }
                                                    fullWidth
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {hazard && (
                                                <TextField
                                                    disabled
                                                    name="Hazard Description"
                                                    placeholder="Hazard Description"
                                                    className={classes.textfieldbackground}
                                                    variant="outlined"
                                                    defaultValue={hazard.significant ? "True" : "False"}
                                                    fullWidth
                                                />
                                            )}
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography className={classes.hazardDescriptionText}>
                                                Justification for Inclusion or Exclusion as a Significant Hazard
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {hazard && (
                                                <TextField
                                                    disabled
                                                    name="Hazard Description"
                                                    placeholder="Hazard Description"
                                                    className={classes.textfieldbackground}
                                                    variant="outlined"
                                                    defaultValue={hazard.justification}
                                                    fullWidth
                                                />
                                            )}
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography className={classes.hazardDescriptionText}>
                                                Preventative Measures of the Significant Hazard
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {hazard && (
                                                <TextField
                                                    disabled
                                                    name="Hazard Description"
                                                    placeholder="Hazard Description"
                                                    className={classes.textfieldbackground}
                                                    variant="outlined"
                                                    defaultValue={hazard.preventative_measures}
                                                    fullWidth
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography className={classes.hazardDescriptionText}>
                                                Linked Processes ({hazard.processes.length})
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {processesRead && (
                                                <TextField
                                                    disabled
                                                    name="Hazard Description"
                                                    placeholder="Hazard Description"
                                                    className={classes.textfieldbackground}
                                                    variant="outlined"
                                                    value={processesRead}
                                                    fullWidth
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography className={classes.hazardDescriptionText}>
                                                Linked to Ingredients?
                                            </Typography>
                                        </Grid>
                                        {hazard.ingredients.length > 0 && (
                                            <Grid item xs={12}>
                                                <TextField
                                                    disabled
                                                    name="Linked to Ingredients_hazard"
                                                    placeholder="Linked to Ingredients"
                                                    className={classes.textfieldbackground}
                                                    variant="outlined"
                                                    defaultValue={"Yes"}
                                                    fullWidth
                                                />
                                            </Grid>
                                        )}
                                        {!hazard.ingredients.length && (
                                            <Grid item xs={12}>
                                                <TextField
                                                    disabled
                                                    name="Linked to Ingredients_hazard"
                                                    placeholder="Linked to Ingredients"
                                                    className={classes.textfieldbackground}
                                                    variant="outlined"
                                                    defaultValue={"No"}
                                                    fullWidth
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}{" "}
                </Grid>
            </div>

            <Message
                position={true}
                open={open}
                message={message.message}
                severity={message.status}
                vertical="bottom"
                horizontal="right"
                handleClose={handleClose}
            />
        </div>
    );
}

export default NewSignificantHazards;
