import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography } from "@material-ui/core";
import GreyTextField from "Components/GreyTextField";
import useOrganization from "hooks/useOrganization";
import { StylesContext } from "App";

const AddFCustomerAddressesDialog = ({ open, handleClose, handleSave, customerAddressToEdit, editable }) => {
    const classes = React.useContext(StylesContext);
    const org = useOrganization();
    const [customerAddress, setCustomerAddress] = useState({
        organization: "",
        customer: "",
        facility_name: "",
        email: "",
        phone_number: "",
        address: "",
        additional_information: "",
    });

    useEffect(() => {
        if (open) {
            if (customerAddressToEdit) {
                setCustomerAddress(customerAddressToEdit);
            } else {
                setCustomerAddress({
                    organization: org.pk,
                    customer: "",
                    facility_name: "",
                    email: "",
                    phone_number: "",
                    address: "",
                    additional_information: "",
                });
            }
        }
    }, [open, customerAddressToEdit]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCustomerAddress((prevCustomerAddress) => ({
            ...prevCustomerAddress,
            [name]: value,
        }));
    };

    const handleSaveCustomerAddress = () => {
        // Check if required fields are not empty
        if (
            customerAddress.organization.trim() === "" ||
            // customerAddress.customer.trim() === "" ||
            customerAddress.facility_name.trim() === "" ||
            customerAddress.email.trim() === "" ||
            customerAddress.phone_number.trim() === ""
        ) {
            alert("Please fill in all required fields.");
            return;
        }
        // Call the handleSave prop function
        handleSave(customerAddress);
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogContent style={{ backgroundColor: "#f8f8f8", height: "400px" }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {!editable ? (
                            <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
                                View Customer Address
                            </Typography>
                        ) :
                            <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
                                {customerAddressToEdit ? "Edit customer Address" : "Add Customer Address"}
                            </Typography>
                        }
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                        <Typography style={{ fontSize: "14px", fontWeight: 500 }}>Customer</Typography>
                        <GreyTextField
                            name="customer"
                            value={customerAddress.customer}
                            onChange={handleChange}
                            fullWidth
                            disabled={!editable}
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                        <Typography style={{ fontSize: "14px", fontWeight: 500 }}>Facility Name</Typography>
                        <GreyTextField
                            name="facility_name"
                            value={customerAddress.facility_name}
                            onChange={handleChange}
                            fullWidth
                            disabled={!editable}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography style={{ fontSize: "14px", fontWeight: 500 }}>Email</Typography>
                        <GreyTextField name="email" value={customerAddress.email} onChange={handleChange} fullWidth disabled={!editable} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography style={{ fontSize: "14px", fontWeight: 500 }}>Phone Number</Typography>
                        <GreyTextField
                            name="phone_number"
                            value={customerAddress.phone_number}
                            onChange={handleChange}
                            fullWidth
                            disabled={!editable}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ fontSize: "14px", fontWeight: 500 }}>Address</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <GreyTextField
                            name="address"
                            value={customerAddress.address}
                            onChange={handleChange}
                            fullWidth
                            disabled={!editable}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ fontSize: "14px", fontWeight: 500 }}>Additional Information</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <GreyTextField
                            name="additional_information"
                            multiline
                            rows={3}
                            value={customerAddress.additional_information}
                            onChange={handleChange}
                            fullWidth
                            disabled={!editable}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ backgroundColor: "#f8f8f8" }}>
                <Button onClick={handleClose}>Cancel</Button>
                <Button color="primary" variant="contained" onClick={handleSaveCustomerAddress} disabled={!editable}>
                    {customerAddressToEdit ? "Save" : "Create"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddFCustomerAddressesDialog;
