import React, { useEffect, useState } from "react";
import { makeStyles, } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AutocompleteSD from "../../Components/AutocompleteSD";
import { StylesContext } from "../../App";

const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  paper: {
    width: '800px',
  },
  cell: {
    border: '1px solid black',
    padding: '5px',
  },
  grid: {
    width: '100%',
    margin: '1px',
  },
  select: {
    width: '100%',
    padding: '0px',
    margin: '0px',
  },
}));



export default function ItemModal(props) {
  const classes = useStyles();
  const globalClasses = React.useContext(StylesContext);

  const [ingredientInstance, setIngredientInstance] = useState({ ...props.ingredientInstance });
  const [disableAll, setDisableAll] = useState(false);
  const [dummyKey, setDummyKey] = useState(1);
  function handleTextFieldChange(event) {
    const property = event.target.name;
    const value = event.target.value;
    console.log(event);

    setIngredientInstance({ ...ingredientInstance, [property]: value });

  }

  function handleAutoCompleteChange(newKey, newValue) {
    setIngredientInstance({ ...ingredientInstance, [newKey]: newValue });
    setDummyKey(dummyKey + 1)

  }

  useEffect(() => {
    console.log(ingredientInstance);

  }, [ingredientInstance])

  useEffect(() => {

    if (!ingredientInstance || !ingredientInstance.sku) {
      return;
    }
    setIngredientInstance({ ...ingredientInstance, unit_type: ingredientInstance.sku.unit_type });
  }, [ingredientInstance.sku])

  function getChoices() {
    if (ingredientInstance.sku && ingredientInstance.sku.unit_choices) {
      return Object.keys(ingredientInstance.sku.unit_choices);
    }
    else {
      return [];
    }
  }

  return (
    <Paper elevation={0} className={classes.paper}>
      <Grid container spacing={3} className={classes.grid}>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Adding Item
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={6}>
          <Typography className={globalClasses.generalFormTypographyHeader}>
            Ingredient Name
          </Typography>
          <AutocompleteSD
            id="ingredient"
            name="ingredient"
            key={dummyKey}
            value={ingredientInstance.ingredient}
            onChange={(emptyEvent, newValue) => { handleAutoCompleteChange('ingredient', newValue) }}
            optionType={'rawObjects'}
            options={props.ingredients}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <Typography className={globalClasses.generalFormTypographyHeader}>
            SKU Id
          </Typography>
          <AutocompleteSD
            id="sku"
            name="sku"
            key={ dummyKey }
            value={ ingredientInstance.sku }
            onChange = {(emptyEvent, newValue) => {handleAutoCompleteChange('sku', newValue)}}
            optionType={'rawObjects'}
            options={props.skus}
            getOptionLabel={(option) => option.sku_id}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
          />
        </Grid> */}

        <Grid item xs={6}>
          <Typography className={globalClasses.generalFormTypographyHeader}>
            Quantity
          </Typography>
          <TextField
            fullWidth
            name="unit_value"
            variant="outlined"
            type="numeric"
            value={ingredientInstance.unit_value}
            onChange={handleTextFieldChange}
          //error={submitAttempted && !order.created_date}
          />
        </Grid>


        <Grid item xs={6}>
          <Typography className={globalClasses.generalFormTypographyHeader}>
            Unit
          </Typography>
          <FormControl className={classes.formControl}>
            {/* todo this sets the wrong value, why. May actually be BE */}
            {/*<AutocompleteSD
              id="unit_type"
              name="unit_type"
              key={ dummyKey }
              value={ ingredientInstance.unit_type }
              onChange = {(emptyEvent, newValue) => {handleAutoCompleteChange('unit_type', newValue)}}
              optionType={'pkIndexingToObjects'}
              choices={(ingredientInstance.sku && ingredientInstance.sku.unit_choices) ? ingredientInstance.sku.unit_choices : []}
              options={getChoices()}
              getOptionLabel={(option) => ingredientInstance.sku ? ingredientInstance.sku.unit_choices[option] : ''}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
            />*/}
            <Select
              id="unit_type"
              native
              variant="outlined"
              name="unit_type"
              //error={fieldObj.validate ? fieldObj.validate(props.lineData[fieldObj.key]) : false}
              //multiple
              value={ingredientInstance.unit_type}
              onChange={handleTextFieldChange}
            >
              {props.units && props.units.map((unit) => (
                <option key={unit.pk} value={unit.pk}>
                  {unit.full_name}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Button
        variant="contained"
        color="primary"
        style={{ margin: "12px" }}
        disabled={disableAll}
        onClick={(e) => { setDisableAll(true); props.handleAddItem(ingredientInstance); props.closeModal(); }}
      >
        Confirm
      </Button>

      <Button
        variant="contained"
        color="secondary"
        style={{ margin: "12px" }}
        onClick={(e) => { setDisableAll(true); props.closeModal() }}
      >
        Close
      </Button>
    </Paper>
  )
}

ItemModal.propTypes = {
  closeModal: PropTypes.func,
  ingredientInstance: PropTypes.object,
  skus: PropTypes.array,
  ingredients: PropTypes.array,
  handleAddItem: PropTypes.func,
  units:PropTypes.array,
  lineData: PropTypes.object,
  setLineData: PropTypes.func,
  lineInfo: PropTypes.object,
  disabled: PropTypes.bool,
  actions: PropTypes.array,
}