import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Select from '@material-ui/core/Select';
import PropTypes from "prop-types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import withStyles from "@material-ui/core/styles/withStyles";
import {StylesContext} from "../../App";

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '0px solid white',
        backgroundColor: 'rgba(0, 0, 0, 0.029)',
      },
    },
  },
})(TextField);

const CssFormControl = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '0px solid white',
        backgroundColor: 'rgba(0, 0, 0, 0.029)',
      },
    },
  },
})(FormControl);

function SkuFields(props) {
  const classes = React.useContext(StylesContext);
  function handleChange(event) {
    props.setShouldClose(true);
    //if (props.setBlockNavigation) props.setBlockNavigation(true);
    const value = event.target.value;
    props.setSku({...props.sku, [event.target.name]: value});
  }

  function handleAutoCompleteChange(field, value)  {
    props.setSku({...props.sku, [field]: value});
  }

  const [unitMetric, setUnitMetric] = useState(false);

  useEffect(() => {
    //only unit type set.
    if((!props.sku.ingredient_name || props.sku.ingredient_name == '') && !props.formulationUnitType  && props.sku.single_unit_type) {
      let skuUnit = props.units.find(unit => unit.pk == props.sku.single_unit_type)
      setUnitMetric(skuUnit.unit_type);
    }
    //Nothing set
    else if((!props.sku.ingredient_name || props.sku.ingredient_name == '') && !props.formulationUnitType) {
      setUnitMetric(false);
    }
    //only ingredient name
    else if ((props.sku.ingredient_name && props.sku.ingredient_name != '') && !props.formulationUnitType){
      let skuIng = props.ingredients.find(ingredient => ingredient.name == props.sku.ingredient_name);
      if(skuIng) {
        setUnitMetric(skuIng.unit_metric);
      }
      else{
        setUnitMetric(false);
      }
    }
    //only formulation unit type
    else if ((!props.sku.ingredient_name || props.sku.ingredient_name == '') && props.formulationUnitType){
      setUnitMetric(props.formulationUnitType.unit_type);
    }
    //there's both, ensure they're in agreement
    else {
      let skuIng = props.ingredients.find(ingredient => ingredient.name == props.sku.ingredient_name);
      if(skuIng) {
        if(props.formulationUnitType.unit_type == skuIng.unit_metric){
          setUnitMetric(skuIng.unit_metric);
        }
        else {
          window.alert('Ingredient/Formulation units not in agreement, ensure they use the same metric. (Weight, volume, or quantity)');
          setUnitMetric('broken')
        }
      }
      else{
        setUnitMetric(props.formulationUnitType.unit_type);
      }
    }
  }, [props.sku, props.formulationUnitType, props.sku.unit_type])
    
  return (
    <Grid container spacing={3} style={{marginTop: '8px'}}>
      <Grid item container xs={6} alignItems="center" justify="left">
        <Typography className={classes.generalFormTypographyHeader}>
          Product Name
        </Typography>
        <CssTextField 
          placeholder="Product Name" 
          name="name" 
          variant="outlined"
          fullWidth
          value={props.sku.name}
          error={props.submitAttempted && !props.sku.name}
          onChange={handleChange}
        />
      </Grid>

      <Grid item container xs={6}>
        <Typography className={classes.generalFormTypographyHeader}>
          SKU ID
        </Typography>
        <CssTextField 
          placeholder="Unique Identifier" 
          name="sku_id" 
          variant="outlined"
          fullWidth
          value={props.sku.sku_id}
          error={props.submitAttempted && !props.sku.sku_id}
          onChange={handleChange}
        />
      </Grid>

      <Grid item container xs={6} >
        <Typography className={classes.generalVerticleAlignTypography}>
          What ingredient type is this SKU?
        </Typography>
      </Grid>
      <Grid item container xs={3} >
        <Autocomplete
          id={'ingredient_name'}
          freeSolo
          fullWidth
          name={'ingredient_name'}
          value={props.sku.ingredient_name}

          onChange = {(emptyEvent, newValue) => {
            if(newValue && typeof(newValue) == 'object') {
              handleAutoCompleteChange('ingredient_name', newValue.name)
            }
            else {
              handleAutoCompleteChange('ingredient_name', newValue)
            }
          }}

          onInputChange = {(emptyEvent, newValue) => {
            if(typeof(newValue) == 'object') {
              handleAutoCompleteChange('ingredient_name', newValue.name)
            }
            else {
              handleAutoCompleteChange('ingredient_name', newValue)
            }
          }}

          options={props.ingredients}
          getOptionLabel={(ingredient) => {
            if(typeof(ingredient) == 'object') {
              return ingredient.name;
            }
            else {
              return ingredient;
            }
          }}
          //style={{ backgroundColor: '#BEB' }}
          renderInput={(params) => <CssTextField {...params} label={''} value={props.sku.ingredient_name} variant="outlined" fullWidth />}
        />
      </Grid>

      <Grid item container xs={6}>
        <Typography className={classes.generalVerticleAlignTypography}>
          What is the default unit that this SKU is {props.isFormulation ? 'produced' : 'ordered'} in?
        </Typography>
      </Grid>
      {props.sku.sku_type == 'BULK' &&
        <Grid item container xs={3} alignItems="center" justify="center">
          <CssFormControl variant="outlined" style={{ "width": "100%" }}>
            <InputLabel htmlFor="age-native-helper">Default Unit</InputLabel>
            <Select
              native
              label="Default Unit"
              style={{ "width": "100%" }}
              value={props.sku.unit_type}
              onChange={handleChange}
              error={props.submitAttempted && !props.sku.unit_type}
              inputProps={{
                name: 'unit_type',
                id: 'unit_type',
                endAdornment: <InputAdornment position="end">
                  <ArrowDropDownIcon/>
                </InputAdornment>,
              }}
            >
              <option aria-label="None" value="" />

              { !unitMetric && props.units && (props.units).filter(unitObj => unitObj.unit_category == 'GLO').map((unitObj) => {
                return <option key={unitObj.pk} value={unitObj.pk}> {unitObj.full_name} </option>
              })}

            { unitMetric && props.units && (props.units.filter(unit => unit.unit_type == unitMetric && unit.unit_category == 'GLO')).map((unitObj) => {
              return <option key={unitObj.pk} value={unitObj.pk}> {unitObj.full_name} </option>
            })}

            </Select>
          </CssFormControl>
        </Grid>
      }
      {props.sku.sku_type == 'PREP' &&
        <Grid item container xs={3} alignItems="center" justify="center">
          <CssFormControl variant="outlined" style={{ "width": "100%" }}>
            <InputLabel htmlFor="age-native-helper">Default Unit</InputLabel>
            <Select
              native
              label="Default Unit"
              style={{ "width": "100%" }}
              value={props.sku.unit_type_placeholder}
              onChange={handleChange}
              error={props.submitAttempted && !props.sku.unit_type_placeholder}
              inputProps={{
                name: 'unit_type_placeholder',
                id: 'unit_type_placeholder',
                endAdornment: <InputAdornment position="end">
                  <ArrowDropDownIcon/>
                </InputAdornment>,
              }}
            >
              <option aria-label="None" value="" />
              
              <option key={1} value={'UNI'}> Unit </option>
              <option key={1} value={'CAS'}> Case </option>
              <option key={1} value={'PAL'}> Pallet </option>
            </Select>
          </CssFormControl>
        </Grid>
      }

      {props.sku.sku_type == 'PREP' && <>
        <Grid item container xs={6}>
          <Typography className={classes.generalVerticleAlignTypography}>
            What is the weight/volume/quantity of a single unit?
          </Typography>
        </Grid>
        <Grid item container xs={3} alignItems="center" justify="center">
          <CssTextField 
            placeholder="Value" 
            name="unit_value" 
            variant="outlined"
            fullWidth
            value={props.sku.unit_value}
            error={props.submitAttempted && !props.sku.unit_value}
            onChange={handleChange}
          />
        </Grid>
        <Grid item container xs={3} alignItems="center" justify="center">
          <CssFormControl variant="outlined" style={{ "width": "100%" }}>
            <InputLabel htmlFor="age-native-helper">Unit Type</InputLabel>
            <Select
              native
              placeholder="Unit Type"
              label="Unit Type"
              style={{ "width": "100%" }}
              value={props.sku.single_unit_type}
              onChange={handleChange}
              error={props.submitAttempted && !props.sku.single_unit_type}
              inputProps={{
                name: 'single_unit_type',
                id: 'single_unit_type',
                endAdornment: <InputAdornment position="end">
                  <ArrowDropDownIcon/>
                </InputAdornment>,
              }}
            >
              <option aria-label="None" value="" />

              {!unitMetric && (props.units).filter(unitObj => unitObj.unit_category == 'GLO').map((unitObj) => {
                return <option key={unitObj.pk} value={unitObj.pk}> {unitObj.full_name} </option>
              })}

              {unitMetric && (props.units).filter(unitObj => (unitObj.unit_type == unitMetric && unitObj.unit_category == 'GLO')).map((unitObj) => {
                return <option key={unitObj.pk} value={unitObj.pk}> {unitObj.full_name} </option>
              })}

            </Select>
          </CssFormControl>
        </Grid>
 

        <Grid item container xs={6}>
          <Typography className={classes.generalVerticleAlignTypography}>
          How many units are in a case?
          </Typography>
        </Grid>
        <Grid item container xs={3} alignItems="center" justify="center">
          <CssTextField 
            placeholder="Value" 
            name="case_unit_value" 
            variant="outlined"
            fullWidth
            value={props.sku.case_unit_value}
            onChange={handleChange}
          />
        </Grid>

        <Grid item container xs={6}>
          <Typography className={classes.generalVerticleAlignTypography}>
            How many cases are in a pallet?
          </Typography>
        </Grid>
        <Grid item container xs={3} alignItems="center" justify="center">
          <CssTextField 
            placeholder="Value" 
            name="pallet_unit_value" 
            variant="outlined"
            fullWidth
            value={props.sku.pallet_unit_value}
            onChange={handleChange}
            error={props.submitAttempted && props.sku.pallet_unit_value && !props.sku.case_unit_value}
          />
        </Grid>
        <Grid item container xs={3}>
          <Typography className={classes.generalVerticleAlignTypography}>
          {(props.sku.pallet_unit_value && props.sku.case_unit_value) ? 
            'There are ' + (props.sku.pallet_unit_value * props.sku.case_unit_value) + ' units in a pallet.' :
            'Pallet/case is not defined.'}
          </Typography>
        </Grid>
      </>}

      {props.isFormulation && <>
        <Grid item container xs={6}>
          <Typography className={classes.generalVerticleAlignTypography}>
            Where is this SKU produced?
          </Typography>
        </Grid>
        <Grid item container xs={3} alignItems="center" justify="center">
          <CssFormControl variant="outlined" style={{ "width": "100%" }}>
            <InputLabel></InputLabel>
            <Select
              native
              style={{ "width": "100%" }}
              value={props.sku.inventory_location}
              onChange={handleChange}
              inputProps={{
                name: 'inventory_location',
                id: 'inventory_location',
                endAdornment: <InputAdornment position="end">
                  <ArrowDropDownIcon/>
                </InputAdornment>,
              }}
            >
              <option key={-1} value={''}> </option>

              {props.inventoryLocations && props.inventoryLocations.filter(invLoc => !invLoc.soft_delete).map((invLoc) => {
                return <option key={invLoc.pk} value={invLoc.pk}> {invLoc.name} </option>
              })}

            </Select>
          </CssFormControl>
        </Grid>
      </>}

    </Grid>
  )
}

export default SkuFields;

SkuFields.propTypes = {
  sku: PropTypes.object,
  setSku: PropTypes.func,
  units: PropTypes.arrayOf(PropTypes.object),
  setShouldClose: PropTypes.func,
  suppliers: PropTypes.arrayOf(PropTypes.object),
  ingredients: PropTypes.arrayOf(PropTypes.object),
  formulationUnitType: PropTypes.object,
}