import React, { useState, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import CustomNumberField from "../../Monitoring/CustomFields/CustomNumberField";
import GreyTextField from "Components/GreyTextField";


export default function NumberFieldEditor({customField, setCustomField}) {
	const [successMessage, setSuccessMessage] = useState(false);

	const [fakeError, setFakeError] = useState("")
	const [fakeInput, setFakeInput] = useState("");

	function fakeSubmit(event) {
		if (customField.required && !fakeInput.length) {
			setFakeError("This field is required.");
		}

		else {
			setFakeError("");
			setSuccessMessage(true);
		}
	}

	useEffect(() => {
		setCustomField({...customField, field_type: 'number'});
	}, []);

	return (
		<Grid container item xs={12} style={{overflowY: 'auto', padding: "16px", paddingRight: '32px'}}>
			<Grid item xs={12}>
				<Typography style={{fontWeight: 'bold', fontSize: '16px'}}>
					Add a Number Field 
				</Typography>
			</Grid>
			<Grid item xs={12} style={{paddingTop: '16px'}}>
				<Typography style={{fontSize: '14px'}}>
					Field Title
				</Typography>
			</Grid>
			<Grid item xs={12} style={{paddingTop: '8px'}}>
				<GreyTextField 
					value={customField.name || ""} 
					name="name" 
					fullWidth 
					onChange={event => setCustomField({...customField, name: event.target.value})}/>
			</Grid>
			<Grid item xs={12} style={{paddingTop: '16px'}}>
				<Typography style={{fontSize: '14px'}}>
					Field Instructions
				</Typography>
			</Grid>
			<Grid item xs={12} style={{paddingTop: '8px'}}>
				<GreyTextField 
					value={customField.description || ""} 
					multiline rows={2} fullWidth 
					onChange={event => setCustomField({...customField, description: event.target.value})}
				/>
			</Grid>
			<Grid item container alignItems="center" xs={6} style={{paddingTop: '16px'}}>
				<Typography>
					Is this field required? 
				</Typography>
			</Grid>
			<Grid item container xs={6} justify="flex-end" style={{paddingTop: '16px'}}>
				<Switch onChange={event => setCustomField({...customField, required: event.target.checked})}/>
			</Grid>

			<Grid item xs={12} style={{paddingTop: '16px', paddingBottom: '16px'}}>
				<FormControlLabel
					control={<Checkbox checked={customField.display_in_table} 
					onChange={(event) => setCustomField({...customField, display_in_table: event.target.checked})} name="checkedA" />}
					label="Should this field be displayed on tables?"
				/>
			</Grid>

			<Grid item xs={12} style={{paddingTop: '16px', paddingBottom: '16px'}}>
				<Typography style={{fontWeight: 'bold', fontSize: '16px'}}>
					Preview
				</Typography>
			</Grid>

			<CustomNumberField
				id="preview"
				name="preview"
				displayName={customField.name}
				description={customField.description}
				required={customField.required}
				value={fakeInput} 
				onChange={event => {setSuccessMessage(false); setFakeInput(event.target.value)}} 
				error={fakeError} 
			/>

			<Grid item container xs={12} style={{paddingTop: '16px'}}>
				<Grid item container xs={12} alignItems="center">
					<Button variant="contained" color="primary" onClick={fakeSubmit}>Fake Submit</Button>
					
					{successMessage &&  
						<Alert severity="success" style={{marginLeft: '8px'}}>Success!</Alert>
					}
				</Grid>
			</Grid>
		</Grid>
	)
}


