import React from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from '@material-ui/icons/Remove';

import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles({
	formControlRoot: {
		width: '100%',
	},

	labelRoot: {
		fontSize: '14px',
		fontWeight: 400,
	}
});


export default function CustomCheckboxField(props) {

	const classes = useStyles();

	return (
		<Grid container item xs={12} style={props.style}>
			<Grid container item xs={12} md={props.oneRow ? 12 : 6}>
				<Grid item xs={12} style={{ paddingTop: '12px' }}>
					<Typography style={{ fontSize: '14px', fontWeight: 500 }}>
						{props.displayName ? props.displayName : "<title>"} {props.required === true ? <span style={{ color: 'red', marginLeft: '4px' }}>*</span> : null}
					</Typography>
					<Typography style={{ fontSize: '12px', fontWeight: 400, marginTop: '4px' }}>
						{props.description ? props.description : "<instructions>"}
					</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={12} md={props.oneRow ? 12 : 6}>
				<Grid item xs={12} style={{ paddingTop: '8px', paddingBottom: '8px' }}>
					<FormControl fullWidth>
						<Grid container item xs={12} justify="space-between" alignItems="center">
							{props.options &&
								props.options.map(option => {
									return (
										<>
											<Grid key={option.id} item xs={12} md={8}>
												<FormControlLabel
													classes={{
														label: classes.labelRoot
													}}
													control={
														<Checkbox
															checked={props.demo ? null : (props.value != null) ? props.value : false}
															onChange={props.demo ? null : (event) => { if (props.demo) return;  props.onChange({ id: option.id, value: event.target.checked }) }}
															name={option.name}
															required={option.required}
															disabled={props.readOnly}
															color="primary"
															size="small"
														/>}
													label={option.label}
												/>
											</Grid>
											{props.demo &&
												<Grid item container justify="flex-end" xs={4}>
													<IconButton onClick={(event) => props.removeOption(option)}>
														<RemoveIcon />
													</IconButton>
												</Grid>
											}
										</>
									)
								})
							}
						</Grid>
					</FormControl>

					{props.error &&
						<Typography style={{ fontSize: '12px', color: '#f44336' }}>{props.error}</Typography>
					}
				</Grid>
			</Grid>
		</Grid>
	)
}