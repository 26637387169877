import React, { useContext } from "react";
import { OrgContext } from "hooks/useOrganization"
import ImagesAPI from "../../Api/ImagesAPI";
import PropTypes from 'prop-types';
import {
  Typography,
  Paper,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core';
import {DropzoneArea} from 'material-ui-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ImageManager from './ImageManager';
import API from "../../Api/Api";

//This status means the component is ready and waiting to accept new files
const OPEN = 0;
//This status means the component is currently uploading files 
const IN_PROGRESS = 1;
//this status means the component is reporting on the files previously uploaded
const REPORTING = 2;

/**
 * An image uploader with an optional file manager. Uses a drop area for uploading,
 * and media cards for the previews.
 * 
 * PROPS
 * 
 * showManager: BOOL - If true, shows the image manager below the image uploader
 * 
 * allowDelete: BOOL - If true, the image cards will have a functioning delete button appended
 * 
 * cardActions: Array[Object] - An array of action objects to allow for each image with the form {
 *    label: string, 
 *    action: function(string/imageUrl, string/imageName, int/imagePrimaryKey)
 * }
 * 
 * cardStyle: Object - A CSS style object that will be applid to each image card.
 * 
 * REQUIRED
 * api: CLASS>Api - An Api class object containing the following function definitions
 *    uploadImages(payload) - Will receive a formData payload of the form {name: string, image: fileObj}
 * 
 *    listImages() - Must return an object of the form 
 *      {data: {image: string/url, name: string, pk: integer/primary key ...} ...}
 * 
 *    deleteImage(pk) - Receives an integer that is the primary key recevied from listImages()
 * 
 */
class ImageUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state= {
      files: props.files ? props.files : [],
      filesLimit: props.filesLimit ? props.filesLimit : 3,
      uploadProcess: props.uploadProcess ? props.uploadProcess : OPEN,
      uploadStatus: props.uploadStatus ? props.uploadStatus : 'Default',
      uploadAction: props.uploadAction,
      showManager: props.showManager,
      api: props.api,
      cardActions: props.actions,
      allowDelete: props.allowDelete,
      cardStyle: props.cardStyle,
      org: props.org,
    }
  }

  componentDidMount() {
    new API().getAuthAPI().getAuthedProfile().then(response => {
      this.setState({user: response.data});
    });
  }
  
  /**
   * Executes when a file is put into the drop area
   * @param {*} files - files currently within the drop area
   */
  dropFile(files) {
    this.setState({
      files: files,
    });
  }
  

  /**
   * Uploads files to the back end server. Uploads are all separate in their own promise,
   * with their responses mapped to typography objects.
   */
  uploadFiles() {
    let org = this.state.org;
    let files = this.state.files;
    
    if(!files || files.length === 0) {
      return;
    }

    let payloads = [];
    
    files.forEach((file) => {
      var payloadForm = new FormData();
      payloadForm.append('name', file.name);
      payloadForm.append('image', file);
      payloadForm.append('organization', org.pk);

      payloads.push(payloadForm);
    });

    this.setState({
      files: [],
      uploadProcess: IN_PROGRESS,
    });

    Promise.all(
      payloads.map((payload) => {return this.state.api.uploadImages(payload).catch(e => {return e}) })
    ).then((responses) => {
      let responseText = [<Typography variant={'h5'}>These Images Have Been Added!</Typography>];
      responses.forEach((response) => {
        responseText.push(<Typography> {response.data.name}: {response.statusText} </Typography>);
      });

      if(this.state.uploadAction) {
        files.forEach((file) => {
          this.state.uploadAction(file);
        });
      }

      this.setState({
        uploadProcess: REPORTING,
        uploadStatus: responseText,
      })
    }).catch((e) => {
      this.setState({
        uploadProcess: REPORTING,
        uploadStatus: <Typography variant={'h5'}> Server Error: {e && e.data ? e : 'Cannot reach server.'} </Typography>
      })
    });
  }

  /**
   * Manages the display of the uploader based on the components state.
   * 
   * OPEN: The uploader displays the droparea only
   * IN_PROGRESS: The uploader displays a spinner while waiting on all promises
   * REPORTING: The uploader displays the server response for each image
   * other: Displays an error stating this is now desynced from the backend
   * 
   */
  manageUploader() {
      switch(this.state.uploadProcess) {
     
      case OPEN:
        return(
          <>
            <Grid item xs={12}>
              <DropzoneArea 
                onChange={(files) => this.dropFile(files)}
                acceptedFiles={['image/*']}
                filesLimit={this.state.filesLimit}
              />
            </Grid>
            <Grid item xs={12}>
              <Button 
                onClick={() => this.uploadFiles()} 
                variant="contained" 
                color="primary"
                style={{float: 'right'}}
                startIcon={<CloudUploadIcon />}
              >
                Upload
              </Button>
            </Grid>
          </>
        );

      case IN_PROGRESS:
        return(
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        );

      case REPORTING:
        return(
          <>
            <Grid item xs={12}>
              {this.state.uploadStatus}
            </Grid>
            <Grid item xs={12}>
              <Button 
                onClick={ () => {this.setState({uploadProcess: OPEN})} }
                variant="contained" 
                color="primary"
                style={{float: 'right'}}
                startIcon={<CloudUploadIcon />}
              >
                Upload More
              </Button>
            </Grid>
          </>
        );
        
      default:
        return(
          <Typography variant={'h5'}>
            Uploader has desynced from drop area.
          </Typography>
        )
    }
  }

  /**
   * Manages the display of the manager based on the components state
   * 
   * IN_PROGRESS: Displays a message that the images are refreshing
   * other: Displays the image manager with the last set of images that were cached.
   */
  manageManager() {
    if(!this.state.showManager) {
      return null;
    }

    switch(this.state.uploadProcess) {
      case IN_PROGRESS:
        return (
          <Paper elevation={1} style={{margin: '16px', padding: '16px'}}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant={'h5'}>
                  Refreshing...
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        )

      default:
        return (
          <ImageManager 
            actions={this.state.cardActions} 
            allowDelete={this.state.allowDelete}
            api={this.state.api}
            cardStyle={this.state.cardStyle}
          />
        )
    }
  }

  render() {
    return (
      <>
       <Paper elevation={1} style={{margin: '16px', padding: '16px'}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant={'h5'}>
                Upload Image
              </Typography> 
            </Grid>
            {this.manageUploader()}
          </Grid>
        </Paper>
        {this.manageManager()}
      </> 
    );
  }
}

ImageUploader.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func // receives (imageURL, imageName, pk)
  })),
  filesLimit: PropTypes.number,
  uploadAction: PropTypes.func,
  allowDelete: PropTypes.bool,
  api: PropTypes.instanceOf(ImagesAPI).isRequired,
  cardStyle: PropTypes.object, // Css style object
  showManager : PropTypes.bool,
}

export default ImageUploader;
