import React, { useState } from 'react';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import TraceabilityUtils from "../TraceabilityUtils";
import {StylesContext} from "../../App";

const utils = new TraceabilityUtils();
  
export default function MultiLotTableHeader(props) {
  const classes = React.useContext(StylesContext);
  //todo fix infinite loop
  const [validatorNeededValue, setValidatorNeededValue] = useState(0);
  const [validatorAccumulatedValue, setValidatorAccumulatedValue] = useState(0);

  const reducer = (accumulator, currentValue) => {
    let parentUnit = props.units[props.headerInfo.headUnit];
    let childUnit = props.units[currentValue[props.header.comparativeChildUnit]];
    let value = Number(currentValue[props.header.comparativeChildField]);
    return accumulator + utils.getConvertedValue(value, childUnit, parentUnit);
  };

  function getHeaderDisplayComparison() {
    let abbreviation = (props.units && props.units[props.headerInfo.headUnit]) ? props.units[props.headerInfo.headUnit].abbreviation : 'NoU';

    let batchAdjustedHeadValue = props.headerInfo.headValue * props.batches

    if(validatorNeededValue != batchAdjustedHeadValue) {
      setValidatorNeededValue(batchAdjustedHeadValue);
    }
    
    return ' ' + utils.formatNumber(batchAdjustedHeadValue) + ' ' + abbreviation;
  }

  function getChildDisplayComparison() {
    let abbreviation = (props.units && props.units[props.headerInfo.headUnit]) ? props.units[props.headerInfo.headUnit].abbreviation : 'NoU';

    let accumulatedValue = props.tableData.reduce(reducer, 0);

    if(validatorAccumulatedValue != accumulatedValue) {
      setValidatorAccumulatedValue(accumulatedValue );
    }
    
    return utils.formatNumber(accumulatedValue) + ' ' +  abbreviation;
  }

  function getBackgroundColor() {
    if(props.faded) {
      return 'grey';
    }
    else if(props.validating && (validatorNeededValue > validatorAccumulatedValue) ) {
      return 'red';
    }
    else {
      return '#FFF';
    }
  }

  return (
    <Paper className={classes.multiLotTableHeaderPaper} style={{backgroundColor: getBackgroundColor()}}>
      <Grid container spacing={3} style={{paddingBottom: '12px'}}>
        <Grid item xs={9} className={classes.multiLotTableHeaderTitleGrid}>
          <Typography className={classes.multiLotTableHeaderTitleTypography}>
            {props.headerInfo.headTitle}
          </Typography>
        </Grid>
        <Grid item container xs={3} className={classes.multiLotTableHeaderComparisonGrid}>
          <Typography className={classes.multiLotTableHeaderComparisonTypography}>
            <b>Quantity Required: </b> <span className={classes.multiLotTableHeaderComparisonSpan}>{ getChildDisplayComparison() + '/' + getHeaderDisplayComparison() } </span>
          </Typography>
        </Grid>

      </Grid>
    </Paper>
  )
}

MultiLotTableHeader.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object),
  setTableData: PropTypes.func,
  header: PropTypes.object,
  headerInfo: PropTypes.object,
  columns: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  faded: PropTypes.bool,
  validating: PropTypes.bool,
}