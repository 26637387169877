class SignatureAPI {
    constructor(api, version) {
      this.api = api;
      this.version = version;
      this.path = `${this.version}/signature`
    }
  
    /**
     * Lists all signatures from the back end server.
     */
    listSignatures() {
      return this.api.get(`${this.path}/`);
    }
  
    /** {data: {image: string/url, name: string, pk: integer/primary key ...} ...}
     * Lists signatures as images to be used with the image manager.
     */
    listImages() {
      return this.listSignatures().then((response) => {
        let mappedData = response.data.map((responseObject) => {
          return {image: responseObject.signature, name: responseObject.pk, pk: responseObject.pk}
        })
  
        return {data: mappedData};
      }).catch((error) => {
        return error;
      });
    }
  
    /**
     * Lists signatures that were uploaded by the current user.
     */
    getSignaturesForUser() {
      return this.api.get(`${this.path}/get_by_user/`);
    }
  
    /**
     * Retrieves a specific image from the back end server with 
     * primary key pk
     * @param {*} pk - The primary key of the desired signature
     */
    retrieveSignature(pk) {
      return this.api.get(`${this.path}/${pk}`);
    }
  
    /**
     * Deletes an image from the back end server
     * @param {*} pk - The primary key of the signature to delete
     */
    deleteSignature(pk) {
      return this.api.delete(`${this.path}/${pk}`);
    }
  
    /**
     * Uploads a signature to the back end server. Fields are signature and haccp_plan.
     * @param {*} payload - The payload to upload. Must be form data, signature field is required.
     */
    uploadSignature(payload) {
      let headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      };
      return this.api.post(`${this.path}/`, payload, headers);
    }
  }
  
  export default SignatureAPI;