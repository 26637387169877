import React from "react"

import useMediaQuery from '@material-ui/core/useMediaQuery';

/**
 * Returns whether the user is on desktop/tablet or not 
 */
export default function useDesktop() {
  const onDesktop = useMediaQuery('(min-width:600px)');
  return onDesktop;
}

