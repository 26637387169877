import React, { forwardRef, useEffect, useState } from "react";
import API from "../../Api/Api";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import HazardDatasetTable from "../Hazards/HazardDatasetTable";
import Grid from "@material-ui/core/Grid";

export default function IngredientDatasetTable(props) {
  function getAPI() {
    return new API().getIngredientDatasetAPI();
  }

  const [ingredientDataset, setIngredientDataset] = useState([]);
  const [ingredientSelected, setIngredientSelected] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  function getDataset() {
    getAPI()
      .listDataset()
      .then((response) => {
        setIngredientDataset(response.data);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getDataset();
  }, []);

  const ingredientColumns = [{ title: "Name", field: "name" }];

  const [selectedRow, setSelectedRow] = useState(null);

  const [ingredientHazards, setIngredientHazards] = useState([]);
  useEffect(() => {
    if (selectedRow) {
      let ingredientSelected = ingredientDataset.find((ingredient) => {
        return ingredient.pk === selectedRow;
      });

      setIngredientSelected(ingredientSelected);

      if (ingredientSelected) {
        setIngredientHazards(ingredientSelected.hazards);
      }
    }
  }, [selectedRow]);

  const [hazardsSelected, setHazardsSelected] = useState([]);

  function cancel() {
    if (props.setCFIADatabaseOpen) {
      props.setCFIADatabaseOpen(false);
    }
    if (props.handleCFIADatabaseClosed) {
      props.handleCFIADatabaseClosed();
    }
  }

  return (
    <div style={{ maxWidth: "100%" }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MaterialTable
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
            title="CFIA Ingredient Database"
            columns={ingredientColumns}
            data={ingredientDataset}
            isLoading={isLoading}
            onRowClick={(evt, selectedRow) => {
              setSelectedRow(selectedRow.pk);
            }}
            options={{
              rowStyle: (rowData) => ({
                backgroundColor: selectedRow === rowData.pk ? "#EEE" : "#FFF",
              }),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <HazardDatasetTable
            hazards={ingredientHazards}
            setHazardsSelected={setHazardsSelected}
            handleCFIADatabaseClosed={cancel}
          />
        </Grid>
        {props.selectIngredient && (
          <Grid item xs={12}>
            <Button
              onClick={() => {
                props.selectIngredient(ingredientSelected, hazardsSelected);
              }}
              variant="contained"
              color="primary"
            >
              Select Ingredient and Hazards
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: "8px" }}
              onClick={cancel}
            >
              Cancel
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
