import React, { useEffect, useState } from 'react';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import DetailCell from "./DetailCell";
import DetailLine from "./DetailLine";
import PropTypes from "prop-types";
import { StylesContext } from "../../App";
import SelectIngredient from 'Traceability/Production/SelectIngredient';
import { Button } from '@material-ui/core';


export default function DetailRow(props) {
  const classes = React.useContext(StylesContext);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const openPopup = (rowData) => {
    setSelectedRowData(rowData);
    setPopupOpen(true);
  };

  //A function to set all detail lines for the row, to be used for adding/removing detail lines.
  function setLines(newData) {
    let detailCol = props.colInfo[props.colInfo.length - 1];
    if (detailCol.type != 'detail' && detailCol.type != 'detailAuto') {
      throw 'Please place detail column last.'
    }
    let detailKey = detailCol.key;

    let newDetailData = [...props.rowData[detailKey]];
    newDetailData = newData;
    props.setRowData({ ...props.rowData, [detailKey]: newDetailData });
  }

  //Make each cell in the row, one cell per field.
  function makeCells() {
    let detailCol = props.colInfo[props.colInfo.length - 1];
    if (detailCol.type != 'detail' && detailCol.type != 'detailAuto') {
      throw 'Please place detail column last.'
    }

    const cells = props.colInfo.map((cellData, i) => (
      <DetailCell
        key={i}
        rowData={props.rowData}
        setRowData={props.setRowData}
        setLineData={setLines}
        colInfo={props.colInfo[i]}
        onDetailClick={props.onDetailClick}
        disabled={props.disabled}
        faded={props.faded}
      />
    ));

    // Add the button for opening the popup component
    if (!props.disabled) {
      cells.push(
        <Grid item key="popupButton">
          <Button
            variant="contained"
            color="primary"
            onClick={() => openPopup(props.rowData)}
          >
            Choose from lot codes
          </Button>
        </Grid>
      );
    }

    return cells;
  }

  //Javascript weirdness requires this to be done this way as there's no pointers.
  //We set up a function that has all needed values for the function it returns,
  //and return that function from here.
  function returnFunction(i, detailKey) {
    return function (newData) {
      let newDetailData = [...props.rowData[detailKey]];
      newDetailData[i] = newData;

      props.setRowData({ ...props.rowData, [detailKey]: newDetailData });
    }
  }

  //Get an array of functions to set the data in each line, to be passed to those lines.
  function getLineDataFunctions(detailKey) { //may need to copy this with a let
    var funcArray = [];
    //Using a for loop here for clarity and to be able to use "let" for the iterator.
    if (props.rowData && props.rowData[detailKey]) {
      for (let i = 0; i < props.rowData[detailKey].length; i++) {
        funcArray.push(returnFunction(i, detailKey));
      }
    }

    return funcArray;
  }

  //Make each detail line.
  function makeLines() {
    let detailCol = props.colInfo[props.colInfo.length - 1];
    if (detailCol.type != 'detail' && detailCol.type != 'detailAuto') {
      throw 'Please place detail column last.'
    }
    let detailKey = detailCol.key;
    let setLineFunctions = getLineDataFunctions(detailKey);

    if (!props.rowData || !props.rowData[detailKey]) {
      return;
    }

    return props.rowData[detailKey].map((detailLine, i) => {
      if (detailLine.detailSelected) {
        return (
          <DetailLine
            key={i}
            lineData={detailLine}
            setlineData={setLineFunctions[i]}
            rowData={props.rowData}
            setRowData={props.setRowData}
            onDetailClick={props.onDetailClick}
            lineInfo={props.detailInfo}
            disabled={props.disabled}
            faded={props.faded}
          />
        )
      }

    })
  }
  function handleSubmitIngrident(res) {
    let manorderdata = props.manOrderData;
    let index = manorderdata.production_ingredient_lines.findIndex(item => item.ingredient === res.ingredient);

    if (index !== -1) {
      // Replace the object at the found index with res
      manorderdata.production_ingredient_lines[index] = res;
    }

    props.setManOrderData(manorderdata)
    setSelectedRowData(res);
    props.setRowData(res);
    props.onRowUpdate(res);
    setPopupOpen(false);
    return;
  }

  return (
    <>
      {props.colInfo &&
        <Paper square className={classes.detailFormRow} style={{ backgroundColor: props.faded ? 'grey' : '#FFF' }}>
          <Grid container spacing={2}>
            {makeCells()}
          </Grid>
          {makeLines()}
        </Paper>
      }
      {isPopupOpen && (
        <SelectIngredient
          open={isPopupOpen}
          handleClose={() => setPopupOpen(false)}
          handleSubmit={handleSubmitIngrident}
          rowData={selectedRowData}
          units={props.units}
        />
      )}
    </>
  )
}

DetailRow.propTypes = {
  rowData: PropTypes.object,
  setRowData: PropTypes.func,
  setLineData: PropTypes.func,
  colInfo: PropTypes.object,
  units: PropTypes.objectOf(PropTypes.object),
  detailInfo: PropTypes.object,
  onDetailClick: PropTypes.func,
  size: PropTypes.number,
  disabled: PropTypes.bool,
  faded: PropTypes.bool,
  onRowUpdate: PropTypes.func,
}