import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import PropTypes from "prop-types";
import TraceabilityUtils from "../TraceabilityUtils";
import {StylesContext} from "../../App";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '95%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  cell: {
    border: '1px solid black',
    padding: '5px',
  },
  grid: {
    width: '100%',
  },
  select: {
    width: '100%',
  },
}));
  
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultiLotTableLine(props) {
  //const classes = useStyles();
  const classes = React.useContext(StylesContext);
  const utils = new TraceabilityUtils();

  function handleChange(event)  {
    props.setTableData({...props.rowData, [event.target.name]: event.target.value});
  }

  function cancelLine() {
    props.setTableData({...props.rowData, detailSelected: false});
  }

  //Make fields for the line.
  function makeFields() {
    
    return props.columns.map((fieldObj, i) => {

      if(fieldObj.type == 'text') {
        return (
          <Grid key={i} item xs>
            <TextField
              name={fieldObj.key}
              label={fieldObj.title}
              error={fieldObj.validate ? fieldObj.validate(props.rowData[fieldObj.key]) : false}
              fullWidth
              value={props.rowData[fieldObj.key] || ''}
              onChange={handleChange}
              disabled={props.disabled || fieldObj.disabled}
            />
          </Grid>
        )
      }
      else if(fieldObj.type == 'numeric') {
        return (
          <Grid key={i} item xs>
            <TextField
              name={fieldObj.key}
              label={fieldObj.title}
              fullWidth
              error={fieldObj.validate ? fieldObj.validate(props.rowData[fieldObj.key]) : false}
              value={props.rowData[fieldObj.key]}
              onChange={handleChange}
              disabled={props.disabled || fieldObj.disabled}
            />
          </Grid>
        )
      }
      else if(fieldObj.type == 'select') {
        return (
          <Grid item xs>
            <FormControl className={classes.detailFormControl}>
              <InputLabel id="demo-mutiple-chip-label">{fieldObj.title}</InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                id={fieldObj.key}
                name={fieldObj.key}
                error={fieldObj.validate ? fieldObj.validate(props.rowData[fieldObj.key]) : false}
                className={classes.multiLotTableSelect}
                value={props.rowData[fieldObj.key] || null}
                onChange={handleChange}
                disabled={props.disabled || fieldObj.disabled}
              >
                {Object.keys(fieldObj.choices).map((choiceKey) => (
                  <MenuItem key={choiceKey} value={choiceKey}>
                    {fieldObj.choices[choiceKey]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )
      }
      else if(fieldObj.type == 'multiselectDisplay') {
        let components = props.rowData[fieldObj.key].map((selected) => {
          let foundObj = fieldObj.choices.find(choice => choice.pk == selected)
          if(foundObj) {        
            return (<Typography key={selected}  noWrap className={classes.multiLotTableDisplayTypography}>
              {foundObj[fieldObj.choice_label]} 
            </Typography>)
          }
        })

        return (<Grid alignItems="center" container item xs>
          {components}
        </Grid>);
      }
      else if(fieldObj.type == 'multiselect') {
        return (
          <Grid item xs>
          <FormControl className={classes.detailFormControl}>
            {/*<InputLabel>{fieldObj.title}</InputLabel>*/}
            <InputLabel></InputLabel>
            <Select
              id={fieldObj.key}
              name={fieldObj.key}
              multiple
              className={classes.multiLotTableSelect}
              fullWidth
              value={props.rowData[fieldObj.key]}
              onChange={handleChange}
              error={fieldObj.validate ? fieldObj.validate(props.rowData[fieldObj.key]) : false}
              disabled={props.disabled || fieldObj.disabled}
              input={<Input id="select-multiple-chip" disabled={props.disabled} />}
              renderValue={(selected) => (
                <div className={classes.detailChips}>
                  {selected.map((value) => {
                    let foundObj = fieldObj.choices.find(choice => choice.pk == value)
                    if(foundObj) {
                      
                      return (<Chip 
                        key={value} 
                        label={foundObj[fieldObj.choice_label]} 
                        className={classes.detailChip} 
                      />)
                    }
                    else {
                      return (<Chip key={value} label={'Missing index: ' + value} className={classes.detailChip} />)
                    }
                  })}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {Object.keys(fieldObj.choices).map((choiceKey) => (
                <MenuItem key={choiceKey} value={choiceKey}>
                  {fieldObj.choices[choiceKey]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Grid>
        )
      }
      else if(fieldObj.type == 'display') {
        return (
          <Grid item container xs alignItems="center">
            <Typography noWrap className={classes.multiLotTableDisplayTypography}>
              {isNaN(props.rowData[fieldObj.key]) ? props.rowData[fieldObj.key] : utils.formatNumber(props.rowData[fieldObj.key])}
            </Typography>
          </Grid>
        )
      }
    })
  }

  function handleEditClick() {
    //the indices are handled in wrappers wehn being passed to this component

    props.setEditingRow()
    props.setEditingTable()
    props.setModalOpen(true);
  }

  return (
    <>
      {props.rowData &&
      
        <Paper elevation={0}  className={classes.multiLotTableRowPaper} style={{backgroundColor: props.faded ? 'grey' : '#FFF'}}>
          
          <Grid item container spacing={3} className={classes.multiLotTableRow}>
            {makeFields()}
            <Grid item xs={1}>
              <Button
                variant="outlined"
                color="primary"
                style={{ margin: "8px" }} 
                disabled={props.disabled}
                onClick={() => {handleEditClick()}}
              >
                Edit
              </Button>
            </Grid>
          </Grid>
        </Paper>
      }
    </>
  )
}

MultiLotTableLine.propTypes = {
  rowData: PropTypes.arrayOf(PropTypes.object),
  setTableData: PropTypes.func,
  header: PropTypes.object,
  columns: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  faded: PropTypes.bool,
  setEditingTable: PropTypes.func,
  setEditingRow: PropTypes.func,
  setModalOpen: PropTypes.func,
}