import React, {useEffect} from "react";
import {NavLink, useRouteMatch} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import {makeStyles} from "@material-ui/core/styles";
import {useLocation} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  active: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function TraceabilityTabs() {
  let match = useRouteMatch();
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const fullPath = useLocation().pathname;
  const pathArray = fullPath.split("/");

  const links = [
    /*{
      label: "Traceability Details",
      path: `${match.url}/details`,
      id: 1,
    },*/
    {
      label: "Inventory Location",
      path: `${match.url}/inventory_locations`,
      id: 1,
    },
    /*{
      label: "Customers",
      path: `${match.url}/customers`,
      id: 3,
    },
    {
      label: "Suppliers",
      path: `${match.url}/suppliers`,
      id: 4,
    },
    {
      label: "SKUs",
      path: `${match.url}/skus`,
      id: 5,
    },
    {
      label: "Formula SKUs",
      path: `${match.url}/formula_skus`,
      id: 11,
    },*/
    {
      label: "Orders",
      path: `${match.url}/orders`,
      id: 6,
    },
    {
      label: "Inventory Viewer",
      path: `${match.url}/item_instances`,
      id: 7,
    },
    {
      label: "Raw Inventory",
      path: `${match.url}/raw_inventory`,
      id: 14,
    },
    {
      label: "Finished Inventory",
      path: `${match.url}/finished_inventory`,
      id: 15,
    },
    {
      label: "Production",
      path: `${match.url}/production`,
      id: 8,
    },
    {
      label: "Shipping",
      path: `${match.url}/shipping`,
      id: 9,
    },
    // {
    //   label: "Recall Manager",
    //   path: `${match.url}/recalls`,
    //   id:13,
    // },
    {
      label: "Units",
      path: `${match.url}/units`,
      id: 10,
    },
    {
      label: "Approved Information",
      path: `${match.url}/approved_information`,
      id: 12,
    },
  ];

  const indexCiper = {
    //details: 1,
    inventory_locations: 1,
    customers: 3,
    suppliers: 4,
    skus: 5,
    formula_skus: 11,
    orders: 6,
    item_instances: 7,
    production: 8,
    shipping: 9,
    units: 10,
    approved_information: 12,
    recalls: 13,
    raw_inventory: 14,
    finished_inventory: 15,
  };

  useEffect(() => {
    let tab = pathArray[pathArray.length - 1];
    setSelectedIndex(indexCiper[tab]);
  }, [fullPath, pathArray, indexCiper]);

  return (
    <div>
      <List dense>
        {links.map((link) => {
          return (
            <ListItem
              key={link.label}
              className={classes.active}
              button
              selected={selectedIndex === link.id}
              onClick={(event) =>
                handleListItemClick(event, link.id)
              }
              component={NavLink}
              to={link.path}
            >
              <ListItemText>{link.label}</ListItemText>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}
