import API from "Api/Api";

import BatchAPI from "./BatchAPI"
import ContactAPI from "./ContactAPI.js"
import CustomerAPI from "./CustomerAPI.js"
import SkuAPI from "./SkuAPI.js"
import InternalLotCodeAPI from "./InternalLotCodeAPI.js"
import ExternalLotCodeAPI from "./ExternalLotCodeAPI.js"
import InstanceItemAPI from "./InstanceItemAPI.js"
import MockRecallAPI from "./MockRecallAPI.js"
import OrderAPI from "./OrderAPI.js"
import LineItemAPI from "./LineItemAPI.js"
import ProductionSheetAPI from "./ProductionSheetAPI.js"
import RecallAPI from "./RecallAPI.js"
import ReceivingAPI from "./ReceivingAPI.js"
import ShipmentAPI from "./ShipmentAPI.js"
import SupplierAPI from "./SupplierAPI.js"
import SkuInstanceAPI from "./SkuInstanceApi"
import InventoryLocationAPI from "./InventoryLocationAPI"
import InventoryAdjustmentAPI from "./InventoryAdjustmentAPI"
import InventoryTransferAPI from "./InventoryTransferAPI"
import TraceabilityAPI from "./TraceabilityApi"
import UnitsAPI from "./UnitsAPI"
import CompanyInfoAPI from "./CompanyInfoAPI";
import FCustomerAPI from "./FCustomerAPI";
import FCustomerAddressesAPI from "./FCustomerAddressesAPI";
import FSuppliersAPI from "./FSuppliersAPI";
import FReceivingAPI from "./FReceivingAPI";
import FIngredientsAPI from "./FIngredientsAPI";
import FProductsAPI from "./FProductsAPI";
import FRawInventoryAPI from "./FRawInventoryAPI";
import FFormulationsAPI from "./FFormulationsAPI";
import FProductionAPI from "./FProductionAPI";
import FProductionsAPI from "./FProductionAPI";
import FFinishedInventoryAPI from "./FFinishedInventoryAPI";
import FShippingAPI from "./FShippingAPI";

export default class Traceability extends API {
  constructor() {
    super()
    this.organization = JSON.parse(localStorage.getItem("organization")) || null;
  }

  getBatchAPI() {
    return new BatchAPI(this.api, this.version);
  }

  getContactAPI() {
    return new ContactAPI(this.api, this.version);
  }

  getCustomerAPI() {
    return new CustomerAPI(this.api, this.version);
  }

  getSkuApi() {
    return new SkuAPI(this.api, this.version);
  }

  getSkuInstanceApi() {
    return new SkuInstanceAPI(this.api, this.version);
  }

  getInstanceItemAPI() {
    return new InstanceItemAPI(this.api, this.version);
  }

  getExternalLotCodeAPI() {
    return new ExternalLotCodeAPI(this.api, this.version);
  }

  getInternalLotCodeAPI() {
    return new InternalLotCodeAPI(this.api, this.version);
  }

  getInventoryLocationAPI() {
    return new InventoryLocationAPI(this.api, this.version);
  }

  getInventoryAdjustmentAPI() {
    return new InventoryAdjustmentAPI(this.api, this.version);
  }

  getInventoryTransferAPI() {
    return new InventoryTransferAPI(this.api, this.version);
  }

  getMockRecallAPI() {
    return new MockRecallAPI(this.api, this.version);
  }

  getOrderAPI() {
    return new OrderAPI(this.api, this.version);
  }

  getLineItemAPI() {
    return new LineItemAPI(this.api, this.version);
  }

  getProductionSheetAPI() {
    return new ProductionSheetAPI(this.api, this.version);
  }

  getReceivingAPI() {
    return new ReceivingAPI(this.api, this.version);
  }

  getRecallAPI() {
    return new RecallAPI(this.api, this.version);
  }

  getFCustomerAPI() {
    return new FCustomerAPI(this.api, this.version);
  }

  getFCustomerAddressesAPI() {
    return new FCustomerAddressesAPI(this.api, this.version);
  }

  getFSuppliersAPI() {
    return new FSuppliersAPI(this.api, this.version);
  }

  getFReceivingAPI() {
    return new FReceivingAPI(this.api, this.version);
  }
  
  getFShippingAPI() {
    return new FShippingAPI(this.api, this.version);
  }

  getFFormulationsAPI() {
    return new FFormulationsAPI(this.api, this.version);
  }

  getFIngredientsAPI() {
    return new FIngredientsAPI(this.api, this.version);
  }

  getFProductsAPI() {
    return new FProductsAPI(this.api, this.version);
  }

  getFProductionsAPI() {
    return new FProductionsAPI(this.api, this.version);
  }

  getFFinishedInventoryAPI() {
    return new FFinishedInventoryAPI(this.api, this.version);
  }

  getFRawInventoryAPI() {
    return new FRawInventoryAPI(this.api, this.version);
  }

  getShippingAPI() {
    return new ShipmentAPI(this.api, this.version);
  }

  getSupplierAPI() {
    return new SupplierAPI(this.api, this.version);
  }
  getCompanyInfoAPI() {
    return new CompanyInfoAPI(this.api, this.version);
  }

  getInstanceManagerAPI() {
    return new TraceabilityAPI(this.api, this.version);
  }

  getUnitsAPI() {
    return new UnitsAPI(this.api, this.version);
  }
}