import React, { useEffect, useState } from "react";
import API from "../../Api/Api";
import ExportPreview from './ExportPreview';

import {updateDiagramHeadless} from "../DiagramDrawer/DiagramUpdater";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";

import MuiAlert from '@material-ui/lab/Alert';
import ContentHeader from "../../Components/Layouts/ContentHeader";

const FileSaver = require('file-saver');

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/**
 * A page to print haccp plans by specfying which sections to print. Has a previewer
 * built in.
 *
 * @param {*} props - Object: an object of the form {pk: int}, where pk is the
 *                        primary key of the haccp plan to be printed.
 */
function ExportDetail(props) {
  const printableSections = [
    {label: 'Product Description Worksheets', id: 'printDescriptions'},
    {label: 'Hazard Analysis Worksheets', id: 'printHazardAnalysis'},
    {label: 'CCP Determination Worksheets', id: 'printCCB'},
    {label: 'HACCP Plan Worksheets', id: 'printHACCP'},
    {label: 'Process Flow Diagram', id: 'printProcessFlow'},
    {label: 'Traffic Flow Diagram', id: 'printTrafficFlow'},
  ];

  const api = new API().getHaccpPlanAPI();

  const [checked, setChecked] = React.useState([]);
  const [pdfFile, setPdfFile] = React.useState(null);
  const [pdfUrl, setPdfUrl] = React.useState(null);
  const [pdfPreviewer, setPreview] = React.useState(<ExportPreview pdf={pdfFile}/>);
  const [snackbarOpen, setSnackBarOpen] = React.useState(props.snackbarOpen);
  const [loading, setLoading] = React.useState(false);

  const LOADER = <div style={{width: '100%', textAlign: 'center', marginTop: '30%'}}><CircularProgress/></div>;

  /**
   * Creates a payload for the backend to get the printed haccp plan
   * containing only the sections specified in check.
   *
   * @param {*} newChecked - Array: An of the form [1,2,...,n] where n
   *  is the length of printable sections and each integer is the index
   *  of a section to print. The integers do not need to be contiguous.
   */
  const makePayload = (newChecked) => {
    let payload = {};
    newChecked.forEach((index) => {
      payload[printableSections[index].id] = true;
    });

    return payload;
  }

  /**
   * Runs when user clicks a checkbox to specify which sections to print. Updates the state
   * representing checked boxes and gets a new pdf from the server.
   *
   * @param {*} value
   */
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setLoading(true);
    getPdf(newChecked);
  };

  /**
   * Can check all section check boxes either on or off.
   *
   * @param {*} on - Boolean: if true, all boxes are checked. Otherwise all boxes are
   *  unchecked.
   */
  const checkAll = (on) => {
    setLoading(true);
    if (on) {
      let newChecked = printableSections.map((section, i) => {
        return i;
      })
      setChecked(newChecked);
      getPdf(newChecked);
    } else {
      setChecked([]);
      getPdf([]);
    }
  };

  /**
   * Makes a list of check boxes for each option in the printableSections object.
   */
  const makeList = () => {
    let listItems = printableSections.map((section, i) => {
      return (
        <ListItem key={i} dense button onClick={handleToggle(i)}>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checked.indexOf(i) !== -1}
              tabIndex={-1}
              disableRipple
              inputProps={{'aria-labelledby': section.id}}
            />
          </ListItemIcon>
          <ListItemText id={section.id} primary={section.label}/>
        </ListItem>
      )
    });

    return (
      <List>
        {listItems}
      </List>
    );
  };


  const [intervalTimer, setIntervalTimer] = useState(null);
  
  // useEffect(() => {
  //   return function cleanup() {
  //     clearTimer();
  //   }
  // }, [intervalTimer, clearTimer]);

  function clearTimer(timer=null) {
    if (timer != null) {
        clearInterval(timer);
    } else {
        clearInterval(intervalTimer);
    }
  }

  /**
   * Gets a pdf from the backend server with chosen sections only
   *
   * @param {*} newChecked - Array: An array of indices for sections in
   *  printableSections object to print
   */
  const getPdf = async (newChecked) => {
    setPreview(LOADER);
    
    await updateDiagramHeadless(props.pk);

    api.exportPlanDetailed(props.pk, makePayload(newChecked)).then((e) => {
        let jobId = e.data;

        const pingStatus = setInterval(() => {
          api.exportJobStatus(props.pk, jobId).then(response => {
            if (response.headers['content-type'] === "application/pdf") {
              let newPdfFile = new Blob([response.data], {type: response.headers['content-type']});
              let newPdfUrl = URL.createObjectURL(newPdfFile);

              setPdfFile(newPdfFile);
              setPdfUrl(newPdfUrl);
              setPreview(<ExportPreview url={newPdfUrl} loader={LOADER}/>);
              setLoading(false);

              clearTimer(pingStatus);
            }
          }).catch(error => {
            console.log(error);
            clearTimer(pingStatus);
          })
        }, 1000); // check job status every second

        setIntervalTimer(pingStatus);
      }
    ).catch((e) => {
        alert('Cannot print from server: ' + e);
        setLoading(false);
      });
  };

  const savePdf = () => {
    if (!pdfUrl) {
      setSnackBarOpen(true);
    } else {
      FileSaver.saveAs(pdfUrl, 'HACCPPlan.pdf');
    }
  };

  return (
    <>
    <ContentHeader title="Export"> </ContentHeader>
    <Grid container spacing={3} style={{padding: "32px", marginBottom: "72px"}}>
      <Grid item xs={12}>
        {loading
          ? (<Button
            style={{margin: "8px"}}
            variant="contained"
            color="secondary"
            onClick={() => setSnackBarOpen(true)}
          >
            Loading...
          </Button>)
          : (<Button
            style={{margin: "8px"}}
            variant="contained"
            color="primary"
            onClick={savePdf}
          >
            Export
          </Button>)}
        <Button variant="contained" onClick={() => {
          checkAll(true)
        }}>
          Select All
        </Button>
        <Button style={{margin: "8px"}} variant="contained" onClick={() => {
          checkAll(false)
        }}>
          Select None
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Paper elevation={1} style={{height: '730px'}}>
          {makeList()}
        </Paper>
      </Grid>

      <Grid item xs={9}>
        {pdfPreviewer}
      </Grid>

      <Snackbar
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        autoHideDuration={3000}
        open={snackbarOpen}
        onClose={() => {
          setSnackBarOpen(false)
        }}
        message="Pdf not fully loaded yet."
      >
        <Alert onClose={() => {
          setSnackBarOpen(false)
        }} severity='warning'>
          Pdf not fully loaded yet.
        </Alert>
      </Snackbar>
    </Grid>
    </>
  );
}

export default ExportDetail;
