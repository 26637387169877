class TraceabilityAPI {
  constructor(api, version) {
    this.api = api;
    this.version = version;
  }
  
  listTraceabilities(pk) {
    if (typeof pk === 'number') {
      return this.api.get(`${this.version}/traceabilities/${pk}/`);
    }
  
    return this.api.get(`${this.version}/traceabilities/`)
  }
  
  createTraceability(payload) {
    return this.api.post(`${this.version}/traceabilities/`, payload);
  }
  
  updateTraceability(payload) {
    return this.api.put(`${this.version}/traceabilities/${payload.pk}/`, payload);
  }
  
  deleteTraceability(pk) {
    return this.api.delete(`${this.version}/traceabilities/${pk}/`);
  }
}  
  
export default TraceabilityAPI