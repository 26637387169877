import React, { useEffect, useState } from "react";

import MaterialTable from "material-table";
import API from "../../Api/Api";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Message from "../../Components/Message";
import SupplierForm from "./SupplierForm";
import DialogContent from "@material-ui/core/DialogContent";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Traceability from "../Traceability";
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import {StylesContext} from "../../App";

const EMPTY_MESSAGE = {
  open: false,
  message: '',
  status: 'info',
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SupplierList(props) {
  const classes = React.useContext(StylesContext);

  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [supplierModalOpen, setSupplierModalOpen] = useState(false);
  const [shouldClose, setShouldClose] = useState(true);
  const [supplierSelected, setSupplierSelected] = useState(-1);
  const [user, setUser] = useState(null);
  const [message, setMessage] = useState( {...EMPTY_MESSAGE} );

  useEffect(() => {
    //this skips the initial render pulling the modal up.
    if(loading) {
      return;
    }

    setSupplierModalOpen(true);
  }, [supplierSelected])

  useEffect(() => {
    const api = new Traceability().getSupplierAPI();
    const authApi = new API().getAuthAPI();
    authApi.getAuthedProfile().then(e => {
      setUser(e.data);
    }).catch(e => {
      //console.log(e);
    })

    if(props.suppliers) {
      let supplierObjects = props.suppliers;

      setSuppliers(supplierObjects);
      setLoading(false);
      return;
    }

    api.listSuppliers().then(response => {
      let supplierObjects = response.data;

      setSuppliers(supplierObjects);
      setLoading(false);
    }).catch(e => {
      //console.log(JSON.stringify(e));
    })

  }, []);

  const supplierColumns = [
    { title: "Company", field: "company" },
    { title: "Email", field: "email" },
    { title: "Phone Number", field: "phone_number" },
    { title: "Fax Number",  field: "fax_number" },
    //{ title: "Address", field: "address"}
  ];

  function addSupplier() {
    //it needs to be different to register, so just flip it back and forth.
    setSupplierSelected(supplierSelected == -1 ? -2 : -1);
  }

  function handleSupplierClosed() {
    if (shouldClose) {
      setSupplierModalOpen(false);
    } else {
      alert("Save changes before closing.")
    }
  }

  function closeModal() {
    if(!shouldClose) {
      if(!window.confirm("Are you sure you want to close without saving?")) {
        return;
      }
    }

    cancel();
  }

  function cancel() {
    setShouldClose(true);
    setSupplierModalOpen(false);
  }

  function onSave(response) {
    let updatedSupplierPk = response.data.pk;
    let updatedSuppliers = [...suppliers];

    let supplierIndex = updatedSuppliers.findIndex(supplier => {
      return supplier.pk === updatedSupplierPk;
    });

    if (supplierIndex > -1) {
      updatedSuppliers.splice(supplierIndex, 1, response.data);
    }
    else {
      updatedSuppliers.push(response.data);
    }

    setSuppliers(updatedSuppliers);
    setMessage({
      open: true,
      message: 'Saved Successfully',
      status: 'success',
    });
  }

  function deleteSupplier(supplier, resolve, reject) {
    const api = new Traceability().getSupplierAPI();

    api.deleteSupplier(supplier.pk).then(response => {
      let updatedSuppliers = [...suppliers];
      let supplierIndex = updatedSuppliers.findIndex(updatedSupplier => {
        return updatedSupplier.pk === supplier.pk;
      });

      if (supplierIndex > -1) {
        updatedSuppliers.splice(supplierIndex, 1);
      }

      setMessage({
        open: true,
        message: 'Deleted Successfully',
        status: 'success',
      });

      setSuppliers(updatedSuppliers);
      resolve();
    }).catch(error => {
      //console.log(error);
      reject();
    })
  }


  return (
    <>
      { loading &&
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginTop: "250px" }}>
            <Typography>
              <CircularProgress />
            </Typography>
          </Grid>
        </Grid>
      }

      { (!loading && suppliers) && <>
        <Paper elevation={0} square variant="outlined" className={classes.generalListPaperContainer} >
          <Grid container spacing={3} className={classes.generalContainerGrid}>
            <Grid item xs={12}>

              <MaterialTable
                components={{
                  Container: props => <Paper {...props} elevation={0} />
                }}

                isLoading={loading} 
                title="Suppliers" 
                columns={supplierColumns} 
                data={suppliers}

                style={{ width: "100%" }}
                localization={{ body: { editRow: { deleteText: 'Are you sure you want to delete this Supplier? This action cannot be reversed.' } } }}

                editable={{
                  onRowDelete: (newData) => new Promise((resolve, reject) => {
                    deleteSupplier(newData, resolve, reject);
                  })
                }}

                actions={
                  [
                    {
                      icon: (props) => (
                        <Button size="small" color="primary" variant="contained" aria-label="add" className={classes.tabTableFreeAction}>
                          Add Supplier
                        </Button>
                      ),
                      tooltip: 'Add Ingredient',
                      isFreeAction: true,
                      onClick: (event) => addSupplier()
                    },
                  ]
                }

                onRowClick={(event, rowData, togglePanel) => {
                  setSupplierSelected(rowData.tableData.id);

                  if(supplierSelected != rowData.tableData.id) {
                    setSupplierSelected(rowData.tableData.id);
                  }
                  else {
                    setSupplierModalOpen(true);
                  }
                }}

                options={{
                  actionsColumnIndex: -1,
                  //exportButton: true,
                  pageSize: 10,
                }}

                icons={{
                  Export: MoreVertIcon,
                }}
              />

            </Grid>
          </Grid>
        </Paper>

        <Dialog fullScreen open={supplierModalOpen} onClose={handleSupplierClosed} TransitionComponent={Transition}>
          <DialogContent className={classes.generalFormDialogueContainer}>
            <SupplierForm
              supplier={supplierSelected >= 0 ? suppliers[supplierSelected] : {}}
              setShouldClose={setShouldClose}
              closeModal={closeModal}
              cancel={cancel}
              onSave={onSave}
              user={user}
              activeTraceability={props.activeTraceability}
            />
          </DialogContent>
        </Dialog>

        <Message
          open={message.open}
          message={message.message}
          severity={message.status}
          vertical="bottom"
          horizontal="left"
          handleClose={() => {setMessage( {...message, open:false} )}}
        />
      </>}
    </>
  )
}

SupplierList.propTypes = {
  suppliers: PropTypes.arrayOf(PropTypes.object),
  activeTraceability: PropTypes.object.isRequired,
}
