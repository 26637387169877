import React, { useEffect, useState } from "react";

import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close"

export default function useStatusMessage(autoHideDuration=6000, actions=[]) {
	const [message, setMessage] = useState({severity: "info", label: ""});
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (message.length && !open) {
			setOpen(false);
		}
	}, [message]);

	useEffect(() => {
		if (open && autoHideDuration) {
			const interval = setInterval(onClose, autoHideDuration);
			return () => clearInterval(interval);
		}	
	}, [open, autoHideDuration]);

	function onClose() {
		setOpen(false);
	}

	function updateMessage(newMessage) {
		setMessage(newMessage);		
		setOpen(true);
	}

	if (!actions.length) {
		actions = [
			{icon: <CloseIcon/>, task: onClose},
		]
	}

	let status = (
		<StatusMessage
			open={open}  
			origin={{vertical: "bottom", horizontal: 'center'}}
			buttons={
				actions.map((action, index) => {
					return (
						<IconButton key={index} size="small" aria-label="close" color="inherit" onClick={action.task}>
							{action.icon}	
						</IconButton>
					)
				})
			}
			message={message}
			autoHideDuration={null}
		/>
	)

	return [updateMessage, status];
}

export function StatusMessage({
	origin,
	open,
	onClose,
	message,
	buttons,
	autoHideDuration,
}) {
	return (
		<Snackbar
			anchorOrigin={{
				vertical: origin.vertical,
				horizontal: origin.horizontal
			}}
			open={open}
			autoHideDuration={autoHideDuration}
			onClose={onClose}
    >
			<Alert severity={message.severity}>
				{message.label}
				{buttons}
			</Alert>
		</Snackbar>
	)
}