class FacilityAPI {

    constructor(api, version, name) {
      this.api = api;
      this.version = version;
      this.name = name;
    }
  
    listFacilities() {
      return this.api.get(`${this.version}/facility/`)
    }
  
    createFacility(payload) {
      return this.api.post(`${this.version}/facility/`, payload);
    }
  
    retrieveFacility(pk) {
      return this.api.get(`${this.version}/facility/${pk}/`);
    }
  
    updateFacility(pk, payload) {
      return this.api.put(`${this.version}/facility/${pk}/`, payload);
    }
  
    deleteFacility(pk) {
      return this.api.delete(`${this.version}/facility/${pk}/`);
    }
  }
  export default FacilityAPI;
  
