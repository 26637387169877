export default class OnboardingAPI {
  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  getSections() {
    return this.api.get(`${this.version}/onboarding/`);
  }

  getInputs() {
    return this.api.get(`${this.version}/onboarding_inputs/`);
  }
  updateInput(payload) {
    return this.api.put(
      `${this.version}/onboarding_inputs/${payload.id}/`,
      payload
    );
  }
}
