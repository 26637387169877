import React, { useState, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import TrafficFlowDiagram from "./TrafficFlowDiagram";
import API from "../../Api/Api";

import Message from "../../Components/Message";

import { createStore } from "redux";
import { Provider } from "react-redux";

const initialState = {
  count: 55,
  index: 0,
  history: [],
  value: "",
  event: "",
  curState: [],
  past: [],
  present: {},
  future: [],
  actions: []
};

function reducer(state = initialState, action) {
  // console.log("action: ", action);
  // console.log("Past is: ", state.past);
  // console.log("Present is: ", state.present);
  // console.log("Future is: ", state.future);
  // console.log("Actions are: ", state.actions);
  // console.log("Index is: ", state.index);
  // console.log("-------------------");
  const {past, present, future, actions} = state;
  const index = state;
  switch (action.type) {

    case "undo":
      if (actions.length > 0 && past.length > 0) {
        return {
          ...state,
          future: state.future.concat(state.present),
          present: state.past[state.past.length - 1],
          past: state.past.slice(0, state.past.length - 1),
        }
      } else {
        return state;
      }
    case "redo":
      if (actions.length > 0 && future.length > 0) {
        return {
          ...state,
          past: state.past.concat(state.present),
          present: state.future[state.future.length - 1],
          future: state.future.slice(0, state.future.length - 1),
        }
      } else {
        return state;
      }
    case "addToCurrent":
      return {
        ...state,
        count: state.count - 1,
        history: state.history.concat({id: Math.random(), count: state.count - 1}),
        curState: state.curState.concat({id: Math.random(), curState: action.value}),
      }
    case "addAction":
      return {
        ...state,
        past: state.past.concat(state.present),
        actions: state.actions.concat({id: Math.random(), event: action.event, actions: action.value}),
        present: {id: Math.random(), event: action.event, present: action.value},
      }
    default:
      return state;

  }
}

const store = createStore(reducer);

export default function TrafficFlow(props) {
  let api = new API();
  
  const [haccpPlan, setHaccpPlan] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let pk = props.haccpPlanPk;
    setIsActive(true);
    api.getHaccpPlanAPI()
      .retrieveHaccpPlan(pk)
      .then((response) => {
        let haccpPlan = response.data;
        setHaccpPlan(haccpPlan);

        if (response.data.traffic_flow_diagram != null) {
          setLoading(false);
        } else {
          // create and add a process flow diagram to the haccp plan
          let formData = new FormData();
          formData.append("organization", response.data.organization);

          api.getTrafficFlowDiagramAPI()
            .createTrafficFlowDiagram(formData)
            .then((response) => {
              api.getHaccpPlanAPI()
                .updateHaccpPlan({
                  ...haccpPlan,
                  traffic_flow_diagram: response.data.pk,
                })
                .then((response) => {
                  setHaccpPlan(response.data);
                  setLoading(false);
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.haccpPlanPk]);

  function addTrafficFlowDiagram(traffic_flow_pk) {
    const updatedHaccpPlan = {
      ...haccpPlan,
      traffic_flow_diagram: traffic_flow_pk,
    };

    api.getHaccpPlanAPI()
      .updateHaccpPlan(updatedHaccpPlan)
      .then((response) => {
        setHaccpPlan(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function deleteTrafficFlowDiagram() {
    api.getTrafficFlowDiagramAPI()
      .deleteTrafficFlowDiagram(haccpPlan.traffic_flow_diagram)
      .then((response) => {
        const updatedHaccpPlan = {
          ...haccpPlan,
          traffic_flow_diagram: null,
        };
        api.getHaccpPlanAPI()
          .updateHaccpPlan(updatedHaccpPlan)
          .then((response) => {
            setHaccpPlan(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function successMessage() {
    if (props.successMessage) {
      props.successMessage();
    }
  }

  return (
    <div id="traffic_flow" style={{ height: "100%" , width: "100%"}}>
      {haccpPlan.traffic_flow_diagram &&
      haccpPlan.process_flow_diagram &&
      !loading &&
      <Provider store={store}>
        <TrafficFlowDiagram
          readOnlyMode={props.readOnlyMode} 
          setIsBlocking={props.setIsBlocking}
          haccpPlan={haccpPlan}
          pk={haccpPlan.traffic_flow_diagram}
          deleteTrafficFlowDiagram={deleteTrafficFlowDiagram}
          successMessage={successMessage}
        /></Provider>
        }

      {/* {!haccpPlan.process_flow_diagram && !loading && (
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginTop: "250px" }}>
            <CircularProgress />
          </Grid>
        </Grid>
      )} */}

      {/* TODO move this to the traffic flow diagram menu   */}
      {/*{haccpPlan.traffic_flow_diagram === null && !loading &&*/}
      {/*<TrafficFlowDiagramDescription addToHaccpPlan={addTrafficFlowDiagram} haccpPlan={haccpPlan}/>*/}
      {/*}*/}
    </div>
  );
}
