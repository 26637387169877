import React from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom";

import MonitoringTasks from "./MonitoringTasks/MonitoringTasks"
import DeviationTasks from "./DeviationTasks/DeviationTasks"
import VerificationTasks from "./VerificationTasks/VerificationTasks"


export default function MyTasks(props) {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/monitoring`}>
        <MonitoringTasks myTasks user={props.user} />  
      </Route>
      <Route path={`${match.url}/deviations`}>
        <DeviationTasks user={props.user} />
      </Route>
      <Route path={`${match.url}/verification`}>
        <VerificationTasks user={props.user} />
      </Route>
    </Switch>
  )
}
