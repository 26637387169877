import React, { useState, useEffect, useRef } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import ReactQuill from "react-quill";
import ReactSignatureCreator from "Components/ReactSignatureCreator";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";

import { useHistory, useParams, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AvatarComponent from "Components/AvatarComponent";
import useDesktop from "hooks/useDesktop";

import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";

import API from "Api/Api";
import CompletedDeviationForm from "../Deviations/CompletedDeviationForm";
import CustomTextField from "Monitoring/CustomFields/CustomTextField";
import SanitizeHtml from "Components/SanitizeHtml";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "0.25px",
  },

  bg: {
    padding: "0px",
    backgroundColor: "rgb(234,234,234, 0.5)",
  },

  form: {
    padding: "16px 16px 16px 16px",
    maxWidth: "900px",
  },

  table: {
    //minWidth: 650,
    marginLeft: 0,
  },

  fakeLink: {
    color: "blue",
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

export default function VerificationForm({ user, onComplete, setClosed }) {
  const [verification, setVerification] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasChanged, setHasChanged] = useState(false);

  const [numPages, setNumPages] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);

  const classes = useStyles();
  const onDesktop = useDesktop();
  const history = useHistory();
  const params = useParams();
  const location = useLocation();

  const api = new API();

  const readOnly = verification != null && verification.time_submitted != null;

  useEffect(() => {
    const pk = params.pk;

    if (pk) {
      api.getMonitoringAPI()
        .getVerificationTask(pk)
        .then((response) => {
          let res = response.data;
          setVerification(res);

          if (res.event_records.length) {
            setNumPages(res.event_records.length);
          } else if (res.deviation_records.length) {
            setNumPages(res.deviation_records.length);
          }

          setPageIndex(0);
          setLoading(false);
        })
        .catch((error) => {
          api.createErrorReport({
            path: location.pathname,
            request: JSON.stringify({
              description: "Could not GET verification form.",
              error: error,
            }),
          });
          console.log(error);
          history.goBack();
        });
    }
  }, [user]);

  function submit(event) {
    if (readOnly) {
      return;
    }

    setLoading(true);
    api.getMonitoringAPI()
      .updateVerificationTask(verification)
      .then(() => {
        if (onComplete) {
          onComplete();
        }
        if (setClosed) {
          setClosed();
        }
        history.goBack();
      })
      .catch((error) => {
        console.error(error);
        api.createErrorReport({
          path: location.pathname,
          request: JSON.stringify({
            description: "Could not update verfication task.",
            error: error,
          }),
        });

        if (setClosed) {
          setClosed();
        }
        history.goBack();
      });
  }

  const pageEl = useRef(null);
  useEffect(() => {
    if (pageIndex != null && pageEl.current) {
      pageEl.current.scrollTop = 0;
    }
  }, [pageIndex]);

  function close(event) {
    if (
      hasChanged &&
      !readOnly &&
      !window.confirm("Are you sure you want to close this form? Any unsaved data will be lost")
    ) {
      return;
    }
    if (setClosed) {
      setClosed();
    }
    history.goBack();
  }

  function next(event) {
    if (pageIndex < numPages) {
      setPageIndex(pageIndex + 1);
    }
  }

  function previous(event) {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1);
    }
  }

  function notesChange(content, delta, source, editor) {
    setVerification({ ...verification, notes: content });
    setHasChanged(true);
  }

  const [validSignature, setValidSignature] = useState(false);

  function checkSignature(sigPad) {
    let sig = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
    setVerification({ ...verification, signature: sig });
    setValidSignature(true);
    setHasChanged(true);
  }

  function validSignatureCheck(validCheck) {
    setValidSignature(validCheck);
  }

  let eventRecord = null;
  if (verification != null && verification.event_records && verification.event_records[pageIndex]) {
    eventRecord = verification.event_records[pageIndex];
  }

  let deviationRecord = null;
  if (verification != null && verification.deviation_records && verification.deviation_records[pageIndex]) {
    deviationRecord = verification.deviation_records[pageIndex];
  }

  return (
    <Dialog open={true} fullScreen style={{ backgroundColor: "rgb(234,234,234, 0.5)" }}>
      {loading && (
        <Grid container justify="center" alignItems="center" style={{ width: "100%", height: "100%" }}>
          <CircularProgress />
        </Grid>
      )}

      {!loading && verification && (
        <>
          <DialogTitle style={{ borderBottom: "1px solid #eaeaea", padding: "8px" }}>
            <Grid container style={{ padding: "8px", overflow: "hidden" }}>
              <Grid item xs={onDesktop ? 8 : 12}>
                {pageIndex < numPages && (
                  <Typography variant="h6">
                    {`Verify Forms (${pageIndex + 1} of ${numPages})`}
                  </Typography>
                )}

                {pageIndex === numPages && <Typography variant="h6">{`Review`}</Typography>}
              </Grid>
              {onDesktop && (
                <Grid item container xs={4} alignItems="center" justify="flex-end">
                  <IconButton aria-label="close" onClick={close} style={{ padding: 0 }}>
                    <CancelIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </DialogTitle>
          <DialogContent
            ref={pageEl}
            style={{ backgroundColor: "#F8F8F8", padding: onDesktop ? "32px" : "0px" }}
          >
            {numPages > 0 && pageIndex <= numPages - 1 && deviationRecord && (
              <CompletedDeviationForm pk={deviationRecord} />
            )}

            {numPages > 0 && pageIndex <= numPages - 1 && eventRecord && (
              <Grid container justify="center" style={{ height: "100%", width: "100%" }}>
                <Paper elevation={0} style={{ maxWidth: "1200px", width: "100%" }} square>
                  <Grid container spacing={3} style={{ padding: 16 }}>
                    <Grid item xs={12}>
                      <Typography className={classes.title}>{eventRecord.event.name}</Typography>
                    </Grid>

                    {eventRecord.status === "OVERDUE" && (
                      <Grid item container xs={12}>
                        <Grid item container>
                          <Grid item>
                            <AvatarComponent
                              rounded
                              user={eventRecord.event_when_submitted.read_primary_monitor}
                            ></AvatarComponent>
                          </Grid>
                          <Grid item style={{ paddingLeft: "8px" }}>
                            {eventRecord.event_submitted.read_primary_monitor && (
                              <Typography style={{ fontSize: "14px" }}>
                                {`Assigned to ${eventRecord.event_when_submitted.read_primary_monitor.first_name} ${eventRecord.event_when_submitted.read_primary_monitor.last_name} `}
                              </Typography>
                            )}
                            <Typography style={{ fontSize: "12px" }}>
                              {`Was due ${eventRecord.due}`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {eventRecord.status !== "OVERDUE" && (
                      <Grid item container xs={12}>
                        <Grid item container>
                          <Grid item>
                            <AvatarComponent
                              rounded
                              user={eventRecord.event_submitted.read_primary_monitor}
                            ></AvatarComponent>
                          </Grid>
                          <Grid item style={{ paddingLeft: "8px" }}>
                            <Typography style={{ fontSize: "14px" }}>
                              {`Completed by ${eventRecord.submitted_by} `}
                            </Typography>
                            <Typography style={{ fontSize: "12px" }}>
                              {`on ${eventRecord.date_submitted} at ${eventRecord.time_submitted}`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {eventRecord.status === "OVERDUE" && (
                      <Grid item xs={12}>
                        <Alert severity="error">
                          This monitoring record was not completed on time.
                        </Alert>
                        <Typography></Typography>
                      </Grid>
                    )}

                    {!onDesktop &&
                      eventRecord &&
                      eventRecord.task_records &&
                      eventRecord.task_records.map((task_record) => {
                        return (
                          <Grid
                            item
                            container
                            spacing={3}
                            xs={12}
                            key={task_record.id}
                            style={{ padding: "5%" }}
                          >
                            <Grid item xs={12}>
                              <Typography>
                                <b>{task_record.task_submitted.name}</b>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>
                                {task_record.task_submitted.description}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              {task_record.custom_field_input &&
                                task_record.task_submitted.custom_fields &&
                                task_record.task_submitted.custom_fields.map(
                                  (customField) => {
                                    return (
                                      <CustomTextField
                                        id={customField.id}
                                        name={customField.field_type}
                                        displayName={customField.name}
                                        description={customField.description}
                                        readOnly={true}
                                        value={
                                          task_record.custom_field_input[
                                          customField.id
                                          ]
                                        }
                                      />
                                    );
                                  }
                                )}
                              <FormControl component="fieldset">
                                <RadioGroup
                                  aria-label="s/u"
                                  value={task_record.satisfactory}
                                  disabled={true}
                                >
                                  <FormControlLabel
                                    value="Satisfactory"
                                    control={<Radio disabled={true} size="small" />}
                                    label="Satisfactory"
                                  />
                                  <FormControlLabel
                                    value="Unsatisfactory"
                                    control={<Radio disabled={true} size="small" />}
                                    label="Unsatisfactory"
                                  />
                                  <FormControlLabel
                                    value="N/A"
                                    control={<Radio disabled={true} size="small" />}
                                    label="N/A"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>Observations</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={true}
                                value={task_record.observations}
                                fullWidth
                                multiline
                                variant="outlined"
                                required
                                rows={3}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              {task_record.deviation_form && (
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="secondary"
                                  onClick={() =>
                                    history.push(
                                      `/deviation/${task_record.deviation_form}`
                                    )
                                  }
                                >
                                  Deviation
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        );
                      })}

                    {!onDesktop &&
                      eventRecord &&
                      eventRecord.status === "OVERDUE" &&
                      eventRecord.event_submitted.read_tasks.map((task_record) => {
                        return (
                          <Grid
                            item
                            container
                            spacing={3}
                            xs={12}
                            key={task_record.id}
                            style={{ padding: "5%" }}
                          >
                            <Grid item xs={12}>
                              <Typography>
                                <b>{task_record.name}</b>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>{task_record.description}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              {task_record.custom_fields &&
                                task_record.custom_fields.map((customField) => {
                                  return (
                                    <CustomTextField
                                      id={customField.id}
                                      name={customField.field_type}
                                      displayName={customField.name}
                                      description={customField.description}
                                      readOnly={true}
                                    />
                                  );
                                })}

                              <FormControl component="fieldset">
                                <RadioGroup aria-label="s/u" disabled={true}>
                                  <FormControlLabel
                                    value="Satisfactory"
                                    control={<Radio disabled={true} size="small" />}
                                    label="Satisfactory"
                                  />
                                  <FormControlLabel
                                    value="Unsatisfactory"
                                    control={<Radio disabled={true} size="small" />}
                                    label="Unsatisfactory"
                                  />
                                  <FormControlLabel
                                    value="N/A"
                                    control={<Radio disabled={true} size="small" />}
                                    label="N/A"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>Observations</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={true}
                                fullWidth
                                multiline
                                variant="outlined"
                                required
                                rows={3}
                              />
                            </Grid>
                          </Grid>
                        );
                      })}

                    {onDesktop && (
                      <Grid item xs={12}>
                        <TableContainer>
                          <Table className={classes.table} aria-label="task-table">
                            <colgroup>
                              <col width="50%" />
                              <col width="25%" />
                              <col width="25%" />
                            </colgroup>
                            <TableHead>
                              <TableCell>Task</TableCell>
                              <TableCell align="left">S/U</TableCell>
                              <TableCell align="left">Observations</TableCell>
                            </TableHead>
                            <TableBody>
                              {eventRecord.status !== "OVERDUE" &&
                                eventRecord.task_records &&
                                eventRecord.task_records.map((task_record) => {
                                  return (
                                    <TableRow key={task_record.pk}>
                                      <TableCell component="th" scope="row">
                                        <b>{task_record.task_submitted.name}</b>
                                        <br></br>
                                        <p>
                                          {
                                            task_record.task_submitted
                                              .description
                                          }{" "}
                                          <span
                                            className={classes.fakeLink}
                                            onClick={() => {
                                              //setTaskSelected(task_record.task_submitted);
                                            }}
                                          >
                                            (Read SOP)
                                          </span>
                                        </p>
                                      </TableCell>
                                      <TableCell align="left">
                                        {task_record.custom_field_input &&
                                          task_record.task_submitted
                                            .custom_fields &&
                                          task_record.task_submitted.custom_fields.map(
                                            (customField) => {
                                              return (
                                                <CustomTextField
                                                  id={customField.id}
                                                  name={
                                                    customField.field_type
                                                  }
                                                  displayName={
                                                    customField.name
                                                  }
                                                  description={
                                                    customField.description
                                                  }
                                                  readOnly={true}
                                                  value={
                                                    task_record
                                                      .custom_field_input[
                                                    customField
                                                      .id
                                                    ]
                                                  }
                                                />
                                              );
                                            }
                                          )}

                                        <FormControl component="fieldset">
                                          <RadioGroup
                                            aria-label="s/u"
                                            value={task_record.satisfactory}
                                            disabled={true}
                                          >
                                            <FormControlLabel
                                              value="Satisfactory"
                                              control={
                                                <Radio
                                                  disabled={true}
                                                  size="small"
                                                />
                                              }
                                              label="Satisfactory"
                                            />
                                            <FormControlLabel
                                              value="Unsatisfactory"
                                              control={
                                                <Radio
                                                  disabled={true}
                                                  size="small"
                                                />
                                              }
                                              label="Unsatisfactory"
                                            />
                                            <FormControlLabel
                                              value="N/A"
                                              control={
                                                <Radio
                                                  disabled={true}
                                                  size="small"
                                                />
                                              }
                                              label="N/A"
                                            />
                                          </RadioGroup>
                                        </FormControl>
                                      </TableCell>
                                      <TableCell align="left">
                                        {task_record.observations}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}

                              {eventRecord.status === "OVERDUE" &&
                                eventRecord.event_submitted.tasks &&
                                eventRecord.event_submitted.read_tasks.map(
                                  (task_record) => {
                                    return (
                                      <TableRow key={task_record.pk}>
                                        <TableCell component="th" scope="row">
                                          <b>{task_record.name}</b>
                                          <br></br>
                                          <p>
                                            {task_record.description}{" "}
                                            <span
                                              className={classes.fakeLink}
                                              onClick={() => {
                                                //setTaskSelected(task_record.task_submitted);
                                              }}
                                            >
                                              (Read SOP)
                                            </span>
                                          </p>
                                        </TableCell>
                                        <TableCell align="left">
                                          {task_record.custom_fields &&
                                            task_record.custom_fields.map(
                                              (customField) => {
                                                return (
                                                  <CustomTextField
                                                    id={
                                                      customField.id
                                                    }
                                                    name={
                                                      customField.field_type
                                                    }
                                                    displayName={
                                                      customField.name
                                                    }
                                                    description={
                                                      customField.description
                                                    }
                                                    readOnly={true}
                                                  //value={task_record.custom_field_input[customField.id]}
                                                  />
                                                );
                                              }
                                            )}
                                          <FormControl component="fieldset">
                                            <RadioGroup
                                              aria-label="s/u"
                                              disabled={true}
                                            >
                                              <FormControlLabel
                                                value="Satisfactory"
                                                control={
                                                  <Radio
                                                    disabled={true}
                                                    size="small"
                                                  />
                                                }
                                                label="Satisfactory"
                                              />
                                              <FormControlLabel
                                                value="Unsatisfactory"
                                                control={
                                                  <Radio
                                                    disabled={true}
                                                    size="small"
                                                  />
                                                }
                                                label="Unsatisfactory"
                                              />
                                              <FormControlLabel
                                                value="N/A"
                                                control={
                                                  <Radio
                                                    disabled={true}
                                                    size="small"
                                                  />
                                                }
                                                label="N/A"
                                              />
                                            </RadioGroup>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell align="left"></TableCell>
                                      </TableRow>
                                    );
                                  }
                                )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    )}

                    {eventRecord.status !== "OVERDUE" && (
                      <>
                        <Grid item xs={12}>
                          <Typography variant="h6">Signature</Typography>
                        </Grid>

                        <Grid item xs={12} style={{ width: "95%", overflow: "auto" }}>
                          <img
                            alt="signature"
                            src={eventRecord.signature}
                            style={{ maxWidth: "700px", maxHeight: "200px" }}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Paper>
              </Grid>
            )}

            {pageIndex > numPages - 1 && !readOnly && (
              <Grid
                container
                item
                xs={12}
                justify="center"
                alignItems="center"
                style={{ height: "auto", width: "100%" }}
              >
                <Paper
                  elevation={0}
                  style={{ width: "100%", maxWidth: "1200px", padding: "16px", paddingTop: "32px" }}
                >
                  <Grid item xs={12} style={{ paddingBottom: "8px" }}>
                    <Typography variant="h6">Observations</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      id="verification-record-editor"
                      style={{
                        border: "1px solid lightgrey",
                        marginLeft: "16px",
                        marginRight: "16px",
                      }}
                    >
                      <ReactQuill theme="snow" onChange={notesChange} />
                    </div>
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: "32px" }}>
                    <Typography variant="h6">Signature</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ width: "100%" }}>
                      <ReactSignatureCreator
                        checkSignature={checkSignature}
                        validSignatureCheck={validSignatureCheck}
                      />
                    </div>
                  </Grid>
                </Paper>
              </Grid>
            )}

            {pageIndex > numPages - 1 && readOnly && (
              <Grid container justify="center" style={{ height: "100%", width: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    maxWidth: "1200px",
                    padding: onDesktop ? "32px" : "16px",
                    backgroundColor: "white",
                  }}
                >
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Observations</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        backgroundColor: "#FAFAFC",
                        minHeight: "250px",
                        marginTop: "16px",
                      }}
                    >
                      <SanitizeHtml value={verification.notes} />
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: "16px" }}>
                      <Typography variant="h6">Signature</Typography>
                    </Grid>
                    <div style={{ overflow: "auto" }}>
                      <img
                        alt="signature"
                        src={verification.signature}
                        style={{ paddingTop: "16px" }}
                      />
                    </div>
                  </Grid>
                </div>
              </Grid>
            )}
          </DialogContent>

          <DialogActions style={{ borderTop: "1px solid #eaeaea" }}>
            <Grid container item xs={12} justify="flex-end">
              <Button variant="outlined" color="secondary" onClick={close} style={{ margin: 4 }}>
                Close
              </Button>
              <Button
                disabled={pageIndex === 0}
                variant="outlined"
                color="primary"
                onClick={previous}
                style={{ margin: 4 }}
              >
                Previous
              </Button>

              {(pageIndex !== numPages || verification.time_submitted != null) && (
                <Button
                  variant="outlined"
                  disabled={pageIndex === numPages}
                  color="primary"
                  onClick={next}
                  style={{ margin: 4 }}
                >
                  Next
                </Button>
              )}

              {pageIndex > numPages - 1 && !readOnly && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submit}
                  style={{ margin: 4 }}
                  disabled={!validSignature}
                >
                  Submit
                </Button>
              )}
            </Grid>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
