import React from "react";

import { withStyles } from '@material-ui/styles';

import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from '@material-ui/icons/Remove';

const BootstrapInput = withStyles((theme) => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(3),
		},
	},
	input: {
		borderRadius: 4,
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #ced4da',
		fontSize: 16,
		padding: '10px 26px 10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		'&:focus': {
			borderRadius: 4,
			borderColor: '#80bdff',
			boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
		},
	},
}))(InputBase);


export default function CustomSelectField(props) {
	return (
		<Grid container item xs={12} style={props.style}>
			<Grid item xs={12} md={props.oneRow ? 12 : 6} style={{ marginBottom: '8px' }}>
				<Grid item xs={12} style={{ paddingTop: '12px' }}>
					<Typography style={{ fontSize: '14px', fontWeight: 500 }}>
						{props.displayName ? props.displayName : "<title>"} {props.required === true ? <span style={{ color: 'red', marginLeft: '4px' }}>*</span> : null}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography style={{ fontSize: '12px', fontWeight: 400 }}>
						{props.description ? props.description : "<instructions>"}
					</Typography>
				</Grid>
			</Grid>
			<Grid item xs={12} md={props.oneRow ? 12 : 6}>
				<Select
					value={props.value}
					onChange={props.onChange}
					disabled={props.readOnly}
					input={<BootstrapInput fullWidth />}
					style={{
						minWidth: "250px",
					}}
				>
					{props.options && props.options.map(option => {
						return (
							<MenuItem value={option.value}>
								<Grid container alignItems="center">
									<Grid item xs={9}>
										<Typography style={{ fontSize: '14px' }}>
											{option.value}
										</Typography>
									</Grid>
								</Grid>
							</MenuItem>
						)
					})}
				</Select>

				{props.error &&
					<Typography style={{ fontSize: '12px', color: '#f44336' }}>
						{props.error}
					</Typography>
				}
			</Grid>
		</Grid>
	)
}