import React, { useEffect, useState } from "react";

import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from '@material-ui/core/IconButton';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import {standardsSelected} from "../Audit";
import {sectionsSelected} from "../Audit";

const Section = React.memo(({style, section, indent, parentChecked, standardsChecked, setStandardChecked, sectionsChecked, setSectionChecked}) => {
	const [checked, setChecked] = useState(false);
	const [isHidden, setIsHidden] = useState(true);

	function handleChange(event) {
		setChecked(event.target.checked);
		setSectionChecked(section, event.target.checked);
	}

	if (section && section.type === 'STANDARD' && standardsSelected[section.standard_id] && !checked) {
		setChecked(true);
	}

	if (section && section.type === 'SECTION' && sectionsSelected[section.section_id] && !checked) {
		setChecked(true)
	}

	useEffect(() => {
		setChecked(parentChecked);
	}, [parentChecked])

	function show(event) {
		setIsHidden(!isHidden);
	}

	return (
		<Grid key={section.id} container spacing={2} style={style} >
			<Grid item xs={12} style={{paddingLeft: `${indent * 16}px`}}>
				<Paper elevation={0} style={{padding: '16px'}}>
					<Grid container alignItems="center">
						<Grid item xs={8}>
							<Checkbox
								checked={checked}
								onChange={handleChange}
								name="checkedB"
								color="primary"
							/>
							<Typography style={{display: 'inline-block', fontWeight: 500, fontSize: '14px'}}>
								{section.title}
							</Typography>
						</Grid>
						<Grid item container xs={4} justify="flex-end" alignItems="center" style={{paddingRight: '4px'}}>
							{section.children.length > 0 && 
							<IconButton onClick={show}>
								{isHidden && 	
									<ArrowRightIcon/>
								}

								{!isHidden && 
									<ArrowDropDownIcon/>
								}
							</IconButton>
							}
						</Grid>
						<Grid item container xs={12}>
							{/*section.requirement && 
								<Typography>
									{section.requirement}
								</Typography>
							*/}
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			<Grid item xs={12}>
			  {section.children && section.children.map(subsection => {
					return (
						<Section 
							key={subsection.id}
							style={{display: isHidden ? 'none' : null}}
							indent={indent + 1} 
							section={subsection} 
							parentChecked={checked} 
							isHidden={isHidden}
							standardsChecked={standardsChecked}
							setStandardChecked={setStandardChecked} 

							sectionsChecked={sectionsChecked}
							setSectionChecked={setSectionChecked}
						/>
					) 
				})}
			</Grid>
		</Grid>
	)
}, (prevProps, nextProps) => {
	if (prevProps.parentChecked !== nextProps.parentChecked) {
		return false;
	}
	
	if (prevProps.isHidden !== nextProps.isHidden) {
		return false;
	}

	return true;
});


export default Section;