import React, { useEffect, useState } from "react"

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

import API from "../../Api/Api";
import { DataGrid } from '@material-ui/data-grid';

import CancelIcon from '@material-ui/icons/Cancel';

export default function AddEventDialog(props) {
  const [availableEvents, setAvailableEvents] = useState([]);
  const [eventSelected, setEventSelected] = useState([]);

  const api = new API();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.open) {
        api.getMonitoringAPI().getAllEvents().then(response => {
            setLoading(false);
            setAvailableEvents(response.data);
        }).catch(error => {
            console.log(error);
        });
    }
  }, [props.open]);

  return (
    <Dialog open={props.open} maxWidth="md" fullWidth>
      <DialogTitle style={{ borderBottom: '1px solid #eaeaea', padding: '16px' }}>
        <Grid container alignItems="center" >
          <Grid item xs={8}>
            Link Event to Task 
          </Grid>
          <Grid item container xs={4} justify="flex-end">
            <IconButton onClick={() => { props.setOpen(false) }}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{height: '100%'}}>

        {!loading && !availableEvents.length &&
          <DialogContentText style={{ padding: '16px' }}>
            No events were found.
        </DialogContentText>
        }

        {!loading && availableEvents.length > 0 &&
          <DialogContentText style={{ padding: '16px' }}>
            Select an available event from the list below to attach it to the task.
        </DialogContentText>
        }

        <Grid container item xs={12} style={{ height: '500px' }}>
          {loading && <Grid item container xs={12} justify="center" alignItems="center" style={{height: '100%'}}><CircularProgress /></Grid>}
          {availableEvents.length > 0 &&
            <DataGrid
              disableColumnMenu
              rows={[...availableEvents]}
              columns={[{ field: 'name', headerName: "Events", flex: 1 }]}
              onRowClick={(rowData) => {
                  setEventSelected(rowData.row);
              }}
            />
          }
        </Grid>
      </DialogContent>
      <DialogActions style={{ borderTop: '1px solid #eaeaea' }}>
        <Grid container item xs={12} alignItems="center" justify="flex-end" >
          <Button variant="outlined" color="secondary" style={{ margin: '4px' }} onClick={() => { props.setOpen(false) }}>
            Cancel
          </Button>
          <Button
            disabled={!eventSelected}
            variant="contained" color="primary" style={{ margin: '4px' }}
            onClick={() => { props.setOpen(false); props.addEvent(eventSelected); }}
          >
            Add Event 
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}