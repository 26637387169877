class LogsAPI {

	constructor(api, version) {
		this.api = api;
		this.version = version;
		this.endpoint = `${this.version}/logs`;
	}

	listLogs() {
		return this.api.get(`${this.endpoint}/`);
	}

	createLog(payload) {
		return this.api.post(`${this.endpoint}/`, payload);
	}

	retrieveLog(pk) {
		return this.api.get(`${this.endpoint}/${pk}/`);
	}

	updateLog(payload) {
		return this.api.put(`${this.endpoint}/${payload.id}/`, payload);
	}

	deleteLog(pk) {
		return this.api.delete(`${this.endpoint}/${pk}/`);
	}
	
	saveLogRecord(payload) {
		return this.api.post(`${this.version}/log_records/`, payload);
	}

	updateLogRecord(payload) {
		return this.api.put(`${this.version}/log_records/${payload.id}/`, payload);
	}
	
	incompleteCount(query='') {
		return this.api.get(`${this.version}/incompleted_records_count/${query}`)
	}
}


export default LogsAPI;