import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import API from "../../Api/Api";
import Message from "../../Components/Message";
import TextField from "@material-ui/core/TextField";
import { Prompt } from 'react-router';
import { useHistory, useRouteMatch, } from "react-router-dom";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";


function TrafficFlowDiagramDescription(props) {
  let [shouldClose, setShouldClose] = useState(false);
  let api = new API();
  let history = useHistory();
  let match = useRouteMatch();

  function handleSubmit(event) {
    setShouldClose(true);
    if (props.setShouldClose) {
      props.setShouldClose(true);
    }
    let formData = new FormData();

    let trafficFlowDiagram = props.trafficFlowDiagram;
    let canvas = "";
    formData.append("name", trafficFlowDiagram.name);
    formData.append("description", trafficFlowDiagram.description);

    for (let i = 0; i < trafficFlowDiagram.cross_contamination_points.length; i++) {
      formData.append("cross_contamination_points", trafficFlowDiagram.cross_contamination_points[i]);
    }

    api.getTrafficFlowDiagramAPI().updateTrafficFlowDiagramImage(trafficFlowDiagram.pk, formData).then(e => {
      props.setTrafficFlowDiagram(e.data);
     // props.setBackgroundImage(e.data);
      props.setShouldClose(false);
      props.save(props.canvas, props.plan, props.diagramType ,trafficFlowDiagram);
    }).catch(e => {
      console.log(e);
    });
  }

  function saveAndContinue() {
    window.alert = function () { };
    handleSubmit();
    setShouldClose(false);
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }

    if (props.modalClose) {
      props.modalClose();
    }
  }

  function handleChange(event) {

    if (!shouldClose) {
      setShouldClose(true);
      if (props.setShouldClose) {
        props.setShouldClose(true);
      }
    }

    const property = event.currentTarget.name;
    const value = event.currentTarget.value;

    props.setTrafficFlowDiagram({ ...props.trafficFlowDiagram, [property]: value })
  }

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState({ message: "", status: "info" }); // error, warning, info, info

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  window.onbeforeunload = function (e) {
    if (shouldClose) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  return (
    <div>
       <Dialog
        open={props.open}
        onClose={props.onClose}
        maxWidth={"lg"}
        fullWidth={true}
      >
        <DialogContent>
      <Prompt
        when={shouldClose}
        message='You have unsaved changes, are you sure you want to leave?'
      />
      {props.trafficFlowDiagram &&
        <div>
          <Grid container spacing={3}>
            <Grid item xs={11}>
              <Typography variant='h5'>
                Traffic Flow Diagram
            </Typography>
            Edit/view this traffic flow diagram.
          </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6'>
                Details
            </Typography>
              <Typography>
                Edit information about this traffic flow diagram.
            </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField name="name" label="Traffic Flow Diagram Name" variant="outlined" value={props.trafficFlowDiagram.name}
                onChange={handleChange} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Traffic Flow Diagram Description" name="description" variant="outlined" multiline
                value={props.trafficFlowDiagram.description} fullWidth rows={4}
                onChange={handleChange} />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ margin: "8px" }}
              >
                Save Changes
            </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={saveAndContinue}
                style={{ margin: "8px" }}
              >
                Save and Continue
            </Button>
              <Button variant='contained' color='secondary' style={{ margin: '8px' }} onClick={() => { props.cancel() }}>
                Cancel
            </Button>
            </Grid>
          </Grid>
        </div>
      }
      <Message open={open} message={message.message} severity={message.status} vertical="bottom"
        horizontal="right"
        handleClose={handleClose} />
    </DialogContent> 
   </Dialog>
    </div>
  )
}

export default TrafficFlowDiagramDescription;
