import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Traceability from "../Traceability";
import Message from "../../Components/Message";
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import DeleteIcon from '@material-ui/icons/Delete';
import TraceabilityUtils from "../TraceabilityUtils";
import CancelIcon from '@material-ui/icons/Cancel';
import withStyles from "@material-ui/core/styles/withStyles";
import {StylesContext} from "../../App";
import FCustomerAddresses from 'Traceability/Fcustomer/FCustomerAddresses';

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '0px solid white',
        backgroundColor: 'rgba(0, 0, 0, 0.029)',
      },
    },
  },
})(TextField);


export default function CustomerForm(props) {
  const classes = React.useContext(StylesContext);
  const utils = new TraceabilityUtils();
  const [customer, setCustomer] = useState(props.customer ? props.customer : {});
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [message, setMessage] = useState(utils.emptyMessage());
  const [submitting, setSubmitting] = useState(false);

  function validateForm() {
    setSubmitAttempted(true);

    /*if (!customer.email || !(/.+@.+\.[a-zA-Z]{2,}/gm).test(customer.email)) {
      setMessage({ open: true, message: 'Please enter a valid email.', status: 'error' });
      return false;
    }*/

    if (!customer.name || !(/^[A-Za-z][A-Za-z\s]*$/).test(customer.name)) {
      setMessage({ open: true, message: 'Please enter the valid Customer Name.', status: 'error' });
      return false;
    }

    if (!customer.customer_id || !(/^[a-zA-Z0-9]+$/).test(customer.customer_id)) {
      setMessage({ open: true, message: 'Please enter the valid Customer Id.', status: 'error' });
      return false;
    }

    const customer_id = customer.customer_id;
    const foundKey = Object.keys(props.customerIdsDict).find(key => props.customerIdsDict[key] == customer_id);
    if (foundKey) {
      if (customer.id != foundKey) {
        setMessage({ open: true, message: 'Customer id already exists Please use another.', status: 'error' });
        return false;
      }
    }

    if (!customer.phone_number || !(/^(\+\d{1}[\s.-]?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g).test(customer.phone_number)) {
      setMessage({ open: true, message: 'Phone number invalid. Must include Country Code (often 1) + Area Code', status: 'error' });
      return false;
    }

    /*if (!(/^(\+\d{1}[\s.-]?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g).test(customer.phone_number)) {
      setMessage({ open: true, message: 'Phone number invalid. Must include Country Code (often 1) + Area Code', status: 'error' });
      return false;
    }*/

    return true;
  }

  function handleSubmit(saveAndContinue) {
    setSubmitAttempted(true);
    setSubmitting(true);
    if (!validateForm()) {
      setSubmitting(false);
      return;
    }

    let customerObject = { ...customer };

    if (props.setShouldClose) {
      props.setShouldClose(true);
    }

    let organization = JSON.parse(localStorage.getItem("organization"));
    customerObject.organization = organization.pk;
    customerObject.traceability = props.activeTraceability.pk;
    const api = new Traceability().getFCustomerAPI();

    if (customerObject.id) {
      api.updateCustomer(customerObject).then(response => {
        if (props.setShouldClose) {
          props.setShouldClose(true);
        }
        setCustomer({...response.data, type: 'Customer'});
        props.setCustomerIdsDict({...props.customerIdsDict,[response.data.id]:response.data.customer_id});
        setSubmitAttempted(false);
        if (props.onSave) {
          props.onSave(response, {...response.data, type: 'Customer'}, saveAndContinue);
        }
        setMessage({ open: true, message: 'Saved Successfully', status: 'success' });
        setSubmitting(false);
      }).catch(error => {
        setSubmitting(false);
        setMessage({ open: true, message: 'Server Error: ' + utils.formatError(error), status: 'error' });
        //console.log('Error Response: ' + JSON.stringify(error));
      });
    }
    else {
      api.createCustomer(customerObject).then(response => {
        if (props.setShouldClose) {
          props.setShouldClose(true);
        }
        setCustomer({...response.data, type: 'Customer'});
        props.setCustomerIdsDict({...props.customerIdsDict,[response.data.id]:response.data.customer_id})
        setSubmitAttempted(false);
        if (props.onSave) {
          props.onSave(response, {...response.data, type: 'Customer'}, saveAndContinue);
        }
        setMessage({ open: true, message: 'Saved Successfully', status: 'success' });
        setSubmitting(false);
      }).catch(error => {
        setSubmitting(false);
        setMessage({ open: true, message: 'Server Error: ' + utils.formatError(error), status: 'error' });
        //console.log('Error Response: ' + JSON.stringify(error));
      });
    }
  }

  function handlePhoneFieldChange(event) {
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }

    const property = event.target.name;
    let phoneString = event.target.value;


    if(phoneString[0] && phoneString[0] != '+' ) {
      phoneString = '+' + phoneString;
    }
    
    //let value = event.target.value.slice(1);
    //value = '+' + value;

    setCustomer({ ...customer, [property]: phoneString });
  }

  function handleTextFieldChange(event) {
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }

    const property = event.target.name;
    const value = event.target.value;

    setCustomer({ ...customer, [property]: value });
  }

  function cancel() {
    setCustomer(props.customer ? props.customer : {});

    if (props.setShouldClose) {
      props.setShouldClose(false);
    }
  }

  function handleDelete() {
    let customerName = customer.company || customer.customer_id || 'this customer';

    if(!window.confirm('Are you sure you want to delete ' + customerName + '?')){
      return;
    }

    if(!customer || !customer.id) {
      props.closeModal ? props.closeModal() : cancel();
    }
    //let pkToDelete = customer.pk;
    const custAPI = new Traceability().getFCustomerAPI();

    custAPI.deleteCustomer(customer.id).then(response => {
      
      if (props.onDelete) {
        props.onDelete(response, {id: customer.id, type: 'Customer', soft_delete: true});
      }
      const { [customer.id]: deletedCustomer, ...updatedCustomerIdsDict } = props.customerIdsDict;
      props.setCustomerIdsDict(updatedCustomerIdsDict);

      setMessage({ open: true, message: 'Deleted Successfully', status: 'success' });
      props.closeModal();
    }).catch(error => {
      setMessage({ open: true, message: 'Server Error: ' + utils.formatError(error), status: 'error' });
      //console.log('Error Response: ' + JSON.stringify(error));
    });
  }
  
  return (
    <form onSubmit={props.handleSubmit ? props.handleSubmit : handleSubmit}>

      <Grid container spacing={3} className={classes.generalContainerGridHead}>
        <Grid item xs={11}>
          <Typography variant="h6" noWrap className={classes.generalFormHeaderTypography}>
              {customer.id ? 'Edit Customer' : 'Add New Customer'}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton style={{ float: 'right' }} onClick={props.closeModal ? props.closeModal : cancel}>
            <CancelIcon style={{ float: 'right' }} />
          </IconButton>
        </Grid>
      </Grid>


      <Grid container spacing={3} className={classes.generalContainerGridBody} style={{marginTop: '16px'}}>

        <Grid item xs={12}>
          <Typography variant="h5" noWrap className={classes.generalFormSubsectionHeader}>
            Customer Details
          </Typography>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={0} square className={classes.generalFormPaperContainerApprovedInfo}>
            <Grid container spacing={3} className={classes.generalContainerGrid}>
              <Grid item xs={8}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Customer Name
                </Typography>
                <CssTextField
                  fullWidth
                  placeholder="Customer Name"
                  className={classes.generalFormTextField}
                  name="name"
                  variant="outlined"
                  value={customer.name}
                  error={submitAttempted && !customer.name}
                  onChange={handleTextFieldChange}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Customer ID
                </Typography>
                <CssTextField
                  fullWidth
                  placeholder="Unique Identifier"
                  className={classes.generalFormTextField}
                  name="customer_id"
                  variant="outlined"
                  value={customer.customer_id}
                  //error={submitAttempted && !customer.customer_id}
                  onChange={handleTextFieldChange}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" noWrap className={classes.generalFormSubsectionHeader}>
            Primary Contact Information
          </Typography>
          <Divider />
        </Grid>

        <Grid item xs={12}>
        <Paper elevation={0} square className={classes.generalFormPaperContainerApprovedInfo}>
            <Grid container spacing={3} className={classes.generalContainerGrid}>
              {/* <Grid item xs={6}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Contact Name
                </Typography>
                <CssTextField
                  fullWidth
                  placeholder="First Name"
                  className={classes.generalFormTextField}
                  name="contact_first_name"
                  variant="outlined"
                  value={customer.contact_first_name}
                  //error={submitAttempted && !customer.contact_first_name}
                  onChange={handleTextFieldChange}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography style={{color: 'rgba(0, 0, 0, 0)'}} className={classes.generalFormTypographyHeader}>
                  .
                </Typography>
                <CssTextField
                  fullWidth
                  placeholder="Last Name"
                  className={classes.generalFormTextField}
                  name="contact_last_name"
                  variant="outlined"
                  value={customer.contact_last_name}
                  //error={submitAttempted && !customer.contact_last_name}
                  onChange={handleTextFieldChange}
                />
              </Grid> */}
              <Grid item xs={6}>
                <Typography className={classes.generalFormTypographyHeader}>
                Company
                </Typography>
                <CssTextField
                  fullWidth
                  placeholder="Company"
                  className={classes.generalFormTextField}
                  name="company"
                  variant="outlined"
                  value={customer.company}
                  //error={submitAttempted && !customer.company}
                  onChange={handleTextFieldChange}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Contact Email
                </Typography>
                <CssTextField
                  fullWidth
                  placeholder="example@email.com"
                  className={classes.generalFormTextField}
                  name="email"
                  variant="outlined"
                  value={customer.email || ''}
                  //error={submitAttempted && (!customer.email || !(/.+@.+\.[a-zA-Z]{2,}/gm).test(customer.email))}
                  onChange={handleTextFieldChange}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Contact Phone
                </Typography>
                <CssTextField
                  fullWidth
                  placeholder="12223334567"
                  className={classes.generalFormTextField}
                  name="phone_number"
                  variant="outlined"
                  //defaultValue="+"
                  //startAdornment={<InputAdornment position="start">+</InputAdornment>}
                  value={customer.phone_number}
                  //error={submitAttempted && !(/^(\+\d{1}[\s.-]?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g).test(customer.phone_number)}
                  onChange={handleTextFieldChange}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.generalFormTypographyHeader}>
                Additional Information
                </Typography>
                <CssTextField
                  fullWidth
                  placeholder="Additional Information"
                  className={classes.generalFormTextField}
                  name="additional_information"
                  multiline
                  rows={3}
                  variant="outlined"
                  value={customer.additional_information}
                  //error={submitAttempted && !customer.additional_information}
                  onChange={handleTextFieldChange}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        
        {customer.id &&       
        <Grid container style={{marginTop: '16px'}}>
        <FCustomerAddresses customer={customer}/>
        </Grid>
        }

      </Grid>

      <Paper elevation={0} square className={classes.generalFormPaperStickyFooterApprovedInfo}>
        <Grid container spacing={3} className={classes.generalContainerGridFoot}>
          <Grid item container xs={12} alignItems="center" justify="flex-end">
            {customer.id && 
              <Button
                variant="outlined"
                startIcon={<DeleteIcon />}
                style={{ marginLeft: "8px" }}
                onClick={() => {handleDelete()} }
                disabled={submitting}
              >
                Delete
              </Button>
            }
            <Button
              //variant="outlined"
              color="secondary"
              style={{ marginLeft: "8px" }}
              onClick={props.closeModal ? props.closeModal : cancel}
              disabled={submitting}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "8px" }}
              onClick={() => { handleSubmit(false) }}
              disabled={submitting}
            >
              Save Changes
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "8px" }}
              onClick={() => { handleSubmit(true) }}
              disabled={submitting}
            >
              Submit
            </Button>

          </Grid>
        </Grid>
      </Paper>

      <Message
        open={message.open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="left"
        handleClose={() => { setMessage({ ...message, open: false }) }}
      />
    </form>
  )
}

CustomerForm.propTypes = {
  customer: PropTypes.object,
  handleSubmit: PropTypes.func,
  user: PropTypes.object.isRequired,
  activeTraceability: PropTypes.object.isRequired,
  setShouldClose: PropTypes.func,
  onSave: PropTypes.func,
  closeModal: PropTypes.func,
  cancel: PropTypes.func,
}