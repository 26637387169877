class ShipmentAPI {
  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listShippings(pk) {
    if (typeof pk === 'number') {
      return this.api.get(`${this.version}/shipping_record/${pk}/`);
    }

    return this.api.get(`${this.version}/shipping_record/`)
  }

  listShippingsSkuExpanded(pk) {
    if (typeof pk === 'number') {
      return this.api.get(`${this.version}/shipping_record/${pk}/list_sku_expanded_specific/`);
    }

    return this.api.get(`${this.version}/shipping_record/list_sku_expanded/`)
  }

  /**
   * Creates a fulfillement record for the shipment record in question.
   * @param {*} payload - Data for fulfillment record.
   */
  fulfillShipment(payload) {
    return this.api.post(`${this.version}/shipping_record/${payload.pk}/ship/`, payload);
  }

  createShippingRecord(payload) {
    return this.api.post(`${this.version}/shipping_record/`, payload);
  }

  /**
   * Sets the status of the shipment following the pattern Draft -> Ready -> Picking -> Awaiting Shipping -> Complete
   * @param {*} pk - The primary key of the shipment to update.
   */
  updateShippingStatus(pk) {
    return this.api.get(`${this.version}/shipping_record/${pk}/update_status/`);
  }

  updateShippingRecord(payload) {
    return this.api.put(`${this.version}/shipping_record/${payload.pk}/`, payload);
  }

  deleteShippingRecord(pk) {
    return this.api.delete(`${this.version}/shipping_record/${pk}/`);
  }
}

export default ShipmentAPI;