class PackagingAPI {
    constructor(api, version) {
      this.api = api;
      this.version = version;
    }
  
    listPackagings(pk) {
      if (typeof pk === 'number') {
        return this.api.get(`${this.version}/packaging/${pk}/`);
      }
  
      return this.api.get(`${this.version}/packaging/`)
    }
  
    createPackaging(payload) {
      return this.api.post(`${this.version}/packaging/`, payload);
    }
  
    updatePackaging(payload) {
      return this.api.put(`${this.version}/packaging/${payload.pk}/`, payload);
    }
  
    deletePackaging(pk) {
      return this.api.delete(`${this.version}/packaging/${pk}/`);
    }
  
  }
  
  export default PackagingAPI;