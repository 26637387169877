import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import FoodbyteLogo from "../logos/FB_Logo_Reversed_RGB_Icon.png";
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    borderRadius: "5%",
    boxShadow: "0px 2.5px 5px grey",
    marginLeft: "40px",
  },
  MenuItemText: {
    fontSize: "32px", //Insert your required size
    fontFamily: "Roboto",
    fontWeight: 500,
    lineHeight: "32px",
  },
  listMenuItemText: {
    fontSize: "20px", //Insert your required size
    fontFamily: "Roboto",
    fontWeight: 500,
    lineHeight: "20px",
    paddingTop: "12px"
  },
  linkMenuItemText: {
    fontSize: "14px", //Insert your required size
    fontFamily: "Roboto",
    fontWeight: 500,
    lineHeight: "20px",
    paddingTop: "12px"
  },
}));

export default function App404(props) {
  const classes = useStyles();
  let history = useHistory();

  function goToHome() {
    history.push(`dashboard/onboarding`);
  }

  return (
    <Grid container>
      <Grid
        container
        style={{ marginTop: "100px", paddingLeft: "150px", width: "50%" }}
      >
        <Grid item xs={12} container justify="flex-start">
          <Typography variant="h2" className={classes.MenuItemText}>
            Foodbyte
          </Typography>
        </Grid>
        <Grid item xs={12} container justify="flex-start">
          <Typography variant="h5" className={classes.listMenuItemText}>
            404 error. Page not found.
          </Typography>
          <Typography variant="h5" className={classes.listMenuItemText}>
            {" "}
            The requested URL could not be found.
          </Typography>
          <Typography variant="h5" className={classes.linkMenuItemText}>
            {" "}
            <Link onClick={goToHome}>
              Take me back to FoodByte
            </Link>
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: "100px", width: "50%" }}>
        <Grid item xs={12} container justify="flex-start">
          <img
            onClick={goToHome}
            className={classes.logo}
            src={FoodbyteLogo}
            width="128"
            height="128"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
