import React, { useState, useEffect } from "react"
import { DialogContent } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import API from "../../Api/Api";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";

const DarkerDisabledTextField = withStyles({
    root: {
        marginRight: 8,
        "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.7)" // (default alpha is 0.38)
        }
    }
})(TextField);

function IngredientModal(props) {

    let api = new API();

    function modelClose() {
        props.handleModalClosed();

    }

    function getAPI() {
        return api.getIngredientsAPI();
    }

    function save(ingredient) {
        getAPI().updateIngredient(ingredient).then(e => {
            props.setIngredient(e.data);
            props.handleModalClosed();
            props.setStatus("Ingredient updated!",
                "info");
        }).catch(e => {
            console.log("error", e);
        });
    }

    return (
        <Dialog
            maxWidth="md"
            open={props.open}
            onClose={props.handleModalClosed}
        >
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <form style={{ padding: "16px" }}>
                            <Grid
                                container
                                spacing={3}
                                style={{ width: "100%", margin: 0, padding: "16px" }}
                            >

                                <Grid item xs={10}>
                                    <Typography variant="h5" noWrap>
                                        Old Ingredient
                                    </Typography>
                                    View this ingredient.
                                        </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        label="Name"
                                        name="name"
                                        disabled
                                        variant="outlined"
                                        value={props.beforeIngredient.name}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        label="Description"
                                        name="description"
                                        variant="outlined"
                                        disabled
                                        value={props.beforeIngredient.description}
                                        fullWidth
                                        multiline
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DarkerDisabledTextField
                                        label="Weight"
                                        name="weight"
                                        variant="outlined"
                                        disabled
                                        value={props.beforeIngredient.weight}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DarkerDisabledTextField
                                        label="Volume"
                                        name="volume"
                                        disabled
                                        setDisabledTextColor="black"
                                        variant="outlined"
                                        value={props.beforeIngredient.volume}
                                        fullWidth
                                    />
                                </Grid>

                                {/* {!props.templateMode && (
                                    <Grid item xs={12}>
                                        <HazardList
                                            ingredient_pk={props.beforeIngredient.pk}
                                            templateSelected={props.templateSelected}
                                            setTemplateSelected={props.setTemplateSelected}
                                        />
                                    </Grid>
                                )} */}
                            </Grid>
                        </form>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => save(props.beforeIngredient)}
                        >
                            Revert
                        </Button>
                    </Grid>

                    <Grid item xs={6}>
                        <form style={{ padding: "16px" }}>
                            <Grid
                                container
                                spacing={3}
                                style={{ width: "100%", margin: 0, padding: "16px" }}
                            >

                                <Grid item xs={10}>
                                    <Typography variant="h5" noWrap>
                                        Current Ingredient
                                    </Typography>
                                    View this ingredient.
                                    </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        label="Name"
                                        name="name"
                                        disabled
                                        variant="outlined"
                                        value={props.oldIngredient.name}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        label="Description"
                                        name="description"
                                        variant="outlined"
                                        disabled
                                        value={props.oldIngredient.description}
                                        fullWidth
                                        multiline
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DarkerDisabledTextField
                                        label="Weight"
                                        name="weight"
                                        disabled
                                        variant="outlined"
                                        value={props.oldIngredient.weight}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DarkerDisabledTextField
                                        label="Volume"
                                        name="volume"
                                        disabled
                                        variant="outlined"
                                        value={props.oldIngredient.volume}
                                        fullWidth
                                    />
                                </Grid>

                                {/* {!props.templateMode && (
                                    <Grid item xs={12}>
                                        <HazardList
                                            ingredient_pk={props.oldIngredient.pk}
                                            templateSelected={props.templateSelected}
                                            setTemplateSelected={props.setTemplateSelected}
                                        />
                                    </Grid>
                                )} */}
                            </Grid>
                        </form>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={modelClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>

        </Dialog >
    )
}
export default IngredientModal;
