
export default class CustomFieldsAPI {
	constructor(api, version, name) {
    this.api = api;
    this.version = version;
    this.name = name;
  }

	listFields(task) {
    return this.api.get(`${this.version}/fields/?task=${task.id}`);
  }

	createField(field) {
		return this.api.post(`${this.version}/fields/`, field);
	}

	updateField(field) {
		return this.api.put(`${this.version}/fields/${field.id}/`, field); 
	}

	deleteField(field) {
		return this.api.delete(`${this.version}/fields/${field.id}/`);
	}
}