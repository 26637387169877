

class ProcessAPI {

  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listProcesses(haccp_plan_pk) {
    if (typeof haccp_plan_pk === 'number') {
      return this.api.get(`${this.version}/processes/?haccp_plan_pk=${haccp_plan_pk}`);
    }

    return this.api.get(`${this.version}/processes/`)
  }

  listProcessesMinimizedJoins(haccp_plan_pk) {
    if (haccp_plan_pk) {
      return this.api.get(`${this.version}/processes/get_min_list/?haccp_plan_pk=${haccp_plan_pk}`);
    }else{

    return this.api.get(`${this.version}/processes/get_min_list/`)
    }
  }

  createProcess(payload) {
    return this.api.post(`${this.version}/processes/`, payload);
  }

  retrieveProcess(pk) {
    return this.api.get(`${this.version}/processes/${pk}/`);
  }

  updateProcess(payload) {
    return this.api.put(`${this.version}/processes/${payload.pk}/`, payload);
  }

  deleteProcess(pk) {
    return this.api.delete(`${this.version}/processes/${pk}/`);
  }

  selectDatasetProcess(process, processSelected, hazardsSelected) {
    let payload = {"process_selected": processSelected, "hazards_selected": [...hazardsSelected]};
    return this.api.post(`${this.version}/processes/${process.pk}/select_dataset/`, payload);
  }

  selectTemplate(pk, template) {
    return this.api.post(`${this.version}/processes/${pk}/select_template/`, template);
  }

  addHazardToProcess(process_pk, hazard) {
    return this.api.post(`${this.version}/processes/${process_pk}/add_hazard/`, hazard);
  }

  removeHazardFromProcess(process_pk, hazard) {
    return this.api.post(`${this.version}/processes/${process_pk}/remove_hazard/`, hazard);
  }

  getHistory(pk) {
    return this.api.get(`${this.version}/processes/${pk}/get_history/`);
  }

  getCCPs(pk) {
    return this.api.get(`${this.version}/processes/${pk}/get_ccps/`);
  }

  getRevertedProcess(pk, date) {
    return this.api.post(`${this.version}/processes/${pk}/get_reverted_process/`, date);
  }
}


export default ProcessAPI;
