import React, { memo, useEffect, useState, useContext } from "react";
import MaterialTable from "material-table";
import API from "../../Api/Api";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

function areEqual(prevProps, nextProps) {
  let a = prevProps.ingredients, b = nextProps.ingredients;

  if (a.length != b.length) return false;

  return true;
}

export default React.memo(function IngredientSelectTable(props) {

  const api = new API();

  const ingredient_columns = [
    { title: "Name", field: "name" },
    // {title: "Description", field: "description"},
    // {title: "Weight", field: "weight", editable: 'never'},
    // {title: "Volume", field: "volume", editable: 'never'},
  ];

  const [ingredients, setIngredients] = useState([]);

  useEffect(() => {
		api.getFormulationsAPI().listHaccpPlanIngredients(props.haccpPlanPk).then(response => {
      let ingredientsAvailable = [...response.data];

      let ingredientsSelected = props.ingredients.map(ingredient => {
        return ingredient.pk;
      });

      let filteredIngredients = ingredientsAvailable.filter(ingredient => {
        return !ingredientsSelected.includes(ingredient.pk);
      });

      setIngredients(filteredIngredients);
    });
  }, [props.ingredients]);

  const handleDelete = (ingredient) => {
    props.removeIngredient(ingredient);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {props.ingredients.map(ingredient => {
          return (
            <Chip
              style={{ margin: '4px' }}
              variant="outlined"
              color="primary"
              key={ingredient.pk}
              label={ingredient.name}
              onDelete={() => {
                handleDelete(ingredient);
              }}
            />
          )
        })}
      </Grid>
      <Grid item xs={12}>
          <MaterialTable
            title="Ingredients"
            components={{
              Container: props => <Paper {...props} elevation={0} />
            }}
            columns={ingredient_columns}
            data={ingredients}

            options={{
              selection: false,
            }}

            actions={[
              {
                icon: 'add',
                //icon: 'subtract',
                tooltip: 'Add to Hazard',
                onClick: (event, rowData) => props.addIngredient(rowData)
              }
            ]}
          />
      </Grid>
    </Grid>
  )
}, areEqual)
