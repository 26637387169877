import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  gridcontainer: {
    padding: "32px",
  },
  nestedcontainer: {
    padding: 0,
    paddingTop: 20,
  },
  griditem: {
    marginBottom: "18px",
  },
  outlinedbutton: {
    color: "#18bff6",
  },
  textfieldbackground: {
    background: "#fafafc",
  },
});

function Step0(props) {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.open) {
      let field0 = props.deviationForm.description_of_deviation;
      let field1 = props.deviationForm.date_of_occurance;
      let field4 = props.deviationForm.where_deviation_occured;
      let field5 = props.deviationForm.why_0;
      if (!field0 || !field1 || !field4 || !field5) {
        if (props.setFieldError) {
          props.setFieldError(true);
        }
      } else {
        if (props.setFieldError) {
          props.setFieldError(false);
        }
      }
    }
  }, [props.open]);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.open) {
      let why0 = props.deviationForm.why_0;
      let why1 = props.deviationForm.why_1;
      let why2 = props.deviationForm.why_2;
      let why3 = props.deviationForm.why_3;
      let why4 = props.deviationForm.why_4;
      setWhy0(why0);
      setWhy1(why1);
      setWhy2(why2);
      setWhy3(why3);
      setWhy4(why4);
      let whyCount = 1;
      if (why0) {
        setNoInput(false);
        whyCount = 1;
      }
      if (why1) {
        setNoInput(false);
        whyCount = 2;
      }
      if (why2) {
        setNoInput(false);
        whyCount = 3;
      }
      if (why3) {
        setNoInput(false);
        whyCount = 4;
      }
      if (why4) {
        setNoInput(false);
        whyCount = 5;
      }
      setCount(whyCount);
    }
  }, [props.open]);

  function handleChange(event) {
    setNoInput(false);
    let field0 = document.getElementById("description_of_deviation").value;
    let field1 = document.getElementById("date_of_occurance").value;
    let field4 = document.getElementById("where_deviation_occured").value;
    let field5 = document.getElementById("why_0").value;
    if (!field0 || !field1 || !field4 || !field5) {
      if (props.setFieldError) {
        props.setFieldError(true);
      }
    } else {
      if (props.setFieldError) {
        props.setFieldError(false);
      }
    }
    if (props.handleChange) {
      props.handleChange(event);
    }
    if (event.target.name === "why_0") {
      setWhy0(event.target.value);
      if (event.target.value === "") {
        setNoInput(true);
      }
    }
    if (event.target.name === "why_1") {
      setWhy1(event.target.value);
    }
    if (event.target.name === "why_2") {
      setWhy2(event.target.value);
    }
    if (event.target.name === "why_3") {
      setWhy3(event.target.value);
    }
    if (event.target.name === "why_4") {
      setWhy4(event.target.value);
    }
  }
  const [count, setCount] = useState(1);
  const [totalWhys, setTotalWhys] = useState(1);
  const [noInput, setNoInput] = useState(true);
  const [why1Hidden, setWhy1Hidden] = useState(false);
  const [why2Hidden, setWhy2Hidden] = useState(false);
  const [why3Hidden, setWhy3Hidden] = useState(false);
  const [why4Hidden, setWhy4Hidden] = useState(false);

  const [why0, setWhy0] = useState(
    props.deviationForm ? props.deviationForm.why_0 : "1"
  );
  const [why1, setWhy1] = useState(
    props.deviationForm ? props.deviationForm.why_1 : "1"
  );
  const [why2, setWhy2] = useState(
    props.deviationForm ? props.deviationForm.why_2 : "1"
  );
  const [why3, setWhy3] = useState(
    props.deviationForm ? props.deviationForm.why_3 : "1"
  );
  const [why4, setWhy4] = useState(
    props.deviationForm ? props.deviationForm.why_4 : "1"
  );

  function addWhy() {
    setNoInput(true);
    setTotalWhys(totalWhys + 1);
    setCount(count + 1);

    if (count >= 5) {
      if (why4Hidden) {
        setWhy4Hidden(false);
      } else if (why3Hidden) {
        setWhy3Hidden(false);
      } else if (why2Hidden) {
        setWhy2Hidden(false);
      } else if (why1Hidden) {
        setWhy1Hidden(false);
      }
    }
  }

  function removeWhy(whyNumber) {
    setNoInput(false);
    if (window.confirm("Are you sure you want to clear this field?")) {
      if (whyNumber === "why_0") {
        setWhy0("");
      
        //  props.deviationForm.why_0 = null;
        if (props.deviationForm.why_1) {
          props.deviationForm.why_0 = props.deviationForm.why_1;
          setWhy0(props.deviationForm.why_1);
          props.deviationForm.why_1 = null;
          setWhy1("");
        }else{ 
          setNoInput(true);
          if (props.setFieldError) {
            props.setFieldError(true);
          }
        }
        if (props.deviationForm.why_2) {
          props.deviationForm.why_1 = props.deviationForm.why_2;
          setWhy1(props.deviationForm.why_2);
          props.deviationForm.why_2 = null;
          setWhy2("");
        }
        if (props.deviationForm.why_3) {
          props.deviationForm.why_2 = props.deviationForm.why_3;
          setWhy2(props.deviationForm.why_3);
          props.deviationForm.why_3 = null;
          setWhy3("");
        }
        if (props.deviationForm.why_4) {
          props.deviationForm.why_3 = props.deviationForm.why_4;
          setWhy3(props.deviationForm.why_4);
          props.deviationForm.why_4 = null;
          setWhy4("");
        }
      }

      if (whyNumber === "why_1") {
        setWhy1("");
        props.deviationForm.why_1 = null;
        if (props.deviationForm.why_2) {
          props.deviationForm.why_1 = props.deviationForm.why_2;
          setWhy1(props.deviationForm.why_2);
          props.deviationForm.why_2 = null;
          setWhy2("");
        }
        if (props.deviationForm.why_3) {
          props.deviationForm.why_2 = props.deviationForm.why_3;
          setWhy2(props.deviationForm.why_3);
          props.deviationForm.why_3 = null;
          setWhy3("");
        }
        if (props.deviationForm.why_4) {
          props.deviationForm.why_3 = props.deviationForm.why_4;
          setWhy3(props.deviationForm.why_2);
          props.deviationForm.why_4 = null;
          setWhy4("");
        }
      }
      if (whyNumber === "why_2") {
        setWhy2("");
        props.deviationForm.why_2 = null;
        if (props.deviationForm.why_3) {
          props.deviationForm.why_2 = props.deviationForm.why_3;
          setWhy2(props.deviationForm.why_3);
          props.deviationForm.why_3 = null;
          setWhy3("");
        }
        if (props.deviationForm.why_4) {
          props.deviationForm.why_3 = props.deviationForm.why_4;
          setWhy3(props.deviationForm.why_4);
          props.deviationForm.why_4 = null;
          setWhy4("");
        }
      }
      if (whyNumber === "why_3") {
        setWhy3("");
        props.deviationForm.why_3 = null;
        if (props.deviationForm.why_4) {
          props.deviationForm.why_3 = props.deviationForm.why_4;
          setWhy3(props.deviationForm.why_4);
          props.deviationForm.why_4 = null;
          setWhy4("");
        }
      }
      if (whyNumber === "why_4") {
        props.deviationForm.why_4 = null;
        setWhy4("");
      }
      if (count > 1) {
        setCount(count - 1);
        setTotalWhys(totalWhys - 1);
      }
    }
  }

  return (
    <Paper elevation={0} style={{ maxWidth: 1200 }}>
      <Grid container spacing={3} className={classes.gridcontainer}>
        <Grid item container xs={12} alignItems="center">
          <Typography variant="h6">Deviation Details</Typography>
        </Grid>
        <Grid item container xs={12} className={classes.griditem}>
          <Grid item xs={12} style={{paddingBottom: '8px'}}>
            <Typography><span style={{color: 'red', paddingRight: '4px'}}>*</span>Description of Deviation</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="description_of_deviation"
              name="description_of_deviation"
              placeholder="Enter text here"
              className={classes.textfieldbackground}
              variant="outlined"
              disabled={props.readOnly}
              multiline
              rows={3}
              defaultValue={
                props.deviationForm
                  ? props.deviationForm.description_of_deviation
                  : ""
              }
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>
        {/* <Grid item container xs={12} md={6} alignItems="center">
          {" "}
          <Typography style={{ color: "red", paddingRight: "4px" }}>
            *
          </Typography>
          <Typography>Date/Time of Deviation</Typography>
        </Grid>
        <Grid item container xs={12} md={6} alignItems="center">
          {" "}
          <Typography style={{ color: "red", paddingRight: "4px" }}>
            *
          </Typography>
          <Typography>Location of Deviation</Typography>
        </Grid> */}
        <Grid item container xs={12} md={6} className={classes.griditem}>
          <Typography
            style={{ color: "red", paddingRight: "4px", paddingBottom: "8px" }}
          >
            *
          </Typography>
          <Typography style={{ paddingBottom: "8px" }}>
            Date/Time of Deviation
          </Typography>
          <TextField
            id="date_of_occurance"
            name="date_of_occurance"
            disabled={props.readOnly}
            className={classes.textfieldbackground}
            value={props.deviationForm.date_of_occurance || ""}
            type="datetime-local"
            defaultValue={
              props.deviationForm ? props.deviationForm.date_of_occurance : ""
            }
            variant="outlined"
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item container xs={12} md={6} className={classes.griditem}>
          <Typography
            style={{ color: "red", paddingRight: "4px", paddingBottom: "8px" }}
          >
            *
          </Typography>
          <Typography style={{ paddingBottom: "8px" }}>
            Location of Deviation
          </Typography>
          <TextField
            id="where_deviation_occured"
            name="where_deviation_occured"
            className={classes.textfieldbackground}
            disabled={props.readOnly}
            placeholder="Enter text here"
            defaultValue={
              props.deviationForm
                ? props.deviationForm.where_deviation_occured
                : ""
            }
            variant="outlined"
            multiline
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Typography variant="h6">Root Cause Analysis</Typography>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Typography>
            Complete a 5-Whys analysis to determine the root cause of the
            deviation.
          </Typography>
        </Grid>
        <Grid container spacing={2} className={classes.nestedcontainer}>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            style={{
              paddingTop: "16px",
              paddingLeft: "16px",
            }}
          >
            {" "}
            <Typography style={{ color: "red", paddingRight: "4px" }}>
              *
            </Typography>
            <Typography>Why?</Typography>
          </Grid>
          <Grid
            item
            container
            xs={8}
            md={10}
            style={{
              paddingLeft: "14px",
              paddingRight: "14px",
            }}
          >
            <TextField
              id="why_0"
              name="why_0"
              disabled={props.readOnly}
              value={why0 ? why0 : ""}
              multiline
              variant="outlined"
              className={classes.textfieldbackground}
              rows={3}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid
            item
            container
            xs={4}
            md={2}
            alignItems="center"
            justify="flex-end"
            style={{ marginLeft: -35, marginRight: -60, paddingRight: 0 }}
          >
            <IconButton
              disabled={props.readOnly}
              aria-label="close"
              onClick={() => removeWhy("why_0")}
              // onClick={cancel}
              // className={classes.iconbutton}
            >
              <DeleteIcon></DeleteIcon>
            </IconButton>
          </Grid>
        </Grid>
        {count > 1 && !why1Hidden && (
          <Grid container spacing={2} className={classes.nestedcontainer}>
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              style={{
                paddingTop: "16px",
                paddingLeft: "16px",
              }}
            >
              {" "}
              <Typography style={{ color: "red", paddingRight: "4px" }}>
                *
              </Typography>
              <Typography>Why?</Typography>
            </Grid>
            <Grid
              item
              container
              xs={8}
              md={10}
              style={{
                paddingLeft: "14px",
                paddingRight: "14px",
              }}
            >
              <TextField
                id="why_1"
                name="why_1"
                disabled={props.readOnly}
                value={why1}
                multiline
                variant="outlined"
                style={{
                  background: "#fafafc",
                }}
                rows={3}
                onChange={handleChange}
                fullWidth
              />{" "}
            </Grid>{" "}
            <Grid
              item
              container
              xs={4}
              md={2}
              alignItems="center"
              justify="flex-end"
              style={{ marginLeft: -35, marginRight: -60, paddingRight: 0 }}
            >
              <IconButton
                aria-label="close"
                disabled={props.readOnly}
                onClick={() => removeWhy("why_1")}
                // onClick={cancel}
                // className={classes.iconbutton}
              >
                <DeleteIcon></DeleteIcon>
              </IconButton>
            </Grid>
          </Grid>
        )}
        {count > 2 && !why2Hidden && (
          <Grid container spacing={2} className={classes.nestedcontainer}>
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              style={{
                paddingTop: "16px",
                paddingLeft: "16px",
              }}
            >
              {" "}
              <Typography style={{ color: "red", paddingRight: "4px" }}>
                *
              </Typography>
              <Typography>Why?</Typography>
            </Grid>
            <Grid
              item
              container
              xs={8}
              md={10}
              style={{
                paddingLeft: "14px",
                paddingRight: "14px",
              }}
            >
              <TextField
                id="why_2"
                disabled={props.readOnly}
                name="why_2"
                value={why2}
                multiline
                variant="outlined"
                style={{
                  background: "#fafafc",
                }}
                rows={3}
                onChange={handleChange}
                fullWidth
              />{" "}
            </Grid>{" "}
            <Grid
              item
              container
              xs={4}
              md={2}
              alignItems="center"
              justify="flex-end"
              style={{ marginLeft: -35, marginRight: -60, paddingRight: 0 }}
            >
              <IconButton
                disabled={props.readOnly}
                aria-label="close"
                onClick={() => removeWhy("why_2")}
                // onClick={cancel}
                // className={classes.iconbutton}
              >
                <DeleteIcon></DeleteIcon>
              </IconButton>
            </Grid>
          </Grid>
        )}
        {count > 3 && !why3Hidden && (
          <Grid container spacing={2} className={classes.nestedcontainer}>
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              style={{
                paddingTop: "16px",
                paddingLeft: "16px",
              }}
            >
              {" "}
              <Typography style={{ color: "red", paddingRight: "4px" }}>
                *
              </Typography>
              <Typography>Why?</Typography>
            </Grid>
            <Grid
              item
              container
              xs={8}
              md={10}
              style={{
                paddingLeft: "14px",
                paddingRight: "14px",
              }}
            >
              <TextField
                id="why_3"
                disabled={props.readOnly}
                name="why_3"
                value={why3}
                multiline
                variant="outlined"
                style={{
                  background: "#fafafc",
                }}
                rows={3}
                onChange={handleChange}
                fullWidth
              />{" "}
            </Grid>{" "}
            <Grid
              item
              container
              xs={4}
              md={2}
              alignItems="center"
              justify="flex-end"
              style={{ marginLeft: -35, marginRight: -60, paddingRight: 0 }}
            >
              <IconButton
                disabled={props.readOnly}
                aria-label="close"
                onClick={() => removeWhy("why_3")}
                // onClick={cancel}
                // className={classes.iconbutton}
              >
                <DeleteIcon></DeleteIcon>
              </IconButton>
            </Grid>
          </Grid>
        )}
        {count > 4 && !why4Hidden && (
          <Grid container spacing={2} className={classes.nestedcontainer}>
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              style={{
                paddingTop: "16px",
                paddingLeft: "16px",
              }}
            >
              {" "}
              <Typography style={{ color: "red", paddingRight: "4px" }}>
                *
              </Typography>
              <Typography>Why?</Typography>
            </Grid>
            <Grid
              item
              container
              xs={8}
              md={10}
              style={{
                paddingLeft: "14px",
                paddingRight: "14px",
              }}
            >
              <TextField
                id="why_4"
                disabled={props.readOnly}
                name="why_4"
                value={why4}
                multiline
                variant="outlined"
                style={{
                  background: "#fafafc",
                }}
                rows={3}
                onChange={handleChange}
                fullWidth
              />{" "}
            </Grid>{" "}
            <Grid
              item
              container
              xs={4}
              md={2}
              alignItems="center"
              justify="flex-end"
              style={{ marginLeft: -35, marginRight: -60, paddingRight: 0 }}
            >
              <IconButton
                disabled={props.readOnly}
                aria-label="close"
                onClick={() => removeWhy("why_4")}
                // className={classes.iconbutton}
              >
                <DeleteIcon></DeleteIcon>
              </IconButton>
            </Grid>
          </Grid>
        )}
        <Grid item container xs={12} alignItems="center" justify="flex-start">
          <Button
            variant="outlined"
            color="primary"
            onClick={addWhy}
            disabled={count > 4 || props.readOnly || noInput}
            className={classes.outlinedbutton}
          >
            Add Why
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Step0;
