import React, { Component, useEffect, useState, useRef, useContext } from "react";

import Alert from '@material-ui/lab/Alert';

import API from "../../Api/Api"

import { OrgContext } from "hooks/useOrganization"

import {
  Grid,
  TextField,
  Paper,
  Typography,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card,
  CardHeader,
  CardActionArea,
  CardContent,
  CircularProgress,
} from "@material-ui/core"

import { useHistory, useLocation } from "react-router-dom";

const defaultPlan = {
  name: "",
  description: "",

  facility: null,
  program: null,

  is_active: true,
  is_template: false,
  is_public: false,
};


export default function CreateNewPlan(props) {

  const [plan, setPlan] = useState({ ...defaultPlan });

  const [availablePrograms, setAvailablePrograms] = useState([]);
  const [programSelected, setProgramSelected] = useState(null);

  const [loading, setLoading] = useState(false);
  const [createdPlan, setCreatedPlan] = useState(null);

  const org = useContext(OrgContext);

  const filterPublic = true;

  useEffect(() => {
    new API().getPlanBuilderAPI().listPrograms(filterPublic).then(response => {
      setAvailablePrograms(response.data);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  const [currentStep, setCurrentStep] = useState(0);

  const history = useHistory();

  const steps = [
    getStepOne(),
    getStepTwo(),
    getComplete(),
  ]

  function goForward(event) {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
    else {
      history.replace("/dashboard");
    }
  }

  function goBack(event) {
    if (currentStep === 0) {
      history.goBack();
    }
    else {
      setCurrentStep(currentStep - 1);
    }
  }

  function handleChange(event) {
    setPlan({ ...plan, [event.target.name]: event.target.value });
  }

  function handleCheck(event) {
    setPlan({ ...plan, [event.target.name]: event.target.checked });
  }

  function selectProgram(program) {
    if (programSelected && (program.id === programSelected.id)) {
      setProgramSelected(null);
    }
    else {
      setProgramSelected(program);
    }
  }

  function submit(event) {
    setLoading(true);

    goForward();

    let newPlan = { ...plan, organization: org.pk, program: programSelected.id };
    new API().getPlanBuilderAPI().createPlan(newPlan).then(response => {
      setLoading(false);
      setCreatedPlan(response.data);
      props.refreshPlans(true);
    }).catch(error => {
      console.log(error);
    });
  }


  function getStepOne() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">
            Create a New Plan
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField name="name" variant="outlined" label="Name" value={plan.name} required fullWidth onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField name="description" variant="outlined" label="Description" value={plan.description} fullWidth onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={plan.is_active} onChange={handleCheck} name="is_active" label="Secondary" />} label="Set this plan as the dashboard for this organization." />
            <FormControlLabel control={<Checkbox checked={plan.is_template} onChange={handleCheck} name="is_template" label="Secondary" />} label="Is this a template?" />
          </FormGroup>
        </Grid>
        <Grid item container justify="flex-end" alignItems="flex-end" xs={12}>
          <Button variant="contained" color="secondary" onClick={goBack} style={{ margin: '4px' }}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={goForward} style={{ margin: '4px' }} disabled={plan.name === ""}>
            Continue
          </Button>
        </Grid>
      </Grid>
    )
  }

  function getStepTwo() {
    return (
      <Grid container direction="row" spacing={2} style={{ height: '100%' }}>
        <Grid item container xs={12}>
          <Typography variant="h5">
            Select a Program
          </Typography>
          <Grid item container xs={12}>
            {availablePrograms.map(program => {
              return (
                <Grid item key={program.id} xs={12}>
                  <Card style={{ width: '100%' }}>
                    <CardActionArea onClick={event => selectProgram(program)}>
                      <CardHeader title={program.name} />
                      <CardContent>
                        {programSelected && program.id === programSelected.id &&
                          <Alert severity="success">{programSelected.name} has been selected</Alert>
                        }
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
        <Grid item container justify="flex-end" alignItems="flex-end" xs={12}>
          <Button variant="contained" color="secondary" onClick={goBack} style={{ margin: '4px' }}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={submit} style={{ margin: '4px' }} disabled={programSelected == null}>
            Submit
          </Button>
        </Grid>
      </Grid>
    )
  }

  function getComplete() {
    if (loading) {
      return <CircularProgress />
    }

    return (
      <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          {createdPlan != null &&
            <Alert severity="success">{`Plan ${createdPlan.name} has been created`}</Alert>
          }
        </Grid>
        <Grid item container justify="flex-end" xs={12}>
          <Button variant="contained" color="primary" onClick={goForward} style={{ margin: '4px' }}>
            Continue
          </Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container direction="row" justify="center" alignItems="center" style={{ height: '100%' }}>
      <Grid item>
        <Paper style={{ width: '100vh', height: '90vh', padding: '32px' }}>
          {steps[currentStep]}
        </Paper>
      </Grid>
    </Grid>
  )
}
