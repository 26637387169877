import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import useAPI from "hooks/useAPI";
import { useHistory, useParams, useRouteMatch, Switch, Route } from "react-router";

import Grid from '@material-ui/core/Grid';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from "@material-ui/core/TablePagination";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TableContainer from "@material-ui/core/TableContainer";

import { makeStyles } from "@material-ui/core/styles";

import ContentHeader from "Components/Layouts/ContentHeader";
import LogSOP from "./LogSOP";
import LogEntry from "./LogEntry";
import useDesktop from "hooks/useDesktop";
import FilterColumns from "./FilterColumns";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { URL, API_VERSION } from "Api/Api";
import { formatDateTime } from "utils/date";

const useStyles = makeStyles({
	tableRoot: {
		margin: '0px',
		width: '100%',
		padding: '16px 12px',
	}
})

function LogTable({ log, headers, rows, query, totalCount }) {
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const [count, setCount] = useState(totalCount || 0);
	const [loading, setLoading] = useState(true);

	const api = useAPI();
	const history = useHistory();
	const classes = useStyles();

	useEffect(() => {
		if (log) {
			setLoading(true);
			query(log, page, rowsPerPage).then(response => {
				let paginatedResponse = response.data;
				setCount(paginatedResponse.count)
				setLoading(false);
			});


		}
	}, [log, rowsPerPage, page, totalCount])


	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleRowHoverOver = (event, row) => {
	}

	const handleRowHoverOut = (event, row) => {
	}

	const handleRowClick = (event, row) => {
	}

	function firstPage() {
		setPage(0);
	}

	function lastPage() {
		setPage()
	}

	function getHeader() {
		let tableCells = [];

		for (let i = 0; i < headers.length; i++) {
			if (!headers[i].visible) {
				continue;
			}

			tableCells.push(
				<TableCell style={{ backgroundColor: 'white' }}>
					{headers[i].label}
				</TableCell>
			)
		}

		return (
			<TableRow>
				{tableCells}
			</TableRow>
		)
	}

	function getBody() {
		if (loading) {
			return (
				<TableRow>
					<TableCell colSpan={6} style={{ textAlign: 'center' }}>
						<CircularProgress />
					</TableCell>
				</TableRow>
			)
		}

		let dataRows = rows.map(row => {
			let tableCells = [];

			headers.forEach(header => {
				if (header.visible) {
					if (header.id != 'is_completed') {
						tableCells.push(
							<TableCell>
								{row[header.id] != undefined || row[header.id] != null ? row[header.id] + "" : (row[header.id] || '-')}
							</TableCell>
						)
					} else {
						tableCells.push(
							<TableCell>
								{row[header.id] !== false ? 'Yes' : 'No'}
							</TableCell>
						)

					}
				}
			})

			return (
				<TableRow id={row.id} hover style={{ cursor: 'pointer' }} onClick={() => openLogDetails(row)}>
					{tableCells}
				</TableRow>
			);
		});

		return dataRows;
	}
	function openLogDetails(log) {
		if (log.is_completed !== false) {
			history.push(`view/${log.id}`)
		} else {
			history.push(`edit/${log.id}`)
		}
	}
	if (!log || !rows) {
		return <CircularProgress />
	}

	return (
		<Paper elevation={0} style={{ width: '100%', height: '100%', overflow: 'auto' }}>
			<Grid container direction="column" justify="space-between" style={{ height: '100%' }}>
				<Grid item xs={12}>
					<TableContainer>
						<Table classes={{ root: classes.tableRoot }} stickyHeader style={{ minWidth: '900px' }}>
							<TableHead>
								{getHeader()}
							</TableHead>
							<TableBody>
								{getBody()}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
				<Grid item xs={12}>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={count}
						rowsPerPage={rowsPerPage}
						page={page}
						onChangePage={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Grid>
			</Grid>
		</Paper>
	)
}


export default function LogEntries(props) {
	const [log, setLog] = useState(null);
	const [entries, setEntries] = useState(null);
	const [filteredEntries, setFilteredEntries] = useState(null);

	const [dialogOpen, setDialogOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [totalCount, setTotalCount] = useState(0);
	const [customFieldKeys, setCustomFieldKeys] = useState(["id", "submitted_by", "time_submitted"]);
	const [totalIncompleteRecords, setTotalIncompleteRecords] = useState(0);
	const api = useAPI();
	const params = useParams();
	const history = useHistory();
	const match = useRouteMatch();
	const onDesktop = useDesktop();
	const [incompletedFilterApplied, setIncompletedFilterApplied] = useState(false);

	function searchCustomFields(searchQ) {
		const customFields = log.read_tasks[0].read_custom_fields;

		searchQ = searchQ.toLowerCase();

		let found = entries.filter(obj => {
			const fieldInput = obj.custom_field_input;
			let include = false;

			for (let i = 0; i < customFields.length; i++) {

				if (!customFields[i].display_in_table) {
					include = true;
					continue
				}

				if (customFields[i].field_type === "checkbox") {
					include = true;
					continue;
				}

				const input = fieldInput[customFields[i].id];
				if (input && input.toLowerCase().includes(searchQ)) {
					include = true;
				}
			}

			if (obj.submitted_by.toLowerCase().includes(searchQ)) {
				include = true;
			}

			if (obj.time_submitted.toLowerCase().includes(searchQ)) {
				include = true;
			}

			if (obj.id.toString().toLowerCase().includes(searchQ)) {
				include = true;
			}

			return include;
		});

		setFilteredEntries(found);
		//return entries;
	}

	useEffect(() => {
		if (params.id && !log) {
			api.getEventAPI().retrieveEvent(params.id).then(response => {
				setLog(response.data);
				setLoading(false);
				query(response.data, 0, 10);
				getIncompleteEntryCount(response.data)
			}).catch(error => {
				//setLoading(true);
				history.replace("/logs")
			})


		}
	}, []);
	function getIncompleteEntryCount(log) {
		api.getLogsAPI().incompleteCount(`?log=${log.id}`).then(response => {
			setTotalIncompleteRecords(response?.data?.incompleted_records_count || 0)
			// console.log(response);
		}).catch(error => {
			//setLoading(true);

		})
	}
	function query(log, page, rowsPerPage) {
		if (log) {
			getIncompleteEntryCount(log)
			return api.getEventAPI().listLogRecords(log.id, page + 1, rowsPerPage, incompletedFilterApplied).then(response => {
				setEntries(response.data.results);
				setFilteredEntries(response.data.results);
				setTotalCount(response.data.count)

				setLoading(false);
				return response;

			}).catch(error => {
				//setLoading(true);
				history.replace("/logs");
			});
		}
	}


	useEffect(() => {
		console.log(incompletedFilterApplied);
		query(log, 0, 10);
	}, [incompletedFilterApplied])
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const goToLog = () => {
		history.push(`/plans/manager/events/${log.id}`);
	};

	const exportCSV = () => {
		api.getEventAPI().exportLogRecords(log.id).then(response => {
			handleMenuClose();
			window.open(response.data);
		}).catch(error => {
			console.error(error);
			handleMenuClose();
		});
	}

	const [snackbarOpen, setSnackbarOpen] = useState(false);
	function onSubmit() {
		query(log, 0, 10).then(response => {
			setSnackbarOpen(true);
		}).catch(error => {
			console.error(error);
		});
	}

	function handleClose() {
		setSnackbarOpen(false);
	}

	function getTableHeaders() {
		if (!log) {
			return [];
		}

		const customFields = log.read_tasks[0].read_custom_fields;
		let headers = []
		headers.push({ id: "form_num", label: "ID", visible: true });

		headers.push(...customFields.map(customField => {
			return { id: customField.id, label: customField.name, type: customField.field_type, custom: true, visible: customField.display_in_table }
		}));

		headers.push({ id: "is_completed", label: "Is Completed", visible: true });
		headers.push({ id: "submitted_by", label: "Submitted By", visible: true });
		headers.push({ id: "time_submitted", label: "Time Submitted", visible: true });

		return headers;
	}

	const [headers, setHeaders] = useState(null);
	useEffect(() => {
		if (log) {
			setHeaders(getTableHeaders());
		}
	}, [log]);

	function getTableRows() {
		if (!filteredEntries) {
			return [];
		}

		let rowData = filteredEntries.map(entry => {
			let obj = {};
			obj["id"] = entry.id;
			headers.forEach(header => {
				if (header.custom) {
					obj[header.id] = entry.custom_field_input[header.id.toString()]

					if (header.type === "datetime" && obj[header.id]) {
						obj[header.id] = formatDateTime(obj[header.id]);
					}
					else if (header.type === "checkbox" ) { // checkbox field
						obj[header.id] = obj[header.id] != true ? "No" : "Yes";
					}
					// else if (header.type === "checkbox") {
					// 	obj[header.id] = "No";
					// }
				} else {
					obj[header.id] = entry[header.id];
				}

			});

			return obj;
		});

		return rowData;
	}

	return (
		<>
			{log &&
				<LogSOP sop={log.read_tasks[0]} open={dialogOpen} setOpen={setDialogOpen} />
			}

			{log && !loading &&
				<ContentHeader
					title="Log Entries"
					search
					searchData={entries}
					setSearchResults={setFilteredEntries}
					filterFunc={(searchQ) => { searchCustomFields(searchQ) }}
				/>
			}

			<Grid container item xs={12} alignItems="center" justify="space-between" style={{ padding: (loading || !onDesktop) ? '32px 32px 0px 32px' : '32px 2.5% 0% 2.5%' }}>
				{log &&
					<>
						<Grid container item xs={12} alignItems="center" justify="space-between">
							<div>
								{/*<IconButton style={{display: 'inline-block'}} onClick={() => history.goBack()}>
								<ArrowBackIcon/>
							</IconButton>*/}
								<Typography style={{ display: 'inline-block', fontSize: '18px', fontWeight: 500, color: "#687895" }}>
									{log.name}
								</Typography>
							</div>

							<div>
								{totalIncompleteRecords > 0 &&
									<Button className={`incomplete-filter ${incompletedFilterApplied ? 'active' : ''}`} variant="contained" color="primary" size="small" onClick={() => { setIncompletedFilterApplied(!incompletedFilterApplied) }}>Incomplete ({totalIncompleteRecords})</Button>}
								<Button variant="contained" color="primary" size="small" disableElevation onClick={() => { setDialogOpen(true) }} style={{ margin: "0px 4px 0px 4px" }}>
									View SOP
								</Button>
								<Button variant="contained" color="primary" size="small" disableElevation onClick={() => { history.push(`submission`) }} style={{ margin: "0px 4px 0px 4px" }}>
									Add Entry
								</Button>
								<IconButton onClick={handleMenuOpen}>
									<MoreHorizIcon />
								</IconButton>
								<Menu
									id="log-menu"
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl)}
									onClose={handleMenuClose}
								>
									<FilterColumns headers={headers} setHeaders={setHeaders} onClose={() => handleMenuClose()} />

									<MenuItem onClick={goToLog}>Edit Log</MenuItem>
									<MenuItem onClick={exportCSV}>Export CSV</MenuItem>
								</Menu>
							</div>
						</Grid>
					</>
				}
			</Grid>

			<Grid container justify="center" alignItems="center" style={{ height: loading ? '100%' : null, padding: (loading || !onDesktop) ? '32px' : '16px 2.5% 0% 2.5%' }}>
				{log && headers &&
					<LogTable log={log} query={query} totalCount={totalCount} headers={headers} rows={getTableRows()} />
				}
			</Grid>

			<Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
				<Alert onClose={handleClose} severity="info">
					Saved!
				</Alert>
			</Snackbar>

			<Switch>
				<Route path={`${match.path}/view/:id`}>
					<LogEntry readOnly />
				</Route>
				<Route path={`${match.path}/edit/:id`}>
					<LogEntry edit onSubmit={onSubmit} />
				</Route>
				<Route path={`${match.path}/submission`}>
					<LogEntry onSubmit={onSubmit} />
				</Route>
			</Switch>
		</>
	)
}