class FCustomerAddressesAPI {
    constructor(api, version) {
        this.api = api;
        this.version = version;
    }

    listFCustomerAddresses(pk) {
        if (typeof pk === 'number') {
            return this.api.get(`${this.version}/f_customer_addresses/address_list/?customer=${pk}`);
        }
        return this.api.get(`${this.version}/f_customer_addresses/`)
    }

    createFCustomerAddresses(payload) {
        return this.api.post(`${this.version}/f_customer_addresses/`, payload);
    }

    updateFCustomerAddresses(payload) {
        return this.api.put(`${this.version}/f_customer_addresses/${payload.pk}/`, payload);
    }

    deleteFCustomerAddresses(pk) {
        return this.api.put(`${this.version}/f_customer_addresses/${pk}/soft_delete_action`);
    }

}

export default FCustomerAddressesAPI;