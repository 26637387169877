import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormGroup from "@material-ui/core/FormControl";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Button from "@material-ui/core/Button";
import API from "../../Api/Api";

export default function AcceptTerms({open, user, setUser}) {
    const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false);
    const [acceptTOS, setAcceptTOS] = useState(false);
    const history = useHistory();

    function handleChange(event) {
        if (event.target.name === "privacy-policy") {
            setAcceptPrivacyPolicy(event.target.checked);
        }
        if (event.target.name === "tos") {
            setAcceptTOS(event.target.checked);
        }
    }

    function submit(event) {
        if (!user) {
            return;
        }

        let terms = {accept_tos: acceptTOS, accept_privacy_policy: acceptPrivacyPolicy};

        new API().getAuthAPI().acceptTerms(user.id, terms).then(response => {
            setUser(response.data);
            history.replace("/")
        }).catch(error => {
            console.log(error);
        }); 
    }

    return (
        <>
        {user &&
            <Dialog open={true} fullWidth maxWidth={'sm'}>
                <DialogTitle>Terms of Service & Privacy Policy</DialogTitle>
                <DialogContent>
                    <Grid container style={{width: '100%', height: '100%', minHeight: '100px'}}>
                        <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox color="default" checked={acceptPrivacyPolicy} onChange={handleChange} name="privacy-policy" />}
                                label={<div>I understand and accept the <a href="https://www.joinfoodbyte.com/privacy-policy" target="_blank">Privacy Policy</a></div>}
                            />
                            <FormControlLabel
                                control={<Checkbox color="default" checked={acceptTOS} onChange={handleChange} name="tos" />}
                                label={<div>I understand and accept the <a href="https://www.joinfoodbyte.com/terms-services" target="_blank">Terms of Service</a></div>}
                            />
                        </FormGroup>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container item xs={12} alignItems="center" justify="flex-end">
                        <Button disabled={(!acceptTOS || !acceptPrivacyPolicy)} onClick={submit} variant="contained" color="primary">Continue</Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        }
        </>
    )
}