import React, { useEffect, useState } from 'react';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Traceability from "../Traceability";
import Message from "../../Components/Message";
import AutocompleteSD from "../../Components/AutocompleteSD";
import Dialog from "@material-ui/core/Dialog";
import TraceabilityUtils from "../TraceabilityUtils";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import IngredientTable from "./IngredientTable";
import AddSkuModal from "./AddSkuModal";
import PropTypes from "prop-types";
import { StylesContext } from "../../App";

const utils = new TraceabilityUtils();

const EMPTY_MESSAGE = {
  open: false,
  message: '',
  status: 'info',
};

export default function ManOrderForm(props) {
  const classes = React.useContext(StylesContext);

  const [manOrderData, setManOrderData] = useState(false);
  const [formulationSku, setFormulationSku] = useState(props.manOrderSheet.formulation);
  const [isBlocking, setIsBlocking] = useState(false);
  const [message, setMessage] = useState({ ...EMPTY_MESSAGE });
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [skuOptions, setSkuOptions] = useState([]);
  const [addSkuModalOpen, setAddSkuModalOpen] = useState(false);
  const [editLinesFunction, setEditLinesFunction] = useState(false);
  const [dummyKey, setDummyKey] = useState(1);
  const [dummyKeyAutoComplete, setDummyKeyAutoComplete] = useState(1);
  const [numberOfBatches, setNumberOfBatches] = useState(1);
  const [submitting, setSubmitting] = useState(false);

  /**
   * This is to be used once when creating a manOrder from a formulation, then shouldn't need to be called again.
   */
  function createManOrderFromFormulation(formulationSku) {
    let newManOrderForm = {
      formulation: formulationSku.id,
      // traceability: formulationSku.formulation.traceability,
      target_unit_value: formulationSku.unit_value,
      target_unit_type: formulationSku.unit_type,
      // inventory_location: formulationSku.inventory_location,
      production_date: new Date().toISOString().substr(0, 10),
      production_ingredient_lines: createNewManOrderLines(formulationSku),
    };

    return newManOrderForm;
  }

  useEffect(() => {
    if (!formulationSku || !manOrderData) {
      setNumberOfBatches(0);
      return;
    }

    let targetUnit = props.units[manOrderData.target_unit_type];
    let formulaUnit = props.units[formulationSku.unit_type];

    if (!targetUnit || !formulaUnit) {
      setNumberOfBatches(0);
      return;
    }

    try {
      let convertedTargetValue = utils.getConvertedValue(manOrderData.target_unit_value, targetUnit, formulaUnit);
      let batches = (convertedTargetValue / formulationSku.unit_value).toFixed(3);

      setNumberOfBatches(isNaN(batches) ? 0 : batches);
    }
    catch (e) {
      //Ignore this in the cases all the units haven't fully updated yet.
    }


  }, [manOrderData.target_unit_value, manOrderData.target_unit_type, formulationSku])

  function createNewManOrderLines(formulationSku) {
    if (!formulationSku) {
      return [];
    }

    let newIngLines = formulationSku.ingredients.map((ingFormInstance, i) => {
      let newProdIngLine = {};
      if (manOrderData && manOrderData.production_ingredient_lines && manOrderData.production_ingredient_lines[i]) {
        newProdIngLine.pk = manOrderData.production_ingredient_lines[i].pk;
      }

      newProdIngLine.ingredient_instance = ingFormInstance.pk;
      newProdIngLine.ingredient = ingFormInstance.ingredient.id;
      newProdIngLine.name = ingFormInstance.ingredient.name;
      newProdIngLine.unit_type = ingFormInstance.unit_type;
      newProdIngLine.unit_value = ingFormInstance.unit_value * numberOfBatches;
      // newProdIngLine.skus = ingFormInstance.ingredient.skus;
      newProdIngLine.picked_quantity = 0;
      newProdIngLine.picked_lot_code = [];
      newProdIngLine.lot_codes = [];
      newProdIngLine.amount_needed = ingFormInstance.unit_value * numberOfBatches;
      newProdIngLine.target = ingFormInstance.unit_value * numberOfBatches + ' ' + props.units[ingFormInstance.unit_type].abbreviation;

      return newProdIngLine;
    })

    return newIngLines;
  }

  function updateProductionIngredientLines(manOrderData, numberOfBatches, formulationSku) {
    let production_ingredient_lines;

    if (!!manOrderData.id) {
      if (!!numberOfBatches) {
        manOrderData.production_ingredient_lines.forEach(line => {
          let ingredient = formulationSku.ingredients.find(ingredient => ingredient.pk === line.ingredient_instance);

          // Update amount_needed based on batch multiplier
          line.amount_needed = ingredient.unit_value * numberOfBatches;

          // Update unit_value based on batch multiplier
          line.unit_value = ingredient.unit_value * numberOfBatches;

          line.target = ingredient.unit_value * numberOfBatches + ' ' + props.units[line.unit_type].abbreviation;
        });
      }

      production_ingredient_lines = manOrderData.production_ingredient_lines;
    } else {
      production_ingredient_lines = createNewManOrderLines(formulationSku);
    }

    return production_ingredient_lines;
  }

  useEffect(() => {
    let updatedProductionIngredientLines = updateProductionIngredientLines(manOrderData, numberOfBatches, formulationSku);
    setManOrderData({ ...manOrderData, production_ingredient_lines: updatedProductionIngredientLines });
  }, [numberOfBatches])

  function getUnitChoices(units, unitType) {
    const targetUnitType = units[unitType].unit_type;
    return Object.values(units)
      .filter(unit => unit.unit_type === targetUnitType)
      .map(unit => unit.pk);
  }

  useEffect(() => {
    if (formulationSku && (!manOrderData || !manOrderData.id)) {
      setManOrderData({ ...createManOrderFromFormulation(formulationSku)/*, target_unit_type: null*/ });
    }
    else if (formulationSku) {
      let updatedProductionIngredientLines = updateProductionIngredientLines(manOrderData, numberOfBatches, formulationSku);
      setManOrderData({
        ...manOrderData,
        /*target_unit_type: null,*/
        //target_unit_value: 0, 
        production_ingredient_lines: updatedProductionIngredientLines,
        // inventory_location: formulationSku.inventory_location,
      });
    }
    else {
      setManOrderData({ production_ingredient_lines: [], /*target_unit_type: null,*/ target_unit_value: 0 });
    }
    setDummyKey(dummyKey + 1);
    setDummyKeyAutoComplete(dummyKeyAutoComplete + 1);

  }, [formulationSku])

  useEffect(() => {
    if (props.manOrderSheet && props.manOrderSheet.id) {
      let newManOrder = { ...props.manOrderSheet };

      let ingredientsData = newManOrder.ingredientInstances.map(item => {
        // Find the corresponding formulation ingredient
        let formulationIngredient = formulationSku.ingredients.find(ingredient => ingredient.ingredient_name == item.ingredient_detail.name);

        // Extract properties from formulationIngredient if found
        let ingredientInstance = formulationIngredient ? formulationIngredient.pk : null;
        let unitType = formulationIngredient ? formulationIngredient.unit_type : null;
        let unitValue = formulationIngredient ? formulationIngredient.unit_value : null;
        let pickedLotCodes = item.lot_codes.map(lot => lot.code).join(', ');
        const totalPickedQuantity = item.lot_codes.reduce((total, lot) => total + lot.unit_value, 0);

        const unitTypeName = props.units[unitType].abbreviation || '';

        // Prepare the object with mapped properties
        return {
          ingredient_instance: ingredientInstance,
          ingredient: item.ingredient_detail.id,
          name: item.ingredient_detail.name,
          unit_type: unitType,
          unit_value: unitValue * numberOfBatches,
          picked_quantity: `${totalPickedQuantity} ${unitTypeName}`,
          picked_lot_code: pickedLotCodes,
          amount_needed: unitValue * numberOfBatches,
          lot_codes: item.lot_codes,
          target: `${unitValue} ${unitTypeName}`
        };
      });

      newManOrder.production_ingredient_lines = ingredientsData;
      newManOrder.target_unit_value = parseFloat(newManOrder.target_unit_value).toFixed(2)
      delete newManOrder.ingredientInstances;

      const unitChoices = getUnitChoices(props.units, props.manOrderSheet.formulation.unit_type);
      setManOrderData({ ...newManOrder });
      setFormulationSku({ ...props.manOrderSheet.formulation, unit_choices: unitChoices });
    } else {
      let newManOrderForm = {
        production_ingredient_lines: [],
        production_date: new Date().toISOString().substr(0, 10),
        status: 'Draft',
      };
      setManOrderData({ ...newManOrderForm });
    }
  }, [props.manOrderSheet])


  function handleTextFieldChange(event) {
    setIsBlocking(true);
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }
    const property = event.target.name;
    const value = event.target.value;

    setManOrderData({ ...manOrderData, [property]: value });
  }

  function handleAutoCompleteChange(newKey, newValue) {
    setIsBlocking(true);
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }

    setManOrderData({ ...manOrderData, [newKey]: newValue });
    setDummyKeyAutoComplete(dummyKeyAutoComplete + 1);
  }

  function calculateIngredientTotals(ingredients, displayFormat) {
    return ingredients
    // return ingredients.map((ingredientLine) => {

    //   let newIngredientLine = { ...ingredientLine };
    //   if (!manOrderData.inventory_location) {
    //     newIngredientLine.available = 'No Quantity Picked';
    //     newIngredientLine.status = 'No Lot Code';
    //   }
    //   else if (newIngredientLine.skus && newIngredientLine.skus.length > 0) {
    //     let parentMetric = props.units[ingredientLine.unit_type].unit_type;
    //     let parentBaseUnit = false;

    //     Object.keys(props.units).forEach((unitPk) => {
    //       if (parentBaseUnit) {
    //         return;
    //       }

    //       if (props.units[unitPk].unit_type == parentMetric) {
    //         parentBaseUnit = props.units[unitPk];
    //       }
    //     })

    //     const reducer = (accumulator, currentValue) => {
    //       let parentUnit = ingredientLine.unit_type;
    //       let value = 0;

    //       if (currentValue.stock_level.location_specific[manOrderData.inventory_location]) {
    //         value = currentValue.stock_level.location_specific[manOrderData.inventory_location];
    //       }

    //       return accumulator + utils.getConvertedValue(value, parentBaseUnit, props.units[parentUnit]);
    //     };

    //     let availableAmount = ingredientLine.skus.reduce(reducer, 0);
    //     newIngredientLine.available = utils.formatNumber(availableAmount) + ' ' + props.units[ingredientLine.unit_type].abbreviation;

    //     newIngredientLine.status = (availableAmount >= newIngredientLine.amount_needed) ? 'Ready To Produce' : 'Insufficient Stock';
    //     newIngredientLine.picked_quantity = 0;
    //     newIngredientLine.picked_lot_code = [];
    //     newIngredientLine.lot_codes = [];
    //   }
    //   else {
    //     newIngredientLine.picked_quantity = 0;
    //     newIngredientLine.picked_lot_code = [];
    //     newIngredientLine.lot_codes = [];
    //     newIngredientLine.available = 'Ingredient Has No SKUs';
    //     newIngredientLine.status = 'Insufficient Stock';
    //   }

    //   return newIngredientLine;
    // });
  }

  function onDetailClick(rowData, setLinesFunction) {
    setAddSkuModalOpen(true)
    setSkuOptions(rowData.skus);
    setEditLinesFunction(() => (rows) => setLinesFunction(rows))
  }

  function mapObjectToField(object, field) {
    let newObject = {};
    Object.keys(object).forEach((key) => {
      newObject[key] = object[key][field]
    })

    return newObject;
  }

  function validateProductionIngredients(ingredients) {
    for (let ingredient of ingredients) {
      if (!ingredient.picked_lot_code || ingredient.picked_lot_code.length === 0) {
        setMessage({ open: true, message: `Ingredient ${ingredient.name} has no picked lot code.`, status: 'error' });
        return false;
      }
      if (!ingredient.picked_quantity || ingredient.picked_quantity <= 0) {
        setMessage({ open: true, message: `Ingredient ${ingredient.name} has no valid picked quantity.`, status: 'error' });
        return false;
      }
      if (!(ingredient.picked_quantity == ingredient.target)) {
        setMessage({ open: true, message: `Please choose lotCode again for Ingredient ${ingredient.name}.`, status: 'error' });
        return false;
      }
    }
    return true;
  }

  function validateForm() {
    setSubmitAttempted(true);

    if (!formulationSku) {
      setMessage({ open: true, message: 'Please specify a formulation.', status: 'error' });
      return false;
    }

    if (!manOrderData.production_date) {
      setMessage({ open: true, message: 'Please specify a production date.', status: 'error' });
      return false;
    }
    
    if (!manOrderData.prod_lot_code) {
      setMessage({ open: true, message: 'Please specify an M/O id.', status: 'error' })
      return false;
    }
    
    const manOrderId = manOrderData.id;
    const manOrderLotCode = manOrderData.prod_lot_code;
    const foundKey = Object.keys(props.productionLotCodeDict).find(key => props.productionLotCodeDict[key] == manOrderLotCode);
    if (foundKey) {
      if (manOrderId != foundKey) {
        setMessage({ open: true, message: 'M/O id already exists for this lot code.', status: 'error' });
        return false;
      }
    }
    
    if (!manOrderData.target_unit_value || isNaN(manOrderData.target_unit_value) || manOrderData.target_unit_value.trim() === '') {
      setMessage({ open: true, message: 'Please specify a valid target amount.', status: 'error' })
      return false;
    }

    if (!manOrderData.target_unit_type) {
      setMessage({ open: true, message: 'Please specify a target unit type.', status: 'error' })
      return false;
    }

    if (!validateProductionIngredients(manOrderData.production_ingredient_lines)) {
      return false;
    }

    return true;
  }

  function handleSaveAndContinue(id, setSubmitting) {
    setIsBlocking(false);
    // props.handleStatusSubmit(id, setSubmitting);
  }

  function handleSubmit(saveAndContinue) {
    setSubmitting(true);
    if (!validateForm()) {
      setSubmitting(false);
      return;
    }

    const api = new Traceability().getFProductionsAPI();

    let manOrderPacket = { ...manOrderData };
    manOrderPacket.formulation = formulationSku.id;
    manOrderPacket.status = !saveAndContinue ? 'DRAFT' : 'ONGOING';

    // let organization = JSON.parse(localStorage.getItem("organization"));

    // manOrderPacket.organization = organization.pk;
    // manOrderPacket.traceability = props.activeTraceability.pk;
    // manOrderPacket.formulation = formulationSku.formulation.pk;
    // manOrderPacket.sku = formulationSku.pk;
    // manOrderPacket.user_entered_lot_code = manOrderPacket.batch_sheet_id;
    //manOrderPacket.production_date = utils.dateObjToString(manOrderPacket.production_date);

    manOrderPacket.ingredientInstances = manOrderPacket.production_ingredient_lines.map((prodIngLine) => {
      if (typeof (prodIngLine.ingredient) == "number") {
        return {
          ingredient: prodIngLine.ingredient,
          total_unit_type: prodIngLine.unit_type,
          total_unit_value: prodIngLine.unit_value,
          lot_codes: prodIngLine.lot_codes
        };
      }
      else {
        return {
          ingredient: prodIngLine.ingredient,
          total_unit_type: prodIngLine.unit_type,
          total_unit_value: prodIngLine.unit_value,
          lot_codes: prodIngLine.lot_codes
        };
      }
    });
    delete manOrderPacket.production_ingredient_lines;
    // console.log("manOrderPacket >> ", manOrderPacket)

    if (props.setShouldClose) {
      props.setShouldClose(true);
    }

    if (manOrderPacket.id) {
      api.updateFProduction(manOrderPacket).then(response => {
        if (props.setShouldClose) {
          props.setShouldClose(true);
        }

        //props.setManOrderSelected({ ...response.data});
        setSubmitAttempted(false);

        if (props.onSave) {
          props.onSave(response.data);
        }
        setMessage({ open: true, message: 'Saved Successfully', status: 'success', });
        setSubmitted(true);
        if (saveAndContinue) {
          handleSaveAndContinue(response.data.id, setSubmitting);
          props.cancel();
        }
        else {
          setSubmitting(false);
        }
      }).catch(error => {
        setSubmitting(false);
        setMessage({ open: true, message: 'Save Failed: ' + utils.formatError(error), status: 'error' });
      });
    }
    else {
      api.createFProduction(manOrderPacket).then(response => {//theres a crash specifically with this save mode?
        if (props.setShouldClose) {
          props.setShouldClose(true);
        }

        //setManOrderData({ ...response.data, consolidated_units: manOrderData.consolidated_units });
        //props.setManOrderSelected({ ...response.data});
        setSubmitAttempted(false);
        setManOrderData({...manOrderData,id:response.data.id})
        if (props.onSave) {
          props.onSave(response.data);
        }
        setMessage({ open: true, message: 'Saved Successfully', status: 'success', });
        setSubmitted(true);
        if (saveAndContinue) {
          handleSaveAndContinue(response.data.id, setSubmitting);
          props.cancel();
        }
        else {
          setSubmitting(false);
        }
      }).catch(error => {
        setSubmitting(false);
        setMessage({ open: true, message: 'Save Failed: ' + utils.formatError(error), status: 'error' });
        //console.log('Error Response: ' + JSON.stringify(error));
      });
    }
  }
  // console.log("props.formulations >> ", props.formulations)
  // console.log("formulationSku >> ", formulationSku)
  // console.log("manOrderData >> ", manOrderData)
  // console.log("props.manOrderSheet >> ", props.manOrderSheet)

  return (
    <>
      {(!manOrderData) &&
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginTop: "250px" }}>
            <Typography>
              <CircularProgress />
            </Typography>
          </Grid>
        </Grid>
      }

      {(manOrderData) &&
        <form onSubmit={handleSubmit}>
          {/* This Paper serves as the body element. */}
          <Paper elevation={0} className={classes.generalFormPaperFieldHolder} style={{ paddingTop: '0px' }}>
            <Grid container spacing={3} className={classes.generalContainerGridBody}>

              <Grid item xs={12}>
                <Typography variant="h5" noWrap>
                  Manufacturing Order
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Product Formulation Name
                </Typography>
                <AutocompleteSD
                  key={dummyKeyAutoComplete}
                  value={formulationSku}
                  id="formulation"
                  disabled={props.disabled}
                  options={Array.isArray(props.formulations) ? props.formulations : []}
                  optionType={"rawObjects"}
                  getOptionLabel={(option) => (option && option.name) ? option.name : 'No Selection'}
                  onChange={(emptyEvent, newValue) => {
                    const unitChoices = getUnitChoices(props.units, newValue.unit_type);
                    setFormulationSku({
                      ...newValue,
                      unit_choices: unitChoices
                    });
                  }}
                  renderInput={(params) => <TextField {...params} className={classes.generalFormTextField} variant="outlined" error={submitAttempted && !formulationSku} />}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.generalFormTypographyHeader}>
                  M/O ID
                </Typography>
                <TextField
                  fullWidth
                  name="prod_lot_code"
                  className={classes.generalFormTextField}
                  variant="outlined"
                  value={manOrderData.prod_lot_code}
                  onChange={handleTextFieldChange}
                  disabled={props.disabled}
                  error={submitAttempted && !manOrderData.prod_lot_code}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Production Date
                </Typography>
                <TextField
                  fullWidth
                  name="production_date"
                  className={classes.generalFormTextField}
                  color='primary'
                  type="date"
                  variant="outlined"
                  //defaultValue={new Date().toISOString().substr(0,10)}
                  value={manOrderData.production_date}
                  onChange={handleTextFieldChange}
                  disabled={props.disabled}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Target formulation yield per batch
                </Typography>
                <TextField
                  fullWidth
                  disabled={props.disabled}
                  name="target_unit_value"
                  className={classes.generalFormTextField}
                  variant="outlined"
                  value={manOrderData.target_unit_value}
                  error={submitAttempted && !manOrderData.target_unit_value}
                  onChange={handleTextFieldChange}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Target Unit Type
                </Typography>
                <AutocompleteSD
                  key={dummyKeyAutoComplete}
                  value={manOrderData.target_unit_type}
                  id="target_unit_type"
                  className={classes.generalFormTextField}
                  disabled={props.disabled}
                  optionType={'pkIndexingToObjects'}
                  options={(formulationSku && formulationSku.unit_choices) ? formulationSku.unit_choices : []}
                  choices={props.units}
                  error={submitAttempted && !manOrderData.target_unit_type}
                  getOptionLabel={(option) => (option && props.units[option]) ? props.units[option].full_name : 'No Name'}
                  onChange={(emptyEvent, newValue) => { handleAutoCompleteChange('target_unit_type', newValue) }}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                />
              </Grid>
              {/* <Grid item xs={6}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Production Location
                </Typography>
                <AutocompleteSD
                  key={dummyKeyAutoComplete}
                  value={manOrderData.inventory_location}
                  id="inventory_location"
                  className={classes.generalFormTextField}
                  disabled={props.disabled}
                  optionType={'pkIndexingToObjects'}
                  options={Object.keys(props.productionLocations)}
                  choices={props.productionLocations}
                  error={submitAttempted && !manOrderData.inventory_location}
                  getOptionLabel={(option) => (option && props.productionLocations[option]) ? props.productionLocations[option].name : 'Default Location No Longer Production'}
                  onChange={(emptyEvent, newValue) => { handleAutoCompleteChange('inventory_location', newValue) }}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                />
              </Grid> */}

              <Grid item xs={12}>
                <Typography variant="h5" noWrap>
                  Ingredients Required
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <IngredientTable
                  ingredients={calculateIngredientTotals(manOrderData.production_ingredient_lines, true)}
                  // internalLotCodes={props.internalLotCodes}
                  onDetailClick={onDetailClick}
                  // setIngredients={setIngredients}
                  unitChoices={mapObjectToField(props.units, 'full_name')}
                  units={props.units}
                  batches={numberOfBatches}
                  setManOrderData={setManOrderData}
                  manOrderData={manOrderData}
                  //validateLotCodes={validateLotCodes}
                  //validateAmount={validateAmount}
                  //validateTotal={validateTotal}
                  disabled={props.disabled}
                  key={dummyKey}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

            </Grid>

            <Message
              open={message.open}
              message={message.message}
              severity={message.status}
              vertical="bottom"
              horizontal="left"
              handleClose={() => { setMessage({ ...message, open: false }) }}
            />

            <Dialog open={addSkuModalOpen} onClose={() => { setAddSkuModalOpen(false) }} maxWidth={"lg"}>
              <DialogContent>
                <AddSkuModal
                  skus={skuOptions}
                  units={props.units}
                  setSkus={editLinesFunction}
                />
              </DialogContent>
            </Dialog>
          </Paper>

          {/* This Grid serves as the footer element. */}
          <Paper elevation={0} className={classes.generalFormPaperStickyFooter}>
            <Grid container spacing={3} className={classes.generalContainerGridFoot}>
              <Grid item container xs={12} alignItems="center" justify="flex-end">
                {props.closeModal &&
                  <Button
                    //variant="outlined"
                    color="secondary"
                    style={{ marginLeft: "8px" }}
                    onClick={props.closeModal}
                    disabled={submitting}
                  >
                    Cancel
                  </Button>
                }
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginLeft: "8px" }}
                  onClick={() => { handleSubmit(false) }}
                  disabled={props.disabled || submitting}
                >
                  Save Changes
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: "8px" }}
                  onClick={() => { handleSubmit(true) }}
                  disabled={props.disabled || submitting}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </form>
      }
    </>
  )
}

ManOrderForm.propTypes = {
  units: PropTypes.objectOf(PropTypes.object),
  formulaSkus: PropTypes.arrayOf(PropTypes.object),
  formulationSku: PropTypes.object,
  manOrderSheet: PropTypes.object,
  handleSubmit: PropTypes.func,
  // handleStatusSubmit: PropTypes.func,
  user: PropTypes.object.isRequired,
  activeTraceability: PropTypes.object.isRequired,
  internalLotCodes: PropTypes.object,
  setShouldClose: PropTypes.func,
  onSave: PropTypes.func,
  closeModal: PropTypes.func,
  cancel: PropTypes.func,
  disabled: PropTypes.bool,
  setReturnMessage: PropTypes.func,
}