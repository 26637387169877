import React, { Component, useEffect, useState, useRef, useContext } from "react";
import _ from 'lodash';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, DragSource } from 'react-dnd';
import { useDrop } from 'react-dnd'

import { OrgContext } from "hooks/useOrganization"

import SortableTree,
{
  getTreeFromFlatData,
  getFlatDataFromTree,
  changeNodeAtPath,
  find
} from 'react-sortable-tree';

import 'react-sortable-tree/style.css';

import PropTypes from 'prop-types';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import API from "../../Api/Api"
import Hidden from "@material-ui/core/Hidden";

import Node, { ItemTypes } from "./Node";

import StandardEditor from "./StandardEditor";
import ProgramManager from "./ProgramManager";
import { Prompt } from "react-router-dom";

import useDesktop from "../../hooks/useDesktop";

const fontStyle = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '21px'
};

function PlanTreeNavigator(props) {
  let api = new API().getPlanBuilderAPI();

  const [program, setProgram] = useState(null);
  const [nodes, setNodes] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [init, setInit] = useState(true);

  const org = useContext(OrgContext);

  let nodeIsDragging = false;

  function getCurrentId(nodes) {
    let currentId = 0;
    if (nodes && nodes.length) {
      currentId = Math.max(...nodes.filter(node => node.id != null).map((node) => node.id));
    }

    return currentId;
  }

  useEffect(() => {
    if (props.activePlan) {
      setProgram(props.activePlan);
      setLoading(false);

      if (props.activePlan.nodes && init) {
        setNodeSelected(props.activePlan.nodes[0]);
      }

      let tree = getTreeFromFlatData({
        flatData: props.activePlan.nodes,
        getKey: getNodeKey,
        getParentKey: node => node.parent,
        rootKey: null
      });

      setNodes(tree);
      setInit(false);
    }
  }, [props.activePlan]);

  function updateTree(updatedTreeData) {
    setUnsavedChanges(true);

    let flatData = getFlatDataFromTree({
      treeData: updatedTreeData,
      getNodeKey: getNodeKey,
      ignoreCollapsed: false,
    }).map(({ node, path, treeIndex, expanded }) => ({
      ...node,
      id: node.id,
      treeIndex: treeIndex,
      treeID: treeIndex,
      title: node.title,
      parent: path.length > 1 ? path[path.length - 2] : null,
    }));

    let currentId = getCurrentId(flatData) + 1;

    for (let node of flatData) {
      if (node.new && node.id == null) {
        console.log(node, currentId);
        node.id = currentId; 
        currentId++;
      }
    }

    const tree = getTreeFromFlatData({
      flatData: flatData,
      getKey: getNodeKey,
      getParentKey: node => node.parent,
      rootKey: null
    });

    setNodes(tree);
  }

  useEffect(() => {
    if (nodeSelected) {
      let updatedNodeSelected = getNodeById(nodeSelected.id);
      setNodeSelected(updatedNodeSelected);
    }
  }, [nodes]);

  function onTreeDataChange(updatedTreeData) {
    updateTree(updatedTreeData);
  }

  function saveTree(flatData) {
    //debouncedSave(flatData, program);
    update(flatData, program)
  }

  const [saving, setSaving] = useState(false);
  const debouncedSave = useRef(_.debounce((flatData, program) => update(flatData, program), 0)).current;

  function update(flatData, program) {
    setSaving(true);

    for (let node of flatData) {
      if (node.new) {
        delete node.id;
      }
    }

    api.updateNodes(flatData, program.id).then(response => {
      let tree = getTreeFromFlatData({
        flatData: response.data,
        getKey: getNodeKey,
        getParentKey: node => node.parent,
        rootKey: null
      });

      setNodes(tree);
      setSaving(false);
      setUnsavedChanges(false);
      setAlertOpen(true);

      props.setActivePlan({ ...program, nodes: response.data });
    }).catch(error => {
      console.error(error);
    })
  }

  const getNodeKey = ({ treeIndex }) => treeIndex;

  function setNodeData(treeId, name, value) {
    const node = getNodeByIndex(treeId);
    const updatedNode = { ...node, [name]: value };

    const updatedTreeData = changeNodeAtPath({
      treeData: nodes,
      path: node.path,
      newNode: updatedNode,
      getNodeKey: getNodeKey,
    });

    onTreeDataChange(updatedTreeData, updatedNode);
  }

  function getNodeByIndex(searchQuery, freshNodes = null) {
    let search = find({
      getNodeKey: getNodeKey,
      treeData: freshNodes || nodes,
      searchQuery: searchQuery,
      searchMethod: ({ path, treeIndex, node, searchQuery }) => { return node.treeID === searchQuery; },
      matches: [],
    });

    if (search.matches.length) {
      return { ...search.matches[0].node, treeIndex: search.matches[0].treeIndex, path: search.matches[0].path };
    }
  }

  function getNodeByName(name) {
    let search = find({
      getNodeKey: getNodeKey,
      treeData: nodes,
      searchQuery: name,
      searchMethod: ({ node }) => { return node.title === name; },
      matches: [],
    });

    if (search.matches.length) {
      return { ...search.matches[0].node, treeIndex: search.matches[0].treeIndex, path: search.matches[0].path };
    }
  }

  function getNodeById(id) {
    let search = find({
      getNodeKey: getNodeKey,
      treeData: nodes,
      searchQuery: id,
      searchMethod: ({ node }) => { return node.id === id; },
      matches: [],
    });

    if (search.matches.length) {
      return { ...search.matches[0].node, treeIndex: search.matches[0].treeIndex, path: search.matches[0].path };
    }
  }

  const [nodeSelected, setNodeSelected] = useState(null);
  const [standardSelected, setStandardSelected] = useState(null);

  useEffect(() => {
    setStandardEditorOpen(standardSelected != null);
  }, [standardSelected]);

  const [standardEditorOpen, setStandardEditorOpen] = useState(false);


  function standardUpdated(standard) {
    setStandardSelected(null);

    let node = getNodeById(standardSelected.id);

    let newNode = { ...node, title: standard.title, 'standard': standard };

    console.log(newNode);

    let changedTreeData = changeNodeAtPath({
      treeData: nodes,
      path: node.path,
      newNode: newNode,
      getNodeKey: getNodeKey,
    });

    onTreeDataChange(changedTreeData);
  }

  function deleteSection(section) {
    let node = getNodeByIndex(section.treeID);

    let changedTreeData = changeNodeAtPath({
      treeData: nodes,
      path: node.path,
      getNodeKey: getNodeKey,
      newNode: null,
    });

    onTreeDataChange(changedTreeData);
  }

  function cancelStandardEditor() {
    setStandardSelected(null);
  }

  function save() {
    let flatData = getFlatDataFromTree({
      treeData: nodes,
      getNodeKey: getNodeKey,
      ignoreCollapsed: false
    }).map(({ node, path, treeIndex, expanded }) => ({
      ...node,
      id: node.id,
      treeIndex: treeIndex,
      title: node.title,
      parent: path.length > 1 ? path[path.length - 2] : null,
    }));

    saveTree(flatData, program);
  }

  const [alertOpen, setAlertOpen] = useState(false);


  function handleClose(event) {
    setAlertOpen(false);
  }
  const onDesktop = useDesktop();

  return (
    <React.Fragment>
      <Prompt when={unsavedChanges} message="You have unsaved changes, are you sure you'd like to leave this page?" />
      {standardSelected &&
        <StandardEditor
          open={standardEditorOpen}
          standardSelected={standardSelected.standard}
          standardUpdated={standardUpdated}
          cancel={cancelStandardEditor}
        />
      }

      <DndProvider backend={HTML5Backend}>
        <Grid container style={{ height: '100%' }}>
          {loading &&
            <Grid item container xs={12} alignItems="center" justify="center" style={{ height: '100%', width: '100%' }}>
              <CircularProgress />
            </Grid>
          }

          {props.user && program && props.sideMenuOpen &&
            <Grid item xs={onDesktop ? 3 : 12} style={{ height: "100%", borderRight: '1px solid #eaeaea' }}>
              {org && editMode && <>
                <Grid item container alignItems="center" justify="center" xs={12}>
                  <Grid item xs={6} style={{ padding: '8px', borderBottom: '1px solid #eaeaea' }}>
                    <DragComponent
                      node={
                        {
                          new: true,
                          type: "SECTION",
                          program: program.id,
                          organization: org.pk,
                          title: "",
                          expanded: true,
                        }
                      }
                    />
                  </Grid>
                  <Grid item xs={6} style={{ padding: '8px', borderBottom: '1px solid #eaeaea' }}>
                      <DragComponent
                        node={
                          {
                            new: true,
                            type: "STANDARD",
                            program: program.id,
                            organization: org.pk,
                            title: "",
                            expanded: false,
                          }
                        }
                      />
                  </Grid>
                </Grid>
              </>
              }
              <SortableTree
                maxDepth={4}
                treeData={nodes}
                onChange={onTreeDataChange}
                dndType={externalNodeType}
                //scaffoldBlockPixelWidth={25}

                rowHeight={42}
                rowDirection="ltr"

                theme={FileExplorerTheme}

                style={{ height: '100%', minHeight: '100%', paddingTop: '16px' }}

                canDrag={editMode}

                onDragStateChanged={({ isDragging, draggedNode }) => { nodeIsDragging = isDragging; }}

                onMoveNode={
                  ({ node, nextParentNode, }) => {
                    console.log(node);
                    nodeIsDragging = false;
                  }
                }

                canNodeHaveChildren={(node) => { return nodeIsDragging ? node.expanded : true }}

                canDrop={
                  ({ node, prevPath, prevParent, prevTreeIndex, nextPath, nextParent, nextTreeIndex }) => {

                    if (!editMode) {
                      return false;
                    }
                    if (node != null && nextParent != null) {
                      // Can't place a section at a depth > 3 
                      if (node.type === "SECTION" && nextPath.length > 3) {
                        return false;
                      }

                      // Can't test a section in a standard
                      if (node.type === "SECTION" && nextParent.type === "STANDARD") {
                        return false;
                      }

                      // Can't nest a standard in a standard
                      if (node.type === "STANDARD" && nextParent.type === "STANDARD") {
                        return false;
                      }
                    }
                    return true;
                  }
                }

                generateNodeProps={({ node, path, treeIndex }) => {
                  return {
                    style: {
                      //borderLeft: node.type === "TEMPLATE_SECTION" ? '1px solid black' : "1px solid black",
                      //borderTop: node.type === "TEMPLATE_SECTION" ? '1px solid black' : "1px solid black",
                      cursor: 'default',
                      height: '36px',
                      border: nodeSelected && nodeSelected.id === node.id ? '1px solid #18bff6' : '1px solid white',
                      ...fontStyle,
                    },

                    onClick: (event) => {
                      if (props.closeSideMenu) {
                        props.closeSideMenu();
                      }
                      event.stopPropagation();
                      setNodeSelected({ ...node, treeIndex: treeIndex });
                    },

                    className: "data-hide-handle",

                    title: (<StandardBin name={node.title} />),
                  }
                }} />
            </Grid>
          }

          <Hidden smDown={!onDesktop && props.sideMenuOpen}>
            <Grid item container direction="column" justify="flex-start" alignItems="flex-start" xs={props.sideMenuOpen ? onDesktop ? 9 : 6 : 12}>
              <Grid container style={{ zIndex: 1, margin: "0px", width: "100%" }}>
                {program &&
                  <ProgramManager
                    readOnly={props.readOnly}
                    program={program}
                    user={props.user}
                    hasUnsavedChanges={false}
                    save={save}
                    saving={saving}
                    editMode={editMode}
                    setEditMode={setEditMode}
                  />
                }
              </Grid>
              <Grid container item style={{ padding: '16px', maxWidth: '100%', maxHeight: 'calc(100vh - 150px)', overflow: 'auto' }}>
                <Paper elevation={0} style={{ width: '100%', backgroundColor: '#F8F8F8' }} square>
                  {nodeSelected &&
                    <Node
                      readOnly={!editMode}
                      tree={nodes}
                      node={nodeSelected}
                      setNodeData={setNodeData}
                      setStandardSelected={setStandardSelected}
                      deleteSection={deleteSection}
                      offset={16}
                    />
                  }
                </Paper>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </DndProvider>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>

        <Alert severity="info" onClose={() => { setAlertOpen(false) }}>
          The program has been saved.
        </Alert>
      </Snackbar>
    </React.Fragment>
  )
}

// -------------------------
// Create an drag source component that can be dragged into the tree
// https://react-dnd.github.io/react-dnd/docs-drag-source.html
// -------------------------
// This type must be assigned to the tree via the `dndType` prop as well
const externalNodeType = 'yourNodeType';
const externalNodeSpec = {
  // This needs to return an object with a property `node` in it.
  // Object rest spread is recommended to avoid side effects of
  // referencing the same object in different trees.
  beginDrag: componentProps => ({ node: { ...componentProps.node } }),
};

const externalNodeCollect = (connect /* , monitor */) => ({
  connectDragSource: connect.dragSource(),
  // Add props via react-dnd APIs to enable more visual
  // customization of your component
  // isDragging: monitor.isDragging(),
  // didDrop: monitor.didDrop(),
});

class externalNodeBaseComponent extends Component {
  render() {
    const { connectDragSource, node } = this.props;

    return connectDragSource(
      <div style={{ width: '100%' }}>
        <Card elevation={0} square>{node.type === "SECTION" && <Typography align="center">+ Section</Typography>}</Card>
        <Card elevation={0} square>{node.type === "STANDARD" && <Typography align="center">+ Standard</Typography>}</Card>
      </div>
      ,
      { dropEffect: 'copy' }
    );
  }
}

externalNodeBaseComponent.propTypes = {
  node: PropTypes.shape({ title: PropTypes.string }).isRequired,
  connectDragSource: PropTypes.func.isRequired,
};

const DragComponent = DragSource(
  externalNodeType,
  externalNodeSpec,
  externalNodeCollect
)(externalNodeBaseComponent);


function StandardBin(props) {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.STANDARD,
    drop: () => ({ name: props.name }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver
  let backgroundColor = '#222'

  return (
    <div ref={drop} style={{
      height: '32px',
      width: '100%',
      display: 'flex',
      paddingLeft: '4px',
      alignItems: 'center',
      justifyContent: 'flex-start'
    }}>
      <div style={{
        //width: "calc(75%)",
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}>
        {props.name}
      </div>
    </div>
  )
}


export default PlanTreeNavigator
