import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams, Link } from "react-router-dom";

import { Grid, Typography, LinearProgress } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: "10px",
    borderRadius: "4px",
  },
  colorPrimary: {
    backgroundColor: "rgba(0, 0, 255, 0.05)",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#0000FF",
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    borderBottom: "1px solid #eaeaea",
  },
  planProgress: {
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: 400,
    lineHeight: "21px",
    color: "#687895",
  },
  sectionName: {
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: 500,
    lineHeight: "21px",
    color: "#000000",
  },
  sectionContainer: {
    marginTop: "8px",
    marginBottom: "8px",
  },
  sectionPercentage: {
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: 400,
    lineHeight: "21px",
    color: "#687895",
    float: "right",
  },
}));

export default function OnboardingProgress(props) {
  const classes = useStyles();
  const [progressPercentages, setProgressPercentages] = useState([
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ]);

  useEffect(() => {
    let allSections = [...props.sections];
    let allInputs = [...props.inputs];
    let percentages = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    allSections.forEach((section) => {
      section.onboarding_tasks.forEach((task) => {
        allInputs.forEach((input) => {
          if (input.task.name === task.name) {
            if (input.completed) {
              percentages[section.order]++;
            }
          }
        });
      });
    });
    setProgressPercentages(percentages);
  }, [props.sections, props.inputs]);

  function getProgress(section) {
    return parseFloat(
      (progressPercentages[section.order] / section.onboarding_tasks.length) *
        100
    ).toFixed(0);
  }

  return (
    <Grid container>
      <Grid item xs={12} style={{ marginBottom: "8px" }}>
        <Typography className={classes.planProgress}>Plan Progress</Typography>
      </Grid>
      {props.sections &&
        props.sections.map((section) => {
          return (
            <Grid item container key={section.id} xs={12} className={classes.sectionContainer}>
              <Grid item xs={6} style={{ marginBottom: "4px" }}>
                <Typography className={classes.sectionName}>
                  {section.name}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ marginBottom: "4px" }}>
                <Typography className={classes.sectionPercentage}>
                  {getProgress(section)}%
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <BorderLinearProgress
                  variant="determinate"
                  value={parseInt(getProgress(section))}
                />
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
}
