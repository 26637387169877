import React from "react"
import { DialogContent } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Hazard from "../../Hazards/Hazard";
import { useState } from "react";

function EditHazardModal(props) {

  return (
    <Hazard 
      allIngredients={props.allIngredients}
      save={props.save} 
      saveAndContinue={props.saveAndContinue} 
      open={props.open}
      handleModalClosed={props.handleModalClosed}
      hasButton={true} 
      setShouldClose={props.setShouldClose}
      setReturnStatus={props.setReturnStatus} 
      setShouldUpdate={props.setShouldUpdate}
      pk={props.hazard_pk} 
      haccpPlanPk={props.haccpPlanPk}
      user={props.user}
      cancel={props.cancel}
    />
  )
}


export default EditHazardModal;
