import React, { useState } from "react";

import Grid from "@material-ui/core/Grid";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from "@material-ui/core/CircularProgress";

import { makeStyles } from "@material-ui/styles";
import FieldBuilder from "./FieldBuilder";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Prompt from "./Prompt";
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from "@material-ui/core/Tooltip";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

import useAPI from "hooks/useAPI";


const useStyles = makeStyles({
  header: { fontStyle: 'Roboto', fontSize: '14px', fontWeight: 500, fontHeight: '21px' },
  title: { fontStyle: 'Roboto', fontSize: '16px', fontWeight: 500, fontHeight: '21px' },
});

const defaultCustomField = {
  name: "",
  description: "",
  field_type: 'textfield',
  required: false,
  display_in_table: true,
  radio_options: [],
  select_options: [],
}

export default function CustomRecordFields({ task, updateTask, readOnly }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [newOpen, setNewOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [loading, setLoading] = useState(false);

  const [customFieldSelected, setCustomFieldSelected] = useState(null);

  const api = useAPI();

  function openAddField() {
    setCustomFieldSelected({ ...defaultCustomField, display_order: task.read_custom_fields && task.read_custom_fields?.length ? task.read_custom_fields?.length + 1 : 1 });
    setNewOpen(true);
  }

  function updateCustomField(customField) {
    if (newOpen) {
      setLoading(true);
      api.getCustomFieldsAPI().createField(
        { ...customFieldSelected, organization: task.organization, task: task.id }
      ).then(response => {
        let updatedTask = { ...task };
        updatedTask.custom_fields.push(response.data.id);
        updatedTask.read_custom_fields.push(response.data);
        updateTask(updatedTask, false, true);
        clear();
        setLoading(false);
      }).catch(error => {
        //setLoading(false);
        console.error(error);
      })
    }
    else {
      setLoading(true);
      api.getCustomFieldsAPI().updateField({ ...customFieldSelected }).then(response => {
        let updatedTask = { ...task };
        let fieldToUpdateIndex = updatedTask.read_custom_fields.findIndex(field => {
          return field.id === response.data.id;
        });

        if (fieldToUpdateIndex > -1) {
          updatedTask.custom_fields[fieldToUpdateIndex] = response.data.id;
          updatedTask.read_custom_fields[fieldToUpdateIndex] = response.data;
          updateTask(updatedTask, false, true);
        }

        clear();
        setLoading(false);
      }).catch(error => {
        console.error(error);
        //setLoading(false);
      })
    }
  }

  function detachCustomField() {
    api.getCustomFieldsAPI().deleteField(customFieldSelected).then(response => {
      let updatedTask = { ...task };
      updatedTask.custom_fields = updatedTask.custom_fields.filter(field => field !== customFieldSelected.id);
      updatedTask.read_custom_fields = updatedTask.read_custom_fields.filter(field => field.id !== customFieldSelected.id);
      updateTask(updatedTask, false, true);

      
      api.getTaskAPI().updateTask(updatedTask).then(response => {
        updateTask(response.data, false, true);
      })
      setDeleteOpen(false)
      clear();
    }).catch(error => {
      console.log(error);
      setDeleteOpen(false);
    })
  }

  function detachCustomFieldAgree(customField) {
    if (customField) {
      detachCustomField(customField);
    }

    setCustomFieldSelected(null);
  }

  function detachCustomFieldDisagree() {
    setCustomFieldSelected(null);
    setDeleteOpen(false);
  }

  function cancel() {
    clear();
  }

  function clear() {
    setOpen(false);
    setNewOpen(false);
    setCustomFieldSelected({ ...defaultCustomField });
    setDeleteOpen(false);
  }

  function moveFieldUp(customField) {
    let readCustomFields = [...task.read_custom_fields];

    let index = readCustomFields.findIndex((val) => {
      return val.id === customField.id;
    });

    if (index <= -1) {
      return;
    }

    if (index === 0) {
      return;
    }

    let temp = readCustomFields[index - 1];
    readCustomFields[index - 1] = readCustomFields[index];
    readCustomFields[index] = temp;

    let fieldsInOrder = readCustomFields.map(read_task => read_task.id)
    let updatedTask = { ...task, custom_fields: fieldsInOrder, read_custom_fields: readCustomFields };

    setUnsavedChanges(true);
    api.getTaskAPI().updateTask(updatedTask).then(response => {
      updateTask(response.data, false, true);
    })
  }

  function moveFieldDown(customField) {
    let readCustomFields = [...task.read_custom_fields];

    let index = readCustomFields.findIndex((val) => {
      return val.id === customField.id;
    });

    if (index <= -1) {
      return;
    }

    if (index === readCustomFields.length - 1) {
      return;
    }

    let temp = readCustomFields[index + 1];
    readCustomFields[index + 1] = readCustomFields[index];
    readCustomFields[index] = temp;

    let fieldsInOrder = readCustomFields.map(read_task => read_task.id)
    let updatedTask = { ...task, custom_fields: fieldsInOrder, read_custom_fields: readCustomFields };

    setUnsavedChanges(true);
    api.getTaskAPI().updateTask(updatedTask).then(response => {
      updateTask(response.data, false, true);
    })
  }

  function getCustomFieldCard(customField) {
    return (
      <Card key={customField.id} elevation={0} style={{ width: '100%', padding: '0px', marginBottom: '16px' }}>
        <CardContent style={{ padding: '8px' }}>
          <Grid container>
            <Grid container item xs={9} alignItems="center" style={{ padding: '4px' }}>
              <Typography className={classes.header}>{customField.name}</Typography>
            </Grid>
            {!readOnly &&
              <Grid container item xs={3} alignItems="center" justify="flex-end" style={{ padding: '4px' }}>
                <Tooltip title="Move up">
                  <IconButton onClick={(event) => { event.stopPropagation(); moveFieldUp(customField) }}>
                    <ArrowUpwardIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Move down">
                  <IconButton onClick={(event) => { event.stopPropagation(); moveFieldDown(customField) }}>
                    <ArrowDownwardIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton onClick={() => { setCustomFieldSelected(customField); setOpen(true) }}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                  <IconButton onClick={() => { setCustomFieldSelected(customField); setDeleteOpen(true); }}>
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            }
          </Grid>
          <Prompt
            agree={() => detachCustomFieldAgree(customField)}
            disagree={detachCustomFieldDisagree}
            open={(customFieldSelected && customFieldSelected.id) === customField.id && deleteOpen}
            title="Remove custom field?"
            description="Are you sure you want to remove this custom field? This action cannot be reversed."
          />
        </CardContent>
      </Card>
    )
  }

  return (
    <>
      <Grid item container xs={6} alignItems="center">
        <Typography className={classes.header}>
          Monitoring Form <span style={{ color: '#18bff6' }}>({task.custom_fields.length})</span>
        </Typography>
      </Grid>

      <Grid item container xs={6} alignItems="center" justify="flex-end">
        <Button size="small" onClick={openAddField}>+ Add Field</Button>
      </Grid>

      {task &&
        <Grid item container xs={12} style={{ paddingTop: '8px' }}>
          {task.read_custom_fields.map(customField => getCustomFieldCard(customField))}
        </Grid>
      }

      <Dialog open={open || newOpen} maxWidth='md' fullWidth onClose={() => clear()}>
        <DialogTitle>
          Add a custom field to this task
        </DialogTitle>

        <DialogContent style={{ height: '900px' }}>
          {!loading &&
            <FieldBuilder customField={customFieldSelected} setCustomField={setCustomFieldSelected} />
          }

          {loading &&
            <Grid container item xs={12} justify="center" alignItems="center" style={{ height: '100%' }}>
              <CircularProgress />
            </Grid>
          }
        </DialogContent>

        <DialogActions>
          <Button disableElevation variant="contained" color="secondary" onClick={cancel}>
            Cancel
          </Button>
          <Button disableElevation variant="contained" color="primary" onClick={updateCustomField}>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}