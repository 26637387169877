import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { makeStyles } from "@material-ui/core/styles";
import ReportIcon from "@material-ui/icons/Report";

import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles({
  gridcontainer: {
    paddingTop: "100px",
    padding: "10px",
    height: "80%",
    width: "100%",
    overflowY: "hidden",
    marginBottom: "100px",
  },
  defaultcard: { height: "248px", width: "248px", backgroundColor: "#ffffff" },
  defaultcardselected: {
    height: "248px",
    width: "248px",
    backgroundColor: "#ffffff",
    borderColor: "#18bff6",
    "& .MuiPaper-rounded": {
      borderStyle: "solid",
      borderColor: "#18bff6",
    },
  },
  defaultnocard: {
    height: "248px",
    width: "248px",
    backgroundColor: "#ffffff",
  },
  cardactions: { justifyContent: "center", paddingTop: 40 },
  cardactionswithcheck: { justifyContent: "center", paddingTop: 65 },
  header: { fontSize: "34px" },
  cardsymbol: { paddingBottom: 12 },
});

function Step1(props) {
  const classes = useStyles();
  
  const onDesktop = useMediaQuery('(min-width:600px)');

  useEffect(() => {
    if (props.open) {
      if (
        props.deviationForm.safety_compromised ||
        props.deviationForm.safety_compromised !== undefined
      ) {
        setValue(props.deviationForm.safety_compromised);
      } else {
        setValue("none");
      }
      let field0 = props.deviationForm.safety_compromised;
      if (field0 === undefined) {
        if (props.setFieldError) {
          props.setFieldError(true);
        }
      } else {
        if (props.setFieldError) {
          props.setFieldError(false);
        }
      }
    }
  }, [props.open]);

  const [value, setValue] = useState(
    props.deviationForm ? props.deviationForm.safety_compromised : "none"
  );

  function handleYesClick() {
    if (!props.readOnly) {
      setValue(true);
      if (props.handleClick) {
        props.handleClick(true);
        props.setFieldError(false);
      }
    }
  }

  function handleNoClick() {
    if (!props.readOnly) {
      setValue(false);
      if (props.handleClick) {
        props.handleClick(false);
        props.setFieldError(false);
      }
    }
  }

  return (
    <Grid
      container
      spacing={3}
      style={{ margin: onDesktop ? '32px' : '0px', padding: onDesktop ? '32px' : '16px', maxWidth: '1200px'}}
    >
      <Grid item container xs={12} alignItems="center" justify="center">
        {" "}
        <Typography className={classes.header} style={{textAlign: 'center'}}>
          <span className={classes.header} style={{color: 'red', paddingRight: '4px'}}>*</span> Was Food Safety Compromised?
        </Typography>
      </Grid>

      <Grid item container xs={12} sm={6} justify={onDesktop ? "flex-end" : "center"}>
        {value && value !== "none" && (
          <Card
            className={classes.defaultcardselected}
            variant="outlined"
            onClick={handleYesClick}
          >
            <CardActions className={classes.cardactionswithcheck}>
              <Grid container>
                <Grid
                  item
                  container
                  justify="center"
                  xs={12}
                  className={classes.cardsymbol}
                >
                  <ReportIcon style={{ color: "red" }}></ReportIcon>
                </Grid>{" "}
                <Grid item container justify="center" xs={12}>
                  <Typography variant="h4">Yes</Typography>
                </Grid>
                <Grid item container justify="center" xs={12}>
                  <Typography style={{ color: "grey" }}>
                    (Food was compromised)
                  </Typography>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        )}

        {!value && value !== "none" && (
          <Card className={classes.defaultnocard} onClick={handleYesClick}>
            <CardActions className={classes.cardactionswithcheck}>
              <Grid container>
                <Grid
                  item
                  container
                  justify="center"
                  xs={12}
                  className={classes.cardsymbol}
                >
                  <ReportIcon style={{ color: "red" }}></ReportIcon>
                </Grid>{" "}
                <Grid item container justify="center" xs={12}>
                  <Typography variant="h4">Yes</Typography>
                </Grid>
                <Grid item container justify="center" xs={12}>
                  <Typography style={{ color: "grey" }}>
                    (Food was compromised)
                  </Typography>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        )}

        {value === "none" && (
          <Card className={classes.defaultnocard} onClick={handleYesClick}>
            <CardActions className={classes.cardactionswithcheck}>
              <Grid container>
                <Grid
                  item
                  container
                  justify="center"
                  xs={12}
                  className={classes.cardsymbol}
                >
                  <ReportIcon style={{ color: "red" }}></ReportIcon>
                </Grid>{" "}
                <Grid item container justify="center" xs={12}>
                  <Typography variant="h4">Yes</Typography>
                </Grid>
                <Grid item container justify="center" xs={12}>
                  <Typography style={{ color: "grey" }}>
                    (Food was compromised)
                  </Typography>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        )}
      </Grid>

      <Grid item container xs={12} sm={6} justify={onDesktop ? "flex-start" : "center"} onClick={handleNoClick}>
        {!value && value !== "none" && (
          <Card className={classes.defaultcardselected} variant="outlined">
            <CardActions className={classes.cardactionswithcheck}>
              <Grid container>
                <Grid
                  item
                  container
                  justify="center"
                  xs={12}
                  className={classes.cardsymbol}
                >
                  <CheckCircleOutlineIcon
                    style={{ color: "green" }}
                  ></CheckCircleOutlineIcon>
                </Grid>{" "}
                <Grid item container justify="center" xs={12}>
                  <Typography variant="h4">No</Typography>
                </Grid>
                <Grid item container justify="center" xs={12}>
                  <Typography style={{ color: "grey", textAlign: 'center'}}>
                    (Food was not compromised)
                  </Typography>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        )}
        {value && value !== "none" && (
          <Card className={classes.defaultnocard} onClick={handleNoClick}>
            <CardActions className={classes.cardactionswithcheck}>
              <Grid container>
                <Grid
                  item
                  container
                  justify="center"
                  xs={12}
                  className={classes.cardsymbol}
                >
                  <CheckCircleOutlineIcon
                    style={{ color: "green" }}
                  ></CheckCircleOutlineIcon>
                </Grid>{" "}
                <Grid item container justify="center" xs={12}>
                  <Typography variant="h4">No</Typography>
                </Grid>
                <Grid item container justify="center" xs={12}>
                  <Typography style={{ color: "grey", textAlign: 'center' }}>
                    (Food was not compromised)
                  </Typography>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        )}
        {value === "none" && (
          <Card className={classes.defaultnocard} onClick={handleNoClick}>
            <CardActions className={classes.cardactionswithcheck}>
              <Grid container>
                <Grid
                  item
                  container
                  justify="center"
                  xs={12}
                  className={classes.cardsymbol}
                >
                  <CheckCircleOutlineIcon
                    style={{ color: "green" }}
                  ></CheckCircleOutlineIcon>
                </Grid>{" "}
                <Grid item container justify="center" xs={12}>
                  <Typography variant="h4">No</Typography>
                </Grid>
                <Grid item container justify="center" xs={12}>
                  <Typography style={{ color: "grey" }}>
                    (Food was not compromised)
                  </Typography>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        )}
      </Grid>
      
    </Grid>
  );
}

export default Step1;
