import React, { useEffect, useState } from "react";

import MaterialTable from "material-table";
import API from "../../Api/Api";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import FormulaSKUForm from "./FormulaSKUForm";
import DialogContent from "@material-ui/core/DialogContent";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Traceability from "../Traceability";
import Message from "../../Components/Message";
import Slide from '@material-ui/core/Slide';
import PropTypes from "prop-types";
import {StylesContext} from "../../App";

const EMPTY_MESSAGE = {
  open: false,
  message: '',
  status: 'info',
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SKUList(props) {
  const classes = React.useContext(StylesContext);

  const [loading, setLoading] = useState(true);
  const [editingModalOpen, setEditingModalOpen] = useState(false);
  const [shouldClose, setShouldClose] = useState(true);
  const [formulationSelected, setFormulationSelected] = useState(-1);
  const [message, setMessage] = useState( {...EMPTY_MESSAGE} );
  const [user, setUser] = useState(null);
  const [units, setUnits] = useState(false);
  const [suppliers, setSuppliers] = useState(false);
  const [formulations, setFormulations] = useState(false);
  const [ingredients, setIngredients] = useState(false);
  const [formulationToDisplay, setFormulationToDisplay] = useState(false);
  const [skuToDisplay, setSkuToDisplay] = useState(false);
  const [dummyKey, setDummyKey] = useState(1);
  //const [packagings, setPackagings] = useState(false);

  useEffect(() => {
    if(loading) {
      return;
    }

    if(formulationSelected >= 0) {
      setFormulationToDisplay(formulations[formulationSelected]);
      // setSkuToDisplay(formulations[formulationSelected].sku);
    }
    else {
      setFormulationToDisplay({});
      // setSkuToDisplay({});
    }

  }, [formulationSelected])

  useEffect(() => {
    if(loading) {
      return;
    }

    setEditingModalOpen(true);
  }, [formulationToDisplay])

  //This is used to select the newly made record upon creation.
  useEffect(() => {
    if(editingModalOpen && formulationSelected < 0) {
      setFormulationSelected(formulations.length - 1);
    }
  }, [formulations])

  useEffect(() => {
    const api = new Traceability().getSkuApi();
    const supplierApi = new Traceability().getSupplierAPI();
    const unitApi = new Traceability().getUnitsAPI();
    const authApi = new API().getAuthAPI();
    const formulaApi = new API().getFormulationsAPI();
    const ingredientApi = new API().getIngredientsAPI();

    if(props.user) {
      setUser(props.user)
    }
    else {
      authApi.getAuthedProfile().then(e => {
        setUser(e.data);
      }).catch(e => {
        //console.log(e);
      })
    }

    if(props.formulations) {
      setFormulations(props.formulations)
    }
    else {
      formulaApi.getFormulationIngredients().then(e => {
        setFormulations(e.data);
      }).catch(e => {
        //console.log(e);
      })
    }

    if(props.units) {
      setUnits(props.units);
    }
    else {
      unitApi.listGlobalUnits().then(e => {
        setUnits(e.data);
      }).catch(e => {
        //console.log(e);
      })
    }

    if(props.suppliers) {
      setSuppliers(props.suppliers);
    }
    else {
      supplierApi.listSuppliers().then(e => {
        setSuppliers(e.data);
      }).catch(e => {
        //console.log(e);
      })
    }

    if(props.ingredients) {
      setIngredients(props.ingredients)
    }
    else {
      ingredientApi.listIngredients().then(e => {
        setIngredients(e.data);
      }).catch(e => {
        //console.log(e);
      })
    }

    setLoading(false);
  }, []);


  const formulationColumns = [
    { title: "Name", field: "name" },
    { title: "Description", field: "description" },
  ];

  function addFormulation() {
    //it needs to be different to register, so just flip it back and forth.
    setFormulationSelected(formulationSelected == -1 ? -2 : -1);
  }

  function handleSkuClosed() {
    if (shouldClose) {
      setEditingModalOpen(false);
    } else {
      alert("Save changes before closing.")
    }
  }

  function closeModal() {
    if(!shouldClose) {
      if(!window.confirm("Are you sure you want to close without saving?")) {
        return;
      }
    }

    cancel();
  }

  function cancel() {
    setShouldClose(true);
    setEditingModalOpen(false);
  }

  /**
   * Handles keeping the list up to date with the back end.
   * 
   * @param {JSON} response - a response object from the server
   */
  function onSave(response) {
    //const unitApi = new Traceability().getUnitsAPI();
    //utils.pkIndexObjectsFromApi(unitApi.listGlobalUnits.bind(unitApi), setUnits);

    let updatedFormPk = response.data.pk;
    let updatedForms = [...formulations];

    let formIndex = updatedForms.findIndex(form => {
      return form.pk === updatedFormPk;
    });

    const formulaApi = new API().getFormulationsAPI();
    formulaApi.retrieveFormulationIngredients(updatedFormPk).then(response => {
      if (formIndex > -1) {
        updatedForms.splice(formIndex, 1, response.data);
      }
      else {
        updatedForms.push(response.data);
        //setFormulationSelected(updatedForms.length - 1);
      }
  
      setFormulations(updatedForms);
      setMessage({
        open: true,
        message: 'Saved Successfully',
        status: 'success',
      });

      //helps force updates for nested objects that won't be noticed.
      setDummyKey(dummyKey + 1);
    }).catch(error => {
      //console.log('Error: ' + error.message);
      setMessage({
        open: true,
        message: 'Could not sync with server. Please refresh page.',
        status: 'error',
      });
    })

    //handleSkuUnits(response.data);
  }

  function deleteSku(form, resolve, reject) {
    const api = new API().getFormulationsAPI();

    api.deleteFormulation(form.pk).then(response => {
      let updatedForms = [...formulations];
      let formIndex = updatedForms.findIndex(updatedForm => {
        return updatedForm.pk === form.pk;
      });

      if (formIndex > -1) {
        updatedForms.splice(formIndex, 1);
      }

      setMessage({
        open: true,
        message: 'Deleted Successfully',
        status: 'success',
      });

      setFormulations(updatedForms);
      resolve();
    }).catch(error => {
      //console.log(error);
      reject();
    })
  }

  return (
    <>
      { (loading || !user || !formulations || !ingredients || !units) &&
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginTop: "250px" }}>
            <Typography>
              <CircularProgress />
            </Typography>
          </Grid>
        </Grid>
      }

      { (!loading && user && formulations && ingredients && units) && <>
        <Paper elevation={0} square variant="outlined" className={classes.generalListPaperContainer} >
          <Grid container spacing={3}>
            <Grid item xs={12}>

              <MaterialTable
                components={{
                  Container: props => <Paper {...props} elevation={0} />
                }}

                isLoading={loading} 
                title="Formulations" 
                columns={formulationColumns} 
                data={formulations}

                style={{ width: "100%" }}
                localization={{ body: { editRow: { deleteText: 'Are you sure you want to delete this process? This action cannot be reversed.' } } }}

                editable={{
                  onRowDelete: (newData) => new Promise((resolve, reject) => {
                    deleteSku(newData, resolve, reject);
                  })
                }}

                actions={
                  [
                    {
                      icon: (props) => (
                        <Button size="small" color="primary" variant="contained" aria-label="add">
                          New Formulation 
                        </Button>
                      ),
                      tooltip: 'Add Ingredient',
                      isFreeAction: true,
                      onClick: (event) => addFormulation()
                    },
                  ]
                }

                onRowClick={(event, rowData, togglePanel) => {
                  if(formulationSelected != rowData.tableData.id) {
                    setFormulationSelected(rowData.tableData.id);
                  }
                  else {
                    setEditingModalOpen(true);
                  }
                }}

                options={{
                  actionsColumnIndex: -1,
                  pageSize: 10,
                }}

                icons={{
                  Export: MoreVertIcon,
                }}
              />

            </Grid>
          </Grid>
        </Paper>

        <Dialog fullScreen open={editingModalOpen} onClose={handleSkuClosed} TransitionComponent={Transition}>
          <DialogContent className={classes.generalFormDialogueContainer}>
            <FormulaSKUForm
              key={dummyKey}
              onSave={onSave}
              formulation={formulationToDisplay}
              sku={skuToDisplay}
              setShouldClose={setShouldClose}
              closeModal={closeModal}
              cancel={cancel}
              user={user}
              units={units}
              ingredients={ingredients}
              activeTraceability={props.activeTraceability}
              setIsBlocking={() => {}}
              suppliers={suppliers}
            />
          </DialogContent>
        </Dialog>

        <Message
          open={message.open}
          message={message.message}
          severity={message.status}
          vertical="bottom"
          horizontal="right"
          handleClose={() => {setMessage( {...message, open:false} )}}
        />
      </>}
    </>
  )
}

SKUList.propTypes = {
  inventoryLocations: PropTypes.arrayOf(PropTypes.object),
  activeTraceability: PropTypes.object.isRequired,
}