import React, { useState, useEffect } from 'react';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Traceability from "../Traceability";
import Message from "../../Components/Message";
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import NoEncryptionOutlinedIcon from '@material-ui/icons/NoEncryptionOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';
import TraceabilityUtils from "../TraceabilityUtils";
import ShippingForm from "./ShippingForm";
import FulfillForm from './FulfillForm';
import PropTypes from 'prop-types';
import { StylesContext } from "../../App";


export default function ShippingStatusManager(props) {
  const utils = new TraceabilityUtils();
  const classes = React.useContext(StylesContext);


  const [message, setMessage] = useState(utils.emptyMessage());
  const [viewingStatus, setViewingStatus] = useState(props.shipping.status);
  const [shippingToShow, setShippingToShow] = useState(props.shipping);
  const [dummyKey, setDummyKey] = useState(0);
  const [pkToUse, setPkToUse] = useState(false);
  const [submittingStatus, setSubmittingStatus] = useState(false);

  useEffect(() => {
    setShippingToShow(props.shipping);
    setViewingStatus(props.shipping.status);

  }, [props.shipping])

  useEffect(() => {
    if (!props.shipping.previous_states) {
      return;
    }

    let prevStates = JSON.parse('{' + props.shipping.previous_states.substring(0, props.shipping.previous_states.length - 1) + '}');

    if (props.shipping.status == viewingStatus) {
      setShippingToShow(props.shipping);
    }
    else if (prevStates[viewingStatus]) {
      setShippingToShow(prevStates[viewingStatus])
    }
    else {
      setShippingToShow({ new_shipment_products: [] })
    }

    setDummyKey(dummyKey + 1);

  }, [viewingStatus])

  function handleStatusClick(status, acceptedStatuses) {
    //Try shipping status first to prevent issues arising from multiple statuses being tied to a single button.
    if (acceptedStatuses.indexOf(props.shipping.status) != -1) {
      setViewingStatus(props.shipping.status)
    }
    else {
      setViewingStatus(status)
    }
  }

  function getClassStyle(acceptedStatuses, recordStatus, viewingStatus) {
    if (acceptedStatuses.indexOf(recordStatus) != -1 && acceptedStatuses.indexOf(viewingStatus) != -1) {
      return classes.statusBothSelected;
    }
    else if (acceptedStatuses.indexOf(recordStatus) != -1) {
      return classes.statusRecordSelected;
    }
    else if (acceptedStatuses.indexOf(viewingStatus) != -1) {
      return classes.statusViewingSelected;
    }
    else {
      return classes.statusUnselected;
    }
  }

  function getButtonIcon(acceptedStatuses, recordStatus, viewingStatus) {
    if (acceptedStatuses.indexOf(recordStatus) != -1 && acceptedStatuses.indexOf(viewingStatus) != -1) {
      return (<LockOutlinedIcon style={{ float: 'center' }} />);
    }
    else if (acceptedStatuses.indexOf(recordStatus) != -1) {
      return (<LockOpenOutlinedIcon style={{ float: 'center' }} />);
    }
    else if (acceptedStatuses.indexOf(viewingStatus) != -1) {
      return (<NoEncryptionOutlinedIcon style={{ float: 'center' }} />);
    }
    else {
      return (<AccessAlarmsIcon style={{ float: 'center' }} />);
    }
  }

  function getStatusPercent(currentStep, stage) {
    const steps = {
      'Draft': 1,
      'READ': 2,
      'Picking': 2,
      'AWAI': 3,
      'CANC': 4,
      'Shipped': 4,
    };

    if (steps[currentStep] < stage) {
      return 0;
    }
    else if (steps[currentStep] > stage) {
      return 100;
    }
    else {
      return 50;
    }
  }

  return (
    <>
      <Paper elevation={0} className={classes.generalFormPaperHeader}>
        {/* This grid acts has header to both forms. */}
        <Grid container spacing={3}>

          <Grid container item justify='center' alignItems='center' spacing={3} className={classes.generalContainerGridHead}>
            <Grid container item justify='center' alignItems='center' spacing={3} style={{ width: '50%' }}>

              <Grid item >
                <Typography align='center' noWrap className={classes.statusText}>
                  {'Create Shipment'}
                </Typography>
                <IconButton
                  variant="outlined"
                  className={getClassStyle(['Draft'], props.shipping.status, viewingStatus)}
                  onClick={() => { handleStatusClick('Draft', ['Draft']) }}
                  disabled={shippingToShow.status === 'Picking'}
                >
                  <div className={classes.statusRoot}>
                    {getButtonIcon(['Draft'], props.shipping.status, viewingStatus)}
                  </div>
                </IconButton>
              </Grid>
              <Grid item xs={1} className={classes.statusLine}>
                <LinearProgress variant="determinate" value={getStatusPercent(viewingStatus, 1)} />
              </Grid>

              <Grid item >
                <Typography align='center' noWrap className={classes.statusText}>
                  {'Pick Shipment'}
                </Typography>
                <IconButton
                  variant="outlined"
                  align='center'
                  className={getClassStyle(['READ', 'Picking'], props.shipping.status, viewingStatus)}
                  onClick={() => { handleStatusClick('Picking', ['READ', 'Picking']) }}
                  disabled={shippingToShow.status === 'Draft'}
                >
                  <div className={classes.statusRoot}>
                    {getButtonIcon(['READ', 'Picking'], props.shipping.status, viewingStatus)}
                  </div>
                </IconButton>
              </Grid>
              <Grid item xs={1} className={classes.statusLine}>
                <LinearProgress variant="determinate" value={getStatusPercent(viewingStatus, 2)} />
              </Grid>

              {/* <Grid item >
                <Typography align='center' noWrap className={classes.statusText}>
                  {'Confirm Shipment'}
                </Typography>
                <IconButton 
                  variant="outlined" 
                  className={getClassStyle(['AWAI'], props.shipping.status, viewingStatus)}
                  onClick={ () => {handleStatusClick('AWAI', ['AWAI'])} }
                >
                  <div className={classes.statusRoot}>
                    {getButtonIcon(['AWAI'], props.shipping.status, viewingStatus)}
                  </div>
                </IconButton>
              </Grid>
              <Grid item xs={1} className={classes.statusLine}>
                <LinearProgress variant="determinate" value={getStatusPercent(viewingStatus, 3)} />
              </Grid> */}

              <Grid item >
                <Typography align='center' noWrap className={classes.statusText}>
                  {'Complete'}
                </Typography>
                <IconButton
                  variant="outlined"
                  className={getClassStyle(['CANC', 'Shipped'], props.shipping.status, viewingStatus)}
                  onClick={() => { handleStatusClick('Shipped', ['CANC', 'Shipped']) }}
                  disabled={shippingToShow.status === 'Draft' || shippingToShow.status === 'Picking'}
                >
                  <div className={classes.statusRoot}>
                    {getButtonIcon(['CANC', 'Shipped'], props.shipping.status, viewingStatus)}
                  </div>
                </IconButton>
              </Grid>
            </Grid>

            <Grid item style={{ width: '100%', height: '0px', float: 'right', position: 'relative', bottom: '110px' }}>
              <IconButton style={{ float: 'right' }} onClick={props.closeModal}>
                <CancelIcon style={{ float: 'right' }} />
              </IconButton>
            </Grid>

          </Grid>
        </Grid>
      </Paper>

      {/* Each form has its own body and footer. */}
      {(viewingStatus == 'Draft' || viewingStatus == 'CANC') &&
        <Grid item xs={12}>
          <ShippingForm
            shipping={shippingToShow}
            disabled={(viewingStatus != props.shipping.status) || viewingStatus == 'Shipped'}
            setShouldClose={props.setShouldClose}
            user={props.user}
            onSave={props.onSave}
            closeModal={props.closeModal}
            cancel={props.cancel}
            finishInventory={props.finishInventory}
            customers={props.customers}
            customerAddressArr={props.customerAddressArr}
            units={props.units}
          />
        </Grid>
      }

      {(viewingStatus == 'READ' || viewingStatus == 'Picking' || viewingStatus == 'AWAI' || viewingStatus == 'Shipped') &&
        <>
          {/* {viewingStatus == 'READ' &&
            <Grid item container xs={12} style={{backgroundColor: '#18BFF6', position: "relative", width: '100%', height: 50, zIndex: 2}}>
              <Grid item container xs={6} justify="flex-end" alignItems="center">
                <Typography style={{color: 'white', fontSize: '16px', fontStyle: 'normal', fontWeight: 500, fontSize: '16px', lineHeight: '24px'}}>
                  This order is ready to be fulfilled.
                </Typography>
              </Grid>
              <Grid item container xs={6} justify="flex-start" alignItems="center">
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginLeft: '8px', color: 'white', border: '1px solid white', display: 'block' }}
                  onClick={() => { handleStatusSubmit(pkToUse ? pkToUse : props.shipping.pk) }}
                  disabled={submittingStatus}
                >
                  Start Picking
                </Button>
              </Grid>
            </Grid> */
          }
          <Grid item xs={12}>
            <FulfillForm
              shipping={shippingToShow}
              disabled={viewingStatus != props.shipping.status || viewingStatus == 'Shipped'}
              setShouldClose={props.setShouldClose}
              user={props.user}
              onSave={props.onSave}
              closeModal={props.closeModal}
              cancel={props.cancel}
              customers={props.customers}
              customerAddressArr={props.customerAddressArr}
              finishInventory={props.finishInventory}
              units={props.units}
            />
          </Grid>
        </>
      }

      {props.shipping.status == 'OUTS' &&
        <Button
          variant="contained"
          color="secondary"
          style={{ margin: "8px" }}
          onClick={props.closeModal}
        >
          Cancel
        </Button>
      }

      <Message
        open={message.open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="left"
        handleClose={() => { setMessage({ ...message, open: false }) }}
      />
      {/*</Paper>*/}
    </>
  )
}

ShippingStatusManager.propTypes = {
  setShouldClose: PropTypes.func,
  setReturnMessage: PropTypes.func,
  onSave: PropTypes.func,
  shipping: PropTypes.object,
  closeModal: PropTypes.func,
  customers: PropTypes.objectOf(PropTypes.object),
  units: PropTypes.objectOf(PropTypes.object),
  finishInventory: PropTypes.objectOf(PropTypes.object),
  cancel: PropTypes.func,
  user: PropTypes.object.isRequired,
}