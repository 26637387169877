import React from 'react';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import {StylesContext} from "../../App";

export default function ItemRow(props) {
  const classes = React.useContext(StylesContext);

  //Used to maintain correct spacing for header.
  function makeEmptyActions() {
    if(!props.actions) {
      return;
    }
    return props.actions.map((action, i) => {
      return (
        <Grid key={i} item xs={1} style={{"opacity": 0}}>
          <Button
            key={i}
            variant="contained"
            color="primary"
            style={{ margin: "8px" }}
            //onClick={ () => {action.func(props.lineData, props.setLineData)} }
            disabled
          >
            {action.title}
          </Button>
        </Grid>
      )
    })
  }

  function makeHeader() {
    return props.lineInfo.map((fieldObj, i) => {
      return (
        <Grid key={i} item xs>
          <Typography className={classes.itemTableHeader}>
            {fieldObj.title}
          </Typography>
        </Grid>
      )
    })
  }
  
  return (
    <>
      {props.lineInfo &&
        <Paper elevation={0} square style={{ "backgroundColor": props.disabled ? 'grey' : '#FFF', height: '48px' }}
          className={classes.itemTableHeaderRow}
        >
          <Grid container spacing={3}>
            {makeHeader()}
            {makeEmptyActions()}
          </Grid>
        </Paper>
      }
    </>
  )

}

ItemRow.propTypes = {
  lineData: PropTypes.object,
  setLineData: PropTypes.func,
  lineInfo: PropTypes.object,
  disabled: PropTypes.bool,
  ineditable: PropTypes.bool,
  actions: PropTypes.array,
  selected: PropTypes.bool,
  setLineSelected: PropTypes.func,
  index: PropTypes.number,
}