class FIngredientsAPI {
    constructor(api, version) {
      this.api = api;
      this.version = version;
    }
    
    listFIngredients(category) {
      if (typeof category === 'string') {
        return this.api.get(`${this.version}/f_ingredients/${category}/`);
      }
   
      return this.api.get(`${this.version}/f_ingredients/`)
    }
    
    createFIngredients(payload) {
      return this.api.post(`${this.version}/f_ingredients/`, payload);
    }
    
    updateFIngredients(payload) {
      return this.api.put(`${this.version}/f_ingredients/${payload.id}/`, payload);
    }
    
    deleteFIngredients(id) {
      return this.api.delete(`${this.version}/f_ingredients/${id}/`);
    }
    
  }
    
  export default FIngredientsAPI;