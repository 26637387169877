import React, { useState, useEffect } from "react";
import API from "Api/Api";
import UpdateIcon from '@material-ui/icons/Update';
import useStatusMessage from "./useStatusMessage";

//const HOW_OFTEN_TO_CHECK_VERSION = 5 * 60 * 1000 // 5 minutes 
const HOW_OFTEN_TO_CHECK_VERSION = 1000 // 5 minutes 

export default function useClientUpdater() {
	/**
	 * Hook that pings an app version endpoint every HOW_OFTEN_TO_CHECK_VERSION ms, if the returned version is different 
	 * than the client's stored version, return a status popover component to let the user click refresh to install.  
	 */
	const api = new API();
	const [foundBuild, setFoundBuild] = useState(null);

	const [setUpdateAvailableMessage, updateMessageComponent] = useStatusMessage(null, [
    {icon: <UpdateIcon/>, task: acceptUpdate}
  ]);

	//useEffect(() => {
	//	getAppVersion();
	//	const interval = setInterval(getAppVersion, HOW_OFTEN_TO_CHECK_VERSION);		
	//	return () => clearInterval(interval);
	//}, []);

	function getAppVersion() {
		api.getAppVersion().then(response => {
		let build = localStorage.getItem('build');
		setFoundBuild(response.data);
		if (build !== response.data) {
			setUpdateAvailableMessage({label: "A new update is available. Click here to install.", severity: 'info'});
		}
		}).catch(error => {
			console.log(error);
		});
	}

	function acceptUpdate() {
		localStorage.setItem("build", foundBuild);
		window.location.reload(true);
	}

  return updateMessageComponent;
}