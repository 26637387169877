class AdminToolAPI {

  constructor(api, version, name) {
    this.api = api;
    this.version = version;
    this.name = name;
  }

  checkAdmin() {
    return this.api.get(`${this.version}/users/is_admin/`)
  }

  adminInviteUser(data){
    return this.api.post(`${this.version}/admin_tools/admin_invite/`, data)
  }

  adminInviteListing(email){
    if (email){
      return this.api.get(`${this.version}/admin_tools/admin_invite_list/?email=`+email)
    }
    else {
      return this.api.get(`${this.version}/admin_tools/admin_invite_list/`)
    }
  }

  adminInviteCancel(pk){
    return this.api.post(`${this.version}/admin_tools/`+pk+`/admin_invite_cancel/`)
  }

  adminInviteResend(pk){
    return this.api.post(`${this.version}/admin_tools/`+pk+`/admin_invite_resend/`)
  }

}
export default AdminToolAPI;

