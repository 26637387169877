import React, {useEffect, useState} from "react";

import {useParams, useHistory, useRouteMatch} from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {IconButton} from "@material-ui/core";

export const BackButton = (props) => {
  let history = useHistory();

  return (
    <IconButton onClick={history.goBack}>
      <ArrowBackIcon style={props.style}/>
    </IconButton>
  );
};
