import React from 'react';
import {
  Grid,
  Button,
  Card,
  CardHeader,
  CardMedia,
  CardActions,
} from '@material-ui/core';

class ImageCard extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      imageURL: props.url,
      imageName: props.name,
      primaryKey: props.pk,
      cardActions: props.actions,
      allowDelete: props.allowDelete,
      api: props.api,
      display: props.display === false ? props.display : true, //this is done to get around falsy issues.
      cardStyle: props.cardStyle
    }
  }

  /**
   * Deletes an image from the backend server using the primary key
   * stored in this component. 
   * On success, sets the state to not display itself.
   * On Failure, gives the user an alert and the status text from the response.
   */
  deleteImage() {
    if(this.props.deleted){
      this.props.deleted();
      this.setState({
        display: false,
      })
    }
  }

  /**
   * Maps the functions passed as a prop to buttons on the image card.
   */
  makeButtons() {
    if(!this.state.cardActions) {
      return null;
    }

    return (this.state.cardActions).map((cardAction) => {
      return (
        <Button size="small" color="primary" onClick={
          () => cardAction.action(
            this.state.imageURL,
            this.state.imageName,
            this.state.primaryKey,
          )
        }>
          {cardAction.label}
        </Button>
      );
    });
  }

  /**
   * Returns either the component to display or null based on if the state
   * is set to display.
   */
  getDisplay() {
    if(this.state.display) {
      return(
        <Grid item style={this.state.cardStyle}>
            <Card>
              <CardHeader 
                title={this.state.imageName}
                titleTypographyProps={{style:{'font-size':'14px','overflowX':'hidden', 'width': '82%'}, noWrap:true}}
              />
              <CardMedia
              style={{
                height: 0,
                paddingTop: '56.25%',
              }}
              image={this.state.imageURL}
              title={this.state.imageName}
              />
              <CardActions>
                {this.makeButtons()}
                {this.state.allowDelete ? 
                  (<Button size="small" color="primary" onClick={() => {this.deleteImage()}}>
                    Delete
                  </Button>) : 
                  null
                }
                {/* todo add these as props for uploader<Button size="small" color="primary" onClick={() => {this.downloadImage()}}>
                  View
                </Button>
                <Button size="small" color="primary" onClick={() => {this.deleteImage()}}>
                  Delete
                </Button>*/}
              </CardActions>
            </Card>
        </Grid>
      )
    }
    else {
      return null;
    }
  }

  render(){
    return(
      this.getDisplay()
    )
  }
}

export default ImageCard;