import React, { useState, useEffect, useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ExtLink from "@material-ui/core/Link";

import API from "../../Api/Api";
import Grid from "@material-ui/core/Grid";

import { useHistory, useLocation, Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

import FoodbyteLogo from "../../logos/FB_Logo_Reversed_RGB_Icon.png";

import PropTypes from 'prop-types';


function Copyright() {
    return (
        <Grid container spacing={3} alignItems="center" justify="center">
            <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © FoodByte '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
            <Grid container item xs={12} spacing={1} alignItems="center" justify="center">
                <Grid item>
                    <ExtLink variant="subtitle2" color="inherit" style={{ fontSize: 11, opacity: 0.8 }} target="_blank" href="https://www.joinfoodbyte.com/privacy-policy">
                        Privacy Policy
                    </ExtLink>
                </Grid>
                <Grid item>
                    <ExtLink variant="subtitle2" color="inherit" style={{ fontSize: 11, opacity: 0.8 }} target="_blank" href="https://www.joinfoodbyte.com/terms-services">
                        Terms of Service
                    </ExtLink>
                </Grid>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        paddingTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logo: {
        borderRadius: "5%",
        boxShadow: "0px 2.5px 5px grey",
        margin: "16px"
    },
}));

function SignIn(props) {
    const classes = useStyles();
    const next_url = localStorage.getItem("next_url");
    const [email, setEmail] = useState(props.email ? props.email : "");
    const [password, setPassword] = useState(props.password ? props.password : "");

    const history = useHistory();
    const location = useLocation();

    const { from } = location.state || { from: { pathname: "/haccp_plans" } };
    const [isLoading, setIsLoading] = useState(props.isLoading);

    const [passwordError, setPasswordError] = useState(props.passwordError);
    const [passwordErrorText, setPasswordErrorText] = useState(props.passwordErrorText);

    const [emailError, setEmailError] = useState(props.emailError);
    const [emailErrorText, setEmailErrorText] = useState(props.emailErrorText);

    const [errors, setErrors] = useState(props.errors);
    const [loading, setLoading] = useState(true);

    const api = new API();

    useEffect(() => {
        setLoading(false);
    }, []);

    function handleEmailInput(event) {
        setEmail(event.target.value);
    }

    function handlePasswordInput(event) {
        setPassword(event.target.value);
    }

    function handleSubmit(event) {
        setIsLoading(true);
        setErrors([]);
        event.preventDefault();

        api.getAuthAPI().login({ 'username': email?.toLowerCase(), 'password': password }).then(response => {

            // localStorage.setItem("access_token", response.data.access);
            // localStorage.setItem("refresh_token", response.data.refresh);
            // history.replace("/my_tasks/monitoring");

            history.replace("/verify-otp/" + email?.toLowerCase());

        }).catch(error => {
            setIsLoading(false);
            if (!error.response) {
                setErrors(['Something went wrong. Please try again']);
                return
            }
            if (error?.response?.data?.detail) {
                setErrors([error.response?.data.detail]);
            } else {
                setErrors(['Something went wrong. Please try again']);
                return
            }


            // setErrors(error.response.data.non_field_errors)
        });
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Grid container direction="column" alignItems="center" justify="center">

                <div className={classes.paper}>

                    <img
                        className={classes.logo}
                        src={FoodbyteLogo}
                        width="64"
                        height="64"
                    />
                    {loading ? <CircularProgress /> :
                        <form className={classes.form} noValidate>
                            <TextField
                                error={emailError}
                                helperText={emailErrorText}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={handleEmailInput}
                            />
                            <TextField
                                error={passwordError}
                                helperText={passwordErrorText}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={handlePasswordInput}
                            />
                            <Link to="/forgot-password" style={{ textDecoration: 'none', color: 'blue', opacity: 0.8 }}>Forgot Password</Link>

                            {isLoading &&
                                <div style={{ textAlign: 'center', margin: '4px' }}>
                                    <CircularProgress />
                                </div>
                            }
                            {errors &&
                                <div>
                                    {errors.map((error, index) => {
                                        return (
                                            <div key={index} style={{ marginTop:'10px',color: 'red', textAlign: 'left' }}>
                                    <Typography>
                                        {error}
                                    </Typography>
                                </div>
                                        )
                                    })}
                        </div>
                    }

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSubmit}
                    >
                        Sign In
                    </Button>
                    {/*<Grid container>*/}
                    {/*<Grid item xs>*/}
                    {/*    <Link href="#" variant="body2">*/}
                    {/*        Forgot password?*/}
                    {/*    </Link>*/}
                    {/*</Grid>*/}
                    {/*<Grid item>*/}
                    {/*    <Link href="#" variant="body2">*/}
                    {/*        {"Don't have an account? Sign Up"}*/}
                    {/*    </Link>*/}
                    {/*</Grid>*/}
                    {/*</Grid>*/}
                </form>
                    }
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Grid>
        </Container >
    );
}

SignIn.propTypes = {
    email: PropTypes.string,
    password: PropTypes.string,
    isLoading: PropTypes.bool,
    passwordError: PropTypes.bool,
    passwordErrorText: PropTypes.string,
    emailError: PropTypes.bool,
    emailErrorText: PropTypes.string,
    errors: PropTypes.array,
}

export default SignIn;
