import React, { useEffect, useState } from 'react';
import DetailTable from '../DetailTable/DetailTable';
import PropTypes from "prop-types";

export default function IngredientTable(props) {
  //const [productionData, setProductionData] = useState(false);
  //const [formulation, setFormulation] = useState(false);
  //const [isBlocking, setIsBlocking] = useState(false);
  //const [message, setMessage] = useState( {...EMPTY_MESSAGE} );
  //const [submitAttempted, setSubmitAttempted] = useState(false);

  //todo rename this one to batch mode, the other to not
  const [colInfo, setColInfo] = useState([
    { key: 'name', type: 'display', title: 'Ingredient', },
    { key: 'target', title: 'Quantity Required', type: 'display' },
    { key: 'picked_quantity', title: 'Quantity Picked', type: 'display' },
    { key: 'picked_lot_code', title: 'Lot Code', type: 'display' },
    ...(props.disabled
      ? [] // If disabled, don't include the Action column
      : [{ key: 'action', title: 'Action' }]),
    //{key: 'skus', type: 'detail', title: 'Skus'},
    {
      key: 'skus',
      cacheKey: 'internal_lot_codes',
      type: 'detailAuto',
      choiceKey: 'sku',
      noOptionsText: 'No Internal Lot Codes for this Ingredient.',
      detailKey: 'pk',
      title: 'Internal Lot Codes',
      choices: props.internalLotCodes,
      choiceLabelKey: 'code',
      errorMessage: 'That lot code does not match a SKU for this ingredient.',
      //validate: props.validateLotCodes,
      hidden: false,
    },
  ]);

  //detailAuto validates based on cacheKey
  const [colInfoAutoDetail, setColInfoAutoDetail] = useState([
    { key: 'name', type: 'display', title: 'Ingredient', },
    //{key: 'total', type: 'display', title: 'Total Added', validate: props.validateTotal},
    { key: 'target', title: 'Quantity Required', type: 'display' },
    { key: 'picked_quantity', title: 'Quantity Picked', type: 'display' }, //todo comes from BE, now needs to be able to filter on FE :(
    { key: 'picked_lot_code', title: 'Lot Code', type: 'display' },
    ...(props.disabled
      ? [] // If disabled, don't include the Action column
      : [{ key: 'action', title: 'Action' }]),
    //{key: 'unit_type', title: 'Unit', type: 'select', choices: props.unitChoices, disabled: true},
    {
      key: 'skus',
      cacheKey: 'internal_lot_codes',
      type: 'detailAuto',
      choiceKey: 'sku',
      noOptionsText: 'No Internal Lot Codes for this Ingredient.',
      detailKey: 'pk',
      title: 'Internal Lot Codes',
      choices: props.internalLotCodes,
      choiceLabelKey: 'code',
      errorMessage: 'That lot code does not match a SKU for this ingredient.',
      //validate: props.validateLotCodes,
      hidden: true,
    },
  ]);

  const [detailInfo, setDetailInfo] = useState([
    { key: 'name', title: 'Name', type: 'display', disabled: true },
    { key: 'consolidatedUnits', title: 'Quantity', type: 'display', disabled: true },
    { key: 'consolidatedUnits', title: 'Quantity', type: 'numeric', disabled: true },
    //{key: 'lotCodesUsed', title: 'Lot Codes', type: 'multiselect', choices: props.internalLotCodes, disabled: true},
  ]);

  const [detailInfoNoLots, setDetailInfoNoLots] = useState([
    { key: 'name', title: 'Name', type: 'display', disabled: true },
    //{key: 'consolidatedUnits', title: 'Quantity', type: 'display', disabled: true},
  ]);

  //todo is it worth the effort to force the new format into this design or create a new one??
  //todone no, not really. Making a new table.

  return (
    <DetailTable
      tableData={props.ingredients}
      // setData={props.setIngredients}
      setManOrderData={props.setManOrderData}
      manOrderData={props.manOrderData}
      colInfo={props.batchMode ? colInfo : colInfoAutoDetail}
      detailInfo={props.batchMode ? detailInfo : detailInfoNoLots}
      onDetailClick={props.onDetailClick}
      disabled={props.disabled}
      faded={props.faded}
      units={props.units}
    />
  )
}

IngredientTable.propTypes = {
  ingredients: PropTypes.arrayOf(PropTypes.object),
  internalLotCodes: PropTypes.object,
  setIngredients: PropTypes.func,
  onDetailClick: PropTypes.func,
  batches: PropTypes.number,
  unitChoices: PropTypes.object,
  disabled: PropTypes.bool,
  batchMode: PropTypes.bool,
  units: PropTypes.objectOf(PropTypes.object),
  validateTotal: PropTypes.func,
  validateAmount: PropTypes.func,
  validateLotCodes: PropTypes.func,
  faded: PropTypes.bool,
}