import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TraceabilityUtils from "../TraceabilityUtils";
import AutocompleteSD from "../../Components/AutocompleteSD";
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  cell: {
    border: '1px solid black',
    padding: '5px',
  },
  select: {
    width: '100%',
  },
}));

const utils = new TraceabilityUtils();


//todo this can probably be completely removed at some point if we don't go back to the old table.
export default function AddSkuModal(props) {
  const classes = useStyles();

  const [selectedSkus, setSelectedSkus] = useState([]);
  const [skuSelected, setSkuSelected] = useState(null);
  const [unitSelected, setUnitSelected] = useState(null);
  const [unitAmount, setUnitAmount] = useState(0);
  const [selectedLotCodes, setSelectedLotCodes] = useState([]);
  const [dummyKey, setDummyKey] = useState(1);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    if(props.lineData) {
      setSkuSelected(props.lineData);
      setUnitSelected(props.lineData.unit_type);
      setUnitAmount(props.lineData.unit_amount);
      setSelectedLotCodes(props.lineData.lotCodesUsed);
    }

  }, [])

  function formatData(skus) {
    let selectedIndices = [];
    return skus.map((sku, i) => {

      if (sku.detailSelected) {
        selectedIndices.push(i);
      }

      return { ...sku, unit_type: props.units[sku.unit_type].abbreviation }
    })
  }

  function getSelectedSkus(skus) {
    let selectedSkus = [];

    skus.forEach((sku, i) => {

      if (sku.detailSelected) {
        selectedSkus.push(i);
      }
    })

    return selectedSkus;
  }

  function handleSkuChange(newValue) {
    setSkuSelected({ ...newValue });
    //props.setSkus(newValue);
  }

  function handleSubmit() {
      /*
        rowData.internal_lot_codes = ingredientRow.internal_lot_codes.map((lotCodeObj) => { return lotCodeObj.pk });

        rowData.production_sku_lines = ingredientRow.skus.reduce((result, skuLine) => {
          if (skuLine.detailSelected) {
            let skuLineData = {};
            skuLineData.sku = skuLine.pk;
            skuLineData.unit_value = skuLine.unit_amount;
            skuLineData.unit_type = skuLine.unit_type;

            result.push(skuLineData);
          }
            return result;
          }, []);
      */

      let sku = props.rowData.skus.find((sku => sku.pk == skuSelected.pk));

      sku.detailSelected = true;
      sku.unit_amount = unitAmount;
      sku.unit_type = unitSelected;
      sku.lotCodesUsed = selectedLotCodes;
      sku.consolidatedUnits = unitAmount + ' ' + skuSelected.unit_choices[unitSelected]

      props.rowData.internal_lot_codes = [...selectedLotCodes]

      props.setRowData({...props.rowData, internal_lot_codes: selectedLotCodes});
      //props.setLineData({detailSelected: true})

      props.closeModal();
  }

  return (
    <Paper elevation={0}>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" noWrap>
            {'Ingredient: ' + props.ingredient.name}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" noWrap>
            {'Qty (Current/Called For): ' + props.rowData.target}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AutocompleteSD
            value={skuSelected}
            id="sku"
            //disabled
            optionType={"rawObjects"}
            options={props.rowData.skus}
            getOptionLabel={(option) => option ? option.name : 'No Selection'}
            onChange={(emptyEvent, newValue) => { handleSkuChange(newValue) }}
            renderInput={(params) => <TextField {...params} label="SKU" variant="outlined" />}
          // error={submitAttempted && order.supplier && order.customer}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="unit_value"
            label="Amount"
            variant="outlined"
            value={unitAmount}
            onChange={(event) => { setUnitAmount(event.target.value) }}
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteSD
            key={dummyKey}
            value={unitSelected}
            id="target_unit_type"
            //className={classes.generalFormTextField}
            disabled={props.disabled}
            optionType={'pkIndexingToObjects'}
            options={(skuSelected) ? Object.keys(skuSelected.unit_choices) : []}
            choices={skuSelected ? skuSelected.unit_choices : []}
            getOptionLabel={(option) => (option && skuSelected.unit_choices[option]) ? skuSelected.unit_choices[option] : 'No Name'}
            onChange={(emptyEvent, newValue) => { setUnitSelected(newValue) }}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
          />
          {/*<FormControl variant="outlined" style={{ "width": "100%" }}>
            <InputLabel htmlFor="age-native-helper">Unit Type</InputLabel>
            <Select
              native
              label="Unit Type"
              style={{ "width": "100%" }}
              value={unitSelected}
              onChange={(event) => { setUnitSelected(event.target.value) }}
              inputProps={{
                name: 'unit_type',
                id: 'unit_type',
              }}
            >
              <option aria-label="None" value="" />

              {skuSelected && Object.keys(skuSelected.unit_choices).map((unitKey) => {
                return <option key={unitKey} value={unitKey}> {skuSelected.unit_choices[unitKey]} </option>
              })}

            </Select>
          </FormControl>*/}
        </Grid>

        <Grid item xs={12}>
        <FormControl className={classes.formControl}>
            <InputLabel id="demo-mutiple-chip-label">Lot Codes</InputLabel>
            <Select
              labelId="lotCodes"
              id="lotCodes"
              name="lotCodes"
              multiple
              className={classes.select}
              fullWidth
              value={selectedLotCodes}
              onChange={(event) => { setSelectedLotCodes(event.target.value) }}
              //error={props.colInfo.validate ? props.colInfo.validate(props.rowData[props.colInfo.key]) : false}
              //disabled={props.disabled || props.colInfo.disabled}
              input={<Input id="select-multiple-chip" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={props.internalLotCodes.find(lotCode => lotCode.pk == value).code} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {skuSelected && props.internalLotCodes.filter(lotCode => lotCode.sku == skuSelected.pk).map((lotCodeObj) => (
                <MenuItem key={lotCodeObj.pk} value={lotCodeObj.pk}>
                  {lotCodeObj.code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Button
        variant="contained"
        color="primary"
        style={{ margin: "12px" }}
        onClick={handleSubmit}
      >
        Submit
      </Button>
      <Button
        variant="contained"
        color="secondary"
        style={{ margin: "12px" }}
        onClick={() => { props.closeModal() }}
      >
        Close
      </Button>
    </Paper>
  )
}