import React, { useEffect, useState } from "react";

import MaterialTable from "material-table";
import API from "../../Api/Api";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import SKUForm from "./SKUForm";
import DialogContent from "@material-ui/core/DialogContent";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Traceability from "../Traceability";
import TraceabilityUtils from "../TraceabilityUtils";
import Message from "../../Components/Message";
import Slide from '@material-ui/core/Slide';
import PropTypes from "prop-types";

const utils = new TraceabilityUtils();

const EMPTY_MESSAGE = {
  open: false,
  message: '',
  status: 'info',
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SKUList(props) {
  const [skus, setSkus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [skuModalOpen, setSkuModalOpen] = useState(false);
  const [shouldClose, setShouldClose] = useState(true);
  const [skuSelected, setSkuSelected] = useState(-1);
  const [message, setMessage] = useState( {...EMPTY_MESSAGE} );
  const [user, setUser] = useState(null);
  const [units, setUnits] = useState(false);
  const [formulations, setFormulations] = useState(false);
  const [ingredients, setIngredients] = useState(false);
  //const [packagings, setPackagings] = useState(false);

  useEffect(() => {
    if(loading) {
      return;
    }

    setSkuModalOpen(true);
  }, [skuSelected])

  useEffect(() => {
    const api = new Traceability().getSkuApi();
    const unitApi = new Traceability().getUnitsAPI();
    const authApi = new API().getAuthAPI();
    const formulaApi = new API().getFormulationsAPI();
    const ingredientApi = new API().getIngredientsAPI();
    //const packagingApi = new API().getPackagingAPI();

    if(props.user) {
      setUser(props.user)
    }
    else {
      authApi.getAuthedProfile().then(e => {
        setUser(e.data);
      }).catch(e => {
        //console.log(e);
      })
    }

    if(props.skus) {
      let skuObjects = props.skus;
      setSkus(skuObjects);
      if(props.units) {
        setUnits(props.units);
      }
    }
    else {
      api.listSkus().then(e => {
        let skuObjects = e.data;
        setSkus(skuObjects);

        if(props.units) {
          setUnits(props.units);
        }
        else {
          utils.pkIndexObjectsFromApi(unitApi.listGlobalUnits.bind(unitApi), setUnits);
        }
      }).catch(e => {
        //console.log(e);
      })
    }

    if(props.formulations) {
      setFormulations(props.formulations)
    }
    else {
      utils.pkIndexObjectsFromApi(formulaApi.listFormulations.bind(formulaApi), setFormulations);
    }

    if(props.ingredients) {
      setIngredients(props.ingredients)
    }
    else {
      utils.pkIndexObjectsFromApi(ingredientApi.listIngredients.bind(ingredientApi), setIngredients);
    }

    /*if(props.packagings) {
      setPackagings(props.packagings)
    }
    else {
      utils.pkIndexObjectsFromApi(packagingApi.listPackagings.bind(packagingApi), setPackagings);
    }*/

    setLoading(false);

  }, []);

  useEffect(() => {
    if(!units || !skus) {
      return;
    }

    let newSkus = [];

    skus.forEach((sku) => {
      newSkus.push(handleSkuUnits(sku))
    })

    setSkus(newSkus);

  }, [units])

  /**
   * This consolidates the units to one field so that the user doesn't need to worry 
   * about multiple fields and their validation.
   * 
   * @param {JSON} skuObject - A Json representation of the sku
   */
  function handleSkuUnits(skuObject) {
    if(!skuObject.unit_value && !skuObject.unit_type) {
      skuObject.units = '';
      return skuObject;
    }

    skuObject.units = parseFloat(skuObject.unit_value).toFixed(2) + ' ' + units[skuObject.unit_type].abbreviation;
    return skuObject;
  }

  const skuColumns = [
    { title: "Name", field: "name" },
    { title: "Code", field: "code" },
    { title: "Description", field: "description" },
    { title: "Amount", field: "units"},
    { title: "Made In House", field: "made_in_house", type: "boolean"},
  ];

  function addSku() {
    //it needs to be different to register, so just flip it back and forth.
    setSkuSelected(skuSelected == -1 ? -2 : -1);
  }

  function handleSkuClosed() {
    if (shouldClose) {
      setSkuModalOpen(false);
    } else {
      alert("Save changes before closing.")
    }
  }

  function closeModal() {
    if(!shouldClose) {
      if(!window.confirm("Are you sure you want to close without saving?")) {
        return;
      }
    }

    cancel();
  }

  function cancel() {
    setShouldClose(true);
    setSkuModalOpen(false);
  }

  /**
   * Handles keeping the list up to date with the back end, includingupdating all skus.
   * 
   * @param {JSON} response - a response object from the server
   */
  function onSave(response) {
    const unitApi = new Traceability().getUnitsAPI();
    utils.pkIndexObjectsFromApi(unitApi.listGlobalUnits.bind(unitApi), setUnits);

    let updatedSkuPk = response.data.pk;
    let updatedSkus = [...skus];

    let skuIndex = updatedSkus.findIndex(sku => {
      return sku.pk === updatedSkuPk;
    });

    handleSkuUnits(response.data);

    if (skuIndex > -1) {
      updatedSkus.splice(skuIndex, 1, response.data);
    }
    else {
      updatedSkus.push(response.data);
    }

    setSkus(updatedSkus);
    setMessage({
      open: true,
      message: 'Saved Successfully',
      status: 'success',
    });
  }

  function deleteSku(sku, resolve, reject) {
    const api = new Traceability().getSkuApi();

    api.deleteSku(sku.pk).then(response => {
      let updatedSkus = [...skus];
      let skuIndex = updatedSkus.findIndex(updatedSku => {
        return updatedSku.pk === sku.pk;
      });

      if (skuIndex > -1) {
        updatedSkus.splice(skuIndex, 1);
      }

      setMessage({
        open: true,
        message: 'Deleted Successfully',
        status: 'success',
      });

      setSkus(updatedSkus);
      resolve();
    }).catch(error => {
      //console.log(error);
      reject();
    })
  }

  return (
    <>
      { (loading || !skus || !user || !formulations || !ingredients || !units) &&
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginTop: "250px" }}>
            <Typography>
              <CircularProgress />
            </Typography>
          </Grid>
        </Grid>
      }

      { (!loading && skus && user && formulations && ingredients && units) && <>
        <Paper elevation={0} variant="outlined" style={{margin: "16px", padding: "16px", width: '100%'}} square>
          <Grid container spacing={3}>
            <Grid item xs={12}>

              <MaterialTable
                components={{
                  Container: props => <Paper {...props} elevation={0} />
                }}

                isLoading={loading} 
                title="SKUs" 
                columns={skuColumns} 
                data={skus}

                style={{ width: "100%" }}
                localization={{ body: { editRow: { deleteText: 'Are you sure you want to delete this process? This action cannot be reversed.' } } }}

                editable={{
                  onRowDelete: (newData) => new Promise((resolve, reject) => {
                    deleteSku(newData, resolve, reject);
                  })
                }}

                actions={
                  [
                    {
                      icon: (props) => (
                        <Button size="small" color="primary" variant="contained" aria-label="add">
                          Add SKU
                        </Button>
                      ),
                      tooltip: 'Add Ingredient',
                      isFreeAction: true,
                      onClick: (event) => addSku()
                    },
                  ]
                }

                onRowClick={(event, rowData, togglePanel) => {
                  if(skuSelected != rowData.tableData.id) {
                    setSkuSelected(rowData.tableData.id);
                  }
                  else {
                    setSkuModalOpen(true);
                  }
                }}

                options={{
                  actionsColumnIndex: -1,
                  //exportButton: true,
                  pageSize: 10,
                }}

                icons={{
                  Export: MoreVertIcon,
                }}
              />

            </Grid>
          </Grid>
        </Paper>

        <Dialog fullScreen open={skuModalOpen} onClose={handleSkuClosed} TransitionComponent={Transition}>
          <DialogContent>
            <SKUForm 
              onSave={onSave}
              sku={skuSelected >= 0 ? skus[skuSelected] : {}}
              setShouldClose={setShouldClose}
              closeModal={closeModal}
              cancel={cancel}
              user={user}
              units={units}
              formulations={formulations}
              ingredients={ingredients}
              //packagings={packagings}
              activeTraceability={props.activeTraceability}
            />
          </DialogContent>
        </Dialog>

        <Message
          open={message.open}
          message={message.message}
          severity={message.status}
          vertical="bottom"
          horizontal="right"
          handleClose={() => {setMessage( {...message, open:false} )}}
        />
      </>}
    </>
  )
}

SKUList.propTypes = {
  inventoryLocations: PropTypes.arrayOf(PropTypes.object),
  activeTraceability: PropTypes.object.isRequired,
}
