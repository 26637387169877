import React, { useContext, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import { createMuiTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ContentHeader from "../../Components/Layouts/ContentHeader";
import Message from "../../Components/Message";
import Process from "./Process";

import { StylesContext } from "../../App";
import { OrgContext } from "hooks/useOrganization"

import useDesktop from "../../hooks/useDesktop";
import API from "../../Api/Api";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function NewProcessList(props) {
  const classes = React.useContext(StylesContext);
  const [processes, setProcesses] = useState(null);
  const [hazards, setHazards] = useState(null);
  const [loading, setLoading] = useState(true);
  const [processModalOpen, setProcessModalOpen] = useState(false);
  const [newProcessModalOpen, setNewProcessModalOpen] = useState(false);
  const [shouldClose, setShouldClose] = useState(false);
  const onDesktop = useDesktop();


  const api = new API();

  const org = useContext(OrgContext);
  let history = useHistory();

  const theme = createMuiTheme({
    overrides: {
      MuiToolBar: {
        h6: {
          fontSize: 14,
        },
      },
      MuiTableCell: {
        root: {
          padding: 14,
        },
      },
      MuiIconButton: {
        root: {
          "&.Mui-disabled": {
            color: "transparent",
          },
        },
      },
    },
  });
  let allHazards = [];
  useEffect(() => {
    getProcesses();
  }, [props.haccpPlanPk]);

  function EditProcess(process) {
    setProcessSelected(process.pk);
    setProcessModalOpen(true);
  }

  function getProcesses() {
    let haccpPlanPk = props.haccpPlanPk;
    if (haccpPlanPk) {
      api.getProcessAPI()
        .listProcesses(haccpPlanPk)
        .then((response) => {
          setProcesses(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
      api.getHazardAPI()
        .listHazards(haccpPlanPk)
        .then(response => {
          setHazards(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  const [user, setUser] = useState(null);
  useEffect(() => {
    api.getAuthAPI()
      .getAuthedProfile()
      .then((e) => {
        setUser(e.data);
        //setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    setLoading(hazards == null && processes == null);
  }, [hazards, processes]);

  const ingredient_columns = [
    { title: "Name", field: "name" },
    { title: "Description", field: "description" },
    // {title: "Last Modified", field: "last_modified", editable: 'never'},
  ];

  let match = useRouteMatch();

  const [processSelected, setProcessSelected] = useState(null);
  const [processToDelete, setProcessToDelete] = useState(null);
  const [deleteWarningOpen, setDeleteWarningOpen] = React.useState(false);

  useEffect(() => {
    if (processSelected == null) return;
    if (deleteWarningOpen) return;
    setProcessModalOpen(true);
  }, [processSelected, deleteWarningOpen]);

  function addNewProcess() {
    setProcessSelected(null);
    setNewProcessModalOpen(true);
  }

  function deleteProcess(process) {
    setWarningOpen(processToDelete);
  }

  function saveAndContinue() {
    history.push(`${match.url}/process_flow`);
  }

  function processSave(process) {
    setShouldClose(false);
    api.getProcessAPI()
      .updateProcess(process)
      .then((response) => {
        getProcesses();

        setReturnStatus("Process Saved!", "info");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function processCreate(process) {
    setShouldClose(false);
    api.getProcessAPI()
      .createProcess(process)
      .then((response) => {
        // setProcesses(response.data);
        getProcesses();
        setNewProcessModalOpen(false);
        setReturnStatus("Process Created!", "info");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function processSaveAndContinue(process) {
    return api
      .getProcessAPI()
      .listProcesses(props.haccpPlanPk)
      .then((response) => {
        setProcessSelected(null);
        setNewProcessModalOpen(false);
        setProcessModalOpen(false);
        getProcesses();
        setReturnStatus("Process Saved!", "info");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const [Open, setOpen2] = useState(true);

  function cancel() {
    setOpen2(false);
    setProcessModalOpen(false);
    setNewProcessModalOpen(false);
  }

  useEffect(() => {
    setProcessSelected(null);
    setOpen2(true);
  }, [Open]);

  useEffect(() => {
    if (!newProcessModalOpen || !processModalOpen) {
      api.getProcessAPI()
        .listProcessesMinimizedJoins(props.haccpPlanPk)
        .then((e) => {
          setProcesses(e.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [newProcessModalOpen, processModalOpen]);

  function handleProcessClosed() {
    if (!shouldClose) {
      setProcessModalOpen(false);
    } else {
      alert("Save changes before closing.");
    }
  }

  function processClicked(pk) {
    setProcessSelected(pk);
    setProcessModalOpen(true);
  }

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState({
    message: "",
    status: "info",
  }); // error, warning, info, info

  function setReturnStatus(message, status) {
    setMessage({ message: message, status: status });
    setOpen(true);
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const [processName, setProcessName] = React.useState("");

  function setWarningOpen(process) {
    setDeleteWarningOpen(true);
    setProcessName(process.name);
    setProcessSelected(process.pk);
  }

  function setWarningClosed() {
    setDeleteWarningOpen(false);
    setAnchorEl(null);
    setProcessSelected(null);
  }
  const [deleteHazardWarningOpen, setDeleteHazardWarningOpen] = React.useState(false);

  function deleteWarnedProcess() {
    setAnchorEl(null);
    setProcessSelected(null);
    api.getProcessAPI()
      .deleteProcess(processToDelete.pk)
      .then(() => {
        let updatedProcesses = [...processes];
        let processIndex = updatedProcesses.findIndex((updatedProcess) => {
          return updatedProcess.pk === process;
        });

        if (processIndex > -1) {
          updatedProcesses.splice(processIndex, 1);
        }
        setProcessToDelete(null);
        setReturnStatus("Process Deleted!", "info");
        setProcesses(updatedProcesses);
        getProcesses();
      })
      .catch((error) => {
        console.log(error);
      });
    setProcessSelected(null);
    setDeleteWarningOpen(false);
  }

  function setHazardWarningOpen(process, hazard) {
    setDeleteHazardWarningOpen(true);
    setProcessToRemove(process);
    setHazardToRemove(hazard);
  }

  function setHazardWarningClosed() {
    setDeleteHazardWarningOpen(false);
    setHazardToRemove(null);
    setProcessToRemove(null);
  }

  function removeHazardFromProcess(process, hazard) {
    api.getProcessAPI()
      .removeHazardFromProcess(process.pk, hazard)
      .then((response) => {
        getProcesses();
        setReturnStatus("Unlinked Hazard!", "info");
      })
      .catch((error) => {
        console.log(error);
      });
    setDeleteHazardWarningOpen(false);
    setHazardToRemove(null);
    setHazardToRemove(null);
  }

  const handleClick = (event, process) => {
    setAnchorEl(event.currentTarget);
    setProcessToDelete(process);
  };

  function hideHazards() {
    setHazardsHidden(!hazardsHidden);
  }

  const [modalOpen, setModalOpen] = useState(false);
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleModalClosed();
  };
  const [hazardToRemove, setHazardToRemove] = useState(null);
  const [processToRemove, setProcessToRemove] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hazardsHidden, setHazardsHidden] = useState(false);
  const menuOpen = Boolean(anchorEl);

  function handleModalClosed() {
    setModalOpen(false);
  }
  return (
    <div style={{ width: "100%" }}>
      <Dialog
        open={deleteWarningOpen}
        onClose={setWarningClosed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete this Process?"}</DialogTitle>
        <DialogContent>
          {/* <Process
            handleClose={handleProcessClosed}
            cancel={cancel}
            setShouldClose={setShouldClose}
            pk={processSelected}
            deleteMode={true}
            open={open}
          /> */}

          <Typography
            style={{
              fontFamily: "Roboto",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "21px",
              color: "#687895",
              marginBottom: "0px",
            }}
          >
            Are you sure you want to delete this Process?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={setWarningClosed} color="primary">
            No
          </Button>
          <Button onClick={deleteWarnedProcess} color="primary" autoFocus>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteHazardWarningOpen}
        onClose={setHazardWarningClosed}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Unlink this?"}</DialogTitle>
        <DialogContent>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "21px",
              color: "#687895",
              marginBottom: "0px",
            }}
          >
            Are you sure you want to un-link this Hazard?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={setHazardWarningClosed} color="primary">
            No
          </Button>
          <Button
            onClick={() => removeHazardFromProcess(processToRemove, hazardToRemove)}
            color="primary"
            autoFocus
          >
            Yes, Remove
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={processModalOpen}
        onClose={handleProcessClosed}
        maxWidth={"md"}
        fullWidth
        TransitionComponent={Transition}
        fullScreen={!onDesktop}
      >
        <DialogContent className={classes.generalFormDialogueContainer}>
          <Process
            setShouldClose={setShouldClose}
            pk={processSelected}
            haccpPlanPk={props.haccpPlanPk}
            cancel={cancel}
            save={processSave}
            deleteMode={false}
            saveAndContinue={processSaveAndContinue}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth={"md"}
        fullWidth
        open={newProcessModalOpen}
        onClose={handleProcessClosed}
        TransitionComponent={Transition}
        fullScreen={!onDesktop}
      >
        <DialogContent className={classes.generalFormDialogueContainer}>
          <Process
            setShouldClose={setShouldClose}
            pk={processSelected}
            cancel={cancel}
            save={processCreate}
            deleteMode={false}
            organization={org.pk}
            haccpPlanPk={props.haccpPlanPk}
            saveAndContinue={processSaveAndContinue}
          />
        </DialogContent>
      </Dialog>

      <ContentHeader title="Processes" />
      
      {loading &&
      <Grid container item spacing={2} alignItems="center" justify="center" style={{width: '100%'}}>
        <CircularProgress/>
      </Grid>
      }

      {!loading && 
      <Grid container spacing={2} className={classes.generalContainerGrid}>
        <Grid item xs={6} container style={{ alignItems: "center" }}>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "21px",
              color: "#687895",
              marginLeft: "28px",
              marginBottom: "0px",
            }}
          >
            Linked Processes
          </Typography>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "21px",
              color: "blue",
              marginLeft: "4px",
              marginBottom: "0px",
            }}
          >
            ({processes != null && processes.length})
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Grid container justify="flex-end" style={{ width: "auto", float: "right", alignItems: "center" }}>
            <Button
              variant="text"
              style={{ marginRight: "16px" }}
              onClick={hideHazards}
              disabled={props.readOnlyMode}
            >
              {!hazardsHidden && (
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "21px",
                    color: "#687895",
                    marginBottom: "0px",
                    opacity: 0.6,
                  }}
                >
                  Hide Hazards
                </Typography>
              )}
              {hazardsHidden && (
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "21px",
                    color: "#687895",
                    marginBottom: "0px",
                    opacity: 0.6,
                  }}
                >
                  Show Hazards
                </Typography>
              )}
            </Button>

            <IconButton
              disableRipple
              style={{
                backgroundColor: "transparent",
                float: "right",
                marginRight: "28px",
              }}
              disabled={props.readOnlyMode}
              onClick={addNewProcess}
            >
              <AddIcon></AddIcon>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#687895",
                  marginBottom: "0px",
                  float: "right",
                }}
              >
                Add Process Step
              </Typography>
            </IconButton>
          </Grid>
        </Grid>

        {processes && processes.map((process) => {
          return (
            <>
            <Grid
              container
              key={process.pk}
              style={{
                width: "100%",
                height: "auto",
                margin: "32px",
                marginTop: "16px",
                marginBottom: "8px",
              }}
            >
              <Paper
                key={process.pk}
                elevation={0}
                style={{
                  width: "100%",
                  height: "auto",
                  marginBottom: "0px",
                }}
              >
                <Grid container item style={{ margin: "12px", alignItems: "center" }}>
                  <Grid
                    item
                    xs={6}
                    container
                    style={{
                      width: "auto",
                      float: "left",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#687895",
                      }}
                    >
                      {process.name}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    md={5}
                    container
                    style={{
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="text"
                      style={{ marginRight: "16px" }}
                      onClick={() => EditProcess(process)}
                      disabled={props.readOnlyMode}
                    >
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "primary",
                          marginBottom: "0px",
                          opacity: 0.6,
                        }}
                      >
                        Edit Process
                      </Typography>
                    </Button>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    md={1}
                    container
                    style={{
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <IconButton
                      disableRipple
                      style={{
                        backgroundColor: "transparent",
                        float: "right",
                        marginRight: "24px",
                      }}
                      disabled={props.readOnlyMode}
                      onClick={(event) => handleClick(event, process)}
                    >
                      <MoreHorizIcon></MoreHorizIcon>
                    </IconButton>

                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={menuOpen}
                      // onChange={handleClose}
                      onClose={handleMenuClose}
                      PaperProps={{
                        style: {
                          maxHeight: 48 * 4.5,
                          width: 200,
                        },
                      }}
                    >
                      <MenuItem key={process} onClick={() => deleteProcess(process)}>
                        Delete
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {!hazardsHidden && 
            <Grid style={{width: '100%', margin: '8px 32px 8px 64px'}}>
              {hazards != null && hazards.map((hazard) => {
                if (hazard.processes.includes(process.pk)) {
                  return (
                    <Grid
                      container
                      key={hazard.pk}
                      style={{
                        width: "100%",
                        height: "auto",
                        margin: '8px 0px 8px 0px',
                      }}
                    >
                      {!hazardsHidden && (
                        <Paper
                          key={hazard.pk}
                          elevation={0}
                          style={{
                            width: "100%",
                            height: "auto",
                          }}
                        >
                          <Grid
                            container
                            item
                            style={{ margin: "12px", alignItems: "center" }}
                          >
                            <Grid
                              item
                              xs={6}
                              container
                              style={{
                                width: "auto",
                                float: "left",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                style={{
                                  fontFamily: "Roboto",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  lineHeight: "21px",
                                  color: "#687895",
                                  opacity: 0.6,
                                }}
                              >
                                {hazard.name}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              container
                              style={{
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}
                            >
                              <IconButton
                                disableRipple
                                style={{
                                  backgroundColor: "transparent",
                                  float: "right",
                                  marginRight: "24px",
                                }}
                                disabled={props.readOnlyMode}
                                onClick={() => setHazardWarningOpen(process, hazard)}
                              >
                                <HighlightOffIcon color="secondary"></HighlightOffIcon>
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Paper>
                      )}
                    </Grid>
                  );
                }
              })}
            </Grid>
            }
            </>
          );
        })}
      </Grid>
      }
      <Message
        position="65px"
        open={open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="right"
        handleClose={handleClose}
      />
    </div>
  );
}

export default NewProcessList;
