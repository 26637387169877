import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import Button from "@material-ui/core/Button";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import NativeSelect from '@material-ui/core/NativeSelect';
import { set, rgb } from 'd3';
import Traceability from "../Traceability";
import Message from "../../Components/Message";
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {StylesContext} from "../../App";

const SpoilageProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(CircularProgress);

/*const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    margin: 'auto',
    alignItems: 'center',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    margin: 'auto',
  },
  top: {
    position: 'absolute',
    left: 0,
    margin: 'auto',
  },
  text: {
    position: 'absolute',
    bottom: 12,
    left: '4px',
  },
}));*/

const EMPTY_MESSAGE = {
  open: false,
  message: '',
  status: 'info',
};

export default function InventoryLocationForm(props) {
  const classes = React.useContext(StylesContext);


  const [unit, setUnit] = useState(props.unit ? props.unit : {spoilage: 0});
  const [isBlocking, setIsBlocking] = useState(false);
  const [message, setMessage] = useState( {...EMPTY_MESSAGE} );
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [baseUnit, setBaseUnit] = useState();
  const [showSpoilage, setShowSpoilage] = useState(props.showSpoilage);

  useEffect(() => {
    if(unit.unit_type == 'WEI') {
      setBaseUnit(props.weightBase);
    }
    else if(unit.unit_type == 'VOL') {
      setBaseUnit(props.volumeBase);
    }
    else {
      setBaseUnit(null);
    }
    
  }, [unit.unit_type])

  function validateForm() {
    setSubmitAttempted(true);

    if(!unit.full_name) {
      setMessage({open: true, message: 'Please name your unit.', status: 'error'});
      return false;
    }

    if(!unit.abbreviation) {
      setMessage({open: true, message: 'Please give your unit an abbreviation.', status: 'error'});
      return false;
    }

    if(!unit.unit_type) {
      setMessage({open: true, message: 'Please set measurement type.', status: 'error'});
      return false;
    }

    if( !unit.ratio_to_base || !Number.isFinite(parseFloat(unit.ratio_to_base)) ) {
      setMessage({open: true, message: 'Please set how many ' + baseUnit + ' per this unit.', status: 'error'});
      return false;
    }

    return true;
  }

  function handleSubmit(saveAndContinue) {
    if(!validateForm()) {
      return;
    }
    let unitObject = {...unit}

    if(props.setShouldClose) {
      props.setShouldClose(true);
    }

    unitObject.organization = props.user.organization.pk;
    unitObject.traceability = props.activeTraceability.pk;

    //reset spoilage if they update the ratio
    if(unitObject.ratio_to_base != props.unit.ratio_to_base) {
      unitObject.spoilage = 0;
    }
    const api = new Traceability().getUnitsAPI();

    if(unitObject.pk) {
      api.updateUnit(unitObject).then(response => {
        setUnit(response.data);
        setSubmitAttempted(false);
        if(props.onSave) {
          props.onSave(response);
        }
        if(saveAndContinue) {
          handleSaveAndContinue();
        }
      }).catch(error => {
        setMessage({
          open: true,
          message: 'Save failed, please double check for spoiled units. ' + (error.response ? JSON.stringify(error.response.data) : ''),
          status: 'error',
        });
      });
    }
    else {
      api.createUnit(unitObject).then(response => {
        setUnit(response.data);
        setSubmitAttempted(false);
        if(props.onSave) {
          props.onSave(response);
        }
        if(saveAndContinue) {
          handleSaveAndContinue();
        }
      }).catch(error => {
        setMessage({
          open: true,
          message: 'Save failed, please double check for spoiled units. ',
          status: 'error',
        });
      });
    }
  }

  function handleSaveAndContinue() {
    setIsBlocking(false);

    if(props.setShouldClose) {
      props.setShouldClose(true);
    }
    if(props.closeModal) {
      props.closeModal();
    }
  }

  function handleTextFieldChange(event) {
    setIsBlocking(true);
    if(props.setShouldClose) {
      props.setShouldClose(false);
    }
    const property = event.target.name;
    const value = event.target.value;

    setUnit({ ...unit, [property]: value });
  }

  function cancel() {
    setUnit( props.unit ? props.unit : {} );

    setIsBlocking(true);
    if(props.setShouldClose) {
      props.setShouldClose(false);
    }
  }

  return (
    <form onSubmit={props.handleSubmit ? props.handleSubmit : handleSubmit}>
      <Paper elevation={0} style={{"margin": "8px", "padding": "16px"}}>
        <Grid container spacing={3} className={classes.generalContainerGridBody}>

          <Grid item xs={8}>
            <Typography variant="h5" noWrap>
              Unit Form
            </Typography>
            Define/Edit a Unit of Measure
          </Grid>
          {/*For now the customer won't have the ability to re-base their units, but if thats re-anabled 
            turn this back on.
          <Grid item xs={6}>
            <div className={classes.root}>
              <Typography variant="h6" noWrap>
                Spoilage
              </Typography>
              <CircularProgress
                variant="determinate"
                className={classes.bottom}
                size={40}
                thickness={4}
                {...props}
                value={100}
              />
              <SpoilageProgress 
                className={classes.top}
                style={{color: rgb( 
                  Math.min(unit.spoilage*230, 255), 
                  Math.max(255 - unit.spoilage*230, 0), 
                  Math.max(255 - unit.spoilage*160, 0)
                )}}
                variant="static"
                value={Math.min(100, unit.spoilage*100)} 
              />
              <Typography noWrap className={classes.text}>
                {unit.spoilage >= 10 ? parseFloat(unit.spoilage).toPrecision(3) : parseFloat(unit.spoilage).toFixed(2)}
              </Typography>
            </div>
          </Grid>*/}
          <Grid item xs={3}>
            <Tooltip title="The Base Unit is what the ratio field converts to." aria-label="base">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={unit.is_base || false}
                    onChange={() => { setUnit({ ...unit, is_base: !unit.is_base }) }}
                    name="is_base"
                    color="primary"
                    disabled={true}
                  />
                }
                label="Is Base Unit?"
              />
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <IconButton style={{ float: 'right' }} aria-label="add an alarm" onClick={props.closeModal ? props.closeModal : cancel}>
            <HighlightOffOutlinedIcon style={{ float: 'right' }} />
            </IconButton>
          </Grid>

          <Divider />

          <Grid item xs={6}>
            <TextField
              fullWidth
              error={submitAttempted && !unit.full_name}
              name="full_name"
              label="Unit Name"
              variant="outlined"
              value={unit.full_name || ""}
              onChange={handleTextFieldChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              error={submitAttempted && !unit.abbreviation}
              name="abbreviation"
              label="Unit Abbreviation"
              variant="outlined"
              value={unit.abbreviation || ""}
              onChange={handleTextFieldChange}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl variant="outlined" style={{"width": "100%"}} error={submitAttempted && !unit.unit_type}>
              <InputLabel>Measurement Type</InputLabel>
              <Select
                label="Measurement Type"
                variant="outlined"
                style={{"width": "100%"}}
                value={unit.unit_type || ""}
                onChange={handleTextFieldChange}
                inputProps={{
                  name: 'unit_type',
                  id: 'unit_type',
                }}
              >
                <MenuItem  value='VOL'> Volume </MenuItem >
                <MenuItem  value="WEI"> Weight </MenuItem >
                <MenuItem  value="QUA"> Quantity </MenuItem >
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              error={submitAttempted && ( !unit.ratio_to_base || !Number.isFinite(parseFloat(unit.ratio_to_base)) )}
              name="ratio_to_base"
              label={"Ratio to Base Unit (" + (baseUnit ? baseUnit : 'not set') + ')'}
              type="number"
              variant="outlined"
              value={unit.ratio_to_base || ""}
              onChange={handleTextFieldChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} className={classes.generalContainerGridFoot}>
          <Grid item container xs={12} alignItems="center" justify="flex-end">
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "8px" }}
              onClick={() => { handleSubmit(false) }}
            >
              Save Changes
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "8px" }}
              onClick={() => { handleSubmit(true) }}
            >
              Save And Continue
            </Button>
          </Grid>
        </Grid>

        <Message
          open={message.open}
          message={message.message}
          severity={message.status}
          vertical="bottom"
          horizontal="right"
          handleClose={() => { setMessage( {...message, open:false} ) }}
        />
      </Paper>
    </form>
  )
}

InventoryLocationForm.propTypes = {
  unit: PropTypes.object,
  handleSubmit: PropTypes.func,
  user: PropTypes.object.isRequired,
  activeTraceability: PropTypes.object.isRequired,
  setShouldClose: PropTypes.func,
  onSave: PropTypes.func,
  closeModal: PropTypes.func,
  cancel: PropTypes.func,
}