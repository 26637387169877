class ProductDescriptionTemplateAPI {

  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listProductDescriptions(haccp_plan_pk = null) {
    if (typeof haccp_plan_pk === 'number') { // Get product_description_templates in formulation
      return this.api.get(`${this.version}/product_description_templates/?haccp_plan_pk=${haccp_plan_pk}`)
    }

    return this.api.get(`${this.version}/product_description_templates/`)
  }

  createProductDescription(payload) {
    return this.api.post(`${this.version}/product_description_templates/`, payload);
  }

  retrieveProductDescription(pk) {
    return this.api.get(`${this.version}/product_description_templates/${pk}/`);
  }

  updateProductDescription(payload) {
    return this.api.put(`${this.version}/product_description_templates/${payload.pk}/`, payload);
  }

  deleteProductDescription(pk) {
    return this.api.delete(`${this.version}/product_description_templates/${pk}/`);
  }

  getHistory(pk) {
    return this.api.get(`${this.version}/product_description_templates/${pk}/get_history/`);
  }
}


export default ProductDescriptionTemplateAPI;
