import React, { useState, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";

import GreyTextField from "Components/GreyTextField";
import AddIcon from '@material-ui/icons/Add';

const MAX_LENGTH = 255;

function RadioButtonInputField({customField, setCustomField}) {
	const [value, setValue] = useState("");

	function addOption() {
		if (value.length >= MAX_LENGTH) {
			return;
		}

		if (value.length <= 0) {
			return;
		}

		// get latest id;
		let id = 0;
		if (customField.radio_options.length) {
			id = customField.radio_options[customField.radio_options.length - 1].id + 1;
		}

		setCustomField({
			...customField, 

			radio_options: [
				...customField.radio_options, 
				{id: id, value: value}
			]
		});

		setValue("");
	}

	function handleEnter(event) {
		if (event.key === 'Enter') {
			addOption();
    }
	}

	return (
			<Card elevation={0} style={{backgroundColor: '#F5F5F5'}}>
				<Grid container item xs={12} alignItems="center">
					<Grid item xs={9} alignItems="center">
						<GreyTextField 
							onKeyDown={handleEnter} 
							fullWidth 
							value={value} 
							placeholder="Add Option..." 
							onChange={(event) => {
								if (event.target.value.length >= MAX_LENGTH) return; 
								setValue(event.target.value);
							}}
						/>
					</Grid>
					<Grid item xs={3} container justify="flex-end" style={{backgroundColor: '#F5F5F5'}}>
						<IconButton onClick={addOption} style={{marginRight: '4px'}}>
							<AddIcon />	
						</IconButton>
					</Grid>
				</Grid>
			</Card>
		)
}

export default function RadioButtonOptionsEditor({customField, setCustomField}) {
	return (
		<RadioButtonInputField customField={customField} setCustomField={setCustomField} />
	)
}