import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Bar, Line, Pie } from "react-chartjs-2";
import "chartjs-plugin-zoom";
import { Dialog, DialogContent, Slide, Card } from "@material-ui/core";
import EventPreview from "./EventPreview";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import API from "../../Api/Api";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
    gridcontainer: {
        padding: "32px",
    },
    nestedcontainer: {
        padding: 0,
        paddingTop: 20,
    },
    griditem: {
        marginBottom: "18px",
    },
    outlinedbutton: {
        color: "#18bff6",
    },
    textfieldbackground: {
        background: "#fafafc",
    },
    pagetitle: { paddingLeft: "38px", alignSelf: "center" },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function EventGraphs(props) {
    const [data, setData] = useState({});
    const [pieData, setPieData] = useState({});
    const [eventRecords, setEventRecords] = useState([]);
    let api = new API();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.getMonitoringAPI()
            .listEventRecords()
            .then((response) => {
                setEventRecords(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        checkEventRecords();
    }, [eventRecords]);

    function checkEventRecords(status) {
        if (eventRecords) {
            eventRecords.forEach((event) => {
                let myDate = new Date(event.time_created);
                let month = myDate.getUTCMonth();
                let day = myDate.getUTCDay();
                let eventStatus = event.status;
                if (eventStatus === "COMPLETE") {
                    switch (month) {
                        case 1:
                            monthDataCompleted[1]++;
                            febuaryDays[day]++;
                            break;
                        case 0:
                            monthDataCompleted[0]++;
                            januaryDays[day]++;
                            break;
                        case 2:
                            monthDataCompleted[2]++;
                            marchDays[day]++;
                            break;
                        case 3:
                            monthDataCompleted[3]++;
                            aprilDays[day]++;
                            break;
                        case 4:
                            monthDataCompleted[4]++;
                            mayDays[day]++;
                            break;
                        case 5:
                            monthDataCompleted[5]++;
                            juneDays[day]++;
                            break;
                        case 6:
                            monthDataCompleted[6]++;
                            julyDays[day]++;
                            break;
                        case 7:
                            monthDataCompleted[7]++;
                            augustDays[day]++;
                            break;
                        case 8:
                            monthDataCompleted[8]++;
                            septemberDays[day]++;
                            break;
                        case 9:
                            monthDataCompleted[9]++;
                            octoberDays[day]++;
                            break;
                        case 10:
                            monthDataCompleted[10]++;
                            novemberDays[day]++;
                            break;
                        case 11:
                            monthDataCompleted[11]++;
                            decemberDays[day]++;
                            break;
                        default:
                    }
                } else if (eventStatus === "UPCOMING") {
                    switch (month) {
                        case 1:
                            monthDataFuture[1]++;
                            febuaryDays[day]++;
                            break;
                        case 0:
                            monthDataFuture[0]++;
                            januaryDays[day]++;
                            break;
                        case 2:
                            monthDataFuture[2]++;
                            marchDays[day]++;
                            break;
                        case 3:
                            monthDataFuture[3]++;
                            aprilDays[day]++;
                            break;
                        case 4:
                            monthDataFuture[4]++;
                            mayDays[day]++;
                            break;
                        case 5:
                            monthDataFuture[5]++;
                            juneDays[day]++;
                            break;
                        case 6:
                            monthDataFuture[6]++;
                            julyDays[day]++;
                            break;
                        case 7:
                            monthDataFuture[7]++;
                            augustDays[day]++;
                            break;
                        case 8:
                            monthDataFuture[8]++;
                            septemberDays[day]++;
                            break;
                        case 9:
                            monthDataFuture[9]++;
                            octoberDays[day]++;
                            break;
                        case 10:
                            monthDataFuture[10]++;
                            novemberDays[day]++;
                            break;
                        case 11:
                            monthDataFuture[11]++;
                            decemberDays[day]++;
                            break;
                        default:
                    }
                } else if (eventStatus === "DEVIATION" || eventStatus === "OVERDUE") {
                    switch (month) {
                        case 1:
                            monthDataDeviations[1]++;
                            febuaryDays[day]++;
                            break;
                        case 0:
                            monthDataDeviations[0]++;
                            januaryDays[day]++;
                            break;
                        case 2:
                            monthDataDeviations[2]++;
                            marchDays[day]++;
                            break;
                        case 3:
                            monthDataDeviations[3]++;
                            aprilDays[day]++;
                            break;
                        case 4:
                            monthDataDeviations[4]++;
                            mayDays[day]++;
                            break;
                        case 5:
                            monthDataDeviations[5]++;
                            juneDays[day]++;
                            break;
                        case 6:
                            monthDataDeviations[6]++;
                            julyDays[day]++;
                            break;
                        case 7:
                            monthDataDeviations[7]++;
                            augustDays[day]++;
                            break;
                        case 8:
                            monthDataDeviations[8]++;
                            septemberDays[day]++;
                            break;
                        case 9:
                            monthDataDeviations[9]++;
                            octoberDays[day]++;
                            break;
                        case 10:
                            monthDataDeviations[10]++;
                            novemberDays[day]++;
                            break;
                        case 11:
                            monthDataDeviations[11]++;
                            decemberDays[day]++;
                            break;
                        default:
                    }
                }
            });
            setData({
                labels: [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                ],
                datasets: [
                    {
                        label: "Completed",
                        backgroundColor: "#18bff6",
                        borderColor: "rgba(0,0,0,1)",
                        //   borderWidth: 1,
                        data: monthDataCompleted,
                    },

                    {
                        label: "Upcoming",
                        backgroundColor: "#BAECFD",
                        borderColor: "rgba(0,0,0,1)",
                        //   borderWidth: 1,
                        data: monthDataFuture,
                    },

                    // {
                    //   label: "Overdue",
                    //   backgroundColor: "#f72020",
                    //   borderColor: "rgba(0,0,0,1)",
                    //   //   borderWidth: 1,
                    //   data: monthDataOverdue,
                    // },
                    {
                        label: "Deviation",
                        backgroundColor: "#FF7070",
                        borderColor: "rgba(0,0,0,1)",
                        //  borderWidth: 1,
                        data: monthDataDeviations,
                    },
                ],
            });
            let totalMonthCompleted = 0;
            if (monthDataCompleted) {
                monthDataCompleted.forEach((month) => {
                    totalMonthCompleted = totalMonthCompleted + month;
                });
            }
            let totalMonthOverdue = 0;
            if (monthDataOverdue) {
                monthDataOverdue.forEach((month) => {
                    totalMonthOverdue = totalMonthOverdue + month;
                });
            }
            let totalMonthFuture = 0;
            if (monthDataFuture) {
                monthDataFuture.forEach((month) => {
                    totalMonthFuture = totalMonthFuture + month;
                });
            }

            let totalMonthDeviations = 0;
            if (monthDataDeviations) {
                monthDataDeviations.forEach((month) => {
                    totalMonthDeviations = totalMonthDeviations + month;
                });
            }
            setPieData({
                datasets: [
                    {
                        label: "Event Records",
                        backgroundColor: ["#18bff6", "#BAECFD", "#FF7070"],
                        hoverBackgroundColor: ["#501800", "#4B5000", "#003350"],
                        data: [
                            totalMonthCompleted,
                            totalMonthFuture,
                            // totalMonthOverdue,
                            totalMonthDeviations,
                        ],
                    },
                ],
                // labels: ["Completed", "Upcoming", "Overdue", "Deviation"],
                labels: ["Completed", "Upcoming", "Deviation"],
            });
        }
    }
    const [selectedMonth, setSelectedMonth] = useState(null);

    let monthDataCompleted = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let monthDataOverdue = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let monthDataFuture = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let monthDataDeviations = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let januaryDays = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let febuaryDays = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let marchDays = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let aprilDays = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let mayDays = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let juneDays = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let julyDays = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let augustDays = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let septemberDays = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let octoberDays = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let novemberDays = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let decemberDays = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    let dayIndex = -1;
    let completedEvents = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let overdueEvents = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let futureEvents = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let deviationEvents = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    function cancel() {
        setMonthTitle(null);
        setBackButton(false);
        setSelectedMonth(null);
        setEventToGoTo(null);
        checkEventRecords();
    }

    const [monthTitle, setMonthTitle] = useState(null);
    const [backButton, setBackButton] = useState(false);
    let eventCounter = 0;

    function sendDailyData(sentMonth, monthName) {
        let dataToSend = [];
        let monthEvents = [[], [], [], [], [], [], [], [], [], [], [], []];
        eventRecords.forEach((event) => {
            let myDate = new Date(event.time_created);
            let month = myDate.getUTCMonth();
            let day = myDate.getUTCDay();
            if (month === sentMonth) {
                switch (month) {
                    case 0:
                        monthDataCompleted[0]++;
                        monthEvents[0].push(event);
                        januaryDays[day]++;
                        dataToSend = januaryDays;
                        break;
                    case 1:
                        monthDataCompleted[1]++;
                        febuaryDays[day]++;
                        monthEvents[1].push(event);
                        dataToSend = febuaryDays;
                        break;
                    case 2:
                        monthDataCompleted[2]++;
                        monthEvents[2].push(event);
                        marchDays[day]++;
                        dataToSend = marchDays;
                        break;
                    case 3:
                        monthDataCompleted[3]++;
                        monthEvents[3].push(event);
                        aprilDays[day]++;
                        dataToSend = aprilDays;
                        break;
                    case 4:
                        monthDataCompleted[4]++;
                        monthEvents[4].push(event);
                        mayDays[day]++;
                        dataToSend = mayDays;
                        break;
                    case 5:
                        monthDataCompleted[5]++;
                        monthEvents[5].push(event);
                        juneDays[day]++;
                        dataToSend = juneDays;
                        break;
                    case 6:
                        monthDataCompleted[6]++;
                        monthEvents[6].push(event);
                        julyDays[day]++;
                        dataToSend = julyDays;
                        break;
                    case 7:
                        monthDataCompleted[7]++;
                        monthEvents[7].push(event);
                        augustDays[day]++;
                        dataToSend = augustDays;
                        break;
                    case 8:
                        monthDataCompleted[8]++;
                        monthEvents[8].push(event);
                        septemberDays[day]++;
                        dataToSend = septemberDays;
                        break;
                    case 9:
                        monthDataCompleted[9]++;
                        monthEvents[9].push(event);
                        octoberDays[day]++;
                        dataToSend = octoberDays;
                        break;
                    case 10:
                        monthDataCompleted[10]++;
                        monthEvents[10].push(event);
                        novemberDays[day]++;
                        dataToSend = novemberDays;
                        break;
                    case 11:
                        monthDataCompleted[11]++;
                        monthEvents[11].push(event);
                        decemberDays[day]++;
                        dataToSend = decemberDays;
                        break;
                    default:
                }
            }
        });

        let monthCiper = {
            January: 31,
            February: 28,
            March: 31,
            April: 30,
            May: 31,
            June: 30,
            July: 31,
            August: 31,
            September: 30,
            October: 31,
            November: 30,
            December: 31,
        };

        let myLabels = [];
        let numberOfDays = monthCiper[monthName];
        for (let index = 1; index <= numberOfDays; ++index) {
            myLabels.push(index.toString());
        }
        monthEvents.forEach((day) => {
            dayIndex++;
            if (day.length > 0) {
                day.forEach((event) => {
                    if (event.formatted_status === "Complete") {
                        completedEvents[dayIndex]++;
                    } else if (event.formatted_status === "Future" || event.formatted_status === "Upcoming") {
                        futureEvents[dayIndex]++;
                        // } else if (event.formatted_status === "Overdue") {
                        //   overdueEvents[dayIndex]++;
                    } else if (event.formatted_status === "Deviation" || event.formatted_status === "Overdue") {
                        deviationEvents[dayIndex]++;
                    }
                });
            }
        });
        setBackButton(true);
        setMonthTitle(monthName);
        setData({
            labels: myLabels,
            datasets: [
                {
                    label: "Completed",
                    backgroundColor: "#18bff6",
                    borderColor: "rgba(0,0,0,1)",
                    //   borderWidth: 1,
                    data: completedEvents,
                },
                {
                    label: "Upcoming",
                    backgroundColor: "#BAECFD",
                    borderColor: "rgba(0,0,0,1)",
                    //   borderWidth: 1,
                    data: futureEvents,
                },
                // {
                //   label: "Overdue",
                //   backgroundColor: "#f72020",
                //   borderColor: "rgba(0,0,0,1)",
                //   //   borderWidth: 1,
                //   data: overdueEvents,
                // },
                {
                    label: "Deviation",
                    backgroundColor: "#FF7070",
                    borderColor: "rgba(0,0,0,1)",
                    //   borderWidth: 1,
                    data: deviationEvents,
                },
            ],
        });
    }
    const [graphType, setGraphType] = useState("COMPLETE");

    const [eventToGoTo, setEventToGoTo] = useState(null);
    const [allEvents, setAllEvents] = useState([null]);
    function goToEventRecord(event) {
        setEventToGoTo(event);
    }
    function goToEventRecords(event) {
        setAllEvents(event);
    }

    return (
        <div style={{ width: "100%" }}>
            {eventToGoTo && (
                <Dialog open={eventToGoTo} fullScreen TransitionComponent={Transition}>
                    <DialogContent>
                        <EventPreview allEvents={allEvents} event={eventToGoTo} open={eventToGoTo} cancel={cancel} />
                    </DialogContent>
                </Dialog>
            )}
            <Grid container item xs={12} style={{ borderBottom: "1px solid lightgrey", height: "90px" }}>
                <Typography
                    style={{
                        position: "relative",
                        top: 46,
                        left: 32,
                        bottom: 20,
                        fontStyle: "Roboto",
                        fontWeight: 500,
                        fontSize: "18px",
                        lineHeight: "24px",
                        color: "#687895",
                    }}
                >
                    Event Graph
                </Typography>
            </Grid>{" "}
            <Grid container item xs={12} justify="center">
                <div
                    style={{
                        padding: "32px",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <Paper elevation={0} style={{ padding: "12px" }}>
                        {loading && (
                            <Grid
                                container
                                item
                                justify="center"
                                alignItems="center"
                                style={{ width: "100%", height: "100%" }}
                            >
                                <CircularProgress />
                            </Grid>
                        )}{" "}
                        {backButton && (
                            <Grid container spacing={3} style={{ padding: "12px" }}>
                                <Grid item xs={8}></Grid>
                                <Grid item container xs={4} alignItems="center" justify="flex-end">
                                    <IconButton aria-label="cancel" onClick={cancel} style={{ padding: 0 }}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )}
                        {graphType === "COMPLETE" && data && !loading && (
                            <Bar
                                data={data}
                                options={{
                                    onClick: function (evt, element) {
                                        if (!backButton) {
                                            if (element.length > 0) {
                                                var month = element[0]._index;
                                                var monthName = element[0]._model.label;
                                                setSelectedMonth(monthName);
                                                sendDailyData(month, monthName);
                                            }
                                        }
                                    },
                                    scales: {
                                        yAxes: [
                                            {
                                                ticks: {
                                                    suggestedMin: 0,
                                                    suggestedMax: 5,
                                                    stepSize: 1,
                                                },
                                            },
                                        ],
                                    },
                                    title: {
                                        display: true,
                                        text: "Event Records",
                                        fontSize: 14,
                                    },
                                    legend: {
                                        display: true,
                                        position: "bottom",
                                    },
                                    title: {
                                        display: true,
                                        text: monthTitle ? monthTitle : "Event Records",
                                    },
                                    zoom: {
                                        enabled: true,
                                        mode: "x",
                                    },
                                    pan: {
                                        enabled: true,
                                        mode: "x",
                                    },
                                }}
                            />
                        )}
                    </Paper>{" "}
                    {/* <Grid item xs={12} style={{ marginTop: "14px", marginLeft: "-14px" }}>
                    <IconButton
                        disableRipple
                        style={{
                            backgroundColor: "transparent",
                            float: "left",
                            marginRight: "28px",
                            color: "#18BFF6",
                            marginLeft: "8px",
                        }}
                        onClick={() => props.prevPage()}
                    >
                        <ArrowBackIcon></ArrowBackIcon>
                        <Typography
                            style={{
                                fontFamily: "Roboto",
                                fontWeight: "500",
                                fontSize: "14px",
                                lineHeight: "21px",
                                color: "#18BFF6",
                                marginBottom: "0px",
                                float: "right",
                                paddingLeft: "8px",
                            }}
                        >
                            Go To Monitoring Performance
                        </Typography>
                    </IconButton>
                </Grid> */}
                </div>{" "}
            </Grid>
            {/*<div style={{ padding: "32px", width: "50%", height: "40%" }}>
        <Paper elevation={0} style={{ padding: "12px" }}>
          <Pie
            data={pieData}
            options={{
              onClick: function (evt, element) {
                goToEventRecords(element);
              },
              title: {
                display: true,
                text: "Event Records",
              },
              legend: {
                display: true,
                position: "bottom",
              },
            }}
          />
        </Paper>
      </div>*/}
            {/* {eventRecords && (
          <Grid container spacing={2} style={{ padding: "12px" }}>
            <Grid item container xs={12}>
              <Typography variant="h6">Most Recent Event Records:</Typography>
            </Grid>
            {eventRecords.map((eventRecord) => {
              eventCounter++;
              if (eventCounter < 10) {
                if (eventRecord.formatted_status === "Complete") {
                  return (
                    <Grid item container xs={6}>
                      <Card
                        style={{
                          width: "100%",
                          height: "100px",
                          padding: "8px",
                          backgroundColor: "#18bff6",
                        }}
                        onClick={() => goToEventRecord(eventRecord)}
                      >
                        {eventRecord.event_name && (
                          <Typography style={{ color: "white" }}>
                            {eventRecord.event_name}
                          </Typography>
                        )}
                        {!eventRecord.event_name && (
                          <Typography style={{ color: "white" }}>
                            UnNamed Event
                          </Typography>
                        )}
                        <Typography style={{ color: "white" }}>
                          {eventRecord.formatted_status}
                        </Typography>
                      </Card>
                    </Grid>
                  );
                }
                if (eventRecord.formatted_status === "Future") {
                  return (
                    <Grid item container xs={6}>
                      <Card
                        style={{
                          width: "100%",
                          height: "100px",
                          padding: "8px",
                          backgroundColor: "#e3e024",
                        }}
                        onClick={() => goToEventRecord(eventRecord)}
                      >
                        {eventRecord.event_name && (
                          <Typography style={{ color: "white" }}>
                            {eventRecord.event_name}
                          </Typography>
                        )}
                        {!eventRecord.event_name && (
                          <Typography style={{ color: "white" }}>
                            UnNamed Event
                          </Typography>
                        )}
                        <Typography style={{ color: "white" }}>
                          {eventRecord.formatted_status}
                        </Typography>
                      </Card>
                    </Grid>
                  );
                }
                if (eventRecord.formatted_status === "Overdue") {
                  return (
                    <Grid item container xs={6}>
                      <Card
                        style={{
                          width: "100%",
                          height: "100px",
                          padding: "8px",
                          backgroundColor: "#f72020",
                        }}
                        onClick={() => goToEventRecord(eventRecord)}
                      >
                        {eventRecord.event_name && (
                          <Typography style={{ color: "white" }}>
                            {eventRecord.event_name}
                          </Typography>
                        )}
                        {!eventRecord.event_name && (
                          <Typography style={{ color: "white" }}>
                            UnNamed Event
                          </Typography>
                        )}
                        <Typography style={{ color: "white" }}>
                          {eventRecord.formatted_status}
                        </Typography>
                      </Card>
                    </Grid>
                  );
                }
                if (eventRecord.formatted_status === "Deviation") {
                  return (
                    <Grid item container xs={6}>
                      <Card
                        style={{
                          width: "100%",
                          height: "100px",
                          padding: "8px",
                          backgroundColor: "#7d1d1d",
                        }}
                        onClick={() => goToEventRecord(eventRecord)}
                      >
                        {eventRecord.event_name && (
                          <Typography style={{ color: "white" }}>
                            {eventRecord.event_name}
                          </Typography>
                        )}
                        {!eventRecord.event_name && (
                          <Typography style={{ color: "white" }}>
                            UnNamed Event
                          </Typography>
                        )}
                        <Typography style={{ color: "white" }}>
                          {eventRecord.formatted_status}
                        </Typography>
                      </Card>
                    </Grid>
                  );
                }
              }
            })}
          </Grid>
        )} */}
        </div>
    );
}

export default EventGraphs;
