import React, { useContext, useEffect, useState, useReducer } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Slide from "@material-ui/core/Slide";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CancelIcon from "@material-ui/icons/Cancel";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Alert } from '@material-ui/lab';

import API from "Api/Api";
import CustomTextField from "Monitoring/CustomFields/CustomTextField";
import AvatarComponent from "Components/AvatarComponent";
import Message from "Components/Message";
import ReactSignatureCreator from "Components/ReactSignatureCreator";
import SOPDialog from "./SOPDialog";

import CustomRadioButtonField from "Monitoring/CustomFields/CustomRadioButtonField";
import CustomDateTimeField from "Monitoring/CustomFields/CustomDateTimeField";
import CustomSelectField from "Monitoring/CustomFields/CustomSelectField";
import CustomCheckboxField from "Monitoring/CustomFields/CustomCheckboxField";
import CustomNumberField from "Monitoring/CustomFields/CustomNumberField";

import TaskRow from "./TaskRow";
import useAuthedUser from "hooks/useAuthedUser";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "0.25px",
  },

  bg: {
    minWidth: '100%',
    backgroundColor: "rgb(234,234,234, 0.5)",
  },

  form: {
    width: '100%',
    maxWidth: '1200px',
    padding: "32px",
  },

  table: {
    overflow: 'auto',
    //minWidth: 650,
  },

  fakeLink: {
    color: "blue",
    textDecoration: "underline",
    cursor: "pointer",
    fontSize: '12px'
  },
}));

const RedText = withStyles({
  root: {
    color: "rgba(255, 0, 0, 1.0)",
  },
})(Typography);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialState = {};
function reducer(submissions, action) {

  if (action.type === 'radio') {
    let id = action.payload.id;
    let value = action.payload.value;
    let updatedSubmissions = { ...submissions };

    updatedSubmissions[id] = {
      ...updatedSubmissions[id],
      satisfactory: value,
    };

    return updatedSubmissions;
  }

  if (action.type === 'custom_field') {
    const customField = action.payload.customField;
    const taskId = action.payload.task_id;
    const fieldId = customField.id;
    const fieldValue = action.payload.value;

    let updatedSubmissions = { ...submissions };

    let existingCustomFormInputs = [];
    if (updatedSubmissions[taskId]) {
      existingCustomFormInputs = updatedSubmissions[taskId].custom_field_input || [];
    }

    updatedSubmissions[taskId] = {
      ...updatedSubmissions[taskId],
      custom_field_input: {
        ...existingCustomFormInputs,
        [fieldId]: fieldValue
      }
    };

    return updatedSubmissions;
    //let task_id = action.payload.task_id;
    //let field_id = action.payload.field_id;
    //let field_value = action.payload.field_value;

    //let updatedSubmissions = { ...submissions };

    //let existingCustomFormInputs = [];
    //if (updatedSubmissions[task_id]) {
    //  existingCustomFormInputs = updatedSubmissions[task_id].custom_field_input || [];
    //}

    //updatedSubmissions[task_id] = {
    //  ...updatedSubmissions[task_id],
    //  custom_field_input: {
    //    ...existingCustomFormInputs,
    //    [field_id]: field_value
    //  }
    //}

    //return updatedSubmissions;
  }

  if (action.type === 'observations') {
    let task_id = action.payload.task_id;
    let observations = action.payload.observations;

    let updatedSubmissions = { ...submissions };
    updatedSubmissions[task_id] = {
      ...updatedSubmissions[task_id],
      observations: observations,
    };

    return updatedSubmissions;
  }

  return submissions;
}

export default function EventRecordForm(props) {
  const [submissions, dispatch] = useReducer(reducer, initialState);

  const [readOnly, setReadOnly] = useState(
    props.readOnly != null ? props.readOnly : true
  );
  const [eventRecord, setEventRecord] = useState(null);
  const [assignedMonitor, setAssignedMonitor] = useState(null);

  const [user] = useAuthedUser();
  const [hasChanged, setHasChanged] = useState(false);
  const [event, setEvent] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  const onDesktop = useMediaQuery('(min-width:600px)');

  const api = new API();

  const [overdue, setOverdue] = useState(false);

  useEffect(() => {
    let pk = params.pk || props.pk;
    if (pk == null) {
      setError("Could not access the event.");
      history.goBack();
      return;
    }

    api
      .getMonitoringAPI()
      .retrieveEventRecord(pk)
      .then((response) => {
        if (response.data.status === "OVERDUE") {
          setOverdue(true);
          setReadOnly(false);
          setEventRecord(response.data);
          setEvent(response.data.event_submitted);
          setTasks(response.data.event_submitted.read_tasks);
        }

        else if (response.data.event_submitted && response.data.status !== "OVERDUE") {
          let submittedEvent = response.data.event_submitted;
          setReadOnly(true);
          setEventRecord(response.data);
          setEvent(submittedEvent);
          setTasks(submittedEvent.read_tasks);
        } else {
          setReadOnly(false);
          setEventRecord(response.data);
          if (response.data.event.primary_monitor) {
            api.getOrganizationAPI().getSelectedUser(response.data.event.primary_monitor).then((member) => {
              setAssignedMonitor(member.data);
            })
              .catch((error) => {
                api.createErrorReport({
                  path: location.pathname,
                  request: {
                    description: "Could not get the selected user",
                    error: JSON.stringify(error),
                  }
                });
                console.log(error);
              });
          }

          setEvent(response.data.event);
          setTasks(response.data.event.read_tasks);
        }
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        api.createErrorReport({
          path: location.pathname,
          request: JSON.stringify({
            description: "Could not retrieve event record.",
            error: error
          })
        });
        history.goBack();
      });
  }, [props.pk]);

  function handleRadioButton(event) {
    let type = 'radio';
    let taskId = event.target.name;
    let value = event.target.value;

    dispatch({ type: type, payload: { id: taskId, value: value } });
  }

  function handleCustomField(event, task, customField) {
    let type = 'custom_field';
    let task_id = task.id;
    let field_id = customField.id;

    let field_value;
    if (customField.field_type === "checkbox" || customField.field_type === "datetime") {
      field_value = event;
    }
    else {
      field_value = event.target.value;
    }

    dispatch({ type: type, payload: { task_id: task_id, customField: customField, value: field_value } });
  }

  function handleObservations(event) {
    let type = 'observations';
    let task_id = event.target.name;
    let observations = event.target.value;

    dispatch({ type: type, payload: { task_id, observations } });
  }

  const [taskErrors, setTaskErrors] = useState([]);
  const [fieldErrors, setFieldErrors] = useState([]);

  function submit(event) {
    let newTaskErrors = [];
    let newFieldErrors = [];

    for (let i = 0; i < tasks.length; i++) {
      let task = tasks[i];
      if (!(tasks[i].id in submissions)) { // no input at all
        newTaskErrors.push(tasks[i].id);

        for (let i = 0; i < task.custom_fields.length; i++) {
          let customField = task.custom_fields[i];
          newFieldErrors.push(customField.id);
        }
      }
      else { // check if null or required fields missing
        let sub = submissions[task.id];

        if (sub.satisfactory == null) {
          newTaskErrors.push(task.id);
        }

        for (let customField of task.read_custom_fields) {
          if (customField.required && (!sub.custom_field_input || !sub.custom_field_input[customField.id])) {
            newFieldErrors.push(customField.id);
          }
        }
      }
    }

    if (newTaskErrors.length) {
      setTaskErrors(newTaskErrors);
    }

    if (newFieldErrors.length) {
      setFieldErrors(newFieldErrors);
    }

    if (newTaskErrors.length || newFieldErrors.length) {
      setError("Please complete required fields for each task.");
      return;
    }

    if (!signature) {
      setError("Please sign the form.");
      return;
    }

    formatAndSend(submissions);
  }

  const [taskSelected, setTaskSelected] = useState(null);

  function formatAndSend() {
    let formattedEventRecord = {
      ...eventRecord,
      signature: signature,
      task_records: [],
      event_when_submitted: event,
    };

    if (submissions) {
      for (const [key, value] of Object.entries(submissions)) {
        let task = tasks.find((task) => task.id === parseInt(key, 10));

        let taskRecord = {
          task: task.id,
          satisfactory: value.satisfactory,
          observations: value.observations,
          custom_field_input: value.custom_field_input,
          task_when_submitted: task,
        };

        formattedEventRecord.task_records.push(taskRecord);
      }
    }

    formattedEventRecord.task_records.sort((a, b) => {
      return a.task_when_submitted.display_order - b.task_when_submitted.display_order;
    });

    setLoading(true);

    //return;
    api
      .getMonitoringAPI()
      .submitRecord(formattedEventRecord)
      .then(() => {
        if (props.onComplete) {
          props.onComplete();
        }
        if (props.setClosed) {
          props.setClosed();
        }

        if (props.close) {
          props.close();
        } else {
          history.goBack();
        }
      })
      .catch(error => {
        api.createErrorReport({
          path: location.pathname,
          request: JSON.stringify({
            description: "Could not submit monitoring record due to this error.",
            error: error,
          })
        });
        setLoading(false);
        setError("Could not reach server.");
      });
  }

  const [signature, setSignature] = useState(null);
  const [validSignature, setValidSignature] = useState(false);

  function checkSignature(sigPad) {
    let sig = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
    setSignature(sig);
    setValidSignature(true);
    setHasChanged(true);
  }

  function validSignatureCheck(validCheck) {
    setValidSignature(validCheck);
  }

  function cancel() {
    if (hasChanged && !readOnly && !overdue && !window.confirm('Are you sure you want to close this form? Any unsaved data will be lost.')) {
      return;
    }

    if (props.setClosed) {
      props.setClosed();
    }
    if (props.close) {
      props.close();
    } else {
      history.goBack();
    }
  }

  return (
    <>
      <SOPDialog task={taskSelected} setTaskSelected={setTaskSelected} />
      <Dialog fullScreen open={true} TransitionComponent={Transition} style={{ padding: '0px' }}>

        {loading && (
          <DialogContent>
            <Grid
              container
              alignItems="center"
              justify="center"
              style={{ width: "100%", height: "100%" }}
            >
              <CircularProgress />
            </Grid>
          </DialogContent>
        )}

        {event && !loading && (
          <>
            {onDesktop &&
              <DialogTitle
                style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
              >
                <Grid container>
                  <Grid item xs={8}>
                    <Typography variant="h6">Monitoring Form #{eventRecord.form_num}</Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={4}
                    alignItems="center"
                    justify="flex-end"
                  >
                    <IconButton onClick={cancel} style={{ padding: 0 }}>
                      <CancelIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>
            }

            <DialogContent className={classes.bg} style={{ padding: onDesktop ? '32px' : '0px' }}>
              <Grid item container xs={12} style={{ width: '100%', height: '100%' }} alignItems="center" justify={onDesktop ? "center" : null}>
                <Paper
                  className={classes.form}
                  elevation={0}
                  style={{ padding: onDesktop ? '32px' : '16px' }}
                >
                  <Grid container spacing={3} alignItems="center" justify="center">
                    <Grid item xs={12}>
                      <Typography className={classes.title}>
                        {event.name}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      {overdue && user && (
                        <Grid item container xs={12}>
                          <Grid item container>
                            <Grid item>
                              <AvatarComponent rounded user={eventRecord.event_when_submitted.read_primary_monitor}></AvatarComponent>
                            </Grid>
                            <Grid item style={{ paddingLeft: '8px' }}>
                              {eventRecord.event_when_submitted.read_primary_monitor &&
                                <Typography style={{ fontSize: '14px' }}>
                                  {`Assigned to ${eventRecord.event_when_submitted.read_primary_monitor.first_name ? eventRecord.event_when_submitted.read_primary_monitor.first_name : "Unkown"} ${eventRecord.event_when_submitted.read_primary_monitor.last_name ? eventRecord.event_when_submitted.read_primary_monitor.last_name : ""} `}
                                </Typography>
                              }
                              <Typography style={{ fontSize: '12px' }}>
                                {`Was due ${eventRecord.due}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}

                      {!overdue && readOnly && user && (
                        <Grid item container xs={12}>
                          <Grid item container>
                            <Grid item>
                              <AvatarComponent rounded user={eventRecord.event_submitted.read_primary_monitor}></AvatarComponent>
                            </Grid>
                            <Grid item style={{ paddingLeft: '8px' }}>
                              <Typography style={{ fontSize: '14px' }}>
                                {`Completed by ${eventRecord.submitted_by} `}
                              </Typography>
                              <Typography style={{ fontSize: '12px' }}>
                                {`on ${eventRecord.date_submitted} at ${eventRecord.time_submitted}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}

                      {!overdue && !readOnly && eventRecord && (
                        <Grid item container xs={12}>
                          <Grid item container>
                            <Grid item style={{ paddingTop: '4px' }}>
                              {assignedMonitor &&
                                <AvatarComponent rounded user={assignedMonitor}></AvatarComponent>
                              }
                            </Grid>
                            <Grid item style={{ paddingLeft: '8px', paddingTop: '4px' }}>
                              <Typography style={{ fontSize: '14px' }}>
                                {`Assigned to ${eventRecord.event.read_primary_monitor.first_name} ${eventRecord.event.read_primary_monitor.last_name}`}
                              </Typography>
                              <Typography style={{ fontSize: '12px' }}>
                                {`Due ${eventRecord.days_due}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}

                      {overdue &&
                        <Grid item xs={12} style={{ marginTop: '16px' }}>
                          <Alert
                            severity="error"
                          >
                            This monitoring record was not completed on time.
                          </Alert>
                          <Typography>
                          </Typography>
                        </Grid>
                      }
                    </Grid>

                    <Grid item xs={12} style={{ minWidth: '100%' }} id="monitoring-table">
                      {onDesktop &&
                        <TableContainer>
                          <Table className={classes.table} size="medium" aria-label="task-table">
                            <colgroup>
                              <col width="50%" />
                              <col width="25%" />
                              <col width="25%" />
                            </colgroup>
                            <TableHead>
                              <tr>
                                <TableCell>Task</TableCell>
                                <TableCell align="left">
                                  S/U <RedText display="inline">*</RedText>
                                </TableCell>
                                <TableCell align="left">
                                  Observations (optional)
                                </TableCell>

                                {/*Only show if there are deviations*/}
                                {readOnly &&
                                  eventRecord.task_records.find(
                                    (task_record) =>
                                      task_record.deviation_form != null
                                  ) != null && (
                                    <TableCell align="center">Actions</TableCell>
                                  )}
                              </tr>
                            </TableHead>
                            <TableBody>
                              {tasks && !readOnly && tasks.map((task) => {
                                return (
                                  <TaskRow
                                    task={task}
                                    submissions={submissions}
                                    overdue={overdue}
                                    taskErrors={taskErrors}
                                    fieldErrors={fieldErrors}
                                    readOnly={readOnly}
                                    handleRadioButton={handleRadioButton}
                                    handleObservations={handleObservations}
                                    handleCustomField={handleCustomField}
                                    setTaskSelected={setTaskSelected}
                                  />
                                );
                              })}

                              {readOnly && eventRecord.task_records.map((task_record) => {
                                return (
                                  <TableRow key={task_record.id}>
                                    <TableCell component="th" scope="row">
                                      <b>{task_record.task_submitted.name}</b>
                                      <br></br>
                                      <p>{task_record.task_submitted.description} {' '}
                                        <span
                                          className={classes.fakeLink}
                                          onClick={() => {
                                            setTaskSelected(task_record.task_submitted);
                                          }}
                                        >
                                          (Read SOP)
                                        </span>
                                      </p>
                                    </TableCell>
                                    <TableCell align="left">
                                      {task_record.custom_field_input && task_record.task_submitted.read_custom_fields && task_record.task_submitted.read_custom_fields.map(customField => {
                                        return (
                                          <>
                                            {customField.field_type === "textfield" &&
                                              <CustomTextField
                                                style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
                                                id={customField.id}
                                                name={customField.name}
                                                displayName={customField.name}
                                                description={customField.description}
                                                readOnly={true}
                                                required={customField.required}
                                                fullWidth
                                                oneRow
                                                value={task_record.custom_field_input[customField.id]}
                                              />
                                            }

                                            {customField.field_type === "number" &&
                                              <CustomNumberField
                                                style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
                                                id={customField.id}
                                                name={customField.name}
                                                displayName={customField.name}
                                                description={customField.description}
                                                readOnly={readOnly}
                                                required={customField.required}
                                                fullWidth
                                                oneRow
                                                value={task_record.custom_field_input[customField.id]}
                                              />
                                            }

                                            {customField.field_type === "radio" &&
                                              <CustomRadioButtonField
                                                style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
                                                id={customField.id}
                                                name={customField.name}
                                                readOnly={readOnly}
                                                displayName={customField.name}
                                                description={customField.description}
                                                options={customField.radio_options}
                                                required={customField.required}
                                                value={task_record.custom_field_input[customField.id]}
                                                fullWidth
                                                oneRow
                                              />
                                            }

                                            {customField.field_type === "datetime" &&
                                              <CustomDateTimeField
                                                style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
                                                id={customField.id}
                                                name={customField.name}
                                                displayName={customField.name}
                                                description={customField.description}
                                                readOnly={true}
                                                required={customField.required}
                                                value={task_record.custom_field_input[customField.id]}
                                                oneRow
                                                fullWidth
                                              />
                                            }

                                            {customField.field_type === "select" &&
                                              <CustomSelectField
                                                style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
                                                id={customField.id}
                                                name={customField.name}
                                                displayName={customField.name}
                                                description={customField.description}
                                                options={customField.select_options}
                                                required={customField.required}
                                                readOnly={true}
                                                value={task_record.custom_field_input[customField.id]}
                                                fullWidth
                                                oneRow
                                              />
                                            }

                                            {customField.field_type === "checkbox" &&
                                              <CustomCheckboxField
                                                style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
                                                textFieldClasses={classes}
                                                id={customField.id}
                                                name={customField.name}
                                                required={customField.required}
                                                displayName={customField.name}
                                                description={customField.description}
                                                options={customField.checkbox_options}
                                                readOnly={true}
                                                fullWidth
                                                oneRow
                                                value={
                                                  (task_record.custom_field_input[customField.id] && task_record.custom_field_input[customField.id].value !== null) ? 
                                                  task_record.custom_field_input[customField.id].value : null
                                                }
                                                onChange={checkedBox => handleCustomField(checkedBox, customField)}
                                              />
                                            }
                                          </>
                                        )}
                                      )
                                      }
                                      <div style={{ marginTop: '16px' }}>
                                        {task_record.satisfactory}
                                      </div>
                                    </TableCell>
                                    <TableCell align="left">
                                      {task_record.observations}
                                    </TableCell>
                                    <TableCell align="center">
                                      {task_record.deviation_form && (
                                        <Button
                                          size="small"
                                          variant="outlined"
                                          color="secondary"
                                          onClick={() =>
                                            history.push(
                                              `/deviation/${task_record.deviation_form}`
                                            )
                                          }
                                        >
                                          Deviation
                                        </Button>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      }

                      {!onDesktop && readOnly && eventRecord.task_records && eventRecord.task_records.map((task_record) => {
                        return (
                          <Grid item container spacing={3} xs={12} key={task_record.id} style={{ padding: '5%' }}>
                            <Grid item xs={12}>
                              <Typography>
                                <b>{task_record.task_submitted.name}</b>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>
                                {task_record.task_submitted.description} {" "}
                                <span
                                  className={classes.fakeLink}
                                  onClick={() => {
                                    setTaskSelected(task_record.task_submitted);
                                  }}
                                >
                                  (Read SOP)
                                </span>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              {task_record.custom_field_input && task_record.task_submitted.custom_fields && task_record.task_submitted.custom_fields.map(customField => {
                                return (
                                  <>
                                    {customField.field_type === "textfield" &&
                                      <CustomTextField
                                        style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
                                        id={customField.id}
                                        name={customField.name}
                                        displayName={customField.name}
                                        description={customField.description}
                                        readOnly={true}
                                        required={customField.required}
                                        fullWidth
                                      />
                                    }

                                    {customField.field_type === "number" &&
                                      <CustomNumberField
                                        style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
                                        id={customField.id}
                                        name={customField.name}
                                        displayName={customField.name}
                                        description={customField.description}
                                        readOnly={readOnly}
                                        required={customField.required}
                                        fullWidth
                                      />
                                    }

                                    {customField.field_type === "radio" &&
                                      <CustomRadioButtonField
                                        style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
                                        id={customField.id}
                                        name={customField.name}
                                        readOnly={true}
                                        displayName={customField.name}
                                        description={customField.description}
                                        options={customField.radio_options}
                                        required={customField.required}
                                      />
                                    }

                                    {customField.field_type === "datetime" &&
                                      <CustomDateTimeField
                                        style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
                                        id={customField.id}
                                        name={customField.name}
                                        displayName={customField.name}
                                        description={customField.description}
                                        readOnly={true}
                                        required={customField.required}
                                        value={submissions[task_record.id]["custom_field_input"][customField.id] || null}
                                      />
                                    }

                                    {customField.field_type === "select" &&
                                      <CustomSelectField
                                        style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
                                        textFieldClasses={classes}
                                        id={customField.id}
                                        name={customField.name}
                                        displayName={customField.name}
                                        description={customField.description}
                                        options={customField.select_options}
                                        required={customField.required}
                                        readOnly={readOnly}
                                        fullWidth
                                      />
                                    }

                                    {customField.field_type === "checkbox" &&
                                      <CustomCheckboxField
                                        style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
                                        textFieldClasses={classes}
                                        id={customField.id}
                                        name={customField.name}
                                        required={customField.required}
                                        displayName={customField.name}
                                        description={customField.description}
                                        options={customField.checkbox_options}
                                        readOnly={true}
                                        fullWidth
                                      />
                                    }
                                  </>
                                )
                              })}
                              <FormControl component="fieldset">
                                <RadioGroup
                                  aria-label="s/u"
                                  value={task_record.satisfactory}
                                  disabled={true}
                                  onChange={handleRadioButton}
                                >
                                  <FormControlLabel
                                    value="Satisfactory"
                                    control={<Radio disabled={true} size="small" />}
                                    label="Satisfactory"
                                  />
                                  <FormControlLabel
                                    value="Unsatisfactory"
                                    control={<Radio disabled={true} size="small" />}
                                    label="Unsatisfactory"
                                  />
                                  <FormControlLabel
                                    value="N/A"
                                    control={<Radio disabled={true} size="small" />}
                                    label="N/A"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>
                                Observations
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={true}
                                value={task_record.observations}
                                fullWidth
                                multiline
                                variant="outlined"
                                required
                                rows={3}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              {task_record.deviation_form && (
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="secondary"
                                  onClick={() =>
                                    history.push(
                                      `/deviation/${task_record.deviation_form}`
                                    )
                                  }
                                >
                                  Deviation
                                </Button>
                              )
                              }
                            </Grid>
                          </Grid>
                        );
                      })
                      }
                      {!onDesktop && tasks && !readOnly && tasks.map((task) => {
                        return (
                          <Grid item container spacing={3} xs={12} key={task.id} style={{ padding: '5%' }}>
                            <Grid item xs={12}>
                              <Typography>
                                <b>{task.name}</b>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>
                                {task.description} {" "}
                                <span
                                  className={classes.fakeLink}
                                  onClick={() => {
                                    setTaskSelected(task);
                                  }}
                                >
                                  (Read SOP)
                                </span>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              {task.custom_fields && task.custom_fields.map(customField => {
                                return <>
                                  {customField.field_type === "textfield" &&
                                    <CustomTextField
                                      style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
                                      error={fieldErrors && fieldErrors.includes(customField.id)}
                                      id={customField.id}
                                      name={customField.name}
                                      displayName={customField.name}
                                      description={customField.description}
                                      required={customField.required}
                                      fullWidth
                                      onChange={event => handleCustomField(event, customField)}
                                    />
                                  }

                                  {customField.field_type === "number" &&
                                    <CustomNumberField
                                      style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
                                      textFieldClasses={classes}
                                      error={fieldErrors && fieldErrors.includes(customField.id)}
                                      id={customField.id}
                                      name={customField.name}
                                      displayName={customField.name}
                                      description={customField.description}
                                      required={customField.required}
                                      fullWidth
                                      onChange={event => handleCustomField(event, customField)}
                                    />
                                  }

                                  {customField.field_type === "radio" &&
                                    <CustomRadioButtonField
                                      style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
                                      id={customField.id}
                                      error={fieldErrors && fieldErrors.includes(customField.id)}
                                      name={customField.name}
                                      displayName={customField.name}
                                      description={customField.description}
                                      options={customField.radio_options}
                                      required={customField.required}
                                      onChange={event => handleCustomField(event, customField)}
                                    />
                                  }

                                  {customField.field_type === "datetime" &&
                                    <CustomDateTimeField
                                      style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
                                      id={customField.id}
                                      error={fieldErrors && fieldErrors.includes(customField.id)}
                                      name={customField.name}
                                      displayName={customField.name}
                                      description={customField.description}
                                      required={customField.required}
                                      onChange={datetime => { handleCustomField(datetime, customField) }}
                                    //value={submission}
                                    />
                                  }

                                  {customField.field_type === "select" &&
                                    <CustomSelectField
                                      style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
                                      textFieldClasses={classes}
                                      id={customField.id}
                                      error={fieldErrors && fieldErrors.includes(customField.id)}
                                      name={customField.name}
                                      displayName={customField.name}
                                      description={customField.description}
                                      options={customField.select_options}
                                      required={customField.required}
                                      fullWidth
                                      onChange={event => handleCustomField(event, customField)}
                                    />
                                  }

                                  {customField.field_type === "checkbox" &&
                                    <CustomCheckboxField
                                      style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
                                      textFieldClasses={classes}
                                      id={customField.id}
                                      error={fieldErrors && fieldErrors.includes(customField.id)}
                                      name={customField.name}
                                      required={customField.required}
                                      displayName={customField.name}
                                      description={customField.description}
                                      options={customField.checkbox_options}
                                      readOnly={readOnly}
                                      fullWidth
                                      onChange={checkedBox => handleCustomField(checkedBox, customField)}
                                    />
                                  }
                                </>
                              })}
                              <FormControl component="fieldset">
                                <RadioGroup
                                  aria-label="s/u"
                                  name={task.id.toString()}
                                  value={task.satisfactory}
                                  disabled={overdue}
                                  onChange={handleRadioButton}
                                >
                                  <FormControlLabel
                                    value="Satisfactory"
                                    control={<Radio disabled={overdue} size="small" />}
                                    label="Satisfactory"
                                    error={taskErrors && taskErrors.includes(task.id)}
                                  />
                                  <FormControlLabel
                                    value="Unsatisfactory"
                                    control={<Radio disabled={overdue} size="small" />}
                                    label="Unsatisfactory"
                                    error={taskErrors && taskErrors.includes(task.id)}
                                  />
                                  <FormControlLabel
                                    value="N/A"
                                    control={<Radio disabled={overdue} size="small" />}
                                    label="N/A"
                                    error={taskErrors && taskErrors.includes(task.id)}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>
                                Observations
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                name="observations"
                                disabled={overdue}
                                onChange={handleObservations}
                                fullWidth
                                multiline
                                variant="outlined"
                                required
                                rows={3}
                              />
                            </Grid>
                          </Grid>
                        )
                      })
                      }
                    </Grid>

                    {!overdue &&
                      <>
                        <Grid item xs={12}>
                          <Typography variant="h6">Signature</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </>
                    }

                    <Grid item xs={12} style={{ overflow: 'auto', padding: '5%' }}>
                      {!readOnly && !overdue && (
                        <ReactSignatureCreator checkSignature={checkSignature} validSignatureCheck={validSignatureCheck} />
                      )}
                      {readOnly && <img src={eventRecord.signature} />}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </DialogContent>
            <DialogActions
              style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}
            >
              <Grid
                item
                container
                kxs={12}
                alignItems="flex-end"
                justify="flex-end"
              >
                {!readOnly && (
                  <>
                    <Button
                      variant="outlined"
                      color="secondary"
                      style={{ margin: 4 }}
                      onClick={cancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ margin: 4 }}
                      onClick={submit}
                      disabled={!validSignature}
                    >
                      Submit
                    </Button>
                  </>
                )}
                {readOnly && (
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ margin: 4 }}
                    onClick={cancel}
                  >
                    Close
                  </Button>
                )}
              </Grid>
            </DialogActions>
          </>
        )
        }
      </Dialog >
      <Message
        open={error != null}
        message={error}
        severity="error"
        vertical="bottom"
        horizontal="center"
        handleClose={() => setError(null)}
      />
    </>
  );
}
