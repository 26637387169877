import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import API from "../../../Api/Api";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import PropTypes from 'prop-types';
import NewUserInvite from "./NewUserInvite"
import ExistingUserInvite from "./ExistingUserInvite"

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © FoodByte '}
            {/*<Link color="inherit" href="https://material-ui.com/">*/}
            {/*</Link>{' '}*/}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function AcceptOrganizationInvite(props) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const currentParams = useParams();
    const [inviteData, setInviteData] = useState(null);
    const [newUser, setNewUser] = useState(true);
    const api = new API();

    function getInvite(inviteToken, inviteHash) {
        api.getOrganizationInviteAPI().getInviteByTokens(inviteToken, inviteHash).then(e => {
            setInviteData(e.data);
            setNewUser(e.data.new_user);
        }).catch(e => {
            console.log(e);
        });

    }

    useEffect(() => {
        getInvite(currentParams.uuid, currentParams.hash);
  }, []);
    


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar} alt="FoodByte Logo" src="logos/FB_Logo_Reversed_RGB_Icon.png"/>
                <Typography component="h1" variant="h5">
                    FoodByte
                </Typography>
                {inviteData && 
                <React.Fragment> 
                    {newUser && <NewUserInvite invite={inviteData}></NewUserInvite>}
                
                    {!newUser && <ExistingUserInvite invite={inviteData}></ExistingUserInvite>}
                    
                </React.Fragment>

            }
            
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}
