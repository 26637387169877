import React from "react";
import Slide from '@material-ui/core/Slide';

export default class TraceabilityUtils {
  formatError(errorObj) {
    if(!errorObj || ! errorObj.message || errorObj.message.indexOf('code 500') != -1) {
      return "Something went wrong on the server. Please try again later."
    }

    return JSON.stringify( (errorObj.response.data) ).replace(/{/g, '').replace(/}/g, '').replace(/\[/g, ' ').replace(/\]/g, ' ');
  }

  /*Formats numbers to 3 decimal places with no trailing insignificant zeroes.
    Convert to a number in case what comes in has been coerced into a string,
    toFixed then converts that back to a string, so we convert it into a number once more
    then toString will automatically strip the trailing zeroes for us.
  */
  formatNumber(num, prec) {
    return Number(Number(num).toFixed(prec ? prec : 3)).toString();
  }

  /**
   * Returns an empty message object.
   */
  emptyMessage() {
    return {
      open: false,
      message: '',
      status: 'info',
    };
  }

  getConvertedValue(value, unitFrom, unitTo) {
    if((!value && value !==0) || !unitFrom || !unitTo) {
      return '0 No Unit/Value Given';
    }
    
    if (unitFrom.unit_type != unitTo.unit_type) {
      throw "Cannot convert between different metrics.";
    }

    if (unitFrom == unitTo) {
      return value;
    }

    let valueInBase = value*unitFrom.ratio_to_base;
    let valueConverted = valueInBase/unitTo.ratio_to_base;

    return valueConverted;
  }

  /**
   * Ciphers statuses for all record types to a human readable string. Returns "Unknown" for unknown strings.
   * 
   * @param {*} status - The BE status string
   * @param {*} shipping - A boolean, is this a shipping order or not.
   */
  statusCipher(status, shipping) {
    const STATUS_CIPHER = {
      DRAF: {text: "Draft", color: 'grey'},
      OUTS: {text: "Outstanding", color: 'grey'},
      ACTI: {text: (shipping ? "Incomplete" : "Receiving"), color: 'blue'},
      CANC: {text: "Cancelled", color: 'red'},
      COMP: {text: "Complete", color: 'green'},
      SHIP: {text: "Shipped", color: 'green'},
      SCHE: {text: "Scheduled", color: 'grey'},
      ONGO: {text: "Ongoing", color: 'blue'},
      ONHO: {text: "On Hold", color: 'grey'},
      PROD: {text: "Production", color: 'blue'},
      READ: {text: "Ready", color: 'blue'},
      PICK: {text: "Picking", color: 'blue'},
      AWAI: {text: "Awaiting Shipment", color: 'blue'},
    }

    if(STATUS_CIPHER[status]) {
      return STATUS_CIPHER[status];
    }
    else {
      return {text: "Unknown", color: '#FFF'}
    }
  }

  /**
   * Ciphers statuses for all record types to an association action to display on the button.
   * 
   * @param {*} status - The BE status string
   * @param {*} shipping - A boolean, is this a shipping order or not.
   */
  statusActionCipher(status, shipping) {
    const STATUS_CIPHER = {
      Draft: "Edit",
      Outstanding: "Edit",
      Incomplete: "Ship",
      Receiving : "Receive",
      Cancelled: "View",
      Complete: "View",
      Shipped: "View",
      Scheduled: "Produce",
      Ongoing: "Produce",
      "On Hold": "Confirm",
      Production: "Produce",
      Ready: "Edit",
      Picking: "Pick",
      "Awaiting Shipment": "Ship",
    }
    if(STATUS_CIPHER[status]) {
      //console.log('Returning ' + STATUS_CIPHER[status]);
      return STATUS_CIPHER[status];
    }
    else {
      return "Unknown";
    }
  }

  /**
   * reduces item instance units to be independent of volume/weight
   * 
   * @param {JSON} itemInstanceObject 
   */
  reduceItemInstanceUnits(itemInstanceObject) {
    let updatedItemInstance = { ...itemInstanceObject };
    
    if(updatedItemInstance.total_weight_value) {
      updatedItemInstance.unit_value = updatedItemInstance.total_weight_value;
      updatedItemInstance.unit_type = updatedItemInstance.total_weight_unit;
    }
    else if(updatedItemInstance.total_volume_value) {
      updatedItemInstance.unit_value = updatedItemInstance.total_volume_value;
      updatedItemInstance.unit_type = updatedItemInstance.total_volume_unit;
    }
    
    return updatedItemInstance;
  }

  /**
   * Returns the item instance units to their orignal state of different fields for weight/volume.
   * Ideally the weight/volume options are returned from the backend to prevent differences in accept units.
   * 
   * @param {JSON} itemInstanceObject 
   * @param {[string]} weightOptions 
   * @param {[string]} volumeOptions 
   */
  expandItemInstanceUnits(itemInstanceObject, weightOptions, volumeOptions) {
    let updatedItemInstance = { ...itemInstanceObject };
    
    if(weightOptions && weightOptions.indexOf(updatedItemInstance.unit_type) != -1) {
      updatedItemInstance.total_weight_unit = updatedItemInstance.unit_type;
      updatedItemInstance.total_weight_value = updatedItemInstance.unit_value;

      updatedItemInstance.total_volume_unit = null;
      updatedItemInstance.total_volume_value = null;
    }
    else if(volumeOptions && volumeOptions.indexOf(updatedItemInstance.unit_type) != -1){
      updatedItemInstance.total_volume_unit = updatedItemInstance.unit_type;
      updatedItemInstance.total_volume_value = updatedItemInstance.unit_value;

      updatedItemInstance.total_weight_unit = null;
      updatedItemInstance.total_weight_value = null;
    }
    else {
      updatedItemInstance.total_volume_unit = null;
      updatedItemInstance.total_volume_value = null;
      updatedItemInstance.total_weight_unit = null;
      updatedItemInstance.total_weight_value = null;
    }
    
    return updatedItemInstance;
  }

  /**
   * reduces sku instance units to be independent of volume/weight
   * 
   * @param {JSON} skuInstanceObject 
   */
  reduceSkuInstanceUnits(skuInstanceObject) {
    let updatedSkuInstance = { ...skuInstanceObject };

    if(updatedSkuInstance.weight_ordered_value) {
      updatedSkuInstance.unit_value = updatedSkuInstance.weight_ordered_value;
      updatedSkuInstance.unit_type = updatedSkuInstance.weight_ordered_unit;
    }
    else if(updatedSkuInstance.volume_ordered_value) {
      updatedSkuInstance.unit_value = updatedSkuInstance.volume_ordered_value;
      updatedSkuInstance.unit_type = updatedSkuInstance.volume_ordered_unit;
    }

    return updatedSkuInstance;
  }

  /**
   * Returns the item instance units to their orignal state of different fields for weight/volume.
   * Ideally the weight/volume options are returned from the backend to prevent differences in accept units.
   * 
   * @param {JSON} itemInstanceObject 
   * @param {[string]} weightOptions 
   * @param {[string]} volumeOptions 
   */
  expandSkuInstanceUnits(skuInstanceObject, weightOptions, volumeOptions) {
    let updatedSkuInstance = { ...skuInstanceObject };
    
    if(weightOptions && weightOptions.indexOf(updatedSkuInstance.unit_type) != -1) {
      updatedSkuInstance.weight_ordered_unit = updatedSkuInstance.unit_type;
      updatedSkuInstance.weight_ordered_value = updatedSkuInstance.unit_value;

      updatedSkuInstance.volume_ordered_unit = null;
      updatedSkuInstance.volume_ordered_value = null;
    }
    else if(volumeOptions && volumeOptions.indexOf(updatedSkuInstance.unit_type) != -1){
      updatedSkuInstance.volume_ordered_unit = updatedSkuInstance.unit_type;
      updatedSkuInstance.volume_ordered_value = updatedSkuInstance.unit_value;

      updatedSkuInstance.weight_ordered_unit = null;
      updatedSkuInstance.weight_ordered_value = null;
    }
    else {
      updatedSkuInstance.volume_ordered_unit = null;
      updatedSkuInstance.volume_ordered_value = null;
      updatedSkuInstance.weight_ordered_unit = null;
      updatedSkuInstance.weight_ordered_value = null;
    }
    
    return updatedSkuInstance;
  }

  /**
   * Could be worth finding out how to do something like this, but for now it's not a priority.
   */
  getTransition() {
    return React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });
  }

  dummyFormulationExpandedIngs() {
    return {
      organization: 1,
      name: 'Dummy formula',
      description: 'This is dummy data.',
      unit_type: 2,
      unit_value: 2.3,
      ingredients:[
        {
          pk: 1,
          name: 'First ing',
          unit_value: 2,
          unit_type: {
            pk: 3,
            full_name: 'pound',
            abbreviation: 'lb',
            unit_type: 'WEI',
            ratio_to_base: '453.59',
            spoilage: 0,
            is_base: false,
          },
        },
        {
          pk: 2,
          name: 'Second ing',
          unit_value: 2.5,
          unit_type: {
            pk: 3,
            full_name: 'pound',
            abbreviation: 'lb',
            unit_type: 'WEI',
            ratio_to_base: '453.59',
            spoilage: 0,
            is_base: false,
          },
        },
      ],
    }
  }

  dummyIngredients() {
    return [
      {
        pk: 1,
        name: 'First ing',
        unit_value: 2,
        unit_type: 3,
      },
      {
        pk: 2,
        name: 'Second ing',
        unit_value: 2.5,
        unit_type: 3,
      },
      {
        pk: 3,
        name: 'Third ing',
        unit_value: 1,
        unit_type: 4,
      },
      {
        pk: 4,
        name: 'Fourth ing',
        unit_value: 4.3,
        unit_type: 3,
      },
    ]
  }

  dummySku() {
    return {
      organization: 1,
      traceability: 1,
      formulation: 1,
      code : '123abc',
      name: 'Pinnochio',
      skuId: 'More name fields',
      case_unit_value: 5,
      pallet_unit_value: 25,
      description: 'Wants to someday be a real sku',
      unit_type: 2,
      unit_value: 3.5,
      unit_choices: [2,3,4],
      status: 'ACTI',
      made_in_house: true,
    }
  }

  /**
   * Returns some dummy units objects indexed by pk to use for stories.
   */
  dummyUnitsPkIndexed() {
    return {
    1: {
        pk: 1,
        full_name: 'litre',
        abbreviation: 'l',
        unit_type: 'VOL',
        ratio_to_base: '1',
        spoilage: 0,
        is_base: true,
      },
    2: {
        pk: 2,
        full_name: 'gram',
        abbreviation: 'g',
        unit_type: 'WEI',
        ratio_to_base: '1',
        spoilage: 0,
        is_base: true,
      },
      3: {
        pk: 3,
        full_name: 'pound',
        abbreviation: 'lb',
        unit_type: 'WEI',
        ratio_to_base: '453.59',
        spoilage: 0,
        is_base: false,
      },
      4: {
        pk: 4,
        full_name: 'kilogram',
        abbreviation: 'kg',
        unit_type: 'WEI',
        ratio_to_base: '1000',
        spoilage: 0.35,
        is_base: false,
      },
      5: {
        pk: 5,
        full_name: 'Three Chicken Wings',
        abbreviation: '3cw',
        unit_type: 'WEI',
        ratio_to_base: '155.42',
        spoilage: 2.05,
        is_base: false,
      },
    }
  }

  /**
   * Returns some dummy units objects to use for stories.
   */
  dummyUnits() {
    return [
      {
        pk: 1,
        full_name: 'litre',
        abbreviation: 'l',
        unit_type: 'VOL',
        ratio_to_base: '1',
        spoilage: 0,
        is_base: true,
      },
      {
        pk: 2,
        full_name: 'gram',
        abbreviation: 'g',
        unit_type: 'WEI',
        ratio_to_base: '1',
        spoilage: 0,
        is_base: true,
      },
      {
        pk: 3,
        full_name: 'pound',
        abbreviation: 'lb',
        unit_type: 'WEI',
        ratio_to_base: '453.59',
        spoilage: 0,
        is_base: false,
      },
      {
        pk: 4,
        full_name: 'kilogram',
        abbreviation: 'kg',
        unit_type: 'WEI',
        ratio_to_base: '1000',
        spoilage: 0.35,
        is_base: false,
      },
      {
        pk: 5,
        full_name: 'Three Chicken Wings',
        abbreviation: '3cw',
        unit_type: 'WEI',
        ratio_to_base: '155.42',
        spoilage: 2.05,
        is_base: false,
      },
    ]
  }

  /**
   * Returns some dummy sku objects to use for stories.
   */
  dummyDisplaySkus() {
    return [
      {
        pk: 1,
        name: 'Banana',
        code: 123,
        unit_type: 1,
      },
      {
        pk: 2,
        name: 'Apple',
        code: 456,
        unit_type: 2,
      },
      {
        pk: 4,
        name: 'Orange',
        code: 789,
        unit_type: 1,
      },
      {
        pk: 7,
        name: 'Grape Fruit',
        code: 159,
        unit_type: 4,
      },
    ]
  }

  /**
   * Returns some dummy customer objects to use for stories.
   */
  dummyDisplayCustomers() {
    return [
      {
        pk: 1,
        company: 'Johnny',
        email: '123RealEmail@place.ca',
      },
      {
        pk: 2,
        company: 'Cracker Jack',
        email: '123asdil@place.ca',
      },
      {
        pk: 3,
        company: 'Fido',
        email: '123456@place.ca',
      },
      {
        pk: 6,
        company: 'Appolo',
        email: 'olympus@mountain.com',
      },
    ]
  }

  /**
   * Returns some dummy supplier objects to use for stories.
   */
  dummyDisplaySuppliers() {
    return [
      {
        pk: 1,
        company: 'Joey',
        email: 'dgsdg@place.ca',
      },
      {
        pk: 2,
        company: 'Bow Jack',
        email: 'sdfg@place.ca',
      },
      {
        pk: 3,
        company: 'Rover',
        email: 'cbnv@place.ca',
      },
      {
        pk: 6,
        company: 'Artemis',
        email: 'olympus@mountain.com',
      },
    ]
  }

  /**
   * Returns some dummy container objects to use for stories.
   * --Likely can be removed if containers don't return.
   */
  dummyDisplayContainers() {
    return [
      {
        pk: 1,
        name: 'Tupperware',
      },
      {
        pk: 2,
        name: 'Bottle',
      },
      {
        pk: 3,
        name: 'Bag',
      },
      {
        pk: 6,
        name: 'Box',
      },
      {
        pk: 8,
        name: 'Some Dudes Cupped Hands',
      },
    ]
  }

  /**
   * Returns some dummy internal lot code objects to use for stories.
   */
  dummyDisplayLotCodes() {
    return [
      {
        pk: 2,
        code: 1234,
      },
      {
        pk: 3,
        code: 4567,
      },
      {
        pk: 4,
        code: 'The red crow moos at midday.',
      },
      {
        pk: 6,
        code: 'Yes',
      },
      {
        pk: 7,
        code: 42,
      },
    ]
  }

  /**
   * Returns some dummy inventory location objects to use for stories.
   */
  dummyDisplayInventoryLocations() {
    return [
      {
        pk: 1,
        name: 'Halifax',
      },
      {
        pk: 2,
        name: 'Tuktoyaktuk',
      },
      {
        pk: 3,
        name: 'Albuquerque',
      },
      {
        pk: 6,
        name: 'Volta',
      },
      {
        pk: 8,
        name: 'Some Dudes Backyard',
      },
    ]
  }

  /**
   * Returns a dummy traceability object to use for stories.
   */
  dummyTraceability() {
    return {
      organization: 1,
      name: 'Dummy Traceability',
      description: 'This is a dummy traceability used for debugging',
    }
  }

  /**
   * Returns an object indexed by primary keys from a BE endpoint.
   * 
   * @param {*} listFunction - The endpoint listing function
   * @param {*} setFunction - Function that stores the new object in a variable
   * @param {*} dataMassageFunction - An optional function to format the incoming data
   */
  pkIndexObjectsFromApi(listFunction, setFunction, dataMassageFunction) {
    listFunction().then(e => {
      let pkIndexedObjects = {};

      e.data.forEach((newObject) => {
        //console.log('asd filteredData: ' + JSON.stringify(newObject));
        pkIndexedObjects[newObject.pk] = dataMassageFunction ? {...dataMassageFunction(newObject)} : {...newObject};
      });

      setFunction(pkIndexedObjects);
    }).catch(e => {
      console.log(e);
    })
  }

  idIndexObjectsFromApi(listFunction, setFunction, dataMassageFunction) {
    listFunction().then(e => {
      let idIndexedObjects = {};

      e.data.forEach((newObject) => {
        //console.log('asd filteredData: ' + JSON.stringify(newObject));
        idIndexedObjects[newObject.id] = dataMassageFunction ? {...dataMassageFunction(newObject)} : {...newObject};
      });
      setFunction(idIndexedObjects);
    }).catch(e => {
      console.log(e);
    })
  }

  /**
   * Returns an object indexed by primary keys from a BE endpoint, flattened to return a single field value
   * instead of the whole object.
   * 
   * @param {*} listFunction - The endpoint listing function
   * @param {*} setFunction - Function that stores the new object in a variable
   * @param {*} dataMassageFunction - An optional function to format the incoming data
   */
  pkIndexFieldsFromApi(listFunction, setFunction, fieldKey) {
    listFunction().then(e => {
      let pkIndexedObjects = {};

      e.data.filter(obj => !obj.soft_delete).forEach((newObject) => {
        //console.log('asd filteredData: ' + JSON.stringify(newObject));
        pkIndexedObjects[newObject.pk] = newObject[fieldKey];
      });

      setFunction(pkIndexedObjects);
    }).catch(e => {
      console.log(e);
    })
  }

  /**
   * Finds an object based on pk and returns the value indexed by keyToReturn.
   * todo might be able to improve this using find
   * 
   * @param {*} objects - an array of objects to search
   * @param {*} pk - Primary key to find
   * @param {*} keyToReturn - Field index to return.
   */
  matchOnPk(objects, pk, keyToReturn) {
    for(var i = 0; i < objects.length; i++) {
      if(objects[i].pk == pk) {
        if(keyToReturn == '_index_'){
          return i;
        }
        else if(keyToReturn == '_all_') {
          return objects[i];
        }
        return objects[i][keyToReturn];
      }
    }

    return '';
  }

  matchOnId(objects, id, keyToReturn) {
    for(var i = 0; i < objects.length; i++) {
      if(objects[i].id == id) {
        if(keyToReturn == '_index_'){
          return i;
        }
        else if(keyToReturn == '_all_') {
          return objects[i];
        }
        return objects[i][keyToReturn];
      }
    }

    return '';
  }

  /**
   * Converts a date object from the BE to a readable string
   * @param {*} dateObj 
   */
  dateObjToString(dateObj) {
    if(!dateObj) {
      return '';
    }

    if(typeof(dateObj) == 'object') {
      let date = dateObj.getDate();
      let month = dateObj.getMonth();
      let year = dateObj.getFullYear();

      let formattedMonth = (month < 9) ? '0' + (month + 1) : month + 1;
      let formattedDate = (date < 10) ? '0' + date : date;

      return year + '-' + formattedMonth + '-' + formattedDate;
    }
    else {//Assume it's the weird stringified date from django
      let dateSplit = dateObj.split('T')[0];

      return dateSplit;
    }
  }

  /**
   * Converts a date in string format yyyy-mm-dd to a BE supported date object.
   * @param {*} dateString 
   */
  stringToDateObj(dateString) {
    if(!dateString) {
      return null;
    }

    let splitDate = dateString.split('-');
    let dateObj = new Date();

    dateObj.setDate(splitDate[2]);
    dateObj.setMonth(splitDate[1] - 1);
    dateObj.setFullYear(splitDate[0]);

    return dateObj;
  }

}