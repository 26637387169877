import React, { useEffect, useState, useContext } from "react";
import { useHistory, useRouteMatch, Switch, Route, matchPath } from "react-router-dom";

import API from "../../Api/Api";
import CancelIcon from "@material-ui/icons/Cancel";

import EventNoteSharpIcon from "@material-ui/icons/EventNoteSharp";
import EventAvailableSharpIcon from "@material-ui/icons/EventAvailableSharp";
import AssignmentSharpIcon from "@material-ui/icons/AssignmentSharp";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";

import PlanTreeNavigator from "./PlanTreeNavigator";
import EventManager from "./EventManager";
import TaskManager from "./TaskManager";
import usePermissions from "hooks/usePermissions";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { makeStyles } from "@material-ui/core/styles";
import HaccpPlanList from "../../HACCP/HaccpPlans/HaccpPlanList";
import HaccpPlan from "../../HACCP/HaccpPlans/HaccpPlan";
import useDesktop from "../../hooks/useDesktop";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

const useStyles = makeStyles({
  paper: {
    backgroundColor: "#F8F8F8",
    overflow: "hidden",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Editor(props) {
  const [loading, setLoading] = useState(true);
  const [readOnly, setReadOnly] = useState(true);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const match = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();

  const api = new API().getPlanBuilderAPI();
  const permission = usePermissions();

  // Check user permissions for read access
  useEffect(() => {
    if (permission != null && permission.team_admin) {
      setReadOnly(false);
    } else {
      setReadOnly(true);
    }
  }, [permission]);

  // Get the plan set as active (dashboard)
  const [activePlan, setActivePlan] = useState(null);
  useEffect(() => {
    api.getActiveProgram()
      .then((response) => {
        setActivePlan(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [editorSelected, setEditorSelected] = useState("program");
  useEffect(() => {
    const matchedPath = matchPath(history.location.pathname, {
      path: "/plans/manager/:editor",
      exact: false,
      strict: false,
    });

    if (matchedPath == null) {
      return;
    }

    if (matchedPath.params.editor !== editorSelected) {
      setEditorSelected(matchedPath.params.editor);
    }
  }, [history.location]);

  function selectProgramEditor() {
    setSideMenuOpen(true);
    history.push("/plans/manager/program");
  }

  function selectEventEditor() {
    setSideMenuOpen(true);
    history.push("/plans/manager/events");
  }

  function selectTaskEditor() {
    setSideMenuOpen(true);
    history.push("/plans/manager/tasks");
  }

  function selectHaccpEditor() {
    setSideMenuOpen(true);
    history.push("/plans/manager/haccp_plans");
  }

  const onDesktop = useDesktop();

  const [sideMenuOpen, setSideMenuOpen] = useState(true);

  function openSideMenu() {
    setSideMenuOpen(!sideMenuOpen);
  }
  function closeSideMenu() {
    setSideMenuOpen(!sideMenuOpen);
  }

  return (
    <Dialog classes={{ paper: classes.paper }} fullScreen open={true} TransitionComponent={Transition}>
      <DialogTitle style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", padding: "0px" }}>
        <Grid container item xs={12} style={{ padding: "16px" }}>
          <Grid item xs={8}>
            <Switch>
              <Route path="/plans/manager/program">
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    color: "#687895",
                  }}
                >
                  Program Editor
                </Typography>
              </Route>
              <Route path="/plans/manager/tasks">
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    color: "#687895",
                  }}
                >
                  Task Editor
                </Typography>
              </Route>
              <Route path="/plans/manager/events">
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    color: "#687895",
                  }}
                >
                  Event Editor
                </Typography>
              </Route>
              <Route path="/plans/manager/haccp_plans">
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    color: "#687895",
                  }}
                >
                  HACCP Editor
                </Typography>
              </Route>
            </Switch>
          </Grid>
          <Grid container item xs={4} alignItems="center" justify="flex-end">
            <IconButton
              onClick={() => {
                if (
                  editorSelected === "program" &&
                  hasUnsavedChanges &&
                  !window.confirm(
                    "Are you sure you want to close this page? Any unsaved data will be lost."
                  )
                ) {
                  return;
                }

                history.push("/plans/active");
              }}
              style={{ padding: 0 }}
            >
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>

      <div style={{ display: "flex", width: "100%", height: "100%" }}>
        <div
          style={{
            flex: "0 0 64px",
            width: "100%",
            height: "100%",
            width: "64px",
            overflow: "auto",
            borderRight: "1px solid #eaeaea",
          }}
        >
          <Grid container>
            <Grid container item xs={12} justify="center" alignItems="center" style={{ marginTop: "8px" }}>
              <IconButton
                onClick={selectProgramEditor}
                disabled={editorSelected === "program"}
              >
                <AssignmentSharpIcon />
              </IconButton>
            </Grid>
            <Grid container item xs={12} justify="center" alignItems="center">
              <IconButton
                onClick={selectEventEditor}
                disabled={editorSelected === "events"}
              >
                <EventNoteSharpIcon />
              </IconButton>
            </Grid>
            <Grid container item xs={12} justify="center" alignItems="center">
              <IconButton
                onClick={selectTaskEditor}
                disabled={editorSelected === "tasks"}
              >
                <EventAvailableSharpIcon />
              </IconButton>
            </Grid>
            <Grid container item xs={12} justify="center" alignItems="center">
              <IconButton
                onClick={selectHaccpEditor}
                disabled={editorSelected === "haccp_plans"}
              >
                <ErrorOutlineIcon />
              </IconButton>
            </Grid>
            <Grid container item xs={12} justify="center" alignItems="center" style={{ marginTop: "8px" }}>
              <IconButton onClick={openSideMenu}>
                {sideMenuOpen ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
            </Grid>
          </Grid>
        </div>

        <div style={{ flex: "0 0 calc(100vw - 64px)", width: "100%", height: "100%" }}>
          <Switch>
            <Route path={`${match.url}/program`}>
              <PlanTreeNavigator
                readOnly={readOnly}
                activePlan={activePlan}
                setHasUnsavedChanges={setHasUnsavedChanges}
                user={props.user}
                setActivePlan={setActivePlan}
                sideMenuOpen={sideMenuOpen}
                onDesktop={onDesktop}
                /*closeSideMenu={closeSideMenu}*/
              />
            </Route>
            <Route path={`${match.url}/events`}>
              <EventManager
                readOnly={readOnly}
                activePlan={activePlan}
                setActivePlan={setActivePlan}
                /*closeSideMenu={closeSideMenu}*/
                sideMenuOpen={sideMenuOpen}
              />
            </Route>
            <Route path={`${match.url}/tasks`}>
              <TaskManager
                readOnly={readOnly}
                activePlan={activePlan}
                setActivePlan={setActivePlan}
                /*closeSideMenu={closeSideMenu}*/
                sideMenuOpen={sideMenuOpen}
              />
            </Route>
            <Route exact path={`${match.url}/haccp_plans`}>
              <HaccpPlanList
                readOnly={readOnly}
                activePlan={activePlan}
                setActivePlan={setActivePlan}
                sideMenuOpen={sideMenuOpen}
                /*closeSideMenu={closeSideMenu}*/
              />
            </Route>
            <Route path={`${match.url}/:pk`}>
              <HaccpPlan drawerOpen={false} sideMenuOpen={sideMenuOpen} /*closeSideMenu={closeSideMenu}*/ />
            </Route>
          </Switch>
        </div>
      </div>
    </Dialog>
  );
}
