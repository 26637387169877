import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {
  BrowserRouter as Router,
} from "react-router-dom";

import TagManager from 'react-gtm-module'

import { DEPLOY_PRODUCTION } from 'Api/Api';

import './PrototypeUtils.js';

if (DEPLOY_PRODUCTION) {
	const tagManagerArgs = {
    gtmId: 'GTM-T62SQW7',
	};
	TagManager.initialize(tagManagerArgs)
}

//window.onbeforeunload = () => {
//	localStorage.setItem("next_url", window.location.pathname)
//};


ReactDOM.render(<Router><App/></Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
