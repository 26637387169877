import React from "react";
import { Route, Redirect } from "react-router-dom";

export default function AdminRoute({ children, ...rest }) {

  const to = "/plans";

  return (
    <Route {...rest} render={({ location }) => {
      if (!rest.user) {
        return <Redirect to={{ pathname: to}}/>
      }

      let user = rest.user.user;
      if (user && !user.is_superuser) {
        return <Redirect to={{ pathname: to}}/>
      }
      else if (user == null) {
        return <Redirect to={{ pathname: to}}/>
      }
      else {
        return (children);
      }
    }} />);
}
