import React, {useEffect, useState} from "react";
import API from "../../Api/Api";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";


export default function HazardDatasetTable(props) {
  const hazardColumns = [
    {title: "Name", field: "name"},
    {title: "Type", field: "type_name"},
  ];

  return (
    <div style={{maxWidth: "100%"}}>
      <MaterialTable components={{Container: props => <Paper {...props} elevation={0}/>}}
                     title="Hazards"
                     columns={hazardColumns}
                     data={props.hazards}
                     onSelectionChange={(rows) => {
                       if (props.setHazardsSelected) {
                         props.setHazardsSelected(rows);
                       }
                     }}
                     options={{
                       selection: true
                     }}
      />
    </div>
  )
}
