import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams, Prompt } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { StylesContext } from "App";
import { BackButton } from "Components/BackButton";
import { OrgContext } from "hooks/useOrganization"
import useDesktop from "../../hooks/useDesktop";

import CrossContaminationPointSelectTable from "./CrossContaminationPointSelectTable";
import HazardDatasetSelectTable from "./HazardDatasetSelectTable";
import HazardDetails from "./HazardDetails";
import IngredientSelectTable from "./IngredientSelectTable";
import ProcessSelectTable from "./ProcessSelectTable";

import Message from "Components/Message";
import VerticalDotMenu from "Components/VerticalDotMenu";

import API from "../../Api/Api";

function Hazard(props) {
  const classes = React.useContext(StylesContext);
  const org = useContext(OrgContext);
  const onDesktop = useDesktop();

  const [hazard, setHazard] = useState(null);
  const [blockNavigation, setBlockNavigation] = useState(false);
  const [fieldError, setFieldError] = useState(false);

  const [loading, setLoading] = useState(true);

  let api = new API();

  function getHazardAPI() {
    if (props.templateMode) {
      return api.getHazardTemplateAPI();
    }

    return api.getHazardAPI();
  }

  function handleChange(event) {
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }
    setBlockNavigation(true);
    let property = event.target.name;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    setHazard({ ...hazard, [property]: value });
  }

  const [isNewHazard, setIsNewHazard] = useState(false);
  useEffect(() => {
    let pk = props.pk;

    setLoading(true);

    if (props.open && pk != null) {
      setIsNewHazard(false);
      getHazard(pk);
    } else {
      setHazard({});
      setIngredients([]);
      setProcesses([]);
      setCrossContaminationPoints([]);
      setIsNewHazard(true);

      setLoading(false);
    }
  }, [props.pk, props.open]);

  function getHazard(pk) {
    getHazardAPI()
      .retrieveHazard(pk)
      .then((response) => {
        setLoading(false);
        setHazard(response.data);
        setIngredients(response.data.ingredients_read);
        setProcesses(response.data.processes_read);
        setCrossContaminationPoints(
          response.data.cross_contamination_points_read
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async function addNewHazard(hazard) {
    let newHazard = {
      ...hazard,
      haccp_plan: props.haccpPlanPk,
      organization: org.pk,
      ingredients: ingredients.map((ingredient) => ingredient.pk),
      processes: processes.map((process) => process.pk),
      cross_contamination_points: crossContaminationPoints.map(
        (crossContaminationPoint) => crossContaminationPoint.pk
      ),
    };

    return api
      .getHazardAPI()
      .createHazard(newHazard)
      .then((response) => {
        setHazard(response.data);
        setIsNewHazard(false);
        return response;
      })
      .catch((error) => {
        console.log("error");
      });
  }

  async function save() {
    if (!hazard.name) {
      setFieldError(true);
      setReturnStatus("Please Enter A Name For This Hazard", "info");
    } else {
      if (hazard.significant && !hazard.justification) {
        setReturnStatus("Please Enter A Justification For This Hazard", "info");
      } else {
        setFieldError(false);
        setBlockNavigation(false);
        if (props.setShouldClose) {
          props.setShouldClose(false);
        }

        if (isNewHazard) {
          setReturnStatus("Hazard Created!", "info");
          await addNewHazard(hazard);
        } else {
          setReturnStatus("Hazard Saved!", "info");
          let ret = await updateHazard(hazard);
          if (ret === false) {
            return;
          }
        }

        if (props.save) {
          props.save(hazard);
        }
      }
    }
  }

  useEffect(() => {
    if (!props.open) {
      setOpen(false);
    }
  }, [props.open]);

  async function saveAndContinue(e) {
    if (!hazard.name) {
      setFieldError(true);
      setReturnStatus("Please Enter A Name For This Hazard", "info");
    } else {
      if (hazard.significant && !hazard.justification) {
        setReturnStatus("Please Enter A Justification For This Hazard", "info");
      } else {
        setFieldError(false);
        setBlockNavigation(false);
        if (props.setShouldUpdate) {
          props.setShouldUpdate(true);
        }

        if (isNewHazard) {
          await addNewHazard(hazard);
        } else {
          let ret = await updateHazard(hazard);
          if (ret === false) {
            return;
          }
        }

        if (props.saveAndContinue) {
          await props.saveAndContinue(hazard);
        }
      }
    }
  }

  async function updateHazard(updatedHazard) {
    let changedHazard = {
      ...updatedHazard,
      type: updatedHazard.type[0],
      ingredients: ingredients.map((ingredient) => ingredient.pk),
      processes: processes.map((process) => process.pk),
      cross_contamination_points: crossContaminationPoints.map(
        (crossContaminationPoint) => crossContaminationPoint.pk
      ),
    };

    if (
      changedHazard.justification === "" &&
      changedHazard.significant === true &&
      !props.templateMode
    ) {
      setReturnStatus(
        "Please enter a justification for the significant hazard.",
        "error"
      );
      return false;
    } else {
      return getHazardAPI()
        .updateHazard(changedHazard)
        .then((e) => {
          setHazard(e.data);
          if (props.setShouldUpdate) {
            props.setShouldUpdate(true);
          }
        })
        .catch((e) => {
          setReturnStatus("Could not update hazard.", "error");
        });
    }

    return false;
  }

  function selectTemplate(template) {
    getHazardAPI()
      .selectTemplate(hazard.pk, template)
      .then((e) => {
        setHazard(e.data);
        setReturnStatus("Template selected!", "info");
      })
      .catch((e) => {
        setReturnStatus("Could not select template.", "error");
      });
  }

  function createTemplate() {
    api
      .getHazardTemplateAPI()
      .createHazard(hazard)
      .then((e) => {
        setReturnStatus("Template created!", "info");
      })
      .catch((e) => {
        setReturnStatus("Could not create template.", "error");
      });
  }

  function getTemplates() {
    return api.getHazardTemplateAPI().listHazards();
  }

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState({
    message: "",
    status: "info",
  }); // error, warning, info, info

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  function setReturnStatus(message, status) {
    setMessage({ message: message, status: status });
    setOpen(true);
  }

  const [ingredients, setIngredients] = useState([]);

  function addIngredient(ingredient) {
    setBlockNavigation(true);

    let updatedIngredients = [...ingredients];

    let index = updatedIngredients.findIndex((instance) => {
      return instance.pk === ingredient.pk;
    });

    if (index > -1) {
      updatedIngredients.splice(index, 1);
    } else {
      updatedIngredients.push(ingredient);
    }

    setIngredients(updatedIngredients);
  }

  function removeIngredient(ingredient) {
    setIngredients(
      ingredients.filter((instance) => instance.pk !== ingredient.pk)
    );
  }

  const [processes, setProcesses] = useState([]);

  function addProcess(process) {
    setBlockNavigation(true);

    let updatedProcesses = [...processes];

    let index = updatedProcesses.findIndex((instance) => {
      return instance.pk === process.pk;
    });

    if (index > -1) {
      updatedProcesses.splice(index, 1);
    } else {
      updatedProcesses.push(process);
    }

    setProcesses(updatedProcesses);
  }

  function removeProcess(process) {
    setProcesses(processes.filter((instance) => instance.pk !== process.pk));
  }

  const [crossContaminationPoints, setCrossContaminationPoints] = useState([]);

  function addCrossContaminationPoint(crossContaminationPoint) {
    setBlockNavigation(true);

    let updatedCrossContaminationPoints = [...crossContaminationPoints];

    let index = updatedCrossContaminationPoints.findIndex(
      (cross_contamination_point) => {
        return cross_contamination_point.pk === crossContaminationPoint.pk;
      }
    );

    if (index > -1) {
      updatedCrossContaminationPoints.splice(index, 1);
    } else {
      updatedCrossContaminationPoints.push(crossContaminationPoint);
    }

    setCrossContaminationPoints(updatedCrossContaminationPoints);
  }

  function removeCrossContaminationPoint(crossContaminationPoint) {
    setCrossContaminationPoints(
      crossContaminationPoints.filter(
        (instance) => instance.pk !== crossContaminationPoint.pk
      )
    );
  }

  window.onbeforeunload = function (e) {
    if (blockNavigation) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  function cancel() {
    //setShouldClose(false);
    if (props.cancel) {
      props.cancel();
    }
  }

  const [cfiaDatabaseOpen, setCFIADatabaseOpen] = useState(false);
  function handleCFIADatabaseClosed() {
    setCFIADatabaseOpen(false);
  }

  function handleCFIADatabaseOpen() {
    setCFIADatabaseOpen(true);
  }

  function selectHazardDataset(hazard) {
    selectCFIAHazard(hazard);
  }

  function selectCFIAHazard(cfiaHazard) {
    if (cfiaHazard == null) {
      return;
    }

    setLoading(true);

    if (isNewHazard) {
      addNewHazard().then((response) => {
        let hazard = response.data;
        api
          .getHazardAPI()
          .selectDatasetHazard(cfiaHazard, hazard)
          .then((response) => {
            handleCFIADatabaseClosed();
            setHazard(response.data);
            setLoading(false);
            setReturnStatus("Selected CFIA Hazard!", "info");
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      });
    } else {
      api
        .getHazardAPI()
        .selectDatasetHazard(cfiaHazard, hazard)
        .then((response) => {
          handleCFIADatabaseClosed();
          setHazard(response.data);
          setLoading(false);
          setReturnStatus("Selected CFIA Hazard!", "info");
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }

  return (
    <div>
      <Prompt
        when={blockNavigation}
        message="You have unsaved changes, are you sure you want to leave?"
      /> 
      <Dialog
        open={props.open}
        onClose={props.handleModalClosed}
        fullWidth={onDesktop}
        maxWidth={onDesktop ? "md" : false}
        fullScreen={!onDesktop}
        style={{overflowX: "hidden"}}
      >
        <DialogContent style={{overflowX: "hidden"}} className={classes.generalFormDialogueContainer}>
          {!loading && (
            <form onSubmit={save}>
              <Grid container style={{ padding: "24px" }} spacing={3}>
                {!props.hasButton && <BackButton />}
                <Grid item xs={!props.hasButton ? 10 : 11}>
                  <Typography variant="h5" noWrap>
                    Hazard {props.templateMode && "Template"}
                  </Typography>
                  <Typography>
                    Edit/view information about this hazard.
                  </Typography>
                </Grid>
                {!props.templateMode && (
                  <Grid item xs={1}>
                    <VerticalDotMenu
                      getTemplates={getTemplates}
                      createTemplate={createTemplate}
                      selectTemplate={selectTemplate}
                      setHazard={setHazard}
                      setStatus={setReturnStatus}
                      api={api.getHazardAPI()}
                      instance={hazard}
                      template_title="Hazard Templates"
                      template_cols={[
                        { title: "Name", field: "name" },
                        {
                          title: "Description",
                          field: "description",
                        },
                        {
                          title: "Type",
                          field: "type_name",
                          editable: "never",
                        },
                      ]}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={handleCFIADatabaseOpen}
                    variant={"contained"}
                    color={"primary"}
                  >
                    Add from CFIA Database
                  </Button>
                  <Dialog
                    open={cfiaDatabaseOpen}
                    onClose={handleCFIADatabaseClosed}
                    maxWidth={"lg"}
                    fullWidth={true}
                  >
                    <DialogContent
                      className={classes.generalFormDialogueContainer}
                    >
                      <HazardDatasetSelectTable
                        selectHazard={selectHazardDataset}
                        cancel={handleCFIADatabaseClosed}
                      />
                    </DialogContent>
                  </Dialog>
                </Grid>
                <Grid item xs={12}>
                  <HazardDetails
                    hazard={hazard}
                    handleChange={handleChange}
                    fieldError={fieldError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <IngredientSelectTable
                    org={org}
                    haccpPlanPk={props.haccpPlanPk}
                    ingredients={ingredients}
                    addIngredient={addIngredient}
                    removeIngredient={removeIngredient}
                    setBlockNavigation={setBlockNavigation}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ProcessSelectTable
                    haccpPlanPk={props.haccpPlanPk}
                    processes={processes}
                    addProcess={addProcess}
                    removeProcess={removeProcess}
                    setBlockNavigation={setBlockNavigation}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CrossContaminationPointSelectTable
                    haccpPlanPk={props.haccpPlanPk}
                    crossContaminationPoints={crossContaminationPoints}
                    addCrossContaminationPoint={addCrossContaminationPoint}
                    removeCrossContaminationPoint={
                      removeCrossContaminationPoint
                    }
                    setBlockNavigation={setBlockNavigation}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </DialogContent>
        <Divider />
        {!loading && (
          <DialogActions
            style={{ justifyContent: "flex-end", paddingLeft: "24px" }}
          >
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: "8px" }}
              onClick={cancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "8px" }}
              onClick={save}
            >
              Save Changes
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "8px" }}
              onClick={saveAndContinue}
            >
              Save and Continue
            </Button>
          </DialogActions>
        )}{" "}
        <Message
          position={true}
          open={open}
          message={message.message}
          severity={message.status}
          vertical="bottom"
          horizontal="right"
          handleClose={handleClose}
        />
      </Dialog>

      {loading && (
        <div style={{ margin: "32px" }}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default Hazard;
