class HazardAPI {
  constructor(api, version, name) {
    this.api = api;
    this.version = version;
    this.name = "HazardAPI";
  }

  listHazards(haccpPlanPk = null) {
    if (haccpPlanPk) {
      return this.api.get(
        `${this.version}/hazards/?haccp_plan_pk=${haccpPlanPk}`
      );
    }

    return this.api.get(`${this.version}/hazards/`);
  }

  //This is working, but it doesn't give components (joins, slower to get) so we can't use it for the list.
  //Useful for any case where you want to quickly display name, type, signifcant, etc. basic info.
  listHazardsMinimizedJoins(haccpPlanPk = null) {
    if (haccpPlanPk) {
      return this.api.get(
        `${this.version}/hazards/get_min_list/?haccp_plan_pk=${haccpPlanPk}`
      );
    }

    return this.api.get(`${this.version}/hazards/get_min_list/`);
  }

  //This lists the raw storage from the database as JSON. Faster than getting the django model back, but
  //That means no joins by default but you can select_related and prefetch.
  listHazardsDictionary() {
    return this.api.get(`${this.version}/hazards/get_dict_list/`);
  }

  listIngredientHazards(ingredient_pk) {
    return this.api.get(
      `${this.version}/hazards/?ingredient_pk=${ingredient_pk}`
    );
  }

	listAllIngredientHazards(haccpPlanPk) {
		return this.api.get(`${this.version}/hazards/?haccp_plan_pk=${haccpPlanPk}&ing=true`);
	}

  listProcessHazards(process_pk) {
    return this.api.get(`${this.version}/hazards/?process_pk=${process_pk}`);
  }

  listCrossContaminationPointHazards(cross_contamination_point_pk) {
    return this.api.get(
      `${this.version}/hazards/?cross_contamination_point_pk=${cross_contamination_point_pk}`
    );
  }

  listSignificantHazards(haccp_plan_pk) {
    if (haccp_plan_pk == null) {
      throw "HACCP plan pk can not be null";
    }

    return this.api.get(
      `${this.version}/hazards/significant/?haccp_plan_pk=${haccp_plan_pk}`
    );
  }

  getProcesses(hazardPk) {
    return this.api.get(`${this.version}/hazards/${hazardPk}/get_processes/`);
  }

  getIngredients(hazardPk) {
    return this.api.get(`${this.version}/hazards/${hazardPk}/get_ingredients/`);
  }

  getCrossContaminationPoints(hazardPk) {
    return this.api.get(
      `${this.version}/hazards/${hazardPk}/get_cross_contamination_points/`
    );
  }

  createHazard(payload) {
    return this.api.post(`${this.version}/hazards/`, payload);
  }

  retrieveHazard(pk) {
    return this.api.get(`${this.version}/hazards/${pk}/`);
  }

  updateHazard(payload) {
    return this.api.put(`${this.version}/hazards/${payload.pk}/`, payload);
  }

  deleteHazard(pk) {
    return this.api.delete(`${this.version}/hazards/${pk}/`);
  }

  selectTemplate(pk, template) {
    return this.api.post(
      `${this.version}/hazards/${pk}/select_template/`,
      template
    );
  }

  selectDatasetHazard(hazardSelected, hazard) {
    let payload = { hazard_selected: hazardSelected };
    return this.api.post(
      `${this.version}/hazards/${hazard.pk}/select_dataset/`,
      payload
    );
  }

  getHistory(pk) {
    return this.api.get(`${this.version}/hazards/${pk}/get_history/`);
  }

  getRevertedHazard(pk, date) {
    return this.api.post(
      `${this.version}/hazards/${pk}/get_reverted_hazard/`,
      date
    );
  }
}

export default HazardAPI;
