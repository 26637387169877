import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DiagramDrawer from '../DiagramDrawer/DiagramDrawer';
import Message from "../../Components/Message";
import { Switch, Route } from 'react-router-dom';
import { createStore } from "redux";
import { Provider } from "react-redux";
import API from "../../Api/Api";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid";
import {saveDiagram} from "../DiagramDrawer/DiagramUpdater";
import Typography from "@material-ui/core/Typography";

const initialState = {
  count: 55,
  index: 0,
  history: [],
  value: "",
  event: "",
  curState: [],
  past: [],
  present: {},
  future: [],
  actions: []
};

function reducer(state = initialState, action) {
  const { past, present, future, actions } = state;
  const index = state;
  switch (action.type) {

    case "undo":
      if (actions.length > 0 && past.length > 0) {
        return {
          ...state,
          future: state.future.concat(state.present),
          present: state.past[state.past.length - 1],
          past: state.past.slice(0, state.past.length - 1),
        }
      } else {
        return state;
      }
    case "deletedProcessGroup":
      if (actions.length > 0 && past.length > 0) {
        return {
          ...state,
          future: state.future.concat(state.past[state.past.length - 1]),
          present: state.past[state.past.length - 2],
          past: state.past.slice(0, state.past.length - 2),
        }
      } else {
        return state;
      }
    case "redo":
      if (actions.length > 0 && future.length > 0) {
        return {
          ...state,
          past: state.past.concat(state.present),
          present: state.future[state.future.length - 1],
          future: state.future.slice(0, state.future.length - 1),
        }
      } else {
        return state;
      }
    case "addToCurrent":
      return {
        ...state,
        count: state.count - 1,
        history: state.history.concat({ id: Math.random(), count: state.count - 1 }),
        curState: state.curState.concat({ id: Math.random(), curState: action.value }),
      };
    case "addAction":
      return {
        ...state,
        past: state.past.concat(state.present),
        actions: state.actions.concat({ id: Math.random(), event: action.event, actions: action.value }),
        present: { id: Math.random(), event: action.event, present: action.value },
      };
    default:
      return state;

  }
}

const store = createStore(reducer);

function ProcessFlowDiagram(props) {
  const [status, setStatus] = useState({ message: null, severity: 'success' });
  const [messageOpen, setMessageOpen] = useState(false);
  const [processFlow, setProcessFlow] = useState(null);
  const [loadingProcessFlow, setLoadingProcessFlow] = useState(true);
  const [loadingProcesses, setLoadingProcesses] = useState(true);
  const [processes, setProcesses] = useState([]);

  let params = useParams();

  useEffect(() => {
    let pk = props.pk || params.pk || null;
    new API().getProcessFlowDiagramAPI()
      .retrieveProcessFlowDiagram(pk)
      .then((e) => {
        let processFlow = e.data;
        setProcessFlow(processFlow);
        setLoadingProcessFlow(false);
      })
      .catch(() => {
        setStatus({
          message: "Could not retrieve process flow diagram.",
          severity: "error",
        });
      });


    new API().getProcessAPI().listProcesses(props.haccpPlan.pk).then((e) => {
      setProcesses(e.data);
      setLoadingProcesses(false);
    })
  }, [props.pk]);

  useEffect(() => {
    if (status.message) {
      setMessageOpen(true);
    }
  }, [status]);

  
  function createTemplate(updatedProcessFlow) {
    new API().getProcessFlowDiagramTemplateAPI()
      .createProcessFlowDiagramTemplate(updatedProcessFlow)
      .then(() => {
        setStatus({
          message: "Template created!",
          severity: "info",
        });
      })
      .catch(() => {
        setStatus({
          message: "Could not create template.",
          severity: "error",
        });
      });
  }

  return (
    <div id="process_flow" style={{ width: '100%', height: "100%" }}>
      <Switch>
        <Route exact>
          <Provider store={store}>
            {loadingProcessFlow && loadingProcesses && !props.haccpPlan && !processFlow &&
              <Grid container direction="column" justify="space-between" alignItems="center">
                <Grid item style={{ marginTop: '250px' }}>
                  <CircularProgress />
                </Grid>
              </Grid>
            }

            {!loadingProcessFlow && !loadingProcesses && props.haccpPlan && processFlow && !props.readOnlyMode &&
              <DiagramDrawer
                CcpAPI={new API().getCcpAPI()}
                processAPI={new API().getProcessAPI()}
                processFlowAPI={new API().getProcessFlowDiagramAPI()}
                createTemplate={createTemplate}
                setProcessFlow={setProcessFlow}
                saveDiagram={saveDiagram}
                processFlow={processFlow}
                haccpPlan={props.haccpPlan}
                processes={processes}
                setIsBlocking={props.setIsBlocking}
              />
            }
            {!loadingProcessFlow && !loadingProcesses && props.haccpPlan && processFlow.saved_image && props.readOnlyMode &&
              <img src={processFlow.saved_image}></img>
            }
            {!loadingProcessFlow && !loadingProcesses && props.haccpPlan && !processFlow.saved_image && props.readOnlyMode &&
                <Grid container direction="column" justify="space-between" alignItems="center">
                <Grid item style={{ marginTop: '250px' }}>
                <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#687895",
                        }}
                      >
                        There is no saved digram for this HACCP Plan
                      </Typography>
                </Grid>
              </Grid>
            }

            <Message
              open={messageOpen}
              message={status.message}
              severity={status.severity}
              vertical="bottom"
              horizontal="right"
              handleClose={() => {
                setMessageOpen(false)
              }}
            />

          </Provider>
        </Route>
      </Switch>
    </div >
  )
}

export default ProcessFlowDiagram;
