class InstanceItemAPI {
  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listItemInstancesWithJoins(sku_instance_pk) {
    if (typeof sku_instance_pk === 'number') {
      return this.api.get(`${this.version}/item_instances_display/${sku_instance_pk}/`);
    }

    return this.api.get(`${this.version}/item_instances_display/`)
  }

  listItemInstances(item_instance_pk) {
    if (typeof item_instance_pk === 'number') {
      return this.api.get(`${this.version}/item_instances/${item_instance_pk}/`);
    }

    return this.api.get(`${this.version}/item_instances/`)
  }

  listInventoryByIngredients(ingredientPkList) {
    return this.api.post(`${this.version}/item_instances/list_ingredient_inventory/`, ingredientPkList)
  }

  listInventoryByIngredientsSkuUnits(ingredientPkList) {
    return this.api.post(`${this.version}/item_instances/list_ingredient_inventory_sku_units/`, ingredientPkList)
  }

  listFinishedInventory() {

    return this.api.get(`${this.version}/item_instances/list_finished_inventory/`)
  }

  listFinishedInventorySkuUnits() {

    return this.api.get(`${this.version}/item_instances/list_finished_inventory_sku_units/`)
  }

  listFinishedInventoryByLotCode() {

    return this.api.get(`${this.version}/item_instances/list_finished_inventory_by_lot_code/`)
  }

  listFinishedInventoryByLotCodeSkuUnits() {

    return this.api.get(`${this.version}/item_instances/list_finished_inventory_by_lot_code_sku_units/`)
  }

  listRawInventory() {

    return this.api.get(`${this.version}/item_instances/list_raw_inventory/`)
  }

  listRawInventorySkuUnits() {

    return this.api.get(`${this.version}/item_instances/list_raw_inventory_sku_units/`)
  }

  createItemInstance(payload) {
    return this.api.post(`${this.version}/item_instances/`, payload);
  }

  updateItemInstance(payload) {
    return this.api.put(`${this.version}/item_instances/${payload.pk}/`, payload);
  }

  deleteItemInstance(pk) {
    return this.api.delete(`${this.version}/item_instances/${pk}/`);
  }

}

export default InstanceItemAPI;