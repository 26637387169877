import React, { useState } from "react";
import API from "../../Api/Api";
import { useRouteMatch, useParams } from "react-router-dom";
import { Typography, Grid, Button, Divider } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Message from "../../Components/Message";
import FoodbyteLogo from "../../logos/FB_Logo_Reversed_RGB_Icon.png";
import ContentHeader from "../../Components/Layouts/ContentHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

export default function NewProfile(props) {
  const classes = useStyles();
  const currentParams = useParams();
  const api = new API();
  const [emailChangeOpen, setEmailChangeOpen] = useState(false);
  const [emailChangeData, setEmailChangeData] = useState({
    email: props.user.user.email,
    password: null,
  });
  const [passwordChangeOpen, setPasswordChangeOpen] = useState(false);
  const [avatarChangeOpen, setAvatarChangeOpen] = useState(false);
  const [emailErrors, setEmailErrors] = useState(null);
  const [passwordErrors, setPasswordErrors] = useState(null);
  const [nameChangeOpen, setNameChangeOpen] = useState(false);
  const [profileData, setProfileData] = useState({
    first_name: props.user.user.first_name,
    last_name: props.user.user.last_name,
    password: null,
    profile_image: null,
  });
  const [firstNameErrors, setFirstNameErrors] = useState(null);
  const [lastNameErrors, setLastNameErrors] = useState(null);
  const [firstName, setFirstName] = useState(props.user.user.first_name);
  const [lastName, setLastName] = useState(props.user.user.last_name);

  function handleEmailChange() {
    api
      .getAuthAPI()
      .changeEmailRequest(emailChangeData)
      .then(() => {
        setEmailChangeOpen(false);
        setEmailErrors(null);
        setPasswordErrors(null);
      })
      .catch((e) => {
        setEmailErrors(e.response.data.email);
        setPasswordErrors(e.response.data.password);
      });
  }

  function handlePasswordReset() {
    api
      .getAuthAPI()
      .resetPasswordRequest()
      .then(() => {
        setPasswordChangeOpen(false);
      })
      .catch((e) => {});
  }

  function saveChanges() {
    setFirstNameErrors(false);
    setLastNameErrors(false);
    handleNameChange();
    if (changedAvatar) {
      handleProfileImageUpload();
    }
  }

  let formData = new FormData(document.forms[0]);
  function handleProfileImageUpload() {
    let upDatedUser = {
      pk: props.user.user.pk,
      username: props.user.user.username,
      email: props.user.email,
      first_name: props.user.user.first_name,
      last_name: props.user.user.last_name,
      is_superuser: props.user.user.is_superuser,
      last_login: props.user.user.last_login,
      date_joined: props.user.user.date_joined,
    };
    formData.append("profile_image", avatarFile);

    api
      .getOrganizationAPI()
      .updateProfileImage(upDatedUser, formData)
      .then((e) => {
        setReturnStatus("User Information Updated!", "info");
        window.location.reload();
      })
      .catch((e) => {
        setReturnStatus("Could not update information.", "error");
      });
  }
  function handleNameChange() {
    api
      .getAuthAPI()
      .changeName(profileData)
      .then((response) => {
        setReturnStatus("Profile Saved!", "info");
        setPasswordChangeOpen(false);
        setNameChangeOpen(false);
      })
      .catch((e) => {
        setFirstNameErrors(e.response.data.first_name);
        setLastNameErrors(e.response.data.last_name);
      });
  }

  function handleEmailOpen(event) {
    setEmailChangeOpen(true);
  }

  function handleEmailDataChange(event) {
    const name = event.target.name;
    setEmailChangeData({
      ...emailChangeData,
      [name]: event.target.value,
    });
  }

  function handleNameDataChange(event) {
    const name = event.target.name;
    setProfileData({
      ...profileData,
      [name]: event.target.value,
    });
  }

  function handleEmailChangeClose() {
    setEmailChangeOpen(false);
    setEmailChangeData({ email: null, password: null });
  }

  const [newAvatar, setNewAvatar] = useState(null);
  const [avatarFile, setAvatarFile] = useState(null);
  const [changedAvatar, setChangedAvatar] = useState(false);

  function handleFileUpload(event) {
    let newAvatarData = URL.createObjectURL(event.target.files[0]);
    setAvatarFile(event.target.files[0]);
    setProfileData({ ...profileData, profile_image: event.target.files[0] });
    setNewAvatar(newAvatarData);
    setChangedAvatar(true);
  }

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState({
    message: "",
    status: "info",
  }); // error, warning, info, info
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function setReturnStatus(message, status) {
    setMessage({ message: message, status: status });
    setOpen(true);
  }

  let match = useRouteMatch();
  return (
    <div style={{ overflowX: "hidden" }}>
			<ContentHeader title="Profile" />
      <Grid container spacing={1} style={{ margin: "32px"}}>
        <Grid item xs={12}>
          <Button
            size="small"
            variant="text"
            style={{
              color: "#18BFF6",
              padding: 0,
              fontFamily: "Roboto",
              fontSize: "14px",
            }}
          >
            Account Details
          </Button>
        </Grid>
        <Grid item xs={11}>
          <Divider
            style={{ paddingRight: "32px", marginBottom: "8px" }}
          ></Divider>
        </Grid>{" "}
        <Grid item xs={1}></Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            style={{
              color: "grey",
              fontFamily: "Roboto",
              fontSize: "14px",
              letterSpacing: "0.1px",
            }}
          >
            Name
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={firstNameErrors ? true : false}
            margin="dense"
            id="first_name"
            name="first_name"
            variant="outlined"
            defaultValue={
              props.user.user.first_name ? props.user.user.first_name : ""
            }
            onChange={handleNameDataChange}
            style={{
              maxWidth: 194,
              backgroundColor: "white",
              marginBottom: "16px",
              marginRight: "12px",
            }}
            helperText={firstNameErrors}
          />

          <TextField
            error={lastNameErrors ? true : false}
            margin="dense"
            id="last_name"
            name="last_name"
            variant="outlined"
            defaultValue={
              props.user.user.last_name ? props.user.user.last_name : ""
            }
            onChange={handleNameDataChange}
            style={{
              maxWidth: 194,
              backgroundColor: "white",
              marginBottom: "16px",
            }}
            helperText={lastNameErrors}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            style={{ color: "grey", fontFamily: "Roboto", fontSize: "14px" }}
          >
            Email
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={emailErrors ? true : false}
            margin="dense"
            id="email"
            name="email"
            variant="outlined"
            disabled
            defaultValue={props.user.user.email ? props.user.user.email : ""}
            style={{
              minWidth: 400,
              backgroundColor: "white",
            }}
            helperText={emailErrors}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            size="small"
            variant="text"
            style={{
              color: "#9798B0",
              borderColor: "#18bff6",
              fontFamily: "Roboto",
              fontSize: "14px",
              padding: 0,
            }}
            onClick={handleEmailOpen}
          >
            Change Email
          </Button>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "12px" }}>
          <Typography
            variant="body1"
            style={{
              color: "grey",
              fontFamily: "Roboto",
              fontSize: "14px",
              letterSpacing: "0.1px",
            }}
          >
            Password
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            id="password"
            name="password"
            variant="outlined"
            defaultValue={"*******"}
            disabled
            // onChange={handlePasswordChange}
            style={{
              minWidth: 400,
              backgroundColor: "white",
            }}
            helperText={emailErrors}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            size="small"
            variant="text"
            style={{
              color: "#9798B0",
              borderColor: "#18bff6",
              fontFamily: "Roboto",
              fontSize: "14px",
              padding: 0,
              letterSpacing: "0.1px",
            }}
            onClick={() => setPasswordChangeOpen(true)}
          >
            Reset Password
          </Button>
        </Grid>
        <Grid container item xs={12}>
            <Grid item xs={12} style={{padding: '8px 8px 8px 0px'}}>
              <a href="https://www.joinfoodbyte.com/privacy-policy" target="_blank">Privacy Policy</a>
            </Grid>
            <Grid item xs={12} style={{padding: '8px 8px 8px 0px'}}>
              <a href="https://www.joinfoodbyte.com/terms-services" target="_blank">Terms of Service</a>
            </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            style={{
              marginTop: "8px",
              color: "grey",
              fontFamily: "Roboto",
              fontSize: "14px",
              letterSpacing: "0.1px",
            }}
          >
            Avatar
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {props.user.profile_image && !newAvatar && (
            <img
              src={props.user.profile_image ? props.user.profile_image : ""}
              width="64"
              height="64"
            ></img>
          )}
          {!props.user.profile_image && !newAvatar && (
            <img src={FoodbyteLogo} width="64" height="64" />
          )}
          {newAvatar && <img src={newAvatar} width="64" height="64" />}
        </Grid>
        <Grid item xs={12}>
          <input
            className={classes.input}
            accept="image/*"
            id="contained-button-file"
            multiple
            type="file"
            onChange={(file) => handleFileUpload(file)}
          />
          <label htmlFor="contained-button-file">
            <Button
              size="small"
              variant="text"
              style={{
                color: "#9798B0",
                borderColor: "#18bff6",
                fontFamily: "Roboto",
                fontSize: "14px",
                padding: 0,
                letterSpacing: "0.1px",
              }}
              component="span"
              // onClick={(file) => handleFileUpload(file)}
            >
              Change Avatar
            </Button>
          </label>
        </Grid>
        <Grid item xs={11}>
          <Divider
            style={{ paddingRight: "32px", marginBottom: "16px" }}
          ></Divider>
        </Grid>{" "}
        <Grid item xs={1}></Grid>
        <Grid item xs={12}>
          <Button
            size="medium"
            variant="contained"
            style={{
              backgroundColor: "#18bff6",
              borderColor: "#18bff6",
            }}
            onClick={saveChanges}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: "14px",
                color: "#FFFFFF",
                paddingTop: "3px",
                letterSpacing: "0.1px",
              }}
            >
              Save Changes
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Dialog
            open={emailChangeOpen}
            aria-labelledby="form-dialog-title"
            onClose={handleEmailChangeClose}
          >
            <DialogTitle id="form-dialog-title">Change Email</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Enter your new email and current password. After doing so, you
                will receive a email to your new email to confirm the change.
              </DialogContentText>
              <TextField
                error={emailErrors ? true : false}
                helperText={emailErrors}
                autoFocus
                margin="dense"
                id="email"
                name="email"
                label="New Email"
                fullWidth
                required={true}
                onChange={handleEmailDataChange}
              />

              <TextField
                error={passwordErrors ? true : false}
                helperText={passwordErrors}
                margin="dense"
                id="password"
                name="password"
                label="Password"
                autoComplete="off"
                fullWidth
                type="password"
                required={true}
                onChange={handleEmailDataChange}
              />
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={handleEmailChangeClose}>
                Cancel
              </Button>
              <Button color="primary" onClick={handleEmailChange}>
                Change Email
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={passwordChangeOpen}
            aria-labelledby="form-dialog-title"
            onClose={() => setPasswordChangeOpen(false)}
          >
            <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
            <DialogContent>
              <DialogContentText>
                A email will be sent to you with a link to change your password
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={() => setPasswordChangeOpen(false)}
              >
                Cancel
              </Button>
              <Button color="primary" onClick={handlePasswordReset}>
                Change Password
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
      <Message
        open={open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="right"
        handleClose={handleClose}
      />
    </div>
  );
}
