import React, { useEffect, useState, useContext } from "react"
import { useHistory, useParams, useRouteMatch } from "react-router-dom"

import ReactQuill from "react-quill";

import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import MuiMenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Alert from '@material-ui/lab/Alert';
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/styles";
import Prompt from "./Prompt";

import CancelIcon from '@material-ui/icons/Cancel';
import { OrgContext } from "hooks/useOrganization"
import { toolbarOptions } from "../../config" 
import WhiteTextField from "Components/WhiteTextField";

import API from "../../Api/Api";

const selectWidth = 350;
const useStyles = makeStyles({
  filled: {
    backgroundColor: 'white',
    "&&:focus": {
      display: 'none',
      border: '0px'
    },
  },

  select: {
    padding: '16px',
  },

  selectMenu: {
    width: '100%'
  },

  formControl: {
    minWidth: selectWidth,
  }
})

const useMenuItemStyles = makeStyles(theme => ({
  menuItem: {
    minWidth: selectWidth
  }
}));

function MenuItem(props) {
  const classes = useMenuItemStyles(props);
  return <MuiMenuItem className={classes.menuItem} {...props} />;
} 

export default function Event(props) {
  const [event, setEvent] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);

  const classes = useStyles();
  const menuItemClasses = useMenuItemStyles();

  const history = useHistory();
  const params = useParams();
  const match = useRouteMatch();

  const org = useContext(OrgContext);

  const api = new API();

  useEffect(() => {
    if (!org) {
      return;
    }

    api.getOrganizationAPI().getTeamMembers().then(response => {
      setTeamMembers(response.data);

      let pk = props.pk || params.event_pk;

      let newEvent = params.event_pk ? false : true;

      if (newEvent && props.activePlan) {
        setEvent({ organization: org.pk, program: props.activePlan.id, tasks: [], name: '', frequency: '', primary_monitor: '', details: '' });
        setLoading(false);
      }

      else if (pk && !newEvent) {
        api.getEventAPI().retrieveEvent(pk).then(response => {
          setEvent(response.data);
          setLoading(false);
        });
      }
    }).catch(error => {
      console.log(error);
    });
    
  }, [org, props.activePlan]);

  function handleChange(eventData) {
    setEvent({ ...event, [eventData.target.name]: eventData.target.value });
  }

  function handleQuillChange(content, delta, source, editor) {
    setEvent({ ...event, "details": content });
  }

  function saveAndContinue() {
    let newEvent = (params.event_pk || event.id) ? false : true;

    if (event.name == null || event.name === "") {
      setErrorText("An event name is required.");
      setErrorOpen(true);
      return;
    }

    if (event.frequency == null || event.frequency === "") {
      setErrorText("An event frequency is required.");
      setErrorOpen(true);
      return;
    }

    // TODO Warning Here that this will set the event as inactive  
    //if ((event.primary_monitor == null || event.primary_monitor == null) && event.active) {
    //}

    if (newEvent) {
      api.getEventAPI().createEvent(event).then(response => {
        if (props.addEvent) {
          props.addEvent(response.data, true);
        }
      }).catch(error => {
        setErrorText("Could not create a new event.");
        setErrorOpen(true);
      });
    }
    else {
      api.getEventAPI().updateEvent(event).then(response => {
        if (props.updateEvent) {
          props.updateEvent(response.data, true, false);
        }
      }).catch(error => {
        setErrorText("Could not update this event.");
        setErrorOpen(true);
      });
    }
  }

  function saveChanges() {
    let newEvent = (params.event_pk || event.id) ? false : true;

    if (event.name == null || event.name === "") {
      setErrorText("An event name is required.");
      setErrorOpen(true);
      return;
    }

    if (event.frequency == null || event.frequency === "") {
      setErrorText("An event frequency is required.");
      setErrorOpen(true);
      return;
    }

    if (newEvent) {
      api.getEventAPI().createEvent(event).then(response => {
        if (props.addEvent) {
          props.addEvent(response.data);
        }
        setEvent(null);

      }).catch(error => {
        setErrorText("Could not create a new event.");
        setErrorOpen(true);
      });
    }  else {
      api.getEventAPI().updateEvent(event).then(response => {
        if (props.updateEvent) {
          props.updateEvent(response.data, false, true);
        }
        setEvent(response.data);
      }).catch(error => {
        setErrorText("Could not update this event.");
        setErrorOpen(true);
      });
    }
  }

  function cancel() {
    if(!window.confirm('Are you sure you want to close this form? Any unsaved data will be lost.')) {
      return;
    }

    history.goBack();
  }

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorText, setErrorText] = useState(null);

  function handleErrorClose() {
    setErrorOpen(false);
    setErrorText(null);
  }

  const [deleteEventOpen, setDeleteEventOpen] = useState(false);
  function deleteEventAgree() {
    api.getEventAPI().deleteEvent(event.id).then(response => {
      if (props.deleteEvent) {
        props.deleteEvent(event);
      }
    }).catch(error => {
      console.log(error);
    });

    setDeleteEventOpen(false);
  }

  function deleteEventDisagree() {
    setDeleteEventOpen(false);
  }

  return (
    <Dialog fullScreen open={true} PaperProps={{style: {backgroundColor: '#F8F8F8'}}}>
      <Prompt 
        open={deleteEventOpen}
        agree={deleteEventAgree}
        disagree={deleteEventDisagree}
        title="Delete Event?"
        description="Are you sure you want to delete this event? This action cannot be reversed."
      />
      <DialogTitle style={{ borderBottom: '1px solid #eaeaea', padding: '0px' }}>
        <Grid container item xs={12} style={{padding: '16px'}}>
          <Grid container item xs={8} jusify="flex-start" alignItems="center">
            <Typography variant="h6">
              Event
            </Typography>
          </Grid>
          <Grid item container xs={4} justify="flex-end" alignItems="center">
            <IconButton onClick={cancel} style={{padding: 0}}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px 8px'}}>
        {loading &&
          <Grid container item xs={12} justify="center" alignItems="center" style={{height: '100%'}}>
            <CircularProgress />
          </Grid>
        }
        {!loading && event &&
          <Grid container spacing={3} style={{padding: '32px', maxWidth: '1200px'}} justify="center">
            <Grid item xs={12}>
              <Typography style={{fontSize: '14px', fontWeight: 500, paddingBottom: '8px'}} >
                Event Name
              </Typography>
              <WhiteTextField required onChange={handleChange} name="name" fullWidth value={event.name || ''} />
            </Grid>
            
            <Grid item xs={12} md={6} lg={4}>
              <Typography style={{fontSize: '14px', fontWeight: 500, paddingBottom: '8px'}}>
                Monitored By
              </Typography>
              <FormControl classes={{root: classes.formControl}}>
                <Select 
                  name="primary_monitor" 
                  value={event.primary_monitor} 
                  onChange={handleChange} 
                  variant="filled" 
                  fullWidth 
                  disableUnderline
                  classes={{select: classes.select, filled: classes.filled}}
                  style={{fontSize: '14px'}}
                >
                  <MenuItem key={0} value={null}>None</MenuItem>
                  {teamMembers.map((teamMember) => {
                    return <MenuItem key={teamMember.pk} value={teamMember.pk}>{teamMember.first_name + " " + teamMember.last_name}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Typography style={{fontSize: '14px', fontWeight: 500, paddingBottom: '8px'}}>
                Verified By
              </Typography>
              <FormControl classes={{root: classes.formControl}}>
                <Select 
                  name="primary_verifier" 
                  style={{fontSize: '14px'}}
                  disableUnderline 
                  classes={{filled: classes.filled, select: classes.select}}
                  value={event.primary_verifier} onChange={handleChange} 
                  variant="filled" 
                  fullWidth>
                  <MenuItem key={0} value={null}>None</MenuItem>
                  {teamMembers.map((teamMember) => {
                    return <MenuItem key={teamMember.pk} value={teamMember.pk}>{teamMember.first_name + " " + teamMember.last_name}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={4} >
              <Typography style={{fontSize: '14px', fontWeight: 500, paddingBottom: '8px'}}>
                Monitoring Frequency
              </Typography>
              <FormControl classes={{root: classes.formControl}}>
                <Select
                  name="frequency"
                  value={event.frequency || ''}
                  onChange={handleChange}
                  disableUnderline
                  classes={{filled: classes.filled, select: classes.select, selectMenu: classes.selectMenu}}
                  inputProps={{classes: {input: classes.input}}}
                  variant="filled"
                  style={{fontSize: '14px'}}
                  fullWidth
                >
                  <MenuItem value={"Daily"}>Daily</MenuItem>
                  <MenuItem value={"Weekly"}>Weekly</MenuItem>
                  <MenuItem value={"Biweekly"}>Biweekly</MenuItem>
                  <MenuItem value={"Monthly"}>Monthly</MenuItem>
                  <MenuItem value={"Quarterly"}>Quarterly</MenuItem>
                  <MenuItem value={"Annually"}>Annually</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{fontSize: '14px', fontWeight: 500, paddingBottom: '8px'}}>
                Event Description 
              </Typography>
              <div className="event-editor" style={{backgroundColor: 'white'}}>
                <ReactQuill
                  theme="snow"
                  value={event.details || ''}
                  onChange={handleQuillChange}

                  modules={{
                    toolbar: toolbarOptions
                  }}
                />
              </div>
            </Grid>
          </Grid>
        }
      </DialogContent>
      <DialogActions>
        <Grid container item xs={6} justify="flex-start" alignItems="center">
          {event && event.id != null && 
            <Button color="secondary" variant="outlined" onClick={() => setDeleteEventOpen(true)}>
              Delete
            </Button>
          }
        </Grid>
        <Grid container item xs={6} justify="flex-end" alignItems="center">
          <Button color="secondary" variant="outlined" style={{margin: '4px'}} onClick={cancel}>Cancel</Button>
          {params.event_pk !== "new" && <Button color="primary" variant="contained" style={{ margin: '4px' }} onClick={saveChanges}>Save Changes</Button>}
          <Button color="primary" variant="outlined" style={{ margin: '4px' }} onClick={saveAndContinue}>Save and Continue</Button>
        </Grid>
      </DialogActions>

      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert severity="error" onClose={() => { handleErrorClose(); }}>
          {errorText}
        </Alert>
      </Snackbar>
    </Dialog>
  )
}
