import API from "../../Api/Api";
import { fabric } from "fabric";
import { saveAs } from 'file-saver';

export const RECT_SETTINGS = {
  width: 100,
  height: 50,
  haccp_type: 'process',
  fill: 'white',
  stroke: "black",
  opacity: 1.0,
  lockScalingFlip: true,
};

export const RECT_TEXT_SETTINGS = {
  width: 95,
  top: 25,
  left: 50,
  fontSize: 12,
  haccp_type: 'processText',
  originX: 'center',
  originY: 'center',
  textAlign: 'center',
  lockScalingFlip: true,
  hasBorders: false,
};

export const CIRCLE_SETTINGS = {
  radius: 40,
  fill: "white",
  stroke: "black",
  opacity: 1.0,
};

export const CIRCLE_TEXT_SETTINGS = {
  width: 40,
  fontSize: 12,
  top: 40,
  left: 40,
  haccp_type: "processText",
  originX: "center",
  originY: "center",
  textAlign: "center",
};

export const supportedDiagrams = {
  PROCESS_FLOW: {
    name: "process_flow", 
    api: new API().getProcessFlowDiagramAPI(),
    width: fabric.util.parseUnit('8.5in'),
    height: fabric.util.parseUnit('11in'),
  },

  TRAFFIC_FLOW: {
    name: "traffic_flow",
    api: new API().getTrafficFlowDiagramAPI(),
    width: fabric.util.parseUnit('11in'),
    height: fabric.util.parseUnit('8.5in'),
  },
}

function getFileName(haccpPlan, diagramType, diagram, templateMode=false) {
  let fileName
  
  if (!(diagramType in supportedDiagrams)) {
    return;
  }
    
  let type = supportedDiagrams[diagramType].name; 

  fileName = `${type}_pk_${diagram.pk}_${haccpPlan.organization}_template_${templateMode}.png`;

  return fileName;
}

function getAPI(diagramType, diagram) {
  
  if (!(diagramType in supportedDiagrams)) {
    return;
  }
  
  let api = supportedDiagrams[diagramType].api;

  return api;
}

/**
 * export async saveDiagram() Converts a diagram to a datablob and updates the instance and saved image fields
 *
 * @param {canvas} canvas - the canvas to convert to a blob
 * @param {HaccpPlan} haccpPlan - the haccp plan the diagram belongs to 
 * @param {string} diagramType - the type of diagram, must be either PROCESS_FLOW or TRAFFIC_FLOW
 * @param {JSON} diagram - the diagram json that will be loaded onto the canvas
 * @param {boolean} download=false - download the diagrams as PNGs
 *
 * @return {...}
 */
export async function saveDiagram(canvas, haccpPlan, diagramType, diagram, download=false) {
  let updatedDiagram = {
    ...diagram, diagram: JSON.stringify(canvas.toJSON(['pk', 'haccp_type', 'x1', 'y1', 'x2', 'y2']))
  }; 
  
  delete diagram.saved_image;

  let filename = getFileName(haccpPlan, diagramType, diagram);
  
  if (filename == null) {
    return;
  }

  let formData = new FormData(document.forms[0]);
  let dataURI = canvas.toDataURL();
  let blob = dataURItoBlob(dataURI);
  let file = new File([blob], filename, { type: "image/png" });

  formData.append("saved_image", file);
  formData.append("diagram", updatedDiagram.diagram);
  
  if (diagram && diagram.cross_contamination_points) {
    for (let i = 0; i < diagram.cross_contamination_points.length; i++) {
      formData.append("cross_contamination_points", diagram.cross_contamination_points[i]);
    }
  }

  let api = new API();
  let FileSaver = require('file-saver');
  
  if (diagramType === "PROCESS_FLOW") {
    if (download) {
      FileSaver.saveAs(blob, 'process-flow.png');  
    }
    else {
      return api.getProcessFlowDiagramAPI()
        .updateProcessFlowDiagramImage(diagram.pk, formData).then(response => {
          return response.data;
        }); 
    }
  }

  else if (diagramType === "TRAFFIC_FLOW") {
    if (download) {
      FileSaver.saveAs(blob, 'traffic-flow.png');  
    }
    else {
      return api.getTrafficFlowDiagramAPI()
        .updateTrafficFlowDiagramImage(diagram.pk, formData).then(response => {
          return response.data;
        });
    }
  }
}

/**
 * updateDiagramHeadless() 
 * Loads and initialzes the diagrams belonging to a haccp plan and ensures the diagrams are in sync with the data in the haccp plan 
 *
 * @param {...} haccpPlanPk - the pk of the haccp plan to be synced
 *
 * @return {...} is async, so it returns a promise
 */
export async function updateDiagramHeadless(haccpPlanPk) {
  if (haccpPlanPk == null) {
    throw "Provide a HACCP Plan PK";
  }
  
  let haccpPlan = await new API().getHaccpPlanAPI().retrieveHaccpPlan(haccpPlanPk).then(response => response.data);
   
  if (haccpPlan.process_flow_diagram == null && haccpPlan.traffic_flow_diagram == null) {
    return;
  }
  
  let processFlowDiagramCanvas = new fabric.StaticCanvas('temp-process-flow-diagram', 
    {width: supportedDiagrams['PROCESS_FLOW'].width, height: supportedDiagrams['PROCESS_FLOW'].height}
  );

  let trafficFlowDiagramCanvas = new fabric.StaticCanvas('temp-trafifc-flow-diagram', 
    {width: supportedDiagrams['TRAFFIC_FLOW'].width, height: supportedDiagrams['TRAFFIC_FLOW'].height}
  );

  let processes = await new API().getProcessAPI().listProcessesMinimizedJoins(haccpPlan.pk).then(response => response.data); 
  let processFlowDiagram = await new API().getProcessFlowDiagramAPI().retrieveProcessFlowDiagram(haccpPlan.process_flow_diagram).then(response => response.data);
  let trafficFlowDiagram = await new API().getTrafficFlowDiagramAPI().retrieveTrafficFlowDiagram(haccpPlan.traffic_flow_diagram).then(response => response.data);
  let ccps = await new API().getCcpAPI().getProcessCCPs(haccpPlan.pk).then(response => response.data);
    
  if (processes == null || processFlowDiagram == null || trafficFlowDiagram == null || ccps == null) {
    throw "Could not update diagrams."; 
  }

  processFlowDiagramCanvas.loadFromJSON(processFlowDiagram.diagram, async () => {
    compareProcesses(processFlowDiagramCanvas, processes);
    checkProcessesForCCPs(processFlowDiagramCanvas, ccps);

    processFlowDiagramCanvas.renderAll();

    saveDiagram(processFlowDiagramCanvas, haccpPlan, "PROCESS_FLOW", processFlowDiagram);
  });

  trafficFlowDiagramCanvas.loadFromJSON(trafficFlowDiagram.diagram, async () => {
    await addBackgroundImage(trafficFlowDiagramCanvas, trafficFlowDiagram, "TRAFFIC_FLOW");

    compareProcesses(trafficFlowDiagramCanvas, processes);
    checkProcessesForCCPs(trafficFlowDiagramCanvas, ccps);

    trafficFlowDiagramCanvas.renderAll();

    saveDiagram(trafficFlowDiagramCanvas, haccpPlan, "TRAFFIC_FLOW", trafficFlowDiagram);
  });
}

/**
 * exportdataURItoBlob() convert base64/URLEncoded data component to raw binary data held in a string
 *
 * @param {...} exportdataURI - base64/URLEncoded data component to be converted
 *
 * @return {...} the data blob 
 */
export function dataURItoBlob(dataURI) {
  let byteString, mimeString, ia;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

//image is null on new diagrams
export function addBackgroundImage(canvas, flowDiagram, diagramType) {
  return new Promise((resolve, reject) => {
    if (canvas == null) {
      reject("Canvas is null");
    }

    // if (flowDiagram.image == null) {
    //   resolve();
    // }

    fabric.Image.fromURL(flowDiagram.image, function (img) {
      img.scaleToWidth(supportedDiagrams[diagramType].width);
      canvas.setBackgroundImage(img);
      canvas.requestRenderAll();
      resolve();
    }, { crossOrigin: "Anonymous" });
  });
}

/**
 * compareProcesses() 
 *
 * Syncs a diagram's process boxes with the processes that are currently attached to the HACCP Plan 
 *
 * @param {...} canvas - the loaded diagram
 * @param {...} processes - the processes attached to the haccp plan 
 * @param {...} canvasMode='select' - the mode the diagram tool is in 
 *
 * @return {...} undefined 
 */
export function compareProcesses(canvas, processes, canvasMode='select') {

  if (canvas == null) {
    return;
  }

  if (processes == null) {
    return;
  }
  
  // compare current plan state to the current diagram and make sure they sync 
  let diagramProcesses = getDiagramProcesses(canvas);
  let addedProcesses = 0;
  
  processes.forEach(process => {
    let index = diagramProcesses.findIndex(diagramProcess => {
      return diagramProcess.pk === process.pk;
    });
    
    if (index > -1) {
      // processes match, update process text
      let diagramProcess = diagramProcesses[index];
      
      // process steps used to be circles, this is legacy code to convert old process diagrams to rectangles
      if (diagramProcess._objects && diagramProcess._objects[0].type === 'circle') {
        canvas.remove(diagramProcess);
        addProcess(canvas, process, 0, {left: diagramProcess.left, top: diagramProcess.top}, canvasMode);
      }

      updateFont(diagramProcess, process.name);
    } else {
      addProcess(canvas, process, addedProcesses++, null, canvasMode);
    }
  });

  diagramProcesses.forEach(diagramProcess => {
    let index = processes.findIndex(process => {
      return process.pk === diagramProcess.pk;
    });

    if (index === -1) {
      canvas.remove(diagramProcess);
    }
  });

  canvas.requestRenderAll();
}

/**
 * makeProcessShape() 
 *
 * Creates a process group and offsets it based on the number of previously added processes 
 *
 * @param {...} process - the process instance the box is linked to 
 * @param {...} addedProcesses - the number of processes added so far
 * @param {...} canvasMode - the mode of the diagram tool
 *
 * @return {...} the new process group
 */
export function makeProcessShape(process, addedProcesses, canvasMode) {
  let processShape = new fabric.Rect(RECT_SETTINGS);
  let processText = new fabric.Textbox(process ? process.name : '', RECT_TEXT_SETTINGS);

  //The math in here sets the offsets for each process to be columns of fifteen processes
  //With each process 20 px below the next. The columns are then 20 px apart as well, 
  //with each new column starting 5px below the last.
  const HORIZONTAL_OFFSET = 20;
  const VERTICAL_OFFSET = 20;
  const COLUMN_OFFSET = 5;
  const ELEMENTS_PER_COL = 15;
  let processGroup = new fabric.Group([processShape, processText], {
    left: 150 + HORIZONTAL_OFFSET*Math.floor(addedProcesses/ELEMENTS_PER_COL),
    top: 100 + VERTICAL_OFFSET*((addedProcesses) % ELEMENTS_PER_COL) + COLUMN_OFFSET*Math.floor(addedProcesses/ELEMENTS_PER_COL),
    hoverCursor: canvasMode === 'select' ? 'grab' : 'default',
    selectable: canvasMode === 'select',
    lockScalingFlip: true,
  });

  return processGroup;
}

export function addProcess(canvas, process, addedProcesses=0, location = null, canvasMode) {
  if (process != null) {
    let processGroup = makeProcessShape(process, addedProcesses, canvasMode);
    
    processGroup.set("pk", process.pk);
    processGroup.set("haccp_type", "process");

    updateFont(processGroup, process.name);
    
    if (location) {
      processGroup.left = location.left;
      processGroup.top = location.top;
    }

    canvas.add(processGroup);
    processGroup.bringToFront();
  }
}

export function checkProcessesForCCPs(canvas, processCcps) {
  if (canvas == null) {
    return;
  }

  if (processCcps == null) {
    return;
  }
  
  getDiagramProcesses(canvas).forEach(process => {
    let isProcessInList = false;
    processCcps.forEach(processCcp => {
      if (process.pk === processCcp.process) {
        isProcessInList = true;

        process._objects[0].set({ stroke: 'black', fill: 'yellow' });
      }
    });

    if (!isProcessInList) {
      process._objects[0].set({ stroke: 'black', fill: 'white' });
    }
  });
}

export function updateFont(processGroup, newText) {
  if (processGroup == null || newText == null) {
    throw "missing parameters";
  }
  
  if (processGroup._objects == null) {
    throw "_objects do not exist";
  }
  
  if (processGroup._objects.length <= 1) {
    throw "process group is missing elements";
  }
  
  let textBox = processGroup._objects[1];
  let margin = 10;

  processGroup.removeWithUpdate(textBox);
  
  let newTextBox = new fabric.Textbox(newText, { ...RECT_TEXT_SETTINGS});
  
  while (newTextBox.height > processGroup._objects[0].height - margin) {
    newTextBox.set({ fontSize: newTextBox.fontSize - 1});
  }
  
  newTextBox.set({left: processGroup.left + processGroup.width / 2, top: processGroup.top + processGroup.height / 2});
  processGroup.addWithUpdate(newTextBox);
}

export function getDiagramProcesses(canvas) {
  if (canvas == null) {
    throw "missing canvas";
  }

  let processList = [];
  let objects = canvas.getObjects();

  for (let i = 0, len = canvas.size(); i < len; i++) {
    if (objects[i].haccp_type && objects[i].haccp_type === "process") {
      processList.push(objects[i]);
    }
  }

  return processList;
}
