class ExternalLotCodeAPI {
    constructor(api, version) {
      this.api = api;
      this.version = version;
    }
  
    listExternalLotCodes(pk) {
      if (typeof pk === 'number') {
        return this.api.get(`${this.version}/external_lot_codes/${pk}/`);
      }
  
      return this.api.get(`${this.version}/external_lot_codes/`)
    }

    //Doesn't currently have a display version, it's not a big model.
    /*listInternalLotCodesDisplay(pk) {
      if (typeof pk === 'number') {
        return this.api.get(`${this.version}/internal_lot_codes_display/${pk}/`);
      }
  
      return this.api.get(`${this.version}/internal_lot_codes_display/`)
    }*/
  
    createExternalLotCode(payload) {
      return this.api.post(`${this.version}/external_lot_codes/`, payload);
    }
  
    updateExternalLotCode(payload) {
      return this.api.put(`${this.version}/external_lot_codes/${payload.pk}/`, payload);
    }
  
    deleteExternalLotCode(pk) {
      return this.api.delete(`${this.version}/external_lot_codes/${pk}/`);
    }
  
  }
  
  export default ExternalLotCodeAPI;