import React, { useEffect, useState } from "react";
import API from "../../Api/Api";
import { Typography, Paper, Grid, Button, Divider } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Message from "../../Components/Message";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import moment from "moment-timezone";
import { withStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import ContentHeader from "../../Components/Layouts/ContentHeader";

const GreyCheckbox = withStyles({
  root: {
    marginRight: "10px",
    color: grey,
    "&$checked": {
      color: grey,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function Settings(props) {
  let api = new API();

  const [settingsData, setSettingsData] = useState({
    time_zone: props.user.user.time_zone ? props.user.user.time_zone : "17",
    days_checked: props.user.user.days_checked
      ? props.user.user.days_checked
      : [null],
    monitoring_paused: props.user.user.monitoring_paused
      ? props.user.user.monitoring_paused
      : "false",
  });

  const [generalSettings, setGeneralSettings] = useState(true);
  const [gmtAdjustmentHours, setgmtAdjustmentHours] = useState(null);
  const [gmtAdjustmentMinutes, setgmtAdjustmentMinutes] = useState(null);
  //const [amOrPM, setAmOrPM] = useState(null);
  const [signAdjust, setSignAdjust] = useState(null);
  const [timeZone, setTimeZone] = useState(
    props.time_zone ? props.time_zone : null 
  );

  function handleTimeZoneChange(event, thing) {
    const value = event.target.value;
    let signAdjust = event.currentTarget.innerText.slice(4, 5);
    setSignAdjust(signAdjust);
    let myTimeZoneHours = event.currentTarget.innerText.slice(5, 7);
    let myTimeZoneminutes = event.currentTarget.innerText.slice(8, 10);
    setTimeZone(value);
    setgmtAdjustmentHours(myTimeZoneHours);
    setgmtAdjustmentMinutes(myTimeZoneminutes);
    setSettingsData({
      ...settingsData,
      time_zone: value,
    });
  }

  const [time, setTime] = useState(null);


  function getCurrentTime() {
    let calceddateMS;
    let date = moment().utc().valueOf();
    if (signAdjust === "-") {
      calceddateMS = date;
    } else {
      calceddateMS = date;
    }
    let travelTime;
    if (signAdjust === "-") {
      let subtractingThisHours = parseInt(gmtAdjustmentHours) - 4;
      let subtractingThisMinutes = parseInt(gmtAdjustmentMinutes);
      travelTime = moment(calceddateMS)
        .subtract(subtractingThisHours, "hours")
        .subtract(subtractingThisMinutes, "minutes")
        .format("hh:mm");
      if (
        moment(
          calceddateMS - gmtAdjustmentHours * 3600000 + 4 * 60 * 60 * 1000
        ).hour() >= 12
      ) {
        //setAmOrPM("PM");
      } else {
        //setAmOrPM("AM");
      }
    } else {
      let addingThisHours = parseInt(gmtAdjustmentHours) + 4;
      let addingThisMinutes = parseInt(gmtAdjustmentMinutes);
      travelTime = moment(calceddateMS)
        .add(addingThisHours, "hours")
        .add(addingThisMinutes, "minutes")
        .format("hh:mm");
      if (
        moment(
          calceddateMS + gmtAdjustmentHours * 3600000 + 4 * 60 * 60 * 1000
        ).hour() >= 12
      ) {
        //setAmOrPM("PM");
      } else {
        //setAmOrPM("AM");
      }
    }
    setTime(travelTime);
  }

  useEffect(() => {
    var timerID = setInterval(() => tick(), 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  }, [timeZone]);

  useEffect(() => {
    getCurrentTime();
  }, [timeZone]);

  const [supportedTimezones, setSupportedTimezones] = useState([])
  useEffect(() => {
    api.getMonitoringAPI().getSupportedTimezones().then(response => {
      setSupportedTimezones(response.data);
    }).catch(error => {
      console.log(error);
    });
  }, [])

  function tick() {
    getCurrentTime();
  }

  const [plan, setPlan] = React.useState(null);
  const [eventManager, setEventManager] = React.useState(null);
  const [alwaysSendEmails, setAlwaysSendEmails] = useState(null);

  useEffect(() => {
    new API()
      .getMonitoringAPI()
      .getEventManager()
      .then((response) => {
        setEventManager(response.data);
        setTimeZone(response.data.time_zone);
        setAlwaysSendEmails(response.data.always_send_emails);

        setSettingsData({
          ...settingsData,
          time_zone: response.data.time_zone,
          days_checked: response.data.days_checked,
          monitoring_started: response.data.monitoring_started,
        });
        if (response.data.days_checked.length > 1) {
          setCheckedItems(JSON.parse(response.data.days_checked));
        }
        if (response.data.monitoring_started) {
          setMonitoringStarted("True");
        } else {
          setMonitoringStarted("False");
        }
        getCurrentTime();
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function saveChanges() {
    //this is to be changed when eventManager backend is changed
    let mySettingsData = settingsData;
    mySettingsData.days_checked = JSON.stringify(checkedItems);
    if (monitoringStarted === "False") {
      mySettingsData.monitoring_started = false;
    } else {
      mySettingsData.monitoring_started = true;
    }
    api
      .getMonitoringAPI()
      .changeSettings(eventManager.id, mySettingsData)
      .then((response) => {
        setReturnStatus("Updated Settings", "info");
        if (response.data.monitoring_started) {
          setMonitoringStarted("True");
        } else {
          setMonitoringStarted("False");
        }
      })
      .catch((e) => {
        console.log("returned: ", e.response.data);
      });
  }

  const [monitoringStarted, setMonitoringStarted] = useState(null);

  function pauseMonitoring() {
    if (monitoringStarted === "False") {
      api
        .getMonitoringAPI()
        .startMonitoring(eventManager.id)
        .then((response) => {
          //   setEventManager(response.data);
      setReturnStatus("Monitoring Started", "info");
        })
        .catch((error) => {
          console.error("Could not pause monitoring.");
        });
      setMonitoringStarted("True");

    } else {
      setMonitoringStarted("False");
      api
        .getMonitoringAPI()
        .pauseMonitoring(eventManager.id)
        .then((response) => {
          setReturnStatus("Monitoring Paused", "info");
          //  setEventManager(response.data);
        })
        .catch((error) => {
          console.error("Could not pause monitoring.");
        });
    }
    if (monitoringStarted === "False") {
      setSettingsData({
        ...settingsData,
        monitoring_started: "False",
      });
      //setReturnStatus("Monitoring has been paused", "info");
    } else {
      setSettingsData({
        ...settingsData,
        monitoring_started: "True",
      });
      //setReturnStatus("Monitoring resumed", "info");
    }
  }

  function handleSendEmails(event) {
    setAlwaysSendEmails(event.target.checked)
    setSettingsData({...settingsData, always_send_emails: event.target.checked});
  }

  const [checkedItems, setCheckedItems] = React.useState({
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
  });

  const {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
  } = checkedItems;

  const [checkedItemsLength, setCheckedItemsLength] = useState(null);

  useEffect(() => {
    let numberOfDays = 0;
    if (checkedItems) {
      for (const property in checkedItems) {
        if (checkedItems[property]) {
          numberOfDays++;
        }
      }
      setCheckedItemsLength(numberOfDays);
    }
  }, [checkedItems]);

  const handleChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
    setSettingsData({
      ...settingsData,
      days_checked: {
        ...checkedItems,
        [event.target.name]: event.target.checked,
      },
    });
  };

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState({
    message: "",
    status: "info",
  }); // error, warning, info, info
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function setReturnStatus(message, status) {
    setMessage({ message: message, status: status });
    setOpen(true);
  }

  function getTzMenuItem(tz) {
    return (
      <MenuItem
        key={tz[0]}
        value={tz[0]}
      >
        {tz[1]}
      </MenuItem>
    )
  }

  return (
    <div style={{ width: "100%"}} >
			<ContentHeader title="Settings" />
      <Grid container spacing={0} style={{ margin: "32px", marginBottom: "64px", width: "auto"}}>
        <Grid item xs={12}>
          <Button
            size="small"
            variant="text"
            style={{
              color: generalSettings ? "grey" : "#18bff6",
              padding: 0,
              marginBottom: "12px",
              lineHeight: "24px",
              marginRight: "20px",
            }}
            onClick={() => setGeneralSettings(true)}
          >
            General Settings
          </Button>

          {/* <Button
            size="small"
            variant="text"
            style={{
              color: !generalSettings ? "grey" : "#18bff6",
              padding: 0,
              lineHeight: "24px",
              marginBottom: "12px",
            }}
            onClick={() => setGeneralSettings(false)}
          >
            Traceability Settings
          </Button> */}
        </Grid>
        <Grid item xs={12}>
          <Divider
            style={{ marginBottom: "32px" }}
          ></Divider>
        </Grid>
        {generalSettings && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{
                  color: "grey",
                  fontSize: "14px",
                  letterSpacing: "0.1px",
                }}
              >
                Time Zone
              </Typography>
            </Grid>
            <Grid item>
            {supportedTimezones && 
              <Select
                id="time_zone"
                name="time_zone"
                variant="outlined"
                value={timeZone || '' }
                onChange={handleTimeZoneChange}
                style={{ width: 300 }}
              >
                {supportedTimezones && supportedTimezones.map(tz => getTzMenuItem(tz))}
              </Select>
            } 
            </Grid>
            {/*<Grid item>
              {time && gmtAdjustmentHours && (
                <Typography
                  variant="body1"
                  style={{
                    color: "grey",
                    paddingTop: "16px",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    letterSpacing: "0.1px",
                    marginLeft: "24px",
                  }}
                >
                  Current Time: {time} {amOrPM}
                </Typography>
              )}
                </Grid>*/}
            <Grid item xs={12} style={{ marginTop: "16px" }}>
              <Typography
                variant="body1"
                style={{
                  color: "grey",
                  fontSize: "14px",
                  letterSpacing: "0.1px",
                }}
              >
                Days of Operation
              </Typography>
            </Grid>
            <Grid item>
              <Paper style={{ width: 300, padding: "20px" }}>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <GreyCheckbox
                          checked={Sunday}
                          onChange={handleChange}
                          name="Sunday"
                        />
                      }
                      label="Sunday"
                    />
                    <FormControlLabel
                      control={
                        <GreyCheckbox
                          checked={Monday}
                          onChange={handleChange}
                          name="Monday"
                        />
                      }
                      label="Monday"
                    />
                    <FormControlLabel
                      control={
                        <GreyCheckbox
                          checked={Tuesday}
                          onChange={handleChange}
                          name="Tuesday"
                        />
                      }
                      label="Tuesday"
                    />
                    <FormControlLabel
                      control={
                        <GreyCheckbox
                          checked={Wednesday}
                          onChange={handleChange}
                          name="Wednesday"
                        />
                      }
                      label="Wednesday"
                    />
                    <FormControlLabel
                      control={
                        <GreyCheckbox
                          checked={Thursday}
                          onChange={handleChange}
                          name="Thursday"
                        />
                      }
                      label="Thursday"
                    />
                    <FormControlLabel
                      control={
                        <GreyCheckbox
                          checked={Friday}
                          onChange={handleChange}
                          name="Friday"
                        />
                      }
                      label="Friday"
                    />
                    <FormControlLabel
                      control={
                        <GreyCheckbox
                          checked={Saturday}
                          onChange={handleChange}
                          name="Saturday"
                        />
                      }
                      label="Saturday"
                    />
                  </FormGroup>
                </FormControl>
              </Paper>
            </Grid>{" "}
            <Grid item>
              <Typography
                variant="body1"
                style={{
                  color: "grey",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  letterSpacing: "0.1px",
                  marginLeft: "24px",
                }}
              >
                Your business operates {checkedItemsLength} day(s) of the week.
              </Typography>
            </Grid>
            <Grid container alignItems="center" style={{ padding: '8px', marginTop: "16px"}}>
              <Grid container item alignItems="center">
                <Typography
                  variant="body1"
                  style={{
                    color: "grey",
                    fontSize: "14px",
                    letterSpacing: "0.1px",
                  }}
                >
                  Send emails if there are no tasks to complete 
                </Typography>
              </Grid>
              <Grid item>
                {alwaysSendEmails != null && 
                <FormControlLabel
                  control={
                    <Switch
                      checked={alwaysSendEmails}
                      onChange={handleSendEmails}
                      name="always_send_emails"
                      color="primary"
                    />
                  }
                  labelPlacement="end"
                  label={alwaysSendEmails ? "Yes" : "No"}
                />
                }
              </Grid>
            </Grid>
           
            <Grid item xs={12} style={{ marginTop: "0px" }}>
              <Typography
                variant="body1"
                style={{
                  color: "grey",
                  fontSize: "14px",
                  letterSpacing: "0.1px",
                }}
              >
                Holiday / Emergency Switch
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                style={{
                  color: "grey",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  letterSpacing: "0.1px",
                }}
              >
                If for any reason (e.g. closed for a holiday), you would like to
                pause monitoring and stop the generation of tasks use the button
                below. Note, all monitoring tasks stop generating until
                unpaused.
              </Typography>
            </Grid>
            <Grid container style={{ padding: "8px", marginTop: "16px" }}>
              <Grid item style={{ marginRight: "80px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  //disabled
                  //style={{ color: "#18bff6", borderColor: "#18bff6" }}
                  onClick={pauseMonitoring}
                >
                  {monitoringStarted === "False"
                    ? "Resume Monitoring"
                    : "Pause Monitoring"}
                </Button>
              </Grid>
              <Grid item style={{ paddingTop: "8px" }}>
                <Typography
                  variant="body2"
                  style={{
                    color: "grey",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    letterSpacing: "0.1px",
                  }}
                >
                  {monitoringStarted === "False"
                    ? "Monitoring is paused, no records are being generated."
                    : "Monitoring is generating as normal."}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{paddingTop: '16px'}}>
              <Button
                size="medium"
                variant="contained"
                color="primary"
                onClick={saveChanges}
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    color: "#FFFFFF",
                    paddingTop: "3px",
                    letterSpacing: "0.1px",
                  }}
                >
                  Save Settings
                </Typography>
              </Button>
            </Grid>
          </Grid>
        )}
        {!generalSettings && <div>Trace</div>}
        {/* Trace Settings Go Here */}
      </Grid>
      <Message
        open={open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="right"
        handleClose={handleClose}
      />
    </div>
  );
}
