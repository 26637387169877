import React, { useState } from 'react';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Traceability from "../Traceability";
import IconButton from '@material-ui/core/IconButton';
import Message from "../../Components/Message";
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import TraceabilityUtils from "../TraceabilityUtils";
import {StylesContext} from "../../App";

const EMPTY_MESSAGE = {
  open: false,
  message: '',
  status: 'info',
};

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '0px solid white',
        backgroundColor: 'rgba(0, 0, 0, 0.029)',
      },
    },
  },
})(TextField);

export default function FProductForm(props) {
  const classes = React.useContext(StylesContext);
  const utils = new TraceabilityUtils();

  const [product, setProduct] = useState(props.product ? props.product : {});
  const [isBlocking, setIsBlocking] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [message, setMessage] = useState({ ...EMPTY_MESSAGE });
  const [submitting, setSubmitting] = useState(false);

  function validateForm() {
    setSubmitAttempted(true);

    /*if (!product.email || !(/.+@.+\.[a-zA-Z]{2,}/gm).test(product.email)) {
      setMessage({ open: true, message: 'Please enter a valid email.', status: 'error' });
      return false;
    }*/

    if (!product.name) {
      setMessage({ open: true, message: 'Please enter the product Name.', status: 'error' });
      return false;
    }

    if (!product.description) {
      setMessage({ open: true, message: 'Please enter the product Description.', status: 'error' });
      return false;
    }

    return true;
  }


  function handleSubmit(saveAndContinue) {
    setSubmitting(true);
    setSubmitAttempted(true);
    if (!validateForm()) {
      setSubmitting(false);
      return;
    }

    let productObject = { ...product };

    if (props.setShouldClose) {
      props.setShouldClose(true);
    }

    let organization = JSON.parse(localStorage.getItem("organization"))
    productObject.organization = organization.pk;
    // productObject.traceability = props.activeTraceability.pk;
    const api = new Traceability().getFProductsAPI();

    if (productObject.id) {
      api.updateFProduct(productObject)
      .then(response => {
        if (props.setShouldClose) {
          props.setShouldClose(true);
        }
        setProduct({...response.data, type: 'Product'});
        setSubmitAttempted(false);
        if (props.onSave) {
          props.onSave(response, {...response.data, type: 'Product'}, saveAndContinue);
        }
        setMessage({ open: true, message: 'Saved Successfully', status: 'success' });
        setSubmitting(false);
      }).catch(error => {
        setMessage({ open: true, message: 'Server Error: ' + utils.formatError(error), status: 'error' });
        setSubmitting(false);
        //console.log('Error Response: ' + JSON.stringify(error));
      });
    }
    else {
      api.createFProduct(productObject)
      .then(response => {
        if (props.setShouldClose) {
          props.setShouldClose(true);
        }
        setProduct({...response.data, type: 'Product'});
        setSubmitAttempted(false);
        if (props.onSave) {
          props.onSave(response, {...response.data, type: 'Product'}, saveAndContinue);
        }
        setMessage({ open: true, message: 'Saved Successfully', status: 'success' });
        setSubmitting(false);
      }).catch(error => {
        setMessage({ open: true, message: 'Server Error: ' + utils.formatError(error), status: 'error' });
        setSubmitting(false);
        //console.log('Error Response: ' + JSON.stringify(error));
      });
    }
  }

  // function handlePhoneFieldChange(event) {
  //   if (props.setShouldClose) {
  //     props.setShouldClose(false);
  //   }

  //   const property = event.target.name;
  //   let phoneString = event.target.value;

  //   if(phoneString[0] && phoneString[0] != '+' ) {
  //     phoneString = '+' + phoneString;
  //   } 

  //   setProduct({ ...product, [property]: phoneString });

  // }

  function handleTextFieldChange(event) {
    setIsBlocking(true);
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }
    const property = event.target.name;
    const value = event.target.value;

    setProduct({ ...product, [property]: value });
  }

  function cancel() {
    setProduct(props.product ? props.product : {});

    setIsBlocking(true);
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }
  }

  function handleDelete() {
    let productName = product.name || 'this product';

    if(!window.confirm('Are you sure you want to delete ' + productName + '?')){
      return;
    }

    if(!product || !product.id) {
      props.closeModal ? props.closeModal() : cancel();
    }
    
    const productAPI = new Traceability().getFProductsAPI();
    productAPI.deleteFProduct(product.id).then(response => {
      
      if (props.onDelete) {
        props.onDelete(response, {id: product.id, type: 'Product', soft_delete: true}, true);
      }
      setMessage({ open: true, message: 'Deleted Successfully', status: 'success' });
      props.closeModal();
    }).catch(error => {
      setMessage({ open: true, message: 'Server Error: ' + utils.formatError(error), status: 'error' });
      //console.log('Error Response: ' + JSON.stringify(error));
    });
  }
  
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3} className={classes.generalContainerGridHead}>
        <Grid item xs={11}>
          <Typography variant="h6" noWrap className={classes.generalFormHeaderTypography}>
            product Form
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton style={{ float: 'right' }} onClick={props.closeModal ? props.closeModal : cancel}>
            <CancelIcon style={{ float: 'right' }} />
          </IconButton>
        </Grid>
      </Grid>


      
      <Grid container spacing={3} className={classes.generalContainerGridBody} style={{marginTop: '16px'}}>

        <Grid item xs={12}>
          <Typography variant="h5" noWrap className={classes.generalFormSubsectionHeader}>
            product Details
          </Typography>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={0} square className={classes.generalFormPaperContainerApprovedInfo}>
            <Grid container spacing={3} className={classes.generalContainerGrid}>
              <Grid item xs={12}>
                <Typography className={classes.generalFormTypographyHeader}>
                  product Name
                </Typography>
                <CssTextField
                  fullWidth
                  placeholder="product Name"
                  className={classes.generalFormTextField}
                  name="name"
                  variant="outlined"
                  value={product.name}
                  error={submitAttempted && !product.name}
                  onChange={handleTextFieldChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.generalFormTypographyHeader}>
                  product Description
                </Typography>
                <CssTextField
                  fullWidth
                  placeholder="product Description"
                  className={classes.generalFormTextField}
                  name="description"
                  variant="outlined"
                  multiline
                  rows={3}
                  value={product.description}
                  //error={submitAttempted && !product.description}
                  onChange={handleTextFieldChange}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Paper elevation={0} square className={classes.generalFormPaperStickyFooterApprovedInfo}>
        <Grid container spacing={3} className={classes.generalContainerGridFoot}>
          <Grid item container xs={12} alignItems="center" justify="flex-end">
            {product.id &&
              <Button
                variant="outlined"
                startIcon={<DeleteIcon />}
                style={{ marginLeft: "8px" }}
                onClick={() => {handleDelete()} }
                disabled={submitting}
              >
                Delete
              </Button>
            }
            <Button
              color="secondary"
              style={{ marginLeft: "8px" }}
              onClick={props.closeModal ? props.closeModal : cancel}
              disabled={submitting}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "8px" }}
              onClick={() => { handleSubmit(false) }}
              disabled={submitting}
            >
              Save Changes
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "8px" }}
              onClick={() => { handleSubmit(true) }}
              disabled={submitting}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Message
        open={message.open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="left"
        handleClose={() => { setMessage({ ...message, open: false }) }}
      />

    </form>
  )
}

FProductForm.propTypes = {
  product: PropTypes.object,
  handleSubmit: PropTypes.func,
  user: PropTypes.object.isRequired,
  activeTraceability: PropTypes.object.isRequired,
  setShouldClose: PropTypes.func,
  onSave: PropTypes.func,
  closeModal: PropTypes.func,
  cancel: PropTypes.func,
}