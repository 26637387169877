import React, { useEffect, useState } from 'react';
import { makeStyles} from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from "prop-types";
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactHtmlParser from 'react-html-parser';
import withStyles from "@material-ui/core/styles/withStyles";
import {StylesContext} from "../../App";


const CssTextField = withStyles({
  root: {
    '& .MuiInputBase-root': {
        //border: '0px solid white',
        //backgroundColor: 'rgba(0, 0, 0, 0.055)',
        //paddingTop: '5px',
        color: '#9798B0',
        fontFamily: 'Roboto',
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.1px',
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  typography: {
    marginTop: '20px',
  },
  header: {
    fontWeight: 'bold',
  },
  select: {
    width: '100%',
    padding: '0px',
    margin: '0px',
  },
  inputGrid:{
    marginTop: '16px',
    marginleft: '50px'
  }
}));


export default function ItemRow(props) {
  const classesLocal = useStyles();
  const classes = React.useContext(StylesContext);

  function handleChange(event)  {
    props.setLineData({...props.lineData, [event.target.name]: event.target.value});
  }

  function handleAutoCompleteChange(field, value)  {
    if (field === 'ingredient_name') {
      props.setLineData({ ...props.lineData, [field]: value, unit_type: null });
    } else {
    props.setLineData({...props.lineData, [field]: value});
    }
  }

  function makeConfirm() {
    return (
      <Grid item xs={1}>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: "8px" }}
          onClick={props.unselectLine}
          //disabled={props.disabled || props.ineditable}
        >
          {'Confirm'}
        </Button>
      </Grid>
    )
  }

  function makeActions() {
    if(!props.actions) {
      return;
    }
    return props.actions.map((action, i) => {
      if(action.title) {
        return (
          <Grid key={i} item xs={1}>
            <Button
              key={i}
              variant="outlined"
              color="primary"
              style={action.style ? action.style : { margin: "12px" }}
              onClick={ () => {action.func(props.lineData, props.setLineData,props.index)} }
              disabled={props.disabled || action.disabled || props.ineditable}
            >
              {action.title}
            </Button>
          </Grid>
        )
      }
      else if(action.icon) {
        return (
          <Grid key={i} item xs={1}>
            <IconButton 
              style={action.style ? action.style : { margin: "12px" }}
              key={i}
              onClick={ () => {action.func(props.lineData, props.setLineData,props.index)} }
              disabled={props.disabled || action.disabled || props.ineditable}
            >
              {action.icon}
            </IconButton>
          </Grid>
        )
      }
    })
  }

  function makeFields() {
    return props.lineInfo.map((fieldObj, i) => {
      if(fieldObj.type == 'text') {
        if(props.selected) {
          return (
            <Grid key={i} item xs className={classesLocal.inputGrid}>
              <TextField
                name={fieldObj.key}
                //label={fieldObj.title}
                error={fieldObj.validate ? fieldObj.validate(props.lineData[fieldObj.key]) : false}
                //variant="outlined"
                fullWidth
                value={props.lineData[fieldObj.key]}
                onChange={handleChange}
                disabled={props.disabled || fieldObj.disabled || props.ineditable}
              />
            </Grid>
          )
        }
        else {
          return (
            <Grid key={i} item xs className={classesLocal.inputGrid}>
              <Typography variant="h6" className={classes.itemTableTypography}>
                {props.lineData[fieldObj.key]}
              </Typography>
            </Grid>
          )
        }
      }
      else if(fieldObj.type == 'display') {
        return (
          <Grid key={i} item xs className={classesLocal.inputGrid}>
            <Typography className={classes.itemTableTypography}>
              {props.lineData[fieldObj.key]}
            </Typography>
          </Grid>
        )
      }
      else if(fieldObj.type == 'displayHTML') {
        return (
          <Grid key={i} item xs className={classesLocal.inputGrid}>
            <Typography className={classes.itemTableTypography}>
              {ReactHtmlParser(props.lineData[fieldObj.key])}
            </Typography>
          </Grid>
        )
      }
      else if(fieldObj.type == 'numeric') {
        if(props.selected) {
          return (
            <Grid key={i} item xs className={classesLocal.inputGrid}>
              <TextField
                name={fieldObj.key}
                //label={fieldObj.title}
                //variant="outlined"
                fullWidth
                error={fieldObj.validate ? fieldObj.validate(props.lineData[fieldObj.key]) : false}
                value={props.lineData[fieldObj.key]}
                onChange={handleChange}
                disabled={props.disabled || fieldObj.disabled || props.ineditable}
              />
            </Grid>
          )
        }
        else {
          return (
            <Grid key={i} item xs className={classesLocal.inputGrid}>
              <Typography variant="h6" className={classes.itemTableTypography}>
                {props.lineData[fieldObj.key]}
              </Typography>
            </Grid>
          )
        }
      }
      else if(fieldObj.type == 'select') {
        if(props.selected) {
          let filteredObj = false;
          if(fieldObj.filterFrom) {
            filteredObj = fieldObj.filterFrom.find(filterObj => filterObj[fieldObj.filterFromKey] == props.lineData[fieldObj.filterBy]);
          }

          if(fieldObj.key == 'unit_choices'){
            fieldObj.choices = props.lineData.unit_choices_arr;
          } 

          if(!(props.disabled || fieldObj.disabled || props.ineditable)){
            return (
              <Grid item xs className={classesLocal.inputGrid}>
                <Autocomplete
                  id={fieldObj.key}
                  name={fieldObj.key}
                  value={props.lineData[fieldObj.key]}
                  onChange = {(emptyEvent, newValue) => {handleAutoCompleteChange(fieldObj.key, newValue)}}
                  options={fieldObj.filterBy && filteredObj ?  
                    fieldObj.choices.filter(choiceObj => choiceObj[fieldObj.key] == filteredObj[fieldObj.filterUsingKey]) : 
                    fieldObj.choices}
                  getOptionLabel={(option) => option[fieldObj.choiceDisplay]}
                  disabled={props.disabled || fieldObj.disabled || props.ineditable}
                  //style={{ backgroundColor: '#BEB' }}
                  renderInput={(params) => <TextField {...params} /*label={fieldObj.title} variant="outlined"*/ />}
                />
              </Grid>
            )
          }
          else {
            return (
              <Grid item xs className={classesLocal.inputGrid}>
                <Autocomplete
                  id={fieldObj.key}
                  name={fieldObj.key}
                  value={props.lineData[fieldObj.key]}
                  onChange = {(emptyEvent, newValue) => {handleAutoCompleteChange(fieldObj.key, newValue)}}
                  options={fieldObj.filterBy && filteredObj ?  
                    fieldObj.choices.filter(choiceObj => choiceObj[fieldObj.key] == filteredObj[fieldObj.filterUsingKey]) : 
                    fieldObj.choices}
                  getOptionLabel={(option) => option[fieldObj.choiceDisplay]}
                  disabled={props.disabled || fieldObj.disabled || props.ineditable}
                  //style={{fontSize: '55px'}}
                  //style={{ backgroundColor: '#BEB' }}
                  renderInput={(params) => <CssTextField {...params} InputProps={{disableUnderline: true}} /*label={fieldObj.title} style={{fontSize: '55px'}} variant="outlined"*/ />}
                />
              </Grid>
            )
          }
        }
        else {
          return (
            <Grid key={i} item xs className={classesLocal.inputGrid}>
              <Typography  variant="h6" className={classes.itemTableTypography}>
                { props.lineData[fieldObj.key] && 
                  props.lineData[fieldObj.key][fieldObj.choiceDisplay] 
                }
              </Typography>
            </Grid>
          )
        }
      }
      else if (fieldObj.key === 'ingredient_name') {
        if(props.selected) {
          return (
            <Grid item xs className={classesLocal.inputGrid}>
              <Autocomplete
                id={fieldObj.key}
                freeSolo={false}
                name={fieldObj.key}
                value={props.lineData[fieldObj.key]}

                onChange = {(emptyEvent, newValue) => {
                  if(newValue && typeof(newValue) == 'object') {
                    handleAutoCompleteChange(fieldObj.key, newValue[fieldObj.choiceDisplay])
                  }
                  else {
                    handleAutoCompleteChange(fieldObj.key, newValue)
                  }
                }}

                onInputChange = {(emptyEvent, newValue) => {
                  if(typeof(newValue) == 'object') {
                    handleAutoCompleteChange(fieldObj.key, newValue[fieldObj.choiceDisplay])
                  }
                }}

                options={fieldObj.choices}
                getOptionLabel={(option) => {
                  try{
                    if(typeof(option) == 'object') {
                      return option[fieldObj.choiceDisplay];
                    }
                    else {
                      return option;
                    }
                  }
                  catch{
                    return "Malformed Option"
                  }
                  
                }}
                disabled={props.disabled || fieldObj.disabled || props.ineditable}
                //style={{ backgroundColor: '#BEB' }}
                renderInput={(params) => <TextField {...params} /*label={fieldObj.title}*/ value={props.lineData[fieldObj.key]} /*variant="outlined"*/ />}
              />
            </Grid>
          )
        }
        else {
          return (
            <Grid key={i} item xs className={classesLocal.inputGrid}>
              <Typography variant="h6" className={classes.itemTableTypography}>
                {   props.lineData[fieldObj.key] }
              </Typography>
            </Grid>
          )
        }
      }
      else if(fieldObj.type == 'freeSolo') {
        if(props.selected) {
          //type handling here is because this tends to like to select objects but freesolo means it needs to accept
          //text. Onchange and oninputchange need to be identical for similar reasons. oninputchange will capture typed 
          //words, onchange will capture option selections.
          return (
            <Grid item xs className={classesLocal.inputGrid}>
              <Autocomplete
                id={fieldObj.key}
                freeSolo
                name={fieldObj.key}
                value={props.lineData[fieldObj.key]}

                onChange = {(emptyEvent, newValue) => {
                  if(newValue && typeof(newValue) == 'object') {
                    handleAutoCompleteChange(fieldObj.key, newValue[fieldObj.choiceDisplay])
                  }
                  else {
                    handleAutoCompleteChange(fieldObj.key, newValue)
                  }
                }}

                onInputChange = {(emptyEvent, newValue) => {
                  if(typeof(newValue) == 'object') {
                    handleAutoCompleteChange(fieldObj.key, newValue[fieldObj.choiceDisplay])
                  }
                  else {
                    handleAutoCompleteChange(fieldObj.key, newValue)
                  }
                }}

                options={fieldObj.choices}
                getOptionLabel={(option) => {
                  try{
                    if(typeof(option) == 'object') {
                      return option[fieldObj.choiceDisplay];
                    }
                    else {
                      return option;
                    }
                  }
                  catch{
                    return "Malformed Option"
                  }
                  
                }}
                disabled={props.disabled || fieldObj.disabled || props.ineditable}
                //style={{ backgroundColor: '#BEB' }}
                renderInput={(params) => <TextField {...params} /*label={fieldObj.title}*/ value={props.lineData[fieldObj.key]} /*variant="outlined"*/ />}
              />
            </Grid>
          )
        }
        else {
          return (
            <Grid key={i} item xs className={classesLocal.inputGrid}>
              <Typography variant="h6" className={classes.itemTableTypography}>
                {   props.lineData[fieldObj.key] }
              </Typography>
            </Grid>
          )
        }
      }
      else if(fieldObj.type == 'selectRelative') {
        if(props.selected) {
          return (
            <Grid item xs className={classesLocal.inputGrid}>
              <FormControl style={{width: '100%'}}>
                <Select
                  id={fieldObj.key}
                  name={fieldObj.key}
                  error={fieldObj.validate ? fieldObj.validate(props.lineData[fieldObj.key]) : false}
                  //multiple
                  className={classes.itemTableSelect}
                  //fullWidth
                  value={props.lineData[fieldObj.key]}
                  onChange={handleChange}
                  disabled={props.disabled || fieldObj.disabled || props.ineditable}
                >
                  { props.lineData[fieldObj.relativeField] && props.lineData[fieldObj.relativeField][fieldObj.relativeKey] &&
                    Object.keys(props.lineData[fieldObj.relativeField][fieldObj.relativeKey]).map((choiceKey) => (
                      <MenuItem key={choiceKey} value={choiceKey}>
                        {props.lineData[fieldObj.relativeField][fieldObj.relativeKey][choiceKey]}
                      </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )
        }
        else {
          return (
            <Grid key={i} item xs className={classesLocal.inputGrid}>
              <Typography variant="h6" className={classes.itemTableTypography}>
                { props.lineData[fieldObj.relativeField][fieldObj.relativeKey][ props.lineData[fieldObj.key] ] }
              </Typography>
            </Grid>
          )
        }
      }
    })
  }

  if(!props.disabled) {
    return (
      <>
        {props.lineInfo &&
        
          <Paper elevation={0} square
            className={ (!props.selected && !props.disabled && !props.ineditable && !props.unselectable) ? classes.itemTableRow : classes.itemTableGrid} 
          >
            <Grid container spacing={3} 
              
              onClick={(event) => {
                (!props.selected && !props.disabled && !props.ineditable && !props.unselectable) && props.setLineSelected(props.index)
              }}>
              {makeFields()}
              {/*props.selected ? makeConfirm() : makeActions()*/}
              { makeActions() }
            </Grid>
          </Paper>
        }
      </>
    )
  }
  else {
    return (
      <>
        {props.lineInfo &&
        <>
          <Paper elevation={0} square style={{"backgroundColor": props.disabled ? 'grey' : '#FFF' }}
            className={ (!props.selected && !props.disabled && !props.ineditable && !props.unselectable) ? classes.itemTableRow : classes.itemTableGrid}
          >
            <Grid container spacing={3} 
              onClick={(event) => {
                (!props.selected && !props.disabled && !props.ineditable && !props.noSelection) && props.setLineSelected(props.index)
              }}>
              { makeFields() }
              {/*props.selected ? makeConfirm() : makeActions()*/}
              { makeActions() }
            </Grid>
            {/*<Divider/>*/}
          </Paper></>
        }
      </>
    )
  }

}

ItemRow.propTypes = {
  lineData: PropTypes.object,
  setLineData: PropTypes.func,
  lineInfo: PropTypes.object,
  disabled: PropTypes.bool,
  ineditable: PropTypes.bool,
  actions: PropTypes.array,
  selected: PropTypes.bool,
  noSelection: PropTypes.bool,
  setLineSelected: PropTypes.func,
  index: PropTypes.number,
}