import React from "react";

import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({ 
	underline: {
		"&&:before": {
			borderBottom: 'none',
		}
	},

	input: {
		fontSize: '14px',
		fontFamily: 'Roboto',
		backgroundColor: 'white',
		padding: '16px',
	},

	multiline: {
		padding: '0px',
	},

	error: {
		margin: '0px',
	}
});

/**
 * Custom textfield that is currently used in the Audit.js file 
 * and has a minimalist all white background
 */
export default function GreyTextField(props) {
	const classes = useStyles();

	return (
		<TextField 
			InputProps={{
				disableUnderline: true, 
				classes: {
					input: classes.input, multiline: classes.multiline, error: classes.error,
				}
			}} 
			variant="filled" 
			{...props}
		/>
	)
}