import axios from "axios";


class TrafficFlowDiagramAPI {

  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listTrafficFlowDiagrams(haccp_plan_pk = null) {
    if (typeof haccp_plan_pk === 'number') { // Get traffic_flow_diagram in formulation
      return this.api.get(`${this.version}/traffic_flow_diagrams/?haccp_plan_pk=${haccp_plan_pk}`)
    }

    return this.api.get(`${this.version}/traffic_flow_diagrams/`)
  }

  createTrafficFlowDiagram(payload) {
    return this.api.post(`${this.version}/traffic_flow_diagrams/`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
  }

  createBlankTrafficFlowDiagram(payload) {
    return this.api.post(`${this.version}/traffic_flow_diagrams/`, payload);
  }

  retrieveTrafficFlowDiagram(pk) {
    return this.api.get(`${this.version}/traffic_flow_diagrams/${pk}/`);
  }

  updateTrafficFlowDiagram(payload) {
    return this.api.put(`${this.version}/traffic_flow_diagrams/${payload.id}/`, payload, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  updateTrafficFlowDiagramImage(pk, formData) {
    return this.api.put(`${this.version}/traffic_flow_diagrams/${pk}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  getHistory(pk) {
    return this.api.get(`${this.version}/traffic_flow_diagrams/${pk}/get_history/`);
  }

  deleteTrafficFlowDiagram(pk) {
    return this.api.delete(`${this.version}/traffic_flow_diagrams/${pk}/`);
  }

  selectTemplate(pk, template) {
    return this.api.post(`${this.version}/traffic_flow_diagrams/${pk}/select_template/`, template);
  }
  getRevertedTrafficFlowDiagram(pk, date) {
    return this.api.post(`${this.version}/traffic_flow_diagrams/${pk}/get_reverted_traffic_flow_diagram/`, date);
  }
}


export default TrafficFlowDiagramAPI;
