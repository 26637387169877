import React, { useState, useEffect } from "react";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardActionArea from "@material-ui/core/CardActionArea";

import TextFieldEditor from "./CustomFieldEditors/TextFieldEditor";
import NumberFieldEditor from "./NumberFieldEditor";
import DateTimeFieldEditor from "./CustomFieldEditors/DateTimeFieldEditor";
import RadioButtonFieldEditor from "./CustomFieldEditors/RadioButtonFieldEditor";
import SelectFieldEditor from "./CustomFieldEditors/SelectFieldEditor";
import CheckboxFieldEditor from "./CustomFieldEditors/CheckboxFieldEditor";

const supportedFields = [
	{displayName: "Text Field", 	 name: 'textfield'},
	{displayName: "Number Field",  name: 'number'},
	{displayName: "Date and Time", name: 'datetime'},
	{displayName: "Radio Buttons", name: 'radio'},
	{displayName: "Checkbox",   name: 'checkbox'},
	{displayName: "Select Field",  name: 'select'},
];

export default function FieldBuilder({customField, setCustomField}) {
	const [fieldOptionSelected, setFieldOptionSelected] = useState(0);

	if (customField.field_type !== supportedFields[fieldOptionSelected].name) {
		let optionSelected = supportedFields.findIndex(option => {
			return option.name === customField.field_type;
		});

		setFieldOptionSelected(optionSelected);
	}

	return  (
		<div style={{display: 'flex'}}>
			<div style={{width: '250px', height: '100%'}}>
				<Grid container justify="center" alignItems="center" direction="column" style={{padding: '0px 12px'}}>
					{supportedFields.map((field, index) => {
						const isSelected = index === fieldOptionSelected; 
						return ( 
							<Grid item xs={12} style={{width: '100%', padding: '8px 0px'}}>
								<CardActionArea key={index} onClick={() => setCustomField({...customField, field_type: field.name})} 
									style={{padding: '8px', border: isSelected ? '1px solid rgb(24, 191, 246)' : '1px solid lightgrey'}}>
									<Typography>
										{field.displayName}
									</Typography>
								</CardActionArea>
							</Grid>
						)
					})}
				</Grid>
			</div>

			<div style={{width: '100%', maxWidth: '900px', height: '100%', padding: '0px 0px'}}>
				{fieldOptionSelected != null &&
				<Grid item container xs={9} style={{maxWidth: '100%'}}>
					{supportedFields[fieldOptionSelected].name === 'textfield' && 
						<TextFieldEditor customField={customField} setCustomField={setCustomField} />
					}

					{supportedFields[fieldOptionSelected].name === 'datetime' && 
						<DateTimeFieldEditor customField={customField} setCustomField={setCustomField} />
					}

					{supportedFields[fieldOptionSelected].name === 'radio' && 
						<RadioButtonFieldEditor customField={customField} setCustomField={setCustomField} />
					}

					{supportedFields[fieldOptionSelected].name === 'select' && 
						<SelectFieldEditor customField={customField} setCustomField={setCustomField} />
					}

					{supportedFields[fieldOptionSelected].name === 'number' && 
						<NumberFieldEditor customField={customField} setCustomField={setCustomField} />
					}

					{supportedFields[fieldOptionSelected].name === 'checkbox' &&
						<CheckboxFieldEditor customField={customField} setCustomField={setCustomField} />
					}
				</Grid>
				}
			</div>
		</div>
	)
}