
class SkuInstanceAPI {
  constructor(api, version) {
    this.api = api;
    this.version = version;
  }
  
  listSkuInstances(sku_instance_pk) {
    if (typeof sku_instance_pk === 'number') {
      return this.api.get(`${this.version}/sku_instances/${sku_instance_pk}/`);
    }

    return this.api.get(`${this.version}/sku_instances/`)
  }

  createSkuInstance(payload) {
    return this.api.post(`${this.version}/sku_instances/`, payload);
  }

  updateSkuInstance(payload) {
    return this.api.put(`${this.version}/sku_instances/${payload.pk}/`, payload);
  }

  deleteSkuInstance(pk) {
    return this.api.delete(`${this.version}/sku_instances/${pk}/`);
  }
  
}

export default SkuInstanceAPI;