import React, { useState, useEffect } from 'react';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import AutocompleteSD from "../../Components/AutocompleteSD";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import FulfillmentModal from "./FulfillmentModal";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Traceability from "../Traceability";
import TraceabilityUtils from "../TraceabilityUtils";
import Message from "../../Components/Message";
import CircularProgress from "@material-ui/core/CircularProgress";
import ItemTable from '../ItemTable/ItemBody';
import PropTypes from 'prop-types';
import { StylesContext } from "../../App";
import ReactSignatureCreator from "../../Components/ReactSignatureCreator";
import { useTheme } from "@material-ui/styles";

const EMPTY_MESSAGE = {
  open: false,
  message: '',
  status: 'info',
};

export default function FulfillForm(props) {
  const utils = new TraceabilityUtils();
  const classes = React.useContext(StylesContext);
  const theme = useTheme();

  const [loading, setLoading] = useState(true);
  const [shipping, setShipping] = useState(false);
  const [message, setMessage] = useState({ ...EMPTY_MESSAGE });
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [fulfillmentModalOpen, setFulfillmentModalOpen] = useState(false);
  const [selectedLine, setSelectedLine] = useState({});
  const [lineInfo, setLineInfo] = useState({});
  const [signature, setSignature] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [customerAddress, setCustomerAddress] = useState({});


  function fulfillLine(lineData, setLineData) {
    setSelectedLine({ ...lineData });
    setFulfillmentModalOpen(true);
  }

  function findDecimalValue(valueString) {
    const decimalIndex = valueString.indexOf('.');
    // console.log("decimalIndex >> ", decimalIndex);
    let decimalPoint = 2
    if (decimalIndex !== -1) {
      const wholePart = valueString.substring(0, decimalIndex);
      const decimalPart = valueString.substring(decimalIndex + 1, decimalIndex + 3);
      if (parseInt(wholePart, 10) > 0) {
        decimalPoint = 2;
      } else if (parseInt(wholePart, 10) === 0 && parseInt(decimalPart, 10) !== 0) {
        decimalPoint = 2;
      } else {
        decimalPoint = 4;
      }
    } else {
      decimalPoint = 2;
    }
    return decimalPoint;
  }

  const actions = [
    ...(shipping.status !== 'Shipped' ? [{ title: 'Pick', func: fulfillLine, style: { marginRight: '23px', marginTop: '13px' } }] : []),
  ]
  
  useEffect(() => {
    setLineInfo([
      { key: 'product', title: 'Product Name', type: 'select', choices: props.finishInventory.map((item) => item.product), choiceDisplay: 'name', disabled: true },
      { key: 'target', title: 'Quantity Required', type: 'displayHTML' },
      { key: 'picked_quantity', title: 'Quantity Picked', type: 'display' },
      { key: 'picked_lot_code', title: 'Lot Code', type: 'display' },
    ])
    
    let newShipping = { ...props.shipping };
    newShipping.customer = !!newShipping.customer ? newShipping.customer : null;
    newShipping.customer_address = !!newShipping.customer_address ? newShipping.customer_address : null;

    let customerAdress = Object.values(props.customerAddressArr).find(item => item.pk == newShipping.customer_address);
    setCustomerAddress(customerAdress);

    if (!!newShipping.shipment_products) {
      newShipping.new_shipment_products = newShipping.shipment_products.map((shipmentProduct) => {

        // console.log("shipmentProduct.total_unit_value >> ",shipmentProduct.total_unit_value)
        // let decimalPoint = findDecimalValue(shipmentProduct.total_unit_value);
        // console.log("decimalPoint >> ",decimalPoint);
        let unitType = !!shipmentProduct && !!shipmentProduct.total_unit_type ? shipmentProduct.total_unit_type : null;
        let unitValue = !!shipmentProduct && !!shipmentProduct.total_unit_value ? parseFloat(shipmentProduct.total_unit_value).toFixed(2) : null;
        let pickedLotCodes = shipmentProduct?.lot_codes?.map(lot => lot.code).join(', ');
        const totalPickedQuantity = !!shipmentProduct.lot_codes ? shipmentProduct?.lot_codes?.reduce((total, lot) => total + lot.unit_value, 0) : 0;

        const unitTypeName = props.units[unitType].abbreviation || '';

        return {
          product: shipmentProduct.product_detail,
          target: unitValue + ' ' + props.units[unitType].abbreviation,
          picked_quantity: `${(totalPickedQuantity).toFixed(2)} ${unitTypeName}`,
          unit_type: unitType,
          unit_value: unitValue,
          amount_needed: unitValue,
          picked_lot_code: !!pickedLotCodes ? pickedLotCodes : '',
          lot_codes: shipmentProduct?.lot_codes ? shipmentProduct.lot_codes : [],
        };
      })
    }

    setShipping(newShipping);
    setLoading(false)
  }, [props.shipping])  

  function getCustomerAddress(customer){
    if (!!customer && !!props.customerAddressArr) {
      let customerAddress = Object.values(props.customerAddressArr).filter(address => address.customer == customer);
      // let pkIndexedcustomerAddress = {};
      // customerAddress.forEach((customerObject) => {
      //   pkIndexedcustomerAddress[customerObject.pk] = { ...customerObject };
      // });
      // console.log("pkIndexedcustomerAddress >> ",pkIndexedcustomerAddress);
      return customerAddress;
    }
    return [];
  }

  function setLineItems(newLineItems) {
    let newShipping = { ...shipping };
    newShipping.new_shipment_products = newLineItems;
    setShipping(newShipping);
  }

  function validateForm() {

    if (!shipping.customer) {
      setMessage({ open: true, message: 'Please specifiy Customer.', status: 'error' });
      return false;
    }

    if (!shipping.customer_address) {
      setMessage({ open: true, message: 'Please specifiy Customer Address.', status: 'error' });
      return false;
    }

    if (!shipping.shipment_date) {
      setMessage({ open: true, message: 'Please specifiy Shipment Date.', status: 'error' });
      return false;
    }

    if (shipping.new_shipment_products.length < 1) {
      setMessage({ open: true, message: 'Shipments must have at least one line item.', status: 'error' });
      return false;
    }

    var linesValidated = true;
    shipping.new_shipment_products.forEach((row, i) => {
      if (!lineValidation(row, i)) {
        linesValidated = false;
      }
    })

    return linesValidated;
  }

  function lineValidation(row, i) {
    // console.log("row >> ", row)
    if (!row.picked_lot_code || row.picked_lot_code.length === 0) {
      setMessage({ open: true, message: `Please select an picked lot code on row ${i}.`, status: 'error' });
      return false;
    }
    if (!row.picked_quantity || row.picked_quantity <= 0) {
      setMessage({ open: true, message: `Please select an valid picked quantity on row ${i}.`, status: 'error' });
      return false;
    }
    if (!(row.picked_quantity == row.target)) {
      setMessage({ open: true, message: `Please choose lotCode again for row ${i}.`, status: 'error' });
      return false;
    }

    return true;
  }

  function handleTextFieldChange(event) {
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }
    const property = event.target.name;
    const value = event.target.value;

    setShipping({ ...shipping, [property]: value });
  }

  function handleAutoCompleteChange(newKey, newValue) {
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }
    setShipping({ ...shipping, [newKey]: newValue });
  }

  function formatShippingInstances(new_shipment_products) {
    return new_shipment_products;
  }

  const [validSignature, setValidSignature] = useState(false);

  function checkSignature(sigPad) {
    let sig = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
    setSignature(sig);
    setValidSignature(true);
  }

  function validSignatureCheck(validCheck) {
    setValidSignature(validCheck);
  }

  function handleSubmit(saveAndContinue) {
    setSubmitting(true);
    if (!validateForm()) {
      setSubmitting(false);
      return;
    }
    let formattedShipping = { ...shipping };
    formattedShipping.status = !!saveAndContinue ? 'SHIPPED' : "PICKING";
    formattedShipping.confirmation_signature = signature;

    formattedShipping.shipment_products = formattedShipping.new_shipment_products.map((item) => {
      return {
        "product": item.product.id,
        "total_unit_type": item.unit_type,
        "total_unit_value": item.unit_value,
        "lot_codes": item.lot_codes
      }
    })

    delete formattedShipping.new_shipment_products;
    delete formattedShipping.organization;
    if (!saveAndContinue) {
      delete formattedShipping.confirmation_signature;
    }

    // console.log("formattedShipping >> ", formattedShipping)

    const api = new Traceability().getFShippingAPI();

    if (formattedShipping.id) {
      api.updateFShipping(formattedShipping).then(response => {
        if (props.setShouldClose) {
          props.setShouldClose(true);
        }

        let newShipping = { ...response.data };
        newShipping.customer = !!newShipping.customer ? newShipping.customer : null;
        newShipping.customer_address = !!newShipping.customer_address ? newShipping.customer_address : null;

        if (!!newShipping.shipment_products) {
          newShipping.new_shipment_products = newShipping.shipment_products.map((shipmentProduct) => {

            // let decimalPoint = findDecimalValue(shipmentProduct.total_unit_value);
            // console.log("decimalPoint >> ",decimalPoint);  
            let unitType = !!shipmentProduct && !!shipmentProduct.total_unit_type ? shipmentProduct.total_unit_type : null;
            let unitValue = !!shipmentProduct && !!shipmentProduct.total_unit_value ? parseFloat(shipmentProduct.total_unit_value).toFixed(2) : null;
            let pickedLotCodes = shipmentProduct?.lot_codes?.map(lot => lot.code).join(', ');
            const totalPickedQuantity = !!shipmentProduct.lot_codes ? shipmentProduct?.lot_codes?.reduce((total, lot) => total + lot.unit_value, 0) : 0;

            const unitTypeName = props.units[unitType].abbreviation || '';

            return {
              product: shipmentProduct.product_detail,
              target: unitValue + ' ' + props.units[unitType].abbreviation,
              picked_quantity: `${(totalPickedQuantity).toFixed(2)} ${unitTypeName}`,
              unit_type: unitType,
              unit_value: unitValue,
              amount_needed: unitValue,
              picked_lot_code: !!pickedLotCodes ? pickedLotCodes : '',
              lot_codes: shipmentProduct?.lot_codes ? shipmentProduct.lot_codes : [],
            };
          })
        }

        setShipping(newShipping);
        setSubmitAttempted(false);
        setMessage({
          open: true,
          message: 'Saved Successfully',
          status: 'success',
        });
        if (props.onSave) {
          props.onSave(response);
        }
        setSubmitting(false);
      }).catch(error => {
        setSubmitting(false);
        setMessage({ open: true, message: 'Save Failed: ' + utils.formatError(error), status: 'error' });
      });
    }

    if (props.setShouldClose) {
      props.setShouldClose(true);
    }
  }

  function handleSubmitProduct(res) {

    setSelectedLine({ ...res })

    // let new_shipment_products = shipping.new_shipment_products.filter(item => item.product.id != res.product.id);
    // new_shipment_products.push(res);
    // Find the index of the existing product
    const index = shipping.new_shipment_products.findIndex(item => item.product.id === res.product.id);

    // Create a new array with the updated product at the same index
    const new_shipment_products = [
      ...shipping.new_shipment_products.slice(0, index),
      res,
      ...shipping.new_shipment_products.slice(index + 1)
    ];

    setShipping({ ...shipping, new_shipment_products })
    setFulfillmentModalOpen(false)
    return;
  }

  // console.log("fullfilled >> shipping >> ", shipping);
  // console.log("selectedLine >> ", selectedLine);
  return (
    <>
      {(!props.shipping || loading) &&
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginTop: "250px" }}>
            <Typography>
              <CircularProgress />
            </Typography>
          </Grid>
        </Grid>
      }
      {(props.shipping && !loading) &&
        <form>
          <Paper elevation={0} className={classes.generalFormPaperFieldHolder}
            style={{
              paddingTop: '0px',
              marginTop: '30px',
              backgroundColor: shipping.status == 'READ' ? 'grey' : theme.palette.background.default,
            }}
          >
            <Grid container spacing={3} className={classes.generalContainerGridBody}>

              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  Fulfilling Shipment
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Customer
                </Typography>
                <AutocompleteSD
                  value={shipping.customer}
                  id="customer"
                  className={classes.generalFormTextField}
                  optionType={'pkIndexingToObjects'}
                  options={Object.keys(props.customers).map((key) => { return props.customers[key].id })}
                  choices={props.customers}
                  getOptionLabel={(option) => {
                    return (option && props.customers[option]?.name) ? props.customers[option]?.name : 'No Name'
                  }}
                  onChange={(emptyEvent, newValue) => { setCustomerAddress({}); handleAutoCompleteChange('customer', newValue) }}
                  renderInput={(params) => <TextField {...params} variant="outlined" error={submitAttempted && !shipping.customer} />}
                  disabled={shipping.status == 'Shipped' || props.disabled}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Customer Address
                </Typography>
                <AutocompleteSD
                  value={customerAddress}
                  id="customer_address"
                  className={classes.generalFormTextField}
                  // optionType={'pkIndexingToObjects'}
                  // options={Object.keys(customerAddress).map((key) => { return customerAddress[key].pk })}
                  // choices={customerAddress}
                  // getOptionLabel={(option) => {
                  //   console.log("option >> ",customerAddress[option]);
                  //   return (option && customerAddress[option]?.address) ? customerAddress[option]?.address : 'No Name'
                  // }}
                  options={getCustomerAddress(shipping.customer)}
                  optionType={"rawObjects"}
                  getOptionLabel={(option) => option.address ? option.address : 'No Name'}
                  onChange={(emptyEvent, newValue) => { handleAutoCompleteChange('customer_address', newValue ? newValue.pk : null) }}
                  renderInput={(params) => <TextField {...params} variant="outlined" error={submitAttempted && !shipping.customer_address} />}
                  disabled={shipping.status == 'Shipped' || props.disabled}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Shipment Date
                </Typography>
                <TextField
                  fullWidth
                  className={classes.generalFormTextField}
                  name="shipment_date"
                  type="date"
                  variant="outlined"
                  value={shipping.shipment_date}
                  onChange={handleTextFieldChange}
                  disabled={shipping.status == 'Shipped' || props.disabled}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5">
                  Items Ordered
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Divider variant="middle" />
              </Grid>

              <Grid item xs={12}>
                <ItemTable
                  tableData={formatShippingInstances(shipping.new_shipment_products)}
                  setLineData={setLineItems}
                  lineInfo={lineInfo}
                  actions={actions}
                  disabled={false}
                  ineditable={props.disabled}
                  noSelection={true}
                  unselectable={true}
                />
              </Grid>

              <Grid item container justify="center" alignItems="center" xs={12} style={{ marginTop: '8px' }}>
                {shipping.status != 'Shipped' &&
                  <ReactSignatureCreator
                    checkSignature={checkSignature}
                    validSignatureCheck={validSignatureCheck}
                    disabled={props.disabled || shipping.status == 'Shipped'}
                  //initialSignature={order.most_recent_signature}
                  />
                }
                {shipping.status == 'Shipped' && shipping.confirmation_signature &&
                  <img src={shipping.confirmation_signature} />
                }
                {shipping.status == 'Shipped' && !shipping.confirmation_signature &&
                  <Typography>
                    {shipping.confirmation_signature ? 'Signed by ' + shipping.confirmation_signature : 'Not Signed'}
                  </Typography>
                }
              </Grid>

            </Grid>
          </Paper>

          {/* This Grid serves as the footer element. */}
          <Paper elevation={0} className={classes.generalFormPaperStickyFooter}>
            <Grid container spacing={3} className={classes.generalContainerGridFoot}>
              <Grid item container xs={12} alignItems="center" justify="flex-end">
                {/* {props.closeModal && */}
                <Button
                  //variant="outlined"
                  color="secondary"
                  style={{ marginLeft: "8px" }}
                  onClick={() => props.closeModal()}
                  disabled={submitting}
                >
                  Cancel
                </Button>
                {/* } */}
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ margin: "8px" }}
                  onClick={() => handleSubmit(false)}
                  disabled={shipping.status == 'Shipped' || props.disabled || submitting}
                >
                  Save Changes
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: "8px" }}
                  onClick={() => { handleSubmit(true) }}
                  disabled={shipping.status == 'READ' || shipping.status == 'Shipped' || props.disabled || !validSignature || submitting}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </form>
      }

      <Dialog open={fulfillmentModalOpen} fullScreen onClose={() => { setFulfillmentModalOpen(false) }} fullWidth maxWidth={"lg"}>
        <DialogContent className={classes.generalFormDialogueContainer}>
          <FulfillmentModal
            open={fulfillmentModalOpen}
            handleClose={() => { setFulfillmentModalOpen(false) }}
            handleSubmit={handleSubmitProduct}
            rowData={selectedLine}
            units={props.units}
            finishInventory={props.finishInventory}
          />
        </DialogContent>
      </Dialog>

      <Message
        open={message.open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="left"
        handleClose={() => { setMessage({ ...message, open: false }) }}
      />
    </>
  )
}

FulfillForm.propTypes = {
  setShouldClose: PropTypes.func,
  setReturnMessage: PropTypes.func,
  shipping: PropTypes.object,
  closeModal: PropTypes.func,
  customers: PropTypes.objectOf(PropTypes.object),
  units: PropTypes.objectOf(PropTypes.object),
  cancel: PropTypes.func,
  user: PropTypes.object.isRequired,
  onSave: PropTypes.func,

}