import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import AddIcon from '@material-ui/icons/Add';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from "@material-ui/core/CircularProgress";
import API from "../Api/Api";
import Traceability from "./Traceability";
const api = new Traceability().getInstanceManagerAPI();
const authApi = new API().getAuthAPI();

export default function TraceabilityList(props) {
  const [traceabilityInstances, setTraceabilityInstances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  let history = useHistory();
  let params = useParams();

  useEffect(() => {
    if(history.location.pathname.indexOf(props.activeTraceability.pk) == -1)  {
      history.replace(`traceability/${props.activeTraceability.pk}/orders/`);
    }
    // if(history.location.pathname.indexOf(props.activeTraceability.pk) == -1)  {
    //   history.replace(`traceability/${props.activeTraceability.pk}/inventory_locations/`);
    // }
  })

  /*useEffect(() => {
    setLoading(true);
    api.listTraceabilities().then(e => {
      setLoading(false);
      setTraceabilityInstances(e.data);
    }).catch(e => {
      setLoading(false);
      console.log(e);
    });

    authApi.getAuthenticatedUser().then(e => {
      setUser(e.data);
    }).catch(e => {
      console.log(e);
    });

  }, []);*/

  const traceability_columns = [
    {title: "Name", field: "name"},
    {title: "Description", field: "description"},
  ];

  function addTraceability() {
    let newData = {organization: user.organization.pk};
    api.createTraceability(newData).then(e => {
      if(props.setTraceability) {
        props.setTraceability(e.data);
      }
      history.push(`traceability/${e.data.pk}/details/`);
    }).catch(e => {
      console.log(e);
    });
  }

  function deleteTraceability(traceability, resolve, reject) {

    api.deleteTraceability(traceability.pk).then(response => {
      let updatedTraceabilities = [...traceabilityInstances];
      let traceabilityIndex = updatedTraceabilities.findIndex(updatedTraceability => {
        return updatedTraceability.pk === traceability.pk;
      });

      if (traceabilityIndex > -1) {
        updatedTraceabilities.splice(traceabilityIndex, 1);
      }

      setTraceabilityInstances(updatedTraceabilities);
      resolve();
    }).catch(error => {
      console.log(error);
      reject();
    })
  }

  return (
    <>
      { loading && !user &&
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginTop: "250px" }}>
            <Typography>
              <CircularProgress />
            </Typography>
          </Grid>
        </Grid>
      }

      { !loading && user &&
        <Paper style={{"margin": "8px", "padding": "16px"}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>

              <MaterialTable
                components={{
                  Container: props => <Paper {...props} elevation={0} />
                }}

                isLoading={loading} 
                title="Traceability Instances" 
                columns={traceability_columns} 
                data={traceabilityInstances}

                style={{ width: "100%" }}
                localization={{ body: { editRow: { deleteText: 'Are you sure you want to delete this Customer? This action cannot be reversed.' } } }}

                editable={{
                  onRowDelete: (newData) => new Promise((resolve, reject) => {
                    deleteTraceability(newData, resolve, reject);
                  })
                }}

                actions={
                  [
                    {
                      icon: (props) => (
                        <Button size="small" color="primary" variant="contained" aria-label="add">
                          New Traceability Instance
                        </Button>
                      ),
                      tooltip: 'New Traceability Instance',
                      isFreeAction: true,
                      onClick: (event) => addTraceability()
                    },
                  ]
                }

                onRowClick={(event, rowData, togglePanel) => {
                  if(props.setTraceability) {
                    props.setTraceability(rowData);
                  }

                  history.push(`traceability/${rowData.pk}/details/`);
                }}

                options={{
                  actionsColumnIndex: -1,
                  exportButton: false,
                  pageSize: 10,
                }}

              />
            </Grid>
          </Grid>
        </Paper>
      }
    </>
  )

}