class ProcessTemplateAPI {

  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listProcesses(haccp_plan_pk = null) {
    if (typeof haccp_plan_pk === 'number') { // Get process_templates in formulation
      return this.api.get(`${this.version}/process_templates/?haccp_plan_pk=${haccp_plan_pk}`)
    }

    return this.api.get(`${this.version}/process_templates/`)
  }

  createProcess(payload) {
    return this.api.post(`${this.version}/process_templates/`, payload);
  }

  retrieveProcess(pk) {
    return this.api.get(`${this.version}/process_templates/${pk}/`);
  }

  updateProcess(payload) {
    return this.api.put(`${this.version}/process_templates/${payload.pk}/`, payload);
  }

  deleteProcess(pk) {
    return this.api.delete(`${this.version}/process_templates/${pk}/`);
  }

  addHazardToProcess(process_pk, hazard) {
    return this.api.post(`${this.version}/process_templates/${process_pk}/add_hazard/`, hazard);
  }

  removeHazardFromProcess(process_pk, hazard) {
    return this.api.post(`${this.version}/process_templates/${process_pk}/remove_hazard/`, hazard);
  }

  getHistory(pk) {
    return this.api.get(`${this.version}/process_templates/${pk}/get_history/`);
  }

  getCCPs(pk) {
    return this.api.get(`${this.version}/process_templates/${pk}/get_ccps/`);
  }
}


export default ProcessTemplateAPI;
