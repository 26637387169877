import React, { useState, useEffect } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles"

import usePermissions from "hooks/usePermissions";
import useOrganization from "hooks/useOrganization";
import useAPI from "hooks/useAPI";

import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ReactQuill from "react-quill";
import { toolbarOptions } from "../../config"
import Prompt from "../../Plans/PlanBuilder/Prompt";

import WhiteTextField from "Components/WhiteTextField";

const useStyles = makeStyles({
	typography: {
		fontSize: '14px',
		fontWeight: 500,
		paddingBottom: '8px',
	}
});

function handleError(error) {
	if (error.response) {
		// The request was made and the server responded with a status code
		// that falls out of the range of 2xx
		return error.response.data;
	} else if (error.request) {
		// The request was made but no response was received
		// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
		// http.ClientRequest in node.js
		return null;
	} else {
		// Something happened in setting up the request that triggered an Error
		return error.message;
	}
}

function LogForm(props) {
	/**
	 * CRUD form for a Log 
	 * 
	 */

	const api = useAPI();
	const permissions = usePermissions();
	const history = useHistory();
	const classes = useStyles();
	const params = useParams();

	const [log, setLog] = useState(null);
	const [loading, setLoading] = useState(true);
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const [deleteEventOpen, setDeleteEventOpen] = useState(false);
	const [errorData, setErrorData] = useState(null);

	useEffect(() => {
		if (params && params.pk && !props.newLog) {
			api.getEventAPI().retrieveEvent(params.pk).then(response => {
				if (response.data.event_type === "LOG") {
					setLog(response.data);
					setLoading(false);
				}
				else {
					history.goBack();
				}
			}).catch(error => {
				handleError(error);
				history.goBack();
			});
		}
		else {
			setLoading(false);
			setLog({
				name: "",
				details: "",
				tasks: [],
			});
		}
	}, [])

	useEffect(() => {
		if (permissions && !permissions.team_admin) {
			history.goBack();
		}
	}, [permissions]);

	function closeForm() {
		if (unsavedChanges && !window.confirm("You have unsaved changes, are you sure?")) {
			return;
		}

		history.goBack();
	}

	function handleQuillChange(content, delta, source, editor) {
		setUnsavedChanges(true);
		setLog({ ...log, "details": content });
	}

	function handleNameChange(event) {
		setUnsavedChanges(true);
		setLog({ ...log, "name": event.target.value });
	}

	function save() {
		setLoading(true);
		setErrorData(null);

		submit().then(response => {
			setUnsavedChanges(false);
			setLoading(false);

			if (props.addLog && props.newLog) {
				props.addLog(response.data, false);
				history.replace(`${response.data.id}/edit_log`);
			}

			if (props.updateLog && !props.newLog) {
				props.updateLog(response.data, false, true)
			}

			setLog(response.data);
		}).catch(error => {
			const errData = handleError(error);
			setErrorData(errData);
			setLoading(false);
		});
	}

	function saveAndContinue() {
		setLoading(true);
		setErrorData(null);

		submit().then(response => {
			setUnsavedChanges(false);
			setLoading(false);

			if (props.addLog && props.newLog) {
				props.addLog(response.data, true);
			}

			if (props.updateLog && !props.newLog) {
				props.updateLog(response.data, true, false);
			}

		}).catch(error => {
			const errData = handleError(error);
			setErrorData(errData);
			setLoading(false);
		});
	}

	function submit() {
		let formData = {
			...log,
      event_type: "LOG",
		}

		if (props.newLog) {
			return api.getEventAPI().createEvent({ ...formData, tasks: [] });
		}

		return api.getEventAPI().updateEvent(formData);
	}

	function deleteEventAgree() {
		api.getEventAPI().deleteEvent(log.id).then(() => {
			if (props.deleteEvent) {
				props.deleteEvent(log);
			}

			history.goBack();
		}).catch(error => {
			console.log(error);
		});

		setDeleteEventOpen(false);
	}

	function deleteEventDisagree() {
		setDeleteEventOpen(false);
	}

	return (
		<Dialog fullScreen open={true} PaperProps={{ style: { backgroundColor: '#F8F8F8' } }}>
			<DialogTitle>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
					<div>
						<Typography>
							Log
						</Typography>
					</div>
					<div>
						<IconButton disabled={loading} onClick={closeForm}>
							<CloseIcon />
						</IconButton>
					</div>
				</div>
			</DialogTitle>
			<DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px 8px' }}>
				{loading &&
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: "center", width: '100%', height: '100%' }}>
						<CircularProgress />
					</div>
				}

				{!loading && log &&
					<Grid container alignItems="center" spacing={3} style={{ maxWidth: '1200px', padding: '16px' }}>
						<Grid item xs={12}>
							<Typography className={classes.typography}>
								Log Name
							</Typography>
							<WhiteTextField
								error={errorData != null} 
								helperText={errorData && errorData["name"]} 
								fullWidth 
								value={log.name} 
								onChange={handleNameChange} 
							/>
						</Grid>
						<Grid item xs={12} style={{ paddingTop: '12px' }}>
							<Typography className={classes.typography}>
								Log Description
							</Typography>
							<div className="event-editor" style={{ backgroundColor: 'white' }}>
								<ReactQuill
									theme="snow"
									value={log.details}
									onChange={handleQuillChange}

									modules={{
										toolbar: toolbarOptions
									}}
								/>
							</div>
						</Grid>
					</Grid>
				}

			</DialogContent>
			<DialogActions>
				<Grid item container xs={12}>
					<div style={{ display: 'flex', width: '50%', alignItems: 'center' }}>
						{!props.newLog &&
							<>
								<Button disabled={loading} onClick={() => setDeleteEventOpen(true)} color="secondary">
									Delete
								</Button>
								<Prompt
									open={deleteEventOpen}
									agree={deleteEventAgree}
									disagree={deleteEventDisagree}
									title="Delete Event?"
									description="Are you sure you want to delete this event? This action cannot be reversed."
								/>
							</>
						}
					</div>
					<div style={{ display: 'flex', width: '50%', alignItems: 'center', justifyContent: 'flex-end' }}>
						<Button disabled={loading} disableElevation onClick={closeForm} color="secondary" variant="contained" style={{ marginRight: '4px' }}>
						 	Cancel	
						</Button>
						<Button disabled={loading} onClick={saveAndContinue} color="primary" variant="contained" disableElevation style={{ marginLeft: '4px' }}>
							Submit
						</Button>
					</div>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}


export default LogForm; 