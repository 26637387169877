class FRawInventoryAPI {
  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listFRawInventory(ingredient_type) {
    if (typeof ingredient_type === 'string') {
      return this.api.get(`${this.version}/f_raw_inventory_all/?ingredient_type=${ingredient_type}`);
    }
    return this.api.get(`${this.version}/f_raw_inventory_all/`);
  }
  newlistFRawInventory(ingredient_type) {
    if (typeof ingredient_type === 'string') {
      return this.api.get(`${this.version}/f_raw_inventory/?ingredient_type=${ingredient_type}`);
    }
    return this.api.get(`${this.version}/f_raw_inventory_all/`);
  }

  listLotCodeFRawInventory(id) {
    if (typeof id === 'number') {
      return this.api.get(`${this.version}/f_raw_inventory/${id}/lot-codes/`);
    }
    return '';
  }

  updateFProduct(payload) {
    return this.api.patch(`${this.version}/f_raw_inventory/${payload.id}/update-unit/`, payload);
  }
}

export default FRawInventoryAPI;