import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// import Badge from '@material-ui/core/Badge';
import MenuIcon from "@material-ui/icons/Menu";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

// import NotificationsIcon from '@material-ui/icons/Notifications';
import NavList from "Main/NavList";
import PropTypes from "prop-types";

//Testing imports, remove later
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {
  useHistory,
} from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import FoodbyteLogo from "../logos/FB_Logo_Reversed_RGB_Icon.png";

import useOrganization from "hooks/useOrganization";
import usePermissions from "hooks/usePermissions";

import SettingsIcon from "@material-ui/icons/Settings";

import API from "../Api/Api";
import AvatarComponent from "Components/AvatarComponent";

const drawerWidth = 260;

function logout() {
  localStorage.clear();
  window.location.replace("/login");
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
  },

  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },

  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    ...theme.mixins.toolbar,
  },

  blue: {
    color: theme.palette.getContrastText("rgba(17, 0, 255, 1.0)"),
    backgroundColor: "rgba(17, 0, 255, 1.0)",
  },

  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px) !important`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  menuButton: {
    marginRight: 36,
  },

  menuButtonHidden: {
    display: "none",
  },

  title: {
    flexGrow: 1,
  },

  drawerPaper: {
    maxHeight: "100vh",
    height: "100vh",
    overflowY: "hidden",
    backgroundColor: "#F8F8F8",
    overflowX: "hidden",
    position: "relative",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    //zIndex: 2000,
  },
  button: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    width: 60,

    [theme.breakpoints.up("sm")]: {
      width: 60,
    },

    zIndex: 0,
  },

  appBarSpacer: theme.mixins.toolbar,

  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },

  //Adjust padding to bring paper items closer/farther from the edge
  grid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },

  fixedHeight: {
    height: 240,
  },

  menuContainer: {
    textAlign: "center",
    alignItems: "center",
    padding: "36px",
    width: "260px",
    height: "140px",
  },

  menuContainerMobile: {
    textAlign: "center",
    alignItems: "center",
    padding: "36px",
    paddingTop: 0,
    height: "140px",
  },
  textCenter: {
    textAlign: "center",
  },
  menuContainerClose: {
    padding: "4px",
  },

  logo: {
    borderRadius: "5%",
    boxShadow: "0px 2.5px 5px grey",
  },
  logoContainer: {
    width: "100%",
  },
  version: {
    color: "#687895",
    position: "relative",
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: "500"
  },
  rootMenu: {
    flexGrow: 1,
  },
  gridItem: {
    maxWidth: "100%",
    overflow: "hidden",
  },
}));

export default function Menus(props) {
  const [appVersion, setAppVersion] = React.useState('v1.1.1');
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuSelected, setMenuSelected] = React.useState(null);

  const [mobileMenu, setMobileMenu] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const permissions = usePermissions();
  const org = useOrganization();

  function openMobileMenu() {
    setMobileMenuOpen(true);
    if (props.setSideOpen) {
      props.setSideOpen(true);
    }
  }

  function closeMobileMenu() {
    setMobileMenuOpen(false);
  }

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 620;

  const classes = useStyles();
  const api = new API();

  const handleDrawerClicked = () => {
    props.sideMenuOpen(!open);
    setOpen(!open);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuSelected(event.currentTarget.id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let history = useHistory();

  let isAdmin = props.user && props.user.user.is_superuser;

  const [orgMenuOpen, setOrgMenuOpen] = useState(false);
  function selectOrg() {
    setOrgMenuOpen(true);
    setMenuSelected("team");
  }

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    if (window.innerWidth < 800) {
      setMobileMenu(true);
      if (props.setSideOpen) {
        props.setSideOpen(true);
      }
    } else {
      setMobileMenu(false);
      if (props.setSideOpen) {
        props.setSideOpen(false);
      }
    }
    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (width < 600) {
      setMobileMenu(true);
      if (props.setSideOpen) {
        props.setSideOpen(true);
      }
    } else {
      if (props.setSideOpen) {
        props.setSideOpen(false);
      }
      setMobileMenu(false);
    }
  }, [width]);

  return (
    <div id="menu-parent"
      style={{
        zIndex: 4,
        width: mobileMenu ? 0 : drawerWidth,
        height: mobileMenu ? "100%" : "100%",
        minWidth: mobileMenu ? 0 : drawerWidth,
      }}
    >
      <div className={classes.rootMenu}>
        {!mobileMenuOpen && mobileMenu && (
          <div
            style={{
              overflowY: "hidden",
              width: width,
              height: "48px",
              backgroundColor: "white",
            }}
          >
            <Menu
              id="profile-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {props.user && permissions && permissions.team_admin && menuSelected && menuSelected === "profile" && (
                <MenuItem disabled key="label">
                  Team Settings
                </MenuItem>
              )}
              {props.user &&
                menuSelected &&
                menuSelected === "profile" && [
                  <MenuItem key="select_org" onClick={selectOrg}>
                    Select Active Team
                  </MenuItem>,
                  <MenuItem
                    key="profile"
                    onClick={() => {
                      handleClose();
                      history.push("/profile");
                    }}
                  >
                    My Profile
                  </MenuItem>,
                  <MenuItem key="logout" onClick={logout}>
                    Logout
                  </MenuItem>,
                ]}

              {props.user && menuSelected && menuSelected === "team" && (
                <MenuItem disabled>Select Team</MenuItem>
              )}

              {props.user &&
                menuSelected &&
                menuSelected === "team" &&
                orgMenuOpen &&
                props.user.user.org_permissions.map(org_permission => {
                  return (
                    <MenuItem
                      key={org_permission.pk}
                      selected={org_permission.organization.pk === org.pk}
                      onClick={(event) => {
                        props.setOrganization(event, org_permission);
                        setOrgMenuOpen(false);
                      }}
                    >
                      {org_permission.organization.name}
                    </MenuItem>
                  );
                })}
            </Menu>{" "}
            <IconButton onClick={openMobileMenu}>
              <MenuIcon />
            </IconButton>
            <div
              style={{
                float: "right",
                overflow: "hidden",
                paddingRight: "16px",
              }}
            >
              <List
                dense
                component="nav"
                aria-label="org-select"
                style={{ padding: 0 }}
              >
                <ListItem
                  button
                  className={classes.button}
                  disableRipple
                  disableTouchRipple
                  style={{ width: 50, height: 50 }}
                  id="profile"
                  aria-haspopup="true"
                  aria-controls="lock-menu"
                  aria-label="org-select"
                  onClick={handleClick}
                >
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                </ListItem>
              </List>
            </div>
          </div>
        )}
      </div>
      {!mobileMenu && (
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <Grid
            container
            alignItems="center"
            justify="center"
            className={clsx(
              classes.menuContainer,
              !open && classes.menuContainerClose
            )}
          >
            <Grid item className={classes.logoContainer}>
              <img
                className={classes.logo}
                src={FoodbyteLogo}
                width="64"
                height="64"
              />

            </Grid>
            <span className={classes.version}>{appVersion}</span>
          </Grid>

          <Divider />

          <div style={{ height: "calc(100vh - 240px)", width: '260px' }}>
            <NavList
              classes={classes}
              sideMenuOpen={props.sideMenuOpen}
              setOrganization={props.setOrganization}
              anchorEl={anchorEl}
              handleClose={handleClose}
              logout={logout}
              menuSelected={menuSelected}
              handleClick={handleClick}
              org={org}
              open={open}
              user={props.user}
            />
          </div>

          <Divider />

          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ position: "absolute", bottom: 0 }}
          >
            {" "}
            <Grid item xs={12} className={classes.gridItem}>
              <Divider />
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <List
                dense
                component="nav"
                aria-label="org-select"
                style={{ padding: 0 }}
              ></List>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}></Grid>
          </Grid>
          {org && (
            <Menu
              id="profile-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {props.user && permissions && permissions.team_admin && menuSelected && menuSelected === "profile" && (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    history.push("/settings");
                  }}
                  key="label"
                >
                  Team Settings
                </MenuItem>
              )}
              {props.user &&
                menuSelected &&
                menuSelected === "profile" && [
                  <MenuItem key="select_org" onClick={selectOrg}>
                    Select Team
                  </MenuItem>,
                  <MenuItem
                    key="profile"
                    onClick={() => {
                      handleClose();
                      history.push("/profile");
                    }}
                  >
                    Profile
                  </MenuItem>,
                  <MenuItem key="logout" onClick={logout}>
                    Logout
                  </MenuItem>,
                ]}

              {props.user && menuSelected && menuSelected === "team" && (
                <MenuItem disabled>Select active team</MenuItem>
              )}

              {props.user &&
                menuSelected &&
                menuSelected === "team" &&
                orgMenuOpen &&
                props.user.user.org_permissions.map(org_permission => {
                  return (
                    <MenuItem
                      key={org_permission.pk}
                      selected={org_permission.organization.pk === org.pk}
                      onClick={(event) => {
                        props.setOrganization(event, org_permission);
                      }}
                    >
                      {org_permission.organization.name}
                    </MenuItem>
                  );
                })}
            </Menu>
          )}
          {props.user && (
            <Grid
              item
              style={{ height: "100px" }}
              container
              alignContent="flex-end"
            >
              <div
                style={{
                  width: "100%",
                  height: "100px",
                }}
              >
                <Grid item container>
                  {" "}
                  <Grid item container style={{ margin: "20px 20px 0 20px" }}>
                    <Grid item xs={2} style={{ marginTop: "6px" }}>
                      <AvatarComponent rounded user={props.user}></AvatarComponent>
                    </Grid>
                    <Grid item xs={10} container style={{ paddingTop: '4px', paddingLeft: '4px' }}>
                      <Grid
                        item
                        xs={12}
                        style={{ marginLeft: "12px", marginTop: "6px" }}
                      >
                        {props.user.user && (
                          <div>
                            <Typography
                              style={{
                                fontFamily: "Roboto",
                                fontSize: "12px",
                              }}
                            >
                              {props.user.user.first_name}{" "}
                              {props.user.user.last_name}
                            </Typography>
                          </div>
                        )}
                      </Grid>

                      {props.user.user.position && (
                        <Grid item xs={12}>
                          <div style={{ paddingLeft: "8px" }}>
                            <Typography
                              style={{
                                marginLeft: "8px",
                                fontFamily: "Roboto",
                                fontSize: "12px",
                              }}
                            >
                              {props.user.user.position}
                            </Typography>
                          </div>
                        </Grid>
                      )}

                      <Grid
                        item
                        xs={12}
                        style={{ marginLeft: "12px", marginRight: "40px" }}
                      >
                        {org && (
                          <div>
                            <Typography
                              style={{
                                fontFamily: "Roboto",
                                fontSize: "12px",
                                wordWrap: "normal",
                                overflowWrap: "anywhere",
                                width: "160px",
                              }}
                            >
                              {org.name}
                            </Typography>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item container >
                    <Grid item xs={12} className={classes.textAlign} >
                      <span className={classes.version}>{appVersion}</span>
                    </Grid>
                  </Grid> */}
                </Grid>
              </div>
            </Grid>
          )}
        </Drawer>
      )}

      {mobileMenu && (
        <Drawer
          onBackdropClick={closeMobileMenu}
          variant="temporary"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={mobileMenuOpen}
        >
          <Grid container alignItems="center" justify="flex-end">
            <Grid item>
              <IconButton onClick={closeMobileMenu}>
                <ChevronLeftIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justify="center"
            className={clsx(
              classes.menuContainerMobile,
              !open && classes.menuContainerClose
            )}
          >
              <Grid item className={classes.logoContainer}>
              <img
                className={classes.logo}
                src={FoodbyteLogo}
                width="64"
                height="64"
              />

            </Grid>
            <span className={classes.version}>{appVersion}</span>
            {/* <Grid item>
              <img
                className={classes.logo}
                src={FoodbyteLogo}
                width="75"
                height="75"
              />
            </Grid> */}
          </Grid>

          <Divider />

          <Grid container style={{ height: "100vh" }} spacing={0}>
            <NavList
              classes={classes}
              sideMenuOpen={props.sideMenuOpen}
              setOrganization={props.setOrganization}
              anchorEl={anchorEl}
              handleClose={handleClose}
              logout={logout}
              menuSelected={menuSelected}
              handleClick={handleClick}
              org={org}
              open={open}
              user={props.user}
            />
          </Grid>

          {org && (
            <Menu
              id="profile-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {props.user && permissions && permissions.team_admin && menuSelected && menuSelected === "profile" && (
                <MenuItem disabled key="label">
                  Settings
                </MenuItem>
              )}
              {props.user &&
                menuSelected &&
                menuSelected === "profile" && [
                  <MenuItem key="select_org" onClick={selectOrg}>
                    Select Team
                  </MenuItem>,
                  <MenuItem
                    key="profile"
                    onClick={() => {
                      handleClose();
                      history.push("/profile");
                    }}
                  >
                    Profile
                  </MenuItem>,
                  <MenuItem key="logout" onClick={logout}>
                    Logout
                  </MenuItem>,
                ]}

              {props.user && menuSelected && menuSelected === "team" && (
                <MenuItem disabled>Select active team</MenuItem>
              )}

              {props.user &&
                menuSelected &&
                menuSelected === "team" &&
                props.user.user.organizations.map((organization) => {
                  return (
                    <MenuItem
                      key={organization.pk}
                      selected={organization.organization.pk === org.pk}
                      onClick={(event) => {
                        props.setOrganization(event, organization);
                      }}
                    >
                      {organization.organization.name}
                    </MenuItem>
                  );
                })}
            </Menu>
          )}
          {props.user && (
            <Grid
              item
              style={{ height: "100px" }}
              container
              alignContent="flex-end"
            >
              <div
                style={{
                  width: "100%",
                  height: "100px",
                }}
              >
                <Divider style={{ width: "100%" }}></Divider>
                <Grid item container>
                  {" "}
                  <Grid item container style={{ margin: "20px" }}>
                    <Grid item xs={2} style={{ marginTop: "6px" }}>
                      <AvatarComponent rounded user={props.user}></AvatarComponent>{" "}
                    </Grid>
                    <Grid item xs={10} container>
                      <Grid
                        item
                        xs={12}
                        style={{ marginLeft: "12px", marginTop: "6px" }}
                      >
                        {props.user.user && (
                          <div>
                            <Typography
                              style={{
                                fontFamily: "Roboto",
                                fontSize: "12px",
                              }}
                            >
                              {props.user.user.first_name}{" "}
                              {props.user.user.last_name}
                            </Typography>
                          </div>
                        )}
                      </Grid>

                      {props.user.user.position && (
                        <Grid item xs={12}>
                          <div style={{ paddingLeft: "8px" }}>
                            <Typography
                              style={{
                                marginLeft: "8px",
                                fontFamily: "Roboto",
                                fontSize: "12px",
                              }}
                            >
                              {props.user.user.position}
                            </Typography>
                          </div>
                        </Grid>
                      )}

                      <Grid
                        item
                        xs={12}
                        style={{ marginLeft: "12px", marginRight: "40px" }}
                      >
                        {org && (
                          <div>
                            <Typography
                              style={{
                                fontFamily: "Roboto",
                                fontSize: "12px",
                                wordWrap: "normal",
                                overflowWrap: "anywhere",
                                width: "160px",
                              }}
                            >
                              {org.name}
                            </Typography>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </div>
            </Grid>
          )}
        </Drawer>
      )}
    </div>
  );
}

Menus.propTypes = {
  sideMenuOpen: PropTypes.func,
};
