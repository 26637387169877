class InventoryAdjustmentAPI {
    constructor(api, version) {
      this.api = api;
      this.version = version;
    }
    
    listInventoryAdjustments(inventory_adjustment_pk) {
      if (typeof inventory_adjustment_pk === 'number') {
        return this.api.get(`${this.version}/inventory_adjustments/${inventory_adjustment_pk}/`);
      }
    
        return this.api.get(`${this.version}/inventory_adjustments/`)
    }
    
    createInventoryAdjustment(payload) {
      return this.api.post(`${this.version}/inventory_adjustments/`, payload);
    }
    
    updateInventoryAdjustment(payload) {
      return this.api.put(`${this.version}/inventory_adjustments/${payload.pk}/`, payload);
    }
    
    deleteInventoryAdjustment(pk) {
      return this.api.delete(`${this.version}/inventory_adjustments/${pk}/`);
    }
    
  }
    
  export default InventoryAdjustmentAPI;