import React, { useEffect, useState, useReducer } from 'react';
import { useParams, useHistory, Prompt } from 'react-router-dom';

import useAPI from 'hooks/useAPI';
import useStatusMessage from 'hooks/useStatusMessage';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from "@material-ui/core/Slide";

import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from "@material-ui/icons/Cancel";

import SanitizeHtml from 'Components/SanitizeHtml';
import CustomTextField from 'Monitoring/CustomFields/CustomTextField';

import ReactSignatureCreator from 'Components/ReactSignatureCreator';

import { makeStyles } from "@material-ui/styles"

import CustomDateTimeField from 'Monitoring/CustomFields/CustomDateTimeField';
import CustomRadioButtonField from 'Monitoring/CustomFields/CustomRadioButtonField';
import CustomSelectField from 'Monitoring/CustomFields/CustomSelectField';
import CustomNumberField from 'Monitoring/CustomFields/CustomNumberField';
import CustomCheckboxField from 'Monitoring/CustomFields/CustomCheckboxField';

import useDesktop from 'hooks/useDesktop';

import LogSOP from './LogSOP';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const initialState = {};
function reducer(submissions, action) {

	if (action.type === 'custom_field') {
		const customField = action.payload.customField;
		const fieldId = customField.id;
		const fieldType = customField.field_type
		const fieldValue = action.payload.value;

		let updatedSubmissions;
		// if (fieldType === "checkbox") {
		// 	updatedSubmissions = { ...submissions, [fieldId]: { ...submissions[fieldId], [action.payload.value.id]: fieldValue } };
		// } else {
			updatedSubmissions = { ...submissions, [fieldId]: fieldValue };
		// }
		return updatedSubmissions;
	}
}

const useStyles = makeStyles({
	underline: {
		"&&:before": {
			borderBottom: 'none',
		}
	},

	input: {
		fontSize: '14px',
		fontFamily: 'Roboto',
		backgroundColor: '#F5F5F5',
		padding: '16px',
	},

	multiline: {
		padding: '0px',
	},
});


function FormFields({ log, logRecord, submissions, handleCustomField, errors, readOnly }) {

	const classes = useStyles();

	if (!log) {
		return <CircularProgress />
	}

	return (
		<Grid container item xs={12}>

			<Grid container item xs={12} style={{ borderBottom: '1px solid #E5E5E5', padding: '8px 0px' }}>
				<Grid item xs={12}>
					<Typography style={{ fontSize: '14px', fontWeight: 700 }}>
						Field
					</Typography>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				{!log.read_tasks[0].custom_fields.length &&
					<Paper elevation={0} style={{ width: '100%', backgroundColor: '#F5F5F5', margin: '16px 0px', padding: '16px 0px' }}>
						<Grid container item xs={12} justify="center" alignItems="center">
							<Typography style={{ fontWeight: 500, fontSize: '14px', opacity: 0.7 }}>
								There are no fields attached to this log...
							</Typography>
						</Grid>
					</Paper>
				}
				{log.read_tasks[0].read_custom_fields.map(customField => {
					return (
						<>
							{customField.field_type === "textfield" &&
								<CustomTextField
									style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
									//textFieldClasses={classes}
									error={errors[customField.id]}
									id={customField.id}
									name={customField.name}
									displayName={customField.name}
									description={customField.description}
									readOnly={readOnly}
									required={customField.required}
									fullWidth
									value={submissions[customField.id] || (readOnly && logRecord && logRecord.custom_field_input[customField.id])}
									onChange={event => handleCustomField(event, customField)}
								/>
							}

							{customField.field_type === "number" &&
								<CustomNumberField
									style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
									textFieldClasses={classes}
									error={errors[customField.id]}
									id={customField.id}
									name={customField.name}
									displayName={customField.name}
									description={customField.description}
									readOnly={readOnly}
									required={customField.required}
									fullWidth
									value={submissions[customField.id] || (readOnly && logRecord && logRecord.custom_field_input[customField.id])}
									onChange={event => handleCustomField(event, customField)}
								/>
							}

							{customField.field_type === "radio" &&
								<CustomRadioButtonField
									style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
									id={customField.id}
									error={errors[customField.id]}
									name={customField.name}
									readOnly={readOnly}
									displayName={customField.name}
									description={customField.description}
									options={customField.radio_options}
									required={customField.required}
									value={submissions[customField.id] || (readOnly && logRecord && logRecord.custom_field_input[customField.id])}
									onChange={event => handleCustomField(event, customField)}
								//error={fakeError}
								/>
							}

							{customField.field_type === "datetime" &&
								<CustomDateTimeField
									style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
									id={customField.id}
									error={errors[customField.id]}
									name={customField.name}
									displayName={customField.name}
									description={customField.description}
									readOnly={readOnly}
									required={customField.required}
									value={submissions[customField.id] || (readOnly && logRecord && logRecord.custom_field_input[customField.id])}
									onChange={datetime => { handleCustomField(datetime, customField) }}
								//error={fakeError} 
								/>
							}

							{customField.field_type === "select" &&
								<CustomSelectField
									style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
									textFieldClasses={classes}
									id={customField.id}
									error={errors[customField.id]}
									name={customField.name}
									displayName={customField.name}
									description={customField.description}
									options={customField.select_options}
									required={customField.required}
									readOnly={readOnly}
									fullWidth
									value={submissions[customField.id] || (readOnly && logRecord && logRecord.custom_field_input[customField.id])}
									onChange={event => handleCustomField(event, customField)}
								/>
							}

							{customField.field_type === "checkbox" &&
								<CustomCheckboxField
									style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
									textFieldClasses={classes}
									id={customField.id}
									error={errors[customField.id]}
									name={customField.name}
									required={customField.required}
									displayName={customField.name}
									description={customField.description}
									options={customField.checkbox_options}
									readOnly={readOnly}
									fullWidth
									value={submissions[customField.id] || (readOnly && logRecord && logRecord.custom_field_input[customField.id])}
									onChange={checkedBox => handleCustomField(checkedBox, customField)}
								/>
							}
						</>
					)
				})}
			</Grid>
		</Grid>
	)
}

export default function LogEntry(props) {

	const params = useParams();
	const history = useHistory();
	const classes = useStyles();

	const [submissions, dispatch] = useReducer(reducer, initialState);
	const [signature, setSignature] = useState(null);
	const [validSignature, setValidSignature] = useState(false);
	const api = useAPI();

	const [notes, setNotes] = useState("");

	const [log, setLog] = useState(null);
	const [logRecord, setLogRecord] = useState(null);

	const [loading, setLoading] = useState(null);
	const [hasChanged, setHasChanged] = useState(false);
	const [disabled, setDisabled] = useState(false);

	const [updateMessage, status] = useStatusMessage();
	const [dialogOpen, setDialogOpen] = useState(false);
	const [errors, setErrors] = useState({});

	const onDesktop = useDesktop();

	useEffect(() => {
		setLoading(true);
		if (params.id && !props.readOnly && !props.edit) {
			api.getEventAPI().retrieveEvent(params.id).then(response => {
				setLog(response.data);
				setLoading(false);
			}).catch(error => {
				setLoading(false);
				updateMessage({ severity: "error", label: "Could not retrieve the event" });
			});
		}

		if (params.id && (props.readOnly || props.edit)) {
			api.getEventAPI().retrieveLogRecord(params.id).then(response => {
				setLog(response.data.log_when_submitted);
				setLogRecord(response.data);

				let logRecordValue = response.data;
				let customValues = Object.values(logRecordValue?.custom_field_input)
				let customFields = logRecordValue?.log_when_submitted?.read_tasks[0]?.read_custom_fields

				Object.keys(logRecordValue?.custom_field_input).forEach((key, index) => {
					let customField = customFields.filter(obj => obj.id == key)
					dispatch({
						type: 'custom_field', payload: {
							customField: customField.length ? customField[0] : {},
							value: customValues[index]
						}
					});
				})

				setNotes(logRecordValue.notes || '')
				setSignature(logRecordValue.signature || null)
				setLoading(false);
			}).catch(error => {
				console.error(error);
			});
		}
	}, []);


	function handleCustomFieldInput(event, customField) {
		let type = 'custom_field';
		let field_value;

		if (customField.field_type === "datetime" ) {
			//field_value = event.toDateString() + " " + event.toTimeString();
			//field_value = event.toLocaleString();
			field_value = event;

		}else if(customField.field_type === "checkbox"){
			field_value = event.value;
		} else {
			field_value = event.target.value;
		}

		setHasChanged(true);
		dispatch({ type: type, payload: { customField: customField, value: field_value } });
	}

	function saveDraft() {
		setHasChanged(false);
		let formInput = {
			log: log.id,
			custom_field_input: {
				...submissions
			},
			notes: notes || '',
			signature: '', // not saving the signature in the case of draft
			log_when_submitted: log,
			is_completed: false
		}
		upsert(formInput)

	}


	function submit() {
		let isValid = validate({ ...submissions })

		if (isValid && validSignature) {
			setHasChanged(false);

			let formInput = {
				log: log.id,
				custom_field_input: {
					...submissions
				},
				notes: notes,
				signature: signature,
				log_when_submitted: log,
				is_completed: true
			}

			upsert(formInput)
		}
	}

	function upsert(formInput) {

		if (logRecord && logRecord.id) {
			formInput.id = logRecord.id
			formInput.form_num = logRecord.form_num
			api.getLogsAPI().updateLogRecord(formInput).then(response => {
				if (props.onSubmit) {
					props.onSubmit();
				}
				history.goBack();
			}).catch(error => {
				console.log(error);
			})
		} else {
			api.getLogsAPI().saveLogRecord(formInput).then(response => {
				if (props.onSubmit) {
					props.onSubmit();
				}
				history.goBack();
			}).catch(error => {
				console.log(error);
			})
		}

	}

	function validate(unvalidatedSubmissions) {
		// check for required fields 

		let errorMessages = {};
		let isValid = true;

		if (!validSignature) {
			return false;
		}

		for (let field of log.read_tasks[0].read_custom_fields) {

			if (field.required &&
				(!unvalidatedSubmissions[field.id] ||
					(unvalidatedSubmissions[field.id] && unvalidatedSubmissions[field.id] == null))) {
				errorMessages[field.id] = "This field is required.";
				isValid = false;
			}
		}

		if (!isValid) {
			setErrors(errorMessages);
			return false;
		}
		else {
			setErrors({});
		}

		return true;
	}

	function checkSignature(sigPad) {
		let sig = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
		setSignature(sig);
		setValidSignature(true);
		setHasChanged(true);
	}

	function validSignatureCheck(validCheck) {
		setValidSignature(validCheck);
	}

	return (
		<Dialog open={true} fullScreen TransitionComponent={Transition} style={{ padding: '0px' }}>
			{log &&
				<LogSOP sop={log.read_tasks[0]} open={dialogOpen} setOpen={setDialogOpen} />
			}

			<Prompt
				when={hasChanged}
				message="You have unsaved changes, are you sure you want to leave?"
			/>

			{onDesktop &&
				<DialogTitle style={{ backgroundColor: "rgb(234,234,234, 0.5)" }}>
					<Grid container item xs={12} alignItems="center">
						<Grid item xs={6}>
							<Typography>
								{(props.readOnly ? "View" : (props.edit ? "Edit" : "New")) + " Log Entry"}
							</Typography>
						</Grid>
						<Grid item container xs={6} justify="flex-end">
							<IconButton onClick={() => history.goBack()}>
								<CancelIcon />
							</IconButton>
						</Grid>
					</Grid>
				</DialogTitle>
			}
			<DialogContent style={{ backgroundColor: "rgb(234,234,234, 0.5)", padding: !onDesktop ? "0px" : null }}>
				<Grid container item xs={12} justify="center" style={{ padding: onDesktop ? '0% 5% 0% 5%' : '0px', height: '100%' }}>
					<Paper elevation={0} style={{ width: '100%', maxWidth: onDesktop ? '900px' : '100%', padding: onDesktop ? '2.5% 5% 0% 5%' : '0px' }}>
						<Grid container item xs={12} style={{ height: loading ? '100%' : null }} justify="center" alignItems="center">
							{loading &&
								<CircularProgress />
							}
						</Grid>

						{!loading && log &&
							<Grid container item xs={12} style={{ padding: !onDesktop ? "16px" : null }}>
								<Grid item xs={8} style={{ paddingBottom: '16px', borderBottom: '1px solid #E5E5E5' }}>
									<Typography style={{ fontSize: '34px', fontWeight: 500, lineHeight: '51px' }}>
										{log.name}
									</Typography>
								</Grid>
								<Grid container item xs={4} justify="flex-end" alignItems="center" style={{ paddingBottom: '16px', borderBottom: '1px solid #E5E5E5' }}>
									<Button disableElevation variant="contained" size="small" color="primary" onClick={() => setDialogOpen(true)}>View SOP</Button>
								</Grid>
								<Grid item xs={12} style={{ paddingBottom: '32px' }}>
									<Typography style={{ fontSize: '14px', fontWeight: 400, lineHeight: '21px' }}>
										<SanitizeHtml value={log.details} style={{ maxHeight: '250px', overflow: 'auto', padding: '16px 0px' }} />
									</Typography>
								</Grid>
								<Grid container item xs={12}>
									<FormFields log={log} logRecord={logRecord} readOnly={props.readOnly} submissions={submissions} errors={errors} handleCustomField={handleCustomFieldInput} />
								</Grid>
								<Grid container item xs={12} style={{ margin: '32px 0px', borderBottom: '1px solid #E5E5E5' }}>
									<Typography style={{ fontSize: '20px', fontWeight: 500, padding: '16px 0px' }}>
										Notes
									</Typography>
								</Grid>
								<Grid container item xs={12}>
									<TextField
										InputProps={{
											disableUnderline: true,
											classes: {
												...classes
											}
										}}
										disabled={props.readOnly}
										multiline
										minRows={3}
										variant="filled"
										value={notes || (logRecord && logRecord.notes)}
										onChange={event => { setNotes(event.target.value); setHasChanged(true) }}
										fullWidth={true}
									/>
								</Grid>
								<Grid container item xs={12} style={{ margin: '32px 0px', borderBottom: '1px solid #E5E5E5' }}>
									<Typography style={{ fontSize: '20px', fontWeight: 500, padding: '16px 0px' }}>
										Signature
									</Typography>
								</Grid>
								<Grid item xs={12}>
									{!props.readOnly && (
										<ReactSignatureCreator checkSignature={checkSignature} initialSignature={signature} validSignatureCheck={validSignatureCheck} />
									)}
									{props.readOnly && <img src={logRecord.signature} />}
								</Grid>
								<Grid container item xs={12} justify='flex-end' alignItems="center" style={{ padding: '16px 0px' }}>
									<Button disableElevation disabled={loading} variant="contained" color="secondary" onClick={() => history.goBack()} style={{ margin: "4px" }}>
										{props.readOnly ? 'Close' : 'Cancel'}
									</Button>
									{!props.readOnly &&
										<Button disableElevation variant="outlined" color="primary" onClick={saveDraft} style={{ margin: "4px" }}>Save</Button>
									}

									{!props.readOnly &&
										<Button disableElevation disabled={loading || !validSignature} variant="contained" color="primary" style={{ marginLeft: "4px" }} onClick={submit}>Submit</Button>
									}

								</Grid>
							</Grid>
						}
					</Paper>
				</Grid>



			</DialogContent>
		</Dialog>
	)
}