import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import GroupedBarCustom from './GroupedBarCustom';
import GroupedRowCustom from './GroupedRowCustom';
import RegularRowCustom from './RegularRowCustom';
import GroupedBodyCustom from './GroupedBodyCustom';
import GroupedActionsCustom from './GroupedActionsCustom';
import HeaderCustom from './HeaderCustom';
import ToolbarCustom from './ToolbarCustom';
import PropTypes from 'prop-types';

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

//Done so that storybook has the correct symbols
const tableIcons = {
  Add: AddBox,
  Check: Check,
  Clear: Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit: Edit,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage: FirstPage,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search: Search,
  SortArrow: ArrowUpward,
  ThirdStateCheck: Remove,
  ViewColumn: ViewColumn
};

const useStyles = makeStyles(theme => ({
  paperContainer:{
    width: '100%', 
    backgroundColor: theme.palette.background.default,
  }
}))

export default function GroupedTable(props) {
  const classesLocal = useStyles()
  const theme = useTheme();

  const [columns, setColumns] = useState([]);
  const [columnInfo, setColumnInfo] = useState(props.columnInfo ? props.columnInfo : {});
  const [grouping, setGrouping] = useState( !(props.grouping == null) ? props.grouping : true);
  const [columnSearching, setColumnSearching] = useState( !(props.columnSearching == null) ? props.columnSearching : false);
  const [selectedRows, setSelectedRows] = useState([]); //stores the row objects themselves
  const [selectedRowIndices, setSelectedRowIndices] = useState([]); //stores the index, as normally MT has these as the most nested element
  const [groupsCount, setGroupsCount] = useState(0);
  const [width, setWidth] = useState(null);
  const [initialWidth, setInitialWidth] = useState(null);
  

  useEffect(() => { 
    if(!props.preSelectedRows) {
      return;
    }

    let preSelectedRowObjects = props.preSelectedRows.map((rowKey) => {
      return props.data[rowKey];
    })

    setSelectedRows(preSelectedRowObjects);
    setSelectedRowIndices(props.preSelectedRows);

  }, [])

  /*useEffect(() => {
    props.columns.forEach((column) => {
      if(width === null && initialWidth === null) {
        setWidth(column.tableData.width);
        setInitialWidth(column.tableData.initialWidth);
      }
      else {
        delete column.tableData.width;
        delete column.tableData.initialWidth;
        column.tableData.width = width;
        column.tableData.initialWidth = initialWidth;
      }
    })
  }, [props.columns])*/

  /**
   * Takes a columns key and formats it to be used as a title by splitting the camel case into spaces.
   * 
   * @param {string} colKey - The key used for a column in the table
   */
  function formatColKey(colKey) {
    if(colKey.indexOf('_') != -1) {
      var splitString = colKey.split(/(?=[_])/gm);
    }
    else {
      var splitString = colKey.split(/(?=[A-Z])/gm);
    }
    splitString[0] = splitString[0].charAt(0).toUpperCase() + splitString[0].slice(1);

    let formattedString = '';
    splitString.forEach((word) => {
      formattedString = formattedString + word.replace('_', '').charAt(0).toUpperCase() + word.replace('_', '').slice(1) + ' ';
    })

    return formattedString;
  }

  useEffect(() => {
    if(!props.data || props.data.length == 0) {
      return;
    }

    let extractedColInfo = [];
    let sampleRow = props.data[0];

    if(columns.length == 0) {
      Object.keys(sampleRow).forEach((col) => {
        //Material table pushes this for internal purposes, ignore and don't display
        //Also don't bother displaying pk, users won't really need it.
        if(col == 'tableData' || col == 'pk') {
          return;
        }


        extractedColInfo.push({field: col, title: formatColKey(col),  ...(columnInfo[col]), })
      })
      setColumns(extractedColInfo);
    }

  }, [props.data])

  const handleChecked = () => {
    setColumnSearching(!columnSearching);
  };

  //Weirdly this hook was not native to material table, it's added custom here.
  //Used here to highlight grouped columns.
  function groupAdded(groupedColumns) {

    //Don't update if not needed as it's slow and unfocuses the window.
    if(groupedColumns.length == groupsCount) {
      return;
    }
    
    let newColumnInfo = [...columns];

    //This loop is unfortuneately necessary as we can't get a hook to the newly added column, just all grouped columns.
    groupedColumns.forEach((column, count) => {
      //todo make this add some style to the row
      let fieldIndex = column.tableData.id;

      let newCellStyle = (columns[fieldIndex] && columns[fieldIndex].cellStyle) ? {...columns[fieldIndex].cellStyle} : {};

      //Progressively darker blue-grays as the nesting increases
      let newRed = Math.max(221 - 15*count, 80);
      let newGreen = Math.max(221 - 15*count, 80);
      let newBlue = Math.max(238 - 10*count, 100);

      newCellStyle.backgroundColor = 'rgb(' + newRed + ',' + newGreen + ',' + newBlue +')';

      newColumnInfo[fieldIndex].cellStyle = {...newCellStyle};
      
    });
    setGroupsCount(groupedColumns.length);
    setColumns(newColumnInfo);
  }

  //Used to unhighlight columns that are no longer grouped.
  function groupRemoved(groupedColumn, index) {

    let field = groupedColumn.field;
    let fieldIndex = groupedColumn.tableData.id;
    let newColumnInfo = [...columns];

    //The background color prop needs to be removed for highlighting on hover,
    //so we remove either just that, or the whole cell style prop.
    if(columns[fieldIndex] && columns[fieldIndex].cellStyle) {
      let newCellStyle = {...columns[fieldIndex].cellStyle};
      delete newCellStyle.backgroundColor;
      newColumnInfo[fieldIndex].cellStyle = {...newCellStyle};
    }
    else {
      delete newColumnInfo[fieldIndex].cellStyle;
    }

    setColumns(newColumnInfo);
  }

  function getRowsRecursively(groupData) {
    let rows = groupData.data;
    
    groupData.groups.forEach((group) => {
      rows = rows.concat(getRowsRecursively(group));
    })
    return rows;
  }

  function handleGroupSelect(column, groupData, isSelected) {
    let rows = groupData.data;

    if(groupData.groups && groupData.groups.length > 0) {
      groupData.groups.forEach((group) => {
        rows = rows.concat(getRowsRecursively(group));
      });
    }

    if(!isSelected) {
      selectMultipleRows(rows);
    }
    else {
      unselectMultipleRows(rows);
    }
  }

  function selectMultipleRows(rows) {
    let newSelectedRows = [ ...selectedRows ];
    let newSelectedRowIndices = [ ...selectedRowIndices ];

    rows.forEach((rowData) => {
      let rowIndex = rowData.tableData.id;
      let rowIndexCachedIndex = newSelectedRowIndices.indexOf(rowIndex);

      //Don't have it in there twice, check to make sure it's actually new
      if(rowIndexCachedIndex == -1) {
        newSelectedRows.push(rowData);
        newSelectedRowIndices.push(rowIndex);
      }
    })
    setSelectedRows( newSelectedRows );
    setSelectedRowIndices( newSelectedRowIndices );
  }

  function unselectMultipleRows(rows) {
    let newSelectedRows = [ ...selectedRows ];
    let newSelectedRowIndices = [ ...selectedRowIndices ];

    rows.forEach((rowData) => {
      let rowIndex = rowData.tableData.id;
      let rowIndexCachedIndex = newSelectedRowIndices.indexOf(rowIndex);

      if(rowIndexCachedIndex != -1) {
        newSelectedRows.splice(rowIndexCachedIndex, 1);
        newSelectedRowIndices.splice(rowIndexCachedIndex, 1);
      }
    })
    props.selectionChange(newSelectedRows);
    setSelectedRows( newSelectedRows );
    setSelectedRowIndices( newSelectedRowIndices );
  }

  function selectionPropsTest(rows) {
    if(rows.length > 0) {
      props.selectionChange(rows);
      selectMultipleRows(rows);
    }
    else {
      unselectMultipleRows(selectedRows);
    }
  }

  function handleRowSelection(event, rowData) {
    let rowIndex = rowData.tableData.id;

    let newSelectedRows = [ ...selectedRows ];
    let newSelectedRowIndices = [ ...selectedRowIndices ];

    let rowIndexCachedIndex = newSelectedRowIndices.indexOf(rowIndex);

    if(rowIndexCachedIndex == -1) {
      newSelectedRows.push(rowData);
      newSelectedRowIndices.push(rowIndex);
    }
    else {
      newSelectedRows.splice(rowIndexCachedIndex, 1);
      newSelectedRowIndices.splice(rowIndexCachedIndex, 1);
    }

    if(props.selectionChange) {
      props.selectionChange(newSelectedRows);
    }

    setSelectedRows( newSelectedRows );
    setSelectedRowIndices( newSelectedRowIndices );
  }
  //todo regular grouping isn't working anymore, fix if we need it, but looks like we're going tabs now.
  return (
      <Grid container spacing={0} style={{minWidth: props.noMinWidth ? "0px" : '1000px', margin: '0px'}}>
        <Grid item xs={12}>
          <MaterialTable
            components={{
              Container: propsLocal => <Paper {...propsLocal} elevation={0} className={classesLocal.paperContainer} /*style={{height: '819px'}}*/ />,
              Groupbar: propsLocal => <GroupedBarCustom {...propsLocal} groupAdded={groupAdded} useTabs={props.useTabs} filterButtons={props.filterButtons} />,
              GroupRow: propsLocal => <GroupedRowCustom {...propsLocal} handleGroupSelect={handleGroupSelect} selected={selectedRowIndices} selection={props.selection} />,
              Row: propsLocal => <RegularRowCustom  {...propsLocal} selectedRowIndices={selectedRowIndices} handleRowSelection={handleRowSelection} />,
            //  //This header is for column headers.
            //  Header: propsLocal => <HeaderCustom {...propsLocal} /*headerStyle={{backgroundColor: "#ECECEC"}}*/ draggable={ props.draggable } filterButtons={props.filterButtons} selectedRows={selectedRows} dataLength={props.data ? props.data.length : 0} />,
              Toolbar: propsLocal => <ToolbarCustom {...propsLocal} filterButtons={props.filterButtons} customSelectedRows={selectedRows} theme={theme} />,
              Body: propsLocal => <GroupedBodyCustom {...propsLocal} />,
              Actions: propsLocal => <GroupedActionsCustom {...propsLocal} />,
            //  //Pagination: propsLocal => <PaginationCustom {...propsLocal} />
            //  //...props.components,
            //  //Actions: propsLocal => <div/>
            }}
            localization={props.localization}
            icons={tableIcons}
            isLoading={props.isLoading}
            title={props.title ? props.title : 'No Title'}
            columns={props.columns ? props.columns : columns.map((c) => ({ ...c, tableData: undefined }))}
            data={props.data}
            onGroupRemoved={groupRemoved}
            onSelectionChange={(rows) => {selectionPropsTest(rows)}}
            draggable={false}
            options={{
              grouping: false,
              filtering: columnSearching,
              selection: props.selection ? props.selection : false,
              actionsColumnIndex: -1,
              ...props.options,
              //exportButton: { csv: true },
            }}

            onRowClick={props.onRowClick}

            actions={
              [
                /*{
                  icon: (props) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={columnSearching}
                          onChange={handleChecked}
                          name="columnLevelSearches"
                          color="primary"
                        />
                      }
                      label="Column Level Searches"
                    />
                  ),
                  tooltip: 'Column Level Searches',
                  isFreeAction: true,
                  onClick: () => handleChecked
                },*/
                ...(props.actions ? props.actions : [])
              ]
            }

          />
        </Grid>
      </Grid>
  )
}

GroupedTable.propTypes = {
  columnInfo: PropTypes.arrayOf(PropTypes.object),
  grouping: PropTypes.bool,
  columnSearching: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  selection: PropTypes.bool,
  draggable: PropTypes.bool,
  isLoading: PropTypes.bool,
  useTabs: PropTypes.bool,
  filterButtons: PropTypes.array,
  onRowClick: PropTypes.func,
  title: PropTypes.string,
  options: PropTypes.object,
  columns: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.array,
  localization: PropTypes.object,
  selectionChange: PropTypes.func,
}
