import React, { useState } from "react"

import { DialogContent } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import API from "../../Api/Api";

import CrossContaminationPoint from "../CrossContaminationPoints/CrossContaminationPoint";

import EditHazardTemplateModal from "../Templates/Hazards/EditHazardTemplateModal";
import EditHazardModal from "../Templates/Hazards/EditHazardModal";

function EditCrossContaminationPointModal(props) {
  const [hazardModalOpen, setHazardModalOpen] = useState(false);

  const [hazardSelected, setHazardSelected] = useState(null);
  const [shouldUpdateCrossContaminationPoint, setShouldUpdateCrossContaminationPoint] = useState(false);
  const [shouldClose, setShouldClose] = useState(false);

  let api = new API();

  function handleHazardModalOpen() {
    setHazardModalOpen(true);
  }

  function handleHazardModalClosed(check) {
    if (!shouldClose) {
      setHazardModalOpen(false);
    } else {
      alert("Save changes before closing.")
    }
  }

  function modelClose() {
    setHazardModalOpen(false);
  }

  function hazardPicked(hazard) {
    handleHazardModalOpen();
    setHazardSelected(hazard);
  }

  function getAPI() {
    if (props.templateMode) {
      return api.getCrossContaminationPointTemplateAPI();
    }

    return api.getCrossContaminationPointAPI();
  }

  function save(cross_contamination_point) {
    if (props.save) {
      props.save(cross_contamination_point);
    } else {
      props.setShouldClose(false);
    }
  }

  function saveAndContinue(cross_contamination_point) {
    if (props.saveAndContinue) {
      props.saveAndContinue(cross_contamination_point);
    } else {
      props.setShouldClose(false);
      getAPI().updateCrossContaminationPoint(cross_contamination_point).then(e => {
        props.setShouldClose(false);
        props.modelClose();
      }).catch(e => {
        console.log("error");
      })
    }
  }

  function hazardHasChanged(hasChanged) {
    props.setShouldClose(hasChanged);
  }


  // TODO merge cross contamination point template with cross contamination point
  return (
    <div>
      <Dialog
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={props.open}
        setOpen={props.setOpen}
        onClose={props.handleModalClosed}
      >
        <DialogContent>
          {props.templateMode &&
            <CrossContaminationPoint
              templateMode={true}
              pk={props.cross_contamination_point_pk}
              shouldUpdate={shouldUpdateCrossContaminationPoint}
              setShouldUpdate={setShouldUpdateCrossContaminationPoint}
              save={save}
              modelClose={props.modelClose}
              saveAndContinue={saveAndContinue}
              hazardSelected={hazardSelected}
              setHazardSelected={hazardPicked}
            />
          }

          {!props.templateMode &&
            <CrossContaminationPoint pk={props.cross_contamination_point_pk}
              shouldUpdate={shouldUpdateCrossContaminationPoint}
              setShouldUpdate={setShouldUpdateCrossContaminationPoint}
              save={save}
              setCCP={props.setCCP}
              hasButton={true}
              setOpen={props.setOpen}
              modelClose={modelClose}
              setShouldClose={hazardHasChanged}
              saveAndContinue={saveAndContinue}
              hazardSelected={hazardSelected}
              setHazardSelected={hazardPicked}

            />
          }
        </DialogContent>
      </Dialog>

      {hazardSelected && props.templateMode &&
        <EditHazardTemplateModal open={hazardModalOpen} handleModalClosed={handleHazardModalClosed}
          hazard_pk={hazardSelected.pk} />
      }

      {hazardSelected && !props.templateMode &&
        <EditHazardModal handleModalClosed={handleHazardModalClosed} modelClose={modelClose}
          setShouldClose={setShouldClose} open={hazardModalOpen}
          hazard_pk={hazardSelected.pk} />
      }
    </div>
  )
}


export default EditCrossContaminationPointModal;
