import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ReactQuill from "react-quill";
import { DialogActions } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

import CancelIcon from "@material-ui/icons/Cancel";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { toolbarOptions } from "../../config" 

import GreyTextField from "Components/GreyTextField";

const useStyles = makeStyles({
  gridcontainer: {
    padding: "32px",
  },
  nestedcontainer: {
    padding: 0,
    paddingTop: 20,
  },
  griditem: {
    marginBottom: "18px",
  },
  outlinedbutton: {
    color: "#18bff6",
  },
  textfieldbackground: {
    background: "#fafafc",
  },
  titleText: {
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: 500,
    letterSpacing: "0.1px",
    lineHeight: "21px",
  },
  errorText: {
    color: "red",
    fontSize: "12px",
    fontFamily: "Roboto",
    fontWeight: 400,
    letterSpacing: "0.1px",
    lineHeight: "21px",
  },
});
function DocumentModal(props) {
  const classes = useStyles();

  function save(document) {
    if (newDocument.title === "") {
      setNameError(true);
    } else {
      if (props.setIsBlocking) {
        props.setIsBlocking(false);
      }
      if (props.save) {
        props.save(document);
      }
    }
  }
  function saveAndContinue(document) {
    if (newDocument.title === "") {
      setNameError(true);
    } else {
      if (props.setIsBlocking) {
        props.setIsBlocking(false);
      }
      if (props.saveAndContinue) {
        props.saveAndContinue(document);
      }
    }
  }
  const [newDocument, setNewDocument] = useState(
    props.document
      ? props.document
      : {
          title: "",
          description: "",
          organization: props.org.pk,
          user: props.user,
        }
  );

  useEffect(() => {
    if (props.document) {
      setNewDocument(props.document);
    }
  }, [props.document]);

  const [nameError, setNameError] = useState(false);
  //react quill issue out of range, disabled blocking for nnow
  function handleQuillChange(content, delta, source, editor) {
    // if (props.document && props.document.description.length > 2) {
    //   if (htmlToText(content) !== props.document.description) {
    //     if (props.setIsBlocking) {
    //       props.setIsBlocking(true);
    //     }
    //   }
    // }

    setNewDocument({ ...newDocument, description: content });
  }
  function handleChange(event) {
    if (props.setIsBlocking) {
      props.setIsBlocking(true);
    }
    setNewDocument({
      ...newDocument,
      [event.target.name]: event.target.value,
    });
    setNameError(false);
  }

  function htmlToText(html) {
    var temp = document.createElement("div");
    temp.innerHTML = html;
    return temp.textContent; // Or return temp.innerText if you need to return only visible text. It's slower.
  }

  return (
    <Dialog
      fullScreen
      open={props.documentModalOpen}
      onClose={props.handleDocumentDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={props.TransitionComponent}
      PaperProps={{style: {backgroundColor: '#F8F8F8'}}}
    >
      <DialogTitle style={{ borderBottom: "1px solid #eaeaea", padding: '16px' }}>
        <Grid container>
          <Grid container item xs={8} jusify="flex-start" alignItems="center">
            <Typography variant="h6">Edit this Document</Typography>
          </Grid>
          <Grid item container xs={4} justify="flex-end" alignItems="center">
            <IconButton onClick={props.handleDocumentDialogClose}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ height: "100%" }}>
        <Grid
          container
          item
          xs={12}
          style={{ padding: "16px", paddingRight: "0px" }}
          spacing={3}
          alignItems="center"
        >
          <Grid item xs={12} style={{ height: "12px" }}>
            <Typography className={classes.titleText}>
              Document Title
            </Typography>
          </Grid>
          {nameError && (
            <Grid item xs={12}>
              <Typography className={classes.errorText}>
                Please fill out title
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} style={{paddingTop: '24px'}}>
            <GreyTextField
              id="title"
              name="title"
              error={nameError}
              disabled={props.mode === "Preview"}
              defaultValue={props.document ? props.document.title : ""}
              //className={classes.textfieldbackground}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} style={{ height: "12px" }}>
            <Typography className={classes.titleText}>
              Document Description
            </Typography>
          </Grid>
          <Grid item xs={12} style={{paddingTop: '24px'}}>
            <GreyTextField
              id="preview"
              name="preview"
              disabled={props.mode === "Preview"}
              defaultValue={props.document ? props.document.preview : ""}
              //className={classes.textfieldbackground}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} style={{ height: "12px" }}>
            <Typography className={classes.titleText}>
              Document Content
            </Typography>
          </Grid>
          {/* style={{ minHeight: "100px", maxHeight: "300px", overflowY: "auto" }} */}
          
          <Grid item xs={12} style={{paddingTop: '24px'}}>
            <div className="event-editor" style={{backgroundColor: 'white'}}>
              <ReactQuill
                theme="snow"
                value={newDocument.description || ''}
                onChange={handleQuillChange}

                modules={{
                  toolbar: toolbarOptions
                }}
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          item
          xs={12}
          justify="flex-end"
          alignItems="center"
          alignContent="flex-end"
        >
          {/*props.document && (
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => props.delete(newDocument)}
            >
              Delete
            </Button>
          )*/}
          <Button
            color="secondary"
            variant="outlined"
            style={{ margin: "4px" }}
            onClick={() => props.handleModalClosed()}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            style={{ margin: "4px" }}
            onClick={() => save(newDocument)}
          >
            Save Changes
          </Button>
          <Button
            color="primary"
            variant="outlined"
            style={{ margin: "4px" }}
            onClick={() => saveAndContinue(newDocument)}
          >
            Save and Continue
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
export default DocumentModal;
