import React, {useEffect, useState, useContext} from "react";
import { useHistory, useRouteMatch, Switch, Route } from "react-router-dom";
import TabbableGroupedTable from "Components/GroupedTable/TabbableGroupTable";
import { OrgContext } from "hooks/useOrganization"

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import API from "Api/Api";
import MobileVerificationTasks from "./MobileVerificationTasks";
import VerificationForm from "Monitoring/Verification/VerificationForm";
import useDesktop from "hooks/useDesktop";
import { Typography } from "@material-ui/core";
import PropTypes from 'prop-types';


const statuses = {
  counted: false,
  column: 'read_status',
  tabs:[
    {title: 'Incomplete', value: 'Incomplete'},
    {title: 'Complete', value: 'Complete'},
    {title: 'All', value: 'All'},
  ]
}

const columns = [
  { title: "Name", field: "name"},
  { title: "Created", field: "time_created"},
  { title: "Status", field: "read_status"},
  { title: "Responsible", field: "responsible"},
];

export default function VerificationTasks(props) {
  const [allTasks, setAllTasks] = useState([]);
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const match = useRouteMatch();

  const org = useContext(OrgContext); 

  // useEffect(() => {
  //     let params = queryString.parse(window.location.search);
  //     let page = params.page;
  //     if (!onDesktop) {
  //         setCurrentPageSize(10);
  //         setPage(page);
  //         searchAllTasks(selectedTab, page, 10);
  //     }
  // }, [onDesktop]);

  useEffect(() => {
    getAllTasks();  
  }, []);
  
  function getAllTasks() {
    new API().getMonitoringAPI().getMyVerificationTasks().then(response => {
      setAllTasks(response.data);
      setLoading(false);
    }).catch(error => {
      console.log(error);
      setLoading(false);
    });
  }

  function getActionButtonName(rowData) {
    if (rowData.status === "COMPLETE") {
      return "View";
    }
    else {
      return "Open";
    }
  }
  
  let onDesktop = useDesktop();
  
  return (
    <>
      {loading && 
        <Grid container alignItems="center" justify="center" style={{width: '100%', height: '100%'}}>
          <Grid container item xs={12} alignItems="center" justify="center">
            <CircularProgress />
          </Grid>
        </Grid>
      }

      {props.user && !onDesktop && !loading &&
        <MobileVerificationTasks 
          user={props.user} 
          tasks={allTasks} 
          title={"My Verification Tasks"} 
          searchKeys={['name', 'responsible']}
        />
      }

      {props.user && onDesktop && !loading &&
        <TabbableGroupedTable 
          tabStatuses={statuses}
          loading={true}
          columns={columns}
          data={allTasks}
          components={{
            Container: props => <Paper {...props} elevation={0} />
          }}
          title="My Verification Tasks"
          emptyDataMessage="You have no verification tasks."
          actions={ 
            [
              rowData => ({
                icon: (props) => {
                  return (
                  <div style={{padding:"4px", paddingTop: "0px", paddingBottom: "0px", background: "#18bff6", borderRadius: "4px"}}>
                    <Typography style={{margin: '4px', color: "white", fontWeight: "400", lineHeight: "1.50rem", fontFamily: "Roboto"}}>
                      { getActionButtonName(rowData) }
                    </Typography>
                  </div>
                  )
                },
                onClick: (event, rowData) => history.push(`${match.path}/verification/${rowData.id}/`),
              }),
            ]
          }
          onRowClick={(event, rowData) => {
            history.push(`${match.path}/verification/${rowData.id}/`)  
        }}
      />
      }


      <Switch>
        <Route path={`${match.path}/verification/:pk/`}>
          <VerificationForm onComplete={() => getAllTasks()} user={props.user}/>
        </Route>
      </Switch>
    </>
  )
}
VerificationTasks.propTypes = {
  allTasks: PropTypes.bool,
  user: PropTypes.object,
  selectMode: PropTypes.bool,
  myTasks: PropTypes.bool,
};
