import React, { useEffect, useState } from "react";

import DialogTemplate from "Components/DialogTemplate";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default function FilterColumns(props) {

	const [open, setOpen] = useState(false);

	const handleChange = (event) => {
		let updatedHeaders = [...props.headers];
		let name = event.target.name;
		let checked = event.target.checked;

		let index = updatedHeaders.findIndex(hd => {
			return hd.id == name;
		});

		if (index > -1) {
			updatedHeaders[index] = {...updatedHeaders[index], visible: checked};
		}

		props.setHeaders(updatedHeaders);
	}

	function formContents() {
		if (!props.headers) {
			return <div>Error</div>
		}

		return (
			<Grid container item xs={12}>
				{props.headers.length > 0 && 
					props.headers.map(header => {
						return (
							<Grid item xs={12}>
								<FormControlLabel
									control={<Checkbox checked={header.visible} onChange={handleChange} name={header.id} />}
									label={header.label}
								/>
							</Grid>
						)
					})
				}
			</Grid>
		)
	}

	function formActions() {
		return (
			<Grid container item xs={12} alignItems="center">
				<Grid item container xs={12} justify="flex-end">
					<Button disableElevation variant="contained" color="primary" size="small" onClick={() => setOpen(false)} style={{margin: '0px 4px'}}>
					  Close 
					</Button>
				</Grid>
			</Grid>
		)
	}

	return (
		<div>
			<MenuItem onClick={() => {setOpen(true)}}>
				Show/Hide Columns
			</MenuItem>
			<DialogTemplate 
				title="Show/Hide Columns" 
				fullScreen={false}
				open={open || props.open} 
				onClose={() => {if (props.onClose()) { props.onClose() } setOpen(false)}}
				formContents={formContents()} 
				formActions={formActions()} 
				cancel={() => {if (props.onClose()) { props.onClose() } setOpen(false)}}
			/>
		</div>
	)
}