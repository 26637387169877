import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Message from "../../Components/Message";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import TextField from "@material-ui/core/TextField";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";

import API from "../../Api/Api";
import EditHazardModal from "../Templates/Hazards/EditHazardModal";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { StylesContext } from "../../App";
import ContentHeader from "../../Components/Layouts/ContentHeader";
import { IconButton } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Chip from "@material-ui/core/Chip";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    accordionNoLine: {
        marginBottom: 8,
        marginTop: 8,
        "&.MuiAccordion-root:before": {
            backgroundColor: "white",
        },
    },
});

const HazardList = React.memo(
    function HazardList(props) {
        const classes = React.useContext(StylesContext);
        let api = new API();
        const nativeClasses = useStyles();

        const [user, setUser] = useState(null);
        const [shouldUpdate, setShouldUpdate] = useState(false);

        useEffect(() => {
            api.getAuthAPI()
                .getAuthedProfile()
                .then((e) => {
                    setUser(e.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        }, []);

        const [hazards, setHazards] = useState(null);
        useEffect(() => {
            getHazards();
        }, [props.shouldUpdate, props.templateSelected, shouldUpdate]);

        useEffect(() => {
            setShouldUpdate(false);
        }, [shouldUpdate]);

        function getHazards() {
            if (props.haccpPlanPk) {
                api.getHazardAPI()
                    .listHazards(props.haccpPlanPk)
                    .then((response) => {
                        setHazards(response.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }

        function deleteHazard(hazard) {
            api.getHazardAPI()
                .deleteHazard(hazard.pk)
                .then((response) => {
                    let updatedHazards = [...hazards];

                    let hazardIndex = updatedHazards.findIndex((updatedHazard) => {
                        return updatedHazard.pk === hazard.pk;
                    });

                    if (hazardIndex > -1) {
                        updatedHazards.splice(hazardIndex, 1);
                    }

                    setHazards(updatedHazards);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        const [hazardModalOpen, setHazardModalOpen] = useState(false);
        const [shouldClose, setShouldClose] = useState(true);
        const [hazardSelected, setHazardSelected] = useState(null);

        function handleHazardModalClosed() {
            if (!shouldClose) {
                alert("Save changes before closing.");
            } else {
                setHazardModalOpen(false);
            }
        }

        function modelClose() {
            if (!shouldClose) {
                return;
            } else {
                setHazardModalOpen(false);
            }
            setHazardModalOpen(false);
        }

        function hazardClicked(pk) {
            setHazardSelected(pk);
            setHazardModalOpen(true);
        }

        function hazardSave() {
            getHazards();
            setShouldClose(true);
        }

        function hazardSaveAndContinue() {
            setReturnStatus("Hazard Saved!", "info");
            getHazards();
            setHazardModalOpen(false);
            setShouldClose(true);
        }

        function cancel() {
            setShouldClose(true);
            if (props.setShouldClose) {
                props.setShouldClose(true);
            }

            setHazardModalOpen(false);
        }

        const [open, setOpen] = React.useState(false);
        const [message, setMessage] = React.useState({
            message: "",
            status: "info",
        }); // error, warning, info, info

        function setReturnStatus(message, status) {
            setMessage({ message: message, status: status });
            setOpen(true);
        }

        const handleClose = (event, reason) => {
            if (reason === "clickaway") {
                return;
            }
            setOpen(false);
        };

        const [deleteWarningOpen, setDeleteWarningOpen] = React.useState(false);
        const [hazardName, setHazardName] = React.useState("");
        const [processesRead, setProcessesRead] = React.useState("null");

        function setWarningClosed() {
            setDeleteWarningOpen(false);
            setAnchorEl(null);
        }

        function deleteWarnedHazard() {
            deleteHazard(hazardToDelete);
            setDeleteWarningOpen(false);
            setAnchorEl(null);
            setReturnStatus("Hazard Deleted!", "info");
        }

        function setMenuClosed() {
            setAnchorEl(null);
        }

        const handleClick = (event, hazard) => {
            setAnchorEl(event.currentTarget);
            event.stopPropagation();
            setHazardToDelete(hazard);
        };
        const handleMenuClose = () => {
            setAnchorEl(null);
            setMenuClosed();
        };
        const [hazardToDelete, setHazardToDelete] = useState(null);
        const [anchorEl, setAnchorEl] = useState(null);
        const menuOpen = Boolean(anchorEl);

        const [expanded, setExpanded] = React.useState(false);
        //could potentially just use components from hazard.components
        let processNames = "";
        const handleAccordianChange = (panel) => (event, isExpanded) => {
            if (panel.processes_read) {
                panel.processes_read.forEach((name) => {
                    if (processNames === "") {
                        processNames = name.name;
                    } else {
                        processNames = processNames + ", " + name.name;
                    }
                });
                setProcessesRead(processNames);
            }
            setExpanded(isExpanded ? panel : false);
        };

        function addHazard() {
            setHazardSelected(null);
            setHazardModalOpen(true);
        }

        return (
            <div>
                {!hazards && <CircularProgress style={{ marginTop: "40%", marginLeft: "50%" }} />}
                <EditHazardModal
                    open={hazardModalOpen}
                    handleModalClosed={handleHazardModalClosed}
                    modelClose={modelClose}
                    haccpPlanPk={props.haccpPlanPk}
                    user={user}
                    cancel={cancel}
                    setShouldUpdate={setShouldUpdate}
                    setShouldClose={setShouldClose}
                    hazard_pk={hazardSelected}
                    saveAndContinue={hazardSaveAndContinue}
                    save={hazardSave}
                />
                <Grid container direction="column" style={{ maxWidth: "100%" }}>
                    <div>
                        <Dialog
                            open={deleteWarningOpen}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Delete this hazard?"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to delete {hazardName} , this action can not be reversed.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={setWarningClosed} color="primary">
                                    No
                                </Button>
                                <Button onClick={deleteWarnedHazard} color="primary" autoFocus>
                                    Yes, Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>

                    <Grid item xs={12}>
                        {hazards && (
                            <div style={{ width: "100%" }}>
                                <ContentHeader title="Hazards" />
                                <Grid container spacing={1} className={classes.generalContainerGrid}>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            style={{
                                                width: "auto",
                                                float: "right",
                                                alignItems: "center",
                                            }}
                                        >
                                            <IconButton
                                                disableRipple
                                                style={{
                                                    backgroundColor: "transparent",
                                                    float: "right",
                                                    marginRight: "28px",
                                                }}
                                                disabled={props.readOnlyMode}
                                                onClick={addHazard}
                                            >
                                                <AddIcon></AddIcon>
                                                <Typography
                                                    style={{
                                                        fontFamily: "Roboto",
                                                        fontWeight: "500",
                                                        fontSize: "14px",
                                                        lineHeight: "21px",
                                                        color: "#687895",
                                                        marginBottom: "0px",
                                                        float: "right",
                                                    }}
                                                >
                                                    Add Hazard
                                                </Typography>
                                            </IconButton>
                                        </Grid>
                                    </Grid>

                                    {hazards.map((hazard) => {
                                        return (
                                            <Accordion
                                                key={hazard.pk}
                                                elevation={0}
                                                style={{
                                                    margin: "32px",
                                                    marginTop: "8px",
                                                    marginBottom: "8px",
                                                    width: "100%",
                                                }}
                                                className={nativeClasses.accordionNoLine}
                                                expanded={expanded === hazard}
                                                onChange={handleAccordianChange(hazard)}
                                            >
                                                <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                                    <Grid container style={{ margin: "8px", alignItems: "center" }}>
                                                        <Grid
                                                            item
                                                            xs={7}
                                                            md={5}
                                                            container
                                                            style={{
                                                                width: "auto",
                                                                float: "left",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: "500",
                                                                    fontSize: "14px",
                                                                    lineHeight: "21px",
                                                                    color: "#687895",
                                                                }}
                                                            >
                                                                {hazard.name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={4}
                                                            md={2}
                                                            container
                                                            style={{
                                                                width: "auto",
                                                                float: "left",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            {hazard.significant && (
                                                                <Tooltip title="Significant Hazard">
                                                                    <ErrorOutlineIcon
                                                                        style={{
                                                                            marginLeft: "12px",
                                                                            color: "#687895",
                                                                        }}
                                                                    ></ErrorOutlineIcon>
                                                                </Tooltip>
                                                            )}
                                                            {hazard.type === "B" && (
                                                                <Chip
                                                                    style={{
                                                                        marginLeft: "12px",
                                                                        color: "#18BFF6",
                                                                        background: "rgba(24, 191, 247, 0.1)",
                                                                    }}
                                                                    label="BIOLOGICAL"
                                                                />
                                                            )}
                                                            {hazard.type === "P" && (
                                                                <Chip
                                                                    style={{
                                                                        marginLeft: "12px",
                                                                        color: "#18BFF6",
                                                                        background: "rgba(24, 191, 247, 0.1)",
                                                                    }}
                                                                    label="PHYSICAL"
                                                                />
                                                            )}
                                                            {hazard.type === "C" && (
                                                                <Chip
                                                                    style={{
                                                                        marginLeft: "12px",
                                                                        color: "#18BFF6",
                                                                        background: "rgba(24, 191, 247, 0.1)",
                                                                    }}
                                                                    label="CHEMICAL"
                                                                />
                                                            )}
                                                        </Grid>{" "}
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            md={0}
                                                            container
                                                            style={{
                                                                width: "auto",
                                                                float: "left",
                                                                alignItems: "center",
                                                            }}
                                                        ></Grid>
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            md={3}
                                                            container
                                                            style={{
                                                                alignItems: "center",
                                                                justifyContent: "flex-end",
                                                            }}
                                                        >
                                                            <Button
                                                                variant="text"
                                                                style={{ marginRight: "16px" }}
                                                                disabled={props.readOnlyMode}
                                                                onClick={() => hazardClicked(hazard.pk)}
                                                            >
                                                                <Typography
                                                                    style={{
                                                                        fontFamily: "Roboto",
                                                                        fontWeight: "500",
                                                                        fontSize: "14px",
                                                                        lineHeight: "21px",
                                                                        color: "#18BFF6",
                                                                        marginBottom: "0px",
                                                                        opacity: 1,
                                                                    }}
                                                                >
                                                                    Edit Hazard
                                                                </Typography>
                                                            </Button>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            md={1}
                                                            container
                                                            style={{
                                                                alignItems: "center",
                                                                justifyContent: "flex-end",
                                                            }}
                                                        >
                                                            <IconButton
                                                                disableRipple
                                                                style={{
                                                                    backgroundColor: "transparent",
                                                                    float: "right",
                                                                }}
                                                                disabled={props.readOnlyMode}
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    handleClick(event, hazard);
                                                                }}
                                                            >
                                                                <MoreHorizIcon></MoreHorizIcon>
                                                            </IconButton>

                                                            <Menu
                                                                anchorEl={anchorEl}
                                                                keepMounted
                                                                open={menuOpen}
                                                                // onChange={handleClose}
                                                                onClose={handleMenuClose}
                                                                PaperProps={{
                                                                    style: {
                                                                        maxHeight: 48 * 4.5,
                                                                        width: 200,
                                                                    },
                                                                }}
                                                            >
                                                                <MenuItem
                                                                    key={hazard}
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        setDeleteWarningOpen(true);
                                                                    }}
                                                                >
                                                                    Delete
                                                                </MenuItem>
                                                            </Menu>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ marginLeft: "8px", marginRight: "8px" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                style={{
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: "500",
                                                                    fontSize: "14px",
                                                                    lineHeight: "21px",
                                                                    color: "#1F203D",
                                                                    marginBottom: "0px",
                                                                    opacity: 1,
                                                                }}
                                                            >
                                                                Hazard Description
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {hazard.description && (
                                                                <TextField
                                                                    disabled
                                                                    name="Hazard Description"
                                                                    placeholder=""
                                                                    className={classes.textfieldbackground}
                                                                    variant="outlined"
                                                                    defaultValue={hazard.description}
                                                                    fullWidth
                                                                />
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                style={{
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: "500",
                                                                    fontSize: "14px",
                                                                    lineHeight: "21px",
                                                                    color: "#1F203D",
                                                                    marginBottom: "0px",
                                                                    opacity: 1,
                                                                }}
                                                            >
                                                                Hazard Type
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                style={{
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: "500",
                                                                    fontSize: "14px",
                                                                    lineHeight: "21px",
                                                                    color: "#1F203D",
                                                                    marginBottom: "0px",
                                                                    opacity: 1,
                                                                }}
                                                            >
                                                                Is this potential hazard significant?
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {hazard.type && (
                                                                <TextField
                                                                    disabled
                                                                    name="Hazard Description"
                                                                    placeholder=""
                                                                    className={classes.textfieldbackground}
                                                                    variant="outlined"
                                                                    defaultValue={
                                                                        hazard.type === "C"
                                                                            ? "Chemical"
                                                                            : hazard.type === "B"
                                                                            ? "Biological"
                                                                            : "Physical"
                                                                    }
                                                                    fullWidth
                                                                />
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {hazard && (
                                                                <TextField
                                                                    disabled
                                                                    name="Hazard Description"
                                                                    placeholder=""
                                                                    className={classes.textfieldbackground}
                                                                    variant="outlined"
                                                                    defaultValue={hazard.significant ? "True" : "False"}
                                                                    fullWidth
                                                                />
                                                            )}
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Typography
                                                                style={{
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: "500",
                                                                    fontSize: "14px",
                                                                    lineHeight: "21px",
                                                                    color: "#1F203D",
                                                                    marginBottom: "0px",
                                                                    opacity: 1,
                                                                }}
                                                            >
                                                                Justification for Inclusion or Exclusion as a
                                                                Significant Hazard
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {hazard && (
                                                                <TextField
                                                                    disabled
                                                                    name="Hazard Description"
                                                                    placeholder=""
                                                                    className={classes.textfieldbackground}
                                                                    variant="outlined"
                                                                    defaultValue={hazard.justification}
                                                                    fullWidth
                                                                />
                                                            )}
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Typography
                                                                style={{
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: "500",
                                                                    fontSize: "14px",
                                                                    lineHeight: "21px",
                                                                    color: "#1F203D",
                                                                    marginBottom: "0px",
                                                                    opacity: 1,
                                                                }}
                                                            >
                                                                Preventative Measures of the Significant Hazard
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {hazard && (
                                                                <TextField
                                                                    disabled
                                                                    name="Hazard Description"
                                                                    placeholder=""
                                                                    className={classes.textfieldbackground}
                                                                    variant="outlined"
                                                                    defaultValue={hazard.preventative_measures}
                                                                    fullWidth
                                                                />
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                style={{
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: "500",
                                                                    fontSize: "14px",
                                                                    lineHeight: "21px",
                                                                    color: "#1F203D",
                                                                    marginBottom: "0px",
                                                                    opacity: 1,
                                                                }}
                                                            >
                                                                Linked Processes ({hazard.processes.length})
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {processesRead && (
                                                                <TextField
                                                                    disabled
                                                                    name="Hazard Description"
                                                                    placeholder=""
                                                                    className={classes.textfieldbackground}
                                                                    variant="outlined"
                                                                    value={processesRead}
                                                                    fullWidth
                                                                />
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                style={{
                                                                    fontFamily: "Roboto",
                                                                    fontWeight: "500",
                                                                    fontSize: "14px",
                                                                    lineHeight: "21px",
                                                                    color: "#1F203D",
                                                                    marginBottom: "0px",
                                                                    opacity: 1,
                                                                }}
                                                            >
                                                                Linked to Ingredients?
                                                            </Typography>
                                                        </Grid>
                                                        {hazard.ingredients.length > 0 && (
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    disabled
                                                                    name="Linked to Ingredients_hazard"
                                                                    placeholder="Linked to Ingredients"
                                                                    className={classes.textfieldbackground}
                                                                    variant="outlined"
                                                                    defaultValue={"Yes"}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                        )}
                                                        {!hazard.ingredients.length && (
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    disabled
                                                                    name="Linked to Ingredients_hazard"
                                                                    placeholder="Linked to Ingredients"
                                                                    className={classes.textfieldbackground}
                                                                    variant="outlined"
                                                                    defaultValue={"No"}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                    })}
                                </Grid>
                            </div>
                        )}
                    </Grid>
                    <Message
                        position={true}
                        open={open}
                        message={message.message}
                        severity={message.status}
                        vertical="bottom"
                        horizontal="right"
                        handleClose={handleClose}
                    />
                </Grid>
            </div>
        );
    },
    function (prevProps, nextProps) {
        if (nextProps.templateSelected !== prevProps.templateSelected) {
            return false;
        }

        if (nextProps.shouldUpdate !== prevProps.shouldUpdate) {
            return false;
        }

        if (prevProps.hazards === nextProps.hazards) {
            return true;
        }

        if (prevProps.hazards == null || nextProps.hazards == null) {
            return false;
        }

        if (prevProps.hazards.length != nextProps.hazards.length) {
            return false;
        }

        // If you don't care about the order of the elements inside
        // the array, you should sort both arrays here.
        // Please note that calling sort on an array will modify that array.
        // you might want to clone your array first.

        for (let i = 0; i < prevProps.hazards.length; ++i) {
            if (JSON.stringify(prevProps) !== JSON.stringify(nextProps)) return false;
        }

        return true;
    }
);

export default HazardList;
