import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles({
  gridcontainer: {
    padding: "40px",
    paddingTop: "64px",
    paddingBottom: "64px",
    marginBottom: "100px",
  },
  nestedcontainer: {
    padding: 0,
    paddingTop: 20,
  },
  griditem: {
    marginBottom: "18px",
  },
  outlinedbutton: {
    color: "#18bff6",
  },
  textfieldbackground: {
    background: "#fafafc",
  },
});

function Step2(props) {
  const classes = useStyles();

  const onDesktop = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    if (props.open) {
      let field0 = props.deviationForm.food_safety_hazard_description;
      let field1 = props.deviationForm.hazard_type;
      let field2 = props.deviationForm.process_steps_involved;
      let field3 = props.deviationForm.identification_of_product;
      let field4 = props.deviationForm.description_of_product_holding;
      let field5 = props.deviationForm.description_of_product_analysis;
      let field6 = props.deviationForm.results_of_product_analysis;
      let field7 = props.deviationForm.description_of_disposal_method;
      let field8 = props.deviationForm.date_of_disposal;
      let field9 = props.deviationForm.description_of_product_analysis_disposal;
      let field10 = props.deviationForm.results_of_product_analysis_disposal;
      let field11 = props.deviationForm.scope_of_impact;
      let field12 = props.deviationForm.product_holding_description;
      let field13 = props.deviationForm.additional_product_analysis;
      let field14 = props.deviationForm.additional_product_analysis_results;
      if (
        !field0 ||
        !field1 ||
        !field2 ||
        !field3 ||
        !field4 ||
        !field5 ||
        !field6 ||
        !field7 ||
        !field8 ||
        !field9 ||
        !field10 ||
        !field11 ||
        !field12 ||
        !field13 ||
        !field14
      ) {
        if (props.setFieldError) {
          props.setFieldError(true);
        }
      } else {
        if (props.setFieldError) {
          props.setFieldError(false);
        }
      }
    }
  }, [props.open]);

  const [hazardType, setHazardType] = useState("Select Hazard Type");

  function handleChange(event) {
    if (event.target.name === "hazard_type") {
      setHazardType(event.target.value);
    }

    if (props.handleChange) {
      props.handleChange(event);
    }

    if (!props.setFieldError) {
      return;
    }
  }

  useEffect(() => {
    if (!props.deviationForm) {
      return;
    }

    let field0 = props.deviationForm.food_safety_hazard_description;
    let field1 = props.deviationForm.hazard_type;
    let field2 = props.deviationForm.process_steps_involved;
    let field3 = props.deviationForm.identification_of_product;
    let field4 = props.deviationForm.description_of_product_holding;
    let field5 = props.deviationForm.description_of_product_analysis;
    let field6 = props.deviationForm.results_of_product_analysis;
    let field7 = props.deviationForm.description_of_disposal_method;
    let field8 = props.deviationForm.date_of_disposal;
    //let field9 = props.deviationForm.description_of_product_analysis_disposal;
    //let field10 = props.deviationForm.results_of_product_analysis_disposal;
    let field11 = props.deviationForm.scope_of_impact;
    let field12 = props.deviationForm.product_holding_description;
    let field13 = props.deviationForm.additional_product_analysis;
    let field14 = props.deviationForm.additional_product_analysis_results;

    if (
      !field0 ||
      !field1 ||
      !field2 ||
      !field3 ||
      !field4 ||
      !field5 ||
      !field6 ||
      !field7 ||
      !field8 ||
      !field11 ||
      !field12 ||
      !field13 ||
      !field14
    ) {
      props.setFieldError(true);
    } else {
      props.setFieldError(false);
    }
  }, [props.deviationForm]);

  return (
    <Paper
      elevation={0}
      style={{
        margin: onDesktop ? 32 : 0,
        padding: onDesktop ? 32 : "32px",
        marginTop: 0,
        maxWidth: "1200px",
      }}
    >
      <Grid container spacing={3}>
        <Grid item container xs={12} alignItems="center">
          <Typography variant="h6">Hazard Analysis</Typography>
        </Grid>
        {/* <Grid item container xs={12} alignItems="center">
          <Divider style={{ width: "100%" }}></Divider>
        </Grid> */}
        <Grid item container xs={12} md={8}>
          <Grid item xs={12} style={{paddingBottom: '16px'}}>
            <Typography><span style={{color: 'red', paddingRight: '4px'}}>*</span>Description of Safety Hazard</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={props.readOnly}
              id="food_safety_hazard_description"
              name="food_safety_hazard_description"
              placeholder="Describe the safety hazard"
              className={classes.textfieldbackground}
              variant="outlined"
              multiline
              rows={3}
              defaultValue={
                props.deviationForm
                  ? props.deviationForm.food_safety_hazard_description
                  : ""
              }
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} md={4}>
          <Typography
            style={{ color: "red", paddingRight: "4px", paddingBottom: "16px" }}
          >
            *
          </Typography>
          <Typography>Hazard Type</Typography>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            // error={submitAttempted && !skuInstance.sku && skuInstance !== 0}
          >
            <InputLabel htmlFor="age-native-helper"></InputLabel>
            <Select
              disabled={props.readOnly}
              name="hazard_type"
              id="hazard_type"
              value={hazardType}
              className={classes.textfieldbackground}
              onChange={handleChange}
            >
              <MenuItem aria-label="None" value="Select Hazard Type">
                Select Hazard Type
              </MenuItem>
              <MenuItem aria-label="Biological" value="biological">
                Biological
              </MenuItem>
              <MenuItem aria-label="Chemical" value="chemical">
                Chemical
              </MenuItem>
              <MenuItem aria-label="Physical" value="physical">
                Physical
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={12} style={{paddingBottom: '16px'}}>
            <Typography><span style={{color: 'red', paddingRight: '4px'}}>*</span> Process Steps Involved</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={props.readOnly}
              id="process_steps_involved"
              name="process_steps_involved"
              placeholder="Describe all process steps that were affected"
              className={classes.textfieldbackground}
              variant="outlined"
              defaultValue={
                props.deviationForm
                  ? props.deviationForm.process_steps_involved
                  : ""
              }
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Typography variant="h5">Product Analysis</Typography>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Divider style={{ width: "100%" }}></Divider>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} style={{paddingBottom: '16px'}}>
            <Typography>
              <span style={{color: 'red', paddingRight: '4px'}}>*</span> Indentification of Product (Including Name, Type, Amount, and Weight/Volume)
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={props.readOnly}
              id="identification_of_product"
              name="identification_of_product"
              placeholder="Describe the products to be analyzed"
              className={classes.textfieldbackground}
              variant="outlined"
              defaultValue={
                props.deviationForm
                  ? props.deviationForm.identification_of_product
                  : ""
              }
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} style={{paddingBottom: '16px'}}>
            <Typography>
              <span style={{color: 'red', paddingRight: '4px'}}>*</span>Description of Product Holding (Including How and Where)
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={props.readOnly}
              id="description_of_product_holding"
              name="description_of_product_holding"
              placeholder="Describe how and where the product is/was held"
              className={classes.textfieldbackground}
              variant="outlined"
              defaultValue={
                props.deviationForm
                  ? props.deviationForm.description_of_product_holding
                  : ""
              }
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} style={{paddingBottom: '16px'}}>
            <Typography><span style={{color: 'red', paddingRight: '4px'}}>*</span>Description of Product Analysis</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={props.readOnly}
              id="description_of_product_analysis"
              name="description_of_product_analysis"
              placeholder="Describe the process of analyzing the product"
              multiline
              rows={3}
              className={classes.textfieldbackground}
              variant="outlined"
              defaultValue={
                props.deviationForm
                  ? props.deviationForm.description_of_product_analysis
                  : ""
              }
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} style={{paddingBottom: '16px'}}>
            <Typography><span style={{color: 'red', paddingRight: '4px'}}>*</span>Results of Product Analysis</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={props.readOnly}
              id="results_of_product_analysis"
              name="results_of_product_analysis"
              placeholder="Describe the results of the product analysis"
              multiline
              rows={3}
              className={classes.textfieldbackground}
              variant="outlined"
              defaultValue={
                props.deviationForm
                  ? props.deviationForm.results_of_product_analysis
                  : ""
              }
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Typography variant="h5">Product Disposal</Typography>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Divider style={{ width: "100%" }}></Divider>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} style={{paddingBottom: '16px'}}>
            <Typography><span style={{color: 'red', paddingRight: '4px'}}>*</span>Description of Disposal Method</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={props.readOnly}
              id="description_of_disposal_method"
              name="description_of_disposal_method"
              placeholder="Describe the method used to dispose of the affected product"
              className={classes.textfieldbackground}
              variant="outlined"
              defaultValue={
                props.deviationForm
                  ? props.deviationForm.description_of_disposal_method
                  : ""
              }
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} style={{paddingBottom: '16px'}}>
            <Typography><span style={{color: 'red', paddingRight: '4px'}}>*</span>Date of Disposal</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={props.readOnly}
              id="date_of_disposal"
              name="date_of_disposal"
              className={classes.textfieldbackground}
              type="date"
              defaultValue={
                props.deviationForm ? props.deviationForm.date_of_disposal : ""
              }
              variant="outlined"
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          alignItems="center"
          style={{ marginTop: "8px" }}
        >
          <Typography variant="h5">
            Additional Cross-Contamination (Process)
          </Typography>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Typography>
            Complete this section of the form if there are additional products
            that are impacted by this deviation. This typically includes any
            products or processing lines that involve the same process at which
            the deviation occured.
          </Typography>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Divider style={{ width: "100%" }}></Divider>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Typography style={{ color: "red", paddingRight: "4px" }}>
            *
          </Typography>{" "}
          <Typography> Scope of Impact</Typography>
        </Grid>
        <Grid item container xs={12}>
          <TextField
            disabled={props.readOnly}
            id="scope_of_impact"
            name="scope_of_impact"
            placeholder="List all additional products impacted, including lot codes"
            className={classes.textfieldbackground}
            variant="outlined"
            defaultValue={
              props.deviationForm ? props.deviationForm.scope_of_impact : ""
            }
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Typography style={{ color: "red", paddingRight: "4px" }}>
            *
          </Typography>{" "}
          <Typography>Product Holding Description</Typography>
        </Grid>
        <Grid item container xs={12}>
          <TextField
            disabled={props.readOnly}
            id="product_holding_description"
            name="product_holding_description"
            placeholder="Describe how the additional products were held, including location"
            className={classes.textfieldbackground}
            variant="outlined"
            defaultValue={
              props.deviationForm
                ? props.deviationForm.product_holding_description
                : ""
            }
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} style={{paddingBottom: '16px'}}>
            <Typography><span style={{color: 'red', paddingRight: '4px'}}>*</span> Additional Product Analysis</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={props.readOnly}
              id="additional_product_analysis"
              name="additional_product_analysis"
              placeholder="Describe if and how products were analysed or evaluated"
              className={classes.textfieldbackground}
              variant="outlined"
              defaultValue={
                props.deviationForm
                  ? props.deviationForm.additional_product_analysis
                  : ""
              }
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} style={{paddingBottom: '16px'}}>
            <Typography><span style={{color: 'red', paddingRight: '4px'}}>*</span>Additional Product Analysis Results</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={props.readOnly}
              id="additional_product_analysis_results"
              name="additional_product_analysis_results"
              placeholder="Detail the results of the analysis performed on the additional products affected"
              className={classes.textfieldbackground}
              variant="outlined"
              defaultValue={
                props.deviationForm
                  ? props.deviationForm.additional_product_analysis_results
                  : ""
              }
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Step2;
