import React, { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import InputLabel from '@material-ui/core/InputLabel';
import Button from "@material-ui/core/Button";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import TraceabilityUtils from "../TraceabilityUtils";
import Message from "../../Components/Message";
import PropTypes from 'prop-types';
import {StylesContext} from "../../App";

const EMPTY_MESSAGE = {
  open: false,
  message: '',
  status: 'info',
};
const utils = new TraceabilityUtils();

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

//todo probably move this to M/O folder at some point, it's more specific to that than to the table itself.
export default function AddLotModal(props) {
  const classes = React.useContext(StylesContext);

  const [lotData, setLotData] = useState( {internal_lot_codes: []} );
  const [message, setMessage] = useState( {...EMPTY_MESSAGE} );
  const [itemInstance, setItemInstance] = useState(false);
  const [validSubmit, setValidSubmit] = useState('missing');
  const [errorText, setErrorText] = useState('')

  useEffect(() => {
    if(!itemInstance) {
      setErrorText('No inventory found at this location.');
      setValidSubmit('missing');
      return;
    }
    let baseUnit = props.units[itemInstance.unit_type];

    if(!lotData.unit_type || !lotData.unit_value) {
      setErrorText('On hand amount is: ' + itemInstance.unit_value_on_hand + baseUnit.abbreviation + '.');
      setValidSubmit('missing');
      return;
    }

    
    let lotDataUnit = props.units[lotData.unit_type];

    let convertedValue = utils.getConvertedValue(lotData.unit_value, lotDataUnit, baseUnit);

    if(Number(itemInstance.unit_value_on_hand) < Number(convertedValue)) {
      setValidSubmit('insufficient');
      setErrorText('Insufficient stock: ' + convertedValue + baseUnit.abbreviation + 
        ' is more than the on hand value of ' + itemInstance.unit_value_on_hand + baseUnit.abbreviation + '.'
      )
    }
    else{
      setErrorText('On hand amount is: ' + itemInstance.unit_value_on_hand + baseUnit.abbreviation + '.');
      setValidSubmit('valid');
    }


  }, [itemInstance, lotData.unit_type, lotData.unit_value])

  useEffect(() => {
    if(lotData.sku || !lotData.internal_lot_codes || lotData.internal_lot_codes.length == 0 ) {
      return;
    }
    else {
      let lotCodePk = lotData.internal_lot_codes[0];
      let skuPk = props.internalLotCodes.find(lotCodeObj => lotCodeObj.pk == lotCodePk).sku;

      setLotData({...lotData, sku: skuPk});
    }
  }, [lotData.internal_lot_codes])

  useEffect(() => {
    if(props.editingTable !== undefined && props.editingRow !== undefined) {

      let newLotData = {...props.tableData[props.editingTable].production_sku_lines[props.editingRow]};
      setLotData(newLotData)
    }
    else {
      setLotData({internal_lot_codes: []});
    }

  }, [props.editingRow])

  useEffect(() => {
    if(!lotData || !lotData.sku) {
      return;
    }

    let skuObj = props.skus.find(sku => sku.pk == lotData.sku);

    setLotData({ ...lotData, unit_type: skuObj.unit_type});

    if(props.itemInstances && props.inventoryLocation) {
      let itemInstanceObj = props.itemInstances.find((instance) => {
        return ( (instance.sku == lotData.sku) && (instance.inventory_location == props.inventoryLocation) );
      })

      setItemInstance(itemInstanceObj);
    }
  }, [lotData.sku])

  function handleDelete() {
    if(props.editingTable !== undefined && props.editingRow !== undefined) {

      let newProdIngLine = {...props.tableData[props.editingTable]};

      newProdIngLine.production_sku_lines.splice(props.editingRow, 1)


      let newTableData = [...props.tableData];

      newTableData.splice(props.editingTable, 1, newProdIngLine);


      props.setLineData([...newTableData]);
    }

    props.closeModal();
  }


  function handleSubmit(saveAndContinue) {
    var indexToAddTo = -1

    props.tableData.forEach((production_ingredient_line, i) => {
      if(production_ingredient_line.skus.find(sku => sku.pk == lotData.sku)) {
        indexToAddTo = i;
      }
    })

    if(props.editingTable !== undefined && props.editingRow !== undefined) {
      let newProdIngLine = {...props.tableData[props.editingTable]};
      newProdIngLine.production_sku_lines.splice(props.editingRow, 1, {
        sku: lotData.sku,
        name: props.skus.find(sku => sku.pk == lotData.sku).name,
        unit_value: lotData.unit_value,
        unit_type: lotData.unit_type,
        amount_display: lotData.unit_value + ' ' + props.units[lotData.unit_type].abbreviation,
        internal_lot_codes: lotData.internal_lot_codes,
      })

      let newTableData = [...props.tableData];
      newTableData.splice(props.editingTable, 1, newProdIngLine);

      props.setLineData([...newTableData]);
      props.closeModal();
    }
    else if(indexToAddTo >= 0) {
      let newProdIngLine = {...props.tableData[indexToAddTo]};

      newProdIngLine.production_sku_lines.push({
        sku: lotData.sku,
        name: props.skus.find(sku => sku.pk == lotData.sku).name,
        unit_value: lotData.unit_value,
        unit_type: lotData.unit_type,
        amount_display: lotData.unit_value + ' ' + props.units[lotData.unit_type].abbreviation,
        internal_lot_codes: lotData.internal_lot_codes,
      });

      let newTableData = [...props.tableData];
      newTableData.splice(indexToAddTo, 1, newProdIngLine);

      props.setLineData([...newTableData]);
      props.closeModal();
    }
    else {
      //something has gone wrong
      window.alert('That SKU does not match an ingredient on this formulation.')
    }
  }

  function handleTextFieldChange(event) {


    const property = event.target.name;
    const value = event.target.value;

    setLotData({ ...lotData, [property]: value });
  }

  function handleSkuFieldChange(event) {


    const property = event.target.name;
    const value = event.target.value;

    setLotData({ ...lotData, [property]: value, internal_lot_codes: [], unit_type: null });
  }

  function cancel() {
    props.closeModal()
  }

  return (
      <>
        <Grid container spacing={0} className={classes.generalContainerGridHead}>

          <Grid item xs={8}>
            <Typography variant="h5" noWrap>
              Lot Details
            </Typography>
          </Grid>
          
        </Grid>
        <Grid container spacing={3} className={classes.generalContainerGridBody}>

          <Grid item xs={4}>
            <Typography className={classes.generalFormTypographyHeader}>
              SKU ID
            </Typography>
            <FormControl variant="outlined" style={{ "width": "100%" }} className={classes.generalFormTextField}>
              <InputLabel></InputLabel>
              <Select
                key={lotData.sku}
                id={'sku'}
                name={'sku'}
                fullWidth
                className={classes.generalFormTextField}
                value={lotData.sku}
                onChange={handleSkuFieldChange}
                disabled={props.disabled || props.editingTable !== undefined}
              >
                {props.skus.map((skuObj, i) => (
                  <MenuItem key={i} value={skuObj.pk}>
                    {skuObj.sku_id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.generalFormTypographyHeader}>
              SKU Name
            </Typography>
            <FormControl variant="outlined" style={{ "width": "100%" }} className={classes.generalFormTextField}>
              <InputLabel></InputLabel>
              <Select
                key={lotData.sku}
                id={'sku'}
                name={'sku'}
                className={classes.generalFormTextField}
                value={lotData.sku}
                onChange={handleSkuFieldChange}
                disabled={props.disabled || props.editingTable !== undefined}
              >
                {props.skus.map((skuObj, i) => (
                  <MenuItem key={i} value={skuObj.pk}>
                    {skuObj.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.generalFormTypographyHeader}>
              Lot Code(s)
            </Typography>
            <FormControl variant="outlined" style={{ "width": "100%" }} className={classes.generalFormTextField}>
              <InputLabel></InputLabel>
              <Select
                id={'internal_lot_codes'}
                name={'internal_lot_codes'}
                multiple
                className={classes.generalFormTextField}
                fullWidth
                value={lotData.internal_lot_codes ? lotData.internal_lot_codes : []}
                onChange={handleTextFieldChange}
                disabled={props.disabled}
                renderValue={(selected) => (
                  <div className={classes.detailChips}>
                    {selected.map((value) => {
                      return (<Chip key={value} label={props.internalLotCodes.find(lotcode => lotcode.pk == value).code} className={classes.detailChip} />)
                    })}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {lotData.sku &&
                  props.skus.find(sku => sku.pk == lotData.sku).internal_lot_codes.map((lotCode) => (
                    <MenuItem key={lotCode.pk} value={lotCode.pk}>
                      {lotCode.code}
                    </MenuItem>
                ))}

                {!lotData.sku &&
                  props.internalLotCodes.map((lotCode) => (
                    <MenuItem key={lotCode.pk} value={lotCode.pk}>
                      {lotCode.code}
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.generalFormTypographyHeader}>
              Quantity Used
            </Typography>
            <TextField
              fullWidth
              name="unit_value"
              className={classes.generalFormTextField}
              variant="outlined"
              value={lotData.unit_value}
              onChange={handleTextFieldChange}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.generalFormTypographyHeader}>
              Units
            </Typography>
            <FormControl variant="outlined" style={{ "width": "100%" }} className={classes.generalFormTextField} >
              <InputLabel></InputLabel>
              <Select
                native
                id={'unit_type'}
                name={'unit_type'}
                className={classes.generalFormTextField}
                value={lotData.unit_type}
                onChange={handleTextFieldChange}
                disabled={props.disabled}
              >
                <option aria-label="None" value="" />
                {lotData.sku &&
                  props.skus.find(sku => sku.pk == lotData.sku).unit_choices.map((unitPk) => (
                    <option key={unitPk} value={unitPk}>
                      {props.units[unitPk].full_name}
                    </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {errorText}
            </Typography>
          </Grid>
          
        </Grid>

        <Grid container spacing={3} className={classes.generalContainerGridFoot}>
          <Grid item container xs={12} alignItems="center" justify="flex-end">
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: "8px" }}
              onClick={() => { cancel() }}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "8px" }}
              onClick={() => { handleDelete() }}
            >
              Delete
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "8px" }}
              onClick={() => { handleSubmit() }}
              disabled={validSubmit != 'valid' || lotData.internal_lot_codes.length == 0}
            >
              Submit
            </Button>
          </Grid>
        </Grid>

        <Message
          open={message.open}
          message={message.message}
          severity={message.status}
          vertical="bottom"
          horizontal="right"
          handleClose={() => { setMessage( {...message, open:false} ) }}
        />
      </>
  )
}

AddLotModal.propTypes = {
  unit: PropTypes.object,
  handleSubmit: PropTypes.func,
  user: PropTypes.object.isRequired,
  activeTraceability: PropTypes.object.isRequired,
  setShouldClose: PropTypes.func,
  onSave: PropTypes.func,
  closeModal: PropTypes.func,
  cancel: PropTypes.func,
  editingTable: PropTypes.number,
  editingRow: PropTypes.number,
}