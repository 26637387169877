import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IngredientList from "../Ingredients/IngredientList";
import API from "../../Api/Api";
import VerticalDotMenu from "../../Components/VerticalDotMenu";
import Divider from "@material-ui/core/Divider";
import Message from "../../Components/Message";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import IngredientTemplateList from "../Templates/Ingredients/IngredientTemplateList";
import Traceability from "../../Traceability/Traceability";
import TraceabilityUtils from "../../Traceability/TraceabilityUtils";
//import PackagingList from "../Packaging/PackagingList";
import { Prompt } from "react-router";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import { useRouteMatch, useLocation } from "react-router-dom";
import { SettingsApplications } from "@material-ui/icons";

let confirmed;

// Edit formulation
const utils = new TraceabilityUtils();
function Formulation(props) {
  const [formulation, setFormulation] = useState(props.formulation);
  const [units, setUnits] = useState(false);
  const [fieldError, setFieldError] = useState(false);

  let [isBlocking, setIsBlocking] = useState(false);
  const api = new API();

  let params = useParams();

  function getFormulationsAPI() {
    if (props.templateMode) {
      return api.getFormulationTemplateAPI();
    }

    return api.getFormulationsAPI();
  }

  useEffect(() => {
    const unitsApi = new Traceability().getUnitsAPI();
    utils.pkIndexObjectsFromApi(unitsApi.listGlobalUnits.bind(unitsApi), setUnits);
  }, [])
  
  useEffect(() => {
    let pk = props.pk || params.pk;
    confirmed = 0;
    if (pk) {
      getFormulation(pk);
    }
  }, [props.pk]);

  function getFormulation(pk) {
    getFormulationsAPI()
      .retrieveFormulation(pk)
      .then((e) => {
        setFormulation(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function handleChange(event) {
    setIsBlocking(true);
    if (props.setIsBlocking) {
      props.setIsBlocking(true);
    }

    const value = event.target.value;
    setFormulation({ ...formulation, [event.target.name]: value });
  }

  function handleSubmit(e) {
    if (!formulation.name) {
      setFieldError(true);
      setReturnStatus("Please Enter All Fields", "info");
    } else {
      setFieldError(false);
    }
    //updateFormulation(formulationPacket);
  }

  function saveAndContinue(e) {
    setIsBlocking(false);
    if (props.setIsBlocking) {
      props.setIsBlocking(false);
    }

    if (props.saveAndContinue) {
      props.saveAndContinue();
    }

    let formulationPacket = { ...formulation };
    if (!formulationPacket.unit_value && formulationPacket.unit_value !== 0) {
      formulationPacket.unit_value = 0;
    }

    updateFormulation(formulationPacket, false, true);
  }

  function updateFormulation(
    updatedFormulation,
    check,
    saveAndContinue = false
  ) {
    if (!formulation.name) {
      setFieldError(true);
      setReturnStatus("Please Enter All Fields", "info");
    } else {
      setFieldError(false);

      setIsBlocking(false);

      getFormulationsAPI()
        .updateFormulation(updatedFormulation)
        .then((e) => {
          setFormulation(e.data);

          setReturnStatus("Updated HACCP Plan!", "info");
          if (props.successMessage) {
            props.successMessage();
          }
          if (props.onSave) {
            props.onSave(e, saveAndContinue);
          }

          if (saveAndContinue) {
            //history.push(`${match.url}/processes`);
            props.closeModal();
          }
          // if (props.saveFormulation) {
          //   props.saveFormulation(e.data.pk);
          // }
        })
        .catch((e) => {
          setReturnStatus("Could not update formulation.", "error");
        });
    }
  }

  const [templateSelected, setTemplateSelected] = useState(false);

  function selectTemplate(template) {
    getFormulationsAPI()
      .selectTemplate(formulation.pk, template)
      .then((e) => {
        setFormulation(e.data);
        setTemplateSelected(true);
        setReturnStatus("Template selected!", "info");
      })
      .catch((e) => {
        setReturnStatus("Could not select template.", "error");
      });
  }

  function createTemplate() {
    let formulationPacket = { ...formulation };
    if (!formulationPacket.unit_value && formulationPacket.unit_value !== 0) {
      formulationPacket.unit_value = 0;
    }

    getFormulationsAPI()
      .createFormulation(formulationPacket)
      .then((e) => {
        setReturnStatus("Template created!", "info");
      })
      .catch((e) => {
        setReturnStatus("Could not create template.", "error");
      });
  }

  function getTemplates() {
    return api.getFormulationTemplateAPI().listFormulations();
  }

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState({ message: "", status: "info" }); // error, warning, info, info
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function setReturnStatus(message, status) {
    setMessage({ message: message, status: status });
    setOpen(true);
  }

  function deleteFormulation() {
    if (props.deleteFormulation) {
      props.deleteFormulation(formulation.pk);
    } else {
      getFormulationsAPI()
        .deleteFormulation(formulation.pk)
        .then((response) => {
          setFormulation(null);
          setReturnStatus("Deleted formulation!", "info");
        })
        .catch((error) => {
          setReturnStatus("Could not delete Formulation.", "error");
        });
    }
  }

  window.onbeforeunload = function (e) {
    if (isBlocking) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  return (
    <div>
      <Prompt
        message={(location, action) => {
          //Prompt triggers on any nav event, in this case push and then replace
          //if any are false, the chain stops and resolves.
          //If one is true somewhere in the chain, the nav proceeds.
          //The goal of this function is to ignore all of that and have this behave like window.confirm

          //If they have already confirmed, or made no changes allow navigation
          if (!isBlocking || confirmed) {
            return true;
          }
          //If they haven't confirmed yet, or are are trying to nav again after previously declining, re-confirm
          else if (
            confirmed === 0 ||
            (action == "PUSH" && confirmed === false)
          ) {
            confirmed = window.confirm(
              "You have unsaved changes, are you sure you want to leave?"
            );
          }
          //If they've said no, and this still transitions to replace, reset confirmed and cancel nav
          else if (action == "REPLACE" && confirmed === false) {
            confirmed = 0;
            return false;
          }

          return confirmed;
        }}
      />
      {!formulation || (!units && <CircularProgress />)}

      {formulation && units && (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <Typography variant="h5" noWrap>
                Edit Formulation {props.templateMode === true && "Template"}
              </Typography>
              Edit/view this formulation.
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
              <IconButton style={{ float: "right" }} onClick={props.closeModal}>
                <HighlightOffOutlinedIcon style={{ float: "right" }} />
              </IconButton>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={11}>
              <TextField
                label="Name"
                name="name"
                variant="outlined"
                error={formulation.name ? false : fieldError}
                fullWidth
                disabled={props.deleteMode}
                value={formulation.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={1}>
              {!props.deleteMode && (
                <VerticalDotMenu
                  style={{ float: "right" }}
                  getTemplates={getTemplates}
                  selectTemplate={selectTemplate}
                  createTemplate={createTemplate}
                  deleteInstance={deleteFormulation}
                  api={api.getFormulationsAPI()}
                  setFormulation={setFormulation}
                  instance={formulation}
                  setStatus={setReturnStatus}
                  instanceName="Formulation"
                  template_title="Formulation Templates"
                  template_cols={[
                    { title: "Name", field: "name" },
                    { title: "Description", field: "description" },
                    { title: "Weight", field: "weight", editable: "never" },
                    { title: "Volume", field: "volume", editable: "never" },
                  ]}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                name="description"
                variant="outlined"
                fullWidth
                disabled={props.deleteMode}
                value={formulation.description}
                multiline
                rows={3}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Unit Value"
                name="unit_value"
                variant="outlined"
                fullWidth
                type="number"
                disabled={props.deleteMode}
                error={formulation.unit_value ? false : fieldError}
                value={formulation.unit_value}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                error={false}
              >
                <InputLabel htmlFor="age-native-helper">Unit Type</InputLabel>
                <Select
                  native
                  disabled={props.deleteMode}
                  label="Unit Type"
                  style={{ width: "100%" }}
                  value={formulation.unit_type || ""}
                  onChange={handleChange}
                  error={formulation.unit_type ? false : fieldError}
                  inputProps={{
                    name: "unit_type",
                    id: "unit_type",
                  }}
                >
                  <option aria-label="None" value="" />

                  {units &&
                    Object.keys(units).map((unitKey, i) => {
                      return (
                        <option key={i} value={unitKey}>
                          {" "}
                          {units[unitKey].full_name}{" "}
                        </option>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>

            {/* remove these once everything's been migrated */}
            {/*<Grid item xs={6}>
            <TextField label="Weight" name="weight" variant="outlined" fullWidth
                       value={formulation.weight}
                       onChange={handleChange}/>
          </Grid>

          <Grid item xs={6}>
            <TextField label="Volume" name="volume" variant="outlined" fullWidth
                       value={formulation.volume}
                       onChange={handleChange}/>
          </Grid>*/}

            {props.templateMode && (
              <Grid item xs={12}>
                <IngredientTemplateList
                  formulation_template_pk={formulation.pk}
                />
              </Grid>
            )}

            {!props.templateMode && (
              <Grid item xs={12}>
                <IngredientList
                deleteMode={props.deleteMode}
                  haccpPlanPk={props.haccpPlanPk}
                  formulation_pk={formulation.pk}
                  formulation={formulation}
                  ingredients={formulation.ingredients}
                  templateSelected={templateSelected}
                  setTemplateSelected={setTemplateSelected}
                  units={units}
                />
              </Grid>
            )}

            {/*!props.templateMode &&
          <Grid item xs={12}>
            <PackagingList 
              haccpPlanPk={props.haccpPlanPk}
              formulation={formulation}
              units={units}
            />
          </Grid>
          */}
            {!props.deleteMode && (
              <Grid item xs={12}>
                <Button
                  style={{ margin: "8px" }}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Save Changes
                </Button>

                <Button
                  style={{ margin: "8px" }}
                  variant="contained"
                  color="primary"
                  onClick={saveAndContinue}
                >
                  Save and Continue
                </Button>

                {props.closeModal && (
                  <Button
                    style={{ margin: "8px" }}
                    variant="contained"
                    color="secondary"
                    onClick={props.closeModal}
                  >
                    Cancel
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        </form>
      )}

      <Message
        open={open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="right"
        handleClose={handleClose}
      />
    </div>
  );
}
export default Formulation;
