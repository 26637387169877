import React, { useState } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import Search from "../Search";
import useDesktop from "../../hooks/useDesktop";

export default function ContentHeader({ title, children, ...props }) {
    const onDesktop = useDesktop();
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    return (
        <Grid
            container
            item
            xs={12}
            alignItems="flex-end"
            style={{
                borderBottom: props.noBorder ? null : "1px solid lightgrey",
                backgroundColor: "#f8f8f8",
                padding: onDesktop ? "32px" : "32px",
                paddingLeft: "32px",
                position: "sticky",
                top: 0,
                zIndex: 2,
            }}
        >
            {!props.haccpPlan && (
                <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{
                        paddingTop: "8px",
                        paddingBottom: onDesktop ? "0px" : "0px",
                    }}
                >
                    <Typography
                        style={{
                            position: "relative",
                            fontStyle: "Roboto",
                            color: "rgb(104, 120, 149)",
                            fontWeight: 500,
                            fontSize: "1.25rem",
                            lineHeight: "1.6",
                        }}
                    >
                        {title}
                    </Typography>
                    {props.description && (
                        <Typography
                            style={{
                                position: "relative",
                                fontStyle: "Roboto",
                                color: "rgb(104, 120, 149)",
                                fontWeight: 500,
                                fontSize: "0.8rem",
                                lineHeight: "1.6",
                            }}
                        >
                            {props.description}
                        </Typography>
                    )}
                </Grid>
            )}
            {props.haccpPlan && (
                <>
                    <Grid
                        item
                        xs={6}
                        md={10}
                        style={{
                            paddingTop: "8px",
                            paddingBottom: onDesktop ? "0px" : "0px",
                        }}
                    >
                        <Typography
                            style={{
                                position: "relative",
                                fontStyle: "Roboto",
                                color: "rgb(104, 120, 149)",
                                fontWeight: 500,
                                fontSize: "1.25rem",
                                lineHeight: "1.6",
                            }}
                        >
                            {title}
                        </Typography>
                        {props.description && (
                            <Typography
                                style={{
                                    position: "relative",
                                    fontStyle: "Roboto",
                                    color: "rgb(104, 120, 149)",
                                    fontWeight: 500,
                                    fontSize: "0.8rem",
                                    lineHeight: "1.6",
                                }}
                            >
                                {props.description}
                            </Typography>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        md={2}
                        container
                        style={{
                            alignItems: "center",
                            justifyContent: "flex-end",
                        }}
                    >
                        <IconButton
                            disableRipple
                            style={{
                                backgroundColor: "transparent",
                                float: "right",
                                marginTop: "4px",
                                marginBottom: "-4px",
                                marginRight: "24px",
                            }}
                            onClick={(event) => handleClick(event)}
                        >
                            <MoreVertIcon></MoreVertIcon>
                        </IconButton>

                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={menuOpen}
                            // onChange={handleClose}
                            onClose={handleMenuClose}
                            PaperProps={{
                                style: {
                                    maxHeight: 48 * 4.5,
                                    width: 200,
                                },
                            }}
                        >
                            <MenuItem
                                key={1}
                                onClick={() => {
                                    props.delete(props.haccpPlan);
                                    setAnchorEl(null);
                                }}
                            >
                                Delete
                            </MenuItem>
                        </Menu>
                    </Grid>
                </>
            )}
            {props.search && props.setSearchResults && props.searchData && (
                <Grid
                    container
                    item
                    justify={onDesktop ? "flex-end" : "center"}
                    style={{
                        marginTop: onDesktop ? "0px" : "8px",
                    }}
                    xs={12}
                    sm={6}
                >
                    <Search
                        fullWidth={!onDesktop}
                        searchData={props.searchData}
                        setSearchResults={props.setSearchResults}
                        searchKeys={props.searchKeys}
                        clearText={props.clearText ? props.clearText : false}
                        filterFunc={props.filterFunc}
                    />
                </Grid>
            )}
        </Grid>
    );
}
