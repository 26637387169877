import React, { useState, useEffect, useRef } from 'react';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Traceability from "../Traceability";
import TraceabilityUtils from "../TraceabilityUtils";
import Message from "../../Components/Message";
import AutocompleteSD from "../../Components/AutocompleteSD";
import CircularProgress from "@material-ui/core/CircularProgress";
import ItemTable from '../ItemTable/ItemBody';
import ItemModal from './ItemModal';
import PropTypes from 'prop-types';
import { StylesContext } from "../../App";
import AddIcon from "@material-ui/icons/Add";
import AddFIngredientsDialog from "../Fcustomer/AddFIngredientsDialog";
import SignatureCanvas from 'react-signature-canvas';
import ReactSignatureCreator from 'Components/ReactSignatureCreator';


const EMPTY_MESSAGE = {
  open: false,
  message: '',
  status: 'info',
};

export default function OrderForm(props) {
  const ingredientApi = new Traceability().getFIngredientsAPI();
  const receivingApi = new Traceability().getFReceivingAPI();
  const classes = React.useContext(StylesContext);
  const utils = new TraceabilityUtils();
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(false);
  const [receivingForm, setReceivingForm] = useState(false);
  const [message, setMessage] = useState({ ...EMPTY_MESSAGE });
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [addItemModalOpen, setAddItemModalOpen] = useState(false);
  const [lineInfo, setLineInfo] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [receivedIngredients, setReceivedIngredients] = useState([]);
  const [ingredients, setIngredients] = useState(props.ingredients ? props.ingredients : []);
  const [signature, setSignature] = useState(null);
  const [validSignature, setValidSignature] = useState(false);
  const sigCanvas = useRef(null);

  useEffect(() => {
    setLineInfo([
      {
        key: 'ingredient_name',
        title: 'Ingredient Name',
        type: 'freeSolo',
        choices: ingredients,
        choiceDisplay: 'name'
      },
      {
        key: 'unit_value',
        title: 'Quantity',
        type: 'numeric'
      },
      {
        key: 'unit_type',
        title: 'Units',
        type: 'select',
        choices: props.units?.filter(unitObj => unitObj.unit_category == 'GLO') || [],
        choiceDisplay: 'full_name',
        filterBy: 'ingredient_name',
        filterFrom: ingredients,
        filterFromKey: 'name',
        filterUsingKey: 'unit_metric'
      },
      {
        key: 'external_lot_code',
        title: 'External Lot Code',
        type: 'text'
      },
    ])

    setOrder(props.order ? props.order : {});
    let items = props.order.is_draft ? props.order.draft_items : props.order.item_instances;
    if (props.order && items) {

      // Map the draft items to the format expected by the ItemTable component
      const formattedDraftItems = items.map((draftItem) => {
        const unitType = props.units.find(unit => unit.pk == draftItem.unit_type)
        const formattedUnitType = unitType || {};
        // console.log("formattedUnitType >> ",formattedUnitType);
        return {
          // organization: draftItem.ingredient.organization,
          ingredient_name: draftItem.ingredient,
          unit_value: draftItem.unit_value,
          unit_type: formattedUnitType,
          external_lot_code: draftItem.external_lot_code,
        };
      });
      setReceivedIngredients(formattedDraftItems);
    }
    setLoading(false);
  }, [props.order, ingredients]);

  /* It's quicker to delete by just adding an ignore flag and not send those lines to the BE.
  */
  function deleteLine(lineData, setLineData) {
    if (window.confirm('Are you sure you want to delete ' + lineData.ingredient_name || 'this line' + '?')) {
      setReceivedIngredients(receivedIngredients.filter(item => item.ingredient_name !== lineData.ingredient_name))
    }
  }

  const actions = [
    { /*title: 'Delete',*/ icon: (<CancelIcon />), func: deleteLine, disabled: order.status == 'COMP' },
  ]

  function setLineItems(newLineItems) {
    // console.log("setLineItems = ", newLineItems);
    // let newOrder = { ...order };
    // newOrder.sku_instances = newLineItems;
    // setOrder(newOrder);
    setReceivedIngredients(newLineItems)
  }
  // new  
  function handleAddItem() {
    let organization = JSON.parse(localStorage.getItem("organization"));
    setReceivedIngredients([...receivedIngredients, { organization: organization.pk, ingredient_name: '', unit_value: 0, unit_type: {}, external_lot_code: '' }]);
  }

  function validateForm(is_draft) {
    setSubmitAttempted(true);
    // if (!order.receiving_id) {
    //   setMessage({ open: true, message: 'Please specifiy an Receiving ID.', status: 'error' });
    //   return false;
    // }

    if (!order.supplier) {
      setMessage({ open: true, message: 'Please specifiy Supplier.', status: 'error' });
      return false;
    }
    if (!order.receiving_date) {
      setMessage({ open: true, message: 'Please specifiy Receiving date.', status: 'error' });
      return false;
    }
    // let ingredients = receivedIngredients.filter(item => {
    //   return item.ingredient_name !== '' && item.unit_value !== '' && Object.keys(item.unit_type).length !== 0;
    // });
    if (receivedIngredients.length < 1) {
      setMessage({ open: true, message: 'You must receive at least one line item.', status: 'error' });
      return false;
    }

    if (!is_draft && !(!!signature)) {
      setMessage({ open: true, message: 'Please specifiy Signature.', status: 'error' });
      return false;
    }

    var linesValidated = true;
    receivedIngredients.forEach((row, i) => {
      if (!lineValidation(row, (i + 1))) {
        linesValidated = false;
      }
    })

    return linesValidated;
  }

  function lineValidation(row, i) {
    if (!row.ingredient_name) {
      setMessage({ open: true, message: 'Please specify an item for row ' + i + '.', status: 'error' });
      return false;
    }

    const isValidNumber = /^\d+(\.\d+)?$/.test(row.unit_value);
    if (!row.unit_value || !isValidNumber) {
      setMessage({ open: true, message: 'Please specify a valid amount for row ' + i + '.', status: 'error' });
      return false;
    }

    if (!row.unit_type || !row.unit_type.full_name) {
      setMessage({ open: true, message: 'Please specify unit type for row ' + i + '.', status: 'error' });
      return false;
    }

    if (!row.external_lot_code) {
      setMessage({ open: true, message: 'Please specify external lot code for row ' + i + '.', status: 'error' });
      return false;
    }

    return true;
  }

  function handleSubmit(saveAndContinue, is_draft) {
    /*setMessage({
      open: true,
      message: 'Gonna disappear on re-render.',
      status: 'success',
    });*/
    if (is_draft) {
      setSignature(null);
    }
    setSubmitting(true);
    if (!validateForm(is_draft)) {
      setSubmitting(false);
      return;
    }

    let formattedOrder = {};
    // formattedOrder.is_draft = order.is_draft;
    if (!!order.id) {
      formattedOrder.id = order.id;
    }
    formattedOrder.receiving_date = order.receiving_date;
    // formattedOrder.supplier = order.supplier;

    // let organization = JSON.parse(localStorage.getItem("organization"));

    // formattedOrder.organization = organization.pk;
    // formattedOrder.traceability = props.activeTraceability.pk;
    let selectedIngredients = [...receivedIngredients];

    const groupedIngredients = selectedIngredients.reduce((acc, ingredient) => {
      const key = `${ingredient.ingredient_name}-${ingredient.external_lot_code}`;
      if (acc[key] && acc[key].unit_type.full_name === ingredient.unit_type.full_name) {
        // If the unit types match, simply add the unit values
        acc[key].unit_value += parseFloat(ingredient.unit_value);
      }
      else if (acc[key] && acc[key].unit_type.full_name != ingredient.unit_type.full_name) {
        // If the unit types don't match, find the base unit type and convert both values to it
        const baseUnitType = props.units.find(unit => unit.is_base === true);
        const baseRatioToBase = parseFloat(baseUnitType.ratio_to_base);
        // Convert ingredient unit value to base unit value
        const ingredientBaseValue = parseFloat(ingredient.unit_value) * parseFloat(ingredient.unit_type.ratio_to_base);
        // Add converted base unit value to existing base unit value
        acc[key].unit_value += ingredientBaseValue / baseRatioToBase;
      }
      else if (!acc[key]) {
        acc[key] = {
          ...ingredient,
          unit_value: parseFloat(ingredient.unit_value) // Convert unit_value to a number
        };
      }
      
      return acc;
    }, {});

    // Converting groupedIngredients object back to array
    const aggregatedIngredients = Object.values(groupedIngredients);

    formattedOrder.ingredients = aggregatedIngredients.map(selectedIngredient => {
      let matchedIngredient = ingredients.find(ingredient => ingredient.name === selectedIngredient.ingredient_name);

      // If a matching ingredient is found, use its pk, otherwise use null
      let id = matchedIngredient ? matchedIngredient.id : null;

      return {
        "id": id,
        "unit_value": selectedIngredient.unit_value, // Assuming unit_value is always the same for all ingredients
        "unit_type": selectedIngredient.unit_type.pk, // Assuming unit_type is always the same for all ingredients
        "external_lot_code": selectedIngredient.external_lot_code // Assuming external_lot_code is always the same for all ingredients
      };
    });
    formattedOrder.is_draft = is_draft;
    if (!is_draft) {
      formattedOrder.confirmation_signature = validSignature ? signature : null;
    }
    formattedOrder.supplier = !!order.supplier && order.supplier.id?order.supplier.id : order.supplier;

    // const relatedIngredient = props.ingredients.find((ingredient) => ingredient.name == skuObject.ingredient_name);

    // if(!relatedIngredient) {
    //   let shouldContinue = window.confirm('This will add the ingredient ' + skuObject.ingredient_name + ' to the system. Continue?');

    //   if(!shouldContinue) {
    //     setSubmitting(false);
    //     return;
    //   }
    // }

    // if (order.status_override) {
    //   formattedOrder.status = order.status_override
    // }
    // else {
    //   delete formattedOrder.status;
    // }

    // let formattedInstances = [];

    // formattedOrder.sku_instances.filter(skuInstance => !skuInstance.delete).forEach((sku_instance) => {
    //   let newInstance = { ...sku_instance };

    //   newInstance.organization = organization.pk;
    //   newInstance.traceability = props.activeTraceability.pk;
    //   newInstance.hiddenPk = newInstance.pk;
    //   newInstance.sku = newInstance.sku.pk;

    //   delete newInstance.tableData;

    //   if (newInstance.status_override) {
    //     newInstance.status = newInstance.status_override;
    //   }
    //   else {
    //     delete newInstance.status;
    //   }

    //   if (newInstance.quantity_ordered == "") {
    //     newInstance.quantity_ordered = null;
    //   }

    //   formattedInstances.push(newInstance);
    // })

    // formattedOrder.sku_instances = formattedInstances;

    // formattedOrder.created_date = formattedOrder.created_date ? formattedOrder.created_date : null;
    // formattedOrder.expected_delivery = formattedOrder.expected_delivery ? formattedOrder.expected_delivery : null;
    // console.log(JSON.stringify(formattedOrder));

    const saveMethod = formattedOrder.id ? receivingApi.updateReceiving(formattedOrder) : receivingApi.createReceiving(formattedOrder);

    saveMethod.then(response => {
      // if (props.setShouldClose) {
      //   props.setShouldClose(true);
      // }

      let newOrder = { ...response.data };

      setOrder(newOrder);
      setSubmitAttempted(false);
      setMessage({
        open: true,
        message: 'Saved Successfully',
        status: 'success',
      });
    
      if (saveAndContinue) {
        // handleSaveAndContinue(newOrder.id, setSubmitting);
        props.onSave(response,true);
        props.closeModal();
      }else{
        props.onSave(response);
      }
      setSubmitting(false);
    }).catch(error => {
      //console.log('Error Response: ' + JSON.stringify(error));
      setSubmitting(false);
      setMessage({ open: true, message: 'Save Failed: ' + utils.formatError(error), status: 'error' });
    });
    // props.setShouldClose(true);
    // console.log("props.setShouldClose(true) >> true");

    return "";
  }

  function handleTextFieldChange(event) {
    // if (props.setShouldClose) {
    //   props.setShouldClose(false);
    // }
    const property = event.target.name;
    const value = event.target.value;

    setOrder({ ...order, [property]: value });
  }

  function handleAutoCompleteChange(newKey, newValue) {
    // if (props.setShouldClose) {
    //   props.setShouldClose(false);
    // }
    setOrder(order => ({ ...order, [newKey]: newValue }));
  }

  // function cancel() {
  //   setOrder(props.order ? props.order : {});

  //   if (props.setShouldClose) {
  //     props.setShouldClose(false);
  //   }
  //   if (props.closeModal) {
  //     props.closeModal();
  //   }
  // }

  // function handleSaveAndContinue(id, setSubmitting) {
  //   props.handleStatusSubmit(id, setSubmitting);

  //   /*if (props.cancel) {
  //     props.cancel();
  //   }*/
  // }

  const handleCloseDialog = () => {
    setOpenDialog(false);
    // setIngredientToEdit(null);
  };

  const addIngredient = async (payload) => {
    try {
      handleCloseDialog();
      const response = await ingredientApi.createFIngredients(payload);
      props.updateIngredients(response.data);
      setIngredients([...ingredients, response.data]);
      setMessage({ open: true, message: 'Ingredient Created Successfully', status: 'success' });
    } catch (error) {
      console.error("Error adding customer:", error);
      setMessage({ open: true, message: 'Server Error: ' + utils.formatError(error), status: 'error' });
    }
  };

  function checkSignature(sigPad) {
    let sig = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
    setSignature(sig);
    setValidSignature(true);
  }

  function validSignatureCheck(validCheck) {
    setValidSignature(validCheck);
  }

  return (
    <>
      {(!props.order || loading) &&
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginTop: "250px" }}>
            <Typography>
              <CircularProgress />
            </Typography>
          </Grid>
        </Grid>
      }
      {(props.order && !loading) &&
        <form onSubmit={handleSubmit}>
          {/* This Paper serves as the body element. */}
          <Paper elevation={0} className={classes.generalFormPaperFieldHolder} style={{ paddingTop: '0px' }}>
            <Grid container spacing={3} className={classes.generalContainerGridBody}>

              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  Details
                </Typography>
              </Grid>

              {/* <Grid item xs={3}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Receiving ID
                </Typography>
                <TextField
                  fullWidth
                  className={classes.generalFormTextField}
                  name="receiving_id"
                  variant="outlined"
                  value={order.receiving_id}
                  onChange={handleTextFieldChange}
                  error={submitAttempted && !order.receiving_id}
                  disabled={order.status == 'COMP' || props.disabled}
                />
              </Grid> */}
              <Grid item sm={4} xs={6}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Supplier
                </Typography>
                <AutocompleteSD
                  value={order.supplier}
                  id="supplier"
                  className={classes.generalFormTextField}
                  optionType={'pkIndexingToObjects'}
                  options={Object.keys(props.suppliers).map((key) => { return props.suppliers[key].id })}
                  choices={props.suppliers}

                  getOptionLabel={(option) => {
                    option = option.id ? option.id : option
                    return (option && props.suppliers[option].name) ? props.suppliers[option].name : 'No Name'
                  }}
                  onChange={(emptyEvent, newValue) => { handleAutoCompleteChange('supplier', newValue) }}
                  renderInput={(params) => <TextField {...params} variant="outlined" error={submitAttempted && !order.supplier} />}
                  disabled={order.status == 'Complete' || props.disabled}
                />
              </Grid>
              <Grid item sm={4} xs={6}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Receiving Date
                </Typography>
                <TextField
                  fullWidth
                  className={classes.generalFormTextField}
                  name="receiving_date"
                  type="date"
                  variant="outlined"
                  //defaultValue={new Date().toISOString().substr(0,10)}
                  value={order.receiving_date}
                  onChange={handleTextFieldChange}
                  disabled={order.status == 'Complete' || props.disabled}
                />
              </Grid>
              <Grid item xs={12} container justify="space-between" alignItems="center" style={{ paddingRight: 0 }}>
                <Typography variant="h5">
                  Receive Items
                </Typography>
                {order.status !== "Complete" &&
                  <>
                    <Typography variant="h6" style={{ flex: 1, textAlign: 'right', marginRight: 8 }}>
                      Ingredient not found in the list ?
                    </Typography>
                    <Button size="small" color="primary" variant="outlined" aria-label="add" style={{ marginLeft: 'auto' }}
                      className={classes.tabTableFreeAction} onClick={() => { setOpenDialog(true) }}
                    >
                      New Ingredient
                    </Button></>}
              </Grid>


              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <ItemTable
                  tableData={receivedIngredients}
                  //tableTitle={'Order Items'}
                  setLineData={setLineItems}
                  lineInfo={lineInfo}
                  actions={actions}

                  handleAddItem={(order.status == "Complete" || props.disabled) ? false : () => { handleAddItem() }}
                  ineditable={order.status == "Complete" || props.disabled}
                />
              </Grid>
              {order.status !== "Complete" &&
                <ReactSignatureCreator
                  // initialSignature={order.confirmation_signature}
                  checkSignature={checkSignature}
                  validSignatureCheck={validSignatureCheck}
                // disabled={order.status === "Complete" || props.disabled}
                // ref={sigCanvas}
                />}
              {order.status == "Complete" && !!order.confirmation_signature &&
                <Grid item xs={12}><img src={order.confirmation_signature} /> </Grid>
              }
            </Grid>
          </Paper>

          {/* This Grid serves as the footer element. */}
          <Paper elevation={0} className={classes.generalFormPaperStickyFooter}>
            <Grid container spacing={3} className={classes.generalContainerGridFoot}>
              <Grid item container xs={12} alignItems="center" justify="flex-end" className={classes.generalContainerGridFoot}>
                <Button
                  //variant="outlined"
                  color="secondary"
                  style={{ marginLeft: "8px" }}
                  onClick={props.closeModal}
                // disabled={order.status == "Complete" || props.disabled || submitting}
                >
                  Cancel
                </Button>
                {order.status !== "Complete" && (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginLeft: "8px" }}
                      onClick={() => { handleSubmit(false, true) }}
                      disabled={order.status == "Complete" || props.disabled || submitting}
                    >
                      Save as Draft
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginLeft: "8px" }}
                      onClick={() => { handleSubmit(true, false,) }}
                      disabled={order.status == "Complete" || props.disabled || submitting}
                    >
                      Submit
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          </Paper>
        </form>
      }

      <Dialog open={addItemModalOpen} onClose={() => { setAddItemModalOpen(false) }} maxWidth={"lg"}>
        <DialogContent>
          <ItemModal
            // skuInstance={{}}
            closeModal={() => { setAddItemModalOpen(false) }}
            // skus={props.skus}
            ingredients={ingredients}
            units={props.units}
            handleAddItem={handleAddItem}
          />
        </DialogContent>
      </Dialog>

      <AddFIngredientsDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        handleSave={addIngredient}
        ingredientToEdit={null}
        editable={true}
      />
      <Message
        open={message.open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="left"
        handleClose={() => { setMessage({ ...message, open: false }) }}
      />
    </>
  )
}

OrderForm.propTypes = {
  // setShouldClose: PropTypes.func,
  order: PropTypes.object,
  closeModal: PropTypes.bool,
  suppliers: PropTypes.objectOf(PropTypes.object),
  customers: PropTypes.objectOf(PropTypes.object),
  skus: PropTypes.objectOf(PropTypes.object),
  ingredients: PropTypes.objectOf(PropTypes.object),

  units: PropTypes.objectOf(PropTypes.object),
  inventoryLocations: PropTypes.objectOf(PropTypes.object),
  cancel: PropTypes.func,
  activeTraceability: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}