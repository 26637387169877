class ProductDescriptionsAPI {

  constructor(api, version, name) {
    this.api = api;
    this.version = version;
    this.name = "ProductDescriptionsAPI";
  }

  listProductDescriptions() {
    return this.api.get(`${this.version}/product_descriptions/`)
  }

  createProductDescription(payload) {
    return this.api.post(`${this.version}/product_descriptions/`, payload);
  }

  retrieveProductDescription(pk) {
    return this.api.get(`${this.version}/product_descriptions/${pk}/`);
  }

  updateProductDescription(payload) {
    return this.api.put(`${this.version}/product_descriptions/${payload.pk}/`, payload);
  }

  deleteProductDescription(pk) {
    return this.api.delete(`${this.version}/product_descriptions/${pk}/`);
  }

  selectTemplate(pk, template) {
    return this.api.post(`${this.version}/product_descriptions/${pk}/select_template/`, template);
  }

  getHistory(pk) {
    return this.api.get(`${this.version}/product_descriptions/${pk}/get_history/`);
  }
  getRevertedProductDescription(pk, date) {
    return this.api.post(`${this.version}/product_descriptions/${pk}/get_reverted_product_description/`, date);
  }
}


export default ProductDescriptionsAPI;
