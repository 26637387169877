import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  textFields: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalDisplay: {
    padding: '20px',
    width: '50%',
    overflowY: 'scroll',
  },
  buttons: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));



export default function GridMenu(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(props.open);
  const [squareGrid] = React.useState(props.squareGrid === undefined ? true : props.squareGrid);
  const [width, setWidth] = React.useState(props.width || 50);
  const [height] = React.useState(props.height || 50);
  const [color, setColor] = React.useState(null);

  // function cacheColorChoice(colorObject) {
  //   setColor(colorObject);
  // }

  function returnGridSettings() {
    let finalWidth = width > 0 ? width : 1;
    let finalHeight = height > 0 ? height : 1;

    if(squareGrid) {
      props.applyGridSettings(finalWidth, finalWidth, color);
    }
    else {
      props.applyGridSettings(finalWidth, finalHeight, color);
    }
  }


  return (
    <Modal
      aria-labelledby='Grid-Menu'
      className={classes.modal}
      open={open}
      onClose={() => {props.onClose ? props.onClose() : setOpen(false)}}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{timeout:800}}
    >
      <Fade in={open}>
        <Paper elevation={3} className={classes.modalDisplay}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h6'>
                Grid Settings
              </Typography>
              <Typography>
                Change the spacing, shape, and color of the canvas grid.
              </Typography>
            </Grid>
            {/*<Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={squareGrid}
                    onChange={() => {setSquareGrid(!squareGrid)}}
                    name="Grid is Square?"
                    
                    color="primary"
                  />
                }
                label="Grid is Square?"
              />
            </Grid>*/}
            <Grid className={classes.textFields} item xs={12}>
              <TextField
                id="outlined-number"
                label={squareGrid ? "Grid Spacing (in pixels)" : "Grid Width"}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={width}
                onChange={(event) => {setWidth(event.target.value)}}
                error={width <= 0}
                helperText={ width <= 0 ? "Number must be positive" : "" }
              />

              {/*!squareGrid && 
                <TextField
                  disabled={squareGrid}
                  id="outlined-number"
                  label="Grid Height"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={squareGrid ? width : height}
                  onChange={(event) => {setHeight(event.target.value)}}
                  error={height <= 0}
                  helperText={ height <= 0 ? "Number must be positive" : "" }
                />
                */}
            </Grid>
            {/*<Grid item xs={12}>
              <Typography variant='h6'>
                Grid Color
              </Typography>
              <SwatchesPicker
                onChangeComplete={props.handleSwatchChange || cacheColorChoice}
                height={570}
                width={350}
              />
            </Grid>*/}
            <Grid className={classes.buttons} item xs={12}>
              <Button onClick={returnGridSettings} color="primary" variant="contained">
                Apply Grid Settings
              </Button>
              <Button onClick={props.cancelGridSettings} color="secondary" variant="contained">
                Cancel
              </Button>
            </Grid>
          </Grid>
          
        </Paper>
      </Fade>
    </Modal>
  )
}

GridMenu.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  squareGrid: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  handleSwatchChange: PropTypes.func,
  applyGridSettings: PropTypes.func,
  cancelGridSettings: PropTypes.func,
}
