class FProductsAPI {
  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listFProducts(id) {
    if (typeof id === 'number') {
      return this.api.get(`${this.version}/f_products/${id}/`);
    }
    return this.api.get(`${this.version}/f_products/`)
  }

  createFProduct(payload) {
    return this.api.post(`${this.version}/f_products/`, payload);
  }

  updateFProduct(payload) {
    return this.api.put(`${this.version}/f_products/${payload.id}/`, payload);
  }

  deleteFProduct(id) {
    return this.api.put(`${this.version}/f_products/${id}/soft_delete_action/`);
  }

}

export default FProductsAPI;