export default class PolicyAPI {
  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listPolicies() {
    return this.api.get(`${this.version}/policies/`);
  }

  getPolicy(pk) {
    return this.api.get(`${this.version}/policies/${pk}`);
  }

  createPolicy(payload) {
    return this.api.post(`${this.version}/policies/`, payload);
  }

  updatePolicy(payload) {
    return this.api.put(`${this.version}/policies/${payload.pk}/`, payload);
  }

  deletePolicy(pk) {
    return this.api.delete(`${this.version}/policies/${pk}/`);
  }
}
