class OrderAPI {
  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listReceivings(receiving_pk) {
    if (typeof receiving_pk === 'number') {
      return this.api.get(`${this.version}/receivings/${receiving_pk}/`);
    }
    return this.api.get(`${this.version}/receivings/`)
  }
  createReceiving(payload) {
    return this.api.post(`${this.version}/receiving/`, payload);
  }
  updateReceiving(payload) {
    return this.api.put(`${this.version}/receiving/${payload.pk}/`, payload);
  }


  
  listOrders(order_pk) {
    if (typeof order_pk === 'number') {
      return this.api.get(`${this.version}/orders/${order_pk}/`);
    }

    return this.api.get(`${this.version}/orders/`)
  }
  createOrder(payload) {
    return this.api.post(`${this.version}/orders/`, payload);
  }

  updateOrder(payload) {
    return this.api.put(`${this.version}/orders/${payload.pk}/`, payload);
  }

  listOrdersSkuExpanded(order_pk) {
    if (typeof order_pk === 'number') {
      return this.api.get(`${this.version}/orders/${order_pk}/list_sku_expanded_specific/`);
    }

    return this.api.get(`${this.version}/orders/list_sku_expanded/`)
  }


  /**
   * Sets the status of the order following the pattern Draft -> Outstanding -> Receiving -> Complete
   * @param {*} pk - The primary key of the order to update.
   */
  updateOrderStatus(pk) {
    return this.api.get(`${this.version}/orders/${pk}/update_status/`);
  }

  deleteOrder(pk) {
    return this.api.delete(`${this.version}/orders/${pk}/`);
  }

  /**
   * Creates a receiving record for the order.
   * @param {*} payload -Data to create the receiving record.
   */
  receiveOrder(payload) {
    return this.api.post(`${this.version}/orders/${payload.pk}/receive/`, payload);
  }

  listActive(payload) {
    return this.api.get(`${this.version}/orders/list_active/`, payload);
  }

  listActiveShipping(pk) {
    if (typeof pk === 'number') {
      return this.api.get(`${this.version}/orders/${pk}/list_active_shipping_specific/`);
    }

    return this.api.get(`${this.version}/orders/list_active_shipping/`);
  }
}

export default OrderAPI;