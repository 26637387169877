import HaccpPlanList from "./HaccpPlanList";
import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import HaccpPlan from "./HaccpPlan";
import Grid from "@material-ui/core/Grid";


function HaccpPlans(props) {
  let match = useRouteMatch();
  const section = {
    height: "100%",
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Switch>
        <Route path={`${match.path}/:pk`}>
          <Grid container spacing={0} style={section}>
            <Grid item xs>
              <HaccpPlan drawerOpen={props.drawerOpen} user={props.user}  sideMenuOpen={props.sideMenuOpen} />
            </Grid>
          </Grid>
        </Route>

        <Route path="*">
          <HaccpPlanList user={props.user} sideMenuOpen={props.sideMenuOpen} />
        </Route>
      </Switch>
    </div>
  );
}

export default HaccpPlans;
