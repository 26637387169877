class IngredientsAPI {

  constructor(api, version, name) {
    this.api = api;
    this.version = version;
    this.name = name;
    this.url = `${this.version}/ingredients`;
  }

  listIngredients(formulationPk = null, haccpPlanPk = null, hazardPk = null) {
    if (formulationPk && typeof formulationPk === 'number') {
      return this.api.get(`${this.url}/?formulation_pk=${formulationPk}`);
    }

    if (haccpPlanPk && typeof haccpPlanPk === 'number') {
      return this.api.get(`${this.url}/?haccp_plan_pk=${haccpPlanPk}`);
    }

    return this.api.get(`${this.url}/`);
  }

  listAllIngredientsOfOrg(haccpPlanPk) {
    if (haccpPlanPk) {
        return this.api.get(`${this.url}/?haccp_plan_pk=${haccpPlanPk.pk}`);
    
    }
    return this.api.get(`${this.url}/`);
  }

  listIngredientsMinimizedJoins(formulationPk = null, haccpPlanPk = null) {
    if (formulationPk && typeof formulationPk === 'number') {
      return this.api.get(`${this.url}/get_list_few_joins/?formulation_pk=${formulationPk}`);
    }

    if (haccpPlanPk && typeof haccpPlanPk === 'number') {
      return this.api.get(`${this.url}/get_list_few_joins/?haccp_plan_pk=${haccpPlanPk}`);
    }

    return this.api.get(`${this.url}/get_list_few_joins/`)
  }

  createIngredient(payload) {
    return this.api.post(`${this.url}/`, payload);
  }

  retrieveIngredient(pk) {
    return this.api.get(`${this.url}/${pk}/`);
  }

  updateIngredient(payload) {
    return this.api.put(`${this.url}/${payload.pk}/`, payload);
  }

  deleteIngredient(pk) {
    return this.api.delete(`${this.url}/${pk}/`);
  }

  selectDatasetIngredient(ingredient, ingredientSelected, hazardsSelected) {
    let payload = { "ingredient_selected": ingredientSelected, "hazards_selected": [...hazardsSelected] };
    return this.api.post(`${this.url}/${ingredient.pk}/select_dataset/`, payload);
  }

  selectTemplate(pk, template) {
    return this.api.post(`${this.url}/${pk}/select_template/`, template);
  }

  addHazardToIngredient(ingredient_pk, hazard) {
    return this.api.post(`${this.url}/${ingredient_pk}/add_hazard/`, hazard);
  }

  removeHazardFromIngredient(ingredient_pk, hazard) {
    return this.api.post(`${this.url}/${ingredient_pk}/remove_hazard/`, hazard);
  }

  getHistory(pk) {
    return this.api.get(`${this.url}/${pk}/get_history/`);
  }
  getRevertedIngredient(pk, date) {
    return this.api.post(`${this.version}/ingredients/${pk}/get_reverted_ingredient/`, date);
  }
}


export default IngredientsAPI;
