import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import API from "Api/Api";
import {useParams, Link } from "react-router-dom";
import GeneralApp404 from "../../Main/GeneralApp404"

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © FoodByte '}
            {/*<Link color="inherit" href="https://material-ui.com/">*/}
            {/*</Link>{' '}*/}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function PasswordReset(props) {
  const classes = useStyles();
  const api = new API();
  const currentParams = useParams();

  const [show404, setShow404] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);


  useEffect(() => {
    api.getAuthAPI().checkEmailChangeRequest({token: currentParams.uuid, hash: currentParams.hash}).then(() => {
      api.getAuthAPI().changeEmailConfirmation({token: currentParams.uuid, hash: currentParams.hash}).then(() => {
        setShowSuccess(true);
      }).catch(e => {
        console.log(e);
      });
    }).catch(e => {
      setShow404(true);
      console.log(e);
    });
  }, []);



  return (
    <React.Fragment>
      {show404 && <GeneralApp404></GeneralApp404>}
      {showSuccess &&
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar} alt="FoodByte Logo" src="logos/FB_Logo_Reversed_RGB_Icon.png"/>
            <Typography component="h1" variant="h5">
                FoodByte
            </Typography>
            <form className={classes.form}>
            <Typography component="h1" variant="h6" align="center">
                Your email has been updated!
            </Typography>

            <Link to="/login">

              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Login Now
              </Button>
            </Link>

              </form>
          </div>
          <Box mt={8}>
              <Copyright />
          </Box>
      </Container>
    }   
    
    </React.Fragment>
  );
}

