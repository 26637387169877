import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CancelIcon from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/core/styles";

import API from "Api/Api";
import { OrgContext } from "hooks/useOrganization"
import Message from "Components/Message";

import Step0 from "./Step0";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

import CompletedDeviationForm from "./CompletedDeviationForm";


const useStyles = makeStyles((theme) => ({
  outlinedbutton: {
    background: "#18bff6",
    margin: "8px",
    width: 100,
    float: "right",
  },
  root: {
    width: "100%",
    height: "70px",
    paddingRight: "10px",
    flexGrow: 1,
    bottom: "0%",
    position: "fixed",
    overflowY: "hidden",
    background: "#fafafc",
  },
  rootTop: {
    width: "100%",
    opacity: 1,
    paddingTop: "0px",
    marginBottom: "12px",
    height: "auto",
    flexGrow: 1,
    top: "0%",
    position: "fixed",
    overflowY: "hidden",
    background: "#fafafc",
    zIndex: 1,
  },

  dialogcontent: {
    padding: 0,
    background: "#fafafc",
  },

  outsidediv: {
    marginTop: "115px",
    boxSizing: "content-box",
    height: "auto",
    width: "100%",
    zIndex: 5,
    overflow: "hidden",
  },
  insidediv: {
    height: "100%",
    width: "100%",
    boxSizing: "content-box",

    zIndex: 5,
    overflow: "auto",
  },
  papercontainer: {
    padding: 16,
    paddingTop: "130px",
    paddingBottom: "%100",
    minHeight: "70vh",
    height: "auto",

    zIndex: 5,
    overflow: "auto",
  },

  gridcontainer: {
    marginTop: 20,
    zIndex: 5,
    overflow: "auto",
  },

  gridcontainerstep7: {
    marginTop: 44,
    paddingBottom: 0,
    zIndex: 5,
    overflow: "auto",
  },
  griditem: { paddingLeft: "100px" },
  pagetitle: { paddingLeft: "38px", alignSelf: "center" },
  //buttoncontainer: { paddingRight: 8, paddingBottom: 44, marginBottom: 22 },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DeviationForm(props) {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [shouldClose, setShouldClose] = useState(false);
  const [deviationForm, setDeviationForm] = useState({});
  const [fieldError, setFieldError] = useState(true);
  const [uploadedCameraImages, setUploadedCameraImages] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const org = useContext(OrgContext);
  const api = new API();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const classes = useStyles();

  const onDesktop = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    let pk = props.pk || params.pk;

    if (pk) {
      api
        .getDeviationAPI()
        .getDeviation(pk)
        .then((response) => {
          let deviationForm = response.data;
          setDeviationForm(deviationForm);
          if (deviationForm.time_submitted !== "---") {
            setSignature(response.data.signature);
          }
          setLoading(false);
          setReadOnly(deviationForm.time_submitted !== "---");
          if (deviationForm.time_submitted !== "---") {
            setFieldError(false);
            setActiveStep(3);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  function handleChange(event) {
    setShouldClose(true);
    const value = event.target.value;
    setDeviationForm({
      ...deviationForm,
      [event.target.name]: value,
    });
  }

  function handleClick(value) {
    setDeviationForm({
      ...deviationForm,
      "safety_compromised": value,
    });
  }

  function handleClickStep3(value) {
    setDeviationForm({
      ...deviationForm,
      "satisfactory": value,
    });
  }
  const [readOnly, setReadOnly] = useState(false);

  function submitForm() {
    setShouldClose(false);

    let form = { ...deviationForm };

    form.signature = signature;

    //clear inputs on pages that should not be submitted, due to filling out section, then pressing back
    if (!form.safety_compromised) {
      form.food_safety_hazard_description = null;
      form.hazard_type = null;
      form.process_steps_involved = null;
      form.identification_of_product = null;
      form.description_of_product_holding = null;
      form.description_of_product_analysis = null;
      form.results_of_product_analysis = null;
      form.description_of_disposal_method = null;
      form.date_of_disposal = null;
      form.description_of_product_analysis_disposal = null;
      form.results_of_product_analysis_disposal = null;
      form.scope_of_impact = null;
      form.product_holding_description = null;
      form.additional_product_analysis = null;
      form.additional_product_analysis_results = null;
    }

    //setReadOnly(true);
    setLoading(true);

    api
      .getDeviationAPI()
      .updateDeviation(form)
      .then(response => {
        if (props.onComplete) {
          props.onComplete();
        }
        
        if(props.setClosed){
          props.setClosed();
        }

        history.goBack();
        //setReadOnly(true);
        setLoading(false);
      })
      .catch(error => {
        //setReadOnly(false);
        api.createErrorReport({
          path: location.pathname, 
          request: JSON.stringify({
            description: "Could not submit a deviation form because of this error", 
            error: error,
          })
        });
        history.goBack();
    });
  }

  const handleNext = () => {
    if (activeStep === 3) {
      submitForm();
      uploadFiles(filesToUpload, cameraImagesToUpload);
    } 
    else {
      if (activeStep === 1 && deviationForm.safety_compromised) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if (activeStep === 1 && !deviationForm.safety_compromised) {
        setActiveStep((prevActiveStep) => prevActiveStep + 2);
        setFieldError(true);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setFieldError(true);
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 3 && !readOnly) {
      setCameraImagesToUpload([]);
      setFilesToUpload([]);
      if (activeStep === 3 && !deviationForm.safety_compromised) {
        setActiveStep((prevActiveStep) => prevActiveStep - 2);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
    } else {
      if (activeStep === 0) {
        if (!shouldClose) {
          history.goBack();
          
        if(props.setClosed){
          props.setClosed();
        }

        } else {
          if (
            window.confirm(
              "You have unsaved changes, are you sure you want to leave?"
            )
          ) {
            
        if(props.setClosed){
          props.setClosed();
        }
            history.goBack();
          }
        }
      } else if (activeStep === 3 && deviationForm.safety_compromised) {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      } else if (activeStep === 3 && !deviationForm.safety_compromised) {
        setActiveStep((prevActiveStep) => prevActiveStep - 2);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
    }
  };

  function cancel() {
    if (!shouldClose) {
      history.goBack();
      
      if(props.setClosed){
        props.setClosed();
      }
    } else {
      if (
        window.confirm(
          "You have unsaved changes, are you sure you want to leave?"
        )
      ) {
        history.goBack();
        
        if(props.setClosed){
          props.setClosed();
        }
      }
    }
  }

  const [message, setMessage] = React.useState({
    message: "",
    status: "info",
  }); // error, warning, info, info
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const [signature, setSignature] = useState(null);

  function checkSignature(sigPad) {
    let sig = sigPad.current.getTrimmedCanvas().toDataURL("image/png");
    setSignature(sig);
  }

  function uploadFiles(draggedFiles, cameraFiles) {
    let allFiles = [...uploadedFiles];
    let allCameraImages = [...uploadedCameraImages];

    draggedFiles.forEach((image) => {
      var payloadForm = new FormData();
      payloadForm.append("name", image.name);
      payloadForm.append("image", image);
      //Get organization the new way TODO
      if (org) {
        payloadForm.append("organization", org.pk);
      }

      api
        .getDeviationImagesAPI()
        .uploadImages(payloadForm)
        .then((e) => {
          allFiles.push(e.data);
          setUploadedFiles(allFiles);
          return e;
        })
        .catch((e) => {
          console.log(e);
        });
    });

    cameraFiles.forEach((image) => {
      var byteString = atob(image.image.split(",")[1]);
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      var blob = new Blob([ia], { type: "image/jpeg" });
      var file = new File([blob], "image.jpg");

      var payloadForm = new FormData();
      payloadForm.append("name", image.title);
      payloadForm.append("image", file);
      payloadForm.append("organization", org.pk);
      api
        .getDeviationImagesAPI()
        .uploadImages(payloadForm)
        .then((e) => {
          allCameraImages.push(e.data);
          setUploadedCameraImages(allCameraImages);
          return e;
        })
        .catch((e) => {
          console.log(e);
        });
    });
    // setUploadedCameraImages(allCameraImages);
    // setUploadedFiles(allFiles);
  }

  const [filesToUpload, setFilesToUpload] = useState([]);
  function setDroppedFiles(files) {
    setFilesToUpload(files);
  }

  const [cameraImagesToUpload, setCameraImagesToUpload] = useState([]);
  function setCameraImages(files) {
    setCameraImagesToUpload(files);
  }

  useEffect(() => {
    if (mrRef.current) {
      mrRef.current.scrollTo(0, 0);
    }
  }, [activeStep]);

  const mrRef = useRef();

  return (
    <Dialog open={true} fullScreen TransitionComponent={Transition}>
      {loading && (
        <DialogContent>
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            justify="center"
            style={{ width: "100%", height: "100%" }}
          >
            <CircularProgress />
          </Grid>
        </DialogContent>
      )}

      <Message
        open={open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="right"
        handleClose={handleClose}
      />

      {!loading && (
        <>
          <DialogTitle
            style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
          >
            <Grid container spacing={3}>
              <Grid item xs={8} className={classes.pagetitle}>
                <Typography variant="h6">Deviation Form #{deviationForm.form_num}</Typography>
              </Grid>
              <Grid
                item
                container
                xs={4}
                alignItems="center"
                justify="flex-end"
              >
                <IconButton
                  aria-label="cancel"
                  onClick={cancel}
                  style={{ padding: 0 }}
                >
                  <CancelIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          {!readOnly && (
            <DialogContent className={classes.dialogcontent}  ref={mrRef}>
              <Grid container justify={onDesktop ? "center" : "flex-start"} alignItems="center" style={{height: '100%', width: '100%'}}>
                {activeStep === 0 && (
                  <Step0
                    handleChange={handleChange}
                    setFieldError={setFieldError}
                    deviationForm={deviationForm}
                    open={activeStep === 0}
                    readOnly={readOnly}
                  ></Step0>
                )}
                {activeStep === 1 && (
                  <Step1
                    handleChange={handleChange}
                    setFieldError={setFieldError}
                    deviationForm={deviationForm}
                    handleClick={handleClick}
                    readOnly={readOnly}
                    open={activeStep === 1}
                  ></Step1>
                )}
                {activeStep === 2 && (
                  <Step2
                    handleChange={handleChange}
                    setFieldError={setFieldError}
                    deviationForm={deviationForm}
                    handleClick={handleClick}
                    readOnly={readOnly}
                    open={activeStep === 2}
                  ></Step2>
                )}
                {activeStep === 3 && (
                  <Step3
                    handleChange={handleChange}
                    setFieldError={setFieldError}
                    checkSignature={checkSignature}
                    deviationForm={deviationForm}
                    handleClick={handleClickStep3}
                    open={activeStep === 3}
                    uploadFiles={uploadFiles}
                    uploadedFiles={uploadedFiles}
                    uploadedCameraImages={uploadedCameraImages}
                    setDroppedFiles={setDroppedFiles}
                    setCameraImages={setCameraImages}
                    readOnly={readOnly}
                    signature={signature}
                  ></Step3>
                )}
              </Grid>
            </DialogContent>
          )}
          {readOnly && (
            <DialogContent className={classes.dialogcontent}>
              <Grid item container xs={12} alignItems="center" justify="center">
                <CompletedDeviationForm
                  deviationForm={deviationForm}
                  cancel={cancel}
                ></CompletedDeviationForm>
              </Grid>
            </DialogContent>
          )}
          {/* {readOnly && (
            <DialogContent className={classes.dialogcontent}>
              <Paper style={{ padding: "12px", margin: "16px" }}>
                <Step0
                  handleChange={handleChange}
                  setFieldError={setFieldError}
                  deviationForm={deviationForm}
                  open={activeStep === 0}
                  readOnly={readOnly}
                ></Step0>
                <Step1
                  handleChange={handleChange}
                  setFieldError={setFieldError}
                  deviationForm={deviationForm}
                  handleClick={handleClick}
                  readOnly={readOnly}
                  open={true}
                ></Step1>
                <Step2
                  handleChange={handleChange}
                  setFieldError={setFieldError}
                  deviationForm={deviationForm}
                  handleClick={handleClick}
                  readOnly={readOnly}
                  open={true}
                ></Step2>
                <Step3
                  handleChange={handleChange}
                  setFieldError={setFieldError}
                  checkSignature={checkSignature}
                  deviationForm={deviationForm}
                  handleClick={handleClickStep3}
                  open={true}
                  uploadFiles={uploadFiles}
                  uploadedFiles={uploadedFiles}
                  uploadedCameraImages={uploadedCameraImages}
                  setDroppedFiles={setDroppedFiles}
                  setCameraImages={setCameraImages}
                  readOnly={readOnly}
                  signature={signature}
                ></Step3>
              </Paper>
            </DialogContent>
          )} */}
          <DialogActions style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}>
            <Grid item xs={4} md={4} container justify="flex-start"></Grid>{" "}
            <Grid
              item
              xs={4}
              md={4}
              container
              justify="flex-end"
              style={{ flexWrap: "nowrap" }}
            >
              {activeStep === 3 && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={cancel}
                  style={{
                    margin: "8px",
                    width: 100,
                  }}
                >
                  Close
                </Button>
              )}
              {!readOnly && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleBack}
                  className={classes.outlinedbutton}
                  disabled={activeStep === 0}
                >
                  Back
                </Button>
              )}
              {activeStep !== 3 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={fieldError}
                  className={classes.outlinedbutton}
                >
                  Next
                </Button>
              )}
              {activeStep === 3 && !readOnly && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={activeStep === 7 || fieldError}
                  className={classes.outlinedbutton}
                >
                  Submit
                </Button>
              )}
            </Grid>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default DeviationForm;
