import React, { useEffect, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import ContentHeader from "../../Components/Layouts/ContentHeader";
import Card from "@material-ui/core/Card";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import IconButton from "@material-ui/core/IconButton";
import DocumentModal from "./DocumentModal";
import Message from "../../Components/Message";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from "@material-ui/icons/Add";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import { OrgContext } from "hooks/useOrganization"

import API from "../../Api/Api";
import AvatarComponent from "../../Components/AvatarComponent";
import { CardActionArea, CardContent, Divider } from "@material-ui/core";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    maxWidth: '352px',
    maxHeight: '352px',
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  parentGrid: {
    padding: "32px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      paddingTop: "20px",
    },
  },
  policyContainer: {
    width: "100%",
    height: "100%",
    padding: "0px",
  },

  addCardContainer: {
  },

  addCard: { 
    maxWidth: "352px", 
    maxHeight: "352px", 
    width: '100%', 
    height: '100%' 
  },

  addIcon: {
    height: "36px",
    width: "36px",
    background: "#18BFF6",
    color: "white",
    position: "relative",
  },
  addSvg: {
    position: "absolute",
    top: "6px",
    left: "6px",
  },
  addNewDocument: {
    color: "#687895",
    fontFamily: "Roboto",
    fontSize: "24px",
    lineHeight: "21px",
    fontWeight: "700",
    letterSpacing: "0.1px",
  },
  descriptionContainer: {
  },
  descriptionText: {
    color: "#687895",
    opacity: "0.6",
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: "400",
    letterSpacing: "0.1px",
  },
  cardContainer: {
    maxWidth: "352px",
    margin: "20px",
    marginTop: 0,
    [theme.breakpoints.down("xs")]: {
      maxWidth: "auto",
      margin: "20px",
      marginTop: 0,
    },
  },

  paper: { width: '100%', height: '100%', maxWidth: "352px", maxHeight: "352px" },

  mappedCard: {
    height: "64px",
    padding: "12px",
    paddingLeft: "20px",
    paddingBottom: "0px",
    marginRight: "0px",
    borderBottom: "1px solid #E4E4E5",
  },
  dateText: {
    color: "#687895",
    opacity: "0.6",
    fontFamily: "Roboto",
    fontSize: "12px",
    lineHeight: "21px",
    fontWeight: "400",
    letterSpacing: "0.1px",
  },
  IconButton: {
    float: "right",
    flex: 1,
    color: "#9798B0",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "transparent" },
  },
  titleContainer: {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  titleText: {
    maxHeight: "42px",
    overflow: 'hidden',
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflowY: "auto",
    color: "#687895",
    fontFamily: "Roboto",
    fontSize: "16px",
    lineHeight: "21px",
    fontWeight: "500",
    letterSpacing: "0.1px",
    alignSelf: "center",
    paddingTop: "16px",
  },

  buttonContainer: {
    padding: '8px',
  },

  previewButton: {
    color: "#9E9FB5",
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "500",
    letterSpacing: "1.25px",
    alignSelf: "center",
    fontSize: "14px",
    marginRight: "12px",
  },
  editButton: {
    color: "#18BFF6",
    fontFamily: "Roboto",
    lineHeight: "16px",
    fontWeight: "500",
    letterSpacing: "1.25px",
    alignSelf: "center",
    fontSize: "14px",
  },
  mappedGridContainer: {
    height: '100%',
    width: '100%',
    maxHeight: "352px",
    maxWidth: "352px",
  },
}));

function Documents(props) {
  const org = useContext(OrgContext);
  let api = new API();
  const [documents, setDocuments] = useState([]);
  const [documentModalOpen, setDocumentModalOpen] = useState(false);
  const [newDocumentMode, setNewDocumentMode] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);

  useEffect(() => {
    getDocuments();
  }, [org]);

  function getDocuments() {
    api
      .getPolicyAPI()
      .listPolicies()
      .then((e) => {
        setDocuments(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function handleDocumentDialogClose() {
    if (!isBlocking) {
      setDocumentModalOpen(false);
      setSelectedDocument(null);
      setNewDocument(null);
    } else {
      if (
        window.confirm(
          "Are you sure you want to discard changes to this document?"
        )
      ) {
        setDocumentModalOpen(false);
        setSelectedDocument(null);
        setNewDocument(null);
        setIsBlocking(false);
      }
    }
  }

  function closeDocumentDialog() {
    setDocumentModalOpen(false);
    setSelectedDocument(null);
    setNewDocument(null);
    setIsBlocking(false);
  }
  const classes = useStyles();

  function addDocument() {
    setIsNewDocument(true);
    setMode("Edit");
    setNewDocumentMode(true);
    setDocumentModalOpen(true);
  }

  function save(document) {
    if (isNewDocument) {
      setIsBlocking(false);
      let tempDocs = [...documents];
      api
        .getPolicyAPI()
        .createPolicy(document)
        .then((e) => {
          tempDocs.push(e.data);
          setNewDocument(e.data);
          setDocuments(tempDocs);
          setIsNewDocument(false);
          setSelectedDocument(e.data);
        })
        .catch((e) => {
          console.log(e);
        });
      setReturnStatus("Document Saved!", "info");
    } else {
      update(document);
    }
  }

  function saveAndContinue(document) {
    if (isNewDocument) {
      setIsBlocking(false);
      let tempDocs = [...documents];
      api
        .getPolicyAPI()
        .createPolicy(document)
        .then((e) => {
          tempDocs.push(e.data);
          setSelectedDocument(e.data);
          setDocuments(tempDocs);
        })
        .catch((e) => {
          console.log(e);
        });
      setNewDocument(null);
      closeDocumentDialog();
      setReturnStatus("Document Saved!", "info");
    } else {
      api
        .getPolicyAPI()
        .updatePolicy(document)
        .then((e) => {
          setSelectedDocument(e.data);
          getDocuments();

          setReturnStatus("Document Saved!", "info");
          closeDocumentDialog();
        })
        .catch((e) => {
          console.log(e);
        });
      closeDocumentDialog();
    }
  }

  function update(document) {
    setIsBlocking(false);
    if (!isNewDocument) {
      api
        .getPolicyAPI()
        .updatePolicy(document)
        .then((e) => {
          setSelectedDocument(e.data);
          getDocuments();
        })
        .catch((e) => {
          console.log(e);
        });
      setNewDocument(null);
      setReturnStatus("Document Updated!", "info");
    } else {
      setIsBlocking(false);
      let tempDocs = [...documents];
      api
        .getPolicyAPI()
        .createPolicy(document)
        .then((e) => {
          tempDocs.push(e.data);
          setSelectedDocument(e.data);
          setDocuments(tempDocs);
        })
        .catch((e) => {
          console.log(e);
        });
      setReturnStatus("Document Saved!", "info");
    }
  }

  const [isNewDocument, setIsNewDocument] = useState(true);
  const [newDocument, setNewDocument] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [mode, setMode] = useState("Preview");

  function handleChange(event) {
    setIsBlocking(true);
    setNewDocument({ ...newDocument, [event.target.name]: event.target.value });
  }

  const [open, setOpen] = React.useState(false);
  const [deleteWarningOpen, setDeleteWarningOpen] = React.useState(false);
  const [message, setMessage] = React.useState({
    message: "",
    status: "info",
  }); // error, warning, info, info
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function setReturnStatus(message, status) {
    setMessage({ message: message, status: status });
    setOpen(true);
  }

  function editDocument(document) {
    setIsNewDocument(false);
    setSelectedDocument(document);
    setDocumentModalOpen(true);
    setMode("Edit");
  }
  function previewDocument(document) {
    setSelectedDocument(document);
    setDocumentModalOpen(true);
    setMode("Preview");
  }
  function changeMode() {
    setMode("Edit");
  }

  function htmlToText(html) {
    var temp = document.createElement("div");
    temp.innerHTML = html;
    return temp.textContent; // Or return temp.innerText if you need to return only visible text. It's slower.
  }
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event, invite) => {
    setAnchorEl(event.currentTarget);
    setPolicyToDelete(invite);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  function setWarningClosed() {
    setDeleteWarningOpen(false);
  }

  const [policyToDelete, setPolicyToDelete] = useState(null);
  function handleDeleteOpen() {
    setDeleteWarningOpen(true);
  }
  function deleteWarnedDocument() {
    api
      .getPolicyAPI()
      .deletePolicy(policyToDelete.pk)
      .then((e) => {
        setDeleteWarningOpen(false);
        getDocuments();
        setDocumentModalOpen(false);
        setReturnStatus("Document Deleted!", "info");
        setNewDocument(null);
        handleDocumentDialogClose();
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function deleteDocument(doc) {
    setPolicyToDelete(doc);
    setIsBlocking(false);
    setDeleteWarningOpen(true);
  }

  return (
    <div className={classes.policyContainer}>
      <div>
        <Dialog
          open={deleteWarningOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete this Document?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this document? This action can not
              be reversed.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={setWarningClosed} color="primary">
              No
            </Button>
            <Button onClick={deleteWarnedDocument} color="primary" autoFocus>
              Yes, Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {documentModalOpen && (
        <DocumentModal
          documentModalOpen={documentModalOpen}
          TransitionComponent={Transition}
          handleDocumentDialogClose={handleDocumentDialogClose}
          handleModalClosed={handleDocumentDialogClose}
          save={save}
          update={update}
          saveAndContinue={saveAndContinue}
          setNewDocument={handleChange}
          newDocumentMode={newDocumentMode}
          document={selectedDocument}
          handleChange={handleChange}
          changeMode={changeMode}
          mode={mode}
          setIsBlocking={setIsBlocking}
          delete={deleteDocument}
          user={props.user.id}
          org={org}
        ></DocumentModal>
      )}

      <ContentHeader title="Documents" />

      <Grid container justify="flex-start" spacing={3} style={{padding: '24px'}}>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={() => {
              handleMenuClose();
              handleDeleteOpen();
            }}
          >
            Delete
          </MenuItem>
        </Menu>

        <Grid item container xs={12} md={4} lg={3} justify="center"> 
          <Card elevation={0} style={{maxWidth: '352px', maxHeight: '352px', height: '100%'}}>
            <CardActionArea onClick={addDocument} style={{width: '100%', height: '100%'}}>
            <CardContent style={{width: '100%', height: '100%'}}>
              <Grid container justify="center" alignItems="center" style={{height: '100%'}}>
                <Grid item>
                  <Grid container spacing={3}>
                  <Grid
                    container
                    item xs={12}
                    alignItems="center"
                    justify="center"
                  >
                    <div className={classes.addIcon}>
                      <AddIcon className={classes.addSvg}></AddIcon>
                    </div>
                  </Grid>
                  <Grid
                    container item xs={12}
                    alignItems="center"
                    justify="center"
                  >
                    <Typography className={classes.addNewDocument} style={{textAlign: 'center'}}>
                      Add New Document
                    </Typography>
                  </Grid>
                  <Grid
                    container item xs={12}
                    alignItems="center"
                    justify="center"
                    className={classes.descriptionContainer}
                  >
                    <Typography className={classes.descriptionText} style={{textAlign: 'center'}}>
                      Need another document? Click on this tile to add a new one.
                    </Typography>
                  </Grid>
                </Grid>
                </Grid>
              </Grid>
            </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        {documents.map((document, index) => {
          return (
            <Grid
              key={index}
              container
              item
              justify="center"
              xs={12}
              md={4}
              lg={3}
            >
              <Card elevation={0} style={{maxWidth: '352px', maxHeight: '352px'}}>
                <Grid
                  container
                  spacing={0}
                >
                  <Grid container item xs={12} style={{padding: '16px', paddingRight: '8px', borderBottom: "1px solid #EAEAEA", paddingBottom: '8px'}} justify="flex-start">
                    <Grid item container xs={10}>
                      <Grid item container xs={12} alignItems="center" justify="flex-start">
                        <Grid item>
                          <AvatarComponent
                            user={document.user}
                            mapped={document.user}
                          ></AvatarComponent>
                        </Grid>
                        <Grid item xs>
                          {document.last_updated && (
                            <Typography className={classes.dateText} style={{paddingLeft: '16px'}}>
                              Last Updated{" "}
                              {new Date(document.last_updated).toLocaleDateString()}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} container alignItems="center" justify="flex-end">
                      <IconButton
                        onClick={(event) => handleMenuClick(event, document)}
                        className={classes.IconButton}
                      >
                        <MoreHorizIcon></MoreHorizIcon>
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid container className={classes.titleContainer}>
                    <Grid container item xs={12} style={{}}>
                      <Typography className={classes.titleText}>
                        {document.title}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      style={{
                        height: "170px",
                      }}
                    >
                      <div
                        style={{
                          maxHeight: "fit-content",
                          overflowWrap: "anywhere",
                          overflowY: "auto",
                          height: "170px",
                          paddingTop: "12px",
                          overFlow: "hidden",
                        }}
                      >
                        {document.preview}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} justify="flex-end" alignItems="center" className={classes.buttonContainer}>
                    <Button
                      size="small"
                      variant="text"
                      className={classes.editButton}
                      onClick={() => editDocument(document)}
                    >
                      Edit
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Message
        open={open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="right"
        handleClose={handleClose}
      />
    </div>
  );
}

export default Documents;
