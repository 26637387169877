import React, { useEffect, useState } from "react";
import API from "../../Api/Api";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import HazardDatasetTable from "../Hazards/HazardDatasetTable";
import Grid from "@material-ui/core/Grid";

export default function ProcessDatasetTable(props) {
  function getAPI() {
    return new API().getProcessDatasetAPI();
  }

  const [processDataset, setProcessDataset] = useState([]);
  const [processSelected, setProcessSelected] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  function getDataset() {
    getAPI().listDataset().then(response => {
      setProcessDataset(response.data);
      setIsLoading(false);
    })
  }

  useEffect(() => {
    getDataset();
  }, []);

  const processColumns = [
    { title: "Name", field: "name" },
  ];

  const [selectedRow, setSelectedRow] = useState(null);

  const [processHazards, setProcessHazards] = useState([]);
  useEffect(() => {
    if (selectedRow) {
      let processSelected = processDataset.find((process) => {
        return process.pk === selectedRow;
      });

      setProcessSelected(processSelected);

      if (processSelected) {
        setProcessHazards(processSelected.hazards);
      }
    }
  }, [selectedRow]);

  const [hazardsSelected, setHazardsSelected] = useState([]);

  function cancel() {
    if (props.setCFIADatabaseOpen) {
      props.setCFIADatabaseOpen(false);
    }
  }

  return (
    <div style={{ maxWidth: "100%" }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MaterialTable components={{ Container: props => <Paper {...props} elevation={0} /> }}
            title="CFIA Process Database"
            columns={processColumns}
            data={processDataset}
            isLoading={isLoading}

            onRowClick={(evt, selectedRow) => {
              setSelectedRow(selectedRow.pk)
            }}
            options={{
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.pk) ? '#EEE' : '#FFF'
              })
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <HazardDatasetTable hazards={processHazards} setHazardsSelected={setHazardsSelected} handleCFIADatabaseClosed={cancel} />
        </Grid>
        {props.selectProcess &&
          <Grid item xs={12}>
            <div style={{float: "right"}}>
            <Button variant='contained' color='secondary' style={{ margin: '8px' }} onClick={cancel}>
              Cancel
            </Button> 
            <Button onClick={() => {
              props.selectProcess(processSelected, hazardsSelected)
            }} variant='contained' color='primary'>
              Select Process and Hazards
          </Button>
          
            </div>
          </Grid>
        }
      </Grid>
    </div>
  )
}
