import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import API from "../../Api/Api";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import AddFCustomerAddressesDialog from "./AddFCustomerAddressesDialog";
import ContentHeader from "Components/Layouts/ContentHeader";
import Traceability from "../Traceability";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import { Divider, Grid, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { StylesContext } from "../../App";
import Message from "Components/Message";
import TraceabilityUtils from "../TraceabilityUtils";
import Search from "Components/Search";
import useDesktop from "../../hooks/useDesktop";

const EMPTY_MESSAGE = {
    open: false,
    message: '',
    status: 'info',
};

const useStyles = makeStyles((theme) => ({
    addButton: {
        margin: theme.spacing(1),
        top: theme.spacing(2),
    },
}));

export default function FCustomerAddresses(props) {
    const classes = React.useContext(StylesContext);
    const onDesktop = useDesktop();
    const classContext = React.useContext(StylesContext);
    const [loading, setLoading] = useState(true);
    const [customersAddresses, setCustomersAddresses] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [customerAddressToEdit, setCustomerAddressToEdit] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [isEditable, setIsEditable] = useState(true);
    const [itemIdToDelete, setItemIdToDelete] = useState(null);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const api = new Traceability().getFCustomerAddressesAPI();
    const [message, setMessage] = useState({ ...EMPTY_MESSAGE });
    const utils = new TraceabilityUtils();

    useEffect(() => {
        fetchCustomersAddresses();
    }, [page]);

    const fetchCustomersAddresses = async () => {
        try {
            const response = await api.listFCustomerAddresses(props.customer.id);
            setCustomersAddresses(response.data);
            setSearchResults(response.data);
            // setCustomersAddresses(response.data.results);
            // setTotalPages(Math.ceil(response.data.count / response.data.results.length));
            // setTotalPages(Math.ceil(100 / 10));
            setLoading(false);
        } catch (error) {
            console.error("Error fetching FCustomerAddressess:", error);
            setLoading(false);
        }
    };

    const handleOpenDialog = (rowData, editable, name) => {
        if (!!rowData && !editable) {
            setCustomerAddressToEdit(rowData);
            setIsEditable(false)
        } else if (!!rowData && editable) {
            setCustomerAddressToEdit(rowData);
            setIsEditable(true)
        } else {
            setCustomerAddressToEdit(null);
            setIsEditable(true)
        }
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setCustomerAddressToEdit(null);
    };

    const addCustomerAddress = async (payload) => {
        try {
            handleCloseDialog();
            payload.customer = props.customer.id;
            const response = await api.createFCustomerAddresses(payload);
            fetchCustomersAddresses();
            setMessage({ open: true, message: 'Customer Address Created Successfully', status: 'success' });
        } catch (error) {
            console.error("Error adding customer:", error);
            setMessage({ open: true, message: 'Server Error: ' + utils.formatError(error), status: 'error' });
        }
    };

    const editCustomerAddress = async (payload) => {
        try {
            setCustomerAddressToEdit(payload);
            await api.updateFCustomerAddresses(payload);
            fetchCustomersAddresses();
            handleCloseDialog();
            setMessage({ open: true, message: 'Customer Address Updated Successfully', status: 'success' });
        } catch (error) {
            console.error("Error editing customer:", error);
            setMessage({ open: true, message: 'Server Error: ' + utils.formatError(error), status: 'error' });
        }
    };

    const handleDeleteConfirmationOpen = (pk) => {
        setItemIdToDelete(pk);
        setDeleteConfirmationOpen(true);
    };

    const handleDeleteConfirmationClose = () => {
        setItemIdToDelete(null);
        setDeleteConfirmationOpen(false);
    };

    const handleDeleteConfirmation = () => {
        softDeleteCustomerAddress();
        setDeleteConfirmationOpen(false);
    };

    const softDeleteCustomerAddress = async () => {
        try {
            await api.deleteFCustomerAddresses(itemIdToDelete);
            fetchCustomersAddresses();
            setMessage({ open: true, message: 'Customer Address Deleted Successfully', status: 'success' });
        } catch (error) {
            console.error("Error soft deleting customer:", error);
            setMessage({ open: true, message: 'Server Error: ' + utils.formatError(error), status: 'error' });
        }
    };

    return (
        <Paper elevation={0} className={classContext.customerDetailsFormField} >
            <Grid item xs={12} style={{ paddingInline: "12px" }} container alignItems="center">
                <Grid item xs={12} sm={9}>
                    <Typography variant="h5" noWrap className={classContext.generalFormSubsectionHeader}>
                        Customer Addresses
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginBottom: '14px' }} >
                    <Search fullWidth searchData={customersAddresses} setSearchResults={setSearchResults} searchKeys={["facility_name", "email", "phone_number", "address"]} />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>

            {loading ? (
                <Grid
                    container
                    direction="column"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item style={{ marginTop: "250px" }}>
                        <Typography>
                            <CircularProgress />
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={3} style={{ marginTop: '12px', paddingInline: "12px" }}>
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <div style={{ width: '100%', overflowX: 'hidden' }}>
                                <div style={{ display: "flex", justifyContent: "flex-end", paddingRight: "10px", marginBottom: "20px" }}>
                                    <Button size="small" color="primary" variant="outlined" aria-label="add"
                                        className={classes.tabTableFreeAction} onClick={() => handleOpenDialog(null, true, "addClick")}>
                                        Add Customer Address
                                    </Button>
                                </div>
                                <MaterialTable
                                    title=""
                                    columns={[
                                        // { title: "Customer", field: "customer" },
                                        { title: "Facility Name", field: "facility_name" },
                                        { title: "Email", field: "email" },
                                        { title: "Phone Number", field: "phone_number" },
                                        { title: "Address", field: "address" },
                                    ]}
                                    data={searchResults}
                                    onRowClick={(event, rowData) => handleOpenDialog(rowData, false, 'onRowClick')}
                                    actions={[
                                        {
                                            icon: () => (
                                                <Button size="small" color="primary" variant="outlined" aria-label="edit">
                                                    Edit
                                                </Button>
                                            ),
                                            tooltip: "Edit Customer Address",
                                            onClick: (event, rowData) => handleOpenDialog(rowData, true, "onedit"),
                                        },
                                        {
                                            icon: () => (
                                                <Button size="small" color="primary" variant="outlined" aria-label="delete">
                                                    {'Delete'}
                                                </Button>
                                            ),
                                            tooltip: "Delete Customer Address",
                                            onClick: (event, rowData) => handleDeleteConfirmationOpen(rowData.pk),
                                        },
                                    ]}
                                    // components={{
                                    //     Toolbar: (props) => (
                                    //         <div style={{ display: "flex", justifyContent: "flex-end", paddingRight: "10px", marginBottom: "20px" }}>
                                    //             <Button size="small" color="primary" variant="outlined" aria-label="add"
                                    //                 className={classes.tabTableFreeAction} onClick={() => handleOpenDialog(null, true, "addClick")}>
                                    //                 Add Customer Address
                                    //             </Button>
                                    //         </div>
                                    //     ),
                                    // }}
                                    options={{
                                        pageSize: 10,
                                        pagination: true,
                                        paginationType: "stepped",
                                        pageSizeOptions: [5, 10, 20],
                                        paginationPosition: "bottom",
                                        actionsColumnIndex: -1,
                                        search: false
                                    }}
                                    onChangePage={(page) => setPage(page)}
                                />
                                <AddFCustomerAddressesDialog
                                    open={openDialog}
                                    handleClose={handleCloseDialog}
                                    handleSave={customerAddressToEdit ? editCustomerAddress : addCustomerAddress}
                                    customerAddressToEdit={customerAddressToEdit}
                                    editable={isEditable}
                                />
                                <DeleteConfirmationDialog
                                    title="Delete Customer Address"
                                    body="Are you sure you want to delete this address?"
                                    open={deleteConfirmationOpen}
                                    handleClose={handleDeleteConfirmationClose}
                                    handleDelete={handleDeleteConfirmation}
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            )}
            <Message
                open={message.open}
                message={message.message}
                severity={message.status}
                vertical="bottom"
                horizontal="left"
                handleClose={() => { setMessage({ ...message, open: false }) }}
            />
        </Paper>
    );
}
