import React from "react"

import {
  IconButton,
  CardActionArea,
  Card,
  CardContent,
} from "@material-ui/core";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CloseIcon from '@material-ui/icons/Close';
import sanitizeHtml from "sanitize-html";

function Standard(props) {

  function standardClicked(event) {
    props.setStandardSelected(props.node);
  }

  const createMarkup = (html) => {
    return {
      __html: sanitizeHtml(html) // prevent XSS attacks
    }
  }

  function getCardContents() {
    return (
      <div>
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              {props.node.standard && props.node.standard.requirement &&
                <div
                  className="preview"
                  dangerouslySetInnerHTML={createMarkup(props.node.standard.requirement)}>
                </div>
              }
            </Grid>
          </Grid>
        </CardContent>
      </div>
    )
  }

  function getStandardContent() {
    return (
      <Grid container style={{ marginTop: '6px', marginBottom: '6px', padding: '16px' }}>
        {props.node &&
          <Grid item xs={12} styling={{ width: '100%' }}>
            <Grid container>
              <Grid container item xs={9}>
                <Grid item xs={12} style={{ padding: '16px' }}>
                  <Typography variant="h6">
                    {(props.node.standard && props.node.standard.title) || "Title"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container alignItems="center" justify="flex-end" xs={3}>
                {!props.readOnly &&
                  <IconButton aria-label="Delete" size="small" style={{ marginRight: '12px' }}
                    onClick={(event) => {
                      event.stopPropagation();
                      props.deleteStandard(props.node);
                    }}>
                    <CloseIcon />
                  </IconButton>
                }
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Card elevation={0} style={{ width: '100%' }}>
                  {getCardContents()}
                </Card>
              </Grid>
            </Grid>
          </Grid>
        }
      </Grid>

    )
  }

  return (
    <>{props.readOnly ? getStandardContent() : <CardActionArea onClick={standardClicked}>{getStandardContent()}</CardActionArea>}</>
  )
}

export default Standard;
