import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Traceability from "../Traceability";
import Paper from "@material-ui/core/Paper";
import Message from "../../Components/Message";
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import TraceabilityUtils from "../TraceabilityUtils";
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from '@material-ui/core/Checkbox';
import withStyles from "@material-ui/core/styles/withStyles";
import { StylesContext } from "../../App";

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '0px solid white',
        backgroundColor: 'rgba(0, 0, 0, 0.029)',
      },
    },
  },
})(TextField);

//todo this could be slated for removal some day.
export default function InventoryLocationForm(props) {
  const classes = React.useContext(StylesContext);
  const utils = new TraceabilityUtils();

  const [inventoryLocation, setInventoryLocation] = useState(props.inventoryLocation ? props.inventoryLocation : {});
  const [message, setMessage] = useState(utils.emptyMessage());
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  /*useEffect(() => {
    if(props.toSubmit === false) {
      handleSubmit(false);
      props.setToSubmit(undefined);
    }
    else if(props.toSubmit === true) {
      handleSubmit(true);
      props.setToSubmit(undefined);
    }
  }, [props.toSubmit])*/

  function validateForm() {
    setSubmitAttempted(true);

    if (!inventoryLocation.name) {
      setMessage({ open: true, message: 'Please enter the Location Name.', status: 'error' });
      return false;
    }

    return true;
  }

  function handleSubmit(saveAndContinue) {
    setSubmitting(true);
    if (!validateForm()) {
      setSubmitting(false);
      return;
    }
    let locationObject = { ...inventoryLocation }

    if (props.setShouldClose) {
      props.setShouldClose(true);
    }

    let organization = JSON.parse(localStorage.getItem("organization"));
    locationObject.organization = organization.pk;
    locationObject.traceability = props.activeTraceability.pk;
    const api = new Traceability().getInventoryLocationAPI();

    if (locationObject.pk) {
      api.updateInventoryLocation(locationObject).then(response => {
        if (props.setShouldClose) {
          props.setShouldClose(true);
        }
        setInventoryLocation(response.data);
        setSubmitAttempted(false);
        if (props.onSave) {
          props.onSave(response, { ...response.data, type: 'Location' }, saveAndContinue);
        }

        if (saveAndContinue) {
          handleSaveAndContinue();
        }
        setMessage({ open: true, message: 'Saved Successfully', status: 'success' });
        setSubmitting(false);
      }).catch(error => {
        setSubmitting(false);
        setMessage({ open: true, message: 'Server Error: ' + utils.formatError(error), status: 'error' });
        //console.log('Error Response: ' + JSON.stringify(error));
      });
    }
    else {
      api.createInventoryLocation(locationObject).then(response => {
        if (props.setShouldClose) {
          props.setShouldClose(true);
        }
        setInventoryLocation(response.data);
        setSubmitAttempted(false);
        if (props.onSave) {
          props.onSave(response, { ...response.data, type: 'Location' }, saveAndContinue);
        }

        if (saveAndContinue) {
          handleSaveAndContinue();
        }
        setMessage({ open: true, message: 'Saved Successfully', status: 'success' });
        setSubmitting(false);
      }).catch(error => {
        setSubmitting(false);
        setMessage({ open: true, message: 'Server Error: ' + utils.formatError(error), status: 'error' });
        //console.log('Error Response: ' + JSON.stringify(error));
      });
    }
  }

  function handleSaveAndContinue() {

    if (props.cancel) {
      props.cancel();
    }
  }

  function handleTextFieldChange(event) {
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }
    const property = event.target.name;
    const value = event.target.value;

    setInventoryLocation({ ...inventoryLocation, [property]: value });
  }

  function handleChecked(event) {
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }
    const property = event.target.name;
    const value = event.target.checked;

    setInventoryLocation({ ...inventoryLocation, [property]: value });
  }

  function cancel() {
    setInventoryLocation(props.inventoryLocation ? props.inventoryLocation : {});

    if (props.setShouldClose) {
      props.setShouldClose(false);
    }
  }

  function handleDelete() {
    if (!inventoryLocation || !inventoryLocation.pk) {
      props.closeModal ? props.closeModal() : cancel();
    }
    const invLocAPI = new Traceability().getInventoryLocationAPI();

    invLocAPI.softDeleteInventoryLocation(inventoryLocation).then(response => {

      if (props.onDelete) {
        props.onDelete(response, { pk: inventoryLocation.pk, type: 'Location', soft_delete: true });
      }
      setMessage({ open: true, message: 'Deleted Successfully', status: 'success' });
      props.closeModal();
    }).catch(error => {
      setMessage({ open: true, message: 'Server Error: ' + utils.formatError(error), status: 'error' });
      //console.log('Error Response: ' + JSON.stringify(error));
    });
  }

  return (
    <>
      <Grid container spacing={3} className={classes.generalContainerGridHead}>
        <Grid item xs={11}>
          <Typography variant="h6" noWrap className={classes.generalFormHeaderTypography}>
            Edit Inventory Location
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton style={{ float: 'right' }} onClick={props.closeModal ? props.closeModal : cancel}>
            <CancelIcon style={{ float: 'right' }} />
          </IconButton>
        </Grid>
      </Grid>


      <Grid container spacing={3} className={classes.generalContainerGridBody} style={{ marginTop: '16px' }}>
        <Grid item xs={12}>
          <Paper elevation={0} square className={classes.generalFormPaperContainerApprovedInfo} style={{ marginBottom: '16px' }}>
            <Grid container spacing={3} className={classes.generalContainerGrid}>
              <Grid item container alignItems='center' justify='flex-end' xs={12} style={{ marginBottom: '0px' }}>
                <Checkbox
                  checked={inventoryLocation.is_production_location}
                  onChange={handleChecked}
                  name="is_production_location"
                  color="primary"
                />
                <Typography>
                  Production Location?
                </Typography>
              </Grid>

              <Grid item xs={12} style={{ marginTop: '0px' }}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Name
                </Typography>
                <CssTextField
                  fullWidth
                  className={classes.generalFormTextField}
                  name="name"
                  variant="outlined"
                  value={inventoryLocation.name || ""}
                  onChange={handleTextFieldChange}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Description
                </Typography>
                <CssTextField
                  name="description"
                  variant="outlined"
                  className={classes.generalFormTextField}
                  fullWidth
                  multiline
                  rows={3}
                  value={inventoryLocation.description || null}
                  onChange={handleTextFieldChange}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* This Grid serves as the footer element. */}
      <Paper elevation={0} className={classes.generalFormPaperStickyFooter}>
        <Grid container spacing={3} className={classes.generalContainerGridFoot}>
          <Grid item container xs={12} alignItems="center" justify="flex-end" className={classes.generalContainerGridFoot}>
            {inventoryLocation.pk &&
              <Button
                variant="outlined"
                startIcon={<DeleteIcon />}
                style={{ marginLeft: "8px" }}
                onClick={() => { handleDelete() }}
                disabled={submitting}
              >
                Delete
              </Button>
            }
            <Button
              //variant="outlined"
              color="secondary"
              style={{ marginLeft: "8px" }}
              onClick={props.closeModal ? props.closeModal : cancel}
              disabled={submitting}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="primary"
              style={{ marginLeft: "8px" }}
              onClick={() => { handleSubmit(false) }}
              disabled={submitting}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "8px" }}
              onClick={() => { handleSubmit(true) }}
              disabled={submitting}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Message
        open={message.open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="left"
        handleClose={() => { setMessage({ ...message, open: false }) }}
      />
    </>
  )
}

InventoryLocationForm.propTypes = {
  inventoryLocation: PropTypes.object,
  handleSubmit: PropTypes.func,
  user: PropTypes.object.isRequired,
  activeTraceability: PropTypes.object.isRequired,
  setShouldClose: PropTypes.func,
  onSave: PropTypes.func,
  closeModal: PropTypes.func,
  cancel: PropTypes.func,
  setFormSubmitFunction: PropTypes.func,
}