class InventoryTransferAPI {
  constructor(api, version) {
    this.api = api;
    this.version = version;
  }
  
  listInventoryTransfer(inventory_transfer_pk) {
    if (typeof inventory_transfer_pk === 'number') {
      return this.api.get(`${this.version}/inventory_transfers/${inventory_transfer_pk}/`);
    }
  
      return this.api.get(`${this.version}/inventory_transfers/`)
  }
  
  createInventoryTransfer(payload) {
    return this.api.post(`${this.version}/inventory_transfers/`, payload);
  }
  
  updateInventoryTransfer(payload) {
    return this.api.put(`${this.version}/inventory_transfers/${payload.pk}/`, payload);
  }
  
  deleteInventoryTransfer(pk) {
    return this.api.delete(`${this.version}/inventory_transfers/${pk}/`);
  }
  
}
  
export default InventoryTransferAPI;