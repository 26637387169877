class InternalLotCodeAPI {
    constructor(api, version) {
      this.api = api;
      this.version = version;
    }
  
    listInternalLotCodes(container_pk) {
      if (typeof container_pk === 'number') {
        return this.api.get(`${this.version}/internal_lot_codes/${container_pk}/`);
      }
  
      return this.api.get(`${this.version}/internal_lot_codes/`)
    }

    listInternalLotCodesDisplay(container_pk) {
      if (typeof container_pk === 'number') {
        return this.api.get(`${this.version}/internal_lot_codes_display/${container_pk}/`);
      }
  
      return this.api.get(`${this.version}/internal_lot_codes_display/`)
    }

    listLotCodesForRecall(){
      return this.api.get(`${this.version}/internal_lot_codes_recall/`)
    }

    listInternalLotCodesWithSku(container_pk) {
      if (typeof container_pk === 'number') {
        return this.api.get(`${this.version}/internal_lot_codes/list_with_skus/${container_pk}/`);
      }
  
      return this.api.get(`${this.version}/internal_lot_codes/list_with_skus/`)
    }
  
    createInternalLotCode(payload) {
      return this.api.post(`${this.version}/internal_lot_codes/`, payload);
    }
  
    updateInternalLotCode(payload) {
      return this.api.put(`${this.version}/internal_lot_codes/${payload.pk}/`, payload);
    }
  
    deleteInternalLotCode(pk) {
      return this.api.delete(`${this.version}/internal_lot_codes/${pk}/`);
    }
  
  }
  
  export default InternalLotCodeAPI;