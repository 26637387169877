import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import API from "../../Api/Api";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ImageCard from "Components/ImageCard/ImageCard";

//import Button from "@material-ui/core/Button";
//import SignatureCanvas from "react-signature-canvas";
//import Camera from "TakePictureHooks/Camera";
//import CameraAltIcon from "@material-ui/icons/CameraAlt";
//import { DropzoneArea } from "material-ui-dropzone";
//import EditImageModal from "TakePictureHooks/EditImageModal";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import ReactSignatureCreator from "Components/ReactSignatureCreator";

const useStyles = makeStyles({
  gridcontainer: {
    padding: "40px",
    paddingTop: "64px",
    paddingBottom: "64px",
    marginBottom: "100px",
  },
  dropzone: {
    paddingTop: "12px",
    paddingBottom: "12px",
    width: "100%",
  },
  nestedcontainer: {
    padding: 0,
    paddingTop: 20,
  },
  griditem: {
    marginBottom: "18px",
  },
  outlinedbutton: {
    color: "#18bff6",
  },
  textfieldbackground: {
    background: "#fafafc",
  },
  signaturebutton: {
    paddingTop: 6,
  },
  canvaspaper: {
    width: "100%",
    height: 200,
  },
});

function Step3(props) {
  let api = new API();
  const classes = useStyles();
  const [signatureMade, setSignatureMade] = useState(false);
  //const [cameraStart, setCameraStart] = useState(true);
  //const [cameraOpen, setCameraOpen] = useState(false);
  //const [signature, setSignature] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editImage, setEditImage] = useState(null);
  //const [cameraImages, setCameraImages] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState(props.uploadedFiles);
  const [uploadedCameraImages, setUploadedCameraImages] = useState(
    props.uploadedCameraImages
  );
  const [files, setFiles] = useState([]);

  const onDesktop = useMediaQuery('(min-width:600px)');
 
  useEffect(() => {
    if (props.open) {
      clearSignature();
      getAllUsers();
    }
  }, [props.open]);

  function clearSignature() {
    if (props.setFieldError) {
      props.setFieldError(true);
    }
  }

  function checkSignature(sigPad) {
    if (props.checkSignature) {
      props.checkSignature(sigPad);
    }

    if (sigPad && sigPad.current && !sigPad.current.isEmpty()) {
      setSignatureMade(true);
      props.setFieldError(false);
    }
    else {
      setSignatureMade(false);
      if (props.setFieldError) {
        props.setFieldError(true);
      }
    }


    let field0 = document.getElementById("description_of_corrective_actions").value;
    let field1 = selectedUser;
    let field2 = document.getElementById("due_date").value;

    if (!field0 || !field1 || !field2) {
      if (props.setFieldError) {
        props.setFieldError(true);
      }
    } else {
      if (props.setFieldError) {
        props.setFieldError(false);
      }
    }
  }

  function validSignatureCheck(validCheck) {
    setSignatureMade(validCheck);

    if (!validCheck && props.setFieldError) {
      props.setFieldError(true); 
    }
  }

  const [allUsers, setAllUsers] = useState([]);

  // TODO Change this to other endpoint, and test date occured
  function getAllUsers() {
    api
      .getOrganizationAPI()
      .getTeamMembers()
      .then((response) => {
        setAllUsers(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function handleChange(event) {
    let field1;
    if (event.target.name === "person_responsible_for_completion") {
      field1 = event.target.value;
      setSelectedUser(event.target.value);
    }
    let field0 = document.getElementById("description_of_corrective_actions")
      .value;
    let field2 = document.getElementById("due_date").value;
    if (!field0 || !field2 || !signatureMade) {
      if (props.setFieldError) {
        props.setFieldError(true);
      }
    } else {
      if (props.setFieldError) {
        props.setFieldError(false);
      }
    }
    if (props.handleChange) {
      props.handleChange(event);
    }
  }
  const [selectedUser, setSelectedUser] = useState("Select User");

  //function deleteFromCameraImages(what, newCameraImages) {
  //  let index = 0;
  //  newCameraImages.forEach((file) => {
  //    if (file.image === what) {
  //      newCameraImages.splice(index, 1);
  //    }
  //    index++;
  //  });
  //  setCameraImages(newCameraImages);
  //  if (props.setCameraImages) {
  //    props.setCameraImages(newCameraImages);
  //  }
  //  setReturnStatus("Image Deleted", "info");
  //}

  //let imagesApi = new API().getDeviationImagesAPI();

  //useEffect(() => {
  //  if (props.open) {
  //    if (props.handleClick) {
  //      props.setFieldError(false);
  //    }
  //  }
  //}, [props.open]);

  useEffect(() => {
    if (props.uploadedFiles) {
      setUploadedFiles(props.uploadedFiles);
    }
  }, [props.uploadedFiles]);

  useEffect(() => {
    if (props.uploadedCameraImages) {
      setUploadedCameraImages(props.uploadedCameraImages);
    }
  }, [props.uploadedCameraImages]);

  //function openCamera() {
  //  setCameraOpen(true);
  //}
  //function closeCamera() {
  //  setCameraOpen(false);
  //}

  //function saveImageFromCamera(image) {
  //  openEditModal(image);
  //  setCameraOpen(false);
  //}

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState({
    message: "",
    status: "info",
  }); // error, warning, info, info
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function setReturnStatus(message, status) {
    setMessage({ message: message, status: status });
    setOpen(true);
  }
  function openEditModal(image) {
    setEditImage(image);
    setEditModalOpen(true);
  }

  //function handleEditModalClosed() {
  //  setEditModalOpen(false);
  //}

  //function dropFile(files) {
  //  if (props.setDroppedFiles) {
  //    props.setDroppedFiles(files);
  //  }
  //  if (files.length > 0) {
  //    setFiles(files);
  //  }
  //}

  //function saveImage(newImage) {
  //  let myFiles = [...cameraImages];
  //  newImage.pk = Math.random();
  //  myFiles.push(newImage);
  //  setCameraImages(myFiles);
  //  if (props.setCameraImages) {
  //    props.setCameraImages(myFiles);
  //  }
  //  setEditModalOpen(false);
  //  setCameraOpen(false);
  //}

  //const handlePreviewIcon = (fileObject, classes) => {
  //  const { type } = fileObject.file;
  //  const iconProps = {
  //    // className : classes.image,
  //  };
  //  if (type.startsWith("image/"))
  //    return (
  //      <ImageCard
  //        key={fileObject.data}
  //        url={fileObject.data}
  //        name={fileObject.name}
  //        // pk={file.pk}
  //        allowDelete={false}
  //        //api={imagesApi}
  //        api={null}
  //        cardStyle={{ width: "200px", height: "200px" }}
  //      />
  //    );
  //};

  return (
    <Paper elevation={0} style={{ marginTop: onDesktop ? 32 : 0, padding: 32, maxWidth: '900px', overflow: 'hidden' }}>
      <Grid container spacing={3}>
        <Grid item container xs={12} alignItems="center">
          <Typography variant="h6">Corrective Action Plan</Typography>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          {" "}
          <Typography style={{ color: "red", paddingRight: "4px" }}>
            *
          </Typography>
          <Typography>Description of Corrective Actions</Typography>
        </Grid>
        <Grid item container xs={12}>
          <TextField
            disabled={props.readOnly}
            id="description_of_corrective_actions"
            name="description_of_corrective_actions"
            placeholder="Describe the corrective actions that are to be taken due to this deviation"
            className={classes.textfieldbackground}
            variant="outlined"
            multiline
            rows={5}
            defaultValue={
              props.deviationForm
                ? props.deviationForm.description_of_corrective_actions
                : ""
            }
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item container md={6} xs={12}>
          <Typography
            style={{ color: "red", paddingRight: "4px", paddingBottom: "16px" }}
          >
            *
          </Typography>
          <Typography>Person Responsible for Completion</Typography>
          {allUsers && (
            <FormControl variant="standard" fullWidth>
              <Select
                disabled={props.readOnly}
                name="person_responsible_for_completion"
                id="person_responsible_for_completion"
                value={
                  props.deviationForm.person_responsible_for_completion
                    ? props.deviationForm.person_responsible_for_completion
                    : selectedUser
                }
                className={classes.textfieldbackground}
                onChange={handleChange}
              >
                <MenuItem aria-label="None" value="Select User">
                  Select User
                </MenuItem>
                {allUsers.map((monitor) => {
                  if (monitor.first_name && monitor.last_name) {
                    return (
                      <MenuItem
                        value={monitor.pk}
                        key={monitor.username}
                      >
                        {monitor.first_name} {monitor.last_name}
                      </MenuItem>
                    );
                  } else {
                    return (
                      <MenuItem
                        value={monitor.pk}
                        key={monitor.username}
                      >
                        {monitor.username}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid item container md={6} xs={12}>
          <Typography
            style={{ color: "red", paddingRight: "4px", paddingBottom: "16px" }}
          >
            *
          </Typography>
          <Typography>Due Date</Typography>
          <TextField
            disabled={props.readOnly}
            id="due_date"
            name="due_date"
            className={classes.textfieldbackground}
            type="date"
            defaultValue={
              props.deviationForm ? props.deviationForm.due_date : ""
            }
            variant="outlined"
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        {/* <EditImageModal
          closePreview={handleEditModalClosed}
          open={editModalOpen}
          image={editImage}
          setStatus={setReturnStatus}
          handleModalClosed={handleEditModalClosed}
          save={saveImage}
          //  delete={deleteImage}
        />
        {cameraOpen && (
          <Camera
            save={saveImageFromCamera}
            //  handleClose={handleCameraClose}
            close={closeCamera}
            open={cameraOpen}
            cameraOpen={cameraStart}
          ></Camera>
        )} */}
        {/*!props.readOnly && (
          <Grid item container xs={12} alignItems="center">
            <Typography variant="h6">Images</Typography>
          </Grid>
        )*/}
        {/*!props.readOnly && (
          <Grid item container xs={12} alignItems="center">
            <Divider style={{ width: "100%" }}></Divider>
          </Grid>
        )*/}
        {/*<Grid item container xs={12}>
          {!props.readOnly && (
            <DropzoneArea
              className={classes.dropzone}
              filesLimit={10}
              showPreviews={true}
              showPreviewsInDropzone={false}
              getPreviewIcon={handlePreviewIcon}
              onChange={(files) => dropFile(files)}
              acceptedFiles={["image/*"]}
            />
          )}

          {cameraImages && !props.readOnly && (
            <Grid
              item
              container
              xs={12}
              className={classes.cameraimagescontainer}
              spacing={8}
            >
              {cameraImages.map((file) => {
                return (
                  <Grid item key={file.image}>
                    <ImageCard
                      key={file.image}
                      url={file.image}
                      name={file.name}
                      pk={file.pk}
                      allowDelete={true}
                      deleted={() =>
                        deleteFromCameraImages(file.image, cameraImages)
                      }
                      api={imagesApi}
                      cardStyle={{ width: "200px", height: "200px" }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Grid>
        {/* <Grid container item xs={12} justify="flex-end">
          {!props.readOnly && (
            <Button
              className={classes.button}
              onClick={() => openCamera()}
              variant="contained"
              color="primary"
              startIcon={<CameraAltIcon />}
            >
              Take Picture
            </Button>
          )}
        </Grid> */}
        <Grid item container xs={12} alignItems="center">
          <Typography variant="h6">Signature</Typography>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Divider style={{ width: "100%" }}></Divider>
        </Grid>
        {!props.readOnly && (
        <Grid item container xs={12}>
          <ReactSignatureCreator 
            checkSignature={checkSignature} validSignatureCheck={validSignatureCheck}
          />
        </Grid>
        )}
        {/*!props.readOnly && (
          <Grid item container xs={12} className={classes.signaturebutton}>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              size="large"
              //onClick={clearSignature}
            >
              Reset Signature
            </Button>
          </Grid>
        )*/}
        {props.readOnly && (
          <div style={{ marginTop: "8px" }}>
            <img src={props.signature} />
          </div>
        )}
      </Grid>
    </Paper>
  );
}

export default Step3;
