import React, { useState, useEffect, useContext } from "react";
import { Route, Switch, useHistory, Redirect, useLocation } from "react-router-dom";

import { UserContext } from "hooks/useAuthedUser";
import { ApiContext } from "hooks/useAPI";
import { OrgContext } from "hooks/useOrganization";
import { OrgPermissionContext } from "hooks/usePermissions";

import GeneralApp404 from "Main/GeneralApp404";
import Menus from "Main/Menus";

import { makeStyles } from "@material-ui/core/styles";
import AdminRouter from "Accounts/AdminTools/AdminRouter";

import Profile from "Accounts/User/Profile";
import HaccpPlans from "HACCP/HaccpPlans/HaccpPlans";
import Images from "Components/Images/Images";
import TraceabilityRouter from "Traceability/TraceabilityRouter";
import Plan from "Plans/PlanManagement/Plan.js";
import Documents from "Plans/PlanManagement/Documents.js";

import MyTasks from "Monitoring/MyTasks/MyTasks";
import OnboardingDashboard from "Dashboard/Onboarding/OnboardingDashboard";
import Editor from "Plans/PlanBuilder/Editor";
import OrgInvite from "Accounts/Organization/OrganizationInvite";

import MonitoringDashboard from "Monitoring/MonitoringDashboard";
import EventRecordForm from "Monitoring/Events/EventRecordForm";
import DeviationForm from "Monitoring/Deviations/DeviationForm";
import VerificationForm from "Monitoring/Verification/VerificationForm";

import Settings from "Accounts/User/Settings";
import MonitoringTasks from "Monitoring/MyTasks/MonitoringTasks/MonitoringTasks";
import AcceptTerms from "Accounts/Legal/AcceptTerms";
import Records from "Monitoring/Records/Records";
import Audits from "Monitoring/Records/Audit/Audits";
import Logs from "Monitoring/Logs/Logs";

import ApprovedInformationTable from "Traceability/ApprovedInformation/ApprovedInformationTable";

import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import useClientUpdater from "hooks/useClientUpdater";

import API from "Api/Api";
import Traceability from 'Traceability/Traceability';

import * as FullStory from '@fullstory/browser';

FullStory.init({ orgId: 'YJT08', debug: false });

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
  },

  content: {
    flexGrow: 1,
    height: "100%",
    width: "auto",
    overflow: "auto",
  },

  contentWithMobileMenu: {
    flexGrow: 1,
    paddingTop: "48px",
    height: "100%",
    width: "auto",
    overflow: "auto",
  },
}));

export default function Main(props) {
  const [user, setUser] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [orgPermissions, setOrgPermissions] = useState(null);
  const [api, setAPI] = useState(new API());

  const [sideOpen, setSideOpen] = React.useState(true);
  const [trace, setTrace] = useState(null);

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const nextURL = localStorage.getItem("next_url");

  const clientUpdateMessage = useClientUpdater();

  useEffect(() => {
    if (user && user.user) {
      FullStory.setUserVars(
        {
          displayName: user.user.first_name + " " + user.user.last_name,
          email: user.user.email
        });
    }
  }, [user]);

  useEffect(() => {
    attemptAuthRefresh();
  }, [])

  function attemptAuthRefresh() {
    api.getAuthAPI().refresh().then(response => {
      getAuthedProfile();
    }).catch(error => {
      console.log(error);
      history.replace('/login');
    });
  }

  function getAuthedProfile() {
    api.getAuthAPI().getAuthedProfile().then(response => {
      let authedUser = response.data;
      setUser(response.data);

      let default_org = authedUser.default_org;
      if (!default_org) {
        history.replace("/login");
      }

      localStorage.setItem("organization", JSON.stringify(default_org));
      setOrganization(default_org);

      new Traceability().getInstanceManagerAPI().listTraceabilities().then(response => {
        setTrace(response.data[0]);
      }).catch(error => {
        console.log(error);
      });

      //history.replace("/my_tasks/monitoring");

    }).catch(error => {
      console.log(error);
      history.replace('/login');
    })
  }

  useEffect(() => {
    if (organization && user) {
      let org_permission = user.user.org_permissions.find(
        permission => permission.organization.pk === organization.pk
      )

      setOrgPermissions(org_permission);
    }
  }, [organization, user]);

  useEffect(() => {
    if (user && (!user.accept_tos || !user.accept_privacy_policy) && location.pathname !== "/accept-terms") {
      history.replace("/accept-terms");
    }
  }, [location, user])

  function selectOrganization(event, orgPermission) {
    setOrganization(orgPermission.organization);
    setOrgPermissions(orgPermission);

    api.getAuthAPI().updateProfile({ id: user.id, default_org_permission: orgPermission.pk }).then(response => {
      setUser(response.data);
      localStorage.setItem("organization", JSON.stringify(orgPermission.organization));
      history.replace("/my_tasks/monitoring");
    });
  }
  useEffect(() => {
    // Set the permission that the user has for the selected org
    if (organization && user) {
      let org_permission = user.user.org_permissions.find(
        permission => permission.organization.pk === organization.pk
      )

      setOrgPermissions(org_permission);
    }
  }, [organization, user]);

  useEffect(() => {
    if (user && (!user.accept_tos || !user.accept_privacy_policy) && location.pathname !== "/accept-terms") {
      history.replace("/accept-terms");
    }
  }, [location, user])

  function selectOrganization(event, orgPermission) {
    setOrganization(orgPermission.organization);
    setOrgPermissions(orgPermission);

    api.getAuthAPI().updateProfile({ id: user.id, default_org_permission: orgPermission.pk }).then(response => {
      setUser(response.data);

      localStorage.setItem("organization", JSON.stringify(orgPermission.organization));
      history.replace("/my_tasks/monitoring/");
    });
  }

  return (
    <div className={classes.root}>
      <UserContext.Provider value={[user, setUser]}>
        <ApiContext.Provider value={api}>
          <OrgContext.Provider value={organization}>
            <OrgPermissionContext.Provider value={[orgPermissions, setOrgPermissions]}>
              {clientUpdateMessage}

              {user &&
                <Menus
                  user={user}
                  setOrganization={selectOrganization}
                  setSideOpen={setSideOpen}
                  sideOpen={sideOpen}
                />
              }

              <main
                className={!sideOpen ? classes.content : classes.contentWithMobileMenu}
              >
                <Switch>
                  {!user &&
                    <Grid container item justify="center" alignItems="center" style={{ width: '100%', height: '100%' }}>
                      <CircularProgress />
                    </Grid>
                  }

                  {user && (
                    <>
                      <Route path="/profile">
                        <Profile user={user} drawerOpen={sideOpen} />
                      </Route>

                      <Route path="/accept-terms">
                        <AcceptTerms user={user} setUser={setUser} />
                      </Route>

                      <Route path="/settings">
                        <Settings user={user} drawerOpen={sideOpen} />
                      </Route>

                      <Route path="/haccp_plans">
                        <HaccpPlans drawerOpen={sideOpen} user={user} />
                      </Route>

                      <Route path="/my_tasks">
                        <MyTasks user={user} />
                      </Route>

                      <Route path="/dashboard/monitoring">
                        <MonitoringDashboard></MonitoringDashboard>
                      </Route>

                      <Route path="/deviation/:pk">
                        <DeviationForm user={user} />
                      </Route>

                      <Route path="/deviation/new">
                        <DeviationForm user={user} />
                      </Route>

                      <Route path="/all_tasks">
                        <MonitoringTasks allTasks user={user} />
                      </Route>

                      <Route path="/monitoring/:pk">
                        <EventRecordForm user={user} />
                      </Route>

                      <Route path="/verification/:pk">
                        <VerificationForm user={user} />
                      </Route>

                      <Route path="/images">
                        <Images />
                      </Route>

                      <Route path="/admin_tools/">
                        <AdminRouter user={user} />
                      </Route>

                      <Route path="/dashboard/onboarding">
                        <OnboardingDashboard user={user} />
                      </Route>

                      <Route path="/records/monitoring_records">
                        <Records user={user} recordType={"monitoring"} />
                      </Route>

                      <Route path="/records/verification_records">
                        <Records user={user} recordType={"verification"} />
                      </Route>

                      <Route path="/records/deviation_records">
                        <Records user={user} recordType={"deviation"} />
                      </Route>

                      {<Route path="/records/audit">
                        <Audits user={user} />
                      </Route>}

                      <Route path="/plans/active">
                        <Plan user={user} />
                      </Route>

                      <Route path={`/plans/manager`}>
                        <Editor user={user} />
                      </Route>

                      <Route path={`/plans/documents`}>
                        <Documents user={user} />
                      </Route>

                      <Route path={`/plans/approved_information`}>
                        <ApprovedInformationTable baseUrl={null} activeTraceability={trace} />
                      </Route>

                      <Route path="/traceability">
                        <TraceabilityRouter />
                      </Route>

                      <Route path="/team">
                        <OrgInvite user={user} setOrgPermissions={setOrgPermissions} />
                      </Route>

                      <Route path="/logs">
                        <Logs user={user}/>
                      </Route>

                      {(!nextURL || nextURL === "null") &&
                        <Route exact path="/">
                          <Redirect to="/my_tasks/monitoring/" />
                        </Route>
                      }

                      <Route exact path="/404">
                        <GeneralApp404 />
                      </Route>
                    </>
                  )}
                </Switch>
              </main>
            </OrgPermissionContext.Provider>
          </OrgContext.Provider>
        </ApiContext.Provider>
      </UserContext.Provider>
    </div>
  );
}