import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams, Link } from "react-router-dom";

import { Grid, Typography } from "@material-ui/core";

import OnboardingTask from "./OnboardingTask";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    borderBottom: "1px solid #eaeaea",
  },
  sectionName: {
    fontFamily: "Roboto",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "0.1px",
    textAlign: "left",
    color: "#687895",
  },
}));
export default function OnboardingTasks(props) {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} style={{ margin: "12px" }}>
        {props.section && (
          <Typography className={classes.sectionName}>
            {props.section.name}
          </Typography>
        )}
        {props.section &&
          props.section.onboarding_tasks.map((task) => {
            return (
              <OnboardingTask
                key={task.id}
                task={task}
                markAsComplete={props.markAsComplete}
                onboardingInputs={props.onboardingInputs}
                complete={props.complete}
              />
            );
          })}
      </Grid>
    </Grid>
  );
}
