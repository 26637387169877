import React, { useState, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from '@material-ui/icons/Remove';
import CustomSelectField from "Monitoring/CustomFields/CustomSelectField"; 
import SelectFieldOptionsEditor from "Plans/PlanBuilder/CustomFieldEditors/SelectFieldOptionsEditor";


import GreyTextField from "Components/GreyTextField";

export default function SelectFieldEditor({customField, setCustomField}) {
	const [successMessage, setSuccessMessage] = useState(false);

	const [fakeError, setFakeError] = useState("")
	const [fakeInput, setFakeInput] = useState(null);

	function fakeSubmit(event) {
		if (customField.required && !fakeInput.length) {
			setFakeError("This field is required.");
		}

		else {
			setFakeError("");
			setSuccessMessage(true);
		}
	}

	useEffect(() => {
		setCustomField({...customField, field_type: 'select'});
	}, []);

	function removeOption(optionToRemove) {
		setCustomField({...customField, select_options: customField.select_options.filter(option => option !== optionToRemove)});
	}

	return (
		<Grid container item xs={12} style={{overflowY: 'auto', padding: "16px", paddingRight: '32px'}}>
			<Grid item xs={12}>
				<Typography style={{fontWeight: 'bold', fontSize: '16px'}}>
					Add a Select Field
				</Typography>
			</Grid>
			<Grid item xs={12} style={{paddingTop: '16px'}}>
				<Typography style={{fontSize: '14px'}}>
					Field Title
				</Typography>
			</Grid>
			<Grid item xs={12} style={{paddingTop: '8px'}}>
				<GreyTextField 
					value={customField.name || ""} 
					name="name" 
					fullWidth 
					onChange={event => setCustomField({...customField, name: event.target.value})}
				/>
			</Grid>
			<Grid item xs={12} style={{paddingTop: '16px'}}>
				<Typography style={{fontSize: '14px'}}>
					Field Instructions
				</Typography>
			</Grid>
			<Grid item xs={12} style={{paddingTop: '8px'}}>
				<GreyTextField 
					value={customField.description || ""} 
					multiline rows={2} fullWidth 
					onChange={event => setCustomField({...customField, description: event.target.value})}
				/>
			</Grid>

			<Grid item container alignItems="center" xs={6} style={{paddingTop: '16px'}}>
				<Typography>
					Is this field required? 
				</Typography>
			</Grid>

			<Grid item container xs={6} justify="flex-end" style={{paddingTop: '16px'}}>
				<Switch onChange={event => setCustomField({...customField, required: event.target.checked})}/>
			</Grid>


			<Grid item xs={12} style={{paddingTop: '16px', paddingBottom: '16px'}}>
				<FormControlLabel
					control={<Checkbox checked={customField.display_in_table} 
					onChange={(event) => setCustomField({...customField, display_in_table: event.target.checked})} name="checkedA" />}
					label="Should this field be displayed on tables?"
				/>
			</Grid>

			<Grid item xs={12}>
				<SelectFieldOptionsEditor customField={customField} setCustomField={setCustomField} />
			</Grid>

			<Grid item xs={12} style={{paddingTop: '16px', paddingBottom: '16px'}}>
				<Typography style={{fontWeight: 'bold', fontSize: '16px'}}>
					Preview
				</Typography>
			</Grid>

			<CustomSelectField
				style={{
					border: '1px solid #F5F5F5',
					padding: '0px 16px',
				}}
				id="preview"
				name="preview"
				displayName={customField.name}
				description={customField.description}
				required={customField.required}
				options={customField.select_options}
				value={fakeInput} 
				//removeOption={removeOption}
				onChange={event => { 
					setSuccessMessage(false); 
					setFakeInput(event.target.value)}
				} 
				error={fakeError} 
			/>

			<Grid container xs={12} justify="flex-end">
				<Grid item container xs={6}>
				</Grid>
				<Grid item container xs={6} style={{paddingTop: '16px'}}>
					{customField.select_options.map(option => {
						return (
							<Grid item container xs={12}>
								<Grid item container xs={6} justify="flex-end" alignItems="center">
									<Typography style={{fontSize: '14px', fontWeight: 400}}>
										{option.value}
									</Typography>
								</Grid>
								<Grid item container xs={6} justify="flex-end" alignItems="center" style={{padding: "0px 8px"}}>
									<IconButton onClick={(event) => removeOption(option)}>
										<RemoveIcon/>
									</IconButton>
								</Grid>
							</Grid>
						)
					})}
				</Grid>
			</Grid>

			<Grid item container xs={12} style={{paddingTop: '16px'}}>
				<Grid item container xs={12} alignItems="center">
					<Button variant="contained" color="primary" onClick={fakeSubmit}>Fake Submit</Button>
					
					{successMessage &&  
						<Alert severity="success" style={{marginLeft: '8px'}}>Success!</Alert>
					}
				</Grid>
			</Grid>
		</Grid>
	)
}