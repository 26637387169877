import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch, useParams, Switch, Route, matchPath, Redirect } from "react-router-dom";

import API from "../../Api/Api";
import Event from "./Event"
import EventWidget from "./EventWidget";
import Search from "../../Components/Search";

import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Alert from '@material-ui/lab/Alert';
import CancelIcon from '@material-ui/icons/Cancel';
import AddTasksDialog from "./AddTasksDialog";
import Prompt from "./Prompt";

import { makeStyles } from "@material-ui/core/styles"

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormLabel } from "@material-ui/core";
import LaunchIcon from '@material-ui/icons/Launch';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import LogForm from "../../Monitoring/Logs/LogForm";

import SanitizeHtml from "../../Components/SanitizeHtml";
import Tooltip from '@material-ui/core/Tooltip';
import useDesktop from "../../hooks/useDesktop";


const useStyles = makeStyles({
  eventCardContentRoot: {
    padding: '16px'
  },

  linkedTasksRoot: {

  },
  accordionNoLine: {
    marginBottom: '16px',
    marginTop: '16px',
    "&.MuiAccordion-root:before": {
      backgroundColor: "white"
    },
  }
});

export default function EventManager(props) {
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const classes = useStyles();

  const [eventSelected, setEventSelected] = useState(null);

  const match = useRouteMatch();
  const history = useHistory();

  const api = new API();
  const onDesktop = useDesktop();

  const [alertOpen, setAlertOpen] = useState(false);
  function handleClose(event) {
    setAlertOpen(false);
  }

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorText, setErrorText] = useState(null);
  function handleErrorClose(event) {
    setErrorOpen(false);
    setErrorText(null);
  }

  useEffect(() => {
    if (events.length && !filteredEvents.length) {
      setFilteredEvents(events);
    }
  }, [events]);

  useEffect(() => {
    if (props.activePlan && props.activePlan.events) {
      setEvents(props.activePlan.events);
      setLoading(false);
    }
  }, [props.activePlan]);

  useEffect(() => {
    if (props.activePlan && history.location) {

      const matchedPath = matchPath(history.location.pathname, {
        path: '/plans/manager/events/:event_pk',
        exact: false,
        strict: false
      });

      if (matchedPath == null) {
        if (props.activePlan.events && props.activePlan.events.length) {
          history.replace(`/plans/manager/events/${props.activePlan.events[0].id}`);
        }
        return;
      }

      let event = props.activePlan.events.find(event => event.id === parseInt(matchedPath.params.event_pk, 10));

      if (event) {
        setEventSelected(event);
      }
    }
  }, [history.location, props.activePlan]);

  function selectEvent(event) {
    if (props.closeSideMenu) {
      props.closeSideMenu();
    }
    history.push(`${match.url}/${event.id}`);
  }

  function updateEvent(event, saveAndContinue, save) {
    let oldIndex = events.findIndex((old) => { return old.id === event.id });

    let updatedEvents = [...events];
    if (oldIndex !== -1) {
      updatedEvents[oldIndex] = event;

      setEvents(updatedEvents);
      setEventSelected(updatedEvents[oldIndex]);
      setAlertOpen(true);

      if (save) {
        props.setActivePlan({ ...props.activePlan, events: updatedEvents });
      }

      if (saveAndContinue) {
        props.setActivePlan({ ...props.activePlan, events: updatedEvents });

        history.push(`/plans/manager/events/${event.id}/`);
      }
    }

    setUnsavedChanges(false);

    return updatedEvents;
  }

  function saveEvent() {
    if (!eventSelected) {
      return;
    }

    api.getEventAPI().updateEvent({ ...eventSelected }).then(response => {
      updateEvent(response.data);
    }).catch(error => {
      setErrorText("Could not update event");
      setErrorOpen(true)
      console.log(error);
    });
  }

  function addEvent(event, saveAndContinue) {
    let updatedEvents = [event, ...events];
    let updatedTasks = [...props.activePlan.tasks];

    if (event.read_tasks && event.read_tasks.length) {
      updatedTasks.push(...event.read_tasks);
    }

    setEvents(updatedEvents);

    props.setActivePlan({ ...props.activePlan, events: updatedEvents, tasks: updatedTasks });
    setAlertOpen(true);

    if (saveAndContinue) {
      history.push(`/plans/manager/events/${updatedEvents[0].id}/`);
    }
  }

  function deleteEvent(event) {
    let updatedEvents = [...events.filter(evt => evt.id !== event.id)];
    setEvents(updatedEvents);


    let updatedTasks = [...props.activePlan.tasks.filter(_task => !(_task.event === event.id && event.event_type === "LOG"))];
    for (let i = 0; i < updatedTasks.length; i++) {
      let updatedTask = updatedTasks[i];
      if (updatedTask.event === event.id) {
        updatedTask.event = null;
      }
    }

    setEventSelected(null);
    props.setActivePlan({ ...props.activePlan, events: updatedEvents, tasks: updatedTasks });

    setAlertOpen(true);

    history.goBack();
  }

  function addTasksToEvent(newTasks) {
     const orderedTasks = eventSelected.read_tasks.map(obj => obj.id);
     const updatedEvent = {
      ...eventSelected,
      tasks: [...orderedTasks, ...newTasks].map(task => parseInt(task, 10))
    };
    
    api.getEventAPI().updateEvent(updatedEvent).then(response => {
      let updatedEvents = updateEvent(response.data);

      // Get the added tasks and update their event locally 
      let updatedTasks = [...props.activePlan.tasks];
      for (let i = 0; i < updatedTasks.length; i++) {
        let updatedTask = updatedTasks[i];
        if (updatedEvent.tasks.find((task) => task === updatedTask.id)) {
          updatedTask.event = updatedEvent.id;
        }
      }

      props.setActivePlan({ ...props.activePlan, events: updatedEvents, tasks: updatedTasks });
    }).catch(error => {
      setErrorText("Could not add task to event.");
      setErrorOpen(true)
    });
  }

  function detachTask(task) {
    api.getTaskAPI().updateTask({ ...task, event: null }).then(response => {
      let updatedEvent = {
        ...eventSelected,
        tasks: eventSelected.tasks.filter(task => task !== response.data.id),
        read_tasks: eventSelected.read_tasks.filter(task => task.id !== response.data.id)
      }

      let updatedEvents = updateEvent(updatedEvent);
      let updatedTasks = [...props.activePlan.tasks];

      for (let i = 0; i < updatedTasks.length; i++) {
        let updatedTask = updatedTasks[i];
        if (updatedTask.id === response.data.id) {
          updatedTask.event = null;
        }
      }

      if (updatedEvent.tasks.length === 0) {
        setEventActive(updatedEvent);
      }

      props.setActivePlan({ ...props.activePlan, events: updatedEvents, tasks: updatedTasks });

    }).catch(error => {
      setErrorText("Could not detach task from event.");
      setErrorOpen(true)
    });
  }

  function setEventActive(event) {
    setSaving(true);

    if (event.primary_monitor == null || event.primary_verifier == null) {
      setErrorText("Must select a monitor and verifier for this event before setting it as active.")
      setErrorOpen(true);
      return;
    }

    if (event.tasks.length === 0 && !event.active) {
      setErrorText("Must have at least 1 task linked to this event before setting it as active.");
      setErrorOpen(true);
      return;
    }

    const updatedEvent = {
      ...event,
      active: !event.active,
      tasks: event.read_tasks.map(_task => _task.id)
    };

    api.getEventAPI().updateEvent(updatedEvent).then(response => {
      let updatedEvents = updateEvent(response.data);
      props.setActivePlan({ ...props.activePlan, events: updatedEvents });
      setSaving(false);
    }).catch(error => {
      setErrorText("Could not set event active.");
      setErrorOpen(true);
      setSaving(false);
    });
  }

  function getEventCard(event) {
    let borderColor = (eventSelected && eventSelected.id === event.id) ? '#18BFF6' : "white";

    return (
      <Card key={event.id} elevation={0} variant="outlined" style={{ margin: '8px', width: '100%', borderColor: borderColor }} >
        <CardActionArea onClick={() => { selectEvent(event) }} style={{ width: '100%', height: '100%' }}>
          <CardContent>
            {event.name}
          </CardContent>
        </CardActionArea>
      </Card>
    )
  }

  function moveTaskUp(task) {
    let read_tasks = [...eventSelected.read_tasks];

    let index = read_tasks.findIndex((val) => {
      return val.id === task.id;
    });

    if (index <= -1) {
      return;
    }

    if (index === 0) {
      return;
    }

    let temp = read_tasks[index - 1];
    read_tasks[index - 1] = read_tasks[index];
    read_tasks[index] = temp;

    let tasks_in_order = read_tasks.map(read_task => read_task.id)
    let updatedEvent = { ...eventSelected, tasks: tasks_in_order, read_tasks: read_tasks };

    setUnsavedChanges(true);
    setEventSelected(updatedEvent);
  }

  function moveTaskDown(task) {
    let read_tasks = [...eventSelected.read_tasks];

    let index = read_tasks.findIndex((val) => {
      return val.id === task.id;
    });

    if (index <= -1) {
      return;
    }

    if (index === read_tasks.length - 1) {
      return;
    }

    let temp = read_tasks[index + 1];
    read_tasks[index + 1] = read_tasks[index];
    read_tasks[index] = temp;

    let tasks_in_order = read_tasks.map(read_task => read_task.id)
    let updatedEvent = { ...eventSelected, tasks: tasks_in_order, read_tasks: read_tasks };

    setUnsavedChanges(true);
    setEventSelected(updatedEvent);
  }

  const [taskSelected, setTaskSelected] = useState(null);
  function getTaskCard(taskData) {
    let task = props.activePlan.tasks.find(read_task => read_task.id === taskData.id);
    if (!task) {
      return;
    }

    return (
      <Accordion elevation={0} style={{ width: '100%' }} className={classes.accordionNoLine} key={task.id}>
        <AccordionSummary
          expandIcon={<Tooltip title="Expand"><ExpandMoreIcon /></Tooltip>}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <Grid container item xs={12} alignItems="center">
            <Grid container item xs={6}>
              <Typography style={{ padding: '4px', fontWeight: 500, fontSize: '14px' }}>
                {task.name}
              </Typography>
              <Tooltip title="Go to task page">
                <IconButton style={{ margin: '0px 0px 0px 8px', padding: '0px' }} onClick={(event) => {
                  event.stopPropagation();
                  history.push(`/plans/manager/tasks/${task.id}/`);
                }}>
                  <LaunchIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid container item xs={6} justify="flex-end" alignItems="center">
              {!props.readOnly && eventSelected.event_type !== "LOG" &&
                <>
                  <Tooltip title="Move up">
                    <IconButton onClick={(event) => { event.stopPropagation(); moveTaskUp(task) }}>
                      <ArrowUpwardIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Move down">
                    <IconButton onClick={(event) => { event.stopPropagation(); moveTaskDown(task) }}>
                      <ArrowDownwardIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Remove task">
                    <IconButton onClick={(event) => { event.stopPropagation(); setTaskSelected(task) }}>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                </>
              }

              <Prompt
                agree={() => detachTaskAgree(task)}
                disagree={detachTaskDisagree}
                open={(taskSelected && taskSelected.id) === task.id}
                title="Detach Task?"
                description="Are you sure you want to detach this task? You can always add it back later."
              />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <SanitizeHtml value={task.procedures} />
        </AccordionDetails>
      </Accordion>
    )
  }

  const [addTasksDialogOpen, setAddTasksDialogOpen] = useState(false);
  function openAvailableTasks() {
    setAddTasksDialogOpen(true);
  }

  function detachTaskAgree(taskSelected) {
    if (taskSelected) {
      detachTask(taskSelected);
    }
    setTaskSelected(null);
  }

  function detachTaskDisagree() {
    setTaskSelected(null);
  }

  function navBar() {
    if (props.sideMenuOpen) {
      return (

        <Grid item container direction="column" style={{ borderRight: '1px solid #eaeaea', minWidth: onDesktop ? '360px' : '100%', maxWidth: onDesktop ? '360px' : '100%' }}>
          <Grid container item style={{ borderBottom: '1px solid #eaeaea', padding: "8px" }}>
            {!props.readOnly &&
              <>
                <Button disabled={loading} size="small" onClick={() => { history.push(`${match.url}/new_event`) }}>
                  + New Event
                </Button>

                <Button disabled={loading} size="small" onClick={() => { history.push(`${match.url}/new_log`) }}>
                  + New Log
                </Button>
              </>
            }
            <Search style={{ padding: '8px' }} fullWidth searchData={events} setSearchResults={setFilteredEvents} searchKeys={['name']} />
          </Grid>

          {!loading &&
            <Grid container item style={{ maxHeight: 'calc(100vh - 175px)', overflow: 'auto', padding: '4px' }}>
              {filteredEvents.map((event) => { return getEventCard(event) })}
            </Grid>
          }
        </Grid>
      )
    }
  }

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <Switch>
        <Route path={`${match.url}`} exact>
          <>
            {loading &&
              <Grid container item xs={12} alignItems="center" justify="center" style={{ height: '100%' }}>
                <CircularProgress />
              </Grid>
            }

            {!loading && navBar()}
          </>
        </Route>

        <Route path={`${match.url}/:event_pk/edit_event`}>
          <Event activePlan={props.activePlan} addEvent={addEvent} updateEvent={updateEvent} deleteEvent={deleteEvent} />
        </Route>

        <Route path={`${match.url}/new_event`}>
          <Event activePlan={props.activePlan} addEvent={addEvent} updateEvent={updateEvent} />
        </Route>

        <Route path={`${match.url}/new_log`}>
          <LogForm newLog activePlan={props.activePlan} addLog={addEvent} updateLog={updateEvent} />
        </Route>

        <Route path={`${match.url}/:pk/edit_log`}>
          <LogForm activePlan={props.activePlan} addLog={addEvent} updateLog={updateEvent} deleteEvent={deleteEvent} />
        </Route>

        <Route path={`${match.url}/:event_pk`}>
          {navBar()}

          <Grid item container justify="center" style={{ width: '100%', height: 'calc(100vh - 65px)', overflowY: 'auto', overflowX: 'auto' }}>
            {loading &&
              <Grid container item xs={12} alignItems="center" justify="center" style={{ height: '100%' }}>
                <CircularProgress />
              </Grid>
            }
            {!loading && eventSelected &&
              <div style={{ boxSizing: 'border-box', padding: '32px 16px', width: '100%', maxWidth: '1000px' }}>
                <Grid item container xs={12}>
                  <EventWidget disabled={unsavedChanges || saving} saving={saving} readOnly={props.readOnly} eventSelected={eventSelected} setEventActive={setEventActive} />
                </Grid>
                <Grid item container xs={12} style={{ margin: '16px 0px', /*height: '50px'*/ }}>
                  {unsavedChanges &&
                    <Alert severity="info" style={{ width: '100%', height: '50px' }} action={<Button onClick={saveEvent}>Save</Button>}>
                      This event has unsaved changes.
                    </Alert>
                  }
                </Grid>
                <Grid item container xs={12} style={{ margin: '16px 0px' }}>
                  <Grid item container xs={6} justify="flex-start" alignItems="center">
                    <Typography style={{ fontSize: '14px', fontWeight: 500 }}>
                      Linked Tasks&nbsp;<span style={{ color: "#18BFF6" }}>({eventSelected.read_tasks.length})</span>
                    </Typography>
                  </Grid>
                  {!props.readOnly && eventSelected.event_type !== "LOG" &&
                    <Grid item container xs={6} justify="flex-end" alignItems="center">
                      <Button size="small" onClick={openAvailableTasks}>
                        + Add Task
                      </Button>
                    </Grid>
                  }
                </Grid>
                <Grid item container xs={12}>
                  {eventSelected.read_tasks.map(task => getTaskCard(task))}
                </Grid>
              </div>
            }
          </Grid>
        </Route>
      </Switch>

      <AddTasksDialog open={addTasksDialogOpen} setOpen={setAddTasksDialogOpen} addTasks={addTasksToEvent} />

      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert severity="info" onClose={() => { setAlertOpen(false) }}>
          The record has been updated.
        </Alert>
      </Snackbar>

      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert severity="error" onClose={() => { handleErrorClose(); }}>
          {errorText}
        </Alert>
      </Snackbar>
    </div>
  )
}
