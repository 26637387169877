import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import API from "../../../Api/Api";
import { useHistory, useLocation } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import PropTypes from 'prop-types';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © FoodByte '}
            {/*<Link color="inherit" href="https://material-ui.com/">*/}
            {/*</Link>{' '}*/}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function ExistingUserInvite(props) {
    const classes = useStyles();
    const api = new API();
    const [userData, setUserData] = useState({pk: props.invite.pk, new_user: false });
    const history = useHistory();

    function onSubmit()
    {
      api.getOrganizationInviteAPI().updateInvite(userData).then(() => {
        history.push("/login");
      }).catch(e => {
          console.log(e);
      });
    }

    return (
      <form className={classes.form} noValidate>
        <Typography variant="h6">
            Click the button below to accept the invite. Once you log in, you will be able to select your team from the sidebar.
        </Typography>
          <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={onSubmit}
          >
              Accept Invite
          </Button>
      </form>
    );
}
