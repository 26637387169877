import React, {forwardRef, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";

import MaterialTable from "material-table";
import API from "../../../Api/Api";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

const IngredientTemplateList = React.memo(function IngredientTemplateList(props) {
    const [ingredientTemplates, setIngredientTemplates] = useState([]);
    const [loading, setLoading] = useState(true);

    const api = new API();

    let history = useHistory();

    useEffect(() => {
      let formulation_pk = props.formulation_template_pk ? props.formulation_template_pk : null;

      api.getIngredientTemplateAPI().listIngredients(formulation_pk).then(e => {
        setIngredientTemplates(e.data);
      }).catch(e => {
        console.log(e);
      });

    }, [api, props.formulation_template_pk]);

    const [user, setUser] = useState(null);
    useEffect(() => {
      api.getAuthAPI().getAuthedProfile().then(e => {
        setUser(e.data);
        setLoading(false);
      }).catch(e => {
        console.log(e);
      })
    }, []);


    function addIngredientTemplateToFormulationTemplate(ingredient_template) {
      api.getFormulationTemplateAPI().addIngredientToFormulation(props.formulation_template_pk, ingredient_template).then(response => {
        setIngredientTemplates(response.data);
      }).catch(error => {
        console.log(error);
      });
    }

    function removeIngredientTemplateFromFormulationTemplate(ingredient_template) {
      api.getFormulationTemplateAPI().removeIngredientFromFormulation(props.formulation_template_pk, ingredient_template).then(response => {
        setIngredientTemplates(response.data);
      }).catch(error => {
        console.log(error);
      });
    }

    const ingredient_columns = [
      {title: "Name", field: "name"},
      {title: "Description", field: "description"},
      {title: "Weight", field: "weight", editable: 'never'},
      {title: "Volume", field: "volume", editable: 'never'},
    ];

    return (
      <div style={{maxWidth: "100%"}}>
        <MaterialTable
          components={{
            Container: (props) => (
              <Paper {...props} elevation={0}/>
            ),
          }}
          isLoading={loading}
          title="Ingredient Templates"
          columns={ingredient_columns}
          data={ingredientTemplates}
          style={{width: "100%"}}
          icons={{
            Add: forwardRef((props, ref) => (
              <Button
                ref={ref}
                {...props}
                color="primary"
                variant="contained"
                aria-label="add"
              >
                Add Template
              </Button>
            )),
          }}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve, reject) => {
                newData.organization = user.organization.pk;

                if (props.formulation_template_pk) {
                  api.getIngredientTemplateAPI()
                    .createIngredient(newData)
                    .then((e) => {
                      addIngredientTemplateToFormulationTemplate(
                        e.data
                      );
                      resolve();
                    })
                    .catch((e) => {
                      console.log(e);
                      reject();
                    });
                } else {
                  api.getIngredientTemplateAPI()
                    .createIngredient(newData)
                    .then((e) => {
                      let updatedIngredients = [
                        ...ingredientTemplates,
                      ];
                      updatedIngredients.push(e.data);
                      setIngredientTemplates(
                        updatedIngredients
                      );
                      resolve();
                    })
                    .catch((e) => {
                      console.log(e);
                      reject();
                    });
                }
              }),
            onRowDelete: (newData) =>
              new Promise((resolve, reject) => {
                try {
                  if (props.formulation_template_pk) {
                    removeIngredientTemplateFromFormulationTemplate(
                      newData
                    );
                  } else {
                    api.getIngredientTemplateAPI()
                      .deleteIngredient(newData.pk)
                      .then((e) => {
                        let updatedIngredients = [
                          ...ingredientTemplates,
                        ];
                        updatedIngredients.splice(
                          updatedIngredients.indexOf(
                            newData
                          ),
                          1
                        );
                        setIngredientTemplates(
                          updatedIngredients
                        );
                      });
                  }
                  resolve();
                } catch (error) {
                  reject();
                }
              }),
          }}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit/View",
              onClick: (event, rowData) => {
                history.push(
                  "/templates/ingredients/" + rowData.pk
                );
              },
            },
          ]}
        />
      </div>
    );
  },
  function (prevProps, nextProps) {
    if (prevProps.ingredients === nextProps.ingredients) return true;
    if (prevProps.ingredients == null || nextProps.ingredients == null)
      return false;
    if (prevProps.ingredients.length != nextProps.ingredients.length)
      return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (let i = 0; i < prevProps.ingredients.length; ++i) {
      if (prevProps.ingredients.length[i] !== nextProps.ingredients.length[i]) return false;
    }

    return true;
  });

export default IngredientTemplateList;
