import React, { useState } from 'react'

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import Section from "./Section"
import Standard from "./Standard"

import API from "../../Api/Api"

export const ItemTypes = {
  STANDARD: 'standard',
  BOX: 'box',
}

function Node(props) {
  let api = new API().getPlanBuilderAPI()

  const [node, setNode] = useState(null);

  const [standardModalOpen, setStandardModalOpen] = useState(false);
  function toggleStandardModal(event) {
    setStandardModalOpen(!standardModalOpen);
  }

  function deleteStandard(standardPK) {
    api.deleteStandard(standardPK).then(response => {
      setNode({ ...node, standards: node.standards.filter(standard => standard != standardPK) });
    });
  }

  function getChildren() {
    if (!props.node.children || !props.node.children.length) {
      return [];
    }

    let childNodes = props.node.children.map(child => {
      return (
        <Node
          key={child.id}
          readOnly={props.readOnly}
          setStandardSelected={props.setStandardSelected}
          node={child}
          setNodeData={props.setNodeData}
          tree={props.tree}
          deleteSection={props.deleteSection}
          offset={props.offset}
        />
      )
    });

    return childNodes;
  }

  return (
    <Grid item xs={12} style={{ width: "100%"}}>

      {props.node && props.node.type === 'SECTION' &&
        <Grid container item xs={12} style={{ paddingLeft: `${props.offset}px`, paddingTop: '8px', paddingBottom: '8px' }}>
          <Section
            node={props.node}
            readOnly={props.readOnly}
            setNodeData={props.setNodeData}
            deleteSection={props.deleteSection}
            offset={props.offset}
          />
          {getChildren()}
        </Grid>
      }

      {props.node && props.node.type === 'STANDARD' &&
        <Grid container item xs={12} style={{ paddingLeft: `${props.offset}px`, paddingTop: '8px', paddingBottom: '8px' }}>
          <Grid item xs={12}>
            <Paper elevation={0}>
              <Standard
                node={props.node}
                readOnly={props.readOnly}
                deleteStandard={props.deleteSection}
                setStandardSelected={props.setStandardSelected}
                offset={props.offset}
              />
            </Paper>
          </Grid>
        </Grid>
      }

    </Grid>
  )
}

export default Node;
