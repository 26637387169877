import React, { useState, useEffect } from "react";
import API from "../../Api/Api";
import ProcessFlowDiagram from "./ProcessFlowDiagram";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

import { createStore } from "redux";
import { Provider } from "react-redux";

import Typography from "@material-ui/core/Typography";

const initialState = {
  count: 55,
  index: 0,
  history: [],
  value: "",
  event: "",
  curState: [],
  past: [],
  present: {},
  future: [],
  actions: []
};


function reducer(state = initialState, action) {
  const { past, present, future, actions } = state;
  const index = state;
  switch (action.type) {

    case "undo":
      if (actions.length > 0 && past.length > 0) {
        return {
          ...state,
          future: state.future.concat(state.present),
          present: state.past[state.past.length - 1],
          past: state.past.slice(0, state.past.length - 1),
        }
      } else {
        return state;
      }
    case "deletedProcessGroup":
      if (actions.length > 0 && past.length > 0) {
        return {
          ...state,
          future: state.future.concat(state.past[state.past.length - 1]),
          present: state.past[state.past.length - 2],
          past: state.past.slice(0, state.past.length - 2),
        }
      } else {
        return state;
      }
    case "redo":
      if (actions.length > 0 && future.length > 0) {
        return {
          ...state,
          past: state.past.concat(state.present),
          present: state.future[state.future.length - 1],
          future: state.future.slice(0, state.future.length - 1),
        }
      } else {
        return state;
      }
    case "addToCurrent":
      return {
        ...state,
        count: state.count - 1,
        history: state.history.concat({ id: Math.random(), count: state.count - 1 }),
        curState: state.curState.concat({ id: Math.random(), curState: action.value }),
      };
    case "addAction":
      return {
        ...state,
        past: state.past.concat(state.present),
        actions: state.actions.concat({ id: Math.random(), event: action.event, actions: action.value }),
        present: { id: Math.random(), event: action.event, present: action.value },
      };
    default:
      return state;

  }
}

function setBlockNavigation() {

}

const store = createStore(reducer);
export default function ProcessFlow(props) {
  let api = new API();

  const [haccpPlan, setHaccpPlan] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isActive, setIsActive] = useState(false);

  function successMessage() {
    if (props.successMessage) {
      props.successMessage();
    }
  }

  useEffect(() => {
    let pk = props.haccpPlanPk;
    setIsActive(true);

    api.getHaccpPlanAPI().retrieveHaccpPlan(pk).then(response => {
      let haccpPlan = response.data;
      setHaccpPlan(haccpPlan);

      if (response.data.process_flow_diagram != null) {
        setLoading(false);
      } else { // create and add a process flow diagram to the haccp plan

        let newDiagram = {
          "organization": response.data.organization,
          // "processes": [...response.data.processes],
        };

        api.getProcessFlowDiagramAPI().createProcessFlowDiagram(newDiagram).then(response => {
          api.getHaccpPlanAPI().updateHaccpPlan({
            ...haccpPlan,
            process_flow_diagram: response.data.pk
          }).then(response => {
            setHaccpPlan(response.data);
            setLoading(false);
          }).catch(error => {
            console.log(error);
          });
        }).catch(error => {
          console.log(error);
        });
      }
    }).catch(error => {
      console.log(error);
    });
  }, [props.haccpPlanPk]);

  return (

    <div id="process_flow" style={{ width: '100%', height: "100%" }}>
      {haccpPlan.process_flow_diagram && !loading &&
        <Provider store={store}>
          <Grid container >
            <ProcessFlowDiagram
             readOnlyMode={props.readOnlyMode} 
              pk={haccpPlan.process_flow_diagram}
              haccpPlan={haccpPlan}
              setIsBlocking={props.setIsBlocking}
              successMessage={successMessage}
            />
          </Grid>
        </Provider>
      }

      {!haccpPlan.process_flow_diagram && !loading && (
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginTop: "250px" }}>
            <Typography>
              <CircularProgress />
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  )
}
