import React, { useEffect, useState } from "react";
import Section from './Section';

import Grid from "@material-ui/core/Grid";

const testData = [
	{
		id: 1, title: 'Sanitation', 
		sections: [
			{id: 2, title: 'Cleaning Products', sections: [{id: 529, title: "Bleach", sections: [], tasks: []}], tasks: []},
		], 
		tasks: []
	},

	{
		id: 3, title: 'Maintenance', 
		sections: [
			{id: 4, title: "Machinery", tasks: []},
		], 
		tasks: []
	},

	{
		id: 5, title: 'Employee Hygiene', 
		sections: [
			{id: 6, title: 'Hand Washing', tasks: []},
		], 
		tasks: []
	},
]

const Scope = ({data, standardsChecked, setStandardChecked, sectionsChecked, setSectionChecked}) => {
	return (
		<Grid container style={{maxHeight: '50vh', overflowY: 'auto', padding: '12px 8px 12px 0px'}}>
			{data && data.map(node => {
				return (
					<Grid key={node.id} item xs={12}>
						<Section 
							key={node.id}
							indent={0} 
							parentChecked={false} 
							section={node} 

							standardsChecked={standardsChecked} 
							setStandardChecked={setStandardChecked} 

							sectionsChecked={sectionsChecked} 
							setSectionChecked={setSectionChecked}
						/>
					</Grid>
				)
			})}
		</Grid>
	)
}

export default Scope;