import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import React from "react";

export default function HazardDetails(props) {
  return (
    
    <Grid container spacing={3}>
      
        <Grid item xs={12}>
           {props.hazard &&
          <TextField
            error={props.hazard.name ? false : props.fieldError}
            label="Hazard Name"
            name="name"
            variant="outlined"
            value={props.hazard && props.hazard.name != null ? props.hazard.name : ""}
            onChange={props.handleChange}
            fullWidth
          />
           }
        </Grid>
        <Grid item xs={12}>
           {props.hazard &&
          <TextField
            label="Hazard Description"
            name="description"
            variant="outlined"
            value={props.hazard && props.hazard.description != null ? props.hazard.description : ""}
            multiline
            rows={3}
            onChange={props.handleChange}
            fullWidth
          />
           }
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Type: </InputLabel>
          <Select
            name="type"
            value={props.hazard && props.hazard.type != null ? props.hazard.type : "B"}
            onChange={props.handleChange}
          >
            <MenuItem value="B">Biological</MenuItem>
            <MenuItem value="C">Chemical</MenuItem>
            <MenuItem value="P">Physical</MenuItem>
          </Select>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.hazard && props.hazard.significant != null  ? props.hazard.significant : false}
                onChange={props.handleChange}
                name="significant"
                value={props.hazard && props.hazard.significant != null ? props.hazard.significant : false}
                color="primary"
              />
            }
            label="Is the Potential Hazard Significant?"
            labelPlacement="start"
          />
        </Grid>
        <Grid item xs={12}>
            <TextField
              label="Justification for Inclusion or Exclusion as a Significant Hazard"
              name="justification"
              variant="outlined"
              value={props.hazard && props.hazard.justification != null ? props.hazard.justification : ""}
              multiline
              rows={3}
              onChange={props.handleChange}
              fullWidth
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
              label="Preventative Measures of the Significant Hazard"
              name="preventative_measures"
              variant="outlined"
              value={props.hazard && props.hazard.preventative_measures != null  ? props.hazard.preventative_measures : ""}
              disabled={props.hazard && props.hazard.significant != null ? !props.hazard.significant : true}
              multiline
              rows={3}
              onChange={props.handleChange}
              fullWidth
            />
         </Grid>
    </Grid>
  )
}
