import React, { useEffect, useState, useContext } from "react";

import {
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  Redirect,
} from "react-router-dom";

import {
  Chip,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Paper,
  Typography,
  Button,
} from "@material-ui/core"

import API from "../../Api/Api"
import CreateNewPlan from "./CreateNewPlan"
import AdminRoute from "../../Components/AdminRoute";
import AddIcon from '@material-ui/icons/Add';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { OrgContext } from "hooks/useOrganization"

export default function Plans(props) {
  const [plans, setPlans] = useState(null);
  const [activePlan, setActivePlan] = useState(null);

  const [refreshPlans, setRefreshPlans] = useState(true);

  const history = useHistory();
  const match = useRouteMatch();

  const org = useContext(OrgContext);

  useEffect(() => {
    if (refreshPlans) {

      new API().getPlanBuilderAPI().listPlans().then(response => {
        setPlans(response.data);

        let active = response.data.find(plan => plan.is_active);
        setActivePlan(active);
        setRefreshPlans(false);
      }).catch(error => {
        console.log(error);
      })
    }
  }, [refreshPlans, org]);

  function handleAddNewPlan(event) {
    history.push(`${match.url}/new_plan/`);
  }

  function getPlansIntro() {
    return (
      <Paper variant="outlined" elevation={0} style={{ margin: '16px', padding: '16px 16px 32px 16px' }} square>
        <Grid container spacing={3}>
          <Grid item container xs={12}>
            <Grid item xs={8}>
              {org &&
                <Typography variant='h5'>
                  Plan Manager for {org.name} (Admin Only)
                </Typography>
              }
            </Grid>
            <Grid item container xs={4} justify="flex-end">
              <Button startIcon={<AddIcon />} onClick={handleAddNewPlan} variant="contained" color="primary">
                Add a Plan
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              To create a new plan, click the button below and select an available program template from the list,
              this will add the plan to the organization. Select a plan below to make it the active plan for an org.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  function getPlanComponents() {
    return (
      plans.map(plan => {
        return (
          <Grid key={plan.id} item xs={4}>
            <Card elevation={0}
              style={{ height: '100%', width: '100%' }}
              variant="outlined"
              square key={plan.id}>
              <CardActionArea style={{ height: '100%' }}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Typography variant="h6">
                        {plan.name}
                      </Typography>
                    </Grid>
                    {plan.is_active &&
                      <Grid container item xs={12} sm={12} md={6} lg={6} justify="flex-end">
                        <Typography>
                          <Chip color="primary"
                            icon={<CheckCircleOutlineIcon />}
                            label="Active"
                          />
                        </Typography>
                      </Grid>
                    }
                    <Grid item xs={12}>
                      <Typography>
                        {plan.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        )
      }));
  }

  return (
    <React.Fragment>
      {props.user &&
        <Switch>
          <Route path={`${match.url}`} exact>
            <Grid container style={{ width: '100%' }}>
              <Grid item xs={12}>
                {getPlansIntro()}
              </Grid>
            </Grid>

            {plans &&
              <Grid container style={{ width: '100%', padding: '16px' }} spacing={3}>
                {getPlanComponents()}
              </Grid>
            }

            {!props.user.user.is_superuser && activePlan &&
              <Redirect to={`${match.url}/${activePlan.id}`} />
            }
          </Route>

          <AdminRoute path={`${match.url}/new_plan/`} user={props.user}>
            <CreateNewPlan user={props.user} refreshPlans={setRefreshPlans} />
          </AdminRoute>
        </Switch>
      }
    </React.Fragment>
  )
}
