import React, { useEffect, useState } from "react";

import TabbableGroupedTable from "../../Components/GroupedTable/TabbableGroupTable";
import API from "../../Api/Api";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Traceability from "../Traceability";
import TraceabilityUtils from "../TraceabilityUtils";
import Message from "../../Components/Message";
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import Paper from "@material-ui/core/Paper";
import OrderStatusManager from "./OrderStatusManager";
import { StylesContext } from "../../App";
import OrderForm from "./OrderForm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function OrderTable(props) {
  const utils = new TraceabilityUtils();
  const classes = React.useContext(StylesContext);


  const EMPTY_MESSAGE = {
    open: false,
    message: '',
    status: 'info',
  };

  const tabStatuses = {
    counted: false,
    column: 'status',
    tabs: [
      { title: 'All', value: 'All' },
      { title: 'In progress', value: 'Draft' },
      { title: 'Received', value: 'Complete' },
      // { title: 'Draft', value: 'Draft' },
      // { title: 'Outstanding', value: 'Outstanding' },
      // { title: 'Receiving', value: 'Receiving' },
      // { title: 'Complete', value: 'Complete' },
    ]
  }

  const [orders, setOrders] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orderFormOpen, setOrderFormOpen] = useState(false);
  // const [orderSelected, setOrderSelected] = useState(-1);
  // const [shouldClose, setShouldClose] = useState(true);
  const [message, setMessage] = useState({ ...EMPTY_MESSAGE });
  const [user, setUser] = useState(null);
  const [customers, setCustomers] = useState(false);
  const [suppliers, setSuppliers] = useState(false);
  // const [skus, setSkus] = useState(false);
  const [ingredients, setIngredients] = useState(false);
  const [units, setUnits] = useState(false);

  const [inventoryLocations, setInventoryLocations] = useState(false);
  const [orderToPass, setOrderToPass] = useState({});
  const [toSubmit, setToSubmit] = useState(undefined);

  // useEffect(() => {
  //   console.log(orderSelected);
  //   setOrderToPass(orderSelected >= 0 ? orders[orderSelected] : { status: 'DRAF', sku_instances: [] })
  // }, [orderSelected])

  useEffect(() => {
    const api = new Traceability().getOrderAPI();
    const authApi = new API().getAuthAPI();
    const customerApi = new Traceability().getFCustomerAPI();
    const supplierApi = new Traceability().getFSuppliersAPI();
    const skuApi = new Traceability().getSkuApi();
    const unitApi = new Traceability().getUnitsAPI();
    const ingredientApi = new Traceability().getFIngredientsAPI();
    const inventoryLocationApi = new Traceability().getInventoryLocationAPI();
    const FReceivingAPI = new Traceability().getFReceivingAPI();

    unitApi.listUnits().then(e => {
      setUnits(e.data);
    }).catch(e => {
      //console.log(e);
    })
    if (props.user) {
      setUser(props.user)
    }
    else {
      authApi.getAuthedProfile().then(e => {
        setUser(e.data);
      }).catch(e => {
        //console.log(e);
      })
    }

    FReceivingAPI.listReceiving().then(e => {
      setOrders(e.data);
    }).catch(e => {
      console.log(e);
    })

    if (props.customers) {
      let pkIndexedCustomers = {};
      props.customers.forEach((customerObject) => {
        pkIndexedCustomers[customerObject.pk] = { ...customerObject };
      });
      setCustomers(pkIndexedCustomers);
    }
    else {
      utils.pkIndexObjectsFromApi(customerApi.listCustomer.bind(customerApi), setCustomers);
    }

    if (props.suppliers) {
      let pkIndexedSuppliers = {};
      props.suppliers.forEach((supplierObject) => {
        pkIndexedSuppliers[supplierObject.pk] = { ...supplierObject };
      });
      setSuppliers(pkIndexedSuppliers);
    }
    else {
      utils.idIndexObjectsFromApi(supplierApi.listFSuppliers.bind(supplierApi), setSuppliers);
    }

    ingredientApi.listFIngredients().then(e => {
      setIngredients(e.data);
    }).catch(e => {
      console.log(e);
    })

    if (props.inventoryLocations) {
      let pkIndexedLocations = {};
      props.inventoryLocations.forEach((invLocation) => {
        pkIndexedLocations[invLocation.pk] = invLocation.name;
      });
      setInventoryLocations(pkIndexedLocations);
    }
    else {
      utils.pkIndexFieldsFromApi(inventoryLocationApi.listInventoryLocationsDisplay.bind(inventoryLocationApi), setInventoryLocations, 'name')
    }

    setLoading(false);

  }, []);

  const orderColumns = [
    // { title: "Order Id", field: "orderId" },
    { title: "Supplier", field: "supplier_name" },
    { title: "Receiving Date", field: "receiving_date" },
    { title: "Status", field: 'status' }
  ];

  function formatTableData(tableData) {
    let formattedTableData = [];


    tableData.forEach((dataPoint) => {
      let formattedPoint = {};
      // formattedPoint.orderId = dataPoint.order_id;

      // let otherCompany = dataPoint.customer ? dataPoint.customer : dataPoint.supplier;

      // if (otherCompany) {
      //   // formattedPoint['Customer/Supplier'] = dataPoint.customer ? customers[otherCompany].company : suppliers[otherCompany].company;
      //   formattedPoint['Customer/Supplier'] = ' ';
      // }
      // else {
      //   formattedPoint['Customer/Supplier'] = ' ';
      // }

      //formattedPoint.order_date = dataPoint.created_date ? utils.dateObjToString(dataPoint.created_date) : '';
      //formattedPoint.expected_delivery = dataPoint.expected_delivery ? utils.dateObjToString(dataPoint.expected_delivery) : '';

      // formattedPoint.status = utils.statusCipher(dataPoint.status).text;
      // formattedPoint.pk = dataPoint.pk;

      formattedPoint = { ...dataPoint };
      formattedPoint.supplier_name = dataPoint.supplier ? dataPoint.supplier.name : '';
      formattedPoint.status = dataPoint.is_draft ? "Draft" : "Complete";

      formattedTableData.push(formattedPoint);
    });


    return formattedTableData;
  }

  /**
   * Handles keeping the list up to date with the back end, including updating all lines.
   * 
   * @param {JSON} response - a response object from the server
   */
  function onSave(response, setReturnMessage, index) {
    setLoading(true)
    let updatedOrderId = response.data.id;
    let updatedOrders = [...orders];
    let newOrder = { ...response.data }

    let itemIndex;
    if (!index && index !== 0) {
      itemIndex = updatedOrders.findIndex(order => {
        return order.id === updatedOrderId;
      });
    }
    else {
      itemIndex = index;
    }

    if (itemIndex > -1) {
      updatedOrders.splice(itemIndex, 1, newOrder);
    }
    else {
      updatedOrders.push(newOrder);
      itemIndex = updatedOrders.length - 1;
    }
    
    setOrders(updatedOrders);
    if (setReturnMessage) {
      setMessage({
        open: true,
        message: 'Saved Successfully',
        status: 'success',
      });
    }
    else {
      setLoading(false)
    }

    setOrderToPass(newOrder);
  }

  function openOrderStatusManager(event, rowData) {
    // let newIndex = utils.matchOnId(orders, rowData.id, '_index_')

    // if (orderSelected != newIndex) {
    // setOrderSelected(rowData.id);
    setOrderToPass(rowData);
    setOrderFormOpen(true);
    // }
    // else {
    //   setOrderFormOpen(true);
    // }
  }

  function closeModal() {
    // console.log("shouldClose >> ", shouldClose);
    // if (!shouldClose) {
    //   if (!window.confirm("Are you sure you want to close without saving?")) {
    //     return;
    //   }
    // }

    cancel();
  }

  function cancel() {
    // setOrderSelected(-1);
    setOrderFormOpen(false);
  }

  const updateIngredients = (newIngredient) => {
    setIngredients([...ingredients, newIngredient]);
  };

  return (
    <>
      {(!orders || !customers || !suppliers || !ingredients || !inventoryLocations) &&
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginTop: "250px" }}>
            <Typography>
              <CircularProgress />
            </Typography>
          </Grid>
        </Grid>
      }

      {(orders && customers && suppliers && inventoryLocations && ingredients
        // && (orderSelected == -1)
      ) &&
        <Paper elevation={0} square className={classes.generalListPaperContainer} >
          <Grid container spacing={0} style={{ padding: '0px' }}>
            <Grid item xs={12}>
              <TabbableGroupedTable

                tabStatuses={tabStatuses}
                data={formatTableData(orders)}
                options={{
                  pageSize: 10,
                }}
                title={'Receiving'}
                columns={orderColumns}
                localization={{
                  body: {
                    emptyDataSourceMessage: 'Click Receive for receiving.',
                  }
                }}

                actions={
                  [
                    {
                      icon: (props) => (
                        <Button size="small" color="primary" variant="outlined" className={classes.tabTableFreeAction}>
                          {'Receive'}
                        </Button>
                      ),
                      tooltip: 'Receive',
                      isFreeAction: true,
                      onClick: (event) => {
                        // setOrderSelected(-2); 
                        setOrderToPass({})
                        setOrderFormOpen(true);
                      }
                    },
                    rowData => ({
                      icon: (props) => (
                        <Button size="small" color="primary" variant="outlined" >
                          {utils.statusActionCipher(rowData.status, false)}
                        </Button>
                      ),
                      tooltip: 'Open Manager',
                      onClick: (event, rowData) => openOrderStatusManager(event, rowData)
                    }),
                  ]
                }

                onRowClick={(event, rowData) => openOrderStatusManager(event, rowData)}
              />
            </Grid>
          </Grid>

          <Message
            open={message.open}
            message={message.message}
            severity={message.status}
            vertical="bottom"
            horizontal="left"
            handleClose={() => { setMessage({ ...message, open: false }); setLoading(false) }}
          />
        </Paper>
      }

      {orders && customers && suppliers && inventoryLocations && ingredients && orderToPass != -1 &&
        <Dialog fullScreen open={orderFormOpen} onClose={closeModal} TransitionComponent={Transition}>
          <DialogContent className={classes.generalFormDialogueContainer}>
            <OrderForm
              order={orderToPass}
              disabled={false}
              // setShouldClose={setShouldClose}
              user={user}
              onSave={onSave}
              closeModal={closeModal}
              cancel={cancel}
              activeTraceability={props.activeTraceability}
              customers={customers}
              suppliers={suppliers}
              units={units}
              ingredients={ingredients}
              updateIngredients={updateIngredients}
              inventoryLocations={inventoryLocations}
            // handleStatusSubmit={handleStatusSubmit}
            // toSubmit={toSubmit}
            // setToSubmit={setToSubmit}
            />
            {/* <OrderStatusManager
              order={orderToPass}
              setShouldClose={setShouldClose}
              user={user}
              onSave={onSave}
              closeModal={closeModal}
              cancel={cancel}
              activeTraceability={props.activeTraceability}
              customers={customers}
              suppliers={suppliers}
              skus={skus}
              units={units}
              ingredients={ingredients}
              inventoryLocations={inventoryLocations}
              toSubmit={toSubmit}
              setToSubmit={setToSubmit}
            /> */}
          </DialogContent>
          {/* We can switch to a dialogue actions down the line if we need but atm it's a lot of re-arranging for little benefit.
          <DialogActions className={classes.generalDialogueFoot}>
            <Button
              //variant="outlined"
              color="primary"
              style={{ margin: "8px" }}
              onClick={props.closeModal}
              //todo great, now how to we do this
              //disabled={orderToPass.status == 'COMP' || props.disabled}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "8px" }}
              //todo whats a good way to get the actions from within the form up to here?
              //todo why does this trigger right away?
              onClick={() => { setToSubmit(false) }}
            >
              Save Changes
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "8px" }}
              onClick={() => { setToSubmit(true) }}
            >
              Submit
            </Button>
      </DialogActions>*/}
        </Dialog>
      }
    </>
  )
}

OrderTable.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  customers: PropTypes.arrayOf(PropTypes.object),
  suppliers: PropTypes.arrayOf(PropTypes.object),
  skus: PropTypes.arrayOf(PropTypes.object),
  units: PropTypes.objectOf(PropTypes.object),
  inventoryLocations: PropTypes.objectOf(PropTypes.object),
  activeTraceability: PropTypes.object,
}