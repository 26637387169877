import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { formatDateTime } from "utils/date";

import DateFnsUtils from '@date-io/date-fns';
import {
	DateTimePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const useStyles = makeStyles({
	underline: {
		"&&:before": {
			borderBottom: 'none',
		}
	},

	input: {
		backgroundColor: '#F5F5F5',
		padding: '16px',
	},

	multiline: {
		padding: '0px',
	},
});

export default function CustomDateTimeField(props) {

	const classes = useStyles();

	return (
		<Grid container style={props.style}>
			<Grid container item xs={12} md={props.oneRow ? 12 : 6}>
				<Grid item xs={12} style={{ paddingTop: '12px' }}>
					<Typography style={{ fontSize: '14px', fontWeight: 500 }}>
						{props.displayName ? props.displayName : "<title>"} {props.required === true ? <span style={{ color: 'red', marginLeft: '4px' }}>*</span> : null}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography style={{ fontSize: '12px', fontWeight: 400 }}>
						{props.description ? props.description : "<instructions>"} 
					</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={12} md={props.oneRow ? 12 : 6}>
				<Grid item xs={12} style={{ paddingTop: '8px', paddingBottom: '8px' }}>
					{!props.readOnly &&
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<DateTimePicker
								readOnly={props.readOnly}
								variant="filled"
								//format='MMMM do, yyyy'
								//maxDate={audit.end_date}
								InputProps={{ disableUnderline: true, classes: { input: classes.input } }}
								showTodayButton
								fullWidth
								value={props.value}
								onChange={props.onChange}
							/>
						</MuiPickersUtilsProvider>
					}

					{props.error &&
						<Typography style={{ fontSize: '12px', color: '#f44336' }}>{props.error}</Typography>
					}

					{props.readOnly &&
						<Typography style={{ fontSize: '14px' }}>
							{props.value ? formatDateTime(props.value) : "This field wasn't entered."}
						</Typography>
					}
				</Grid>
			</Grid>
		</Grid>
	)
}