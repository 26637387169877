class TrafficFlowDiagramTemplateAPI {

  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listTrafficFlowDiagramTemplates(haccp_plan_pk = null) {
    if (typeof haccp_plan_pk === 'number') { // Get traffic_flow_diagram_templates in formulation
      return this.api.get(`${this.version}/traffic_flow_diagram_templates/?haccp_plan_pk=${haccp_plan_pk}`)
    }

    return this.api.get(`${this.version}/traffic_flow_diagram_templates/`)
  }

  createTrafficFlowDiagramTemplate(payload) {
    return this.api.post(`${this.version}/traffic_flow_diagram_templates/`, payload);
  }

  createBlankTrafficFlowDiagramTemplate(payload) {
    return this.api.post(`${this.version}/traffic_flow_diagram_templates/`, payload);
  }

  retrieveTrafficFlowDiagramTemplate(pk) {
    return this.api.get(`${this.version}/traffic_flow_diagram_templates/${pk}/`);
  }

  deleteTrafficFlowDiagramTemplate(pk) {
    return this.api.delete(`${this.version}/traffic_flow_diagram_templates/${pk}/`);
  }

  updateTrafficFlowDiagramTemplate(payload) {
    return this.api.put(`${this.version}/traffic_flow_diagram_templates/${payload.id}/`, payload, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  updateTrafficFlowDiagramTemplateImage(pk, formData) {
    return this.api.put(`${this.version}/traffic_flow_diagram_templates/${pk}/`, formData);
  }

  getHistory(pk) {
    return this.api.get(`${this.version}/traffic_flow_diagram_templates/${pk}/get_history/`);
  }
}


export default TrafficFlowDiagramTemplateAPI;
