import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DialogContent, DialogActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    searchContainer: {
        paddingLeft: "12px",
        paddingTop: "12px",
        display: "inline-flex",
        alignItems: "baseline",
    },
    searchResultsText: {
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: "20px",
        lineHeight: "20px",
        letterSpacing: "0.15px",
        color: "black",
    },
    resultsFoundText: {
        paddingLeft: "12px",
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "14px",
        letterSpacing: "0.1px",
        color: "#687895",
    },
    iconButtonContainer: {
        paddingTop: "0px",
        alignItems: "baseline",
    },
    iconButton: { padding: 0, marginRight: "0px" },
    eventText: {
        paddingLeft: "12px",
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        letterSpacing: "0.1px",
        color: "#1F203D",
    },
    submitButton: { margin: "8px", marginRight: "12px" },
    select: { margin: "12px", marginRight: "0px", width: "100%" },
    selectContainer: { marginTop: "24px", paddingRight: "24px" },
    textfieldbackground: {
        "&::-webkit-calendar-picker-indicator": {
            display: "none",
            "-webkit-appearance": "none",
        },
    },
}));

export default function SearchModal(props) {
    const [resultsFound, setResultsFound] = useState(null);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(
        props.search
            ? props.search
            : {
                  name: "All Records",
                  end: "2022-01-01",
                  start: "2000-01-01",
                  monitor: "All Users",
                  type: "Any",
              }
    );
    const classes = useStyles();

    function handleChange(eventData) {
        setSearch({ ...search, [eventData.target.name]: eventData.target.value });
    }

    function cancel() {
        if (props.cancel) {
            props.cancel();
        }
    }
    function save() {
        if (props.save) {
            props.save(search, 1, props.currentPageSize);
        }
    }
    function handleAutoCompleteChange(field, value) {
        if (value) {
            setSearch({ ...search, name: value.name });
        }
    }
    function clearSearch() {
        setSearch({
            name: "All Records",
            end: "2022-01-01",
            start: "2000-01-01",
            monitor: "All Users",
            type: "Any",
        });
    }

    return (
        <>
            {loading && (
                <Grid container item xs={12} alignItems="center" justify="center" style={{ height: "100%" }}>
                    <CircularProgress />
                </Grid>
            )}

            {!loading && (
                <Dialog open={props.open} onClose={props.handleClose} maxWidth={"md"}>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={11} className={classes.searchContainer}>
                                <Typography className={classes.searchResultsText}>Search Results</Typography>
                                <Typography className={classes.resultsFoundText}>
                                    {props.resultsFound ? props.resultsFound : 0} records found
                                </Typography>
                            </Grid>{" "}
                            <Grid item container justify="flex-end" xs={1} className={classes.iconButtonContainer}>
                                <IconButton
                                    aria-label="cancel"
                                    onClick={() => props.handleClose()}
                                    className={classes.iconButton}
                                >
                                    <CancelIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12} md={6} style={{ marginTop: "24px" }}>
                                <Typography className={classes.eventText}>Event Name</Typography>
                                <Autocomplete
                                    id="name"
                                    style={{ padding: "12px" }}
                                    value={search}
                                    options={props.recordNames}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) => option.name === value.name}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            setSearch({ ...search, name: newValue.name });
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} className={classes.selectContainer}>
                                <Typography className={classes.eventText}>Record Status</Typography>{" "}
                                <div style={{ width: "100%" }}>
                                    {props.recordType === "monitoring" && (
                                        <Select
                                            name="type"
                                            value={search.type || "Any"}
                                            onChange={handleChange}
                                            variant="outlined"
                                            className={classes.select}
                                        >
                                            <MenuItem value={"Any"}>Any Status</MenuItem>
                                            <MenuItem value={"COMPLETE"}>Completed</MenuItem>
                                            <MenuItem value={"INCOMPLETE"}>Incomplete</MenuItem>
                                            <MenuItem value={"OVERDUE"}>Overdue</MenuItem>
                                        </Select>
                                    )}
                                    {props.recordType !== "monitoring" && (
                                        <Select
                                            name="type"
                                            value={search.type || "Any"}
                                            onChange={handleChange}
                                            variant="outlined"
                                            className={classes.select}
                                        >
                                            <MenuItem value={"Any"}>Any Status</MenuItem>
                                            <MenuItem value={"COMPLETE"}>Completed</MenuItem>
                                            <MenuItem value={"INCOMPLETE"}>Incomplete</MenuItem>
                                        </Select>
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} style={{ marginTop: "24px" }}>
                                <Typography className={classes.eventText}>Start Date (Optional)</Typography>
                                <TextField
                                    style={{ padding: "12px" }}
                                    id="start"
                                    name="start"
                                    disabled={props.readOnly}
                                    classes={{root: classes.textfieldbackground}}
                                    value={search.start ? search.start : ""}
                                    type="date"
                                    variant="outlined"
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ marginTop: "24px" }}>
                                <Typography className={classes.eventText}>End Date (Optional)</Typography>
                                <TextField
                                    id="end"
                                    style={{ padding: "12px" }}
                                    name="end"
                                    disabled={props.readOnly}
                                    className={classes.textfieldbackground}
                                    value={search.end ? search.end : ""}
                                    type="date"
                                    variant="outlined"
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            {props.allUsers && (
                                <Grid item xs={12} md={6} className={classes.selectContainer}>
                                    <Typography className={classes.eventText}>Assigned Monitor</Typography>
                                    {props.recordType !== "verification" && (
                                        <Select
                                            style={{ margin: "12px" }}
                                            name="monitor"
                                            value={search.monitor || ""}
                                            onChange={handleChange}
                                            variant="outlined"
                                            fullWidth
                                        >
                                            <MenuItem value={"All Users"}>All Users</MenuItem>
                                            {props.allUsers.map((user) => {
                                                return (
                                                    <MenuItem name="monitor" value={user.pk} key={user.pk}>
                                                        {user.email}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    )}
                                    {props.recordType === "verification" && (
                                        <Select
                                            style={{ margin: "12px" }}
                                            name="monitor"
                                            value={search.monitor || ""}
                                            onChange={handleChange}
                                            variant="outlined"
                                            fullWidth
                                        >
                                            <MenuItem value={"All Users"}>All Users</MenuItem>
                                            {props.allUsers.map((user) => {
                                                return (
                                                    <MenuItem name="monitor" value={user.pk} key={user.pk}>
                                                        {user.email}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    )}
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <div style={{ float: "right" }}>
                                    <Button
                                        variant="text"
                                        color="secondary"
                                        style={{ margin: "8px" }}
                                        onClick={clearSearch}
                                    >
                                        Clear Search
                                    </Button>{" "}
                                    <Button variant="text" color="secondary" style={{ margin: "8px" }} onClick={cancel}>
                                        Cancel
                                    </Button>{" "}
                                    <Button
                                        disabled={!search.monitor || !search.name}
                                        variant="contained"
                                        color="primary"
                                        className={classes.submitButton}
                                        onClick={save}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
}
