import React, { useEffect, useState } from 'react';
import { makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PropTypes from "prop-types";
import {StylesContext} from "../../App";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '95%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  cell: {
    border: '1px solid black',
    padding: '5px',
  },
  select: {
    width: '100%',
  },
}));
  
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function DetailCell(props) {
  //const classes = useStyles();
  const classes = React.useContext(StylesContext);

  const [detailChoices, setDetailChoices] = useState(false);

  useEffect(() => {
    if(!props.colInfo.choices || props.colInfo.type != 'detailAuto') {
      return;
    }

    if(!props.rowData || !props.rowData[props.colInfo.key]) {
      return;
    }

    let detailKeys = props.rowData[props.colInfo.key].map((detail) => {
      return detail[props.colInfo.detailKey];
    });

    let validChoices = props.colInfo.choices.reduce((result, choice) => {
      if(detailKeys.indexOf(choice[props.colInfo.choiceKey]) != -1) {
        result.push(choice);
      }
      return result;
    }, []);

    setDetailChoices(validChoices);

  }, [props.colInfo.choices])

  function handleChange(event)  {

    props.setRowData({...props.rowData, [props.colInfo.key]: event.target.value});
  }

  function handleAutoCompleteChange(newKey, newValue) {

    let detailKeys = props.rowData[props.colInfo.key].map((detail) => {
      return detail[props.colInfo.detailKey];
    });

    let badChoice = false;

    let choiceKeys = newValue.map((row) => {
      if(detailKeys.indexOf(row[props.colInfo.choiceKey]) == -1) {
        badChoice = true;
      }
      return row[props.colInfo.choiceKey];
    });

    let newDetails = props.rowData[props.colInfo.key].map((detail) => {
      if(choiceKeys.indexOf(detail[props.colInfo.detailKey]) != -1) {
        return {...detail, detailSelected: true,}
      }
      else {
        return {...detail, detailSelected: false,}
      }
    })

    if(!badChoice || newValue.length === 0) {
      var newRowData = {...props.rowData, [props.colInfo.cacheKey]: newValue, [props.colInfo.key]: newDetails,};
    }
    else {
      alert(props.colInfo.errorMessage);
      var newRowData = {...props.rowData};
    }
    props.setRowData(newRowData)
  }
  
  return (
    <>
      {props.colInfo.type == 'display' && !props.colInfo.hidden &&
        <Grid item xs className={classes.detailCell}>
          <Typography noWrap className={classes.detailDisplayField}>
            {props.rowData[props.colInfo.key]}
          </Typography>
        </Grid>
      }
      {props.colInfo.type == 'text' && !props.colInfo.hidden &&
        <Grid item xs className={classes.detailCell}>
          <TextField
            name={props.colInfo.key}
            label={props.colInfo.title}
            error={props.colInfo.validate ? props.colInfo.validate(props.rowData[props.colInfo.key]) : false}
            fullWidth
            value={props.rowData[props.colInfo.key] || ''}
            onChange={handleChange}
            disabled={props.disabled || props.colInfo.disabled}
          />
        </Grid>
      }
      {props.colInfo.type == 'numeric' && !props.colInfo.hidden &&
        <Grid item xs className={classes.detailCell}>
          <TextField
            name={props.colInfo.key}
            label={props.colInfo.title}
            type="number"
            error={props.colInfo.validate ? props.colInfo.validate(props.rowData[props.colInfo.key]) : false}
            fullWidth
            value={props.rowData[props.colInfo.key]}
            onChange={handleChange}
            disabled={props.disabled  || props.colInfo.disabled}
          />
        </Grid>
      }
      {props.colInfo.type == 'detail' && !props.colInfo.hidden &&
        <Grid item xs className={classes.detailCell}>
          <Typography>
            {(props.colInfo.disabled ? '' : 'Add ') + props.colInfo.title}
          </Typography>
          {!props.colInfo.disabled && 
            <IconButton disabled={props.disabled} color="primary" aria-label="open add modal" component="span" onClick={() => {props.onDetailClick(props.rowData, props.setLineData, props.setRowData)} }>
              <AddCircleIcon fontSize={'large'} />
            </IconButton>
          }
        </Grid>
      }
      {props.colInfo.type == 'detailAuto' && !props.colInfo.hidden &&
        <Grid item xs className={classes.detailCell}>
          <Autocomplete
            multiple
            onChange = {(emptyEvent, newValue) => {handleAutoCompleteChange(props.colInfo.cacheKey, newValue)}}
            id="tags-standard"
            options={detailChoices}
            getOptionLabel={(option) => JSON.stringify(option[props.colInfo.choiceLabelKey])}
            noOptionsText={props.colInfo.noOptionsText || 'No Options'}
            value={props.rowData[props.colInfo.cacheKey]}
            error={props.colInfo.validate ? props.colInfo.validate(props.rowData[props.colInfo.cacheKey]) : false}
            disabled={props.disabled || props.colInfo.disabled}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={props.colInfo.title}
                error={props.colInfo.validate ? props.colInfo.validate(props.rowData[props.colInfo.cacheKey]) : false}
                disabled={props.disabled || props.colInfo.disabled}
              />
            )}
          />
        </Grid>
      }
      {props.colInfo.type == 'select' &&
        <Grid item xs className={classes.detailCell}>
          <FormControl className={classes.detailFormControl}>
            <InputLabel id={props.colInfo.key}>{props.colInfo.title}</InputLabel>
            <Select
              labelId={props.colInfo.key}
              id={props.colInfo.key}
              name={props.colInfo.key}
              disabled={props.colInfo.disabled}
              className={classes.detailSelect}
              fullWidth
              error={props.colInfo.validate ? props.colInfo.validate(props.rowData[props.colInfo.key]) : false}
              value={props.rowData[props.colInfo.key]}
              onChange={handleChange}
              disabled={props.disabled || props.colInfo.disabled}
            >
              {Object.keys(props.colInfo.choices).map((choiceKey) => (
                <MenuItem key={choiceKey} value={choiceKey}>
                  {props.colInfo.choices[choiceKey]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      }
      {/*props.colInfo.type == 'selectRelative' && Unfinished for now, shouldn't be hard to implement if needed.
        <Grid item xs className={classes.cell}>
          <FormControl className={classes.formControl}>
            <InputLabel id={props.colInfo.key}>{props.colInfo.title}</InputLabel>
            <Select
              labelId={props.colInfo.key}
              id={props.colInfo.key}
              name={props.colInfo.key}
              disabled={props.colInfo.disabled}
              //multiple
              className={classes.select}
              fullWidth
              error={props.colInfo.validate ? props.colInfo.validate(props.rowData[props.colInfo.key]) : false}
              value={props.rowData[props.colInfo.key]}
              onChange={handleChange}
              disabled={props.disabled || props.colInfo.disabled}
            >
              {Object.keys(props.rowData['skus']['unit_choices']).map((choiceKey) => (
                <MenuItem key={choiceKey} value={choiceKey}>
                  {props.rowData['skus']['unit_choices'][choiceKey]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      */}
      {props.colInfo.type == 'multiselect' &&
        <Grid item xs className={classes.detailCell}>
          <FormControl className={classes.detailFormControl}>
            <InputLabel id="demo-mutiple-chip-label">{props.colInfo.title}</InputLabel>
            <Select
              labelId="demo-mutiple-chip-label"
              id={props.colInfo.key}
              name={props.colInfo.key}
              multiple
              className={classes.detailSelect}
              fullWidth
              value={props.rowData[props.colInfo.key]}
              onChange={handleChange}
              error={props.colInfo.validate ? props.colInfo.validate(props.rowData[props.colInfo.key]) : false}
              disabled={props.disabled || props.colInfo.disabled}
              input={<Input id="select-multiple-chip" disabled={props.disabled} />}
              renderValue={(selected) => (
                <div className={classes.detailChips}>
                  {selected.map((value) => (
                    <Chip key={value} label={props.colInfo.choices[value]} className={classes.detailChip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {Object.keys(props.colInfo.choices).map((choiceKey) => (
                <MenuItem key={choiceKey} value={choiceKey}>
                  {props.colInfo.choices[choiceKey]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      }
    </>
  )
}

DetailCell.propTypes = {
  rowData: PropTypes.object,
  setRowData: PropTypes.func,
  setLineData: PropTypes.func,
  colInfo: PropTypes.object,
  onDetailClick: PropTypes.func,
  disabled: PropTypes.bool,
  faded: PropTypes.bool,
}