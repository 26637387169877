import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import API from "Api/Api";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import useDesktop from "../../hooks/useDesktop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  gridcontainer: {
    padding: "32px",
  },
  nestedcontainer: {
    padding: 0,
    paddingTop: 20,
    paddingLeft: 12,
  },

  griditem: {
    marginBottom: "0px",
  },

  formHeaderText: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "600",
  },

  formAnswerText: {
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: "400",
  },

  outlinedbutton: {
    color: "#18bff6",
  },

  textfieldbackground: {
    background: "#fafafc",
  },

  gridcontainer: {
    paddingTop: "100px",
    padding: "10px",
    height: "80%",
    width: "100%",
    overflowY: "hidden",
    marginBottom: "100px",
  },

  defaultcard: { height: "248px", width: "248px", backgroundColor: "#ffffff" },

  defaultcardselected: {
    height: "248px",
    width: "248px",
    backgroundColor: "#ffffff",
    borderColor: "#18bff6",
    "& .MuiPaper-rounded": {
      borderStyle: "solid",
      borderColor: "#18bff6",
    },
  },

  defaultnocard: {
    height: "248px",
    width: "248px",
    backgroundColor: "#ffffff",
  },

  cardactions: { justifyContent: "center", paddingTop: 40 },

  cardactionswithcheck: { justifyContent: "center", paddingTop: 65 },

  header: { fontSize: "34px" },

  cardsymbol: { paddingBottom: 12 },

  gridcontainer: {
    padding: "40px",
    paddingTop: "64px",
    paddingBottom: "64px",
    marginBottom: "100px",
  },

  outlinedbutton: {
    color: "#18bff6",
  },

  textfieldbackground: {
    background: "#fafafc",
  },

  gridcontainer: {
    padding: "40px",
    paddingTop: "64px",
    paddingBottom: "64px",
    marginBottom: "100px",
  },

  dropzone: {
    paddingTop: "12px",
    paddingBottom: "12px",
    width: "100%",
  },

  outlinedbutton: {
    color: "#18bff6",
  },

  textfieldbackground: {
    background: "#fafafc",
  },

  signaturebutton: {
    paddingTop: 6,
  },

  canvaspaper: {
    width: "100%",
    height: 200,
  },
}));

function InfoCard({children, ...rest}) {

  const onDesktop = useDesktop();

  return (
    <Paper
      {...rest}
      elevation={0}
      style={{ 
        padding: "32px", 
        margin: onDesktop ? "32px" : "0px", 
        width: '100%', 
        maxWidth: "1200px" 
      }}
    >
      {children}
    </Paper>
  )
}

function CompletedDeviationForm(props) {
  const [loading, setLoading] = useState(true);
  const [deviationForm, setDeviationForm] = useState(props.deviationForm);

  const [step2, setStep2] = useState(false);

  const classes = useStyles();
  const api = new API();
  const params = useParams();

  useEffect(() => {
    let pk = props.pk || params.pk;

    if (deviationForm != null) {
      if (deviationForm.signature) {
        setSignature(deviationForm.signature);
      }

      setLoading(false);

      return;
    }

    if (pk) {
      api.getDeviationAPI()
        .getDeviation(pk)
        .then((response) => {
          setDeviationForm(response.data);
          if (response.data.time_submitted !== "---") {
            setSignature(response.data.signature);
          }
          
          if (response.data.date_of_occurance) {
            setFormattedDate(response.data.date_of_occurance);
          }

          if (response.data.process_steps_involved) {
            setStep2(true);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    
  }, [props.pk, params.pk]);

  const [formattedDate, setFormattedDate] = useState(null);
  const [signature, setSignature] = useState(null);
  const onDesktop = useDesktop();

  return (
    <Grid container justify="center" style={{ width: "100%" }}>
      {loading && (
        <Grid
          container
          item
          justify="center"
          alignItems="center"
          style={{ marginTop: "64px", width: "100%", height: "100%" }}
        >
          <CircularProgress />
        </Grid>
      )}
      {!loading && (
        <Grid container item justify="center" xs={12}>
          <InfoCard>
            <Grid container style={{ width: "100%" }}>
              <Grid item container xs={12} spacing={3} alignItems="center" style={{ marginBottom: "24px" }}>
                <Grid item xs={12}>
                  <Typography variant="h5">Deviation Details</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.formHeaderText}>
                    Form ID 
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.griditem} style={{ backgroundColor: "#FAFAFC", margin: "4px" }}>
                  <Typography className={classes.formAnswerText}>
                    {"#" + deviationForm.form_num} 
                  </Typography>
                </Grid>
                <Grid item container xs={12} className={classes.griditem}>
                  <Typography variant="h6" className={classes.formHeaderText}>
                    Description of Deviation
                  </Typography>
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  className={classes.griditem}
                  style={{ backgroundColor: "#FAFAFC", margin: "4px" }}
                >
                  <Typography className={classes.formAnswerText}>
                    {deviationForm ? deviationForm.description_of_deviation : ""}
                  </Typography>
                </Grid>

                <Grid item container xs={12} className={classes.griditem}>
                  <Typography variant="h6" className={classes.formHeaderText}>
                    Date/Time of Deviation
                  </Typography>
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  className={classes.griditem}
                  style={{ backgroundColor: "#FAFAFC", margin: "4px" }}
                >
                  {formattedDate && (
                    <Typography className={classes.formAnswerText}>{formattedDate}</Typography>
                  )}
                </Grid>

                <Grid item container xs={12} className={classes.griditem}>
                  <Typography variant="h6" className={classes.formHeaderText}>
                    Location of Deviation
                  </Typography>
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  className={classes.griditem}
                  style={{ backgroundColor: "#FAFAFC", margin: "4px" }}
                >
                  <Typography className={classes.formAnswerText}>
                    {deviationForm ? deviationForm.where_deviation_occured : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </InfoCard>
        </Grid>
      )}
      {!loading && (
        <Grid container item xs={12} justify="center">
          <InfoCard>
            <Grid container>
              <Grid item container spacing={3} xs={12} style={{ marginBottom: "24px" }}>
                <Grid item container xs={12} alignItems="center">
                  <Typography variant="h5">Root Cause Analysis</Typography>
                </Grid>
                <Grid item container xs={12} alignItems="center" style={{ paddingTop: "16px" }}>
                  <Typography className={classes.formHeaderText}>
                    5-Whys analysis to determine the root cause of the deviation.
                  </Typography>
                </Grid>
                <Grid item container xs={12} alignItems="center">
                  <Typography variant="h6" className={classes.formHeaderText}>
                    Why?
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  style={{
                    backgroundColor: "#FAFAFC",
                  }}
                >
                  <Typography className={classes.formAnswerText}>
                    {deviationForm ? deviationForm.why_0 : ""}
                  </Typography>
                </Grid>
                {deviationForm.why_1 && (
                  <Grid container spacing={3} className={classes.nestedcontainer}>
                    <Grid item container xs={12} alignItems="center">
                      <Typography variant="h6" className={classes.formHeaderText}>
                        Why?
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      xs={12}
                      style={{
                        backgroundColor: "#FAFAFC",
                      }}
                    >
                      <Typography className={classes.formAnswerText}>
                        {deviationForm ? deviationForm.why_1 : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {deviationForm.why_2 && (
                  <Grid container spacing={3} className={classes.nestedcontainer}>
                    <Grid item container xs={12} alignItems="center">
                      <Typography variant="h6" className={classes.formHeaderText}>
                        Why?
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      xs={12}
                      style={{
                        backgroundColor: "#FAFAFC",
                      }}
                    >
                      <Typography className={classes.formAnswerText}>
                        {deviationForm ? deviationForm.why_2 : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {deviationForm.why_3 && (
                  <Grid container spacing={3} className={classes.nestedcontainer}>
                    <Grid item container xs={12} alignItems="center">
                      <Typography variant="h6" className={classes.formHeaderText}>
                        Why?
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      xs={12}
                      style={{ backgroundColor: "#FAFAFC" }}
                    >
                      <Typography className={classes.formAnswerText}>
                        {deviationForm ? deviationForm.why_3 : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {deviationForm.why_4 && (
                  <Grid container spacing={3} className={classes.nestedcontainer}>
                    <Grid item container xs={12} alignItems="center">
                      <Typography variant="h6" className={classes.formHeaderText}>
                        Why?
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      xs={12}
                      style={{ backgroundColor: "#FAFAFC" }}
                    >
                      <Typography className={classes.formAnswerText}>
                        {deviationForm ? deviationForm.why_4 : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </InfoCard>
        </Grid>
      )}
      {!loading && (
        <Grid container item xs={12} justify="center">
          <InfoCard>
            <Grid container spacing={3} style={{ marginTop: "8px" }}>
              <Grid item container xs={12} alignItems="center">
                <Typography variant="h5">Was Food Safety Compromised?</Typography>
              </Grid>
              <Grid item container xs={12} style={{ marginBottom: "8px" }}>
                {deviationForm.safety_compromised && (
                  <Alert severity="error" style={{ width: "100%" }}>
                    Yes
                  </Alert>
                )}
                {!deviationForm.safety_compromised && (
                  <Alert severity="success" style={{ width: "100%" }}>
                    No
                  </Alert>
                )}
              </Grid>
            </Grid>
          </InfoCard>
        </Grid>
      )}
      {step2 && !loading && (<>
        <Grid container item justify="center" xs={12}>
          <InfoCard>
            <Grid container spacing={3} style={{ marginBottom: "24px" }}>
              <Grid item container xs={12} alignItems="center">
                <Typography variant="h5">Hazard Analysis</Typography>
              </Grid>

              <Grid item container xs={12} className={classes.griditem}>
                <Typography variant="h6" className={classes.formHeaderText}>
                  Description of Safety Hazard
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                className={classes.griditem}
                style={{ backgroundColor: "#FAFAFC" }}
              >
                <Typography className={classes.formAnswerText}>
                  {deviationForm ? deviationForm.food_safety_hazard_description : ""}
                </Typography>
              </Grid>

              <Grid item container xs={12} className={classes.griditem}>
                <Typography variant="h6" className={classes.formHeaderText}>
                  Hazard Type
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                className={classes.griditem}
                style={{ backgroundColor: "#FAFAFC" }}
              >
                <Typography className={classes.formAnswerText}>
                  {deviationForm && deviationForm.hazard_type ? deviationForm.hazard_type.capitalize() : ""}
                </Typography>
              </Grid>

              <Grid item container xs={12} alignItems="center">
                <Typography variant="h6" className={classes.formHeaderText}>
                  Process Steps Involved
                </Typography>
              </Grid>

              <Grid item container xs={12} style={{ backgroundColor: "#FAFAFC" }}>
                <Typography className={classes.formAnswerText}>
                  {deviationForm ? deviationForm.process_steps_involved : ""}
                </Typography>
              </Grid>
            </Grid>
          </InfoCard>
        </Grid>
        <Grid container item xs={12} justify="center">
          <InfoCard>
            <Grid item container spacing={3} xs={12} style={{ marginBottom: "24px" }}>
              <Grid item container xs={12} alignItems="center" style={{ marginTop: "24px" }}>
                <Typography variant="h5">Product Analysis</Typography>
              </Grid>

              <Grid item container xs={12}>
                <Typography variant="h6" className={classes.formHeaderText}>
                  Indentification of Product (Including Name, Type, Amount, and Weight/Volume)
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                className={classes.griditem}
                style={{ backgroundColor: "#FAFAFC" }}
              >
                <Typography className={classes.formAnswerText}>
                  {deviationForm ? deviationForm.identification_of_product : ""}
                </Typography>
              </Grid>

              <Grid item container xs={12}>
                <Typography variant="h6" className={classes.formHeaderText}>
                  Description of Product Holding (Including How and Where)
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                className={classes.griditem}
                style={{ backgroundColor: "#FAFAFC" }}
              >
                <Typography className={classes.formAnswerText}>
                  {deviationForm ? deviationForm.description_of_product_holding : ""}
                </Typography>
              </Grid>

              <Grid item container xs={12}>
                <Typography variant="h6" className={classes.formHeaderText}>
                  Description of Product Analysis
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                className={classes.griditem}
                style={{ backgroundColor: "#FAFAFC" }}
              >
                <Typography className={classes.formAnswerText}>
                  {deviationForm ? deviationForm.description_of_product_analysis : ""}
                </Typography>
              </Grid>

              <Grid item container xs={12}>
                <Typography variant="h6" className={classes.formHeaderText}>
                  Results of Product Analysis
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                className={classes.griditem}
                style={{ backgroundColor: "#FAFAFC" }}
              >
                <Typography className={classes.formAnswerText}>
                  {deviationForm ? deviationForm.results_of_product_analysis : ""}
                </Typography>
              </Grid>
            </Grid>
          </InfoCard>
        </Grid>
        <Grid item container xs={12} justify="center">
          <InfoCard>
            <Grid item container spacing={3} xs={12} style={{ marginBottom: "24px" }}>
              <Grid item container xs={12} alignItems="center" style={{ marginTop: "24px" }}>
                <Typography variant="h5">Product Disposal</Typography>
              </Grid>

              <Grid item container xs={12}>
                <Typography variant="h6" className={classes.formHeaderText}>
                  Description of Disposal Method
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                className={classes.griditem}
                style={{ backgroundColor: "#FAFAFC" }}
              >
                <Typography className={classes.formAnswerText}>
                  {deviationForm ? deviationForm.description_of_disposal_method : ""}
                </Typography>
              </Grid>

              <Grid item container xs={12}>
                <Typography className={classes.formHeaderText}>Date of Disposal</Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                className={classes.griditem}
                style={{ backgroundColor: "#FAFAFC" }}
              >
                <Typography className={classes.formAnswerText}>
                  {deviationForm ? deviationForm.date_of_disposal : ""}
                </Typography>
              </Grid>
            </Grid>
          </InfoCard>
        </Grid>
        <Grid container item xs={12} justify="center">
          <InfoCard>
            <Grid item container spacing={3} xs={12} style={{ marginBottom: "24px" }}>
              <Grid item container xs={12} alignItems="center" style={{ marginTop: "24px" }}>
                <Typography variant="h5">Additional Cross-Contamination (Process)</Typography>
              </Grid>

              <Grid item container xs={12} alignItems="center">
                <Typography variant="h6" className={classes.formHeaderText}>
                  Scope of Impact
                </Typography>
              </Grid>

              <Grid item container xs={12} style={{ backgroundColor: "#FAFAFC" }}>
                <Typography className={classes.formAnswerText}>
                  {deviationForm ? deviationForm.scope_of_impact : ""}
                </Typography>
              </Grid>

              <Grid item container xs={12} alignItems="center">
                <Typography variant="h6" className={classes.formHeaderText}>
                  Product Holding Description
                </Typography>
              </Grid>

              <Grid item container xs={12} style={{ backgroundColor: "#FAFAFC" }}>
                <Typography className={classes.formAnswerText} style={{ backgroundColor: "#FAFAFC" }}>
                  {deviationForm ? deviationForm.product_holding_description : ""}
                </Typography>
              </Grid>

              <Grid item container xs={12} sm={6}>
                <Typography variant="h6" className={classes.formHeaderText}>
                  Additional Product Analysis
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                className={classes.griditem}
                style={{ backgroundColor: "#FAFAFC" }}
              >
                <Typography className={classes.formAnswerText}>
                  {deviationForm ? deviationForm.additional_product_analysis : ""}
                </Typography>
              </Grid>

              <Grid item container xs={12} sm={6}>
                <Typography variant="h6" className={classes.formHeaderText}>
                  Additional Product Analysis Results
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                className={classes.griditem}
                style={{ backgroundColor: "#FAFAFC" }}
              >
                <Typography className={classes.formAnswerText}>
                  {deviationForm ? deviationForm.additional_product_analysis_results : ""}
                </Typography>
              </Grid>
            </Grid>
          </InfoCard>
        </Grid>
      </>)}

      {!loading && (
      <Grid container item xs={12} justify="center">
        <Paper
          elevation={0}
          style={{
            padding: "32px",
            paddingTop: "4px",
            margin: onDesktop ? "32px" : "0px",
            width: '100%',
            maxWidth: "1200px",
          }}
        >
          <Grid container spacing={3} style={{ marginBottom: "24px", marginTop: "24px" }}>
            <Grid item container xs={12} alignItems="center">
              <Typography variant="h5">Corrective Action Plan</Typography>
            </Grid>

            <Grid item container xs={12} alignItems="center">
              <Typography variant="h6">Description of Corrective Actions</Typography>
            </Grid>

            <Grid item container xs={12} style={{ backgroundColor: "#FAFAFC" }}>
              <Typography className={classes.formAnswerText}>
                {deviationForm ? deviationForm.description_of_corrective_actions : ""}
              </Typography>
            </Grid>

            <Grid item container xs={12}>
              <Typography variant="h6">Due Date</Typography>
            </Grid>

            <Grid
              item
              container
              xs={12}
              className={classes.griditem}
              style={{ backgroundColor: "#FAFAFC" }}
            >
              <Typography className={classes.formAnswerText}>
                {deviationForm ? deviationForm.due_date : ""}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      )}
      {!loading && (
      <Grid container item xs={12} justify="center">
        <InfoCard>
          <Grid item container xs={12} alignItems="center">
            <Typography variant="h5">Signature</Typography>
          </Grid>

          {signature && (
            <div style={{ margin: "16px" }}>
              <img src={signature} />
            </div>
          )}
        </InfoCard>
      </Grid>
      )}
    </Grid>
  );
}

export default CompletedDeviationForm;
