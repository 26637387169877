import React, {useEffect, useState} from "react"
import {DialogContent} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Hazard from "../../Hazards/Hazard";

function EditHazardTemplateModal(props) {

  function save(event) {
  }

  function saveAndContinue(event) {
    props.handleModalClosed();
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleModalClosed}
      >
        <DialogContent>
          <Hazard templateMode={true} save={save} saveAndContinue={saveAndContinue} pk={props.hazard_pk}/>
        </DialogContent>
      </Dialog>
    </div>
  )
}


export default EditHazardTemplateModal;
