import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";

import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Slide from '@material-ui/core/Slide';

import IconButton from "@material-ui/core/IconButton";
import CancelIcon from '@material-ui/icons/Cancel';

import API from "../../Api/Api"
import { toolbarOptions } from "../../config" 

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const standardDefault = {
  bullet: "",
  title: "",
  requirement: "",
};

export default function StandardEditor(props) {
  const [standard, setStandard] = useState({ ...standardDefault });

  function handleQuillChange(content, delta, source, editor) {
    setStandard({ ...standard, requirement: content });
  }

  function updateStandard() {
    props.standardUpdated({ ...standard });
  }

  function onChange(event) {
    setStandard({
      ...standard,
      [event.target.name]: event.target.value,
    });
  }

  function toggleModal(event) {
    if(!window.confirm('Are you sure you want to close this form? Any unsaved data will be lost.')) {
      return;
    }

    setStandard({ ...standardDefault });

    props.cancel();
  }

  useEffect(() => {
    if (props.standardSelected != null) {
      setStandard({ ...props.standardSelected });
    }
    else {
      setStandard({ ...standardDefault });
    }

  }, [props.standardSelected]);

  return (
    <Dialog open={props.open}
      fullScreen
      maxWidth="lg"
      fullWidth
      onClose={toggleModal}
      TransitionComponent={Transition}
    >
      <DialogTitle style={{ borderBottom: '1px solid #eaeaea', padding: '0px' }}>
        <Grid container item xs={12} style={{padding: '16px'}} alignItems="center">
          <Grid item container xs={8} alignItems="center" justify="flex-start">
            <Typography variant="h6">Standard</Typography>
          </Grid>
          <Grid item container xs={4} alignItems="center" justify="flex-end">
            <IconButton onClick={() => props.cancel()} style={{padding: '0px'}}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} style={{marginTop: '32px'}}>
          <Grid item xs={12}>
            <Typography variant="h6">
              Title
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField variant="outlined" fullWidth name="title" value={standard.title || ""} onChange={onChange} />
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <Typography variant="h6">
              Requirement
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ReactQuill
              theme="snow"
              value={standard.requirement}
              onChange={handleQuillChange}
              modules={{
                toolbar: toolbarOptions
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant={'contained'} color="secondary" onClick={toggleModal}>Cancel</Button>
        <Button variant={'contained'} color="primary" onClick={updateStandard}>Update</Button>
      </DialogActions>
    </Dialog>
  )
}
