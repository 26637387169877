class ChangelogAPI {
    constructor(api, version) {
      this.api = api;
      this.version = version;
      this.path = `${this.version}/changelog`
    }
  
    listChangelog() {
      return this.api.get(`${this.path}/`);
    }
  
    retrieveChangelog(pk) {
      return this.api.get(`${this.path}/${pk}/`);
    }
  
    mostRecentChangelog() {
        return this.api.get(`${this.path}/most_recent/`)
    }
  }
  
  export default ChangelogAPI;
  
