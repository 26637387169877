import React, {useEffect, useState } from "react";

import API from "../../Api/Api";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Message from "../../Components/Message";
import InventoryLocationForm from "./InventoryLocationForm";
import DialogContent from "@material-ui/core/DialogContent";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from "@material-ui/core/CircularProgress";
import TabbableGroupedTable from "../../Components/GroupedTable/TabbableGroupTable";
import Typography from "@material-ui/core/Typography";
import Traceability from "../Traceability";
import PropTypes from "prop-types";
import Slide from '@material-ui/core/Slide';
import TraceabilityUtils from "../TraceabilityUtils";
import {StylesContext} from "../../App";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//todo split this into 2 tables, make a serializer to get finished prod with stock by lot code
export default function InventoryLocationList(props) {
  const utils = new TraceabilityUtils();
  const classes = React.useContext(StylesContext);

  const [inventoryLocations, setInventoryLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inventoryLocationModalOpen, setInventoryLocationModalOpen] = useState(false);
  const [shouldClose, setShouldClose] = useState(true);
  const [inventoryLocationSelected, setInventoryLocationSelected] = useState(-1);
  const [user, setUser] = useState(null);
  const [message, setMessage] = useState( utils.emptyMessage() );
  const [toSubmit, setToSubmit] = useState(undefined);

  useEffect(() => {
    //this skips the initial render pulling the modal up.
    if(loading) {
      return;
    }

    if(!inventoryLocationModalOpen){
      setInventoryLocationModalOpen(true);
    }
  }, [inventoryLocationSelected])

  useEffect(() => {
    const api = new Traceability().getInventoryLocationAPI();
    const authApi = new API().getAuthAPI();
    authApi.getAuthedProfile().then(e => {
      setUser(e.data);
    }).catch(e => {
      //console.log(e);
    })

    if(props.inventoryLocations) {
      let inventoryLocationObjects = props.inventoryLocations;

      setInventoryLocations(inventoryLocationObjects);
      setLoading(false);
      return;
    }

    api.listInventoryLocations().then(response => {
      let inventoryLocationObjects = response.data;

      setInventoryLocations(inventoryLocationObjects);
      setLoading(false);
    }).catch(e => {
      //console.log(JSON.stringify(e));
    })

  }, []);

  const inventoryLocationColumns = [
    { title: "Name", field: "name" },
    { title: "Description", field: "description" },
  ];

  const tabStatuses = {
    counted:false,
    column: 'status',
    tabs:[
      {title: 'All', value: 'All'},
    ]
  }

  function addInventoryLocation() {
    //It needs to be different to register, so just flip it back and forth.
    setInventoryLocationSelected(inventoryLocationSelected == -1 ? -2 : -1);
  }

  function handleInventoryLocationClosed() {
    if (shouldClose) {
      setInventoryLocationModalOpen(false);
    } else {
      alert("Save changes before closing.")
    }
  }

  function closeModal() {
    if(!shouldClose) {
      if(!window.confirm("Are you sure you want to close without saving?")) {
        return;
      }
    }

    cancel();
  }

  function cancel() {
    setShouldClose(true);
    setInventoryLocationModalOpen(false);
  }

  function deleteInventoryLocation(invLocation, resolve, reject) {
    const api = new Traceability().getInventoryLocationAPI();

    api.deleteInventoryLocation(invLocation.pk).then(response => {
      let updatedInvLocations = [...inventoryLocations];
      let invLocationIndex = updatedInvLocations.findIndex(updatedInvLocation => {
        return updatedInvLocation.pk === invLocation.pk;
      });

      if (invLocationIndex > -1) {
        updatedInvLocations.splice(invLocationIndex, 1);
      }

      setMessage({
        open: true,
        message: 'Deleted Successfully',
        status: 'success',
      });

      setInventoryLocations(updatedInvLocations);
      resolve();
    }).catch(error => {
      //console.log(error);
      reject();
    })
  }

  /**
   * Handles keeping the list up to date with the back end.
   * 
   * @param {JSON} response - a response object from the server
   */
  function onSave(response) {
    let updatedLocationPk = response.data.pk;
    let updatedLocations = [...inventoryLocations];

    let locationIndex = updatedLocations.findIndex(location => {
      return location.pk === updatedLocationPk;
    });

    if (locationIndex > -1) {
      updatedLocations.splice(locationIndex, 1, response.data);
    }
    else {
      updatedLocations.push(response.data);
    }

    setInventoryLocations(updatedLocations);
    setMessage({
      open: true,
      message: 'Saved Successfully',
      status: 'success',
    });
  }

  return (
    <>
      { (loading || !inventoryLocations) &&
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginTop: "250px" }}>
            <Typography>
              <CircularProgress />
            </Typography>
          </Grid>
        </Grid>
      }

      { (!loading && inventoryLocations) && <>
        <Paper elevation={0} square className={classes.generalListPaperContainer}>
          <Grid container spacing={0} style={{padding: '0px'}}>
            <Grid item xs={12}>
              <TabbableGroupedTable
                tabStatuses={tabStatuses}

                isLoading={loading} 
                title="Inventory Locations" 
                columns={inventoryLocationColumns} 
                data={inventoryLocations}

                style={{ width: "100%" }}
                localization={{ body: { editRow: { deleteText: 'Are you sure you want to delete this Inventory Location? This action cannot be reversed.' } } }}

                editable={{
                  onRowDelete: (newData) => new Promise((resolve, reject) => {
                    deleteInventoryLocation(newData, resolve, reject)
                  })
                }}

                actions={
                  [
                    {
                      icon: (props) => (
                        <Button size="small" color="primary" variant="outlined" aria-label="add" className={classes.tabTableFreeAction}>
                          Add Inventory Location
                        </Button>
                      ),
                      tooltip: 'Add Inventory Location',
                      isFreeAction: true,
                      onClick: (event) => addInventoryLocation()
                    },
                    {
                      icon: (props) => (
                        <Button size="small" color="primary" variant="outlined" aria-label="add">
                          Edit
                        </Button>
                      ),
                      tooltip: 'Open Manager',
                      onClick: (event, rowData) => {
                        //console.log(JSON.stringify(rowData));
                        setInventoryLocationSelected(rowData.tableData.id);
      
                        if(inventoryLocationSelected != rowData.tableData.id) {
                          setInventoryLocationSelected(rowData.tableData.id);
                        }
                        else {
                          setInventoryLocationModalOpen(true);
                        }
                      }
                    }
                  ]
                }

                onRowClick={(event, rowData, togglePanel) => {
                  //console.log(JSON.stringify(rowData));
                  setInventoryLocationSelected(rowData.tableData.id);

                  if(inventoryLocationSelected != rowData.tableData.id) {
                    setInventoryLocationSelected(rowData.tableData.id);
                  }
                  else {
                    setInventoryLocationModalOpen(true);
                  }
                }}

                options={{
                  actionsColumnIndex: -1,
                  //exportButton: true,
                  pageSize: 10,
                }}

                icons={{
                  Export: MoreVertIcon,
                }}
              />

            </Grid>
          </Grid>
        </Paper>
        

        <Dialog fullScreen open={inventoryLocationModalOpen} onClose={handleInventoryLocationClosed} TransitionComponent={Transition}>
          <DialogContent className={classes.generalFormDialogueContainer}>
            <InventoryLocationForm
              //handleSubmit={handleSubmit}
              inventoryLocation={inventoryLocationSelected >= 0 ? inventoryLocations[inventoryLocationSelected] : {}}
              setShouldClose={setShouldClose}
              cancel={cancel}
              user={user}
              onSave={onSave}
              closeModal={closeModal}
              activeTraceability={props.activeTraceability}
              toSubmit={toSubmit}
              setToSubmit={setToSubmit}
            />
          </DialogContent>

          {/*<DialogActions className={classes.generalDialogueFoot}>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "8px" }}
              //todo whats a good way to get the actions from within the form up to here?
              //todo why does this trigger right away?
              onClick={() => { setToSubmit(false) }}
            >
              Save Changes
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "8px" }}
              onClick={() => { setToSubmit(true) }}
            >
              Save And Continue
            </Button>
              </DialogActions>*/}
        </Dialog>
        

        <Message
          open={message.open}
          message={message.message}
          severity={message.status}
          vertical="bottom"
          horizontal="left"
          handleClose={() => {setMessage( {...message, open:false} )}}
        />
      </>}
    </>
  )
}

InventoryLocationList.propTypes = {
  inventoryLocations: PropTypes.arrayOf(PropTypes.object),
  activeTraceability: PropTypes.object.isRequired,
}
