import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import API from "../../../Api/Api";
import { useHistory, useLocation } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import PropTypes from 'prop-types';

import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © FoodByte '}
            {/*<Link color="inherit" href="https://material-ui.com/">*/}
            {/*</Link>{' '}*/}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function NewUserInvite(props) {
    const classes = useStyles();
    const api = new API();
    const history = useHistory();
    const [userData, setUserData] = useState({first_name: "", last_name: "", password: "", confirm_password: "", pk: props.invite.pk, new_user: true });
    const [firstNameError, setFirstNameError] = useState(null);
    const [lastNameError, setLastNameError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);


    function onFieldChange(event){
      const name = event.target.name;
      if (event.target.name === "tos") {
          setUserData({
              ...userData,
              accept_tos: event.target.checked,
          })
      }
      else if (event.target.name === "privacy-policy") {
          setUserData({
              ...userData,
              accept_privacy_policy: event.target.checked,
          })
      }
      else {
        setUserData({
          ...userData,
          [name]: event.target.value,
      });
      }
    }

    function onSubmit()
    {
      api.getOrganizationInviteAPI().updateInvite(userData).then(() => {
        history.push("/login");

      }).catch(e => {
          console.log(e);
          setFirstNameError(e.response.data.first_name)
          setLastNameError(e.response.data.last_name)
          setPasswordError(e.response.data.password)
          setConfirmPasswordError(e.response.data.confirm_password)
      });
    }
    return (
                <form className={classes.form} noValidate>
                  <Typography component="h1" variant="h6">
                      Complete your account information to accept the invite
                  </Typography>
                    <TextField
                        error={firstNameError ? true : false}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="first_name"
                        label="First Name"
                        name="first_name"
                        autoComplete="first_name"
                        autoFocus
                        onChange={onFieldChange}
                        helperText={firstNameError}
                    />
                    <TextField
                        error={lastNameError ? true : false}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="last_name"
                        label="Last Name"
                        id="last_name"
                        autoComplete="last_name"
                        onChange={onFieldChange}
                        helperText={lastNameError}
                    />

                    <TextField
                    error={passwordError ? true : false}
                    type="password"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    id="password"
                    autoComplete="current-password"
                    onChange={onFieldChange}
                    helperText={passwordError}
                    />

                    <TextField
                      error={confirmPasswordError ? true : false}
                      type="password"
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="confirm_password"
                      label="Confirm Password"
                      id="confirm_password"
                      autoComplete="current-password"
                      onChange={onFieldChange}
                      helperText={confirmPasswordError}
                    />

                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox color="default" onChange={onFieldChange} name="privacy-policy" />}
                            label={<div>I understand and accept the <a href="https://www.joinfoodbyte.com/privacy-policy" target="_blank">Privacy Policy</a></div>}
                        />
                        <FormControlLabel
                            control={<Checkbox color="default" onChange={onFieldChange} name="tos" />}
                            label={<div>I understand and accept the <a href="https://www.joinfoodbyte.com/terms-services" target="_blank">Terms of Service</a></div>}
                        />
                    </FormGroup>

                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={!userData.accept_tos || !userData.accept_privacy_policy}
                        onClick={onSubmit}
                    >
                        Accept Invite
                    </Button>
                </form>
    );
}
