import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import API from "../../Api/Api";
import Message from "../../Components/Message";
import { Prompt } from "react-router";
import PropTypes from "prop-types";
import { StylesContext } from "../../App";
import Paper from "@material-ui/core/Paper";

import { useRouteMatch } from "react-router-dom";
import ContentHeader from "../../Components/Layouts/ContentHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import useDesktop from "../../hooks/useDesktop";

let confirmed = 0;

//todo what is wrapping this in a paper? Need to find it to apply the global style.
function ProductDescription(props) {
  let [isBlocking, setIsBlocking] = useState(false);
  let [loading, setLoading] = useState(true);
  const [productDescription, setProductDescription] = useState(
    props.productDescription ? props.productDescription : null
  );
  let api = new API();
  let params = useParams();
  let history = useHistory();
  let match = useRouteMatch();
  const classes = React.useContext(StylesContext);
  const onDesktop = useDesktop();

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState({
    message: "",
    status: "info",
  }); // error, warning, info, info
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function getProductDescriptionAPI() {
    if (props.templateMode) {
      return api.getProductDescriptionTemplateAPI();
    }

    return api.getProductDescriptionAPI();
  }

  useEffect(() => {
    let pk = props.pk || params.pk;
    confirmed = 0;

    if (pk) {
      getProductDescription(pk);
    }
  }, [props.pk, params.pk]);

  function getProductDescription(pk) {
    getProductDescriptionAPI()
      .retrieveProductDescription(pk)
      .then((e) => {
        setProductDescription(e.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function handleChange(event) {
    if (props.setIsBlocking) {
      props.setIsBlocking(true);
    }

    setIsBlocking(true);
    if (props.setIsBlocking) {
      props.setIsBlocking(true);
    }

    const property = event.target.name;
    const value = event.target.value;

    setProductDescription({ ...productDescription, [property]: value });
  }

  function handleSubmit(e) {
    setIsBlocking(false);

    if (props.setIsBlocking) {
      props.setIsBlocking(false);
    }

    e.preventDefault();

    updateProductDescription({...productDescription}, false);
  }

  function handleSaveAndContinue() {
    updateProductDescription(productDescription, true);
    setIsBlocking(false);
    if (props.setIsBlocking) {
      props.setIsBlocking(false);
    }
    //history.push(`${match.url}/formulation`);
  }

  function updateProductDescription(updatedProductDescription, check) {
    if (check) {
      setSAC(true);
    }

    getProductDescriptionAPI()
      .updateProductDescription({...productDescription})
      .then((e) => {
        setProductDescription(e.data);
        if (props.successMessage) {
          props.successMessage();
        } else {
          setReturnStatus("Updated product description!", "info");
          //history.push(`${match.url}/formulation`);
        }
      })
      .catch(() => {
        setReturnStatus("Could not update product description.", "error");
      });
  }

  function deleteProductDescription() {
    getProductDescriptionAPI()
      .deleteProductDescription(productDescription.pk)
      .then(() => {
        setProductDescription(null);

        if (props.deleteProductDescription) {
          props.deleteProductDescription();
        }

        setReturnStatus("Deleted product description!", "info");
      })
      .catch(() => {
        setReturnStatus("Could not delete product description.", "error");
      });
  }

  function createTemplate() {
    getProductDescriptionAPI()
      .createProductDescription(productDescription)
      .then(() => {
        setReturnStatus("Template created!", "info");
      })
      .catch(() => {
        setReturnStatus("Could not create template.", "error");
      });
  }

  function selectTemplate(template) {
    getProductDescriptionAPI()
      .selectTemplate(productDescription.pk, template)
      .then((e) => {
        setProductDescription(e.data);
        setReturnStatus("Template selected!", "info");
      })
      .catch((e) => {
        setReturnStatus("Could not select template.", "error");
        console.log(e);
      });
  }

  function getTemplates() {
    return api.getProductDescriptionTemplateAPI().listProductDescriptions();
  }

  function setReturnStatus(message, status) {
    setMessage({ message: message, status: status });
    setOpen(true);
  }

  window.onbeforeunload = function (e) {
    if (isBlocking) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  let [SAC, setSAC] = useState(false);
  useEffect(() => {
    if (SAC) {
      history.push(`${match.url}/formulation`);
    }
  }, [SAC]);

  return (
    <>
      <Prompt
        message={(location, action) => {
          //Prompt triggers on any nav event, in this case push and then replace
          //if any are false, the chain stops and resolves.
          //If one is true somewhere in the chain, the nav proceeds.
          //The goal of this function is to ignore all of that and have this behave like window.confirm

          //If they have already confirmed, or made no changes allow navigation
          if (!isBlocking || confirmed) {
            return true;
          }
          //If they haven't confirmed yet, or are are trying to nav again after previously declining, re-confirm
          else if (
            confirmed === 0 ||
            (action == "PUSH" && confirmed === false)
          ) {
            confirmed = window.confirm(
              "You have unsaved changes, are you sure you want to leave?"
            );
          }
          //If they've said no, and this still transitions to replace, reset confirmed and cancel nav
          else if (action == "REPLACE" && confirmed === false) {
            confirmed = 0;
            return false;
          }

          return confirmed;
        }}
      />
      {loading && (
        <CircularProgress
          style={{ position: "absolute", left: "50%", top: "50%" }}
        />
      )}

      {productDescription && (
        <div>
          {/* <ContentHeader title="Product Description" haccp="yes" /> */}
          <ContentHeader title="Product Description" />
          <Grid
            container
            spacing={2}
            className={classes.generalContainerGridProductDescription}
            style={{ padding: onDesktop === true ? "32px"  : "0px" }}
          >
            <Paper
              className={classes.generalFormPaperContainerProductDescription}
              elevation={0}
            >
              <Grid
                container
                spacing={2}
                className={classes.generalContainerGridProductDescription}
              >
                {/* <Grid item xs={11}></Grid> */}
                {/* <Grid item xs={1}>
              <VerticalDotMenu
                style={{
                  float: "right",
                }}
                getTemplates={getTemplates}
                createTemplate={createTemplate}
                selectTemplate={selectTemplate}
                //deleteInstance={deleteProductDescription}
                api={api.getProductDescriptionAPI()}
                instance={productDescription}
                setStatus={setReturnStatus}
                setProductDescription={setProductDescription}
                instanceName="Product Description"
                template_title="Product Description Templates"
                template_cols={[
                  {
                    title: "Product Name(s)",
                    field: "product_names",
                  },
                  {
                    title: "Product Characteristics",
                    field: "product_characteristics",
                  },
                  {
                    title: "Packaging",
                    field: "packaging",
                    editable: "never",
                  },
                  {
                    title: "Usage",
                    field: "usage",
                    editable: "never",
                  },
                  {
                    title: "Shelf Life",
                    field: "shelf_life",
                    editable: "never",
                  },
                  {
                    title: "Where Sold",
                    field: "where_sold",
                    editable: "never",
                  },
                  {
                    title: "Labelling Instructions",
                    field: "labelling_instructions",
                    editable: "never",
                  },
                  {
                    title: "Distribution Controls",
                    field: "distribution_controls",
                    editable: "never",
                  },
                ]}
              />
            </Grid> */}
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "21px",
                    }}
                  >
                    Product Name
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    style={{ backgroundColor: "#F8F8F8" }}
                    name="product_names"
                    placeholder="The common name for the product(s) covered by this HACCP plan"
                    variant="outlined"
                    fullWidth
                    disabled={props.readOnlyMode}
                    value={productDescription.product_names}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "21px",
                    }}
                  >
                    Source of Raw Material
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    style={{ backgroundColor: "#F8F8F8" }}
                    name="sources"
                    placeholder="Source of raw material"
                    variant="outlined"
                    fullWidth
                    disabled={props.readOnlyMode}
                    multiline
                    rows={3}
                    value={productDescription.sources}
                    onChange={handleChange}
                  />
                </Grid>

                {/* <Grid item xs={12}>
              <TextField
                name="product_characteristics"
                label="Important final product characteristics"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                value={
                  productDescription.product_characteristics
                }
                onChange={handleChange}
              />
            </Grid> */}
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "21px",
                    }}
                  >
                    Packaging
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    style={{ backgroundColor: "#F8F8F8" }}
                    name="packaging"
                    placeholder="Packaging"
                    variant="outlined"
                    fullWidth
                    disabled={props.readOnlyMode}
                    value={productDescription.packaging}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "21px",
                    }}
                  >
                    Intended Use of End Product
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    style={{ backgroundColor: "#F8F8F8" }}
                    name="usage"
                    placeholder="How the end product is to be used"
                    variant="outlined"
                    fullWidth
                    disabled={props.readOnlyMode}
                    value={productDescription.usage}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "21px",
                    }}
                  >
                    Shelf-Life of Product(s)
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    style={{ backgroundColor: "#F8F8F8" }}
                    name="shelf_life"
                    placeholder="Shelf life"
                    variant="outlined"
                    fullWidth
                    disabled={props.readOnlyMode}
                    value={productDescription.shelf_life}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "21px",
                    }}
                  >
                    Where will Product be Sold
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    style={{ backgroundColor: "#F8F8F8" }}
                    name="where_sold"
                    placeholder="Where the product will be sold"
                    variant="outlined"
                    fullWidth
                    disabled={props.readOnlyMode}
                    value={productDescription.where_sold}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "21px",
                    }}
                  >
                    Special Labelling Instructions
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    style={{ backgroundColor: "#F8F8F8" }}
                    name="labelling_instructions"
                    placeholder="Special labelling instructions"
                    fullWidth
                    disabled={props.readOnlyMode}
                    variant="outlined"
                    value={productDescription.labelling_instructions}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "21px",
                    }}
                  >
                    Special Distribution Control
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    style={{ backgroundColor: "#F8F8F8" }}
                    name="distribution_controls"
                    placeholder="Special distribution control"
                    variant="outlined"
                    fullWidth
                    disabled={props.readOnlyMode}
                    value={productDescription.distribution_controls}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid
                  container
                  spacing={2}
                  className={classes.generalContainerGridFoot}
                >
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={props.readOnlyMode}
                      style={{ margin: "8px", float: "right" }}
                      onClick={handleSaveAndContinue}
                    >
                      Save And Continue
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={props.readOnlyMode}
                      style={{ margin: "8px", float: "right" }}
                      onClick={handleSubmit}
                    >
                      Save Changes
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </div>
      )}
      <Message
        position="200px"
        open={open}
        message={message.message}
        severity={message.status}
        horizontal="right"
        handleClose={handleClose}
      />
    </>
  );
}

ProductDescription.propTypes = {
  productDescription: PropTypes.shape({
    pk: PropTypes.number,
    product_names: PropTypes.string,
    sources: PropTypes.string,
    product_characteristics: PropTypes.string,
    packaging: PropTypes.string,
    usage: PropTypes.string,
    shelf_life: PropTypes.string,
    where_sold: PropTypes.string,
    labelling_instructions: PropTypes.string,
    distribution_controls: PropTypes.string,
  }),
  hasButton: PropTypes.bool,
  templateMode: PropTypes.bool,
  successMessage: PropTypes.func,
  deleteProductDescription: PropTypes.func,
  setBlockNavigation: PropTypes.func,
};

export default ProductDescription;
