class CCPTemplateAPI {

  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listCCPTemplates() {
    return this.api.get(`${this.version}/ccp_templates/`)
  }

  createCCPTemplate(payload) {
    return this.api.post(`${this.version}/ccp_templates/`, payload);
  }

  retrieveCCPTemplate(pk) {
    return this.api.get(`${this.version}/ccp_templates/${pk}/`);
  }

  updateCCPTemplate(payload) {
    return this.api.put(`${this.version}/ccp_templates/${payload.pk}/`, payload);
  }

  deleteCCPTemplate(pk) {
    return this.api.delete(`${this.version}/ccp_templates/${pk}/`);
  }

  getHistory(pk) {
    return this.api.get(`${this.version}/ccp_templates/${pk}/get_history/`);
  }
}


export default CCPTemplateAPI;
