class ReceivingAPI {
  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listReceivingRecords(pk) {
    if (typeof pk === 'number') {
      return this.api.get(`${this.version}/receiving_record/${pk}/`);
    }

    return this.api.get(`${this.version}/receiving_record/`);
  }

  /* Currently this is done through orders.
  createReceiving(payload) {
    return this.api.post(`${this.version}/receiving_record/`, payload);
  }*/

  updateReceivingRecord(payload) {
    return this.api.put(`${this.version}/receiving_record/${payload.pk}/`, payload);
  }

  deleteReceivingRecord(pk) {
    return this.api.delete(`${this.version}/receiving_record/${pk}/`);
  }

}

export default ReceivingAPI;