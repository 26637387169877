import React, { useState, useEffect } from "react"
import { DialogContent } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import API from "../../Api/Api";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";

const DarkerDisabledTextField = withStyles({
    root: {
        marginRight: 8,
        "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.7)" // (default alpha is 0.38)
        }
    }
})(TextField);
function CCPModal(props) {

    let api = new API();

    function modelClose() {
        props.handleModalClosed();
    }

    function getAPI() {
        return api.getCrossContaminationPointAPI();
    }

    function save(CCP) {
        getAPI().updateCrossContaminationPoint(CCP).then(e => {
            props.setCCP(e.data);
            props.save(e.data);
            props.handleModalClosed();
            props.setStatus("Cross Contamination Point Updated!", "info");
        }).catch(e => {
            console.log("error", e);
        });
    }

    // function historyHasChanged(hasChanged) {
    //     props.setShouldClose(hasChanged);
    // }

    return (
        <Dialog
            maxWidth="md"
            open={props.open}
            onClose={props.handleModalClosed}
        >
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <form  style={{ padding: "16px" }}>
                            <Grid container style={{ padding: "16px" }} spacing={3}>
               
                                <Grid item xs={10}>
                                    <Typography variant="h5" noWrap>
                                        Old Cross Contamination Point
                                    </Typography>
                                        View this Cross Contamination Point.
                                </Grid>

                                <Grid item xs>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        disabled
                                        label="Name"
                                        name="name"
                                        variant="outlined"
                                        value={props.beforeCCP.name}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        disabled
                                        label="Description"
                                        name="description"
                                        variant="outlined"
                                        value={props.beforeCCP.description}
                                        multiline
                                        rows={3}
                                        fullWidth
                                    />
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <Typography variant="h6">Hazards</Typography>
                                    <Typography>
                                        Identify and edit hazards belonging to this
                                        Cross Contamination Point.
                                    </Typography>
                                </Grid> */}
                                {/* <Grid item xs={12}>
                                    <HazardList
                                        cross_contamination_point_pk={
                                            props.beforeCCP.pk
                                        }
                                    />
                                </Grid> */}
                            </Grid>
                        </form>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => save(props.beforeCCP)}
                        >
                            Revert
                        </Button>
                    </Grid>

                    <Grid item xs={6}>
                        <form style={{ padding: "16px" }}>
                            <Grid container style={{ padding: "16px" }} spacing={3}>
                                <Grid item xs={10}>
                                    <Typography variant="h5" noWrap>
                                        Current Contamination Point
                      
                                    </Typography>
                                        View this Cross Contamination Point.
                                </Grid>

                                <Grid item xs>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        disabled
                                        label="Name"
                                        name="name"
                                        variant="outlined"
                                        value={props.oldCCP.name}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DarkerDisabledTextField
                                        disabled
                                        label="Description"
                                        name="description"
                                        variant="outlined"
                                        value={props.oldCCP.description}
                                        multiline
                                        rows={3}
                                        fullWidth
                                    />
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <Typography variant="h6">Hazards</Typography>
                                    <Typography>
                                        Identify and edit hazards belonging to this
                                        Cross Contamination Point.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <HazardList
                                        cross_contamination_point_pk={
                                            props.oldCCP.pk
                                        }
                                    />
                                </Grid> */}
                            </Grid>
                        </form>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={modelClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>

        </Dialog >
    )
}
export default CCPModal;
