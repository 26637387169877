import React, { forwardRef, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Ingredient from "./Ingredient";
import MaterialTable from "material-table";
import API from "../../Api/Api";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Message from "../../Components/Message";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from "@material-ui/core/Typography";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const IngredientList = React.memo(function IngredientList(props) {
  const [loading, setLoading] = useState(true);
  const [ingredients, setIngredients] = useState(null);
  const [ingredientModalOpen, setIngredientModalOpen] = useState(false);
  const [shouldClose, setShouldClose] = useState(true);

  const api = new API();

  const theme = createMuiTheme({
    overrides: {
      MuiIconButton: {
        root: {
          '&.Mui-disabled': {
            color: "transparent",
          }
        }
      },
      MuiTableCell: {
        root: {
          padding: 14,
        }
      }
    }
  });

  useEffect(() => {
    if (props.formulation_pk) {
      getIngredients(props.formulation_pk);
    }
    else {
      setIngredients([]);
    }
  }, [props.haccpPlanPk, props.formulation_pk, props.templateSelected, props.ingredients]);

  function getIngredients(formulation_pk) {
    if(!formulation_pk) {
      setIngredients([]);
      return;
    }
    api.getIngredientsAPI().listIngredients(formulation_pk).then(e => {
      let newIngredients = e.data.map((ingredient) => {
        if(ingredient.unit_type && (ingredient.unit_value || ingredient.unit_value === 0) ){
          let updatedIngredient = {...ingredient};
          updatedIngredient.units = parseFloat(ingredient.unit_value).toFixed(3) + ' ' + props.units[ingredient.unit_type].abbreviation;
          return updatedIngredient;
        }
        else {
          return ingredient;
        }
      });

      setIngredients(newIngredients);

      if (props.setTemplateSelected) {
        props.setTemplateSelected(false);
      }
    }).catch(e => {
      console.log(e);
    })
  }

  function addIngredientToFormulation(ingredient) {
    api.getFormulationsAPI().addIngredientToFormulation(props.formulation_pk, ingredient).then(response => {
      let newIngredients = response.data.map((ingredient) => {
        if(ingredient.unit_type && (ingredient.unit_value || ingredient.unit_value === 0) ){
          let updatedIngredient = {...ingredient};
          updatedIngredient.units = parseFloat(ingredient.unit_value).toFixed(3) + ' ' + props.units[ingredient.unit_type].abbreviation;
          return updatedIngredient;
        }
        else {
          return ingredient;
        }
      });

      setIngredients(newIngredients);
    }).catch(error => {
      console.log(error);
    });
  }

  function deleteIngredient(ingredient, resolve, reject) {
    api.getIngredientsAPI().deleteIngredient(ingredient.pk).then(response => {
      let updatedIngredients = [...ingredients];
      let ingredientIndex = updatedIngredients.findIndex(updatedIngredient => {
        return updatedIngredient.pk === ingredient;
      });

      if (ingredientIndex > -1) {
        updatedIngredients.splice(ingredientIndex, 1);
      }

      setIngredients(updatedIngredients);
    }).catch(error => {
      console.log(error);
    });
  }

  let history = useHistory();
  let { params } = useParams();

  const [user, setUser] = useState(null);
  useEffect(() => {
    api.getAuthAPI().getAuthedProfile().then(e => {
      setUser(e.data);
      setLoading(false);
    }).catch(e => {
      console.log(e);
    })
  }, []);

  // function addNewIngredient() {
  //   let newIngredient = {haccp_plan: props.haccpPlanPk, organization: user.organization.pk};
  //
  //   api.getIngredientsAPI().createIngredient(newIngredient).then(e => {
  //     addIngredientToFormulation(e.data);
  //     goToIngredientForm(e.data);
  //   }).catch(e => {
  //     console.log(e);
  //   });
  // }

  function goToIngredientForm(ingredient) {
    if (props.setIngredients) {
      props.setIngredients(ingredient);
    } else {
      history.push("/data/ingredients/" + ingredient.pk);
    }
  }

  const ingredient_columns = [
    { title: "Name", field: "name" },
    { title: "Description", field: "description" },
    { title: "Amount", field: "units", editable: 'never' },
    //{ title: "Weight", field: "weight", editable: 'never' },
    //{ title: "Volume", field: "volume", editable: 'never' },
  ];

  const [ingredientSelected, setIngredientSelected] = useState(null);

  function ingredientSave(ingredient) {
    setShouldClose(true);
    setReturnStatus("Ingredient Saved!", "info");
    api.getIngredientsAPI().updateIngredient(ingredient).then(response => {
      let updatedIngredients = [...ingredients];
      let index = ingredients.findIndex(ingredient => ingredient.pk === response.data.pk);

      let newIngredient = response.data;
      newIngredient.units = parseFloat(newIngredient.unit_value).toFixed(3) + ' ' + props.units[newIngredient.unit_type].abbreviation;

      if (index > -1) updatedIngredients[index] = newIngredient;

      setIngredients(updatedIngredients);
    }).catch(error => {
      setReturnStatus("Error. Could not get ingredients.", "error");
    });
  }
  
  async function ingredientSaveAndContinue(ingredient) {
    setShouldClose(true);
    
    return api.getIngredientsAPI().listIngredients(props.formulationPk).then(response => {
      setReturnStatus("Updated ingredient!", "info");
      setIngredients(response.data);
      setIngredientSelected(null);

      let updatedIngredients = [...ingredients];
      let index = ingredients.findIndex(ingredient => ingredient.pk === response.data.pk);

      let newIngredient = response.data;
      newIngredient.units = parseFloat(newIngredient.unit_value).toFixed(3) + ' ' + props.units[newIngredient.unit_type].abbreviation;

      if (index > -1) updatedIngredients[index] = newIngredient;

      setIngredients(updatedIngredients);
    }).catch(error => {
      setReturnStatus("Error. Could not get ingredients.", "error");
    });
  }

  function handleIngredientsClosed(check) {
    if (shouldClose) {
      setIngredientModalOpen(false);
      setShouldClose(true);
      setIngredientSelected(null);
    } else {
      alert("Save changes before closing.")
    }
  }

  const [deleteWarningOpen, setDeleteWarningOpen] = React.useState(false);
  useEffect(() => {
    if (ingredientSelected == null) return;
    if (deleteWarningOpen) return;

    setIngredientModalOpen(true);
  }, [ingredientSelected, deleteWarningOpen])

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState({
    message: "",
    status: "info",
  }); // error, warning, info, info

  function setReturnStatus(message, status) {
    setMessage({ message: message, status: status });
    setOpen(true);
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  function cancel() {
    setIngredientModalOpen(false);
    setIngredientSelected(null);
  }

  const [ingredientName, setIngredientName] = React.useState("");

  function setWarningOpen(check, rowData) {
    setDeleteWarningOpen(true);
    setIngredientName(rowData.name);
    setIngredientSelected(rowData.pk);
  }

  function setWarningClosed() {
    setIngredientModalOpen(false);
    handleIngredientsClosed();
    setIngredientSelected(null);
    setDeleteWarningOpen(false);
  }

  function deleteWarnedIngredient() {
    deleteIngredient(ingredientSelected);
    setIngredientSelected(null);
    setDeleteWarningOpen(false);
  }
  
  function addNewIngredient() {
    setIngredientSelected(null);
    setIngredientModalOpen(true);
  }

  return (
    <div style={{ maxWidth: "100%" }}>
      {!props.deleteMode &&
       <Ingredient 
          open={ingredientModalOpen}
          handleClose={handleIngredientsClosed} 
          cancel={cancel} 
          setShouldClose={setShouldClose} 
          haccpPlanPk={props.haccpPlanPk} 
          formulationPk={props.formulationPk}
          organization={props.organization}
          user={user}
          user={user}
          pk={ingredientSelected} 
          save={ingredientSave} 
          saveAndContinue={ingredientSaveAndContinue} 
       />}
      <Grid container direction={'row'} wrap="nowrap" alignItems="flex-start" justify="space-between">
        <Grid item xs={6}>
          <div style={{ padding: "16px" }}>
            <Typography variant="h5" margin="8px">
              Ingredients
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          
      {!props.deleteMode &&
          <div style={{ padding: "16px", float: "right" }}>
            <Button size="medium" color="primary" variant="contained" aria-label="add" onClick={addNewIngredient}>
              Add Ingredient
            </Button>
          </div>
}
        </Grid>
      </Grid>
      <div>
        
      {!props.deleteMode &&
        <Dialog
          open={deleteWarningOpen}
          onClose={setWarningClosed}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete this ingredient?"}</DialogTitle>
          <DialogContent>
            <Ingredient 
          organization={props.organization}
              handleClose={handleIngredientsClosed} 
              cancel={cancel} 
              setShouldClose={setShouldClose} 
              pk={ingredientSelected} 
              save={ingredientSave} 
              saveAndContinue={ingredientSaveAndContinue}
              units={props.units}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={setWarningClosed} color="primary">
              No
            </Button>
            <Button onClick={deleteWarnedIngredient} color="primary" autoFocus>
              Yes, Delete
            </Button>
          </DialogActions>
        </Dialog>
}
      </div>

      {ingredients &&
        <ThemeProvider theme={theme}>
          <MaterialTable
            components={{ Container: props => <Paper {...props} elevation={0} /> }}
            isLoading={loading}
            title=""
            columns={ingredient_columns}
            data={ingredients}
            style={{ width: "100%" }}
            actions={ props.disabled ? 
              [
                {
                  icon: 'delete',
                  tooltip: 'Delete',
                  onClick: (event, rowData) => {
                    event.stopPropagation();
                    setWarningOpen(true, rowData);
                  }
                }
              ]
           : [] }

            onRowClick={(event, rowData, togglePanel) => {
              setIngredientSelected(rowData.pk);
            }}

            options={{
              actionsColumnIndex: -1,
              minBodyHeight: "30vh",
              exportButton: true,
              paging: false,
            }}

            icons={{
              Export: MoreVertIcon,
            }}
          />
        </ThemeProvider>
      }
      <Message
        open={open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="right"
        handleClose={handleClose}
      />
    </div>
  )
}, function (prevProps, nextProps) {
  if (nextProps.templateSelected !== prevProps.templateSelected) {
    return false;
  }

  for (let i = 0; i < prevProps.ingredients.length; i++) {
    if (prevProps.ingredients[i] !== nextProps.ingredients[i]) {
      return false;
    }
  }

  if (prevProps.ingredients === nextProps.ingredients) return true;
  if (prevProps.ingredients == null || nextProps.ingredients == null) return false;
  if (prevProps.ingredients.length != nextProps.ingredients.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (let i = 0; i < prevProps.ingredients.length; ++i) {
    if (prevProps.ingredients.length[i] !== nextProps.ingredients.length[i]) return false;
  }

  return true;
});

export default IngredientList;
