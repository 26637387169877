import React, { useEffect } from "react";

import { Switch, Route, useRouteMatch } from "react-router-dom"
import NewSignificantHazards from "./NewSignificantHazards";
import CCPDeterminationWorksheet from "./CCPDeterminationWorksheet";
import CCP from "../CCP";
import Grid from "@material-ui/core/Grid";
import NewHazardList from "../../Hazards/NewHazardList";
import PropTypes from 'prop-types';

function HazardAnalysis(props) {
  let match = useRouteMatch();
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <Switch>
          <Route exact path={`${match.url}/hazard_analysis/hazards`}
                 render={() => (<NewHazardList haccpPlanPk={props.haccpPlanPk} readOnlyMode={props.readOnlyMode}/>)}/>

          <Route exact path={`${match.url}/hazard_analysis/significant_hazards`}
                 render={() => (<NewSignificantHazards haccpPlanPk={props.haccpPlanPk} readOnlyMode={props.readOnlyMode}/>)}/>

          <Route exact path={`${match.url}/hazard_analysis/significant_hazards/ccp_determination/:pk`}
                 render={() => (<CCPDeterminationWorksheet readOnlyMode={props.readOnlyMode}/>)}/>

          <Route exact path={`${match.url}/hazard_analysis/significant_hazards/ccps/:pk`}
                 render={() => (<CCP readOnlyMode={props.readOnlyMode}/>)}/>
        </Switch>
      </Grid>
    </Grid>
  )
}

HazardAnalysis.propTypes = {
  haccpPlanPk: PropTypes.number,
}

export default HazardAnalysis;

