class FReceivingAPI {
    constructor(api, version) {
        this.api = api;
        this.version = version;
    }

    listReceiving(id) {
        if (typeof id === 'number') {
            return this.api.get(`${this.version}/f_receiving/${id}/`);
        }
        return this.api.get(`${this.version}/f_receiving/`)
    }

    createReceiving(payload) {
        return this.api.post(`${this.version}/f_receiving/`, payload);
    }

    updateReceiving(payload) {
        return this.api.put(`${this.version}/f_receiving/${payload.id}/`, payload);
    }

    // deleteReceiving(id) {
    //     return this.api.put(`${this.version}/f_receiving/${id}/soft_delete_action`);
    // }

}

export default FReceivingAPI;