class CameraImagesAPI {
    constructor(api, version) {
      this.api = api;
      this.version = version;
      this.path = `${this.version}/camera_image`
    }
  
    /**
     * Lists all images from the back end server.
     */
    listImages() {
      return this.api.get(`${this.path}/`);
    }
  
    /**
     * Retrieves a specific image from the back end server with 
     * primary key pk
     * @param {*} pk - The primary key of the desired image
     */
    retrieveImage(pk) {
      return this.api.get(`${this.path}/${pk}/`);
    }
  
    /**
     * Deletes an image from the back end server
     * @param {*} pk - The primary key of the image to delete
     */
    deleteImage(pk) {
      return this.api.delete(`${this.path}/${pk}/`);
    }
  
    /**
     * Uploads an image to the back end server.
     * @param {*} payload - The payload to upload. Must be form data, image field is required.
     */
    uploadImage(payload) {
      return this.api.post(`${this.path}/`, payload);
    }
    // updateImage(payload) {
    //   return this.api.put(`${this.path}/${payload.pk}/`, payload);
    // }
    updateImage(pk, formData) {
      return this.api.put(`${this.version}/camera_image/${pk}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }
  }
  
  export default CameraImagesAPI;
  