import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import {StylesContext} from "../../App";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '95%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  cell: {
    border: '1px solid black',
    padding: '5px',
  },
  select: {
    width: '100%',
  },
}));
  
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function DetailCell(props) {
  //const classes = useStyles();
  const classes = React.useContext(StylesContext);

  return (
    <>
      {props.colInfo && !props.colInfo.hidden &&
        <Grid item xs className={classes.detailCellHeader}>
          <Typography variant="h6" noWrap>
            {props.colInfo.title}
          </Typography>
        </Grid>
      }
    </>
  )
}

DetailCell.propTypes = {
  colInfo: PropTypes.object,
  disabled: PropTypes.bool,
  faded: PropTypes.bool,
}