import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import API from "../../../Api/Api";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { useParams, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import CCP from "../CCP";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableCell, RadioGroup } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { Prompt } from "react-router";
import { Alert } from "@material-ui/lab";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import Message from "../../../Components/Message";

let confirmed;

function CCPDeterminationWorksheet(props) {
    const [ccpDeterminationWorksheet, setCCPDeterminationWorksheet] = useState(null);
    let [isBlocking, setIsBlocking] = useState(false);
    const api = new API().getCcpDeterminationWorksheetAPI();
    const ccp_api = new API().getCcpAPI();

    const params = useParams();
    const history = useHistory();

    useEffect(() => {
        let pk = props.pk || params.pk;
        getCCPDeterminationWorksheet(pk);
        confirmed = 0;
    }, []);
    useEffect(() => {
        if (props.message) {
            if (props.message.message !== "") {
                setReturnStatus(props.message.message, props.message.status);
            }
        }
    }, [props.message]);

    const [hazard, setHazard] = useState(null);

    function getCCPDeterminationWorksheet(pk) {
        if (!pk) {
            if (props.setShouldClose) {
                props.setShouldClose(false);
            }
            api.createCCPDeterminationWorksheet({
                organization: props.hazard.organization,
                hazard: props.hazard.pk,
                new: "yes",
            })
                .then((e) => {
                    setCCPDeterminationWorksheet(e.data);
                    setHazard(props.hazard);
                })
                .catch((e) => {
                    console.log(e.data);
                });
        } else {
            api.retrieveCCPDeterminationWorksheet(pk)
                .then((e) => {
                    setCCPDeterminationWorksheet(e.data);
                    if (e.data.hazard) {
                        new API()
                            .getHazardAPI()
                            .retrieveHazard(e.data.hazard)
                            .then((response) => {
                                setHazard(response.data);
                            });
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }

    function handleChange(event) {
        setIsBlocking(true);
        const property = event.target.name;
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;

        setCCPDeterminationWorksheet({
            ...ccpDeterminationWorksheet,
            [property]: value,
        });
    }

    const [deleteCCPDialogOpen, setDeleteCCPDialogOpen] = useState(false);

    function handleDeleteCCPDialogClose() {
        setDeleteCCPDialogOpen(false);
    }

    function deleteCCP() {
        ccp_api.deleteCCP(ccpDeterminationWorksheet.ccp).then((response) => {
            let worksheet = {
                ...ccpDeterminationWorksheet,
                ccp: null,
                is_ccp: false,
            };
            api.updateCCPDeterminationWorksheet(worksheet).then((response) => {
                setCCPDeterminationWorksheet(response.data);
            });
        });
    }

    function handleIsACPP(question, value) {
        ccpDeterminationWorksheet.question = value;
        if (ccpDeterminationWorksheet.ccp === null) {
            let worksheet = {
                ...ccpDeterminationWorksheet,
                is_ccp: true,
                [question]: value,
            };
            //attachCCP(worksheet);
            updateCCPDeterminationWorksheet(worksheet);
        } else {
            let worksheet = {
                ...ccpDeterminationWorksheet,
                is_ccp: true,
                [question]: value,
            };
            updateCCPDeterminationWorksheet(worksheet);
        }
    }

    function handleIsNotACPP(question, value) {
        let worksheet = {
            ...ccpDeterminationWorksheet,
            [question]: value,
            is_ccp: false,
        };
        updateCCPDeterminationWorksheet(worksheet);
        if (ccpDeterminationWorksheet.is_ccp) {
            //setDeleteCCPDialogOpen(true);
        }
    }

    function handleIsCCPChange(event) {
        setIsBlocking(true);
        const property = event.target.name;
        const value = event.target.checked;
        if (value === false) {
            setDeleteCCPDialogOpen(true);
            return;
        }

        if (value === true) {
            let worksheet = { ...ccpDeterminationWorksheet, [property]: value };
            attachCCP(worksheet);
        }
    }

    function isACPP(check, property, value) {
        if (check) {
            let worksheet = { ...ccpDeterminationWorksheet, [property]: value };
            attachCCP(worksheet);
        } else {
            setDeleteCCPDialogOpen(true);
            return;
        }
    }

    function saveChanges() {
        //setIsBlocking(false);
        api.updateCCPDeterminationWorksheet(ccpDeterminationWorksheet)
            .then((e) => {
                //setCCPDeterminationWorksheet(e.data);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    function saveChangesAndContinue() {
        setIsBlocking(false);
        api.updateCCPDeterminationWorksheet(ccpDeterminationWorksheet)
            .then((e) => {
                setCCPDeterminationWorksheet(e.data);
                history.goBack();
            })
            .catch((e) => {
                console.log(e);
            });
    }

    function updateCCPDeterminationWorksheet(worksheet) {
        api.updateCCPDeterminationWorksheet(worksheet)
            .then((e) => {
                setCCPDeterminationWorksheet(e.data);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    async function attachCCP(worksheet) {
        if (worksheet.ccp === null) {
            let ccp = {
                organization: ccpDeterminationWorksheet.organization,
                significant_hazard: ccpDeterminationWorksheet.hazard,
            };

            ccp_api
                .createCCP(ccp)
                .then((e) => {
                    worksheet.ccp = e.data.pk;
                    updateCCPDeterminationWorksheet(worksheet);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }

    window.onbeforeunload = function (e) {
        if (isBlocking) {
            e.preventDefault();
            e.returnValue = "";
        }
    };

    const [q1Disabled, setQ1Disabled] = React.useState(true);
    const [q2Disabled, setQ2Disabled] = React.useState(true);
    const [q3Disabled, setQ3Disabled] = React.useState(true);
    const [q4Disabled, setQ4Disabled] = React.useState(true);

    const handleRadioChange1 = (event) => {
        if (props.setShouldClose) {
            props.setShouldClose(false);
        }
        let updatedCCPDeterminationWorksheet = { ...ccpDeterminationWorksheet };

        updatedCCPDeterminationWorksheet.q2 = null;
        updatedCCPDeterminationWorksheet.q3 = null;
        updatedCCPDeterminationWorksheet.q4 = null;

        if (event.target.value === "true") {
            updatedCCPDeterminationWorksheet.q1 = true;
        } else {
            updatedCCPDeterminationWorksheet.q1 = false;
            updatedCCPDeterminationWorksheet.q2 = null;
            updatedCCPDeterminationWorksheet.q3 = null;
            updatedCCPDeterminationWorksheet.q4 = null;
        }

        updatedCCPDeterminationWorksheet.is_ccp = false;

        updateCCPDeterminationWorksheet(updatedCCPDeterminationWorksheet);
    };

    const handleRadioChange2 = async (event) => {
        if (props.setShouldClose) {
            props.setShouldClose(false);
        }
        let updatedCCPDeterminationWorksheet = { ...ccpDeterminationWorksheet };

        updatedCCPDeterminationWorksheet.q3 = null;
        updatedCCPDeterminationWorksheet.q4 = null;

        if (event.target.value === "true") {
            updatedCCPDeterminationWorksheet.q2 = true;
            updatedCCPDeterminationWorksheet.is_ccp = true;
            await attachCCP(updatedCCPDeterminationWorksheet);
        } else {
            updatedCCPDeterminationWorksheet.q2 = false;
            updatedCCPDeterminationWorksheet.is_ccp = false;
        }

        updateCCPDeterminationWorksheet(updatedCCPDeterminationWorksheet);
    };

    const handleRadioChange3 = (event) => {
        if (props.setShouldClose) {
            props.setShouldClose(false);
        }
        let updatedCCPDeterminationWorksheet = { ...ccpDeterminationWorksheet };

        updatedCCPDeterminationWorksheet.q4 = null;

        if (event.target.value === "true") {
            updatedCCPDeterminationWorksheet.q3 = true;
        } else {
            updatedCCPDeterminationWorksheet.q3 = false;
            updatedCCPDeterminationWorksheet.is_ccp = false;
        }

        updateCCPDeterminationWorksheet(updatedCCPDeterminationWorksheet);
    };

    const handleRadioChange4 = async (event) => {
        if (props.setShouldClose) {
            props.setShouldClose(true);
        }
        let updatedCCPDeterminationWorksheet = { ...ccpDeterminationWorksheet };

        if (event.target.value === "true") {
            updatedCCPDeterminationWorksheet.q4 = true;
            updatedCCPDeterminationWorksheet.is_ccp = false;
        } else {
            updatedCCPDeterminationWorksheet.q4 = false;
            updatedCCPDeterminationWorksheet.is_ccp = true;
            await attachCCP(updatedCCPDeterminationWorksheet);
        }

        updateCCPDeterminationWorksheet(updatedCCPDeterminationWorksheet);
    };

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState({
        message: "",
        status: "info",
    }); // error, warning, info, info

    function setReturnStatus(message, status) {
        setMessage({ message: message, status: status });
        setOpen(true);
    }

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={props.open}
                fullScreen
                onClose={() => {
                    props.close();
                }}
            >
                <DialogContent>
                    <Prompt
                        message={(location, action) => {
                            //Prompt triggers on any nav event, in this case push and then replace
                            //if any are false, the chain stops and resolves.
                            //If one is true somewhere in the chain, the nav proceeds.
                            //The goal of this function is to ignore all of that and have this behave like window.confirm

                            //If they have already confirmed, or made no changes allow navigation
                            if (!isBlocking || confirmed) {
                                return true;
                            }
                            //If they haven't confirmed yet, or are are trying to nav again after previously declining, re-confirm
                            else if (confirmed === 0 || (action == "PUSH" && confirmed === false)) {
                                confirmed = window.confirm("You have unsaved changes, are you sure you want to leave?");
                            }
                            //If they've said no, and this still transitions to replace, reset confirmed and cancel nav
                            else if (action == "REPLACE" && confirmed === false) {
                                confirmed = 0;
                                return false;
                            }

                            return confirmed;
                        }}
                    />
                    {ccpDeterminationWorksheet && hazard && (
                        <Grid container spacing={1} style={{ width: "100%", margin: "12px" }}>
                            <Grid item xs={11}>
                                <Typography variant="h5">CCP Determination Worksheet</Typography>
                                Edit/view this CCP Determination Worksheet for the hazard below.
                            </Grid>

                            <Grid item xs={1}>
                                <IconButton
                                    aria-label="cancel"
                                    onClick={() => props.close()}
                                    style={{ padding: 0, marginRight: "32px" }}
                                >
                                    <CancelIcon />
                                </IconButton>
                            </Grid>
                            <Grid container style={{ margin: "10px" }} spacing={3}>
                                <Grid item xs={12}>
                                    <TableContainer style={{ padding: 0, margin: 0 }}>
                                        <Table style={{ padding: 0, margin: 0 }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Hazard Name</TableCell>
                                                    <TableCell>Type</TableCell>
                                                    <TableCell>Hazard Description</TableCell>
                                                    <TableCell>Justification</TableCell>
                                                    <TableCell>Preventative Measures</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow key={hazard.pk}>
                                                    <TableCell>{hazard.name}</TableCell>
                                                    <TableCell>{hazard.type_name}</TableCell>
                                                    <TableCell>{hazard.description}</TableCell>
                                                    <TableCell>{hazard.justification}</TableCell>
                                                    <TableCell>{hazard.preventative_measures}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                {/*<Grid item xs={1}>*/}
                                {/*    <VerticalDotMenu*/}
                                {/*        getTemplates={getTemplates}*/}
                                {/*        selectTemplate={selectTemplate}*/}
                                {/*        createTemplate={createTemplate}*/}
                                {/*        api={api}*/}
                                {/*        instance={ccpDeterminationWorksheet}*/}
                                {/*        template_title="CCP Determination Worksheet Template"*/}
                                {/*        template_cols={[*/}
                                {/*            {title: "Q1", field: "q1"},*/}
                                {/*            {title: "Q2", field: "q2"},*/}
                                {/*            {title: "Q3", field: "q3"},*/}
                                {/*            {title: "Q4", field: "q4"},*/}
                                {/*        ]}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                <Grid item xs={12}>
                                    <Typography>Q1. Do control preventative measures exist?</Typography>

                                    {ccpDeterminationWorksheet.q1 === null && (
                                        <RadioGroup
                                            aria-label="gender"
                                            name="gender1"
                                            value={null}
                                            onChange={handleRadioChange1}
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="false" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    )}
                                    {ccpDeterminationWorksheet.q1 !== null && (
                                        <RadioGroup
                                            aria-label="gender"
                                            name="gender1"
                                            value={ccpDeterminationWorksheet.q1.toString()}
                                            onChange={handleRadioChange1}
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="false" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        Q2. Is the step specifically designed to eliminate or reduce the likely
                                        occurrence of the hazard to an acceptable level?
                                    </Typography>
                                    {ccpDeterminationWorksheet.q1 === null && (
                                        <RadioGroup
                                            aria-label="gender"
                                            name="gender1"
                                            value={null}
                                            onChange={handleRadioChange2}
                                        >
                                            <FormControlLabel value="true" disabled control={<Radio />} label="Yes" />
                                            <FormControlLabel value="false" disabled control={<Radio />} label="No" />
                                        </RadioGroup>
                                    )}
                                    {ccpDeterminationWorksheet.q1 !== null && ccpDeterminationWorksheet.q2 === null && (
                                        <RadioGroup
                                            aria-label="gender"
                                            name="gender1"
                                            value={null}
                                            onChange={handleRadioChange2}
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="false" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    )}
                                    {ccpDeterminationWorksheet.q2 !== null && (
                                        <RadioGroup
                                            aria-label="gender"
                                            name="gender1"
                                            value={ccpDeterminationWorksheet.q2.toString()}
                                            onChange={handleRadioChange2}
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="false" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        Q3. Could contamination with identified hazards occur in excess of acceptable
                                        levels or increase to unacceptable levels?
                                    </Typography>
                                    {ccpDeterminationWorksheet.q2 === null && (
                                        <RadioGroup
                                            aria-label="gender"
                                            name="gender1"
                                            value={null}
                                            onChange={handleRadioChange3}
                                        >
                                            <FormControlLabel value="true" disabled control={<Radio />} label="Yes" />
                                            <FormControlLabel value="false" disabled control={<Radio />} label="No" />
                                        </RadioGroup>
                                    )}
                                    {ccpDeterminationWorksheet.q2 !== null && ccpDeterminationWorksheet.q3 === null && (
                                        <RadioGroup
                                            aria-label="gender"
                                            name="gender1"
                                            value={null}
                                            onChange={handleRadioChange3}
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="false" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    )}
                                    {ccpDeterminationWorksheet.q3 !== null && (
                                        <RadioGroup
                                            aria-label="gender"
                                            name="gender1"
                                            value={ccpDeterminationWorksheet.q3.toString()}
                                            onChange={handleRadioChange3}
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="false" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        Q4. Will a subsequent step eliminate the identified hazard or reduce the likely
                                        occurence to an acceptable level?
                                    </Typography>
                                    {ccpDeterminationWorksheet.q3 === null && (
                                        <RadioGroup
                                            aria-label="gender"
                                            name="gender1"
                                            value={null}
                                            onChange={handleRadioChange4}
                                        >
                                            <FormControlLabel value="true" disabled control={<Radio />} label="Yes" />
                                            <FormControlLabel value="false" disabled control={<Radio />} label="No" />
                                        </RadioGroup>
                                    )}
                                    {ccpDeterminationWorksheet.q3 !== null && ccpDeterminationWorksheet.q4 === null && (
                                        <RadioGroup
                                            aria-label="gender"
                                            name="gender1"
                                            value={null}
                                            onChange={handleRadioChange4}
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="false" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    )}
                                    {ccpDeterminationWorksheet.q4 !== null && (
                                        <RadioGroup
                                            aria-label="gender"
                                            name="gender1"
                                            value={ccpDeterminationWorksheet.q4.toString()}
                                            onChange={handleRadioChange4}
                                        >
                                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="false" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    )}
                                </Grid>
                                {ccpDeterminationWorksheet.is_ccp && (
                                    <Grid item xs={12}>
                                        <Alert severity="warning">This step is a CCP!</Alert>
                                    </Grid>
                                )}
                                {!ccpDeterminationWorksheet.is_ccp && (
                                    <Grid item xs={12}>
                                        <Alert severity="success">This step is not a CCP.</Alert>
                                    </Grid>
                                )}
                            </Grid>

                            {ccpDeterminationWorksheet.ccp && (
                                <Grid item xs={12}>
                                    <CCP
                                        pk={ccpDeterminationWorksheet.ccp}
                                        isCCP={ccpDeterminationWorksheet.is_ccp}
                                        deleteCCP={deleteCCP}
                                        isBlocking={isBlocking}
                                        close={props.close}
                                        saveAndContinue={props.saveAndContinue}
                                    />
                                </Grid>
                            )}
                            {ccpDeterminationWorksheet.q4 && !ccpDeterminationWorksheet.ccp && (
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ margin: "8px" }}
                                        onClick={() => props.saveAndContinue()}
                                        style={{ float: "right" }}
                                    >
                                        Save and Continue
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    )}

                    {/*<Dialog
        open={deleteCCPDialogOpen}
        onClose={handleDeleteCCPDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to delete this CCP? </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be reversed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCCPDialogClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={deleteCCP} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>*/}
                    <Message
                        open={open}
                        message={message.message}
                        severity={message.status}
                        vertical="bottom"
                        horizontal="right"
                        handleClose={handleClose}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default CCPDeterminationWorksheet;
