import React, { useEffect, useRef, useState } from "react";
import MaterialTable from "material-table";
import API from "../../Api/Api";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

function areEqual(prevProps, nextProps) {
  let a = prevProps.crossContaminationPoints, b = nextProps.crossContaminationPoints;
  if (a.length !== b.length) return false;

  return true;
}

export default React.memo(function CrossContaminationPointSelectTable(props) {

  const api = new API();
  const crossContaminationPointsColumns = [
    {title: "Name", field: "name"},
    // {title: "Description", field: "description"},
  ];

  const [crossContaminationPoints, setCrossContaminationPoints] = useState([]);
  useEffect(() => {
    api.getCrossContaminationPointAPI().listCrossContaminationPoints(props.haccpPlanPk).then(response => {
      let crossContaminationPointsAvailable = [...response.data];

      let crossContaminationPointsSelected = props.crossContaminationPoints.map(crossContaminationPoint => {
        return crossContaminationPoint.pk;
      });

      let filteredCrossContaminationPoints = crossContaminationPointsAvailable.filter(crossContaminationPoint => {
        return !crossContaminationPointsSelected.includes(crossContaminationPoint.pk);
      });

      setCrossContaminationPoints(filteredCrossContaminationPoints);
    }).catch(error => {
      console.log(error);
    });
  }, [props.crossContaminationPoints]);

  const handleDelete = (crossContaminationPoint) => {
    props.removeCrossContaminationPoint(crossContaminationPoint);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {props.crossContaminationPoints.map(crossContaminationPoint => {
          return (
            <Chip style={{margin: '4px'}}
                  variant="outlined"
                  color="primary"
                  key={crossContaminationPoint.pk}
                  label={crossContaminationPoint.name}
                  onDelete={() => {
                    handleDelete(crossContaminationPoint);
                  }}
            />
          )
        })}
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          title="Cross Contamination Points"
          columns={crossContaminationPointsColumns}
          data={crossContaminationPoints}

          components={{
            Container: props => <Paper {...props} elevation={0}/>
          }}

          options={{
            selection: false,
          }}

          actions={[
            {
              icon: 'add',
              tooltip: 'Add to Hazard',
              onClick: (event, rowData) => props.addCrossContaminationPoint(rowData)
            }
          ]}
        />
      </Grid>
    </Grid>
  )
}, areEqual);