import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import OnboardingTasks from "./OnboardingTasks";
import OnboardingProgress from "./OnboardingProgress";
import ContentHeader from "../../Components/Layouts/ContentHeader";
import API from "../../Api/Api";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  item1: {
    order: 1,
    [theme.breakpoints.down("xs")]: {
      order: 2,
    },
  },
  item2: {
    order: 2,
    padding: "16px",
    [theme.breakpoints.down("xs")]: {
      order: 1,
      padding: "14px",
    },
  },
}));

export default function OnboardingDashboard(props) {
  const [onboardingSections, setOnboardingSections] = useState([]);
  const [onboardingInputs, setOnboardingInputs] = useState([]);

  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const api = new API();

  useEffect(() => {
    Promise.all([api
      .getOnboardingAPI()
      .getSections()
      .then((response) => {
        setOnboardingSections(response.data);
      }), api
      .getOnboardingAPI()
      .getInputs()
      .then((response) => {
        setOnboardingInputs(response.data);
      })
    ]).then(() => {
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      console.log(error);
    });
  }, []);

  //update input of task. try
  function markTaskAsComplete(taskToMark) {
    let allSections = [...onboardingSections];
    let allInputs = [...onboardingInputs];
    allSections.forEach((section) => {
      section.onboarding_tasks.forEach((task) => {
        if (task.id === taskToMark.id) {
          allInputs.forEach((input) => {
            if (input.task.id === taskToMark.id) {
              input.completed = !input.completed;
              api
                .getOnboardingAPI()
                .updateInput(input)
                .then((response) => {
                  //  console.log("response updated", response.data);
                });
            }
          });
        }
      });
    });
    setOnboardingInputs(allInputs);
  }

  return (
    <>
      {loading &&
      <Grid container item xs={12} alignItems="center" justify="center" style={{height: '100%'}}>
        <CircularProgress/> 
      </Grid>
      }

      {!loading && 
      <Grid container>
        <Grid item xs={12}>
          <ContentHeader title="Onboarding Dashboard" />
        </Grid>
        <Grid container item xs={12} style={{ padding: "16px", paddingBottom: "32px" }}>
          <Grid item sm={8} xs={12} className={classes.item1}>
            {onboardingSections &&
              onboardingSections.map((section) => {
                return (
                  <OnboardingTasks
                    key={section.id}
                    section={section}
                    markAsComplete={markTaskAsComplete}
                    onboardingInputs={onboardingInputs}
                  />
                );
              })}
          </Grid>
          <Grid item sm={4} xs={12} className={classes.item2}>
            <OnboardingProgress
              sections={onboardingSections}
              inputs={onboardingInputs}
            />
          </Grid>
        </Grid>
      </Grid>
      }
    </>
  );
}
