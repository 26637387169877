import React, { useEffect, useState } from 'react';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ItemRow from "./ItemRow";
import ItemHeader from "./ItemHeader";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";


export default function DetailTable(props) {
  const [lineSelected, setLineSelected] = useState(-1);
  const [dummyKey, setDummyKey] = useState(0);

  useEffect(() => {
    setDummyKey(dummyKey + 1);
  }, [lineSelected])


  function unselectLine() {
    setLineSelected(-1);
  }

  //Javascript weirdness requires this to be done this way as there's no pointers.
  //We set up a function that has all needed values for the function it returns,
  //and return that function from here.
  function returnFunction(i) {
    return function(newData){
      let newTableData = [...props.tableData];
      newTableData[i] = newData;
      props.setLineData(newTableData);
    }
  }

  //Create an array of functions to set the data in each row, to be passed to each row.
  function getRowDataFunctions() {
    var funcArray = [];

    //Using a for loop here for clarity and to be able to use "let" for the iterator.
    for(let i = 0; i< props.tableData.length; i++) {
      funcArray.push( returnFunction(i) );
    }

    return funcArray;
  }

  //Create each row of the table.
  function makeRows() {
    let funcArray = getRowDataFunctions();

    let finalIndex = props.tableData.length;

    //todo try other ways of spacing these
    return props.tableData.map((lineData, i) => {
      return (<>
        <Grid item container key={i} xs={12} style={{margin: '0px'}}>
          <ItemRow 
            key={i}
            index={i}
            //selected={lineSelected == i}
            selected={true}
            setLineSelected={setLineSelected}
            lineData={lineData}
            setLineData={funcArray[i]}
            colInfo={props.colInfo}
            lineInfo={props.lineInfo}
            disabled={props.disabled}
            actions={props.actions}
            ineditable={props.ineditable}
            unselectable={props.unselectable}
            noSelection={props.noSelection}
            unselectLine={unselectLine}
            last={i==(finalIndex - 1)}
          />
        </Grid>
      </>)
    })
  }

  return (
    <>
      {props.lineInfo && <>
        <Paper key={dummyKey} square elevation={0} style={{"marginTop": "24px", "padding": "0px", /*backgroundColor: 'red'*/ "backgroundColor": props.disabled ? 'grey' : '#FFF'}}>
          <Grid container spacing={3}>
            {props.tableTitle &&
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  {props.tableTitle}
                </Typography>
              </Grid>
            }
            {props.header &&
              <Grid item>
                {props.header}
              </Grid>
            }
          </Grid>
          <ItemHeader
            colInfo={props.colInfo}
            lineInfo={props.lineInfo}
            disabled={props.disabled}
            actions={props.actions}
            ineditable={props.ineditable}
            unselectLine={unselectLine}
          />

          <Grid container spacing={0}>
            { makeRows() }
          </Grid>
          
        </Paper>
        <Divider style={{ marginTop: "18px"}}/>

          {props.handleAddItem &&
            <Grid container spacing={1} style={{ marginTop: "4px"}}>
              <Grid item xs>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginTop: "0px", marginLeft: "0px"}}
                  onClick={props.handleAddItem}
                  disabled={props.disabled}
                >
                  New
                </Button>
              </Grid>
            </Grid>
          }
      </>}
    </>
  )
}

DetailTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object),
  setRowData: PropTypes.func,
  setLineData: PropTypes.func,
  setData: PropTypes.func,
  colInfo: PropTypes.object,
  lineInfo: PropTypes.array,
  handleAddItem: PropTypes.func,
  disabled: PropTypes.bool,
  ineditable: PropTypes.bool,
  noSelection: PropTypes.bool,
  actions: PropTypes.array,
  tableTitle: PropTypes.string,
  header: PropTypes.object,
}