export function calculateLineIntersection(line1, line2) {
  let x1 = line1.x1, x2 = line1.x2, x3 = line2.x1, x4 = line2.x2;
  let y1 = line1.y1, y2 = line1.y2, y3 = line2.y1, y4 = line2.y2;

  // Check if none of the lines are of length 0
  if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
    return false;
  }

  let denominator = ((x2 - x1) * (y4 - y3) - (y2 - y1) * (x4 - x3));
  if (denominator === 0) { // lines are parallel or coincident (same)
    return false;
  }

  let t = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
  let u = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

  if (0 < t && t < 1 && 0 < u && u < 1) {
    let px = x1 + t * (x2 - x1);
    let py = y1 + t * (y2 - y1);
    return {x: px, y: py};
  }

  return false;
}

// https://en.wikipedia.org/wiki/Line%E2%80%93line_intersection

// line intercept math by Paul Bourke http://paulbourke.net/geometry/pointlineplane/
// Determine the intersection point of two line segments
// Return FALSE if the lines don't intersect
// function intersect(x1, y1, x2, y2, x3, y3, x4, y4) {
//
//   // Check if none of the lines are of length 0
// 	if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
// 		return false
// 	}
//
// 	denominator = ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1))
//
//   // Lines are parallel
// 	if (denominator === 0) {
// 		return false
// 	}
//
// 	let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator
// 	let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator
//
//   // is the intersection along the segments
// 	if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
// 		return false
// 	}
//
//   // Return a object with the x and y coordinates of the intersection
// 	let x = x1 + ua * (x2 - x1)
// 	let y = y1 + ua * (y2 - y1)
//
// 	return {x, y}
// }

// https://stackoverflow.com/questions/31706847/fabricjs-line-coordinates-after-movement
export function calcLineCoords(line) {
  const {tl, tr, bl, br} = line.calcCoords();

  let coordsStart;
  let coordsEnd;

  if (line.x1 > line.x2) {
    if (line.y1 > line.y2) {
      coordsStart = br;
      coordsEnd = tl;
    } else {
      coordsStart = tr;
      coordsEnd = bl;
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (line.y1 > line.y2) {
      coordsStart = bl;
      coordsEnd = tr;
    } else {
      coordsStart = tl;
      coordsEnd = br;
    }
  }

  return [coordsStart, coordsEnd];
}
