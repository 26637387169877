

class OrganizationAPI {

  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  // get the logged in users organization
  getUserOrganization() {
    return this.api.get(`get_organization/`);
  }

  getOrganization(pk) {
    return this.api.get(`${this.version}/organizations/${pk}/`);
  }

  listOrganizations() {
    return this.api.get(`${this.version}/organizations/`);
  }

  updateUser(user) {
    return this.api.put(`${this.version}/users/${user.pk}/`, user);
  }

  getSelectedUser(user) {
    return this.api.get(`${this.version}/profiles/${user}/`);
  }

  getTeamMembers() {
    return this.api.get(`${this.version}/organizations/all_team_members/`);
  }

  updateProfileImage(userNum, formData) {
    return this.api.put(`${this.version}/profiles/${userNum.pk}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  getProfilePic() {
    return this.api.get(`get_profile_pic/`);
  }

  getMyFacility() {
    return this.api.get(`${this.version}/facility/`);
  }

  createOrganization(data) {
    return this.api.post(`${this.version}/organizations/`, data);
  }

  getOrganizationInvites() {
    return this.api.get(`${this.version}/organization_invite/get_for_organization/`)
  }

  inviteUser(data){
    return this.api.post(`${this.version}/organizations/invite/`, data);
  }

  userHasAccess(orgId){
    return this.api.get(`${this.version}/organizations/`+orgId+`/user_has_access/`)
  }

  getPermissions(){
    return this.api.get(`${this.version}/organization_access/get_user_permissions_for_org`)
  }

  getTeamPermissions() {
    return this.api.get(`${this.version}/organizations/all_team_permissions/`);
  }

  updateUserPermissions(userAccess) {
    return this.api.put(`${this.version}/organizations/update_permissions/`, userAccess)
  }
}


export default OrganizationAPI;
