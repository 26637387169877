import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import API from "../../Api/Api";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import PropTypes from 'prop-types';
import GeneralApp404 from "../../Main/GeneralApp404"

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © FoodByte '}
            {/*<Link color="inherit" href="https://material-ui.com/">*/}
            {/*</Link>{' '}*/}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: 32,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function VerifyOtp(props) {
    const classes = useStyles();
    const api = new API();
    const history = useHistory();
    const location = useLocation();
    const currentParams = useParams();

    const [otp, setOtp] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);

    const [otpError, setOtpError] = useState(false);
    const [otpErrorText, setOtpErrorText] = useState('');
    function handleSubmit() {
        setOtpError(false);
        setOtpErrorText('');
        api.getAuthAPI().verifyOtp({ otp: otp, email: currentParams.email }).then((response) => {
            localStorage.setItem("access_token", response.data.access);
            localStorage.setItem("refresh_token", response.data.refresh);
            history.replace("/my_tasks/monitoring");
        })
            .catch(error => {
                if (error.response) {
                    setOtpError(true);
                    setOtpErrorText(error.response.data.detail);
                } else if (error.request) {
                    console.log(error.request); 
                } else {
                    console.log('Error', error.message);
                }
            });
    }

    function handleOtpChange(event) {
        setOtp(event.target.value);
    }

    return (
        <React.Fragment>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar} alt="FoodByte Logo" src="logos/FB_Logo_Reversed_RGB_Icon.png" />
                    <Typography component="h1" variant="h5">
                        FoodByte
                    </Typography>
                    <form className={classes.form}>
                        <Typography align="center">
                            We have sent an OTP on your email which will be valid for 5 minutes. <br />Enter the OTP below to login.
                        </Typography>
                        <TextField
                            error={otpError}
                            helperText={otpErrorText}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="otp"
                            label="OTP"
                            id="otp"
                            onChange={handleOtpChange}
                        />

                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleSubmit}
                        >
                            Verify
                        </Button>
                    </form>
                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Container>


        </React.Fragment>
    );
}

