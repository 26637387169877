class FSuppliersAPI {
  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listFSuppliers(id) {
    if (typeof id === 'number') {
      return this.api.get(`${this.version}/f_suppliers/${id}/`);
    }
    return this.api.get(`${this.version}/f_suppliers/`)
  }

  createFSuppliers(payload) {
    return this.api.post(`${this.version}/f_suppliers/`, payload);
  }

  updateFSuppliers(payload) {
    return this.api.put(`${this.version}/f_suppliers/${payload.id}/`, payload);
  }

  deleteFSuppliers(id) {
    return this.api.put(`${this.version}/f_suppliers/${id}/soft_delete_action/`);
  }

}

export default FSuppliersAPI;