import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

const COLOR_SCHEMES = {
  default: {color: 'black', backgroundColor: 'gainsboro', onHover: 'lightslategray'},
  primary: {color: 'white', backgroundColor: 'dodgerblue', onHover: 'midnightblue'},
  secondary: {color: 'white', backgroundColor: 'firebrick', onHover: 'maroon'},
}

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(props.open || false);
  let colorScheme = props.color ? props.color : 'default';

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const agreeHandler = () => {
    if (props.agreeHandler && (props.instancePk || props.instancePk === 0)) {
      props.agreeHandler(props.instancePk);
    }

    setOpen(false);
  };

  const onMouseOver = event => {
    const el = event.target;
    el.style.backgroundColor = COLOR_SCHEMES[colorScheme].onHover;
  };
  
  const onMouseOut = event => {
    const el = event.target;
    el.style.backgroundColor = COLOR_SCHEMES[colorScheme].backgroundColor;
  };

  const handleMenuContext = () => {
    if(!props.menu) {
      return (
        <Button variant={props.buttonVariant} color={colorScheme} style={props.style} onClick={handleClickOpen}>
          {props.buttonText}
        </Button>
      )
    }
    else {
      let styleObj = {...props.style};
      styleObj.color = COLOR_SCHEMES[colorScheme].color;
      styleObj.backgroundColor = COLOR_SCHEMES[colorScheme].backgroundColor;

      return (
        <MenuItem 
          style={styleObj} 
          onClick={handleClickOpen}
          onMouseEnter={event => onMouseOver(event)}
          onMouseOut={event => onMouseOut(event)}
        >
          {props.buttonText}
        </MenuItem >
      )
    }
  }

  return (
    <div>
      {handleMenuContext()}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.dialogDescription}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {props.disagreeText}
          </Button>
          <Button onClick={agreeHandler} color="primary" autoFocus>
            {props.agreeText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


AlertDialog.propTypes = {
  open: PropTypes.bool,
  color: PropTypes.oneOf(['default', 'primary', 'secondary']),
  agreeHandler: PropTypes.func,
  instancePk: PropTypes.number,
  buttonVariant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  style: PropTypes.object, //css style object
  menu: PropTypes.bool,
  buttonText: PropTypes.string,
  dialogTitle: PropTypes.string,
  dialogDescription: PropTypes.string,
  disagreeText: PropTypes.string,
  agreeText: PropTypes.string,
}
