import React, { useEffect, useState } from "react";
import DiagramDrawer from "../DiagramDrawer/DiagramDrawer";
import Message from "../../Components/Message";
import { Switch, Route } from "react-router-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import API from "../../Api/Api";

import { saveDiagram } from "../DiagramDrawer/DiagramUpdater";

const initialState = {
  count: 55,
  index: 0,
  history: [],
  value: "",
  event: "",
  curState: [],
  past: [],
  present: {},
  future: [],
  actions: [],
};

function reducer(state = initialState, action) {
  const { past, present, future, actions } = state;
  const index = state;
  switch (action.type) {
    case "undo":
      if (actions.length > 0 && past.length > 0) {
        return {
          ...state,
          future: state.future.concat(state.present),
          present: state.past[state.past.length - 1],
          past: state.past.slice(0, state.past.length - 1),
        };
      } else {
        return state;
      }
    case "redo":
      if (actions.length > 0 && future.length > 0) {
        return {
          ...state,
          past: state.past.concat(state.present),
          present: state.future[state.future.length - 1],
          future: state.future.slice(0, state.future.length - 1),
        };
      } else {
        return state;
      }
    case "addToCurrent":
      return {
        ...state,
        count: state.count - 1,
        history: state.history.concat({
          id: Math.random(),
          count: state.count - 1,
        }),
        curState: state.curState.concat({
          id: Math.random(),
          curState: action.value,
        }),
      };
    case "addAction":
      return {
        ...state,
        past: state.past.concat(state.present),
        actions: state.actions.concat({
          id: Math.random(),
          event: action.event,
          actions: action.value,
        }),
        present: {
          id: Math.random(),
          event: action.event,
          present: action.value,
        },
      };
    default:
      return state;
  }
}
const store = createStore(reducer);

function TrafficFlowDiagram(props) {
  const [status, setStatus] = useState({ message: null, severity: "success" });
  const [messageOpen, setMessageOpen] = useState(false);
  const [trafficFlow, setTrafficFlow] = useState(null);
  const [loadingTrafficFlow, setLoadingTrafficFlow] = useState(true);
  const [loadingProcesses, setLoadingProcesses] = useState(true);
  const [processes, setProcesses] = useState([]);

  useEffect(() => {
    if (!props.haccpPlan) return;

    let pk = props.haccpPlan.traffic_flow_diagram;

    new API()
      .getTrafficFlowDiagramAPI()
      .retrieveTrafficFlowDiagram(pk)
      .then((trafficFlowDiagramResponse) => {
        let trafficFlowDiagram = trafficFlowDiagramResponse.data;
        setTrafficFlow(trafficFlowDiagram);
        setLoadingTrafficFlow(false);
      })
      .catch((e) => {
        setStatus({
          message: "Could not retrieve traffic flow diagram.",
          severity: "error",
        });
      });

    new API()
      .getProcessAPI()
      .listProcesses(props.haccpPlan.pk)
      .then((e) => {
        setProcesses(e.data);
        setLoadingProcesses(false);
      });
  }, [props.haccpPlan]);

  useEffect(() => {
    if (status.message) {
      setMessageOpen(true);
    }
  }, [status]);

  const BUTTONS = [
    //none
  ];

  return (
    <div
      id="traffic_flow"
      style={{ width: "100%", height: "100%", zIndex: 999 }}
    >
      <Switch>
        <Route exact>
          <Provider store={store}>
            {loadingTrafficFlow &&
              loadingProcesses &&
              !props.haccpPlan &&
              !trafficFlow && (
                <Grid
                  container
                  direction="column"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item style={{ marginTop: "250px" }}>
                    <CircularProgress />
                  </Grid>
                </Grid>
              )}

            {!loadingTrafficFlow &&
              !loadingProcesses &&
              props.haccpPlan &&
              trafficFlow &&
              !props.readOnlyMode && (
                <DiagramDrawer
                  CcpAPI={new API().getCcpAPI()}
                  processAPI={new API().getProcessAPI()}
                  //processFlowAPI={api.getProcessFlowDiagramAPI()}
                  trafficFlowApi={new API().getTrafficFlowDiagramAPI()}
                  setFlowDiagram={setTrafficFlow}
                  saveDiagram={saveDiagram}
                  trafficFlow={trafficFlow}
                  haccpPlan={props.haccpPlan}
                  buttons={BUTTONS}
                  useProcessCircles={true}
                  processes={processes}
                  setIsBlocking={props.setIsBlocking}
                  width="11in"
                  height="8.5in"
                />
              )}
            {!loadingTrafficFlow &&
              !loadingProcesses &&
              props.haccpPlan &&
              trafficFlow.saved_image &&
              props.readOnlyMode && (
                <img src={trafficFlow.saved_image}></img>
              )}
                   {!loadingTrafficFlow && !loadingProcesses && props.haccpPlan && !trafficFlow.saved_image && props.readOnlyMode &&
                <Grid container direction="column" justify="space-between" alignItems="center">
                <Grid item style={{ marginTop: '250px' }}>
                <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#687895",
                        }}
                      >
                        There is no saved digram for this HACCP Plan
                      </Typography>
                </Grid>
              </Grid>
            }

            <Message
              open={messageOpen}
              message={status.message}
              severity={status.severity}
              vertical="bottom"
              horizontal="right"
              handleClose={() => {
                setMessageOpen(false);
              }}
            />
          </Provider>
        </Route>
      </Switch>
    </div>
  );
}

export default TrafficFlowDiagram;
