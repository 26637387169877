import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import API from "../../Api/Api";
import Divider from "@material-ui/core/Divider";
import VerticalDotMenu from "../../Components/VerticalDotMenu";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DialogContent  from "@material-ui/core/DialogContent";
import { Prompt } from 'react-router';
import { OrgContext } from "hooks/useOrganization"

import Message from "../../Components/Message";

import IngredientDatasetTable from "./IngredientDatasetTable";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";

function Ingredient(props) {
  const org = useContext(OrgContext); 
  const [ingredient, setIngredient] = useState(null);
  const [isBlocking, setIsBlocking] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  //const [units, setUnits] = useState(false);
  const api = new API();

  let params = useParams();
  let history = useHistory();
  let hasButton = props.hasButton;

  let match = useRouteMatch();

  function getIngredientsAPI() {
    if (props.templateMode) {
      return api.getIngredientTemplateAPI();
    }

    return api.getIngredientsAPI();
  }

  function getHazardsAPI() {
    if (props.templateMode) {
      return api.getHazardTemplateAPI();
    }

    return api.getHazardAPI();
  }

  useEffect(() => {
    let pk = props.pk || params.pk;
    //May need to remove this check, but it is using haccp_plan pk, not ingredient.
    if (props.pk) {
      getIngredient(pk);
    } else {
      setLoading(false);
      setIngredient({
        description: "",
        haccp_plan: props.haccpPlanPk,
        hazards: [],
        name: "",
       organization: org.pk,
      });
    }
  }, []);

  function getIngredient(pk) {
    getIngredientsAPI()
      .retrieveIngredient(pk)
      .then((e) => {
        setIngredient(e.data);
        setLoading(false);
        getHazards(e.data.pk);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const [hazards, setHazards] = useState([]);

  function getHazards(ingredient_pk) {
    getHazardsAPI()
      .listIngredientHazards(ingredient_pk)
      .then((e) => {
        setHazards(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function handleChange(event) {
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }
    setIsBlocking(true);
    const value = event.target.value;
    setIngredient({ ...ingredient, [event.target.name]: value });
  }

  function handleSubmit(e) {
    if (
      !ingredient.unit_type ||
      !ingredient.unit_value ||
      !ingredient.name ||
      !ingredient.description
    ) {
      setFieldError(true);
      setReturnStatus("Please Enter All Fields", "info");
    } else {
      setFieldError(false);

      setIsBlocking(false);
      e.preventDefault();
      if (!props.pk) {
        if (props.save) {
          props.save(ingredient);
          if (props.cancel) {
            props.cancel();
          }
        } else {
          updateIngredient(ingredient);
          if (props.cancel) {
            props.cancel();
          }
        }
      } else {
        if (props.update) {
          props.update(ingredient);
        } else {
          updateIngredient(ingredient);
        }
      }
    }
  }

  function saveAndContinue(e) {
    if (
      !ingredient.unit_type ||
      !ingredient.unit_value ||
      !ingredient.name ||
      !ingredient.description
    ) {
      setFieldError(true);
      setReturnStatus("Please Enter All Fields", "info");
    } else {
      setFieldError(false);

      setIsBlocking(false);
      e.preventDefault();
      if (!props.pk) {
        if (props.save) {
          props.save(ingredient);
          if (props.cancel) {
            props.cancel();
          }
        } else {
          updateIngredient(ingredient);
          if (props.cancel) {
            props.cancel();
          }
        }
      } else {
        if (props.update) {
          props.update(ingredient);
          if (props.cancel) {
            props.cancel();
          }
        } else {
          updateIngredient(ingredient);
          if (props.cancel) {
            props.cancel();
          }
        }
      }
    }
  }

  // function backButton(e) {
  //   e.preventDefault();
  //   history.goBack();
  // }
  //
  // function setIngredientHazard(hazard) {
  //   let updatedIngredient = {...ingredient};
  //   updatedIngredient.hazards.push(hazard.pk);
  //
  //   updateIngredient(updatedIngredient);
  // }
  //
  // function removeIngredientHazard(hazard_pk) {
  //   let updatedIngredient = {...ingredient};
  //   updatedIngredient.hazards.splice(
  //     updatedIngredient.hazards.indexOf(hazard_pk),
  //     1
  //   );
  //
  //   updateIngredient(updatedIngredient);
  // }

  /**
   * This consolidates the units to one field so that the user doesn't need to worry
   * about multiple fields and their validation.
   *
   * @param {JSON} jsonObject - A Json representation of the object
   */
  function handleUnits(jsonObject) {
    if (!jsonObject.unit_value && !jsonObject.unit_type) {
      jsonObject.units = "";
      return jsonObject;
    }

    jsonObject.units =
      parseFloat(jsonObject.unit_value).toFixed(2) +
      " " +
      props.units[jsonObject.unit_type].abbreviation;
    return jsonObject;
  }

  function updateIngredient(updatedIngredient) {
    getIngredientsAPI()
      .updateIngredient(updatedIngredient)
      .then((e) => {
        setIngredient(handleUnits(e.data));
        setReturnStatus("Updated ingredient!", "info");
        getHazards(e.data.pk);
      })
      .catch((e) => {
        setReturnStatus("Could not update ingredient.", "error");
      });
  }

  // function createTemplate() {
  //   getIngredientsAPI()
  //     .createIngredient(ingredient)
  //     .then((e) => {
  //       setReturnStatus("Template Created!", "info");
  //     })
  //     .catch((e) => {
  //       setReturnStatus("Could not create template.", "error");
  //     });
  // }
  //
  // const [templateSelected, setTemplateSelected] = useState(false);
  //
  // function selectTemplate(template) {
  //   getIngredientsAPI()
  //     .selectTemplate(ingredient.pk, template)
  //     .then((e) => {
  //       setReturnStatus("Ingredient template selected!", "info");
  //       setTemplateSelected(true);
  //       setIngredient(e.data);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }
  //
  // function getTemplates() {
  //   return api.getIngredientTemplateAPI().listIngredients();
  // }

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState({
    message: "",
    status: "info",
  }); // error, warning, info, info
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function setReturnStatus(message, status) {
    setMessage({ message: message, status: status });
    setOpen(true);
  }

  window.onbeforeunload = function (e) {
    if (isBlocking) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  const [cfiaDatabaseOpen, setCFIADatabaseOpen] = useState(false);

  function handleCFIADatabaseClosed() {
    setCFIADatabaseOpen(false);
  }

  function handleCFIADatabaseOpen() {
    setCFIADatabaseOpen(true);
  }

  function selectCFIAIngredientAndHazards(ingredientSelected, hazardsSelected) {
    if (ingredientSelected == null) {
      return;
    }

    setLoading(true);

    let ingredientDataset = { ...ingredientSelected };

    new API()
      .getIngredientsAPI()
      .selectDatasetIngredient(ingredient, ingredientDataset, hazardsSelected)
      .then((response) => {
        handleCFIADatabaseClosed();
        setIngredient(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function cancel() {
    //setShouldClose(false);
    if (props.cancel) {
      props.cancel();
    }
  }
  const [loading, setLoading] = useState(true);

  return (
    <div>
      <Prompt
        when={isBlocking}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      {ingredient && !loading && (
        <form onSubmit={handleSubmit} style={{ padding: "16px" }}>
          <Grid
            container
            spacing={3}
            style={{ width: "100%", margin: 0, padding: "16px" }}
          >
            {/*{!props.hasButton && <BackButton/>}*/}

            <Grid item xs={11}>
              <Typography variant="h5" noWrap>
                Ingredient {props.templateMode && "Template"}
              </Typography>
              Edit/view this ingredient.
            </Grid>

            {!props.templateMode && !props.deleteMode && (
              <Grid item xs={1}>
                <VerticalDotMenu
                  // getTemplates={getTemplates}
                  // createTemplate={createTemplate}
                  // selectTemplate={selectTemplate}
                  setStatus={setReturnStatus}
                  api={api.getIngredientsAPI()}
                  setIngredient={setIngredient}
                  instance={ingredient}
                  template_title="Select an ingredient template from the list below."
                  template_cols={[
                    {
                      title: "Name",
                      field: "name",
                    },
                    {
                      title: "Description",
                      field: "description",
                    },
                    {
                      title: "Weight",
                      field: "weight",
                      editable: "never",
                    },
                    {
                      title: "Volume",
                      field: "volume",
                      editable: "never",
                    },
                  ]}
                />
              </Grid>
            )}
            {!props.deleteMode && (
              <Grid item xs={12}>
                <Button
                  onClick={handleCFIADatabaseOpen}
                  variant={"contained"}
                  color={"primary"}
                >
                  Add from CFIA Database
                </Button>
                <Dialog
                  open={cfiaDatabaseOpen}
                  onClose={handleCFIADatabaseClosed}
                  maxWidth={"lg"}
                >
                  <DialogContent>
                    <IngredientDatasetTable
                      selectIngredient={selectCFIAIngredientAndHazards}
                      setCFIADatabaseOpen={setCFIADatabaseOpen}
                      handleCFIADatabaseClosed={handleCFIADatabaseClosed}
                    />
                  </DialogContent>
                </Dialog>
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Name"
                name="name"
                variant="outlined"
                error={ingredient.name ? false : fieldError}
                value={ingredient.name}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                name="description"
                variant="outlined"
                error={ingredient.description ? false : fieldError}
                value={ingredient.description}
                onChange={handleChange}
                fullWidth
                multiline
                rows={3}
              />
            </Grid>
            {/* Swap these two fields out for the unit model. */}

            <Grid item xs={6}>
              <TextField
                label="Unit Value"
                name="unit_value"
                type="number"
                variant="outlined"
                fullWidth
                error={ingredient.unit_value ? false : fieldError}
                value={ingredient.unit_value}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                error={false}
              >
                <InputLabel htmlFor="age-native-helper">Unit Type</InputLabel>
                <Select
                  native
                  label="Unit Type"
                  style={{ width: "100%" }}
                  error={ingredient.unit_type ? false : fieldError}
                  value={ingredient.unit_type || ""}
                  onChange={handleChange}
                  inputProps={{
                    name: "unit_type",
                    id: "unit_type",
                  }}
                >
                  <option aria-label="None" value="" />

                  {props.units &&
                    Object.keys(props.units).map((unitKey, i) => {
                      return (
                        <option key={i} value={unitKey}>
                          {" "}
                          {props.units[unitKey].full_name}{" "}
                        </option>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>

            {/*<Grid item xs={6}>
              <TextField
                label="Weight"
                name="weight"
                variant="outlined"
                value={ingredient.weight}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Volume"
                name="volume"
                variant="outlined"
                value={ingredient.volume}
                onChange={handleChange}
                fullWidth
              />
            </Grid>*/}
            {!props.deleteMode && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "8px" }}
                  onClick={handleSubmit}
                >
                  Save Changes
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "8px" }}
                  onClick={saveAndContinue}
                >
                  Save and Continue
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ margin: "8px" }}
                  onClick={cancel}
                >
                  Cancel
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      )}
      {loading && <CircularProgress />}
      <Message
        open={open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="right"
        handleClose={handleClose}
      />
    </div>
  );
}

export default Ingredient;
