import React, { forwardRef, useEffect, useState } from "react";
import API from "../../Api/Api";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import HazardDatasetTable from "./HazardDatasetTable";
import Grid from "@material-ui/core/Grid";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

export default function ProcessDatasetTable(props) {
  function getAPI() {
    return new API().getHazardDatasetAPI();
  }

  const hazardColumns = [
    { title: "Name", field: "name" },
    { title: "Type", field: "type_name" },
  ];

  const [hazardDataset, setHazardDataset] = useState([]);
  const [hazardSelected, setHazardSelected] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  function getDataset() {
    getAPI()
      .listDataset()
      .then((response) => {
        setHazardDataset(response.data);
        setIsLoading(false);
      });
  }

  function cancel() {
    if (props.cancel) {
      props.cancel();
    }
  }

  useEffect(() => {
    getDataset();
  }, []);
  const theme = createMuiTheme({
    overrides: {
      MuiTypography: {
        h6: {
          fontSize: 14,
        },
      },
      MuiTableCell: {
        root: {
          padding: 14,
        },
      },
    },
  });
  return (
    <div style={{ minWidth: "100%", padding: "24px" }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ThemeProvider theme={theme}>
            <MaterialTable
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              title="CFIA Hazard Database"
              columns={hazardColumns}
              data={hazardDataset}
              isLoading={isLoading}
              onRowClick={(evt, selectedRow) => {
                setHazardSelected(selectedRow);
              }}
              options={{
                rowStyle: (rowData) => ({
                  backgroundColor:
                    hazardSelected && hazardSelected.pk === rowData.pk
                      ? "#EEE"
                      : "#FFF",
                }),
                pageSize: 10,
              }}
            />
          </ThemeProvider>
        </Grid>
        {props.selectHazard && (
          <Grid item xs={12}>
            <div style={{ float: "right" }}>
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: "8px" }}
                onClick={cancel}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setIsLoading(true);
                  props.selectHazard(hazardSelected);
                }}
                variant="contained"
                color="primary"
              >
                Select Hazard
              </Button>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
