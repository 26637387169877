import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams, useRouteMatch} from "react-router-dom";
import {
  Switch,
  Route,
} from "react-router-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";
import TraceabilityList from "./TraceabilityList"
import TraceabilityDetails from "./TraceabilityDetails";
import CustomerList from "./Customer/CustomerList";
import InventoryLocationList from "./InventoryLocation/InventoryLocationList";
import ApprovedInformationTable from "./ApprovedInformation/ApprovedInformationTable";
import SKUList from "./SKUs/SKUList";
import SupplierList from "./Supplier/SupplierList";
import ItemInstanceTable from "./ItemInstances/ItemInstanceTable";
import RawInventoryTable from "./ItemInstances/RawInventoryTable";
import FinishedInventory from "./ItemInstances/FinishedInventory";
import OrderTable from "./Orders/OrderTable";
//import ReceivingOrderTable from "./Receiving/ReceivingOrderTable";
import ShippingOrderTable from "./Shipping/ShippingOrderTable";
import RecallManager from "./Recalls/RecallManager";
import UnitsList from "./Units/UnitsList";
import FormulaSKUList from "./FormulationSkus/FormulaSKUList";
import ManOrderList from "./Production/ManOrderList"
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import TraceabilityTabs from "./TraceabilityTabs";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import API from "../Api/Api";
import Traceability from "./Traceability";
import { OrgContext } from "hooks/useOrganization"


function TraceabilityRouter(props) {
  let match = useRouteMatch();
  let history = useHistory();
  const org = useContext(OrgContext);
  const [user, setUser] = useState(null);
  const [traceability, setTraceability] = useState(null);
  useEffect(() => {
    if(!user) {
      return;
    }

    if (!org) {
      return;
    }

    const api = new Traceability().getInstanceManagerAPI();

    api.listTraceabilities().then((response) => {
      if(response.data.length == 0) {
        let newData = {organization: org.pk};
        api.createTraceability(newData).then(e => {
          setTraceability(e.data);
          if(history.location.pathname.indexOf(e.data.pk) == -1) {
            // if (org.has_traceability) {
            //   history.replace(`traceability/${e.data.pk}/orders`);
            // } else {
              history.replace(`traceability/${e.data.pk}/approved_information`)
            // }
          }
        }).catch(e => {
          console.log(e);
        });
      }
      else {
        setTraceability(response.data[0]);
        if(history.location.pathname.indexOf(response.data[0].pk) == -1) {
          // if (org.has_traceability) {
          //   history.replace(`traceability/${response.data[0].pk}/orders`);
          // }
          // else {
            history.replace(`traceability/${response.data[0].pk}/approved_information`)
          // }
        }
      }
    })
    .catch((error) => {
    })
  }, [user, org]);

  useEffect(() => {
    const authApi = new API().getAuthAPI();
    authApi.getAuthedProfile().then(e => {
      setUser(e.data);
    }).catch(e => {
      console.log(e);
    });
  }, []);
  return (
    <>
      { (!user || !traceability) &&
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginTop: "250px" }}>
            <Typography>
              <CircularProgress />
            </Typography>
          </Grid>
        </Grid>
      }
      { user && traceability &&
      <Grid container style={{width: '100%', height: '100%'}}>
        {/* <Grid item xs={2}>
        <Box height="100%">
          <Route path="/traceability/:pk">
            <Paper
              elevation={0}
              style={{
                height: "100%",
                transition: "width 2",
                //zIndex:200,
              }}
            >
              <TraceabilityTabs/>
            </Paper>
          </Route>
        </Box>
        </Grid> */}
        <Grid item container xs={12}>
          <Switch>
            {/*<Route path={`${match.url}/:pk/details/`} render={() => <TraceabilityDetails 
              baseURL={match.url} 
              organization={user.organization.pk} 
              traceabilityInstance={traceability} 
              setTraceability={setTraceability} 
            /> }/>
            <Route path={`${match.url}/:pk/inventory_locations/`} render={() => <InventoryLocationList 
              baseURL={match.url}
              activeTraceability={traceability}
            />} />*/}
            {/*<Route path={`${match.url}/:pk/customers/`} render={() => <CustomerList 
              baseURL={match.url}
              activeTraceability={traceability}
            />} />
            <Route path={`${match.url}/:pk/suppliers/`} render={() => <SupplierList 
              baseURL={match.url}
              activeTraceability={traceability}
            />} />
            <Route path={`${match.url}/:pk/formula_skus/`} render={() => <FormulaSKUList 
              baseURL={match.url}
              activeTraceability={traceability}
            />} />
            <Route path={`${match.url}/:pk/skus/`} render={() => <SKUList 
              baseURL={match.url}
              activeTraceability={traceability} 
            />} /> */}
            <Route path={`${match.url}/:pk/orders/`} render={() => <OrderTable 
              baseURL={match.url}
              activeTraceability={traceability}
            />} />
            {/*<Route path={`${match.url}/:pk/item_instances/`} render={() => <ItemInstanceTable 
              baseURL={match.url}
              activeTraceability={traceability}
            />} />*/}
            <Route path={`${match.url}/:pk/raw_inventory/`} render={() => <RawInventoryTable 
              baseURL={match.url}
              activeTraceability={traceability}
            />} />
            <Route path={`${match.url}/:pk/finished_inventory/`} render={() => <FinishedInventory
              baseURL={match.url}
              activeTraceability={traceability}
            />} />

            <Route path={`${match.url}/:pk/production/`} render={() => <ManOrderList 
              baseURL={match.url}
              activeTraceability={traceability}
            />} />

            <Route path={`${match.url}/:pk/shipping/`} render={() => <ShippingOrderTable 
              baseURL={match.url}
              activeTraceability={traceability}
            />} />

            <Route path={`${match.url}/:pk/recalls/`} render={() => <RecallManager 
              baseURL={match.url}
              activeTraceability={traceability}
            />} />

            <Route path={`${match.url}/:pk/units/`} render={() => <UnitsList 
              baseURL={match.url}
              activeTraceability={traceability}
            />} />

            <Route path={`${match.url}/:pk/approved_information/`} render={() => <ApprovedInformationTable 
              baseURL={match.url}
              activeTraceability={traceability}
            />} />

            <Route exact path={`${match.url}/`} render={() => <TraceabilityList 
              baseURL={match.url} 
              setTraceability={setTraceability}
              activeTraceability={traceability}
            />}/>
          </Switch>
        </Grid>
      </Grid>
      }
    </>
  );
}
export default TraceabilityRouter;