class ImagesAPI {
  constructor(api, version) {
    this.api = api;
    this.version = version;
    this.path = `${this.version}/image`
  }

  /**
   * Lists all images from the back end server.
   */
  listImages() {
    return this.api.get(`${this.path}/`);
  }

  /**
   * Retrieves a specific image from the back end server with 
   * primary key pk
   * @param {*} pk - The primary key of the desired image
   */
  retrieveImage(pk) {
    return this.api.get(`${this.path}/${pk}/`);
  }

  /**
   * Deletes an image from the back end server
   * @param {*} pk - The primary key of the image to delete
   */
  deleteImage(pk) {
    return this.api.delete(`${this.path}/${pk}/`);
  }

  /**
   * Uploads an image to the back end server.
   * @param {*} payload - The payload to upload. Must be form data, image field is required.
   */
  uploadImages(payload) {
    let headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    return this.api.post(`${this.path}/`, payload, headers);
  }

  addImageToTrafficFlowDiagram(pk, payload) {
    return this.api.put(`${this.path}/${pk}/link_to_floor_plan/`, payload, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }
}

export default ImagesAPI;
