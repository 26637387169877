import React, { useEffect, useState, Suspense } from "react";

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";

import Main from "./Main/Main"
import SignIn from "./Accounts/User/SignIn";
import AcceptInvite from "./Accounts/User/Invites/AcceptOrganizationInvite";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import PrivateRoute from "./Components/PrivateRoute";

import PasswordReset from "./Accounts/User/PasswordReset";
import EmailChangeConfirmation from "./Accounts/User/EmailChangeConfirmation"
import ForgotPassword from "./Accounts/User/ForgotPassword"

import "./css/quill.css";

import API from "./Api/Api";

import CssBaseline from "@material-ui/core/CssBaseline";
import VerifyOtp from "Accounts/User/VerifyOtp";

const themeJson = {
  //Use caution when making an override, it will become the default for all components if we don't specify 
  //the same prop in a higher priority
  overrides: {
    MuiPickerDTTabs: {
      tabs: {
        color: 'white',
      }
    },

    MuiAccordion: {
      // "&.MuiAccordion-root:before": {
      //   backgroundColor: "white"
      // },
    },
    MuiButton: {
      containedPrimary: {
        '&:hover': {
          backgroundColor: '#18bff6',
        }
      }
    },
    // Style sheet name 
    MuiIconButton: {
      // Name of the rule
      root: {
        // Some CSS
        borderRadius: '10%', // instead of 50%, makes square buttons not have oval highlights
        padding: '8px', //reduced from 12px, was just too big on small buttons.
      },
    },
    //This is the easiest way to get a handle on the <Table> element of material table right now.
    MuiTable: {
      root: {
        width: '96%',
        margin: '0px 32px 8px',
      }
    }
  },
  palette: {
    common: {
      black: "#000",
      white: "#fff"
    },
    background: {
      paper: "#fff",
      //paper: "red",
      default: "#f8f8f8",
      //default: '#ffaa11',
      emphasize: '#ffffff',
      //default: 'red',
    },
    border: {
      default: '#E4E4E5',
    },
    tabTable: {
      headerColor: '#687895',
    },
    primary: {
      light: "#7986cb",
      //main: "rgba(17, 0, 255, 1.0)",//this is different than mat ui default primary, is this intentional?
      main: "#18bff6",
      //main: "#aaccbb",
      dark: "#303f9f",
      contrastText: "#fff"
    },
    secondary: {
      light: "#ff4081",
      main: "#949eb1",
      //main: "#aa11FF",
      dark: "#c51162",
      contrastText: "#fff"
    },
    faded: {
      main: "#E5E5E5",
    },
    textEmphasis: {
      hard: '#687895',
      soft: 'rgba(104, 120, 149, 0.6)',
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff"
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)"
    }
  },
}
const theme = createMuiTheme(themeJson);

const useStyles = makeStyles((themeProp) => ({
  /******** Styles for item tables, like order items on order/shipping records ********/
  /******Grouped Table Styles ********/
  //don't use these for now, we'll set this up when we replace material table.
  /*groupedTableContainer:{
    "width": "100%", 
    backgroundColor: "#ECECEC"
  },
  groupedTableHeaderStyle:{
    backgroundColor: "#ECECEC",
  },*/
  /*****Recall Styles ****/
  accordionNoLine: {
    margin: "32px",
    marginTop: "8px",
    marginBottom: "8px",
    width: "100%",
    // "&.MuiAccordion-root:before": {
    //   backgroundColor: "white"
    // },
  },
  recallTypeCard: {
    width: '300px',
    '&:hover': {
      background: "#CCF",
    },
  },
  recallDisplayCard: {
    width: 'calc(100% - 64px)',
    margin: '32px',
  },
  recallDisplayAvatar: {
    backgroundColor: 'blue',
  },
  recallDisplayContainerGrid: {

  },
  recallDisplayItemGrid: {

  },
  /******* Tab Actions  ******/
  tabTableFreeAction: {
    //margin: '0px 0px 0px 8px',
    margin: '0px',
    height: '36px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '1.25px',
  },
  itemTableGrid: {
    width: '100%',
    borderTop: '1px solid ' + themeJson.palette.border.default,
    marginTop: '0px',
    paddingLeft: '24px',
    paddingRight: '16px',
    height: '64px',
  },
  itemTableRow: {
    width: '100%',
    borderTop: '1px solid ' + themeJson.palette.border.default,
    marginTop: '0px',
    paddingLeft: '24px',
    paddingRight: '16px',
    height: '64px',
    '&:hover': {
      background: "#CCF",
    },
  },
  backNextButtons: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  itemTableHeaderRow: {
    width: '100%',
    paddingLeft: '24px',
    paddingRight: '16px',
  },
  itemTableTypography: {
    marginTop: '5px',
    color: '#9798B0',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.1px',
  },
  itemTableHeader: {
    fontWeight: 'bold',
  },
  itemTableSelect: {
    width: '100%',
    padding: '0px',
    margin: '0px',
  },

  /******Status Manager Styles *****/
  statusLine: {
    position: 'relative',
    top: '12px',
  },
  statusText: {

  },
  statusRoot: {
    position: 'relative',
    margin: 'auto',
    paddingTop: '3px',
    alignItems: 'center',
    width: '150px',
  },
  statusUnselected: {
    display: 'block',
    margin: 'auto',
    backgroundColor: '#e4e4e5',
    borderRadius: '50%',
    height: '50px',
    width: '50px',
  },
  statusRecordSelected: {
    display: 'block',
    margin: 'auto',
    backgroundColor: '#e4e4e5',
    borderRadius: '50%',
    height: '50px',
    width: '50px',
  },
  statusViewingSelected: {
    display: 'block',
    margin: 'auto',
    backgroundColor: '#e4e4e5',
    borderRadius: '50%',
    height: '50px',
    width: '50px',
  },
  statusBothSelected: {
    display: 'block',
    margin: 'auto',
    //backgroundColor: '#DDD',
    border: 'solid 1.5px #18bff6',
    borderRadius: '50%',
    height: '50px',
    width: '50px',
  },

  /*****Multi Lot Table Styles  *******/
  multiLotTableSingleGrid: {
    width: '100%',
  },
  multiLotTableFooter: {
    width: '100%',
  },
  multiLotTablePaper: {
    width: '100%',
  },
  multiLotTableHeaderPaper: {
    marginTop: '12px',
  },
  multiLotTableHeaderTitleGrid: {
  },
  multiLotTableHeaderTitleTypography: {
    fontWeight: 'bold',
    padding: '2px 8px',
  },
  multiLotTableHeaderComparisonGrid: {
    marginBottom: '1px',
    paddingBottom: '0px'
  },
  multiLotTableHeaderComparisonTypography: {
    padding: '2px 8px',
  },
  multiLotTableHeaderComparisonSpan: {
    color: '#9798B0',
  },
  multiLotTableSelect: {

  },
  multiLotTableRow: {

  },
  multiLotTableRowPaper: {
    padding: '10px 12px',
    borderTop: 'solid 1px ' + themeJson.palette.border.default,
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
  },
  multiLotTableDisplayTypography: {
    color: '#9798B0',
    //backgroundColor: 'blue',
    lineHeight: '1.5',
    letterSpacing: '0.1px',
  },

  /***** Detail Table Styles ******/
  detailFormContainer: {
    width: '100%',
  },
  detailFormRow: {
    width: '100%',
    marginBottom: '13px',
  },
  detailFormControl: {
    margin: themeProp.spacing(1),
    width: '95%',
  },
  detailGrid: {
    width: '100%',
  },
  detailChips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  detailChip: {
    margin: 2,
  },
  detailCellHeader: {
    //border: '1px solid black',
    padding: '5px',
  },
  detailCell: {
    //border: '1px solid black',
    padding: '5px',
  },
  detailDisplayField: {
    padding: '8px 0px',
  },
  detailSelect: {
    width: '100%',
  },

  /*****General Styles *********/
  generalPaperFullLengthHeader: {
    backgroundColor: themeJson.palette.background.default,
    height: 'auto',
    borderBottom: '1px solid ' + themeJson.palette.border.default,
    margin: '0px',
    width: "100%",
  },
  generalTabButtonSelected: {
    color: themeJson.palette.primary.main,
    fontSize: '14px',
    lineHeight: '14px',
    backgroundColor: 'transparent'
  },
  generalTabButtonNotSelected: {
    color: themeJson.palette.secondary.main,
    fontSize: '14px',
    lineHeight: '14px',
    backgroundColor: 'transparent'
  },
  generalListPaperContainer: {
    margin: "0px",
    padding: "16px 0px 0px", //todo if this padding is < 12 the tables need a scroll bar for some reason, find out why.
    width: '100%',
    backgroundColor: themeJson.palette.background.default,
  },
  generalFormSubsectionHeader: {
    marginBottom: '12px',
    fontWeight: 'bold',
  },
  generalFormHeader: {
    margin: '4px 8px 4px',
    color: '#687895',
  },
  generalFormHeaderTypography: {
    color: '#687895',
  },
  generalFormTypographyHeader: {
    marginBottom: '12px',
  },
  generalFormPaperContainer: {
    //border: '1px solid rgba(0, 0, 0, 0.12)',  
    margin: "16px",
    padding: "8px",
    width: "100%",
  },
  generalFormPaperContainerHaccp1: {
    //border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: "8px",
    width: "100%",
    margin: "32px",
    marginBottom: "0px"
  },
  generalFormPaperContainerProductDescription: {
    //border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: "8px",
    width: "100%",
    margin: "32px",
    marginBottom: '96px',
  },
  stepperPaper: {
    backgroundColor: "white",
    //borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    //zIndex: 5000,
    //bottom: 0,
    width: "100%",
    //left: "64px",
    //height: "auto",
    //  [theme.breakpoints.down("sm")]: {
    //    left: "64px",   backgroundColor: "white",
    //    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    //    position: "fixed",
    //    zIndex: 5000,
    //    bottom: 0,
    //    width: "100%",
    //    height: "auto",
    //  },
  },
  stepperGrid: {
    backgroundColor: "white",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    position: "fixed",
    zIndex: 5000,
    bottom: 0,
    left: "calc(64px + 360px)",
  },
  generalFormPaperContainerHaccp: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: "8px",
    width: "auto",
  },
  generalFormPaperContainerSkuFields: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    margin: "16px 24px",
    padding: "8px",
    width: "100%",
  },
  generalFormPaperContainerApprovedInfo: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: "8px",
    //width: "100%",
  },
  //todo if these two stay the same by release merge the classes
  generalFormPaperStickyFooter: {
    position: 'fixed',
    bottom: "0px",
    borderTop: '1px solid ' + themeJson.palette.border.default,
    width: "100%",
    margin: "0px",
    padding: "4px 64px 4px",
    zIndex: 10000,
    //backgroundColor: themeJson.palette.background.default,
    //backgroundColor: 'red',
    backgroundColor: "#f8f8f8",
  },
  generalFormPaperStickyFooterApprovedInfo: {
    position: 'fixed',
    bottom: "0px",
    borderTop: '1px solid ' + themeJson.palette.border.default,
    width: "100%",
    margin: "0px",
    padding: "4px 64px 4px",
    zIndex: 10000,
    //backgroundColor: themeJson.palette.background.default,
    //backgroundColor: 'red',
    backgroundColor: "#f8f8f8",
  },
  generalFormPaperFieldHolder: {
    width: "100%",
    //margin: "8px", 
    padding: "24px 0px",
    backgroundColor: themeJson.palette.background.default,
  },
  generalFormPaperHeader: {
    //margin: "16px", 
    padding: "8px",
    width: "100%",
    backgroundColor: themeJson.palette.background.default,
  },
  generalFormDialogueContainer: {
    padding: "0px",
    backgroundColor: themeJson.palette.background.default,
  },
  generalContainerGrid: {
    padding: "16px",
    marginBottom: '96px',
  },
  generalContainerGridProductDescription: {
    padding: "16px",
  },
  generalContainerGridHead: {
    padding: "4px 16px",
    borderBottom: '1px solid ' + themeJson.palette.border.default,
  },
  generalContainerGridBody: {
    //padding: "16px",
    minWidth: '1100px',
    padding: '0px 80px',
    marginBottom: '96px',
  },
  productDescriptionContainerGridBody: {
    paddingTop: "32px",
    minWidth: '1100px',
    padding: '0px 80px',
  },
  generalContainerGridFoot: {
    padding: "16px",
  },
  generalDialogueFoot: {
    width: "100%",
    margin: "0px",
    padding: "4px",
    backgroundColor: themeJson.palette.background.default,
  },
  generalVerticleAlignTypography: {
    padding: '2px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  generalFormTextField: {
    backgroundColor: '#FFFFFF',
    borderRadius: '3px',
  },
  customerDetailsFormField: {
    width: "100%",
    backgroundColor: themeJson.palette.background.default,
  },
  customerDetailsContainerGridBody: {
    minWidth: '1100px',
    padding: '0px 80px',
    marginBottom: '16px',
  },
}));

export const StylesContext = React.createContext(useStyles);


function App() {
  return (
    <div className="App">
      <Suspense fallback={
        <Grid container item justify="center" alignItems="center" style={{ width: '100%', height: '100%' }}>
          <CircularProgress />
        </Grid>
      }>
        <StylesContext.Provider value={useStyles()}>

          <CssBaseline />

          <ThemeProvider theme={theme}>
            <Router>
              <Switch>
                <Route path="/login">
                  <SignIn/>
                </Route>
                <Route path="/invite/:uuid/:hash">
                  <AcceptInvite/>
                </Route>
                <Route path="/password-reset/:uuid/:hash">
                  <PasswordReset/>
                </Route>
                <Route path="/forgot-password">
                  <ForgotPassword/>
                </Route>
                <Route path="/email-change-confirm/:uuid/:hash">
                  <EmailChangeConfirmation/>
                </Route>
                <Route path="/verify-otp/:email">
                  <VerifyOtp/>
                </Route>
                <PrivateRoute path="/">
                  <Main/>
                </PrivateRoute>
              </Switch>
            </Router>
          </ThemeProvider>
        </StylesContext.Provider>
      </Suspense>
    </div>
  );
}

export default App;
