import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import SaveIcon from '@material-ui/icons/Save';
import ImportExportIcon from '@material-ui/icons/ImportExport';

import ContentHeader from "../../../Components/Layouts/ContentHeader";
import Scope from "./Scope/Scope";
import SimpleBackdrop from "../../../Components/SimpleBackdrop";

import DateFnsUtils from '@date-io/date-fns'; // choose your lib

import {
	DatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { makeStyles } from "@material-ui/styles"

import API from "../../../Api/Api";
import AuditAPI from "./api/AuditAPI";
import { OrgContext } from "hooks/useOrganization"

import { saveAs } from 'file-saver';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles({
	underline: {
		"&&:before": {
			borderBottom: 'none',
		}
	},

	input: {
		backgroundColor: 'white',
		padding: '16px',
	},

	multiline: {
		padding: '0px',
	},
})

const emptyAudit = {
	organization: "",
	title: "",
	description: "",
	start_date: new Date(),
	end_date: new Date(),
	standards: [],
}

export let standardsSelected = {};
export let sectionsSelected = {};

export default function Audit({ readOnly, getAudits }) {

	const [audit, setAudit] = useState({ ...emptyAudit });
	const [scope, setScope] = useState([]);
	const [loading, setLoading] = useState(true);

	const [recordCount, setRecordCount] = useState(0);
	const [currentInterval, setCurrentInterval] = useState();
	const [length, setLength] = useState(0);
	const [backdropOpen, setBackdropOpen] = useState(false);

	//const [standardsSelected, setStandardsSelected] = useState({});
	//const [sectionsSelected, setSectionsSelected] = useState({});

	const classes = useStyles();
	const history = useHistory();
	const params = useParams();

	const [error, setError] = useState("");

	const org = useContext(OrgContext);

	const [alertOpen, setAlertOpen] = useState(false);
	const [auditAlert, setAuditAlert] = useState(false);

	const [errorOpen, setErrorOpen] = useState({ message: "", open: false });

	useEffect(() => {
		if (params.id) {
			new AuditAPI().getAudit(params.id).then(response => {
				response.data.standards.forEach(standard => {
					standardsSelected[standard] = true;
				});

				response.data.sections.forEach(section => {
					sectionsSelected[section] = true;
				});

				setLength(Object.keys(standardsSelected).length);

				setAudit({
					...response.data,
					start_date: new Date(response.data.start_date),
					end_date: new Date(response.data.end_date)
				});
			});
		}

		new API()
			.getPlanBuilderAPI().getActiveProgramTree()
			.then((response) => {
				setScope(response.data);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setErrorOpen({ message: "An error has occurred (500).", open: true });
			});

		return function cleanup() {
			standardsSelected = {};
			sectionsSelected = {};
		}
	}, [params.id]);

	useEffect(() => {
		return function cleanup() {
			clearInterval(currentInterval)
		}
	}, [currentInterval]);

	useEffect(() => {
		let standardKeys = Object.keys(standardsSelected).map(key => parseInt(key, 10));

		new AuditAPI().getNumSelectedRecords({
			start_date: audit.start_date.toISOString(),
			end_date: audit.end_date.toISOString(),
			standards_selected: standardKeys
		}).then(response => {
			setRecordCount(response.data.record_count);
		});
	}, [audit.start_date, audit.end_date, length]);


	function setStandardChecked(id, checked) {
		let updatedStandardsSelected = { ...standardsSelected };

		if (id in updatedStandardsSelected) {
			if (!checked) {
				delete updatedStandardsSelected[id]
			}
		}
		else {
			updatedStandardsSelected[id] = true;
		}

		standardsSelected = { ...updatedStandardsSelected };
		setLength(Object.keys(updatedStandardsSelected).length);
	}

	function setSectionChecked(section, checked) {
		let explored = new Set();
		let s = [];

		s.push(section);

		explored.add(section);

		while (s.length) {
			let section = s.pop();

			if (section.type === 'STANDARD') {
				if (checked) {
					standardsSelected[section.standard_id] = checked;
				}
				else {
					delete standardsSelected[section.standard_id];
				}
			}

			if (section.type === 'SECTION') {
				if (checked) {
					sectionsSelected[section.section_id] = checked;
				}
				else {
					delete sectionsSelected[section.section_id];
				}
			}

			section.children.filter(child => !explored.has(child)).forEach(child => {
				explored.add(child);
				s.push(child);
			});
		}

		setLength(Object.keys(standardsSelected).length);
	}

	function saveAudit(event) {
		try {
			let formattedAudit = formatAudit();

			if (!audit.id) {
				new AuditAPI().createAudit(formattedAudit).then(response => {
					setAudit({ ...response.data, start_date: new Date(response.data.start_date), end_date: new Date(response.data.end_date) });
					setAlertOpen(true);
					getAudits()
				});
			}
			else {
				new AuditAPI().updateAudit(formattedAudit).then(response => {
					setAudit({ ...response.data, start_date: new Date(response.data.start_date), end_date: new Date(response.data.end_date) });
					setAlertOpen(true);
					getAudits();
				})
			}
		} catch (e) {
			setErrorOpen({ message: `${e.message}`, open: true });
			console.error(e);
		}
	}

	function getJobResponse(jobId, intervalId) {
		if (!jobId) {
			throw new Error("Please provide a job id.");
		}

		if (!intervalId) {
			throw new Error("Please provide an interval id.");
		}

		new AuditAPI().getJobStatus(jobId).then(response => {
			if (response.data === 'finished') {
				new AuditAPI().getJobResult(jobId).then(response => {
					let blob = new Blob([response.data], { type: "application/zip" });
					saveAs(blob, "audit.zip");
					clearInterval(intervalId);
					setBackdropOpen(false);
				}).catch(error => {
					console.log(error);
					clearInterval(intervalId);
					setBackdropOpen(false);
				});
			}
		}).catch(error => {
			setBackdropOpen(false);
			setErrorOpen({ message: `An error has occurred (500)`, open: true });
			clearInterval(intervalId);
		})
	}

	function exportAudit(event) {
		try {
			let formattedAudit = formatAudit();
			setBackdropOpen(true);
			new AuditAPI().exportAudit(formattedAudit).then(response => {
				setBackdropOpen(false);
				// let blob = new Blob([response.data], { type: "application/zip" });
				// saveAs(blob, "audit.zip");
				
				let jobId = response.data;
				localStorage.setItem("audit_download_job_" + formattedAudit.id, jobId);
				setAuditAlert(true)
				
				// let intervalId = setInterval(() => getJobResponse(jobId, intervalId), 1000);
			}).catch(error => {
				setErrorOpen({ message: `An error has occurred (500)`, open: true });
				setBackdropOpen(false);
				console.error(error)
			});
		} catch (e) {
			setErrorOpen({ message: `${e.message}`, open: true });
			console.error(e);
		}
	}

	function formatAudit() {
		if (!audit.title) {
			throw new Error("Please enter a name");
		}

		//if (!audit.description) {
		//	throw new Error("Please enter notes");
		//}

		if (!audit.start_date) {
			throw new Error("Please enter a start date for this audit");
		}

		if (!audit.end_date) {
			throw new Error("Please enter an end date for this audit");
		}

		/*if (!Object.keys(standardsSelected).length) {
			throw new Error("There are no standards selected");
		}*/

		let standardKeys = Object.keys(standardsSelected).map(key => parseInt(key, 10));
		let sectionKeys = Object.keys(sectionsSelected).map(key => parseInt(key, 10));

		let formattedAudit = {
			...audit,
			organization: org.pk,
			standards: standardKeys,
			sections: sectionKeys,
		};

		return formattedAudit;
	}

	return (
		<Grid container>
			<ContentHeader title="Edit Audit" />
			<SimpleBackdrop open={backdropOpen} />

			<Snackbar
				open={alertOpen}
				autoHideDuration={6000}
				onClose={() => { setAlertOpen(false) }}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Alert severity="info" onClose={() => { setAlertOpen(false) }}>
					Your audit has been saved
				</Alert>
			</Snackbar>
			<Snackbar
				open={auditAlert}
				autoHideDuration={8000}
				onClose={() => { setAuditAlert(false) }}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Alert severity="info" onClose={() => { setAuditAlert(false) }}>
					Your export audit request has been made. You can download it from the audit list.
				</Alert>
			</Snackbar>
			<Snackbar
				open={errorOpen.open}
				autoHideDuration={6000}
				onClose={() => { setErrorOpen({ message: "", open: false }) }}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert severity="error" onClose={() => { setErrorOpen({ message: "", open: false }); }}>
					{errorOpen.message}
				</Alert>
			</Snackbar>

			<Grid container style={{ padding: '32px' }} justify="space-evenly">
				<Grid item container xs={12} md={8} style={{ padding: '16px' }} spacing={2} direction="column">
					<Grid item>
						<Typography style={{ fontWeight: 500, fontSize: '14px' }}>
							Audit Name
						</Typography>
					</Grid>
					<Grid item>
						{!readOnly &&
							<Paper elevation={0} style={{ height: '50px' }}>
								<TextField
									InputProps={{ disableUnderline: true, classes: { input: classes.input } }}
									value={audit.title}
									onChange={event => setAudit({ ...audit, "title": event.target.value })}
									variant="filled"
									fullWidth
								/>
							</Paper>
						}
					</Grid>
					<Grid item>
						<Typography style={{ fontWeight: 500, fontSize: '14px' }}>
							Audit Notes
						</Typography>
					</Grid>
					<Grid item>
						{!readOnly &&
							<TextField
								multiline
								rows={3}
								InputProps={{ disableUnderline: true, classes: { input: classes.input, multiline: classes.multiline } }}
								value={audit.description}
								onChange={event => { setAudit({ ...audit, "description": event.target.value }) }}
								variant="filled"
								fullWidth
							/>
						}
						{readOnly &&
							<Paper elevation={0} style={{ height: '50px' }}>

							</Paper>
						}
					</Grid>
					<Grid item>
						<Typography style={{ fontWeight: 500, fontSize: '14px' }}>
							Audit Scope
						</Typography>
					</Grid>
					<Grid item container>
						{loading &&
							<Grid item container justify="center" style={{ marginTop: '32px' }}>
								<CircularProgress color="primary" />
							</Grid>
						}


						{!loading &&
							<Scope
								readOnly={readOnly}
								data={scope.tree}

								standardsChecked={standardsSelected}
								setStandardChecked={setStandardChecked}

								sectionsChecked={sectionsSelected}
								setSectionChecked={setSectionChecked}
							/>
						}
					</Grid>
				</Grid>
				<Grid item container xs={12} md={4} style={{ padding: '16px' }} direction="column" spacing={2}>
					<Grid item>
						<Typography style={{ fontWeight: 500, fontSize: '14px' }}>
							Start Date
						</Typography>
					</Grid>
					<Grid item>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<DatePicker
								readOnly={readOnly}
								variant="filled"
								format='MMMM do, yyyy'
								maxDate={audit.end_date}
								InputProps={{ disableUnderline: true, classes: { input: classes.input } }}
								showTodayButton
								fullWidth
								value={audit.start_date}
								onChange={(startDate) => setAudit({ ...audit, start_date: startDate })}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
					<Grid item>
						<Typography style={{ fontWeight: 500, fontSize: '14px' }}>
							End Date
						</Typography>
					</Grid>
					<Grid item>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<DatePicker
								readOnly={readOnly}
								minDate={audit.start_date}
								variant="filled"
								format='MMMM do, yyyy'
								InputProps={{ disableUnderline: true, classes: { input: classes.input } }}
								showTodayButton
								fullWidth
								value={audit.end_date}
								onChange={(endDate) => setAudit({ ...audit, end_date: endDate })}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
					<Grid item>
						<Typography style={{ fontWeight: 500, fontSize: '14px' }}>
							Export Details
						</Typography>
					</Grid>
					<Grid item>
						<Grid item container xs={12}>
							<Grid item container xs={8} justify="flex-start" alignItems="center">
								<Typography style={{ fontWeight: 500, fontSize: '14px' }}>
									Total Scope
								</Typography>
							</Grid>
							<Grid item container xs={4} justify="flex-end" alignItems="center">
								<Typography style={{ fontSize: '12px', opacity: 0.5 }}>
									{Math.round((length / scope.num_standards) * 100)}% of Program
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<LinearProgress variant="determinate" value={Math.round((length / scope.num_standards) * 100)} />
					</Grid>
					<Grid item container spacing={2} style={{ marginTop: '8px' }}>
						<Grid item xs={4}>
							<Typography style={{ fontWeight: 500, fontSize: '14px' }}>
								Total Record:
							</Typography>
						</Grid>
						<Grid item xs={8}>
							<Typography style={{ fontSize: '12px' }}>
								{recordCount} Record(s) between {audit.start_date.toDateString()} and {audit.end_date.toDateString()}
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography style={{ fontWeight: 500, fontSize: '14px' }}>
								Standards:
							</Typography>
						</Grid>
						<Grid item xs={8}>
							<Typography style={{ fontSize: '12px' }}>
								{length} standards selected
							</Typography>
						</Grid>
						{/*<Grid item xs={4}>
							<Typography style={{fontWeight: 500, fontSize: '14px'}}>
								Est. Time
							</Typography>
						</Grid>
						<Grid item xs={8}>
							<Typography style={{fontSize: '12px'}}>
								5+ minutes
							</Typography>
					</Grid>*/}
						<Grid container item xs={12} alignItems="center" justify="flex-start" style={{ padding: '8px 0px' }}>
							<Button
								style={{ margin: '4px' }}
								startIcon={<SaveIcon />}
								variant="contained"
								color="primary"
								size="small"
								onClick={saveAudit}
							>
								Save
							</Button>
							<Button
								style={{ margin: '4px' }}
								startIcon={<ImportExportIcon />}
								variant="contained"
								color="primary"
								size="small"
								disabled={!audit || audit.id == null}
								onClick={exportAudit}
							>
								Export
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}