import React, { useEffect, useState, useContext } from "react";
import ReactQuill from "react-quill";
import { useHistory, useParams } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Alert from '@material-ui/lab/Alert';
import Snackbar from "@material-ui/core/Snackbar";

import CancelIcon from '@material-ui/icons/Cancel';
import { OrgContext } from "hooks/useOrganization"
import { toolbarOptions } from "../../config" 

import Prompt from "./Prompt";
import API from "Api/Api";
import GreyTextField from "Components/GreyTextField";
import WhiteTextField from "Components/WhiteTextField";


export default function Task(props) {
  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const params = useParams();

  const org = useContext(OrgContext);

  const api = new API();

  useEffect(() => {
    if (!org) {
      return;
    }

    let pk = props.pk || params.task_pk;

    let newTask = params.task_pk ? false : true;

    if (newTask && props.activePlan) {
      setTask({ organization: org.pk, program: props.activePlan.id, standards: [], name: '', description: '', procedures: '', custom_fields: [] });
      setLoading(false);
    }

    else if (pk && !newTask) {
      api.getTaskAPI().retrieveTask(pk).then(response => {
        setTask(response.data);
        setLoading(false);
      });
    }
  }, [org, props.activePlan]);

  function handleChange(taskData) {
    setTask({ ...task, [taskData.target.name]: taskData.target.value });
  }

  function handleQuillChange(content, delta, source, editor) {
    setTask({ ...task, "procedures": content });
  }

  function setSuccessStatus(text) {
    setStatusText(text);
    setStatusSeverity("info");
    setStatusOpen(true);
  }

  function setErrorStatus(text) {
    setStatusText(text);
    setStatusSeverity("error");
    setStatusOpen(true);
  }

  function saveAndContinue() {
    let newTask = (params.task_pk || task.id) ? false : true;

    if (task.name == null || task.name == "") {
      setErrorStatus("A name is required"); 
    }

    if (newTask) {
      api.getTaskAPI().createTask(task).then(response => {
        if (props.addTask) {
          props.addTask(response.data, true, true);
        }
      }).catch(error => {
        setErrorStatus("Could not create task.");
      });
    }
    else {
      api.getTaskAPI().updateTask(task).then(response => {
        if (props.updateTask) {
          props.updateTask(response.data, true, true);
        }
      }).catch(error => {
        setErrorStatus("Could not update task");
      });
    }
  }

  function saveChanges() {
    let newTask = (params.task_pk || task.id) ? false : true;

    if (task.name == null || task.name == "") {
      setErrorStatus("A task name is required");
      return;
    }

    if (newTask) {
      api.getTaskAPI().createTask(task).then(response => {
        if (props.addTask) {
          props.addTask(response.data);
        }
        setTask(response.data);
        setSuccessStatus("Task created");
      }).catch(error => {
        setErrorStatus("Could not create task.");
      });
    }
    else {
      api.getTaskAPI().updateTask(task).then(response => {
        if (props.updateTask) {
          props.updateTask(response.data, false, true);
        }

        setTask(response.data);
        setSuccessStatus("Task updated");
      }).catch(error => {
        setErrorStatus("Could not update task.");
      });
    }
  }

  const [statusOpen, setStatusOpen] = useState(false);
  const [statusText, setStatusText] = useState(null);
  const [statusSeverity, setStatusSeverity] = useState("error")

  function handleStatusClose() {
    setStatusOpen(false);
    setStatusText(null);
  }

  const [deleteTaskOpen, setDeleteTaskOpen] = useState(false);
  function deleteTaskAgree() {
    api.getTaskAPI().deleteTask(task.id).then(response => {
      if (props.deleteTask) {
        props.deleteTask(task, true, false);
      }
    }).catch(error => {
      console.log(error);
    });

    setDeleteTaskOpen(false);
  }

  function deleteTaskDisagree() {
    setDeleteTaskOpen(false);
  }

  function cancel() {
    if(!window.confirm('Are you sure you want to close this form? Any unsaved data will be lost.')) {
      return;
    }

    history.goBack();
  }

  function isLogTask(task) {
    return props.activePlan.events.find(_event => _event.event_type === "LOG" && task.event === _event.id) != null;
  }

  return (
    <Dialog fullScreen open={true} PaperProps={{style: {backgroundColor: '#F8F8F8'}}}>
      <Prompt 
        open={deleteTaskOpen}
        agree={deleteTaskAgree}
        disagree={deleteTaskDisagree}
        title="Delete Task?"
        description="Are you sure you want to delete this task? This action cannot be reversed. 
        Deleting this task will not change any due or previously completed monitoring records."
      />
      <DialogTitle style={{ borderBottom: '1px solid #eaeaea', padding: '0px'}}>
        <Grid container item xs={12} style={{padding: '16px'}}>
          <Grid container item xs={8} jusify="flex-start" alignItems="center">
            <Typography variant="h6">
              Task
            </Typography>
          </Grid>
          <Grid item container xs={4} justify="flex-end" alignItems="center">
            <IconButton onClick={() => { history.goBack() }} style={{padding: 0}}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px 8px'}}>
        {loading &&
          <Grid container item xs={12} justify="center" alignItems="center">
            <CircularProgress />
          </Grid>
        }
        {!loading && task &&
          <Grid container spacing={3} style={{maxWidth: '1200px', padding: '16px'}}>
            <Grid item xs={12} style={{paddingBottom: '0px'}}>
              <Typography style={{fontSize: '14px', fontWeight: 500}}>
                Task Name
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <WhiteTextField onChange={handleChange} name="name" fullWidth value={task.name || ''} />
            </Grid>
            <Grid item xs={12} style={{paddingBottom: '0px'}}>
              <Typography style={{fontSize: '14px', fontWeight: 500}}>
                Task Description
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <WhiteTextField multiline rows={3} onChange={handleChange} name="description" fullWidth value={task.description || ''} />
            </Grid>
            <Grid item xs={12} style={{paddingBottom: '0px'}}>
              <Typography style={{fontSize: '14px', fontWeight: 500}}>Standard Operating Procedure (SOP)</Typography>
            </Grid>
            <Grid item xs={12}>
              <div id="task-editor" style={{backgroundColor: 'white'}}>
                <ReactQuill
                  theme="snow"
                  value={task.procedures}
                  onChange={handleQuillChange}
                  modules={{
                    toolbar: toolbarOptions
                  }}
                />
              </div>
            </Grid>
          </Grid>
        }
      </DialogContent>
      
      <DialogActions>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={6}>
            {task && task.id != null && !isLogTask(task) &&  
              <Button color="secondary" variant="outlined" onClick={() => setDeleteTaskOpen(true)}>
                Delete
              </Button>
            }
          </Grid>
          <Grid item container xs={6} justify="flex-end">
            <Button color="secondary" variant="outlined" style={{margin: '4px'}} onClick={cancel}>Cancel</Button>
            {params.task_pk !== "new" && <Button color="primary" variant="outlined" style={{ margin: '4px' }} onClick={saveChanges}>Save Changes</Button>}
            <Button color="primary" variant="contained" style={{ margin: '4px' }} onClick={saveAndContinue}>Save and Continue</Button>
          </Grid>
        </Grid>
      </DialogActions>

      <Snackbar
        open={statusOpen}
        autoHideDuration={6000}
        onClose={handleStatusClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert severity={statusSeverity} onClose={() => { handleStatusClose(); }}>
          {statusText}
        </Alert>
      </Snackbar>
    </Dialog>
  )
}
