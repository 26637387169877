class RecallAPI {
  constructor(api, version) {
    this.api = api;
    this.version = version;
  }
  
  listRecalls(pk) {
    if (typeof pk === 'number') {
      return this.api.get(`${this.version}/recalls/${pk}/`);
    }
 
    return this.api.get(`${this.version}/recalls/`)
  }
  
  createRecall(payload) {
    return this.api.post(`${this.version}/recalls/`, payload);
  }
  
  updateRecall(payload) {
    return this.api.put(`${this.version}/recalls/${payload.pk}/`, payload);
  }
  
  deleteRecall(pk) {
    return this.api.delete(`${this.version}/recalls/${pk}/`);
  }
  
}
  
export default RecallAPI;