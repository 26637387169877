import React, { useState } from "react";

import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from "@material-ui/core/MenuItem";
import HistoryModal from "../HACCP/History/HistoryModal";
import AlertDialog from "./AlertDialog";
import Button from "@material-ui/core/Button";

export default function VerticalDotMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // const [modalOpen, setModalOpen] = useState(false);

  // function handleModalClosed() {
  //   setModalOpen(false);
  // }

  const [historyModalOpen, setHistoryModalOpen] = useState(false);

  function handleHistoryModalOpened() {
    setHistoryModalOpen(true);
  }

  function handleHistoryModalClosed() {
    setHistoryModalOpen(false);
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    //handleModalClosed();
  };

  return (
    <div style={props.style}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {props.white &&
          <MoreVertIcon style={{ color: 'white' }} />
        }

        {!props.white &&
          <MoreVertIcon />
        }
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        // onChange={handleClose}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: 200,
          },
        }}
      >
        {props.removeInstance &&
          <MenuItem onClick={props.removeInstance}>Remove</MenuItem>
        }

        {props.api &&
          <MenuItem onClick={handleHistoryModalOpened}>
            View history
        </MenuItem>
        }

        {props.exportPlan &&
          <Button style={{ margin: '8px' }}
            variant="contained"
            color="primary"
            target="_blank"
            href={`https://haccp-backend-test.herokuapp.com/export_plan/${props.instance.pk}`}
            rel="noopener noreferrer"
          >
            Export Plan to PDF
          </Button>
        }

        {props.deleteInstance &&
          <AlertDialog buttonText={`Delete`}
            buttonVariant='contained'
            color='secondary'
            dialogTitle={`Are you sure you want to delete this ${props.instanceName}?`}
            dialogDescription={`This action cannot be reversed.`}
            instancePk={props.instance.pk}
            menu={true}
            agreeHandler={() => {
              props.deleteInstance(props.instance.pk)
            }}
            agreeText='Delete'
            disagreeText='Cancel'
            style={{ margin: '8px'}}
          />
        }
      </Menu>

      {/*{props.getTemplates &&*/}
      {/*<TemplateSelect button_text='Select Template' title={props.template_title}*/}
      {/*                handleModalClosed={handleModalClosed}*/}
      {/*                description='Select a template' open={modalOpen} table={{*/}
      {/*  cols: props.template_cols,*/}
      {/*  getData: props.getTemplates,*/}
      {/*  selectTemplate: selectTemplate,*/}
      {/*}}/>*/}
      {/*}*/}

      {props.api && props.instance &&
        <HistoryModal pk={props.instance.pk} api={props.api}
          setProductDescription={props.setProductDescription} setHazard={props.setHazard}
          setIngredient={props.setIngredient} setFormulation={props.setFormulation} save={props.save}
          saveData={props.saveData} setStatus={props.setStatus}
          setTrafficFlowDiagram={props.setTrafficFlowDiagram}
          setProcessFlowDiagram={props.setProcessFlowDiagram} setupCanvas={props.setupCanvas}
          setCCP={props.setCCP} setProcess={props.setProcess} canvas={props.canvas} changeMode={props.changeMode}
          initializeCanvas={props.initializeCanvas} addProcessListeners={props.addProcessListeners}
          gridEnabled={props.gridEnabled} open={historyModalOpen} handleModalClosed={handleHistoryModalClosed} />
      }
    </div>
  )
}
