class UnitsAPI {
    constructor(api, version) {
      this.api = api;
      this.version = version;
    }

    listGlobalUnits() {

      return this.api.get(`${this.version}/units/list_global_units/`)
    }
  
    listUnits(pk) {
      if (typeof spk === 'number') {
        return this.api.get(`${this.version}/units/${pk}/`);
      }
  
      return this.api.get(`${this.version}/units/`)
    }

    listWeightUnits() {
  
      return this.api.get(`${this.version}/units/list_weight_units/`)
    }

    listVolumeUnits() {
  
      return this.api.get(`${this.version}/units/list_volume_units/`)
    }

    listQuantityUnits() {
  
      return this.api.get(`${this.version}/units/list_quantity_units/`)
    }
  
    createUnit(payload) {
      return this.api.post(`${this.version}/units/`, payload);
    }
  
    updateUnit(payload) {
      return this.api.put(`${this.version}/units/${payload.pk}/`, payload);
    }
  
    deleteUnit(pk) {
      return this.api.delete(`${this.version}/units/${pk}/`);
    }
  
  }
  
  export default UnitsAPI;