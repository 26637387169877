import React from "react";
import { useHistory, useRouteMatch, Switch, Route } from "react-router-dom";

import Programs from "Plans/PlanBuilder/Programs";
import Plans from "Plans/PlanManagement/Plans.js";
import MonitoringManager from "Monitoring/MyTasks/MonitoringManager";
import CreateOrganizationForm from "./CreateOrganization";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    myDiv: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    },
}));

export default function AdminRouter(props) {
    const match = useRouteMatch();
    const classes = useStyles();

    return (
        <div className={classes.myDiv}>
            <Switch>
                {/*<Route path={`${match.url}/program_templates`}>
                    <Programs user={props.user} />
                </Route>*/}
                <Route path={`${match.url}/add_org`}>
                    <CreateOrganizationForm />
                </Route>
                <Route path={`${match.url}/all_plans`}>
                    <Plans user={props.user} />
                </Route>
                {/*<Route path={`${match.url}/new_client`}>
                    <MonitoringManager user={props.user}></MonitoringManager>
    </Route>*/}
            </Switch>
        </div >
    )
}