import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import "chartjs-plugin-zoom";
import { Slide } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles({
  gridcontainer: {
    padding: "32px",
  },
  nestedcontainer: {
    padding: 0,
    paddingTop: 20,
  },
  griditem: {
    marginBottom: "18px",
  },
  outlinedbutton: {
    color: "#18bff6",
  },
  textfieldbackground: {
    background: "#fafafc",
  },
  pagetitle: { paddingLeft: "38px", alignSelf: "center" },
  previewPaper: { margin: 32, height: "100%", width: "auto", padding: "12px" },
});

function EventPreview(props) {
  let history = useHistory();
  const [event, setEvent] = useState(null);
  const classes = useStyles();
  useEffect(() => {
    if (props.event) {
      setEvent(props.event);
    }
  }, [props.open, props.event]);

  function goToEvent(pk) {
    history.push(`plan_manager/events/${pk}`);
  }

  return (
    <div>
      {" "}
      <Paper
        elevation={0}
        square
        variant="outlined"
        className={classes.previewPaper}
      >
        <Grid item container xs={12} alignItems="center" justify="flex-end">
          <IconButton
            aria-label="cancel"
            onClick={props.cancel}
            style={{ padding: 0 }}
          >
            <CancelIcon />
          </IconButton>
        </Grid>
        <Grid container spacing={3} style={{ padding: "12px" }}>
          <Grid item xs={8} className={classes.pagetitle}>
            {event && (
              <Grid container spacing={2}>
                <Grid container item xs={12}>
                  <Typography variant="h6">
                    Event Preview:{" "}
                    {event.event_name ? event.event.name : "UnNamed Event"}
                  </Typography>
                </Grid>

                <Grid container item xs={12}>
                  <Typography>
                    {" "}
                    Date Submitted: {event.date_submitted}
                  </Typography>
                </Grid>

                <Grid container item xs={12}>
                  <Typography>
                    {" "}
                    Time Submitted: {event.time_submitted}
                  </Typography>
                </Grid>
                <Grid container item xs={12}>
                  <Typography> Submitted By: {event.submitted_by}</Typography>
                </Grid>
                <Grid container item xs={12}>
                  <Link
                    href="#"
                    onClick={() => goToEvent(event.id)}
                    color="#18bff6"
                  >
                    Link To Event
                  </Link>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default EventPreview;
