import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import React, { useState, useEffect } from "react";
import { useHistory, useParams} from "react-router-dom";
import Button from "@material-ui/core/Button";
import Traceability from "./Traceability";
import Message from "../Components/Message";

const EMPTY_MESSAGE = {
  open: false,
  message: '',
  status: 'info',
};

function TraceabilityDetails(props) {
  let history = useHistory();
  let params = useParams();
  const [traceabilityInstance, setTraceabilityInstance] = useState(null);
  const [message, setMessage] = useState( {...EMPTY_MESSAGE} );

  useEffect(() => {
    if(props.traceabilityInstance) {
      setTraceabilityInstance(props.traceabilityInstance);
    }
    else {
      const api = new Traceability().getInstanceManagerAPI();
      api.listTraceabilities(params.pk).then((response) => {
        setTraceabilityInstance(response.data[0]);
        if(props.setTraceability) {
          props.setTraceability(response.data[0]);
        }
      })
      .catch((error) => {
        setMessage({open: true, message: 'Server Error: ' + error.message, status: 'error'});
      })
    }
    
  }, [])

  function handleTextFieldChange(event) {
    const property = event.target.name;
    const value = event.target.value;

    setTraceabilityInstance({ ...traceabilityInstance, [property]: value });
  }

  function handleSubmit(e, saveAndContinue) {
    console.log(e);
    e.preventDefault();
    const api = new Traceability().getInstanceManagerAPI();
    api.updateTraceability(traceabilityInstance).then((response) => {
      setTraceabilityInstance(response.data);
      if(props.setTraceability) {
        props.setTraceability(response.data);
      }
      setMessage({open: true, message: 'Update Successful.', status: 'success'});

      if(saveAndContinue) {
        handleSaveAndContinue(e);
      }
    })
    .catch((error) => {
      setMessage({open: true, message: 'Server Error: ' + error.message, status: 'error'});
    });
  }

  function handleSaveAndContinue(e) {
    //handleSubmit(e);
    let nextUrl = props.baseURL + '/' + params.pk;

    history.push(`${nextUrl}/orders`);
  }

  return (
    <>
      {traceabilityInstance &&
        <Paper style={{"margin": "8px", "padding": "16px"}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6">
                Traceability Details
              </Typography>
              Enter details about this Traceability Instance.
            </Grid>
            <Grid item xs={12}>
              <TextField label="Name" name="name" variant="outlined" fullWidth
                value={traceabilityInstance.name}
                onChange={handleTextFieldChange} 
              />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Description" name="description" variant="outlined" multiline
                value={traceabilityInstance.description} fullWidth rows={4}
                onChange={handleTextFieldChange} 
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "8px" }}
                onClick={(e) => {handleSubmit(e, false)}}
              >
                Save Changes
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "8px" }}
                onClick={(e) => {handleSubmit(e, true)}}
              >
                Save And Continue
              </Button>
            </Grid>
          </Grid>
        </Paper>
      }

      <Message
        open={message.open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="right"
        handleClose={() => { setMessage( {...message, open:false} ) }}
      />
    </>
  )
}

export default TraceabilityDetails;
