class FormAPI {
    constructor(api, version, name) {
        this.api = api;
        this.version = version;
        this.name = "FormAPI";
    }

    listForms(haccpPlanPk = null) {
        if (haccpPlanPk) {
            return this.api.get(
                `${this.version}/forms/?haccp_plan_pk=${haccpPlanPk}`
            );
        }

        return this.api.get(`${this.version}/forms/`);
    }

    createForm(payload) {
        return this.api.post(`${this.version}/forms/`, payload);
    }

    retrieveForm(pk) {
        return this.api.get(`${this.version}/forms/${pk}/`);
    }

    updateForm(payload) {
        return this.api.put(`${this.version}/forms/${payload.pk}/`, payload);
    }

    uploadImage(payload) {
        return this.api.post(`${this.version}/form_image/`, payload);
    }
    listImages(pk) {
        return this.api.get(`${this.version}/form_image/?pk=${pk}`);
    }

    deleteForm(pk) {
        return this.api.delete(`${this.version}/forms/${pk}/`);
    }

    updateFormInstance(form) {
        return this.api.post(`${this.version}/forminstance/${form.pk}/`, form);
    }

    createFormInstance(form) {
        return this.api.post(`${this.version}/forminstance/`, form);
    }

    listFormInstances(haccpPlanPk = null) {
        if (haccpPlanPk) {
            return this.api.get(
                `${this.version}/forminstance/?haccp_plan_pk=${haccpPlanPk}`
            );
        }

        return this.api.get(`${this.version}/forminstance/`);
    }

    deleteFormInstance(pk) {
        return this.api.delete(`${this.version}/forminstance/${pk}/`);
    }
}

export default FormAPI;
