class FCustomerAPI {
    constructor(api, version) {
        this.api = api;
        this.version = version;
    }

    listCustomer(id) {
        if (typeof id === 'number') {
            return this.api.get(`${this.version}/f_customers/${id}/`);
        }
        return this.api.get(`${this.version}/f_customers/`)
    }

    createCustomer(payload) {
        return this.api.post(`${this.version}/f_customers/`, payload);
    }

    updateCustomer(payload) {
        return this.api.put(`${this.version}/f_customers/${payload.id}/`, payload);
    }

    deleteCustomer(id) {
        return this.api.put(`${this.version}/f_customers/${id}/soft_delete_action/`);
    }

}

export default FCustomerAPI;