class CustomerAPI {
  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listCustomers(pk) {
    if (typeof pk === 'number') {
      return this.api.get(`${this.version}/customers/${pk}/`);
    }

    return this.api.get(`${this.version}/customers/`)
  }

  listCustomersDisplay(pk) {
    if (typeof pk === 'number') {
      return this.api.get(`${this.version}/customers_display/${pk}/`);
    }

    return this.api.get(`${this.version}/customers_display/`);
  }

  createCustomer(payload) {
    return this.api.post(`${this.version}/customers/`, payload);
  }

  updateCustomer(payload) {
    return this.api.put(`${this.version}/customers/${payload.pk}/`, payload);
  }

  softDeleteCustomer(payload) {
    return this.api.put(`${this.version}/customers/${payload.pk}/soft_delete_action/`, payload);
  }

  deleteCustomer(pk) {
    return this.api.delete(`${this.version}/customers/${pk}/`);
  }
}

export default CustomerAPI