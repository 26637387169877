import React from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={1} variant="filled" {...props} />;
}

export default function Message(props) {

  return (
    <div>
      {props.position && (
        <Snackbar
          style={{ zIndex: 200000000, bottom: "65px" }}
          anchorOrigin={{
            vertical: props.vertical ? props.vertical : "bottom",
            horizontal: props.horizontal ? props.horizontal : "right",
          }}
          open={props.open}
          autoHideDuration={2500}
          onClose={props.handleClose}
        >
          <Alert
            onClose={props.handleClose}
            severity={props.severity}
            style={{ zIndex: 20000 }}
          >
            {props.message}
          </Alert>
        </Snackbar>
      )}
      {!props.position && (
        <Snackbar
          style={{ zIndex: 20000}}
          anchorOrigin={{
            vertical: props.vertical ? props.vertical : "bottom",
            horizontal: props.horizontal ? props.horizontal : "right",
          }}
          open={props.open}
          autoHideDuration={2500}
          onClose={props.handleClose}
        >
          <Alert
            onClose={props.handleClose}
            severity={props.severity}
            style={{ zIndex: 20000 }}
          >
            {props.message}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
