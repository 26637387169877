class FormulationsAPI {

  constructor(api, version, name) {
    this.api = api;
    this.version = version;
    this.name = name;
  }

  listFormulations() {
    return this.api.get(`${this.version}/formulations/`)
  }

  createFormulation(payload) {
    return this.api.post(`${this.version}/formulations/`, payload);
  }

  retrieveFormulation(pk) {
    return this.api.get(`${this.version}/formulations/${pk}/`);
  }

  updateFormulation(payload) {
    return this.api.put(`${this.version}/formulations/${payload.pk}/`, payload);
  }

  deleteFormulation(pk) {
    return this.api.delete(`${this.version}/formulations/${pk}/`);
  }

  softDeleteFormulation(payload) {
    return this.api.put(`${this.version}/formulations/${payload.pk}/soft_delete_action/`, payload);
  }

  listHaccpPlanIngredients(haccpPlanPk) {
    return this.api.get(`${this.version}/formulations/haccp_plan_ingredients/?haccp_plan_pk=${haccpPlanPk}`);
  }

  getFormulationWithIngredientsAndSkus() {
    return this.api.get(`${this.version}/formulations_ingredient_and_sku/`);
  }

  retrieveFormulationWithIngredientsAndSkus(pk) {
    return this.api.get(`${this.version}/formulations_ingredient_and_sku/${pk}`);
  }

  createFormulationSku(payload) {
    return this.api.post(`${this.version}/formulations_ingredient_and_sku/`, payload);
  }

  updateFormulationIngredient(payload) {//updateFormulationSku
    return this.api.put(`${this.version}/formulation_and_ingredient/${payload.pk}/`, payload);
  }

  
  //  MADE TO ELIMINATE SKUS FROM THE FORMULATIONS 
  // start
  getFormulationIngredients() {
    return this.api.get(`${this.version}/formulations_ingredient/`);
  }

  retrieveFormulationIngredients(pk) {
    return this.api.get(`${this.version}/formulations_ingredient/${pk}`);
  }

  createFormulationIngredients(payload) {
    return this.api.post(`${this.version}/formulations_ingredient/`, payload);
  }
  updateFormulationIngredients(payload) {
    return this.api.put(`${this.version}/formulations_ingredient/${payload.pk}/`, payload);
  }
  // end


  copyFormulation(pk) {
    return this.api.post(`${this.version}/formulations/${pk}/copy/`);
  }

  selectTemplate(pk, template) {
    return this.api.post(`${this.version}/formulations/${pk}/select_template/`, template);
  }

  addIngredientToFormulation(formulation_pk, ingredient) {
    return this.api.post(`${this.version}/formulations/${formulation_pk}/add_ingredient/`, ingredient);
  }

  removeIngredientFromFormulation(formulation_pk, ingredient) {
    return this.api.post(`${this.version}/formulations/${formulation_pk}/remove_ingredient/`, ingredient);
  }

  getFormulationWithIngredients() {
    return this.api.get(`${this.version}/formulations_and_ingredient/`);
  }

  

  getHistory(pk) {
    return this.api.get(`${this.version}/formulations/${pk}/get_history/`);
  }

  getRevertedFormulation(pk, date) {
    return this.api.post(`${this.version}/formulations/${pk}/get_reverted_formulation/`, date);
  }

  getPackagings(pk) {
    return this.api.get(`${this.version}/formulations/${pk}/get_packages/`);
  }

  setPackagingToFormulation(formulation_pk, packagings) {
    return this.api.post(`${this.version}/formulations/${formulation_pk}/add_packaging/`, packagings);
  }

}


export default FormulationsAPI;
