class CcpDeterminationWorksheetAPI {

  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listCCPDeterminationWorksheets() {
    return this.api.get(`${this.version}/ccp_determinations/`)
  }

  createCCPDeterminationWorksheet(payload) {
    return this.api.post(`${this.version}/ccp_determinations/`, payload);
  }

  retrieveCCPDeterminationWorksheet(pk) {
    return this.api.get(`${this.version}/ccp_determinations/${pk}/`);
  }

  updateCCPDeterminationWorksheet(payload) {
    return this.api.put(`${this.version}/ccp_determinations/${payload.pk}/`, payload);
  }

  deleteCCPDeterminationWorksheet(pk) {
    return this.api.delete(`${this.version}/ccp_determinations/${pk}/`);
  }

  getHistory(pk) {
    return this.api.get(`${this.version}/ccp_determinations/${pk}/get_history/`);
  }
}


export default CcpDeterminationWorksheetAPI;
