class HazardDatasetAPI {

  constructor(api, version) {
    this.api = api;
    this.version = version;
    this.path = `${this.version}/hazard_dataset`
  }

  listDataset() {
    return this.api.get(`${this.path}/`)
  }

  retrieveDataRow(pk) {
    return this.api.get(`${this.path}/${pk}/`);
  }

  selectData(pk, template) {
    return this.api.post(`${this.path}/${pk}/select_row/`, template);
  }

  getHistory(pk) {
    return this.api.get(`${this.path}/${pk}/get_history/`);
  }
}


export default HazardDatasetAPI;
