import React, { useState } from 'react';
import MultiLotTableSingle from "./MultiLotTableSingle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import PropTypes from "prop-types";
import { Grid } from '@material-ui/core';
import {StylesContext} from "../../App";
import Button from "@material-ui/core/Button";
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MultiLotTables(props) {
  
  const classes = React.useContext(StylesContext);

  const [modalOpen, setModalOpen] = useState( false );
  const [editingTable, setEditingTable] = useState( undefined );
  const [editingRow, setEditingRow] = useState( undefined );

  //Return a closure.
  function returnFunction(i) {
    return function(newData){
      let newTableData = [...props.tableData];
      newTableData[i] = newData;
      
      props.setLineData(newTableData);
    }
  }

  //Create an array of functions to set the data in each row, to be passed to each row.
  function getRowDataFunctions() {
    var funcArray = [];

    let length = props.tableData ? props.tableData.length : 0

    //Using a for loop here for clarity and to be able to use "let" for the iterator.
    for(let i = 0; i < length; i++) {
      funcArray.push( returnFunction(i) );
    }

    return funcArray;
  }

  function addLotClick() {

    setEditingTable(undefined);
    setEditingRow(undefined);
    setModalOpen(true);
  }


  function makeTables() {
    let rowFunctions = getRowDataFunctions();
    return props.tableData.map((tableInfo, i) => {

      return (
        <Grid item xs={12} key={i} className={classes.multiLotTableSingleGrid}>
          <MultiLotTableSingle
            key={i}
            headerInfo={{
              headTitle: tableInfo[props.header.key],  
              headValue: tableInfo[props.header.comparativeParentField], 
              headUnit:  tableInfo[props.header.comparativeParentUnit],
            }}
            tableData={tableInfo[props.eachTableDataKey]}
            setTableData={rowFunctions[i]}
            header={props.header}
            columns={props.columns}
            disabled={props.disabled}
            faded={props.faded}
            units={props.units}
            validating={props.validating}
            batches={props.batches}

            setEditingTable={() => {setEditingTable(i)}}
            setEditingRow={setEditingRow}
            setModalOpen={setModalOpen}
          />
        </Grid>
      )
    })
  }

  return (
    <>
      {props.columns &&
        <Grid container spacing={3}>
          { makeTables() }
          <Grid item container xs={12} alignItems="center" justify="flex-end" className={classes.multiLotTableFooter}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "8px" }}
              onClick={() => { addLotClick() }}
              disabled={props.disabled}
            >
              Add Lot
            </Button>
          </Grid>
        </Grid>
      }

      <Dialog fullScreen open={modalOpen} onClose={() => {setModalOpen(false)}} maxWidth={"lg"} TransitionComponent={Transition}>
        <DialogContent className={classes.generalFormDialogueContainer}>
          <props.modal
            tableData={props.tableData}
            setLineData={props.setLineData}
            editingTable={editingTable}
            editingRow={editingRow}
            closeModal={() => {setModalOpen(false)}}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

MultiLotTables.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object),
  setLineData: PropTypes.func,
  header: PropTypes.object,
  columns: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  faded: PropTypes.bool,
  eachTableDataKey: PropTypes.string,
  validating: PropTypes.bool,
}
