class BatchAPI {
    constructor(api, version) {
      this.api = api;
      this.version = version;
    }
  
    listBatches(pk) {
      if (typeof pk === 'number') {
        return this.api.get(`${this.version}/batch_sheets/${pk}/`);
      }
  
      return this.api.get(`${this.version}/batch_sheets/`)
    }

    listBatchesToEdit(pk) {
      if (typeof pk === 'number') {
        return this.api.get(`${this.version}/batch_sheets_to_edit/${pk}/`);
      }
  
      return this.api.get(`${this.version}/batch_sheets_to_edit/`)
    }
  
    createBatch(payload) {
      return this.api.post(`${this.version}/batch_sheets/`, payload);
    }
  
    updateBatch(payload) {
      return this.api.put(`${this.version}/batch_sheets/${payload.pk}/`, payload);
    }

    /**
    * Sets the status of the batch sheet following the pattern Draft -> Scheduled -> Ongoing -> On hold -> Complete
    * @param {*} pk - The primary key of the order to update.
    */
    updateBatchStatus(pk) {
      return this.api.get(`${this.version}/batch_sheets/${pk}/update_status/`);
    }
  
    deleteBatch(pk) {
      return this.api.delete(`${this.version}/batch_sheets/${pk}/`);
    }

    //todo probably can delete
    getBatchDifferences(pk) {
      if (typeof pk === 'number') {
        return this.api.get(`${this.version}/batch_sheet_differences/${pk}/`);
      }
  
      return this.api.get(`${this.version}/batch_sheet_differences/`)
    }
  }
  
  export default BatchAPI