class CrossContaminationPointTemplateAPI {

  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listCrossContaminationPoints(haccp_plan_pk) {
    if (typeof haccp_plan_pk === 'number') {
      return this.api.get(`${this.version}/cross_contamination_point_templates/?haccp_plan_pk=${haccp_plan_pk}`);
    }

    return this.api.get(`${this.version}/cross_contamination_point_templates/`);
  }

  createCrossContaminationPoint(payload) {
    return this.api.post(`${this.version}/cross_contamination_point_templates/`, payload);
  }

  retrieveCrossContaminationPoint(pk) {
    return this.api.get(`${this.version}/cross_contamination_point_templates/${pk}/`);
  }

  updateCrossContaminationPoint(payload) {
    return this.api.put(`${this.version}/cross_contamination_point_templates/${payload.pk}/`, payload);
  }

  deleteCrossContaminationPoint(pk) {
    return this.api.delete(`${this.version}/cross_contamination_point_templates/${pk}/`);
  }

  selectTemplate(pk, template) {
    return this.api.post(`${this.version}/cross_contamination_point_templates/${pk}/select_template/`, template);
  }

  addHazardToCrossContaminationPoint(cross_contamination_point_pk, hazard) {
    return this.api.post(`${this.version}/cross_contamination_point_templates/${cross_contamination_point_pk}/add_hazard/`, hazard);
  }

  removeHazardFromCrossContaminationPoint(cross_contamination_point_pk, hazard) {
    return this.api.post(`${this.version}/cross_contamination_point_templates/${cross_contamination_point_pk}/remove_hazard/`, hazard);
  }

  getHistory(pk) {
    return this.api.get(`${this.version}/cross_contamination_point_templates/${pk}/get_history/`);
  }
}


export default CrossContaminationPointTemplateAPI;
