import React from 'react';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import DetailHeaderCell from "./DetailHeaderCell";
import PropTypes from "prop-types";
import {StylesContext} from "../../App";
  

export default function DetailRow(props) {
  const classes = React.useContext(StylesContext);

  //Make each cell in the row, one cell per field.
  function makeCells() {
    let detailCol = props.colInfo[props.colInfo.length - 1];
    if(detailCol.type != 'detail' && detailCol.type != 'detailAuto') {
      throw 'Please place detail column last.'
    } 

    return props.colInfo.map((cellData, i) => {
      return (
        <DetailHeaderCell 
          key={i}
          colInfo={props.colInfo[i]}
          disabled={props.disabled}
          faded={props.faded}
        />
      )
    })
  }

  return (
    <>
      {props.colInfo &&
        <Paper square className={classes.detailFormRow} style={{marginTop: '12px', backgroundColor: props.faded ? 'grey' : '#FFF'}}>
          <Grid container spacing={3}>
            {makeCells()}
          </Grid>
        </Paper>
      }
    </>
  )
}

DetailRow.propTypes = {
  rowData: PropTypes.object,
  setRowData: PropTypes.func,
  setLineData: PropTypes.func,
  colInfo: PropTypes.object,
  detailInfo: PropTypes.object,
  onDetailClick: PropTypes.func,
  size: PropTypes.number,
  disabled: PropTypes.bool,
  faded: PropTypes.bool,
}