import React, { useState, useEffect } from "react"

import { DialogContent } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

import Process from "../Process/Process";
import EditHazardModal from "../Templates/Hazards/EditHazardModal";
import EditHazardTemplateModal from "../Templates/Hazards/EditHazardTemplateModal";

import API from "../../Api/Api";
import ProcessDatasetTable from "../Process/ProcessDatasetTable";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import useDesktop from "../../hooks/useDesktop";

function EditProcessModal(props) {

  const [hazardModalOpen, setHazardModalOpen] = useState(false);
  const [hazardSelected, setHazardSelected] = useState(null);
  const onDesktop = useDesktop();


  const [shouldUpdateProcess, setShouldUpdateProcess] = useState(false);
  const [shouldClose, setShouldClose] = useState(false);

  let api = new API();

  function handleHazardModalOpen() {
    setHazardModalOpen(true);
  }

  function handleHazardModalClosed(check) {
    if (!shouldClose) {
      setHazardModalOpen(false);
      setShouldUpdateProcess(true);
    } else {
      alert("Save changes before closing.")
    }
  }

  function modelClose() {
    setHazardModalOpen(false);
    setShouldUpdateProcess(true);

  }

  function hazardPicked(hazard) {
    handleHazardModalOpen();
    setHazardSelected(hazard);
  }

  function getAPI() {
    if (props.templateMode) {
      return api.getProcessTemplateAPI();
    }

    return api.getProcessAPI();
  }

  function save(process) {
    getAPI().updateProcess(process).then(e => {
      props.setProcessText(e.data.pk, e.data.name);
    }).catch(e => {
      console.log("error", e);
    });
  }

  function saveAndContinue(process) {
    setShouldClose(false);
    props.setShouldClose(false);
    getAPI().updateProcess(process).then(e => {

      props.setProcessText(e.data.pk, e.data.name);
      props.modelClose();

    }).catch(e => {
      console.log("error", e);
    });
  }

  function cancel() {
    setShouldClose(false);
    props.setShouldClose(false);
    props.modelClose();
  }

  function hazardHasChanged(hasChanged) {
    props.setShouldClose(hasChanged);
  }

  // TODO merge process template component and process component like this one
  return (
    <Dialog
    open={props.open}
    onClose={cancel}
    maxWidth="md"
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullScreen={!onDesktop}
  >
    <DialogContent>
      <Process
        deleteMode={false}
        pk={props.process_pk}
        open={props.open}
        cancel={cancel}
        setShouldClose={hazardHasChanged}
        shouldUpdate={shouldUpdateProcess}
        setShouldUpdate={setShouldUpdateProcess}
        save={save}
        backButton={false}
        saveAndContinue={saveAndContinue}
        hazardSelected={hazardSelected}
        setHazardSelected={hazardPicked}
        loadProcessCCPs={props.loadProcessCCPs}
      />
    </DialogContent>
    </Dialog>
  )
}


export default EditProcessModal;
