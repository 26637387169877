import React, { useEffect, useState, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import API from "../../Api/Api";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import HaccpPlanDetailsModal from "./HaccpPlanDetailsModal";

import Card from "@material-ui/core/Card";
import Button from '@material-ui/core/Button';
import Select from "@material-ui/core/Select";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Message from "../../Components/Message";
import CardActionArea from "@material-ui/core/CardActionArea";

import { makeStyles } from "@material-ui/core/styles";
import { OrgContext } from "hooks/useOrganization"
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  haccpMenu: {
    width: "100%",
    height: "calc(100% + 70px)",
  },
  iconButton: {
    color: "#687895",
    backgroundColor: "transparent",
    marginTop: "5px",
  },
  newPlanText: {
    marginTop: '4px',
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#687895",
    textTransform: 'none',
    opacity: 1,
  },
  fullWidth: {
    width: "100%",
  },
  diabledCards: {},
}));

HaccpSideMenu.propTypes = {
  disabled: PropTypes.bool,
  haccpPlan: PropTypes.object,
  user: PropTypes.object,
};

function HaccpSideMenu(props) {
  const classes = useStyles();
  const org = useContext(OrgContext);
  const api = new API();
  let history = useHistory();
  const match = useRouteMatch();
  const [haccpPlan, setHaccpPlan] = useState(props.haccpPlan ? props.haccpPlan : null);

  const [haccpPlans, setHaccpPlans] = useState([
    { pk: 1, name: "HACCP Plan Name" },
  ]);

  const [activeHaccpPlan, setActiveHaccpPlan] = useState(null);
  const haccpMenu = [
    {
      name: "details",
      label: "Plan Details",
      path: `${match.url}/details`,
    },
    {
      name: "description",
      label: "Product Description",
      path: `${match.url}/description`,
    },
    {
      name: "formulation",
      label: "Formulations",
      path: `${match.url}/formulation`,
    },
    {
      name: "processes",
      label: "Processes",
      path: `${match.url}/processes`,
    },
    {
      name: "process_flow",
      label: "Process Flow Diagram",
      path: `${match.url}/process_flow`,
    },
    {
      name: "traffic_flow",
      label: "Traffic Flow Diagram",
      path: `${match.url}/traffic_flow`,
    },
    {
      name: "hazards",
      label: "Hazard Analysis",
      path: `${match.url}/hazard_analysis/hazards`,
    },
    {
      name: "significant_hazards",
      label: "Significant Hazards",
      path: `${match.url}/hazard_analysis/significant_hazards`,
    },
    {
      name: "export",
      label: "Export",
      path: `${match.url}/export`,
    },
  ];

  function getAllHaccpPlans() {
    api
      .getHaccpPlanAPI()
      .listHaccpPlansMinimized()
      .then((e) => {
        setHaccpPlans(e.data);
        e.data.forEach((plan) => {
          if (plan.pk.toString() === match.params.pk) {
            setActiveHaccpPlan(plan.name);
          }
        });
      })
      .catch((e) => { });
  }

  const [clickedMenuItem, setClickedMenuItem] = useState(null);

  useEffect(() => {
    var segment_str = window.location.pathname;
    var segment_array = segment_str.split("/");
    var last_segment = segment_array.pop();
    getAllHaccpPlans();
    setClickedMenuItem(last_segment);
  }, [props, match]);

  useEffect(() => {
    if (clickedMenuItem) {
    checkActivePlan();
    }
  }, [match.params.pk]);

  function checkActivePlan() {
    haccpPlans.forEach((plan) => {
      if (plan.pk.toString() === match.params.pk) {
        setActiveHaccpPlan(plan.name);
      }
    });
  }

  function handlePlanChange(event) {
    haccpPlans.forEach((plan) => {
      if (plan.name === event.target.value) {
        setActiveHaccpPlan(plan.name);
         history.push(`/plans/manager/haccp_plans/${plan.pk}/product/details`);
      }
    });
    
  }

  const [haccpDetailsModalOpen, setHaccpDetailsModalOpen] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);

  const [message, setMessage] = useState({
    message: "",
    status: "info",
  }); // error, warning, info, info
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    if (!isBlocking) {
      setHaccpDetailsModalOpen(false);
    } else {
      setIsBlocking(false);
      alert("Save changes before closing.");
    }
  };
  function addHaccpPlan() {
    setHaccpDetailsModalOpen(true);
  }
  function saveDetails(name, description) {
    let newData = {
      organization: org.pk,
      name: name,
      description: description,
      created_by: props.user ? props.user.email : "",
    };
    api
      .getHaccpPlanAPI()
      .createHaccpPlan(newData)
      .then((e) => {
        history.push(`/plans/manager/haccp_plans/${e.data.pk}/product/details`);
        setHaccpDetailsModalOpen(false);
        setReturnStatus("HACCP Plan Created!", "info");
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function setReturnStatus(message, status) {
    setMessage({ message: message, status: status });
    setOpen(true);
  }
  function cancelDetails() {
    if (!isBlocking) {
      setHaccpDetailsModalOpen(false);
    } else {
      if (window.confirm("Do You Want To Discard Changes?")) {
        setHaccpDetailsModalOpen(false);
      }
    }
  }
  function handleMenuClick(e, menuItem) {
    if(props.closeSideMenu){
      props.closeSideMenu();
    }
    if (!props.disabled) {
      e.preventDefault();
      setClickedMenuItem(menuItem.name);
      if (!isBlocking) {
        history.push(menuItem.path);
      } else {
        if (window.confirm("Do You Want To Discard Changes?")) {
          history.push(menuItem.path);
        }
      }
    }
  }
  return (
    <Grid container direction="row" alignItems="flex-start">
      <HaccpPlanDetailsModal
        open={haccpDetailsModalOpen}
        save={saveDetails}
        setIsBlocking={setIsBlocking}
        cancel={cancelDetails}
        handleClose={handleClose}
      ></HaccpPlanDetailsModal>
      <Grid
        item
        xs={12}
        container
        direction="column"
        alignItems="center"
        justify="center"
        style={{ borderBottom: "1px solid #eaeaea", float: "inline-start" }}
      >
        <Grid container style={{ padding: '8px' }}>
          <Grid item xs={12}>
            <Button onClick={addHaccpPlan} color="default" startIcon={<AddIcon/>}>
              <Typography className={classes.newPlanText}>
                New HACCP Plan
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        style={{
          borderBottom: "1px solid #eaeaea",
          float: "inline-start",
          width: "100%",
        }}
      >
        <div style={{ height: "88px", width: "100%" }}>
          <Grid container className={classes.fullWidth}>
            <Grid item xs={12} className={classes.fullWidth}>
              <FormControl
                variant="outlined"
                style={{ margin: "18px", width: "calc(100% - 32px)" }}
              >
                {activeHaccpPlan && (
                  <Select
                    name="haccp_plan"
                    id="haccp_plan"
                    value={activeHaccpPlan}
                    onChange={handlePlanChange}
                    style={{ backgroundColor: "#FFFFFF" }}
                  >
                    {haccpPlans.map((plan) => {
                      return (
                        <MenuItem
                          aria-label="None"
                          key={plan.name}
                          value={plan.name}
                        >
                          {plan.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
                {!haccpPlan && !activeHaccpPlan && (
                  <Select
                    name="haccp_plan"
                    id="haccp_plan"
                    style={{ backgroundColor: "#FFFFFF" }}
                    value="HACCP Plan Name"
                  >
                    <MenuItem
                      aria-label="None"
                      key={1}
                      value={"HACCP Plan Name"}
                      style={{
                        color: "#687895",
                      }}
                    >
                      HACCP Plan Name
                    </MenuItem>
                  </Select>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} container className={classes.haccpMenu}>
        <div className={classes.haccpMenu}>
          <Grid container className={classes.haccpMenu}>
            {/* <Grid container style={{ width: "100%", overflow: "scroll" }}> */}
            {haccpMenu.map((menuItem) => {
              return (
                <Grid key={menuItem.name} item xs={12} className={classes.fullWidth}>
                  {clickedMenuItem && !props.disabled && (
                    <Card
                      elevation={0}
                      style={{
                        height: "48px",
                        margin: "20px",
                        marginBottom: "-8px",
                        width: "calc(100% - 36px)",
                        border: "1px solid white",
                        borderColor:
                          clickedMenuItem === menuItem.name
                            ? "rgba(24, 191, 247, 0.7)"
                            : "white",
                      }}
                      onClick={(e) => handleMenuClick(e, menuItem)}
                    >
                      <CardActionArea style={{ height: "48px" }}>
                        <Typography
                          style={{
                            marginTop: "2px",
                            fontFamily: "Roboto",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "21px",
                            opacity: 1,
                            color: "#687895",
                            marginLeft: "20px",
                          }}
                        >
                          {menuItem.label}
                        </Typography>
                      </CardActionArea>
                    </Card>
                  )}
                  {clickedMenuItem && props.disabled && (
                    <Card
                      elevation={0}
                      style={{
                        height: "48px",
                        margin: "20px",
                        marginBottom: "-8px",
                        width: "calc(100% - 36px)",
                        border: "1px solid white",
                        borderColor:
                          clickedMenuItem === menuItem.name
                            ? "rgba(24, 191, 247, 0.7)"
                            : "white",
                      }}
                    >
                      <Typography
                        style={{
                          marginTop: "12px",
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "21px",
                          opacity: 1,
                          color: "#687895",
                          marginLeft: "20px",
                        }}
                      >
                        {menuItem.label}
                      </Typography>
                    </Card>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Grid>
      <Message
        open={open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="right"
        handleClose={handleClose}
      />
    </Grid>
  );
}

export default HaccpSideMenu;
