import axios from "axios";


class ProcessFlowDiagramTemplateAPI {

  constructor(url, token, version) {
    this.token = token;

    this.api = axios.create({
      baseURL: url,
      headers: {
        'Authorization': `Token ${token}`,
      }
    });

    this.version = version;
  }

  listProcessFlowDiagramTemplates(haccp_plan_pk = null) {
    if (typeof haccp_plan_pk === 'number') { // Get process_flow_diagram_template in formulation
      return this.api.get(`${this.version}/process_flow_diagram_templates/?haccp_plan_pk=${haccp_plan_pk}`)
    }

    return this.api.get(`${this.version}/process_flow_diagram_templates/`)
  }

  createProcessFlowDiagramTemplate(payload) {
    return this.api.post(`${this.version}/process_flow_diagram_templates/`, payload);
  }

  retrieveProcessFlowDiagram(pk) {
    return this.api.get(`${this.version}/process_flow_diagram_templates/${pk}/`);
  }

  updateProcessFlowDiagram(payload) {
    return this.api.put(`${this.version}/process_flow_diagram_templates/${payload.pk}/`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  updateProcessFlowDiagramImage(pk, formData) {
    return this.api.put(`${this.version}/process_flow_diagram_templates/${pk}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  deleteProcessFlowDiagramTemplate(pk) {
    return this.api.delete(`${this.version}/process_flow_diagram_templates/${pk}/`);
  }

  getHistory(pk) {
    return this.api.get(`${this.version}/process_flow_diagram_templates/${pk}/get_history/`);
  }
}


export default ProcessFlowDiagramTemplateAPI;
