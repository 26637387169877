import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";import Button from "@material-ui/core/Button";

import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import API from "Api/Api";

import DeviationForm from "Monitoring/Deviations/DeviationForm";
import TabbableGroupedTable from "Components/GroupedTable/TabbableGroupTable";
import useDesktop from "hooks/useDesktop";
import EventMonitoringForm from "Monitoring/Events/EventRecordForm";
import MobileDeviationTasks from "Monitoring/MyTasks/DeviationTasks/MobileDeviationTasks";

import { OrgContext } from "hooks/useOrganization"

import PropTypes from 'prop-types';


const statuses = {
  counted: false,
  column: 'status',
  tabs:[
    {title: 'Incomplete', value: 'Incomplete'},
    {title: 'Complete', value: 'Complete'},
    {title: 'All', value: 'All'},
  ]
}

const columns = [
  { title: "Task/Event", field: "name"},
  { title: "Trigger", field: "reason"},
  { title: "Created", field: "created"},
  { title: "Submitted", field: "time_submitted"},
];

export default function DeviationTasks(props) {
  const [allTasks, setAllTasks] = useState([]);

  const history = useHistory();
  const match = useRouteMatch();

  const org = useContext(OrgContext); 

  const onDesktop = useDesktop();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllTasks();  
  }, [org]);
  
  function getAllTasks() {
    new API().getMonitoringAPI().getMyDeviations().then(response => {
      setAllTasks(response.data);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      console.log(error);
    });
  }

  function getActionButtonName(_rowData) {
    return "Record";
  }
  
  return (
    <>
      {loading &&
        <Grid container alignItems="center" justify="center" style={{width: '100%', height: '100%'}}>
          <Grid item container xs={12} alignItems="center" justify="center">
            <CircularProgress/>
          </Grid>
        </Grid>
      }

      {props.user && !onDesktop && !loading && 
        <MobileDeviationTasks user={props.user} tasks={allTasks} title={"All Deviations"} searchKeys={['name', 'reason']}/>
      }

      {props.user && onDesktop && !loading &&
        <Grid container style={{width: '100%'}}>
      <TabbableGroupedTable 
        noMinWidth={true}
        tabStatuses={statuses}
        columns={columns}
        data={allTasks}
        components={{
          Container: props => <Grid container> <Paper style={{width: '100%', height: '100%'}} {...props} elevation={0} /></Grid>
        }}
        title="All Deviations"
        emptyDataMessage="You have no deviations."
        actions={ 
          [
            rowData => ({
              icon: (_props) => {
                return (
                  <div style={{padding:"4px", paddingTop: "0px", paddingBottom: "0px", background: "#18bff6", borderRadius: "4px"}}>
                    <Typography style={{margin: '4px', color: "white", fontWeight: "400", lineHeight: "1.5rem", fontFamily: "Roboto"}}>
                      { getActionButtonName(rowData) }
                    </Typography>
                  </div>
                )
              },
              onClick: (_event, rowData) => {
                if (rowData.event_record) {
                  _event.preventDefault();
                  history.push(`${match.path}/monitoring/${rowData.event_record}/`);
                }
                else if (rowData.task_record) {
                  _event.preventDefault();
                  history.push(`${match.path}/monitoring/${rowData.task_record.event_record}/`);
                }
              }          
            }),
            _rowData => ({
              icon: (_props) => {
                return (
                  <div style={{padding:"4px", paddingTop: "0px", paddingBottom: "0px", background: "#18bff6", borderRadius: "4px"}}>
                  <Typography style={{margin: '4px', color: "white", fontWeight: "400", lineHeight: "1.50rem", fontFamily: "Roboto"}}>
                      Deviation
                    </Typography>
                  </div>
                )
              },
              onClick: (_event, rowData) => {
                history.push(`${match.path}/deviation/${rowData.id}/`);
                _event.preventDefault();
              },
            }),
          ]
        }
        onRowClick={(_event, rowData) => {
          if (rowData.id) {
            history.push(`${match.path}/deviation/${rowData.id}/`);
            _event.preventDefault();
          }
      }}
      /></Grid>
    }

    <Switch>
      <Route path={`${match.path}/deviation/:pk`}>
        <DeviationForm onComplete={() => getAllTasks()} user={props.user} />
      </Route>
      <Route path={`${match.path}/monitoring/:pk`}>
        <EventMonitoringForm onComplete={() => getAllTasks()} user={props.user} />
      </Route>
    </Switch>

    </>
  )
}
DeviationTasks.propTypes = {
  allTasks: PropTypes.bool,
  user: PropTypes.object,
  selectMode: PropTypes.bool,
  myTasks: PropTypes.bool,
};
