import React, { useState, useEffect } from "react";

import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from '@material-ui/icons/Remove';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	label: {
		fontSize: '14px',
	}
});

export default function CustomRadioButtonField(props) {

	const classes = useStyles();

	return (
		<Grid container item xs={12} style={props.style}>
			<Grid item xs={12} md={props.oneRow ? 12 : 6} style={{ marginBottom: '8px' }}>
				<Grid item xs={12} style={{ paddingTop: '12px' }}>
					<Typography style={{ fontSize: '14px', fontWeight: 500 }}>
						{props.displayName ? props.displayName : "<title>"}
						{props.required === true ? <span style={{ color: 'red', marginLeft: '4px' }}>*</span> : null}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography style={{ fontSize: '12px', fontWeight: 400 }}>
						{props.description ? props.description : "<instructions>"}
					</Typography>
				</Grid>
			</Grid>
			<Grid item xs={12} md={props.oneRow ? 12 : 6}>
				<FormControl component="fieldset" style={{ width: '100%' }} >
					<RadioGroup value={props.value} onChange={props.onChange} style={{ width: '100%' }}>
						<Grid container item xs={12}>
							{props.options && props.options.map(option => {
								return (
									<>
										<Grid item xs={9}>
											<FormControlLabel
												key={option.id}
												label={option.value}
												value={option.value}
												classes={{ label: classes.label }}
												control={<Radio disabled={props.readOnly} />}
											/>
										</Grid>

										{props.removeOption &&
											<Grid item container xs={3} justify="flex-end">
												<IconButton onClick={() => props.removeOption(option)}>
													<RemoveIcon />
												</IconButton>
											</Grid>
										}
									</>
								)
							})
							}
						</Grid>
					</RadioGroup>
				</FormControl>

				{props.error &&
					<Typography style={{ fontSize: '12px', color: '#f44336' }}>{props.error}</Typography>
				}
			</Grid>
		</Grid>
	)
}