import React, { useEffect, useState, useContext } from "react";
import {
  useHistory,
  useParams,
  useRouteMatch,
  useLocation,
} from "react-router-dom";


import API from "../../Api/Api";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import HaccpPlanDetailsModal from "./HaccpPlanDetailsModal";
import { OrgContext } from "hooks/useOrganization";
import { CardActionArea } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import HaccpSideMenu from "./HaccpSideMenu";

function HaccpPlanList(props) {
  const [haccpPlans, setHaccpPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const api = new API();
  let history = useHistory();
  let match = useRouteMatch();
  let { params } = useParams();
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();
  let payload = {};
  const org = useContext(OrgContext);

  useEffect(() => {
    getHaccpPlans();
  }, []);

  function getHaccpPlans() {
    setLoading(true);
    api
      .getHaccpPlanAPI()
      .listHaccpPlansMinimized()
      .then((e) => {
        history.push(`haccp_plans/${e.data[0].pk}/product/details`);
        setHaccpPlans(e.data);
        setIsActive(true);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  }

  const [user, setUser] = useState(null);
  useEffect(() => {
    api
      .getAuthAPI()
      .getAuthedProfile()
      .then((e) => {
        setUser(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const haccpPlan_columns = [
    { title: "Name", field: "name" },
    { title: "Description", field: "description" },
    {
      title: "Created By",
      field: "owner.username",
      render: (rowData) => {
        if (rowData.owner) {
          return rowData.owner.first_name != null &&
            rowData.owner.last_name != null
            ? rowData.owner.first_name + " " + rowData.owner.last_name
            : rowData.owner.username;
        }

        return "";
      },
    },
  ];

  const [haccpDetailsModalOpen, setHaccpDetailsModalOpen] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);

  function addHaccpPlan() {
    setHaccpDetailsModalOpen(true);
  }

  function saveDetails(name, description) {
    let newData = {
      organization: org.pk,
      name: name,
      description: description,
    };
    api
      .getHaccpPlanAPI()
      .createHaccpPlan(newData)
      .then((e) => {
        history.push(`haccp_plans/${e.data.pk}/product/details`);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState({
    message: "",
    status: "info",
  }); // error, warning, info, info
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    if (!isBlocking) {
      setHaccpDetailsModalOpen(false);
    } else {
      setIsBlocking(false);
      alert("Save changes before closing.");
    }
  };

  function setReturnStatus(message, status) {
    setMessage({ message: message, status: status });
    setOpen(true);
  }

  window.onbeforeunload = function (e) {
    if (isBlocking) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  const [changelogOpen, setChangelogOpen] = useState(false);
  const handleChangelogOpen = () => {
    setChangelogOpen(true);
  };

  const handleChangelogClose = () => {
    setChangelogOpen(false);
  };

  function cancelDetails() {
    if (!isBlocking) {
      setHaccpDetailsModalOpen(false);
    } else {
      if (window.confirm("Do You Want To Discard Changes?")) {
        setHaccpDetailsModalOpen(false);
      }
    }
  }
  //todo issue in here
  return (
    <Grid container style={{ height: "100%"}}>
      <HaccpPlanDetailsModal
        open={haccpDetailsModalOpen}
        save={saveDetails}
        setIsBlocking={setIsBlocking}
        cancel={cancelDetails}
        handleClose={handleClose}
      ></HaccpPlanDetailsModal>
      <Grid item  style={{borderRight: "1px solid rgba(0, 0, 0, 0.12)", width: "360px"}}>
        <HaccpSideMenu disabled={true} closeSideMenu={props.closeSideMenu}></HaccpSideMenu>


      </Grid>
      {!loading && (
        <Grid
          item
          style={{
            margin: "40px",
            maxWidth: "352px",
            height: "352px",
            flexWrap: "nowrap",
            maxWidth: "auto",
            height: "352px",
          }}
        >
          <CardActionArea
            onClick={addHaccpPlan}
            style={{ width: "352px", height: "352px" }}
          >
            <Card style={{ width: "352px", height: "352px" }}>
              <Grid container alignItems="center" justify="center">
                <Grid
                  container
                  item
                  xs={12}
                  alignItems="center"
                  justify="center"
                  style={{ marginTop: "98px" }}
                >
                  <div
                    style={{
                      height: "36px",
                      width: "36px",
                      background: "#18BFF6",
                      color: "white",
                      position: "relative",
                    }}
                  >
                    <AddIcon
                      style={{ position: "absolute", top: "6px", left: "6px" }}
                    ></AddIcon>
                  </div>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  alignItems="center"
                  justify="center"
                  style={{ marginTop: "35px" }}
                >
                  {" "}
                  <Typography
                    style={{
                      color: "#687895",
                      fontFamily: "Roboto",
                      fontSize: "24px",
                      lineHeight: "21px",
                      fontWeight: "700",
                      letterSpacing: "0.1px",
                    }}
                  >
                    Add New HACCP Plan
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  alignItems="center"
                  justify="center"
                  style={{
                    margin: "27px",
                    marginLeft: "64px",
                    marginRight: "64px",
                  }}
                >
                  {" "}
                  <Typography
                    style={{
                      color: "#687895",
                      opacity: "0.6",
                      fontFamily: "Roboto",
                      fontSize: "14px",
                      lineHeight: "21px",
                      fontWeight: "400",
                      letterSpacing: "0.1px",
                    }}
                  >
                    Need a new HACCP Plan? Click on this tile to add a new one.
                  </Typography>
                </Grid>
              </Grid>
            </Card>{" "}
          </CardActionArea>
        </Grid>
      )}
    </Grid>
  );
}

export default HaccpPlanList;
