class InventoryLocationAPI {
  constructor(api, version) {
    this.api = api;
    this.version = version;
  }
  
  listInventoryLocations(pk) {
    if (typeof pk === 'number') {
      return this.api.get(`${this.version}/inventory_locations/${pk}/`);
    }
  
    return this.api.get(`${this.version}/inventory_locations/`)
  }

  listInventoryLocationsDisplay(pk) {
    if (typeof pk === 'number') {
      return this.api.get(`${this.version}/inventory_locations_display/${pk}/`);
    }
  
    return this.api.get(`${this.version}/inventory_locations_display/`)
  }

  listProductionLocationsDisplay() {
    return this.api.get(`${this.version}/inventory_locations_display/list_production_locations/`)
  }
  
  createInventoryLocation(payload) {
    return this.api.post(`${this.version}/inventory_locations/`, payload);
  }
  
  updateInventoryLocation(payload) {
    return this.api.put(`${this.version}/inventory_locations/${payload.pk}/`, payload);
  }

  softDeleteInventoryLocation(payload) {
    return this.api.put(`${this.version}/inventory_locations/${payload.pk}/soft_delete_action/`, payload);
  }
  
  deleteInventoryLocation(pk) {
    return this.api.delete(`${this.version}/inventory_locations/${pk}/`);
  }
  
}
  
export default InventoryLocationAPI;