import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Chip from '@material-ui/core/Chip';
import Typography from "@material-ui/core/Typography";
import MaterialSwitch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import AvatarComponent from "Components/AvatarComponent";
import SanitizeHtml from "Components/SanitizeHtml";
import AssignmentIcon from '@material-ui/icons/Assignment';

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  header: { fontStyle: 'Roboto', fontSize: '16px', fontWeight: 500, fontHeight: '21px' },
});

export default function EventWidget({ eventSelected, setEventActive, readOnly, disabled, saving }) {
  const history = useHistory();
  const classes = useStyles();

  function goToEdit() {
    if (eventSelected.event_type === "LOG") {
      history.push(`/plans/manager/events/${eventSelected.id}/edit_log`);
    }
    else {
      history.push(`/plans/manager/events/${eventSelected.id}/edit_event`);
    }
  }
  return (
    <Paper elevation={0} square style={{ width: '100%'}}>
      <Grid container justify="center" style={{ width: '100%', borderBottom: '1px solid #eaeaea' }}>
        <Grid item container spacing={2} xs={12} justify="flex-start" alignItems="center" style={{ padding: '16px 8px' }}>
          {eventSelected.read_primary_monitor &&
            <Grid item>
              <AvatarComponent rounded user={eventSelected.read_primary_monitor} />
            </Grid>
          }

          {!eventSelected.read_primary_monitor &&
            <Grid item>
              {/*<Avatar variant="rounded" />*/}
              <AssignmentIcon />
            </Grid>
          }

          <Grid item>
            <Typography display="inline" style={{ maxWidth: '500px' }} className={classes.header}>
              {eventSelected.name}
            </Typography>
          </Grid>

          <Grid item>
            <Chip
              label={eventSelected.frequency || "Log"}
              size="small"
              color="primary"
            />
          </Grid>

          {eventSelected.read_primary_verifier && eventSelected.event_type !== "LOG" &&
            <Grid item>
              <Typography style={{ fontSize: '14px', fontWeight: 400, color: '#687895' }}>
                Verified By: {eventSelected.read_primary_verifier.first_name + " " + eventSelected.read_primary_verifier.last_name}
              </Typography>
            </Grid>
          }

          {!readOnly &&
            <Grid item container alignItems="center" style={{ marginLeft: 'auto', width: "max-content"}}>
              {!saving && eventSelected.event_type !== "LOG" && 
              <Typography style={{fontWeight: 400, fontSize: '14px', color: '#687895'}}>
                {eventSelected.active ? 'Active' : 'Inactive'}
              </Typography>
              }

              {saving && <CircularProgress size="16px" />}
              
              {eventSelected.event_type !== "LOG" && 
                <MaterialSwitch
                  checked={eventSelected.active}
                  disabled={disabled}
                  onChange={() => setEventActive(eventSelected)}
                  name="active"
                  color="primary"
                />
              }

              {eventSelected.event_type === "LOG" && 
              <Button 
                disabled={disabled} 
                color="primary" 
                variant="contained" 
                disableElevation
                size="small" 
                style={{marginRight: '8px'}} 
                onClick={() => { history.push(`/logs/entries/${eventSelected.id}/`)}}>
                Log Entries 
              </Button>
              }

              <Button disabled={disabled} disableElevation color="primary" variant="contained" size="small" onClick={goToEdit}>
                Edit {eventSelected.event_type === "LOG" ? "Log" : "Event"}
              </Button>
            </Grid>
          }
        </Grid>
      </Grid>
      <Grid item container xs={12} style={{ padding: '16px' }}>
        <Grid item xs={12}>
          <SanitizeHtml value={eventSelected.details} style={{ maxHeight: '250px', overflow: 'auto' }} />
        </Grid>
      </Grid>
    </Paper>
  )
}