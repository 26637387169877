import SignaturePad from 'react-signature-canvas'
import React, { useState, useEffect, useRef  } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
// import FontSelector from './FontSelector';
// import { fabric } from "fabric";
import {StylesContext} from "../App";
// var WebFont = require('webfontloader');

function ReactSignatureCreator(props) {
  const classes = React.useContext(StylesContext);
  let canvas = {};

  useEffect(() => {
    if(!props.initialSignature){
      return;
    }

    sigPad.fromDataURL(props.initialSignature);
  }, [])

  const parentRef = useRef(null);

  const [canvasSize, setCanvasSize] = useState({width: 700, height: 200})

  function getWidth() {
    if (parentRef.current && sigPad.current) {
      let parentWidth  = parentRef.current.clientWidth;
      let parentHeight = parentRef.current.clientHeight;

      setCanvasSize({width: parentWidth, height: parentHeight});

     // let existingData = sigPad.current.toData(); 
     // sigPad.current.clear();
      //sigPad.current.fromData(existingData);
    }
  }

  useEffect(() => {
    const resizeListener = () => {
      // change width from the state object
      getWidth();
    };

    // set resize listener
    window.addEventListener('resize', resizeListener);

    resizeListener();

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    }

  }, []);

  const sigPad = useRef({});

  const checkSignature = () => {
    if(props.checkSignature){
      props.checkSignature(sigPad);
    }
  }

  function clearCanvas() {
    if (props.validSignatureCheck) {
      props.validSignatureCheck(false);
    }
    sigPad.current.clear();
  }
  

  return (
    <Paper elevation={0} style={{backgroundColor: 'rgba(0,0,0,0)', width: '100%'}}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} container justify="center" style={{width: '100%'}}>
          <div
            ref={parentRef}
            style={{
              height: 200,
              width: '100%',
              boxSizing: 'border-box',
              backgroundColor: 'white',
		          backgroundColor: '#F5F5F5',
              padding: "1px",
              //border: "1px solid lightgrey",
            }}

          > {/* Disable doesn't seem to work, just wrap this in something to disappear it and let the div serve as a proxy. */}
            {!props.disabled &&
              <SignaturePad 
                canvasProps={{width: canvasSize.width, height: canvasSize.height}} 
                ref={(ref) => { canvas = ref;  sigPad.current = ref; }} 
                onEnd={checkSignature} 
                // ref={sigPad} 
              />
            }
          </div>
        </Grid>

        <Grid item container justify="flex-start" xs={12} style={{marginTop: '16px'}}>
          <Button disabled={props.disabled} disableElevation variant="contained" size="small" color='secondary' onClick={clearCanvas}>
            Reset 
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ReactSignatureCreator;