import API from "./Api";

class IngredientTemplateAPI {

  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listIngredients(formulation_pk = null) {
    if (typeof formulation_pk === 'number') { // Get ingredients in formulation
      return this.api.get(`${this.version}/ingredient_templates/?formulation_pk=${formulation_pk}`)
    }

    return this.api.get(`${this.version}/ingredient_templates/`)
  }

  createIngredient(payload) {
    return this.api.post(`${this.version}/ingredient_templates/`, payload);
  }

  retrieveIngredient(pk) {
    return this.api.get(`${this.version}/ingredient_templates/${pk}/`);
  }

  updateIngredient(payload) {
    return this.api.put(`${this.version}/ingredient_templates/${payload.pk}/`, payload);
  }

  deleteIngredient(pk) {
    return this.api.delete(`${this.version}/ingredient_templates/${pk}/`);
  }

  addHazardToIngredient(ingredient_pk, hazard) {
    return this.api.post(`${this.version}/ingredient_templates/${ingredient_pk}/add_hazard/`, hazard);
  }

  removeHazardFromIngredient(ingredient_pk, hazard) {
    return this.api.post(`${this.version}/ingredient_templates/${ingredient_pk}/remove_hazard/`, hazard);
  }

  getHistory(pk) {
    return this.api.get(`${this.version}/ingredient_templates/${pk}/get_history/`);
  }
}


export default IngredientTemplateAPI;
