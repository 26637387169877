import React, { useState, useEffect } from "react"
import { DialogContent, CardMedia } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import API from "../../Api/Api";
import Divider from "@material-ui/core/Divider";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { fabric } from "fabric";
import FormGroup from "@material-ui/core/FormGroup";
import CircularProgress from "@material-ui/core/CircularProgress";

function TrafficFlowDiagramModal(props) {
    const [loading, setLoading] = useState(true);
    const [grid, setGrid] = useState(false);


    let api = new API();

    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string

        let byteString, mimeString, ia;
        if (dataURI.split(",")[0].indexOf("base64") >= 0)
            byteString = atob(dataURI.split(",")[1]);
        else byteString = unescape(dataURI.split(",")[1]);

        // separate out the mime component
        mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

        // write the bytes of the string to a typed array
        ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }
    const canvas_styling = {
        zIndex: 0,
    };

    const CANVAS_WIDTH = fabric.util.parseUnit('8.5in');
    const CANVAS_HEIGHT = fabric.util.parseUnit('8in');

    const cellSize = 50;

    const rows = CANVAS_HEIGHT / cellSize;
    const cols = CANVAS_WIDTH / cellSize;

    function initializeCanvas2(trafficFlowDiagram, diagram, callback) {
        let canvas = new fabric.StaticCanvas(diagram, {
            backgroundColor: "rgb(255, 255, 255)",

        });
        canvas.setDimensions({ width: CANVAS_WIDTH + 2, height: CANVAS_HEIGHT + 2 });

        if (trafficFlowDiagram.diagram == null) {
            return;
        }
        canvas.loadFromJSON(trafficFlowDiagram.diagram, () => {
            canvas.requestRenderAll();
        });
        if (props.gridEnabled) {
            setGrid(true);
            for (let row = 0; row < rows + 1; row++) {
                canvas.add(
                    new fabric.Line([0, row * 50, CANVAS_WIDTH, row * 50], {
                        fill: "grey",
                        stroke: "grey",
                        haccp_type: "gridLine",
                        strokeWidth: 0.5,
                        opacity: 0.75,
                        selectable: false,
                        excludeFromExport: true,
                        perPixelTargetFind: true,
                    })
                );
            }

            for (let col = 0; col < cols + 1; col++) {
                canvas.add(
                    new fabric.Line([col * 50, 0, col * 50, CANVAS_HEIGHT], {
                        fill: "grey",
                        stroke: "grey",
                        haccp_type: "gridLine",
                        strokeWidth: 0.5,
                        opacity: 0.75,
                        selectable: false,
                        excludeFromExport: true,
                        perPixelTargetFind: true,
                    })
                );
            }

            canvas.forEachObject((object) => {
                if (object.haccp_type === "process") {
                    object.bringToFront();
                }
            });
        } else {
            setGrid(false);
        }

        callback();
    }
    function changeLoading() {
        setLoading(false);
    }

    useEffect(() => {
        let diagram1 = "diagram1";
        let diagram2 = "diagram2";
        if (props.oldTrafficFlowDiagram && props.beforeTrafficFlowDiagram) {
            initializeCanvas2(props.beforeTrafficFlowDiagram, diagram1, changeLoading);
            initializeCanvas2(props.oldTrafficFlowDiagram, diagram2, changeLoading);

            return () => {
                setLoading(true);
            }
        }
    }, [props.beforeTrafficFlowDiagram, loading])

    function saveData() {
        props.handleModalClosed();
        props.setStatus({
            message: "Traffic Flow Diagram saved!",
            severity: "info",
        });
        let diagram;

        diagram = props.beforeTrafficFlowDiagram.diagram;
        let canvas = document.getElementById("diagram1");

        delete diagram.saved_image;
        delete diagram.image;

        let fileName;
        if (props.templateMode) {
            fileName = `traffic_flow_diagram_pk_${props.beforeTrafficFlowDiagram.pk}_template_true.png`;
        } else {
            fileName = `traffic_flow_diagram_pk_${props.beforeTrafficFlowDiagram.pk}_template_false.png`;
        }

        let formData = new FormData(document.forms[0]);
        let dataURI = canvas.toDataURL();
        let blob = dataURItoBlob(dataURI);
        let file = new File([blob], fileName, { type: "image/png" });

        formData.append("name", props.beforeTrafficFlowDiagram.name);
        formData.append("description", props.beforeTrafficFlowDiagram.description);
        formData.append("saved_image", file);
        formData.append("diagram", diagram);

        if (props.beforeTrafficFlowDiagram.cross_contamination_points) {
            for (let i = 0; i < props.beforeTrafficFlowDiagram.cross_contamination_points.length; i++) {
                formData.append(
                    "cross_contamination_points",
                    props.beforeTrafficFlowDiagram.cross_contamination_points[i]
                );
            }
        }
        api.getTrafficFlowDiagramAPI()
            .updateTrafficFlowDiagramImage(props.beforeTrafficFlowDiagram.pk, formData)
            .then((e) => {
                props.setTrafficFlowDiagram(e.data);
                props.setupCanvas(e.data, grid);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        let byteString, mimeString, ia;
        if (dataURI.split(",")[0].indexOf("base64") >= 0)
            byteString = atob(dataURI.split(",")[1]);
        else byteString = unescape(dataURI.split(",")[1]);

        // separate out the mime component
        mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

        // write the bytes of the string to a typed array
        ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }

    return (
        <Dialog
            maxWidth="lg"
            open={props.open}
            onClose={props.handleModalClosed}
        >
            <DialogContent>
                {loading &&
                    <CircularProgress />
                }
                {!loading &&
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Grid item>
                                <Typography variant="h5" noWrap>
                                    Old Traffic Flow Diagram
                                     </Typography>
                                <div
                                    style={{
                                        width: "100%",
                                        padding: "16px",
                                        overflow: "scroll",
                                    }}
                                >
                                    <canvas style={canvas_styling} id="diagram1" />
                                </div>
                            </Grid>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => saveData()}
                            >
                                Revert
                        </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid item>
                                <Typography variant="h5" noWrap>
                                    Current Traffic Flow Diagram
                                     </Typography>
                                <div
                                    style={{
                                        width: "100%",
                                        padding: "16px",
                                        overflow: "scroll",
                                    }}
                                >
                                    <canvas style={canvas_styling} id="diagram2" />
                                </div>
                            </Grid>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={props.handleModalClosed}
                            >
                                Cancel
                        </Button>
                        </Grid>
                    </Grid>
                }
            </DialogContent>

        </Dialog >
    )
}
export default TrafficFlowDiagramModal;
