import React, {useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import GroupedTable from "./GroupedTable";
import PropTypes from 'prop-types';
import {StylesContext} from "../../App";



export default function TabbableGroupedTable(props) {
  const classes = React.useContext(StylesContext);

  const [filterTo, setFilterTo] = useState(false);
  const [tabState, setTabState] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [dummyKey, setDummyKey] = useState(1);

  useEffect(() => {
    
    getTabCounts(props.data);
  }, [props.data])

  useEffect(() => {
    if(!tabState) {
      return;
    }
    makeTabs(props.data);

  }, [tabState])

  useEffect(() => {

    //This is used to circumvent an issue in material/grouped table. Too many re-filters appear to have
    //memory issues and get very slow after 5-6 tab clicks. Reseting everything with key ignores that.
    makeTabs(props.data);
    setDummyKey(dummyKey + 1);
  }, [filterTo, props.tabStatuses])

  function getTabCounts(records) {
    let newTabs = [];

    props.tabStatuses.tabs.forEach((tab, i) => {
      if(i === 0 && !filterTo) {
        setFilterTo(tab.value);
      }
      if(tab.value == 'All') {
        newTabs.push({...tab, count: records.length});
        return;
      }

      let newCount = records.filter(record => record[props.tabStatuses.column] == tab.value).length;

      if(props.tabStatuses.column != 'ignore') {
        newTabs.push({...tab, count: newCount});
      }
      else {
        newTabs.push({...tab});
        makeTabs(props.data);
      }
    });
    setTabState({...props.tabStatuses, counted: true, tabs: newTabs});
  }

  function handleTabclick(tab) {
    if(props.grabTabValue) {
      props.grabTabValue(tab.value)
    }

    setFilterTo(tab.value)
  }

  
  function makeTabs() {
    if(!tabState || !tabState.tabs) {
      return;
    }
    let newTabs = [];

    tabState.tabs.forEach((tab, i) => {
      newTabs.push((<Button
        key={i}
        disableRipple
        classes={{root: (filterTo == tab.value) ? classes.generalTabButtonSelected : classes.generalTabButtonNotSelected}}
        className={filterTo == tab.value ? classes.generalTabButtonSelected : classes.generalTabButtonNotSelected}
        //className={() => {returnGetTabClass(tab.value)}}
        style={i > 0 ? { marginLeft: "4px", backgroundColor: 'transparent' } : {marginLeft: "0px", backgroundColor: 'transparent'} }
        onClick={() => { handleTabclick(tab) }}
        //color="secondary"
      >
        {tab.title + ' (' + tab.count + ')'}
      </Button>))
    });
    setTabs(newTabs)
  }

  function filterData(data) {

    if(filterTo && filterTo != 'All' && tabState.column != 'ignore') {
      let newData = [...data].filter(dataPoint => {

        return dataPoint[tabState.column] == filterTo
      })

      return newData
    }
    else {

      return data;
    }
  }

  //todo conditional actions plan


  return (<div className='tabGroupTable' style={{width: '100%'}}>
        <GroupedTable 
          noMinWidth={props.noMinWidth ? true : undefined}
          key={dummyKey}
          data={filterData(props.data)}
          //todo material ui corrupts passed columns with a memory leak,
          //this removes the corupting component from our data
          columns = { props.columns ? props.columns.map((c) => ({ ...c, tableData: undefined })) : false}
          useTabs={true}
          filterButtons={tabs}
          options={{
            pageSize: 10,
          }}
          //formatData={formatTableData}
          //draggable={!loading} Leave false unless drag grouping comes back
          title={props.title}
          localization={{
            body: {
                emptyDataSourceMessage: props.emptyDataMessage || "",
            }
          }}

          actions={
            [
              ...(props.actions ? props.actions : [])
            ]
          }

          onRowClick={props.onRowClick} 
        />
        </div>
  )
}

TabbableGroupedTable.propTypes = {
  columnInfo: PropTypes.arrayOf(PropTypes.object),
  grouping: PropTypes.bool,
  tabStatuses: PropTypes.object,
  grabTabValue: PropTypes.func,
  actions: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.object),
  columnSearching: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  selection: PropTypes.bool,
  draggable: PropTypes.bool,
  isLoading: PropTypes.bool,
  useTabs: PropTypes.bool,
  filterButtons: PropTypes.array,
  onRowClick: PropTypes.func,
  title: PropTypes.string,
  options: PropTypes.object,
}
