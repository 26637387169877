import React, { useEffect, useState, useContext } from "react";
import { useHistory, useRouteMatch, Route, Switch } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import queryString from "query-string";
import ContentHeader from "./Layouts/ContentHeader";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from '@material-ui/core/Checkbox';
import { TableHead } from "@material-ui/core";
import Message from "./Message";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import useDesktop from "../hooks/useDesktop";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "calc(100%)",
  },
  tablePagination: {
    float: "right",
    flexShrink: 0,
    flex: "auto",
    marginLeft: theme.spacing(2.5),
  },
  tablePaginationLoading: {
    float: "right",
    flexShrink: 0,
    flex: "auto",
    marginLeft: theme.spacing(2.5),
    opacity: "75%",
  },
  paper: {
    minWidth: "500px",
    width: "100%",
    //paddingTop: "0px",
    //paddingBottom: "0px",
    //padding: "16px",
    //paddingRight: "0px",
  },
  paperFullWidth: {
    minWidth: "500px",
    width: "100%",
    paddingTop: "0px",
    paddingBottom: "0px",
    padding: "16px",
    paddingRight: "0px",
  },
  paperMobile: {
    width: "100%",
    paddingTop: "0px",
    paddingBottom: "0px",
    padding: "16px",
    paddingRight: "0px",
  },
  paperWithScrollBar: {
    minWidth: "500px",
    width: "100%",
    paddingTop: "0px",
    paddingBottom: "0px",
    padding: "16px",
    paddingRight: "0px",
  },
  table: {
    //height: "400px",
    width: "100%",
    minWidth: "1000px",
    marginLeft: "0px",
    marginRight: "0px",
    tableLayout: 'fixed',
  },

  loadingContainer: {
    width: "100%",
    padding: "16px",
    height: "calc(100vh - 275px)"
  },

  gridContainer: {
    maxWidth: "100%",
    padding: "32px"
  },

  gridContainerRecords: {
    maxWidth: "100%",
    padding: "32px",
    paddingTop: "16px"
  },

  tableContainer: {
    minHeight: "calc(10vh)",
    width: "100%",
  },

  tableContainerLoading: {
    width: "100%",
    //opacity: "75%",
    minHeight: "calc(10vh)",
  },

  tableHeadLoading: {
    //display: "table",
    width: "100%",
    backgroundColor: "#FFFFFF",
    padding: "-16px",
    marginBottom: "8px",
    zIndex: 3,
  },

  tableHead: {
    backgroundColor: "#FFFFFF",
    //width: "100%",
    //marginBottom: "8px",
    //zIndex: 3,
  },

  selectCell: {
    paddingBottom: "8px",
    backgroundColor: "#FFFFFF",
    paddingTop: "12px"
  },

  sendDataContainer: {
    marginLeft: "auto",
    marginTop: "-4px"
  },

}));

const useToolbarStyles = makeStyles((theme) => ({
  tableText: {
    fontSize: "14px", //Insert your required size
    lineHeight: "21px", //Insert your required size
    color: "#949EB1",
    fontWeight: 500,
    fontFamily: "Roboto",
    letterSpacing: "0.1px",
  },
  selectedTableText: {
    fontSize: "14px", //Insert your required size
    lineHeight: "21px", //Insert your required size
    color: "#18bff6",
    fontWeight: 500,
    fontFamily: "Roboto",
    letterSpacing: "0.1px",
  },
  gridContainer: {
    width: "100%",
    paddingBottom: "8px",
  },
  gridContainerSend: {
    width: "100%",
    paddingBottom: "8px",
  },
  gridItem: {
    paddingRight: "8px",
  },
  gridItemSendButton: {
    float: "right",
    justifySelf: "flex-end",
    alignSelf: "flex-end",
  },
}));

export default function CustomTable(props) {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(null);
  const [tabs, setTabs] = useState(props.tabs ? props.tabs : []);
  const history = useHistory();
  const match = useRouteMatch();
  const [order, setOrder] = useState("asc");
  const [noSearchResults, setNoSearchResults] = useState(false);
  const [savedOrderId, setSavedOrderID] = useState(0);
  const [field, setField] = useState(null);
  const onDesktop = useDesktop();

  const handleMessageClose = (event, reason) => {
    if (props.handleMessageClose) {
      props.handleMessageClose(event, reason);
    }
  };

  const handleChangePage = (newPage) => {
    if (!props.loading) {
      if (props.handleChangePage) {
        props.handleChangePage(newPage);
      }
      setClearText(true);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    if (!props.loading) {
      setClearText(true);
      if (props.handleChangeRowsPerPage) {
        props.handleChangeRowsPerPage(event);
      }
    }
  };

  useEffect(() => {
    if (props.checkTaskStatus) {
      props.checkTaskStatus();
    }
    checkTaskStatus();
  }, []);

  useEffect(() => {
    let params = queryString.parse(window.location.search);
    setSavedOrderID(params.col);
    setClearText(true);
    setNoSearchResults(false);
    if (props.myTasks) {
      if (props.searchTasks) {
        props.searchTasks(params.status, params.page, props.currentPageSize, null);
      }
    } else {
      if (props.searchTasks) {
        props.searchTasks(params.status, params.page, props.currentPageSize, "all");
      }
    }
  }, [selectedTab, props.page, window.location]);
  const [pageLoading, setPageLoading] = useState(true);
  //allTasks can change based on search, so can't map props.allTasks
  useEffect(() => {
    if (!props.loading) {
      setTabs(props.tabs);
      setOrder(props.order);
      setNoSearchResults(false);
      setPageLoading(false);
      setField(props.field);
      setSelectedTab(props.selectedTab);
    }
  }, [
    props.loading,
    props.tabs,
    props.currentPageSize,
    props.page,
    props.allTasksStatic,
    props.order,
    props.field,
    props.selectedTab,
    props.orderBy,
  ]);

  useEffect(() => {
    let params = queryString.parse(window.location.search);
    setSavedOrderID(params.order);
    if (params.order === "desc") {
      setOrder("desc");
    } else {
      setOrder("asc");
    }
  }, [props.loading]);

  function checkTaskStatus() {
    if (props.myTasks) {
      if (props.getTasksStatus) {
        props.getTasksStatus("true");
      }
    } else {
      if (props.getTasksStatus) {
        props.getTasksStatus();
      }
    }
  }

  function setSearchResults(tasks) {
    setChecked(false);
    if (tasks.length < 1) {
      setNoSearchResults(true);
    } else {
      setNoSearchResults(false);
    }
    if (props.setSearchResults) {
      props.setSearchResults(tasks);
    }
    if (props.clearSelected) {
      props.clearSelected();
    }
  }
  const [clearText, setClearText] = useState(false);

  function changeTab(url) {
    setChecked(false);
    setClearText(true);
    setSelectedTab(url);
    if (props.changeTab) {
      props.changeTab(url);
    }
  }

  const [orderBy, setOrderBy] = useState("");

  function exportSelected() {
    if (props.exportSelected) {
      props.exportSelected();
    }
  }
  function exportAll() {
    if (props.exportAll) {
      props.exportAll();
    }
  }

  const EnhancedTableToolbar = (props2) => {
    const classes = useToolbarStyles();
    return (
      <Grid container className={props.records ? classes.gridContainerRecords : classes.gridContainer}>
        {tabs.length &&
          !props.noTableHead &&
          tabs.map((tab) => (
            <Grid item key={tab.name} className={classes.gridItem}>
              <Button style={{ textTransform: "none" }} onClick={() => changeTab(tab.url)}>
                <Typography
                  className={selectedTab === tab.url ? classes.selectedTableText : classes.tableText}
                  id="tableTitle"
                  component="div"
                >
                  {tab.name} ({tab.count})
                </Typography>
              </Button>
            </Grid>
          ))}
        <Grid item style={{ marginLeft: "auto", marginTop: "-4px", height: "40px" }}>
         
          {props.selectMode && props.dataToSend.length >= 1 && (
            <Button
              variant="contained"
              color="primary"
              style={{ textTransform: "none" , marginRight:"10px"}}
              onClick={exportSelected}
            >
              {props.sendButtonName}
            </Button>
          )}
           {props.selectMode && props.allTasks.length >= 1 && (
            <Button
              variant="contained"
              color="primary"
              style={{ textTransform: "none" }}
              onClick={exportAll}
            >
              {props.exportAllButtonName}
            </Button>
          )}
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    let params = queryString.parse(window.location.search);
    setOrder(params.order);
    if (props.headCells && params.col && params.col !== 'null') {
      setOrderBy(props.headCells[parseInt(params.col)].id);
      setField(props.headCells[parseInt(params.col)].field);
    }
  }, [window.location, props.headCells]);

  const [checked, setChecked] = useState(false);

  function handleFirstPageButtonClick() {
    handleChangePage(1);
  }

  function handleBackButtonClick() {
    handleChangePage(parseInt(props.page) - 1);
  }

  function handleNextButtonClick() {
    handleChangePage(parseInt(props.page) + 1);
  }

  function handleLastPageButtonClick() {
    handleChangePage(Math.max(0, Math.ceil(props.totalTasks / props.currentPageSize)));
  }

  function TablePaginationActions() {
    return (
      <div style={{ float: "right", display: "flex", marginLeft: "16px" }}>
        <IconButton onClick={handleFirstPageButtonClick} disabled={props.page === 1} aria-label="First Page">
          {<FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={props.page === 1} aria-label="Previous Page">
          {<KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={props.page >= Math.ceil(props.totalTasks / props.currentPageSize)}
          aria-label="Next Page"
        >
          {<KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={props.page >= Math.ceil(props.totalTasks / props.currentPageSize)}
          aria-label="Last Page"
        >
          {<LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  const EnhancedTableHead = (props2) => {
    const createSortHandler = (property, order, savedOrderId) => (event) => {
      setSavedOrderID(savedOrderId);
      if (order === "asc") {
        setOrder("desc");
        if (props.sort) {
          props.sort(props.allTasks, "desc", savedOrderId);
        }
      } else {
        setOrder("asc");
        if (props.sort) {
          props.sort(props.allTasks, "asc", savedOrderId);
        }
      }
      setOrderBy(property);
    };

    const handleChange = (event) => {
      setChecked(!checked);
      if (props.selectAllRows) {
        props.selectAllRows(event.target.checked);
      }
    };

    return (
      <TableHead className={props.loading ? classes.tableHeadLoading : classes.tableHead}>
        {props.selectMode && checked !== null && (
          <TableCell
            key={1}
            align="left"
            width="5%"
            className={classes.selectCell}
          >
            <Checkbox
              checked={checked ? true : false}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          </TableCell>
        )}
        {props.headCells.map((column, index) => (
          <TableCell
            width={column.width}
            key={column.id + 1}
            align={column.align}
            style={column.style}
          >
            {!column.disableSort &&
              <TableSortLabel
                active={field === column.field}
                direction={orderBy === column.id ? order : "asc"}
                onClick={createSortHandler(column.id, order, index)}
              >
                {column.label}
              </TableSortLabel>
            }

            {column.disableSort && column.label}
          </TableCell>
        ))}
      </TableHead>
    );
  };

  return (
    <>
      {props.allTasksStatic && !props.hideHeader && (
        <ContentHeader
          search
          searchData={props.allTasksStatic}
          searchKeys={props.searchKeys ? props.searchKeys : ["name"]}
          clearText={props.clearText}
          setSearchResults={setSearchResults}
          title={props.title ? props.title : "My Tasks"}
          noBorder={props.hideDivider ? true : undefined}
        />
      )}
      {pageLoading && (
        <Grid container item justify="center" alignItems="center" style={{ width: "100%", height: "50%" }}>
          <CircularProgress />
        </Grid>
      )}
      {!pageLoading && (
        <Grid
          container
          item
          xs={12}
          justify="flex-start"
          className={props.records ? classes.gridContainerRecords : classes.gridContainer}
        >
          <EnhancedTableToolbar />

          <Paper
            elevation={0}
            className={
              !onDesktop
                ? classes.paperMobile
                : props.fullWidth
                  ? classes.paperFullWidth
                  : props.currentPageSize === 5
                    ? classes.paper
                    : classes.paperWithScrollBar
            }
          >
            <Grid container item xs={12}>
              <>
                <TableContainer
                  className={
                    props.loading ? classes.tableContainerLoading : classes.tableContainer
                  }
                >
                  {props.allTasks && props && (
                    <Table
                      stickyHeader
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      size="medium"
                      padding="normal"
                      aria-label="enhanced table"
                    >
                      <EnhancedTableHead />
                      <TableBody>
                        {/*<Typography style={{ fontWeight: 500, fontSize: "14px" }}>
                                                      {noSearchResults
                                                          ? `No search results found`
                                                          : `You have no ${selectedTab !== "all" ? selectedTab : ""} tasks`}
                                                      </Typography>*/}
                        {props.customTableRows()}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>

                {props.page && props.currentPageSize && (
                  <TablePagination
                    aria-disabled={props.loading}
                    className={
                      props.loading ? classes.tablePaginationLoading : classes.tablePagination
                    }
                    style={{ float: "right" }}
                    rowsPerPageOptions={!onDesktop ? [] : [5, 10, 25, 50]}
                    component="div"
                    count={props.totalTasks}
                    rowsPerPage={props.currentPageSize}
                    page={props.page - 1}
                    onChangePage={handleChangePage} //these props are for an old version, could cause issues if material ui package is updated
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                )}
              </>

            </Grid>
          </Paper>
          <Message
            open={props.open}
            message={props.message.message}
            severity={props.message.status}
            vertical="bottom"
            horizontal="right"
            handleClose={handleMessageClose}
          />
        </Grid>
      )}
    </>
  );
}
CustomTable.propTypes = {
  selectMode: PropTypes.bool,
  page: PropTypes.number,
  allTasks: PropTypes.array,
  allTasksStatic: PropTypes.array,
  loading: PropTypes.bool,
  selectedTab: PropTypes.string,
  totalTasks: PropTypes.number,
  currentPageSize: PropTypes.number,
  title: PropTypes.string,
  handleChangeRowsPerPage: PropTypes.func,
  handleChangePage: PropTypes.func,
  searchKeys: PropTypes.array,
  tabs: PropTypes.array,
  changeTab: PropTypes.func,
  headCells: PropTypes.array,
  customTableRows: PropTypes.func,
  setSearchResults: PropTypes.func,
  sort: PropTypes.func,
  clearText: PropTypes.bool,
  order: PropTypes.string,
  selectAllRows: PropTypes.func,
  exportSelected: PropTypes.func,
  checked: PropTypes.array,
  sendButtonName: PropTypes.string,
  clearSelected: PropTypes.func,
  exportAllButtonName: PropTypes.string,
  exportAll: PropTypes.func,
  dataToSend: PropTypes.array,

  field: PropTypes.string,
  message: PropTypes.object,
  open: PropTypes.bool,
  handleMessageClose: PropTypes.func,
};
