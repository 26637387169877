class HaccpPlanAPI {

  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listHaccpPlans() {
    return this.api.get(`${this.version}/haccp_plans/`);
  }

  listHaccpPlansMinimized() {
    return this.api.get(`${this.version}/haccp_plans/get_min_list/`)
  }

  createHaccpPlan(payload) {
    return this.api.post(`${this.version}/haccp_plans/`, payload);
  }

  retrieveHaccpPlan(pk) {
    return this.api.get(`${this.version}/haccp_plans/${pk}/`);
  }

  updateHaccpPlan(payload) {
    return this.api.put(`${this.version}/haccp_plans/${payload.pk}/`, payload);
  }

  deleteHaccpPlan(pk) {
    return this.api.delete(`${this.version}/haccp_plans/${pk}/`);
  }

  selectTemplate(pk, template) {
    return this.api.post(`${this.version}/haccp_plans/${pk}/select_template/`, template);
  }

  exportPlan(pk) {
    return this.api.get(`${this.version}/haccp_plans/${pk}/export/`);
  }

  /**
   * Allows you to choose sections of a haccp plan to print.
   * 
   * @param {*} pk - integer: The primary key of the haccp plan to print.
   * @param {*} payload - object: A JSON object of the form
   *        {"print_description": bool,
            "print_hazard_analysis": bool,
            "print_ccb_determination": bool,
            "print_haccp_plan": bool,
            "print_process_flow": bool,
            "print_traffic_flow": bool,}
            where true means to print that section.
   */
  exportPlanDetailed(pk, payload) {
    return this.api.post(`${this.version}/haccp_plans/${pk}/export_plan_detailed/`, payload);
  }

  /**
   * Checks the status of a queued export job
   * @param pk - integer: The primary key of the haccp plan being exported
   * @param jobId - string: The UUID of the export job.
   */
  exportJobStatus(pk, jobId) {
    return this.api.post(`${this.version}/haccp_plans/${pk}/export_job_status/`, {job_id: jobId});
  }

  getFormulations(pk) {
    return this.api.get(`${this.version}/haccp_plans/${pk}/get_formulations/`);
  }

  addFormulationToHaccpPlan(haccpPlanPk, formulation) {
    return this.api.post(`${this.version}/haccp_plans/${haccpPlanPk}/add_formulation/`, formulation);
  }

  removeFormulationFromHaccpPlan(haccpPlanPk, formulation) {
    return this.api.post(`${this.version}/haccp_plans/${haccpPlanPk}/remove_formulation/`, formulation);
  }

  getAllIngredients(haccpPlanPk) {
    return this.api.get(`${this.version}/formulations_and_ingredient/${haccpPlanPk}/list_formulation_ingredients/`);
  }

  getHistory(pk) {
    return this.api.get(`${this.version}/haccp_plans/${pk}/get_history/`);
  }

  addProcessToHaccpPlan(haccpPlanPk, process) {
    return this.api.post(`${this.version}/haccp_plans/${haccpPlanPk}/add_process/`, process);
  }

  removeProcessFromHaccpPlan(haccpPlanPk, process) {
    return this.api.post(`${this.version}/haccp_plans/${haccpPlanPk}/remove_process/`, process);
  }

  addHazardToHaccpPlan(haccpPlanPk, hazard) {
    return this.api.post(`${this.version}/haccp_plans/${haccpPlanPk}/add_hazard/`, hazard);
  }

  removeHazardFromHaccpPlan(haccpPlanPk, hazard) {
    return this.api.post(`${this.version}/haccp_plans/${haccpPlanPk}/remove_hazard/`, hazard);
  }
}


export default HaccpPlanAPI;
