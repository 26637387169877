import React from 'react';
import API from '../../Api/Api';
import ImageCard from './ImageCard';
import PropTypes from 'prop-types';
import ImagesAPI from "../../Api/ImagesAPI";
import {
  Typography,
  Paper,
  Grid,
  Button,
} from '@material-ui/core';
import { saveAs } from 'file-saver';
//const api = new API().getImagesAPI();
const FileSaver = require('file-saver');


/**
 * An image file manager. Uses a drop area for uploading,
 * and media cards for the previews.
 * 
 * PROPS
 * allowDelete: BOOL - If true, the image cards will have a functioning delete button appended
 * 
 * actions: Array[Object] - An array of action objects to allow for each image with the form {
 *    label: string, 
 *    action: function(string/imageUrl, string/imageName, int/imagePrimaryKey)
 * }
 * 
 * REQUIRED
 * api: CLASS>Api - An Api class object containing the following function definitions
 *    listImages() - Must return an object of the form 
 *      {data: {image: string/url, name: string, pk: integer/primary key ...} ...}
 * 
 *    deleteImage(pk) - Receives an integer that is the primary key recevied from listImages()
 * 
 */
class ImageManager extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      responseObjects: [],
      responseState: 'Request Pending.',
      cardActions: props.actions,
      canDeleteCards: props.allowDelete,
      api: props.api,
      cardStyle: props.cardStyle,
    }

    this.getImagesFromServer();
  }

  /**
   * Maps the response objects to ImageCard components.
   * 
   * @param {*} responseObjects - Response objects from the backend server.
   */
  mapToPreviews(responseObjects) {
    return responseObjects.map((responseObject) => {
      return ( 
        <ImageCard 
          url={responseObject.image} 
          name={responseObject.name}
          pk={responseObject.pk}
          actions={this.state.cardActions}
          allowDelete={this.state.canDeleteCards}
          api={this.state.api}
          cardStyle={this.state.cardStyle}
        />
      )
    });
  }

  /**
   * Gets all images from the backend server. On success, stores all response
   * objects to this components state. In either case, the server ersponse 
   * will be stored to the state.
   */
  getImagesFromServer() {
    this.state.api.listImages().then((response) => {
      this.setState({
        responseObjects: response.data,
        responseState: 'Request Successful'
      });

    }).catch((error) => {
      this.setState({
        responseState: 'Request Failed: ' + error.statusText,
      });
    });
  }

  render() {
    return(
      <Paper elevation={1} style={{margin: '16px', padding: '16px'}}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant={'h5'}>
              Stored Images
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{height: '400px', overflowY: 'scroll',}}>
          {this.mapToPreviews(this.state.responseObjects)}
        </Grid>
      </Paper>
    )
  }
}

ImageManager.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func // receives (imageURL, imageName, pk)
  })),
  allowDelete: PropTypes.bool,
  api: PropTypes.instanceOf(ImagesAPI).isRequired,
  cardStyle: PropTypes.object, // Css style object
}

export default ImageManager;









