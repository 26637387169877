class FormulationTemplateAPI {

  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listFormulations() {
    return this.api.get(`${this.version}/formulation_templates/`)
  }

  createFormulation(payload) {
    return this.api.post(`${this.version}/formulation_templates/`, payload);
  }

  retrieveFormulation(pk) {
    return this.api.get(`${this.version}/formulation_templates/${pk}/`);
  }

  updateFormulation(payload) {
    return this.api.put(`${this.version}/formulation_templates/${payload.pk}/`, payload);
  }

  deleteFormulation(pk) {
    return this.api.delete(`${this.version}/formulation_templates/${pk}/`);
  }

  addIngredientToFormulation(formulation_pk, ingredient) {
    return this.api.post(`${this.version}/formulation_templates/${formulation_pk}/add_ingredient/`, ingredient);
  }

  removeIngredientFromFormulation(formulation_pk, ingredient) {
    return this.api.post(`${this.version}/formulation_templates/${formulation_pk}/remove_ingredient/`, ingredient);
  }

  getHistory(pk) {
    return this.api.get(`${this.version}/formulation_templates/${pk}/get_history/`);
  }
}


export default FormulationTemplateAPI;
