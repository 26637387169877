export default class LineItemAPI {
  constructor(api, version) {
    this.api = api;
    this.version = version;
    this.url = this.version + "/line_items"
  }

  listLineItems(order_pk = null) {
    return this.api.get(`${this.version}/`)
  }

  retrieveLineItem(pk) {
    return this.api.get(`${this.version}/${pk}/`)
  }

  createLineItem(lineItem) {
    return this.api.post(`${this.version}/`, lineItem)
  }

  updateLineItem(pk) {
    return this.api.put(`${this.version}/${pk}/`)
  }

  deleteLineItem(pk) {
    return this.api.delete(`${this.version}/${pk}/`)
  }
}