import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';


export default function AutocompleteSD(props) {

  const [initValue, setInitValue] = useState((typeof props.value) == 'number' ? props.value.toString() : props.value);
  const [sdOptions, setSdOptions] = useState([]);

  useEffect(() => {
    //Used for when options are an array of pk's that index and receive back options.
    if(props.optionType == 'pkIndexingToObjects') {
      let pkIndices = [...props.options];
      let choiceObject = {...props.choices};

      let filteredPkIndices = [];

      pkIndices.forEach((pkIndex) => {
        if(choiceObject[pkIndex] && !choiceObject[pkIndex].soft_delete) {
          if( (typeof pkIndex) == 'number' ) {
            filteredPkIndices.push(pkIndex.toString());
          }
          else {
            filteredPkIndices.push(pkIndex);
          }
        }
      })

      if(initValue && (typeof initValue) == 'number' && filteredPkIndices.indexOf(initValue.toString()) == -1) {
        filteredPkIndices.push(initValue.toString());
      }
      else if (initValue && filteredPkIndices.indexOf(initValue) == -1) {
        filteredPkIndices.push(initValue);
      }

      setSdOptions([...filteredPkIndices]);
    }
    else if(props.optionType == "rawObjects") {
      
      let filteredOptions = props?.options?.filter((option) => 
        {
          return ( !option.soft_delete || JSON.stringify(option) == JSON.stringify(initValue) )
        }) || [];
      setSdOptions([...filteredOptions]);
    }


  }, [props.options])

  return (
    <Autocomplete
      {...props}
      options={sdOptions}
    />
  );
}

AutocompleteSD.propTypes = {
  options: PropTypes.object,
  choices: PropTypes.object,
  optionType: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
}