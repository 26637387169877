import React from "react"
import PropTypes from 'prop-types';

import CancelIcon from '@material-ui/icons/Cancel';

import { makeStyles, useTheme } from "@material-ui/core/styles";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  IconButton,
  Paper,
} from "@material-ui/core";

DialogTemplate.propTypes = {
  title: PropTypes.string,
  formContents: PropTypes.object,
  formActions: PropTypes.object, 
  cancel: PropTypes.func,
  open: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 8,
    fontFamily: "Roboto",
    fontSize: "34px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "0.25px"
  },

  bg: {
    padding: "0px", 
    //backgroundColor: "rgb(234, 234, 234, 0.5)",
  },

  form: {
    margin: "16px 16px 16px 16px",
    padding: "16px 16px 16px 16px",
  }, 

  table: {
    //minWidth: 650,
  },

  fakeLink: {
    color: "blue",
    textDecoration: "underline",
    cursor: "pointer",
  },

  bottomBorder: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },

  topBorder: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)'
  }
}));

export default function DialogTemplate(props) {
  const classes = useStyles();

  return (
    <Dialog 
      fullScreen={props.fullScreen != null ? props.fullScreen : true} 
      fullWidth={props.fullWidth != null ? props.fullWidth : true}
      size={props.size != null ? props.size : "sm"}
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle /*className={classes.bottomBorder}*/>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Typography variant="h6">{props.title}</Typography>
          </Grid>
          <Grid container item xs={4} alignItems="center" justify="flex-end">
            <IconButton onClick={props.cancel} style={{padding: 0}}>
              <CancelIcon/>            
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.bg}>
        <Paper className={classes.form} elevation={0}>
          {props.formContents}
        </Paper>
      </DialogContent>
      <DialogActions className={classes.topBorder}>
        <Grid item container xs={12} alignItems="flex-end" justify="flex-end">
          {props.formActions}
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
