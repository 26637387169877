import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import ProductDescription from "../ProductDescriptions/ProductDescription";
import Button from "@material-ui/core/Button";
import API from "../../Api/Api";
import ExportDetail from "../Export/ExportDetail";
import Message from "../../Components/Message";
import { useLocation, useHistory, useRouteMatch, Prompt } from "react-router-dom";
import { StylesContext } from "../../App";

import HazardAnalysis from "../CCP/CCPDetermination/HazardAnalysis";
import MobileStepper from "@material-ui/core/MobileStepper";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import useTheme from "@material-ui/core/styles/useTheme";
import ProcessFlow from "../ProcessFlow/ProcessFlow";
import TrafficFlow from "../TrafficFlowDiagram/TrafficFlow";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import ContentHeader from "../../Components/Layouts/ContentHeader";
import NewFormulations from "../Formulations/NewFormulations";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import NewProcessList from "../Process/NewProcessList";
import useDesktop from "../../hooks/useDesktop";

HaccpPlanDescription.propTypes = {
  pk: PropTypes.string,
  haccpPlanPk: PropTypes.number,
  setShouldClose: PropTypes.func,
  drawerOpen: PropTypes.bool,
  mobileMenuOpen: PropTypes.bool,
  successMessage: PropTypes.func,
};

let confirmed = 0;

function HaccpPlanDescription(props) {
    const theme = useTheme();
    const classes = React.useContext(StylesContext);
    const onDesktop = useDesktop();

    const api = new API();

    const [readOnlyMode, setReadOnlyMode] = useState(false);
    const [haccpPlan, setHaccpPlan] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isBlocking, setIsBlocking] = useState(false);
    const currentMatch = useRouteMatch();

    const history = useHistory();
    //This function checks the org of the active haccp plan and checks if the current
    //user is an admin of that org, if not the plan is set to read-only, which is passed to each
    //component.
    useEffect(() => {
        if (props.user && haccpPlan) {
            if (props.user.organizations) {
                props.user.organizations.forEach((org) => {
                    if (org.organization.pk === haccpPlan.organization) {
                        if (!org.team_admin) {
                            setReadOnlyMode(true);
                        }
                    }
                });
            }
        }
    }, [props, haccpPlan]);
    useEffect(() => {
        confirmed = 0;
        setLoading(true);
        if (currentMatch.params.pk !== "haccp_plans") {
            api.getHaccpPlanAPI()
                .retrieveHaccpPlan(currentMatch.params.pk)
                .then((response) => {
                    setLoading(false);
                    setHaccpPlan(response.data);
                })
                .catch((error) => {
                    setLoading(false);
                    switch (error.response.status) {
                        case 403:
                            history.replace("/plans");
                            break;
                        default:
                            break;
                    }
                });
        }
    }, [currentMatch, props.haccpPlanPk]);

    function handleChange(event) {
        if (event.target.name === "name") {
            setNameError(false);
        }

        const value = event.target.value;

        setHaccpPlan({ ...haccpPlan, [event.target.name]: value });
    }

    const [nameError, setNameError] = useState(false);

    function handleSubmit(e) {
        if (!haccpPlan.name) {
            setNameError(true);
            setReturnStatus("Please Enter A Name", "error");
        } else {
            setIsBlocking(false);
            e.preventDefault();
            updateHaccpPlan(haccpPlan);
        }
    }
    function deletePlan(plan) {
        setDeleteWarningOpen(true);
    }
    const [deleteWarningOpen, setDeleteWarningOpen] = React.useState(false);

    function setWarningClosed() {
        setDeleteWarningOpen(false);
    }

    function deleteWarnedPlan() {
        setDeleteWarningOpen(false);
        api.getHaccpPlanAPI()
            .deleteHaccpPlan(haccpPlan.pk)
            .then((response) => {
                history.push(`/plans/manager/haccp_plans`);
            })
            .catch(() => {});
    }

    function handleSaveAndContinue(e) {
        if (!haccpPlan.name) {
            setNameError(true);
            setReturnStatus("Please Enter A Name", "error");
        } else {
            setIsBlocking(false);
            e.preventDefault();
            updateHaccpPlan(haccpPlan);
            if (props.successMessage) {
                props.successMessage();
            }
            history.push(`${match.url}/description`);
        }
    }

    function successMessage() {
        if (props.successMessage) {
            props.successMessage();
        }
    }

    function updateHaccpPlan(
        updatedHaccpPlan,
        successMessage = "Updated HACCP Plan!",
        errorMessage = "Could not update HACCP Plan"
    ) {
        api.getHaccpPlanAPI()
            .updateHaccpPlan(updatedHaccpPlan)
            .then((response) => {
                setHaccpPlan(response.data);
                if (props.successMessage) {
                    props.successMessage();
                } else {
                    setReturnStatus(successMessage, "info");
                }
            })
            .catch(() => {
                setReturnStatus(errorMessage, "error");
            });
    }

    function exportPlan() {
        return api.getHaccpPlanAPI().exportPlan();
    }

    function addNewProductDescription() {
        api.getProductDescriptionAPI()
            .createProductDescription({
                organization: haccpPlan.organization,
            })
            .then((response) => {
                let updatedHaccpPlan = {
                    ...haccpPlan,
                    product_description: response.data.pk,
                };
                updateHaccpPlan(updatedHaccpPlan);
            })
            .catch(() => {
                setReturnStatus("Could not add ");
            });
    }

    function deleteProductDescription() {
        const updatedHaccpPlan = { ...haccpPlan, product_description: null };
        updateHaccpPlan(updatedHaccpPlan);
    }

    const [formulationForm, setFormulationForm] = useState({
        error: false,
        helperText: "",
        name: "",
        description: "",
    });

    function handleFormulationFormChange(event) {
        setFormulationForm({
            ...formulationForm,
            [event.target.name]: event.target.value,
        });
    }

    function addNewFormulation() {
        if (!formulationForm.name.length) {
            setFormulationForm({
                ...formulationForm,
                error: true,
                helperText: "A name is required.",
            });
            return;
        }

        setFormulationForm({
            ...formulationForm,
            error: false,
            helperText: "",
        });

        let newFormulation = {
            name: formulationForm.name,
            description: formulationForm.description,
            organization: haccpPlan.organization,
            unit_value: 0, //todo this can be removed once the back end issue with modelFields not checking validation properly is cleared up
        };

        api.getFormulationsAPI()
            .createFormulation(newFormulation)
            .then((response) => {
                let updatedHaccpPlan = { ...haccpPlan };
                updatedHaccpPlan.formulations.push(response.data.pk);
                updateHaccpPlan(updatedHaccpPlan);
            })
            .catch(() => {
                setReturnStatus("Could not add a formulation.", "error");
            });
    }

    function copyFormulation(pk) {
        api.getFormulationsAPI()
            .copyFormulation(pk)
            .then((response) => {
                let updatedHaccpPlan = { ...haccpPlan };
                updatedHaccpPlan.formulations.push(response.data.pk);
                updateHaccpPlan(updatedHaccpPlan, "Copied Formulation!");
            })
            .catch(() => {
                setReturnStatus("Could not add a formulation.", "error");
            });
    }

    function deleteFormulation(pk) {
        let updatedHaccpPlan = { ...haccpPlan };

        let index = updatedHaccpPlan.formulations.indexOf(pk);

        if (index > -1) {
            let formulation = updatedHaccpPlan.formulations[index];
            api.getFormulationsAPI()
                .deleteFormulation(formulation)
                .then(() => {
                    updatedHaccpPlan.formulations.splice(index, 1);
                    updateHaccpPlan(updatedHaccpPlan, `Deleted Formulation!`);
                })
                .catch(() => {
                    setReturnStatus("Could not delete formulation.");
                });
        }
    }

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState({
        message: "",
        status: "info",
    }); // error, warning, info, info

  function getHaccpPlanDetailsComponent() {
    //The prompt in this one works a little different as the user can nav away and still
    //have changes cached, so the alert also works that way here.
    return (
      <div>
        <div>
          <Dialog
            open={deleteWarningOpen}
            onClose={setWarningClosed}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Delete this HACCP Plan?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this plan? This action can not
                be reversed.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={setWarningClosed} color="primary">
                No
              </Button>
              <Button onClick={deleteWarnedPlan} color="primary" autoFocus>
                Yes, Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        </div>
        )}
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    function setReturnStatus(message, status) {
        setMessage({ message: message, status: status });
        setOpen(true);
    }

    const [activeStep, setActiveStep] = React.useState(0);
    const [blockNavigation, setBlockNavigation] = useState(false);

    function getHaccpPlanDetailsComponent() {
        //The prompt in this one works a little different as the user can nav away and still
        //have changes cached, so the alert also works that way here.
        return (
            <div>
                <div>
                    <Dialog
                        open={deleteWarningOpen}
                        onClose={setWarningClosed}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Delete this hazard?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to delete this plan? This action can not be reversed.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={setWarningClosed} color="primary">
                                No
                            </Button>
                            <Button onClick={deleteWarnedPlan} color="primary" autoFocus>
                                Yes, Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>

                <ContentHeader title="HACCP Details" delete={true} haccpPlan={haccpPlan} delete={deletePlan} />

                <Grid container spacing={3} className={classes.generalContainerGrid}>
                    <Paper elevation={0} className={classes.generalFormPaperContainerHaccp1} style={{ margin: onDesktop ? "64px" : "16px" }}>
                        <Prompt
                            message={(location, action) => {
                                //Prompt triggers on any nav event, in this case push and then replace
                                //if any are false, the chain stops and resolves.
                                //If one is true somewhere in the chain, the nav proceeds.
                                //The goal of this function is to ignore all of that and have this behave like window.confirm
                                //If they have already confirmed, or made no changes allow navigation
                                if (!isBlocking || (confirmed && action == "REPLACE")) {
                                    setIsBlocking(false);
                                    return true;
                                }
                                //If they haven't confirmed yet, or are are trying to nav again after previously declining, re-confirm
                                else if (confirmed === 0 || action == "PUSH" /*&& confirmed === false*/) {
                                    confirmed = window.confirm(
                                        "You have unsaved changes, are you sure you want to leave?"
                                    );
                                }
                                //If they've said no, and this still transitions to replace, reset confirmed and cancel nav
                                else if (action == "REPLACE" && confirmed === false) {
                                    confirmed = 0;
                                    return false;
                                }

                                return confirmed;
                            }}
                        />
                        <Grid container spacing={2} className={classes.generalContainerGrid}>
                            <Grid item xs={12}>
                                <Typography
                                    style={{
                                        fontFamily: "Roboto",
                                        fontWeight: "500",
                                        fontSize: "14px",
                                        lineHeight: "21px",
                                    }}
                                >
                                    HACCP Plan Name
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    style={{ backgroundColor: "#F8F8F8" }}
                                    placeholder="HACCP Plan Name"
                                    name="name"
                                    variant="outlined"
                                    disabled={readOnlyMode}
                                    error={nameError}
                                    fullWidth
                                    value={haccpPlan.name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    style={{
                                        fontFamily: "Roboto",
                                        fontWeight: "500",
                                        fontSize: "14px",
                                        lineHeight: "21px",
                                    }}
                                >
                                    Plan Description (Optional)
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    style={{ backgroundColor: "#F8F8F8" }}
                                    placeholder="A brief description of the HACCP plan."
                                    name="description"
                                    variant="outlined"
                                    multiline
                                    value={haccpPlan.description}
                                    disabled={readOnlyMode}
                                    fullWidth
                                    rows={4}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    style={{
                                        fontFamily: "Roboto",
                                        fontWeight: "500",
                                        fontSize: "14px",
                                        lineHeight: "21px",
                                    }}
                                >
                                    Created By
                                </Typography>
                            </Grid>{" "}
                            {/* <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "21px",
                  }}
                >
                  Creation Date
                </Typography>
              </Grid> */}
                            <Grid item xs={12}>
                                <TextField
                                    disabled
                                    style={{ backgroundColor: "#F8F8F8" }}
                                    placeholder="Creator Name"
                                    name="created_by"
                                    variant="outlined"
                                    value={haccpPlan.created_by}
                                    fullWidth
                                    onChange={handleChange}
                                />
                            </Grid>
                            {/* <Grid item xs={6}>
                {!myCreationDate && (
                  <TextField
                    style={{ backgroundColor: "#F8F8F8" }}
                    name="creation_date"
                    variant="outlined"
                    type="datetime-local"
                    value={haccpPlan.creation_date}
                    fullWidth
                    onChange={handleChange}
                  />
                )}
                {myCreationDate && (
                  <TextField
                    style={{ backgroundColor: "#F8F8F8" }}
                    placeholder="Creation Date"
                    name="creation_date"
                    variant="outlined"
                    value={new Date(myCreationDate)}
                    fullWidth
                    disabled
                  />
                )}
              </Grid> */}
                            <Grid container spacing={3} className={classes.generalContainerGridFoot}>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ margin: "8px", float: "right" }}
                                        onClick={handleSubmit}
                                        disabled={readOnlyMode}
                                    >
                                        Save Changes
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ margin: "8px", float: "right" }}
                                        onClick={handleSaveAndContinue}
                                        disabled={readOnlyMode}
                                    >
                                        Save And Continue
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </div>
        );
    }

    //todo might be worth moving these structures to the forms themselves so it can be edited in the component.
    //Alternatively, define the paper-grid-grid structure in one place and swap out the innards so it's only defined once.
    function getProductDescriptionComponent() {
        if (haccpPlan && haccpPlan.product_description === null) {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Product Description</Typography>
                        Add a product description to this HACCP Plan.
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            onClick={addNewProductDescription}
                            variant="contained"
                            color="primary"
                            style={{ margin: "8px" }}
                        >
                            Add Product Description
                        </Button>
                    </Grid>
                </Grid>
            );
        }

        if (haccpPlan && haccpPlan.product_description !== null) {
            return (
                <ProductDescription
                    readOnlyMode={readOnlyMode}
                    haccpPlanPk={haccpPlan.pk}
                    setIsBlocking={setIsBlocking}
                    successMessage={successMessage}
                    blockNavigation={blockNavigation}
                    hasButton={true}
                    deleteProductDescription={deleteProductDescription}
                    pk={haccpPlan.product_description}
                    onDesktop={onDesktop}
                />
            );
        }
    }

    function getFormulationVariantsComponent() {
        if (haccpPlan) {
            return (
                <NewFormulations
                    readOnlyMode={readOnlyMode}
                    key={haccpPlan.pk}
                    haccpPlanPk={haccpPlan.pk}
                    setIsBlocking={setIsBlocking}
                    haccpPlan={haccpPlan}
                    copyFormulation={copyFormulation}
                    deleteFormulation={deleteFormulation}
                    successMessage={successMessage}
                />

                // <FormulationList
                //   haccpPlanPk={haccpPlan.pk}
                //   setIsBlocking={setIsBlocking}
                //   haccpPlan={haccpPlan}
                //   copyFormulation={copyFormulation}
                //   deleteFormulation={deleteFormulation}
                //   successMessage={successMessage}
                // />
            );
        }
    }

  function getProcessesComponent() {
    return <NewProcessList haccpPlanPk={haccpPlan.pk} readOnlyMode={readOnlyMode} />;
  }

    function getProcessFlowComponent() {
        return <ProcessFlow setIsBlocking={setIsBlocking} haccpPlanPk={haccpPlan.pk} readOnlyMode={readOnlyMode} />;
    }

    function getTrafficFlowComponent() {
        return <TrafficFlow setIsBlocking={setIsBlocking} haccpPlanPk={haccpPlan.pk} readOnlyMode={readOnlyMode} />;
    }

    function getHazardAnalysisComponent() {
        return <HazardAnalysis haccpPlanPk={haccpPlan.pk} readOnlyMode={readOnlyMode} />;
    }

    function getSignificantHazardsComponent() {
        return <HazardAnalysis haccpPlanPk={haccpPlan.pk} readOnlyMode={readOnlyMode} />;
    }

    let match = useRouteMatch();
    const steps = [
        { step: 0, name: "Plan Details", path: `${match.url}/details` },
        { step: 1, name: "Product Description", path: `${match.url}/description` },
        { step: 2, name: "Formulation", path: `${match.url}/formulation` },
        { step: 3, name: "Processes", path: `${match.url}/processes` },
        { step: 4, name: "Process Flow", path: `${match.url}/process_flow` },
        { step: 5, name: "Traffic Flow", path: `${match.url}/traffic_flow` },
        {
            step: 6,
            name: "Hazard Analysis",
            path: `${match.url}/hazard_analysis/hazards`,
        },
        {
            step: 7,
            name: "Significant Hazards",
            path: `${match.url}/hazard_analysis/significant_hazards`,
        },
        { step: 8, name: "Export To PDF", path: `${match.url}/export` },
    ];

    function getStepContent(step) {
        switch (step) {
            case 0:
                return getHaccpPlanDetailsComponent();
            case 1:
                return getProductDescriptionComponent();
            case 2:
                return getFormulationVariantsComponent();
            case 3:
                return getProcessesComponent();
            case 4:
                return getProcessFlowComponent();
            case 5:
                return getTrafficFlowComponent();
            case 6:
                return getHazardAnalysisComponent();
            case 7:
                return getSignificantHazardsComponent();
            case 8:
                return <ExportDetail pk={haccpPlan.pk} />;
            default:
                return "Unknown step";
        }
    }

    let fullPath = useLocation().pathname;
    useEffect(() => {
        setIsBlocking(false);
        steps.forEach((step) => {
            if (fullPath === step.path) {
                setActiveStep(step.step);
                history.replace(step.path);
            }
        });
    }, [fullPath]);

    const handleNext = () => {
        let newActiveStep = activeStep + 1;

        let step = steps[newActiveStep];

        if (!isBlocking) {
            setActiveStep(step.step);
        }
        setIsBlocking(false);

        history.push(step.path);
    };

    const handleBack = () => {
        let newActiveStep = activeStep - 1;
        let step = steps[newActiveStep];

        if (!isBlocking) {
            setActiveStep(step.step);
        }
        setIsBlocking(false);
        history.push(step.path);
    };

    const handleStep = (step) => () => {
        if (!isBlocking) {
            setActiveStep(step.step);
        }
        history.push(step.path);
    };
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                position: "relative",
                zIndex: 44,
            }}
        >
            {haccpPlan && (
                <div style={{ width: "100%", height: "100%", position: "relative" }}>
                    <Grid
                        container
                        spacing={3}
                        style={{
                            width: "100%",
                            margin: 0,
                            marginBottom: 40,
                            padding: 0,
                        }}
                    >
                        <Paper square className={classes.stepperPaper}>
                            <MobileStepper
                                //className={classes.stepperGrid}
                                style={{ backgroundColor: "white",
                                borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                position: "fixed",
                                zIndex: 5000,
                                bottom: 0,
                                left: props.sideMenuOpen ? "calc(64px + 360px)" : "calc(64px)",
                            }}
                                variant="dots"
                                steps={steps.length}
                                activeStep={activeStep}
                                nextButton={
                                    <Button
                                        size="small"
                                        color={"primary"}
                                        onClick={handleNext}
                                        disabled={activeStep === steps.length - 1}
                                    >
                                        Next
                                        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                    </Button>
                                }
                                backButton={
                                    <Button
                                        size="small"
                                        color={"primary"}
                                        onClick={handleBack}
                                        disabled={activeStep === 0}
                                    >
                                        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                        Back
                                    </Button>
                                }
                            />
                        </Paper>
                        {getStepContent(activeStep)}
                    </Grid>
                </div>
            )}
            {!haccpPlan && (
                <div style={{ width: "100%", height: "100%", position: "relative" }}>
                    <Grid
                        container
                        spacing={3}
                        style={{
                            width: "100%",
                            margin: 0,
                            marginBottom: 40,
                            padding: 0,
                        }}
                    >
                        <Paper className={classes.stepperPaper}>
                            <MobileStepper
                               // className={classes.stepperGrid}
                                style={{ backgroundColor: "white",
                                borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                position: "fixed",
                                zIndex: 5000,
                                bottom: 0,
                                left: props.sideMenuOpen ? "calc(64px + 360px)" : "calc(64px)",
                            }}
                                variant="dots"
                                steps={steps.length}
                                activeStep={activeStep}
                                nextButton={
                                    <Button
                                        size="small"
                                        color={"primary"}
                                        onClick={handleNext}
                                        disabled={activeStep === steps.length - 1}
                                    >
                                        Next
                                        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                    </Button>
                                }
                                backButton={
                                    <Button
                                        size="small"
                                        color={"primary"}
                                        onClick={handleBack}
                                        disabled={activeStep === 0}
                                    >
                                        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                        Back
                                    </Button>
                                }
                            />
                        </Paper>
                        {/* {getStepContent(activeStep)} */}
                    </Grid>
                </div>
            )}

            {loading && <CircularProgress style={{ position: "absolute", left: "50%", top: "50%" }} />}

            <Message
                position={true}
                open={open}
                message={message.message}
                severity={message.status}
                marginBottom="36px"
                style={{ zIndex: 999999 }}
                vertical="bottom"
                horizontal="right"
                handleClose={handleClose}
            />
        </div>
    );
}

export default HaccpPlanDescription;
