import React from "react";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import SanitizeHtml from "Components/SanitizeHtml";
import DialogTemplate from "Components/DialogTemplate";



export default function LogSOP({sop, open, setOpen}) {
	function getFormContents() {
    return (
      <Grid container spacing={3}>
        {sop && <>
        <Grid item xs={12}>
          <Typography variant="h6">
            SOP
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            {sop.name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
						Procedures
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SanitizeHtml value={sop.procedures}/>
        </Grid>
        </>}
      </Grid>
    )
  }
	return (
		<DialogTemplate 
			open={open}
			title={sop.name}
			cancel={() => setOpen(false)}
			formContents={getFormContents()}
			formActions={null}
		/>
	)
}