import React, { useState, useEffect } from "react";
import { useHistory, Switch, Route, Link, useRouteMatch } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert"
import Button from "@material-ui/core/Button";

import useAPI from "hooks/useAPI";
import usePermissions from "hooks/usePermissions";

import ContentHeader from "Components/Layouts/ContentHeader";
import LogEntries from "./LogEntries";

import AvatarComponent from "Components/AvatarComponent";
import SanitizeHtml from "Components/SanitizeHtml";
import useDesktop from "hooks/useDesktop";
import { formatDateTime } from "utils/date";

function LogCard({ log }) {

	const history = useHistory();
	const match = useRouteMatch();
	const onDesktop = useDesktop();

	function cardClick() {
		if (onDesktop) {
			history.push(`${match.path}/entries/${log.id}/`);
		}
		else {
			history.push(`${match.path}/entries/${log.id}/submission/`);
		}
	}


	return (
		<Card elevation={0} style={{ margin: '8px 0px' }}>
			<Grid container item xs={12}>
				<CardActionArea onClick={cardClick}>
					<CardHeader
						titleTypographyProps={{ style: { padding: '4px 0px', fontSize: '14px', fontWeight: 500 } }}
						subheaderTypographyProps={{ style: { fontSize: '11px', fontWeight: 400 } }}
						avatar={<AvatarComponent user={log.last_submitted_by} />}
						title={log.name}
						subheader={
							(log.last_submitted && log.last_submitted_by) ?
								`Last submitted on ${formatDateTime(log.last_submitted)} by ${log.last_submitted_by.first_name + " " + log.last_submitted_by.last_name}` :
								"This log has not been used yet."}
					/>
				</CardActionArea>
			</Grid>
		</Card>
	)
}

export default function Logs(props) {

	const api = useAPI();
	const match = useRouteMatch();
	const history = useHistory();
	const permissions = usePermissions();

	const [logs, setLogs] = useState([]);
	const [filteredLogs, setFilteredLogs] = useState([]);

	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false);
	const [totalIncompleteRecords, setTotalIncompleteRecords] = useState(0);
	useEffect(() => {
		api.getEventAPI().listLogs().then(response => {
			setLogs(response.data);
			setFilteredLogs(response.data);
			setLoading(false);
		}).catch(error => {
			setLoading(false);
			setError(true);
		});
		api.getLogsAPI().incompleteCount().then(response => {
			setTotalIncompleteRecords(response.data.incompleted_records_count)
		}).catch(error => {
			//setLoading(true);

		})
	}, [])

	return (
		<>
			<Grid item container xs={12}>

				<Switch>
					<Route path={`${match.path}/entries/:id`}>
						<Grid container alignItems="center" justify="center">
							<LogEntries />
						</Grid>
					</Route>

					<Route path={`${match.path}`} exact>
						<ContentHeader title="My Logs" search searchData={logs} setSearchResults={setFilteredLogs} searchKeys={['name']} />
						<Grid container alignItems="center" justify="center" style={{ padding: '16px 2.5% 0px 2.5%' }}>
							{loading &&
								<Grid item xs={12}>
									<CircularProgress />
								</Grid>
							}

							{!loading && !logs.length && !error &&
								<Grid item xs={12}>
									<Alert severity="info">
										Could not find any logs, click <Link to="/plans/manager/events">here</Link> to add a new log
									</Alert>
								</Grid>
							}

							{!loading && !error && logs.length > 0 &&
								<Grid item xs={12} style={{ padding: '8px' }}>
									<Paper square elevation={0} style={{ padding: '16px 32px' }}>
										<Grid container item xs={12} alignItems="center">
											<Grid container item xs={8}>
												<Typography style={{ fontSize: '14px', fontWeight: 500 }}>
													Click on a log below to begin
												</Typography>
											</Grid>
											{permissions.team_admin &&
												<Grid container item justify="flex-end" xs={4}>
													<Typography>
														{totalIncompleteRecords > 0 && <span className="incomplete-filter">INCOMPLETE ({totalIncompleteRecords})</span>}

														<Button onClick={() => { history.push("/plans/manager/events/") }} color="primary" size="small" variant="contained" disableElevation>
															Log Editor
														</Button>
													</Typography>
												</Grid>
											}
										</Grid>
									</Paper>
								</Grid>
							}

							{!loading && !error && filteredLogs &&
								<Grid item container xs={12} justify="flex-start">
									{filteredLogs.map(log => (
										<Grid style={{ padding: '8px' }} item xs={12} md={6} lg={4}>
											<LogCard key={log.id} log={log} />
										</Grid>)
									)}
								</Grid>
							}


							{!loading && error &&
								<Grid item container xs={12} justify="center">
									<Alert style={{ width: '100%' }} severity="error">
										Unable to load logs
									</Alert>
								</Grid>
							}
						</Grid>
					</Route>
				</Switch>
			</Grid>
		</>
	)
}