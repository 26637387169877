import React, { useEffect, useState } from "react";

import TabbableGroupedTable from "../../Components/GroupedTable/TabbableGroupTable";
import API from "../../Api/Api";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Traceability from "../Traceability";
import Button from "@material-ui/core/Button";
import TraceabilityUtils from "../TraceabilityUtils";
import Message from "../../Components/Message";
import InventoryAdjustmentForm from "./InventoryAdjustmentForm";
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import Paper from "@material-ui/core/Paper";
import { StylesContext } from "../../App";
import { DialogActions, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

const EMPTY_MESSAGE = {
  open: false,
  message: '',
  status: 'info',
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ItemInstanceTable(props) {
  const classes = React.useContext(StylesContext);
  const utils = new TraceabilityUtils();
  const rawInventoryApi = new Traceability().getFRawInventoryAPI();
  
  const [loading, setLoading] = useState(true);
  const [itemAdjustmentModalOpen, setItemAdjustmentModalOpen] = useState(false);
  const [itemInstanceSelected, setItemInstanceSelected] = useState(null);
  const [itemInstanceModalOpen, setItemInstanceModalOpen] = useState(false);
  const [shouldClose, setShouldClose] = useState(true);
  const [tabValue, grabTabValue] = useState('RAW');
  const [message, setMessage] = useState({ ...EMPTY_MESSAGE });
  const [user, setUser] = useState(null);
  const [ingredients, setIngredients] = useState([]);
  const [rawInventorys, setRawInventorys] = useState([]);
  const [pkgInventorys, setPkgInventorys] = useState([]);
  const [units, setUnits] = useState([]);
  const [tabStatuses, setTabStatuses] = useState(false);
  
  const byRawIngredientColumns = [
    { title: "Ingredient Name", field: "ingredient" },
    { title: "Qty In-Stock", field: "quantity" },
    {
      title: "Actions", field: "actions", render: rowData => (
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => openModal(rowData)}
        >
          View
        </Button>
      )
    }
    // { title: "Type", field: "type" },
  ]

  const byPkgIngredientColumns = [
    { title: "Ingredient Name", field: "ingredient" },
    { title: "Qty In-Stock", field: "quantity" },
    {
      title: "Actions", field: "actions", render: rowData => (
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => openModal(rowData)}
        >
          View
        </Button>
      )
    }
    // { title: "Type", field: "type" },
  ]

  const byIngredientColumns = [
    { title: "Ingredient Name", field: "ingredient" },
    { title: "Qty In-Stock", field: "quantity" },
    {
      title: "Actions", field: "actions", render: rowData => (
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => openModal(rowData)}
        >
          View
        </Button>
      )
    }
  ]

  function openModal(itemInstance) {
    setItemInstanceSelected(itemInstance);
    setItemInstanceModalOpen(true);
  }

  useEffect(() => {
    const authApi = new API().getAuthAPI();
    const unitApi = new Traceability().getUnitsAPI();
    const ingredientApi = new Traceability().getFIngredientsAPI();

    unitApi.listUnits().then(e => {
      setUnits(e.data);
    }).catch(e => {
      //console.log(e);
    })

    ingredientApi.listFIngredients().then(e => {
      setIngredients(e.data);
    }).catch(e => {
      //console.log(e);
    })

    // rawInventoryApi.listFRawInventory("RAW").then(e => {
    //   setRawInventorys(e.data);
    // }).catch(e => {
    //   //console.log(e);
    // })

    // rawInventoryApi.listFRawInventory("PKG").then(e => {
    //   setPkgInventorys(e.data);
    // }).catch(e => {
    //   //console.log(e);
    // })

    Promise.all([
      rawInventoryApi.newlistFRawInventory("RAW"),
      rawInventoryApi.newlistFRawInventory("PKG")
    ])
    .then(([rawInventoryData, pkgInventoryData]) => {
      setRawInventorys(rawInventoryData.data);
      setPkgInventorys(pkgInventoryData.data);
  
      setTabStatuses({
        counted: true,
        column: 'ignore',
        tabs: [
          { title: 'Raw Ingredients', value: 'RAW', count: rawInventoryData.data.length },
          { title: 'Packaging Materials', value: 'PKG', count: pkgInventoryData.data.length },
        ]
      });
    })
    .catch(e => {
      // console.log(e);
    })

    authApi.getAuthedProfile().then(e => {
      setUser(e.data);
    }).catch(e => {
      //console.log(e);
    })

    setLoading(false);

  }, []);

  useEffect(()=>{
    setTabStatuses({
      counted: true,
      column: 'ignore',
      tabs: [
        { title: 'Raw Ingredients', value: 'RAW', count: rawInventorys.length },
        { title: 'Packaging Materials', value: 'PKG', count: pkgInventorys.length },
      ]
    });
  },[rawInventorys,pkgInventorys]);

  useEffect(() => {
    if (loading) {
      return;
    }

    setItemInstanceModalOpen(true);
  }, [itemInstanceSelected])

  function handleItemInstanceClosed() {
    if (shouldClose) {
      setItemInstanceModalOpen(false);
    }
  }

  function formatTableData(tableData) {
    let formattedTableData = [];
    tableData.forEach((dataPoint) => {
      let formattedPoint = {};
      //console.log('xsd dataPoint: ' + JSON.stringify(dataPoint))
      const matchingUnit = units.find(unit => unit.pk == dataPoint.unit_type);
      const abbreviation = matchingUnit ? matchingUnit.abbreviation : '';
      
      formattedPoint.ingredient = dataPoint.ingredient.name;
      formattedPoint.quantity = dataPoint.total_unit_value+' '+abbreviation;

      formattedPoint.lot_codes = dataPoint.lot_codes.map((lotCodeData) => {
        const matchingLotUnit = units.find(unit => unit.pk == lotCodeData.unit_type);
        const lotAbbreviation = matchingLotUnit ? matchingLotUnit.abbreviation : '';
        return {
          "lot_code": lotCodeData.lot_code,
          "unit_value": lotCodeData.unit_value,
          "unit_type": lotCodeData.unit_type,
          "unit": lotCodeData.unit_value + ' ' + lotAbbreviation,
        }
      });

      // formattedPoint.pk = dataPoint.pk;
      formattedTableData.push(formattedPoint);
    })

    return formattedTableData;
  }

  function handleItemAdjustmentClosed() {
    if (shouldClose) {
      setItemAdjustmentModalOpen(false);
    } else {
      alert("Save changes before closing.");
    }
  }

  /**
   * Handles keeping the list up to date with the back end, including updating all lines.
   * 
   * @param {JSON} response - a response object from the server
   */
  function onSave(response) {
    // console.log("response >> ",response.data);
    // const updatedInventoryItem = response.data;

    // if (updatedInventoryItem.ingredient.category == 'RAW') {
    //   const updatedRawInventorys = rawInventorys.filter(item => item.id !== updatedInventoryItem.id);
    //   setRawInventorys([...updatedRawInventorys, updatedInventoryItem]);
    // }
    // else if (updatedInventoryItem.ingredient.category == 'PKG') {
    //   const updatedPkgInventorys = pkgInventorys.filter(item => item.id !== updatedInventoryItem.id);
    //   setPkgInventorys([...updatedPkgInventorys, updatedInventoryItem]);
    // }

    const fetchRawInventory = rawInventoryApi.newlistFRawInventory("RAW");
    const fetchPkgInventory = rawInventoryApi.newlistFRawInventory("PKG");

  // Chain the promises and update the state when both requests are completed
  Promise.all([fetchRawInventory, fetchPkgInventory])
    .then(([rawInventoryData, pkgInventoryData]) => {
      setRawInventorys(rawInventoryData.data);
      setPkgInventorys(pkgInventoryData.data);

      setTabStatuses({
        counted: true,
        column: 'ignore',
        tabs: [
          { title: 'Raw Ingredients', value: 'RAW', count: rawInventoryData.data.length },
          { title: 'Packaging Materials', value: 'PKG', count: pkgInventoryData.data.length },
        ]
      });
    })
    .catch(e => {
      // Handle errors if necessary
      console.error("Error fetching inventory data:", e);
    });

    setMessage({
      open: true,
      message: 'Saved Successfully',
      status: 'success',
    });
  }

  // function openTransferModal() {
  //   setItemInstanceModalOpen(true);
  // }

  function openAdjustmentModal() {
    setItemAdjustmentModalOpen(true);
  }

  function getColumns(tabVal) {
    if(tabVal == 'RAW') {
      return byRawIngredientColumns;
    }
    else if (tabVal == 'PKG') {
      return byPkgIngredientColumns;
    }
    return byIngredientColumns;
  }

  function getFormattedDataByTabValue(tabVal) {
    if(tabVal == 'RAW') {
      return formatTableData(rawInventorys);
    }
    else if (tabVal == 'PKG') {
      return formatTableData(pkgInventorys);
    }
  
    return formatTableData(rawInventorys);
  }
  
  return (
    <>
      { (!tabStatuses || !rawInventorys || !pkgInventorys) &&
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginTop: "250px" }}>
            <Typography>
              <CircularProgress />
            </Typography>
          </Grid>
        </Grid>
      }

      { (tabStatuses && rawInventorys && pkgInventorys) &&
        <Paper elevation={0} square className={classes.generalListPaperContainer} >
          <Grid container spacing={0} style={{ padding: '0px' }}>
            <Grid item xs={12}>
              <TabbableGroupedTable
                data={getFormattedDataByTabValue(tabValue)}
                formatData={formatTableData}
                columns={getColumns(tabValue)}
                draggable={!loading}
                title={tabValue === 'RAW' ? 'Raw Inventory' : 'Packaging Materials'}
                grabTabValue={grabTabValue}
                tabStatuses={tabStatuses}
                actions={//todo buttons are stacked, fix to be in line.
                  [
                    // {
                    //   icon: (props) => (
                    //       <Button size="small" color="primary" variant="outlined" className={classes.tabTableFreeAction}>
                    //         Transfer
                    //       </Button>
                    //   ),
                    //   tooltip: 'Make an Inventory Transfer',
                    //   isFreeAction: true,
                    //   onClick: (event) => openTransferModal()
                    // },
                    {
                      icon: (props) => (
                          <Button size="small" color="primary" variant="outlined" className={classes.tabTableFreeAction}>
                            Adjustment
                          </Button>
                      ),
                      tooltip: 'Make an Inventory Adjustment',
                      isFreeAction: true,
                      onClick: (event) => openAdjustmentModal()
                    },
                  ]
                }
              />
            </Grid>
          </Grid>

          <Dialog open={itemInstanceModalOpen} onClose={handleItemInstanceClosed} maxWidth="sm" fullWidth>
            <DialogTitle>All Raw Inventory Lotcode</DialogTitle>
            <DialogContent style={{ overflow: 'hidden' }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1">Ingredient Name: {itemInstanceSelected?.ingredient}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">Total Produced: {itemInstanceSelected?.quantity}</Typography>
                </Grid>
              </Grid>

              {/* Example content */}
              <TableContainer component={Paper} elevation={1} style={{ padding: '12px', marginTop: '10px',overflow: 'hidden'}}>
                <Table style={{margin: '0px 2% 8px'}}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Ingredient Lot Code</TableCell>
                      <TableCell>Existing Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!itemInstanceSelected && itemInstanceSelected?.lot_codes && itemInstanceSelected.lot_codes.map((lot, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography>{lot.lot_code}</Typography>
                          {/* <CssTextField fullWidth variant="outlined" value={lot.internal_lot_code} disabled />
                       */}
                        </TableCell>
                        <TableCell>
                          <Typography>{lot.unit}</Typography>
                          {/* <CssTextField fullWidth variant="outlined" value={lot.unit} disabled /> */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleItemInstanceClosed} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          {/* <Dialog fullScreen open={itemInstanceModalOpen} onClose={handleItemInstanceClosed} TransitionComponent={Transition}>
            <DialogContent className={classes.generalFormDialogueContainer}>
              <InventoryTransferForm
                onSave={onSave}
                units={units}
                setShouldClose={setShouldClose}
                user={user}
                closeModal={() => { setItemInstanceModalOpen(false) }}
                activeTraceability={props.activeTraceability}
                skus={skus}
                internalLotCodes={internalLotCodes}
                inventoryLocations={inventoryLocations}
                itemInstances={itemInstances}
              />
            </DialogContent>
          </Dialog> */}

          <Dialog fullScreen open={itemAdjustmentModalOpen} onClose={handleItemAdjustmentClosed} TransitionComponent={Transition}>
            <DialogContent className={classes.generalFormDialogueContainer}>
              <InventoryAdjustmentForm
                onSave={onSave}
                units={units}
                setShouldClose={setShouldClose}
                user={user}
                closeModal={() => { setItemAdjustmentModalOpen(false) }}
                activeTraceability={props.activeTraceability}
                // skus={skus}
                ingredients={ingredients}
                // internalLotCodes={internalLotCodes}
                // inventoryLocations={inventoryLocations}
                // itemInstances={itemInstances}
              />
            </DialogContent>
          </Dialog>

          <Message
            open={message.open}
            message={message.message}
            severity={message.status}
            vertical="bottom"
            horizontal="left"
            handleClose={() => { setMessage({ ...message, open: false }); setLoading(false) }}
          />
        </Paper>
      }
    </>
  )
}

ItemInstanceTable.propTypes = {
  itemInstances: PropTypes.arrayOf(PropTypes.object),
  // skus: PropTypes.arrayOf(PropTypes.object),
  // units: PropTypes.objectOf(PropTypes.object),
  // internalLotCodes: PropTypes.arrayOf(PropTypes.object),
  inventoryLocations: PropTypes.arrayOf(PropTypes.object),
  activeTraceability: PropTypes.object.isRequired
}