import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Select from "@material-ui/core/Select";
import PropTypes from "prop-types";
import SkuFields from "./SkuFields";
import ItemTable from "../ItemTable/ItemBody";
import Paper from "@material-ui/core/Paper";
import { StylesContext } from "../../App";
import withStyles from "@material-ui/core/styles/withStyles";
import CancelIcon from "@material-ui/icons/Cancel";
import { Autocomplete } from "@material-ui/lab";
import { Button } from "@material-ui/core";
import Traceability from "Traceability/Traceability";
import TraceabilityUtils from "Traceability/TraceabilityUtils";
import AddFIngredientsDialog from "Traceability/Fcustomer/AddFIngredientsDialog";

const EMPTY_MESSAGE = {
  open: false,
  message: '',
  status: 'info',
};

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "0px solid white",
        backgroundColor: "rgba(0, 0, 0, 0.029)",
      },
    },
  },
})(TextField);

const CssFormControl = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "0px solid white",
        backgroundColor: "rgba(0, 0, 0, 0.029)",
      },
    },
  },
})(FormControl);

function FormulationFields(props) {
  //const [formulation, setFormulation] = useState(props.formulation);
  //const [units, setUnits] = useState(false);
  const classes = React.useContext(StylesContext);
  const utils = new TraceabilityUtils();
  const ingredientApi = new Traceability().getFIngredientsAPI();
  const [openDialog, setOpenDialog] = useState(false);
  // const [ingredients, setIngredients] = useState(props.ingredients ? props.ingredients : []);
  const [message, setMessage] = useState({ ...EMPTY_MESSAGE });

  function handleChange(event) {
    props.setShouldClose(true);
    //if (props.setBlockNavigation) props.setBlockNavigation(true);
    const value = event.target.value;
    props.setFormulation({ ...props.formulation, [event.target.name]: value });
  }

  //filterBy by is the table column value used for comparison in filtering
  //filterFrom is the list of objects your filter by column can take
  //filterFromKey is the key in the filterfrom objects to match the line value to an object
  //filterUsingKey is the key in the filterfrom object used to filter the values for the filtered column itself.

  const lineInfo = [
    {
      key: "ingredient_name",
      title: "Ingredient Name",
      type: "freeSolo",
      choices: props.ingredients ? props.ingredients : [],
      choiceDisplay: "name",
    },
    { key: "unit_value", title: "Quantity", type: "numeric" },
    {
      key: "unit_type",
      title: "Units",
      type: "select",
      choices: props.units.filter((unitObj) => unitObj.unit_category == "GLO"),
      choiceDisplay: "full_name",
      filterBy: "ingredient_name",
      filterFrom: props.ingredients,
      filterFromKey: "name",
      filterUsingKey: "unit_metric",
    },
  ];

  /* It's quicker to delete by just adding an ignore flag and not send those lines to the BE.
   */
  function deleteLine(lineData, setLineData) {
    if (
      window.confirm(
        "Are you sure you want to delete " + lineData.ingredient_name + "?"
      )
    ) {
      setLineData({ ...lineData, delete: true });
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
    // setIngredientToEdit(null);
  };

  const addIngredient = async (payload) => {
    try {
      handleCloseDialog();
      const response = await ingredientApi.createFIngredients(payload);
      props.setIngredients([...props.ingredients, response.data]);
      setMessage({ open: true, message: 'Ingredient Created Successfully', status: 'success' });
    } catch (error) {
      console.error("Error adding customer:", error);
      setMessage({ open: true, message: 'Server Error: ' + utils.formatError(error), status: 'error' });
    }
  };

  function handleAddItem() {
    let organization = JSON.parse(localStorage.getItem("organization"));
    if (props.formulation.ingredients) {
      props.setIngredientInstances([
        ...props.formulation.ingredients,
        {
          organization: organization.pk,
          ingredient_name: "",
          unit_value: 0,
          unit_type: {},
        },
      ]);
    } else {
      props.setIngredientInstances([
        {
          organization: organization.pk,
          ingredient_name: "",
          unit_value: 0,
          unit_type: {},
        },
      ]);
    }
  }

  const actions = [
    //todo need to support icon buttons through actions
    //{ title: 'Delete', func: deleteLine },
    { icon: <CancelIcon style={{ float: "right" }} />, func: deleteLine },
  ];

  function filterTableData() {
    if (
      props.formulation &&
      props.formulation.ingredients
    ) {
      return props.formulation.ingredients.filter(
        (skuInstance) => !skuInstance.delete
      );
    } else {
      return [];
    }
  }
 
  return (
    <>
      <Grid container spacing={3} style={{ marginTop: "8px" }}>
        <Grid item container xs={12} alignItems="center" justify="left">
          <Typography variant="h5" noWrap>
            Formulation Details
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Paper
          elevation={0}
          className={classes.generalFormPaperContainerSkuFields}
          style={{ margin: "16px 12px" }}
        >
          <Grid container spacing={3} style={{ marginTop: "8px" }}>
            <Grid item container xs={12} alignItems="center" justify="left">
              <Typography noWrap className={classes.generalFormTypographyHeader}>
                Product Formulation Name
              </Typography>
              <CssTextField
                //label="Name"
                name="name"
                variant="outlined"
                fullWidth
                //style={{backgroundColor: '#f8f8f8', border: '0px solid white'}}
                value={props.formulation.name}
                onChange={handleChange}
                error={props.submitAttempted && !props.formulation.name}
              />
            </Grid>

            <Grid item container xs={6}>
              <Typography className={classes.generalFormTypographyHeader}>
                Whats the formulation yield, per batch?
              </Typography>
              <CssTextField
                placeholder="Value"
                name="unit_value"
                variant="outlined"
                fullWidth
                value={props.formulation.unit_value}
                onChange={handleChange}
                error={props.submitAttempted && !props.formulation.unit_value}
              />
            </Grid>
            <Grid item container xs={6}>
              {/* Just to keep spacing. Easier and more consistent than trying to space with css.*/}
              <Typography className={classes.generalFormTypographyHeader}>
                Unit Type
              </Typography>
              {/* <CssFormControl variant="outlined" style={{ width: "100%" }}>
              <Select
                native
                placeholder="Unit Type"
                style={{ width: "100%" }}
                value={props.formulation.unit_type}
                onChange={handleChange}
                error={props.submitAttempted && !props.formulation.unit_value}
                inputProps={{
                  name: "unit_type",
                  id: "unit_type",
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowDropDownIcon />
                    </InputAdornment>
                  ),
                }}
              >
                <option aria-label="None" value="" />

                {props.units &&
                  props.units
                    .filter((unitObj) => unitObj.unit_category == "GLO")
                    .map((unitObj) => {
                      return (
                        <option value={unitObj.pk}>
                          {" "}
                          {unitObj.full_name}{" "}
                        </option>
                      );
                    })}
              </Select>
            </CssFormControl> */}
              <CssFormControl variant="outlined" style={{ width: "100%" }}>
                <Autocomplete
                  id="unit_type"
                  name="unit_type"
                  value={props.units.find(unitObj => unitObj.pk === props.formulation.unit_type) || null}
                  onChange={(event, newValue) => {
                    handleChange({
                      target: {
                        name: "unit_type",
                        value: newValue ? newValue.pk : "",
                      }
                    });
                  }}
                  options={props.units ? props.units.filter((unitObj) => unitObj.unit_category == "GLO") : []}
                  getOptionLabel={(option) => option.full_name || ""}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      variant="outlined"
                      placeholder="Unit Type"
                      error={props.submitAttempted && !props.formulation.unit_value}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.endAdornment}
                            <InputAdornment position="end">
                              <ArrowDropDownIcon />
                            </InputAdornment>
                          </>
                        )
                      }}
                    />
                  )}
                />
              </CssFormControl>
            </Grid>
          </Grid>
        </Paper>

        <Grid item container xs={12} alignItems="center" justify="left">
          <Typography variant="h5" noWrap>
            Ingredients
          </Typography>
          <Typography variant="h6" style={{ flex: 1, textAlign: 'right', marginRight: 8 }}>
            Ingredient not found in the list ?
          </Typography>
          <Button size="small" color="primary" variant="outlined" aria-label="add" style={{ marginLeft: 'auto' }}
            className={classes.tabTableFreeAction} onClick={() => { setOpenDialog(true) }}
          >
            New Ingredient
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Paper
          elevation={0}
          className={classes.generalFormPaperContainerSkuFields}
          style={{ margin: "16px 12px" }}
        >
          <ItemTable
            tableData={filterTableData()}
            tableTitle={""}
            setLineData={props.setIngredientInstances}
            lineInfo={lineInfo}
            actions={actions}
            handleAddItem={() => {
              handleAddItem();
            }}
          />
        </Paper>

        {/* <Grid item container xs={12} alignItems="center" justify="left">
        <Typography variant="h5" noWrap>
          SKU Fields
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Paper elevation={0} className={classes.generalFormPaperContainerSkuFields} style={{margin: '16px 12px'}}>
        <Grid container spacing={3} style={{marginTop: '8px'}}>
          <Grid item container xs={12} alignItems="center" justify="center">
            <SkuFields
              sku={props.sku}
              setSku={props.setSku}
              units={props.units}
              inventoryLocations={props.inventoryLocations}
              setShouldClose={props.setShouldClose}
              suppliers={props.suppliers}
              ingredients={props.ingredients}
              formulationUnitType={props.units.find(unit => unit.pk == props.formulation.unit_type)}
              submitAttempted={props.submitAttempted}
              isFormulation={true}
            />
          </Grid>
        </Grid>
      </Paper>

      <Grid item container xs={12} alignItems="center" justify="left">
        <Typography variant="h5" noWrap>
          Process
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Paper elevation={0} className={classes.generalFormPaperContainerSkuFields} style={{margin: '16px 12px'}}>
        <Grid container spacing={3} style={{marginTop: '8px'}}>
          <Grid item container xs={12}>
            <Typography variant="h6" noWrap className={classes.generalFormTypographyHeader}>
              Describe the process to produce this formulation. (include each step along with temperatures, equipment used, times, etc.)
            </Typography>
            <CssTextField
              id="procedure"
              value={props.formulation.procedure}
              label="Describe the process to produce this formulation here"
              name="procedure" 
              multiline
              fullWidth
              rows={4}
              variant="outlined"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Paper>*/}
      </Grid>
      <AddFIngredientsDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        handleSave={addIngredient}
        ingredientToEdit={null}
        editable={true}
      />
    </>
  );
}

export default FormulationFields;

FormulationFields.propTypes = {
  formulation: PropTypes.object,
  setFormulation: PropTypes.func,
  setIngredientInstances: PropTypes.func,
  setShouldClose: PropTypes.func,
  suppliers: PropTypes.array,
  inventoryLocations: PropTypes.arrayOf(PropTypes.object),
  sku: PropTypes.object,
  setSku: PropTypes.func,
  ingredients: PropTypes.array,
  units: PropTypes.array,
  user: PropTypes.object,
};
