import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";

import API from "../../Api/Api";

import { fabric } from "fabric";
import { addBackgroundImage, compareProcesses, checkProcessesForCCPs, updateFont } from "./DiagramUpdater.js";
import { calculateLineIntersection, calcLineCoords } from "../../Components/LineIntersections";

import EditProcessModal from "../TrafficFlowDiagram/EditProcessModal";
import EditCrossContaminationPointModal from "../TrafficFlowDiagram/EditContaminationPointModal";
import TrafficFlowDiagramDescription from "../TrafficFlowDiagram/TrafficFlowDiagramDescription";
import TrafficFlowDiagramImageManager from "../TrafficFlowDiagram/TrafficFlowDiagramImageManager";
import TextField from "@material-ui/core/TextField";

import Message from "../../Components/Message";

import { BackButton } from "../../Components/BackButton";

import { useRouteMatch } from "react-router-dom";
import { Prompt } from "react-router";

import { connect } from "react-redux";

import { SwatchesPicker } from "react-color";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import VerticalDotMenu from "../../Components/VerticalDotMenu";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { IconButton } from "@material-ui/core";
import GridMenu from "./GridMenu";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import UndoIcon from "@material-ui/icons/Undo";
import RedoIcon from "@material-ui/icons/Redo";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import DescriptionIcon from "@material-ui/icons/Description";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import GridOnIcon from "@material-ui/icons/GridOn";
import SaveIcon from "@material-ui/icons/Save";
import AddBoxIcon from "@material-ui/icons/AddBox";

import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: "white",
    },
    textButton: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
}));

fabric.Object.prototype._controlsVisibility = {
    tl: true,
    tr: true,
    br: true,
    bl: true,
    ml: false,
    mt: false,
    mr: false,
    mb: false,
    mtr: false,
};

let lineVariant = "arrow";
const lineStrokeOptions = [
    {
        name: "Solid",
        doubleEnded: false,
        strokeDashArray: null,
    },
    {
        name: "Solid (double-ended)",
        doubleEnded: true,
        strokeDashArray: null,
    },
    {
        name: "Dashed (short)",
        doubleEnded: false,
        strokeDashArray: [5, 5],
    },
    {
        name: "Dashed (short) (double-ended)",
        doubleEnded: true,
        strokeDashArray: [5, 5],
    },
    {
        name: "Dashed (medium)",
        doubleEnded: false,
        strokeDashArray: [10, 5],
    },
    {
        name: "Dashed (medium) (double-ended)",
        doubleEnded: true,
        strokeDashArray: [10, 5],
    },
    {
        name: "Dashed (long)",
        doubleEnded: false,
        strokeDashArray: [15, 5],
    },
    {
        name: "Dashed (long) (double-ended)",
        doubleEnded: true,
        strokeDashArray: [15, 5],
    },
];

let color = { hex: "#000000", rgb: { r: "0", g: "0", b: "0", a: "1" } };

let canvasMode = "select";

let activeLine = null;
let previousLine = null;
let previousPath = null;
let previousPaths = [];
let startX, startY, secondX, secondY, perpendicularize;

let intersectionPoints = [];
let tempIntersectionPoints = [];

let isDown = false;
let activeObject = null;

let shiftKeyDown = false;
let altKeyDown = false;
let ctrlKeyDown = false;
let metaKeyDown = false;

const cellSize = 50;

let CANVAS_HEIGHT;
let CANVAS_WIDTH;
//let rows;
//let cols;

let canvas = new fabric.Canvas("diagram", {
    backgroundColor: "rgb(255, 255, 255)",
    selection: true,
    stopContextMenu: true, // disable right click menu
    fireRightClick: true,
});

let processFlow;
let trafficFlow;
let flowDiagram;
let gridEnabled;
let confirmed = 0;

DiagramDrawer.propTypes = {
    processFlow: PropTypes.object,
    traffic_flow: PropTypes.object,
    width: PropTypes.string,
    height: PropTypes.string,
    successMessage: PropTypes.func,
    setBlockNavigation: PropTypes.func,
    saveDiagram: PropTypes.func.isRequired,
    CCPs: PropTypes.array,
    haccpPlan: PropTypes.object,
    processes: PropTypes.array,
    useProcessCircles: PropTypes.bool,
    processAPI: PropTypes.any.isRequired,
    processFlowAPI: PropTypes.any,
    // setFlowDiagram: PropTypes.func.isRequired,
    getCCPs: PropTypes.func,
    dispatch: PropTypes.func,
    deleteProcess: PropTypes.func,
    past: PropTypes.array,
    present: PropTypes.object,
    future: PropTypes.array,
    buttons: PropTypes.arrayOf(PropTypes.element),
    templateMode: PropTypes.bool,
};

const CANVAS_MODE_INDEX = 0;
const LINE_VARIANT_INDEX = 1;
const LINE_COLOR_INDEX = 2;
const GRID_SWITCH_INDEX = 3;
const HISTORY_MENU_INDEX = 4;

let globalHeight = 50;
let globalWidth = 50;

function DiagramDrawer(props) {
    const [processSelected, _setProcessSelected] = useState(null);
    const processSelectedRef = useRef(processSelected);
    const setProcessSelected = (data) => {
        processSelectedRef.current = data;
        _setProcessSelected(data);
    };

    const [processModalOpen, setProcessModalOpen] = useState(false);

    const [undoDialogOpen, setUndoDialogOpen] = useState(false);

    const [messageOpen, setMessageOpen] = useState(false);

    const [shouldClose, setShouldClose] = useState(false);
    const [isBlocking, setIsBlocking] = useState(false);

    const classes = useStyles();

    const [undoDisabled, setUndoDisabled] = useState(true);
    const [redoDisabled, setRedoDisabled] = useState(true);
    const [didSomething, setDidSomething] = useState(false);

    const [gridEnabledReact, setGridEnabledReact] = useState(!!props.processFlow);
    const [crossContaminationPointSelected, setCrossContaminationPointSelected] = useState(null);
    const [crossContaminationModalOpen, setCrossContaminationModalOpen] = useState(false);
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);
    const [imageManagerOpen, setImageManagerOpen] = useState(false);
    const [gridMenuOpen, setGridMenuOpen] = useState(false);
    const [mode, setMode] = useState("select");
    const [status, setStatus] = useState({ message: null, severity: "success" });
    const [reactLineVariant, setReactLineVariant] = useState(lineStrokeOptions[0].name);

    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [reactColor, setReactColor] = useState({
        hex: "#000000",
        rgb: { r: "0", g: "0", b: "0", a: "1" },
    });

    const [gridWidth, setGridWidth] = useState(50);
    const [gridHeight, setGridHeight] = useState(50);
    const [gridColor, setGridColor] = useState("grey");
    const [rows, setRows] = useState(50);
    const [cols, setCols] = useState(50);

    useEffect(() => {
        processFlow = props.processFlow;
        trafficFlow = props.trafficFlow;
        flowDiagram = props.processFlow ? props.processFlow : props.trafficFlow;

        // size of printer paper in portrait mode
        CANVAS_WIDTH = fabric.util.parseUnit(props.width || "8.5in");
        CANVAS_HEIGHT = fabric.util.parseUnit(props.height || "11in");
        setRows(CANVAS_HEIGHT / gridHeight);
        setCols(CANVAS_WIDTH / gridWidth);
        gridEnabled = !!props.processFlow;
        confirmed = 0;

        initializeCanvas(flowDiagram);
        canvas.requestRenderAll();

        return function cleanup() {
            removeWindowListeners();
        };
    }, []);

    useEffect(() => {
        if (gridEnabledReact) {
            setCols(CANVAS_WIDTH / gridWidth);
            globalWidth = gridWidth;
        }
    }, [gridWidth]);

    useEffect(() => {
        if (gridEnabledReact) {
            setRows(CANVAS_HEIGHT / gridHeight);
            globalHeight = gridHeight;
        }
    }, [gridHeight]);

    useEffect(() => {
        if (gridEnabledReact) {
            removeGrid();
            drawGrid();
        }
    }, [rows, cols, gridColor]);

    useEffect(() => {
        if (!gridEnabledReact) {
            removeGrid();
        } else {
            drawGrid();
        }

        gridEnabled = gridEnabledReact;

        canvas.requestRenderAll();
    }, [gridEnabledReact]);

    useEffect(() => {
        if (canvas) {
            changeMode(mode);
        }
    }, [mode]);

    useEffect(() => {
        if (status.message) {
            setMessageOpen(true);
        }
    }, [status]);

    function handleModalOpen() {
        if (props.processFlow) {
            setProcessModalOpen(true);
        }
    }

    function handleModalClosed(check) {
        if (props.processFlow) {
            if (!shouldClose) {
                setProcessModalOpen(false);
            } else {
                alert("Save changes before closing.");
            }
        }
    }

    function editProcess() {
        if (processSelected) {
            handleModalOpen();
        }
    }

    function windowKeyPress(event) {
        if (event.key === "q") {
            // cancel line drawing
            canvas.remove(activeLine);
            canvas.remove(...tempIntersectionPoints);
            //todo group in here

            if (activeLine && canvasMode == "wall") {
                canvas.remove(previousPaths[previousPaths.length - 1]);

                previousPaths.forEach((path) => {
                    path.haccp_type = "wall";
                    canvas.remove(path);
                });

                let roomGroup = new fabric.Group(previousPaths);
                roomGroup.set({
                    haccp_type: "room",
                    selectable: false,
                    perPixelTargetFind: true,
                    lockScalingFlip: true,
                    hoverCursor: "default",
                });

                canvas.add(roomGroup);

                props.dispatch({
                    type: "addAction",
                    event: "CreatedRoomGroup",
                    value: { roomGroup },
                });
                setIsBlocking(true);
                if (props.setIsBlocking) {
                    props.setIsBlocking(true);
                }
                setUndoDisabled(false);

                canvas.remove(activeLine);
                activeLine = null;

                previousLine = null;
                previousPath = null;
                previousPaths = [];

                canvas.requestRenderAll();
                return;
            }

            activeLine = null;
            previousPath = null;
            previousLine = null;
            previousPaths = [];
        }
    }

    function windowMouseDown(event) {
        shiftKeyDown = event.shiftKey;
        altKeyDown = event.altKey;
        ctrlKeyDown = event.ctrlKey;
        metaKeyDown = event.metaKey;
    }

    function windowMouseMove(event) {
        shiftKeyDown = event.shiftKey;
        altKeyDown = event.altKey;
        ctrlKeyDown = event.ctrlKey;
        metaKeyDown = event.metaKey;
    }

    function windowMouseUp(event) {
        shiftKeyDown = event.shiftKey;
        altKeyDown = event.altKey;
        ctrlKeyDown = event.ctrlKey;
        metaKeyDown = event.metaKey;
    }

    function addWindowListeners() {
        window.addEventListener("keypress", windowKeyPress);
        window.addEventListener("mousedown", windowMouseDown);
        window.addEventListener("mousemove", windowMouseMove);
        window.addEventListener("mouseup", windowMouseUp);
    }

    function removeWindowListeners() {
        window.removeEventListener("keypress", windowKeyPress);
        window.removeEventListener("mousemove", windowMouseMove);
        window.removeEventListener("mousedown", windowMouseDown);
        window.removeEventListener("mouseup", windowMouseUp);
    }

    useEffect(() => {
        addWindowListeners();

        return () => {
            removeWindowListeners();
        };
    }, []);

    function updatePathCoords(path, offset = null) {
        let coords = calcLineCoords(path);
        if (offset != null) {
            path.set({
                x1: coords[0].x + offset.left,
                y1: coords[0].y + offset.top,
                x2: coords[1].x + offset.left,
                y2: coords[1].y + offset.top,
            });
        } else {
            path.set({
                x1: coords[0].x,
                y1: coords[0].y,
                x2: coords[1].x,
                y2: coords[1].y,
            });
        }
    }

    async function saveData(canvas, haccpPlan, diagramType, updatedFlowDiagram) {
        setIsBlocking(false);

        if (props.setIsBlocking) {
            props.setIsBlocking(false);
        }

        if (gridEnabledReact) {
            removeGrid();
        }

        props
            .saveDiagram(canvas, haccpPlan, diagramType, updatedFlowDiagram)
            .then(() => {
                if (diagramType === "PROCESS_FLOW") {
                    setReturnStatus("Updated Process Flow diagram", "info");
                } else if (diagramType === "TRAFFIC_FLOW") {
                    setReturnStatus("Updated Traffic Flow diagram", "info");
                }

                if (gridEnabledReact) {
                    drawGrid();
                }
            })
            .catch((error) => {
                setReturnStatus("Could not update diagram.", "error");
            });
    }

    function mouseDown(event) {
        if (canvasMode === "select") {
            selectModeMouseDown(event);
        } else if (canvasMode === "line") {
            lineModeMouseDown(event);
        }
    }

    function mouseUp(event) {
        if (canvasMode === "select") {
            selectModeMouseUp(event);
        } else if (canvasMode === "line") {
            lineModeMouseUp(event);
        } else if (canvasMode === "wall") {
            wallModeMouseUp(event);
        }
    }

    function mouseMove(event) {
        if (canvasMode === "line") {
            lineModeMouseMove(event);
        }
    }

    function handleModeChange(event) {
        setMode(event.target.value);
    }

    // Change the mode to something else (draw, delete, etc)
    function changeMode(mode) {
        switch (mode) {
            case "select":
                initializeSelectMode();
                break;
            case "line":
                initializeLineMode();
                break;
            case "wall":
                initializeWallMode();
                break;
            default:
                console.log("Invalid mode");
        }
    }

    function initializeSelectMode() {
        canvasMode = "select";

        if (!canvas.__eventListeners) {
            return;
        }

        if (activeLine) {
            canvas.remove(activeLine);
            activeLine = null;
        }

        canvas.__eventListeners["mouse:move"] = null;
        canvas.__eventListeners["mouse:down"] = null;
        canvas.__eventListeners["mouse:up"] = null;

        canvas.selection = true;
        canvas.forEachObject((object) => {
            if (object.haccp_type === "gridLine") {
                return;
            }

            object.selectable = true;
            object.hoverCursor = "grab";
        });

        canvas.on("mouse:down", selectModeMouseDown);
        canvas.on("mouse:move", selectModeMouseUp);

        canvas.requestRenderAll();
    }

    function initializeLineMode() {
        canvasMode = "line";

        canvas.discardActiveObject().renderAll();

        if (activeObject) {
            activeObject = null;
        }

        if (processSelected) {
            setProcessSelected(null);
        }

        if (canvas.__eventListeners) {
            canvas.__eventListeners["mouse:move"] = null;
            canvas.__eventListeners["mouse:down"] = null;
            canvas.__eventListeners["mouse:up"] = null;
        }

        canvas.on("mouse:down", lineModeMouseDown);
        canvas.on("mouse:move", lineModeMouseMove);

        canvas.selection = false;
        canvas.forEachObject((object) => {
            object.selectable = false;

            if (object.haccp_type === "process") {
                object.hoverCursor = "default";
            } else {
                object.hoverCursor = "grab";
            }
        });

        canvas.requestRenderAll();
    }

    function initializeWallMode() {
        canvasMode = "wall";

        canvas.discardActiveObject().renderAll();

        if (activeObject) {
            activeObject = null;
        }

        if (processSelected) {
            setProcessSelected(null);
        }

        if (canvas.__eventListeners) {
            canvas.__eventListeners["mouse:move"] = null;
            canvas.__eventListeners["mouse:down"] = null;
            canvas.__eventListeners["mouse:up"] = null;
        }

        canvas.on("mouse:down", wallModeMouseDown);
        canvas.on("mouse:move", wallModeMouseMove);

        canvas.selection = false;
        canvas.forEachObject((object) => {
            object.selectable = false;
            object.hoverCursor = "default";

            /*if (object.haccp_type === "process") {
        object.hoverCursor = "default";
      } else {
        object.hoverCursor = "grab";
      }*/
        });

        canvas.requestRenderAll();
    }

    function resizeProcessText(event) {
        let actualWidth, largest, tryWidth;

        actualWidth = event.target.scaleX * event.target.width;
        largest = canvas.getActiveObject().__lineWidths[0];
        tryWidth = (largest + 15) * event.target.scaleX;

        canvas.getActiveObject().set("width", tryWidth);
        if (event.target.left + actualWidth >= canvas.width - 10) {
            event.target.set("width", canvas.width - event.target.left - 10);
        }

        canvas.requestRenderAll();
    }

    function setProcessText(process_pk, name) {
        let objs = canvas.getActiveObjects();

        objs.forEach((object) => {
            if (object.pk === process_pk) {
                let processGroup = object;
                updateFont(processGroup, name);
            }
        });

        canvas.requestRenderAll();
    }

    const canvas_styling = {
        zIndex: 0,
    };

    const control_styling = {
        position: "sticky",
        backgroundColor: "white",
        top: 0,
        zIndex: 2,
    };

    function setupCanvas(trafficFlow, grid) {
        canvas.clear();
        canvas.dispose();

        if (grid) {
            drawGrid();
        }
        addTextListeners();
        addProcessListeners();
        canvas.requestRenderAll();
    }

    function processClickedListener(event) {
        if (event.target) {
            setProcessSelected(event.target.pk);
        }
        if (event.target && event.target._objects && event.target._objects.length > 1) {
            setProcessMousedOver(event.target._objects[1].text);
        }
    }

    function processDoubleClickedListener(event) {
        if (!event.target || canvasMode != "select") {
            return;
        }

        let process = event.target;
        setProcessSelected(process.pk);
        handleModalOpen();
    }

    function textDoubleClickedListener(event) {
        if (!event.target || canvasMode != "select") {
            return;
        }

        let text = event.target;
        // setProcessSelected(process.pk);
        handleTextEditModalOpen(text);
    }

    function handleTextEditModalClosed() {
        setEditTextModalOpen(false);
    }
    const [myTextObject, setMyTextObject] = useState(null);
    function handleTextEditModalOpen(text) {
        setMyTextObject(text);
        setEditTextModalOpen(true);
    }
    const [editTextModalOpen, setEditTextModalOpen] = useState(false);
    function processMovedListener(event) {
        if (gridEnabled) {
            // snap to grid

            let snap = getCoordinate(event.target.left, event.target.top);
            event.target.set({ left: snap.x, top: snap.y });

            let proc = event.target;
            setIsBlocking(true);
            if (props.setIsBlocking) {
                props.setIsBlocking(true);
            }
            setUndoDisabled(false);
            setRedoDisabled(false);
        }

        event.target.bringToFront();
    }

    function getCoordinate(x, y) {
        let roundedX = Math.round(x / globalWidth) * globalWidth;
        let roundedY = Math.round(y / globalHeight) * globalHeight;

        return { x: roundedX, y: roundedY };
    }

    function initializeCanvas() {
        canvas = new fabric.Canvas("diagram", {
            backgroundColor: "rgb(255, 255, 255)",
            selection: true,
            stopContextMenu: true, // disable right click menu
            fireRightClick: true,
        });

        canvas.setDimensions({
            width: CANVAS_WIDTH + 2,
            height: CANVAS_HEIGHT + 2,
        });

        canvas.on("object:moved", (event) => {
            if (!event.target) {
                return;
            }
            if (event.target.haccp_type === "process") {
            } else if (event.target.haccp_type === "cross_contamination_point") {
            } else if (event.target.haccp_type === "lineGroup" || event.target.haccp_type === "room") {
                // arrow line selected
                let group = event.target;
                updateArrowCoordinates(group);
            } else if (event.target.haccp_type === "line") {
                // non-capped line selected
                canvas.discardActiveObject();
                updatePathCoords(event.target);
            } else if (event.target._objects && event.target._objects.length) {
                // multiple items selected
                let initialSelectionGroup = canvas.getActiveObject();
                let objects = canvas.getActiveObjects();
                canvas.discardActiveObject();

                let finalSelection = new fabric.ActiveSelection([], { canvas: canvas });

                objects.forEach((object) => {
                    if (object.haccp_type === "lineGroup") {
                        updateArrowCoordinates(object);
                    } else if (object.haccp_type === "line") {
                        updatePathCoords(object);
                    }

                    finalSelection.addWithUpdate(object);
                });

                canvas.setActiveObject(finalSelection);
            }

            setIsBlocking(true);
            if (props.setIsBlocking) {
                props.setIsBlocking(true);
            }
            canvas.requestRenderAll();
        });

        //addWindowListeners();

        if (props.processFlow) {
            initializeProcessFlowDiagram(flowDiagram);
        }
        if (props.trafficFlow) {
            initializeTrafficFlowDiagram(flowDiagram);
        }
    }

    function crossContaminationPointDoubleClickedListener(event) {
        if (!event.target || canvasMode != "select") {
            return;
        }

        let crossContaminationPoint = event.target;
        setCrossContaminationPointSelected(crossContaminationPoint.pk);
        setCrossContaminationModalOpen(true);
    }

    function addCrossContaminationPointListeners() {
        canvas.forEachObject((object) => {
            if (object.haccp_type === "cross_contamination_point") {
                let cross_contamination_point = object;
                cross_contamination_point.set({ hasControls: false });
                cross_contamination_point.on("mousedblclick", crossContaminationPointDoubleClickedListener);
            }
        });
    }

    function initializeTrafficFlowDiagram(trafficFlowDiagram) {
        if (trafficFlowDiagram == null || trafficFlowDiagram.diagram == null) {
            compareProcesses(canvas, props.processes, canvasMode);

            addTextListeners();
            addProcessListeners();
            addCrossContaminationPointListeners();

            new API()
                .getCcpAPI()
                .getProcessCCPs(props.haccpPlan.pk)
                .then((response) => {
                    checkProcessesForCCPs(canvas, response.data);
                    initializeSelectMode(canvas);

                    canvas.requestRenderAll();
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            canvas.loadFromJSON(trafficFlowDiagram.diagram, () => {
                addBackgroundImage(canvas, trafficFlowDiagram, "TRAFFIC_FLOW");

                compareProcesses(canvas, props.processes, canvasMode);

                addTextListeners();
                addProcessListeners();
                addCrossContaminationPointListeners();

                new API()
                    .getCcpAPI()
                    .getProcessCCPs(props.haccpPlan.pk)
                    .then((response) => {
                        checkProcessesForCCPs(canvas, response.data);
                        initializeSelectMode(canvas);

                        let objects = canvas.getObjects();
                        objects.forEach((object) => {
                            if (object.haccp_type === "lineGroup") {
                                updateArrowCoordinates(object);
                            } else if (object.haccp_type === "line") {
                                updatePathCoords(object);
                            }
                        });

                        canvas.requestRenderAll();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            });
        }
    }

    function initializeProcessFlowDiagram(processFlowDiagram) {
        if (processFlowDiagram == null || processFlowDiagram.diagram == null) {
            drawGrid();

            compareProcesses(canvas, props.processes, canvasMode);
            addProcessListeners();
            addTextListeners();

            props.CcpAPI.getProcessCCPs(props.haccpPlan.pk)
                .then((response) => {
                    checkProcessesForCCPs(canvas, response.data);
                    canvas.requestRenderAll();
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            canvas.loadFromJSON(processFlowDiagram.diagram, () => {
                drawGrid();

                compareProcesses(canvas, props.processes, canvasMode);
                addTextListeners();
                addProcessListeners();

                props.CcpAPI.getProcessCCPs(props.haccpPlan.pk)
                    .then((response) => {
                        checkProcessesForCCPs(canvas, response.data);

                        let objects = canvas.getObjects();
                        objects.forEach((object) => {
                            if (object.haccp_type === "lineGroup") {
                                updateArrowCoordinates(object);
                            } else if (object.haccp_type === "line") {
                                updatePathCoords(object);
                            }
                        });

                        canvas.requestRenderAll();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            });
        }
    }

    function loadActiveProcessCCPText() {
        if (processSelected == null) {
            return;
        }

        props.processAPI.getCCPs(processSelected).then((response) => {
            setProcessModalOpen(false);
            setStatus({
                message: "CCP names added.",
                severity: "info",
            });

            let ccps = response.data;
            let xOffset = 10;
            let yOffset = 10;

            ccps.forEach((ccp) => {
                if (!ccp.identifier) {
                    return;
                }

                let ccpText = new fabric.Textbox(ccp.identifier, {
                    width: 100,
                    left: xOffset,
                    top: yOffset,
                    fontSize: 12,
                    haccp_type: "ccpText",
                    textAlign: "center",
                    lockScalingFlip: true,
                });

                canvas.add(ccpText);
                xOffset += 10;
                yOffset += 10;
            });

            canvas.requestRenderAll();
        });
    }

    /**
     * Done without cloning now, as Fabric does not get along well with cloning grouped paths.
     *
     * @param {*} group - The fabric group object to duplicate.
     */
    function updateArrowCoordinates(group) {
        let objects = group._objects;

        //This breaks the objects from the group more or less, making them relative to canvas
        //group._restoreObjectsState();
        group.destroy();

        for (let i = 0; i < objects.length; i++) {
            if (objects[i].haccp_type === "line") {
                updatePathCoords(objects[i]);
            }
        }

        //This regroups the objects, making them relative to the group
        group._updateObjectsCoords();
    }

    function drawGrid() {
        for (let row = 0; row < rows + 1; row++) {
            canvas.add(
                new fabric.Line([0, row * gridHeight, CANVAS_WIDTH, row * gridHeight], {
                    fill: gridColor,
                    stroke: gridColor,
                    haccp_type: "gridLine",
                    strokeWidth: 0.5,
                    opacity: 0.75,
                    selectable: false,
                    excludeFromExport: true,
                    perPixelTargetFind: true,
                })
            );
        }

        for (let col = 0; col < cols + 1; col++) {
            canvas.add(
                new fabric.Line([col * gridWidth, 0, col * gridWidth, CANVAS_HEIGHT], {
                    fill: gridColor,
                    stroke: gridColor,
                    haccp_type: "gridLine",
                    strokeWidth: 0.5,
                    opacity: 0.75,
                    selectable: false,
                    excludeFromExport: true,
                    perPixelTargetFind: true,
                })
            );
        }

        canvas.forEachObject((object) => {
            if (object.haccp_type === "process") {
                object.bringToFront();
            }
        });
    }

    function removeGrid() {
        canvas.forEachObject((object) => {
            if (object.haccp_type === "gridLine") {
                canvas.remove(object);
            }
        });
    }

    function updateFont(processGroup, newText) {
        let text = processGroup._objects[1];
        text.set({ text: newText, fontSize: 11 });

        while (text.textLines.length > 5) {
            text.set({ fontSize: text.fontSize - 1 });
        }
    }

    function getDiagramProcesses() {
        let processList = [];
        let objects = canvas.getObjects();

        for (let i = 0, len = canvas.size(); i < len; i++) {
            if (objects[i].haccp_type && objects[i].haccp_type === "process") {
                processList.push(objects[i]);
            }
        }

        return processList;
    }

    function addText() {
        let text = new fabric.IText("Enter text", {
            editable: true,
            selectable: true,
            fontFamily: "arial sans-serif",
            fontSize: 14,
            left: 100,
            top: 100,
            id: Math.random(),
            haccp_type: "text",
        });

        canvas.add(text);

        props.dispatch({
            type: "addAction",
            event: "CreatedText",
            value: { text },
        });
        setIsBlocking(true);
        if (props.setIsBlocking) {
            props.setIsBlocking(true);
        }

        addTextListeners();
        setUndoDisabled(false);
        //setRedoDisabled(false);
    }

    function selectModeMouseDown(event) {
        if (event.target == null) {
            setProcessMousedOver(null);
        }
        if (event.target && event.target.haccp_type === "text") {
            activeObject = event.target;
            let processSelected = activeObject.id;
            setProcessSelected(processSelected);
            let textMovedPos = { left: event.target.left, top: event.target.top };
            let text = event.target;
            props.dispatch({
                type: "addAction",
                event: "MovedText",
                value: { text, textMovedPos },
            });
            setUndoDisabled(false);
        }

        if (event.target && event.target.haccp_type !== "text") {
            activeObject = event.target;

            if (event.target.haccp_type === "process") {
                let processSelected = activeObject.id;
                setProcessSelected(processSelected);
                let procMovedPos = { left: activeObject.left, top: activeObject.top };
                let proc = activeObject;
                props.dispatch({
                    type: "addAction",
                    event: "MovedProcess",
                    value: { proc, procMovedPos },
                });

                setIsBlocking(true);
                if (props.setIsBlocking) {
                    props.setIsBlocking(true);
                }

                setUndoDisabled(false);
                setCrossContaminationPointSelected(null);
            }

            if (event.target.haccp_type === "line") {
                let lineMovedPos = {
                    left: event.target.left,
                    top: event.target.top,
                    x1: event.target.x1,
                    x2: event.target.x2,
                    y1: event.target.y1,
                    y2: event.target.y2,
                };

                let line = event.target;
                props.dispatch({
                    type: "addAction",
                    event: "MovedLine",
                    value: { line, lineMovedPos },
                });

                setIsBlocking(true);
                if (props.setIsBlocking) {
                    props.setIsBlocking(true);
                }

                setUndoDisabled(false);
            }

            if (event.target.haccp_type === "lineGroup") {
                let lgMovedPos = { left: event.target.left, top: event.target.top };
                let lineGroup = event.target;
                props.dispatch({
                    type: "addAction",
                    event: "MovedLineGroup",
                    value: { lineGroup, lgMovedPos },
                });
                setIsBlocking(true);
                if (props.setIsBlocking) {
                    props.setIsBlocking(true);
                }
                setUndoDisabled(false);
            }

            if (event.target.haccp_type === "cross_contamination_point") {
                let crossContaminationPointSelected = activeObject.pk;
                let ccpMovedPos = { left: activeObject.left, top: activeObject.top };
                let ccp = activeObject;
                props.dispatch({
                    type: "addAction",
                    event: "MovedCCP",
                    value: { ccp, ccpMovedPos },
                });
                setIsBlocking(true);
                if (props.setIsBlocking) {
                    props.setIsBlocking(true);
                }
                setUndoDisabled(false);
                setCrossContaminationPointSelected(crossContaminationPointSelected);
                setProcessSelected(null);
            }

            if (event.target.haccp_type === "room") {
                let roomObject = activeObject;
                let roomOrigin = { left: roomObject.left, top: roomObject.top };
                props.dispatch({
                    type: "addAction",
                    event: "MovedRoom",
                    value: { roomObject, roomOrigin },
                });
                setIsBlocking(true);
                if (props.setIsBlocking) {
                    props.setIsBlocking(true);
                }
                setUndoDisabled(false);
            }
        } else {
            activeObject = null;
            setProcessSelected(null);
            setCrossContaminationPointSelected(null);
        }
    }

    function selectModeMouseUp(event) {
        if (event.target != null) {
            activeObject = event.target;
        } else {
            activeObject = null;
        }
    }

    function lineModeMouseDown(event) {
        if (event.target == null) {
            setProcessMousedOver(null);
        }

        let point, points;

        isDown = true;

        if (activeLine) {
            if (!secondX && !secondY) {
                point = canvas.getPointer(event.e);
                secondX = point.x;
                secondY = point.y;
                perpendicularize = shiftKeyDown;
            }

            let lines = getDrawnLines();
            lines.forEach((line) => {
                if (line.haccp_type == "wall") {
                    return;
                }
                drawIntersectionPoints(activeLine, line);
            });

            previousPath = drawLinePath(activeLine);
            previousPaths.push(previousPath);

            previousLine = activeLine;

            canvas.add(previousPath);
            canvas.remove(activeLine);
        } else {
            point = canvas.getPointer(event.e);
            startX = point.x;
            startY = point.y;
            secondX = null;
            secondY = null;
            perpendicularize = false;
            //These are need as when the canvas rerenders line points are normalized
            //To have x1 always on the left, so the double ended arrow can't be placed
            //using those line coords.
        }

        if (shiftKeyDown && activeLine) {
            points = [activeLine.x2, activeLine.y2, activeLine.x2, activeLine.y2];
        } else {
            point = canvas.getPointer(event.e);
            points = [point.x, point.y, point.x, point.y];
        }

        if (event.button === 3) {
            // right click, cap line
            if (activeLine) {
                let dx, dy, theta;

                dx = activeLine.x1 - activeLine.x2;
                dy = activeLine.y1 - activeLine.y2;

                theta = Math.atan2(dx, dy);
                theta *= -180 / Math.PI;

                if (theta < 0) {
                    theta += 360;
                }

                let endTriangle = new fabric.Triangle({
                    left: activeLine.x2,
                    top: activeLine.y2,
                    originX: "center",
                    originY: "center",
                    stroke: color.hex,
                    fill: color.hex,
                    width: 10,
                    height: 10,
                    angle: theta,
                    selectable: false,
                });

                canvas.remove(previousPaths[previousPaths.length - 1]);

                let arrow1 = [previousPaths[previousPaths.length - 1], endTriangle];

                if (lineVariant.doubleEnded) {
                    let line, dx, dy, theta;

                    line = previousPaths[0];

                    canvas.remove(previousPaths[0]);

                    dx = secondX - startX;
                    dy = secondY - startY;

                    theta = Math.atan2(dx, dy);
                    theta *= -180 / Math.PI;

                    if (theta < 0) {
                        theta += 360;
                    }

                    if (perpendicularize) {
                        //Round to a right angle
                        theta = Math.round(theta / 90) * 90;
                    }

                    if (previousPaths.length > 1) {
                        let startTriangle = new fabric.Triangle({
                            left: startX,
                            top: startY,
                            originX: "center",
                            originY: "center",
                            stroke: color.hex,
                            fill: color.hex,
                            width: 10,
                            height: 10,
                            angle: theta,
                            selectable: false,
                        });

                        let arrow2 = [line, startTriangle];

                        let arrowGroup2 = new fabric.Group(arrow2);
                        arrowGroup2.set({
                            haccp_type: "lineGroup",
                            selectable: false,
                            perPixelTargetFind: true,
                            lockScalingFlip: true,
                        });

                        canvas.add(arrowGroup2);
                    } else {
                        let startTriangle = new fabric.Triangle({
                            left: startX,
                            top: startY,
                            originX: "center",
                            originY: "center",
                            stroke: color.hex,
                            fill: color.hex,
                            width: 10,
                            height: 10,
                            angle: theta,
                            selectable: false,
                        });

                        arrow1.push(startTriangle);
                    }
                }

                let arrowGroup1 = new fabric.Group(arrow1);
                arrowGroup1.set({
                    haccp_type: "lineGroup",
                    selectable: false,
                    perPixelTargetFind: true,
                    lockScalingFlip: true,
                });

                canvas.add(arrowGroup1);

                props.dispatch({
                    type: "addAction",
                    event: "CreatedArrowGroup",
                    value: { arrowGroup1 },
                });
                setIsBlocking(true);
                if (props.setIsBlocking) {
                    props.setIsBlocking(true);
                }
                setUndoDisabled(false);

                canvas.remove(activeLine);
                activeLine = null;

                previousLine = null;
                previousPath = null;
                previousPaths = [];

                canvas.requestRenderAll();
                return;
            }
        }

        activeLine = new fabric.Line(points, {
            strokeWidth: 1,
            strokeDashArray: lineVariant.strokeDashArray,
            fill: color.hex,
            stroke: color.hex,
            originX: "center",
            originY: "center",
            selectable: false,
            perPixelTargetFind: true,
        });

        intersectionPoints = [...tempIntersectionPoints];
        tempIntersectionPoints = [];

        canvas.add(activeLine);
        canvas.requestRenderAll();
    }

    function wallModeMouseMove(event) {
        if (!activeLine) {
            return;
        }

        let point = canvas.getPointer(event.e);

        if (shiftKeyDown) {
            let dx, dy, theta, roundedTheta;

            dx = point.x - activeLine.x1;
            dy = point.y - activeLine.y1;

            theta = Math.atan2(dy, dx);

            theta *= 180 / Math.PI;

            roundedTheta = Math.round(theta / 90) * 90;

            if (roundedTheta === -0 || roundedTheta === 0) {
                activeLine.set({ x2: point.x, y2: activeLine.y1 });
            }

            if (roundedTheta === -90) {
                activeLine.set({ x2: activeLine.x1, y2: point.y });
            }

            if (roundedTheta === 90) {
                activeLine.set({ x2: activeLine.x1, y2: point.y });
            }

            if (roundedTheta === 180 || roundedTheta === -180) {
                activeLine.set({ x2: point.x, y2: activeLine.y1 });
            }

            activeLine.setCoords();
            canvas.requestRenderAll();

            return;
        }

        var endStartDistance = euclideanDistance([
            [startX, point.x],
            [startY, point.y],
        ]);

        //If they click close to the starting point, assume they're trying to make a closed room.
        if (previousPaths.length >= 2 && endStartDistance <= 25) {
            activeLine.set({ x2: startX, y2: startY });
        } else {
            activeLine.set({ x2: point.x, y2: point.y });
        }
        activeLine.setCoords();

        canvas.requestRenderAll();
    }

    function wallModeMouseDown(event) {
        if (event.target == null) {
            setProcessMousedOver(null);
        }

        let point, points;

        isDown = true;

        if (activeLine) {
            let lines = getDrawnLines();

            previousPath = drawLinePath(activeLine);
            previousPaths.push(previousPath);

            previousLine = activeLine;

            canvas.add(previousPath);
            canvas.remove(activeLine);
        } else {
            point = canvas.getPointer(event.e);
            startX = point.x;
            startY = point.y;
            secondX = null;
            secondY = null;
            perpendicularize = false;
            //These are need as when the canvas rerenders line points are normalized
            //To have x1 always on the left, so the double ended arrow can't be placed
            //using those line coords.
        }

        point = canvas.getPointer(event.e);
        if (shiftKeyDown && activeLine) {
            points = [activeLine.x2, activeLine.y2, activeLine.x2, activeLine.y2];
        } else {
            points = [point.x, point.y, point.x, point.y];
        }

        if (event.button === 3) {
            //todo group lines into a "wall" group
            var endStartDistance = euclideanDistance([
                [startX, point.x],
                [startY, point.y],
            ]);

            //If they click close to the starting point, assume they're trying to make a closed room.
            //This is here to have the canvas treat it as though they clicked on the starting point.
            if (endStartDistance <= 25) {
                points = [startX, startY, startX, startY];
            }

            if (activeLine) {
                canvas.remove(previousPaths[previousPaths.length - 1]);

                previousPaths.forEach((path) => {
                    path.haccp_type = "wall";
                    canvas.remove(path);
                });

                let roomGroup = new fabric.Group(previousPaths);
                roomGroup.set({
                    haccp_type: "room",
                    selectable: false,
                    perPixelTargetFind: true,
                    lockScalingFlip: true,
                    hoverCursor: "default",
                });

                canvas.add(roomGroup);

                props.dispatch({
                    type: "addAction",
                    event: "CreatedRoomGroup",
                    value: { roomGroup },
                });
                setIsBlocking(true);
                if (props.setIsBlocking) {
                    props.setIsBlocking(true);
                }
                setUndoDisabled(false);

                canvas.remove(activeLine);
                activeLine = null;

                previousLine = null;
                previousPath = null;
                previousPaths = [];

                canvas.requestRenderAll();
                return;
            }
        }

        var endStartDistance = euclideanDistance([
            [startX, point.x],
            [startY, point.y],
        ]);
        if (endStartDistance <= 25) {
            points = [startX, startY, startX, startY];
        }

        activeLine = new fabric.Line(points, {
            strokeWidth: 2,
            strokeDashArray: lineVariant.strokeDashArray,
            fill: color.hex,
            stroke: color.hex,
            originX: "center",
            originY: "center",
            selectable: false,
            perPixelTargetFind: true,
            hoverCursor: "default",
        });

        intersectionPoints = [...tempIntersectionPoints];
        tempIntersectionPoints = [];

        canvas.add(activeLine);
        canvas.requestRenderAll();
    }

    function wallModeMouseUp(event) {
        isDown = false;
        canvas.remove(activeLine);

        let points = [activeLine.x1, activeLine.y1, activeLine.x2, activeLine.y2];

        activeLine = new fabric.Line(points, {
            strokeWidth: 2,
            strokeDashArray: lineVariant.strokeDashArray,
            fill: color.hex,
            stroke: color.hex,
            originX: "center",
            originY: "center",
            selectable: false,
            perPixelTargetFind: true,
        });

        let dx, dy, theta;

        dx = activeLine.x1 - activeLine.x2;
        dy = activeLine.y1 - activeLine.y2;

        theta = Math.atan2(dx, dy);
        theta *= -180 / Math.PI;

        if (theta < 0) {
            theta += 360;
        }

        //todo grouping here maybe
        let arrow = [activeLine];
        let arrowGroup = new fabric.Group(arrow);
        arrowGroup.set("selectable", false);

        canvas.add(arrowGroup);
    }

    /**
     * Takes an array of n pairs of points, and returns the distance between the
     * points they represent
     *
     * @param {*} pointPairs - Array of pairs of points in the same dimension in an array.
     * e.g 2d takes [[x1,x2],[y1,y2]]
     *    3d takes [[x1,x2],[y1,y2],[z1,z2]]
     *    etc...
     */
    function euclideanDistance(pointPairs) {
        let sumOfSquares = 0;

        pointPairs.forEach((pair) => {
            sumOfSquares += Math.pow(pair[0] - pair[1], 2);
        });

        return Math.sqrt(sumOfSquares);
    }

    function getDrawnLines() {
        let lines = [];

        canvas.getObjects().forEach((object) => {
            if (object.type === "path") {
                lines.push(object);
            }

            if (object.type === "group") {
                object._objects.forEach((object) => {
                    if (object.haccp_type === "line") {
                        lines.push(object);
                    }

                    if (object.haccp_type === "lineGroup") {
                        lines.push(object._objects[0]);
                    }

                    if (object.haccp_type === "wall") {
                        lines.push(object /*._objects[0]*/);
                    }
                });
            }
        });

        return lines;
    }

    function sortIntersectionPoints(tempIntersectionPoints, dx) {
        if (dx < 0) {
            tempIntersectionPoints.sort((a, b) => {
                return b.x - a.x;
            });
        } else {
            tempIntersectionPoints.sort((a, b) => {
                return a.x - b.x;
            });
        }
    }

    // https://math.stackexchange.com/questions/175896/finding-a-point-along-a-line-a-certain-distance-away-from-another-point
    function drawLinePath(completedLine) {
        let dx = completedLine.x2 - completedLine.x1,
            dy;
        let x1, y1, x2, y2;

        // inverted due to path arcs being drawn upside down, not sure why
        if (dx > 0) {
            x2 = completedLine.x1;
            x1 = completedLine.x2;
            y2 = completedLine.y1;
            y1 = completedLine.y2;
        } else {
            x1 = completedLine.x1;
            x2 = completedLine.x2;
            y1 = completedLine.y1;
            y2 = completedLine.y2;
        }

        dy = y2 - y1;
        dx = x2 - x1;

        // length of vector, pythagorean's theorem
        let v = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));

        // normalized vector
        let u = { x: dx / v, y: dy / v };

        // distance (+,-) from intersection point to use for arc drawing (width of arc)
        let d = 5;

        let start = `M ${x1} ${y1}`;
        let end = `L ${x2} ${y2}`;

        // arc path settings
        let arcXRadius = 1;
        let arcYRadius = 1;
        let xAxisRotation = 0;
        let largeArcFlag = 0;
        let sweepFlag = 0;

        let arcs = [];

        if (tempIntersectionPoints.length) {
            sortIntersectionPoints(tempIntersectionPoints, dx);

            for (let i = 0; i < tempIntersectionPoints.length; i++) {
                let intersectionPoint = tempIntersectionPoints[i];

                let startX = Math.round(intersectionPoint.x - d * u.x);
                let startY = Math.round(intersectionPoint.y - d * u.y);

                let endX = Math.round(intersectionPoint.x + d * u.x);
                let endY = Math.round(intersectionPoint.y + d * u.y);

                let arc = ` L ${startX} ${startY} A ${arcXRadius} ${arcYRadius} ${xAxisRotation} ${largeArcFlag} ${sweepFlag} ${endX} ${endY} `;

                arcs.push(arc);
            }
        }

        let pathString = "".concat(start, ...arcs, end);
        let path = new fabric.Path(pathString, {
            haccp_type: canvasMode == "line" ? "line" : "wall",
            x1: x1,
            x2: x2,
            y1: y1,
            y2: y2,
            hoverCursor: "default",
            strokeWidth: canvasMode == "wall" ? 2 : 1,
            strokeDashArray: lineVariant.strokeDashArray,
            fill: "transparent",
            stroke: color.hex,
            originX: "center",
            originY: "center",
            selectable: false,
            perPixelTargetFind: true,
            // lockRotation: true,
            lockScalingFlip: true,
            // lockScalingX: true,
            // lockScalingY: true
        });
        if (canvasMode == "line") {
            props.dispatch({
                type: "addAction",
                event: "CreatedPath",
                value: { path },
            });
        }
        setIsBlocking(true);
        if (props.setIsBlocking) {
            props.setIsBlocking(true);
        }
        setUndoDisabled(false);
        //setRedoDisabled(false);
        return path;
    }

    function drawIntersectionPoints(line1, line2) {
        let intersectionPoint = calculateLineIntersection(line1, line2);
        if (intersectionPoint) {
            tempIntersectionPoints.push(intersectionPoint);
        }
    }

    function lineModeMouseMove(event) {
        if (!activeLine) {
            return;
        }

        let point = canvas.getPointer(event.e);

        if (shiftKeyDown) {
            let dx, dy, theta, roundedTheta;

            dx = point.x - activeLine.x1;
            dy = point.y - activeLine.y1;

            theta = Math.atan2(dy, dx);

            theta *= 180 / Math.PI;

            roundedTheta = Math.round(theta / 90) * 90;

            if (roundedTheta === -0 || roundedTheta === 0) {
                activeLine.set({ x2: point.x, y2: activeLine.y1 });
            }

            if (roundedTheta === -90) {
                activeLine.set({ x2: activeLine.x1, y2: point.y });
            }

            if (roundedTheta === 90) {
                activeLine.set({ x2: activeLine.x1, y2: point.y });
            }

            if (roundedTheta === 180 || roundedTheta === -180) {
                activeLine.set({ x2: point.x, y2: activeLine.y1 });
            }

            activeLine.setCoords();
            canvas.requestRenderAll();

            return;
        }

        activeLine.set({ x2: point.x, y2: point.y });
        activeLine.setCoords();

        canvas.requestRenderAll();
    }

    function lineModeMouseUp(event) {
        isDown = false;
        canvas.remove(activeLine);

        let points = [activeLine.x1, activeLine.y1, activeLine.x2, activeLine.y2];

        activeLine = new fabric.Line(points, {
            strokeWidth: 2,
            strokeDashArray: lineVariant.strokeDashArray,
            fill: color.hex,
            stroke: color.hex,
            originX: "center",
            originY: "center",
            selectable: false,
            perPixelTargetFind: true,
        });

        let dx, dy, theta;

        dx = activeLine.x1 - activeLine.x2;
        dy = activeLine.y1 - activeLine.y2;

        theta = Math.atan2(dx, dy);
        theta *= -180 / Math.PI;

        if (theta < 0) {
            theta += 360;
        }

        let triangle = new fabric.Triangle({
            left: activeLine.x2,
            top: activeLine.y2,
            originX: "center",
            originY: "center",
            stroke: "black",
            width: 10,
            height: 10,
            angle: theta,
            selectable: false,
        });

        let arrow = [activeLine, triangle];
        let arrowGroup = new fabric.Group(arrow);
        arrowGroup.set("selectable", false);

        canvas.add(arrowGroup);
    }

    function undoSelectedProcess() {
        return;
    }

    function handleUndoDialogClose() {
        setUndoDialogOpen(false);
    }

    function deleteLine() {
        if (!canvas.getActiveObjects()) {
            return;
        }

        let objs = canvas.getActiveObjects();

        if (objs.length) {
            setIsBlocking(true);
            if (props.setIsBlocking) {
                props.setIsBlocking(true);
            }
        }

        objs.forEach((object) => {
            if (object.haccp_type === "process") {
                return;
            }
            if (object._objects && object._objects.length) {
                canvas.remove(...object._objects);
            }
            canvas.remove(object);
        });

        canvas.discardActiveObject();
        canvas.requestRenderAll();
    }

    function gridModeChange(event) {
        setGridEnabledReact(!gridEnabledReact);
    }

    function lineVariantChange(event) {
        let variantName = event.target.value;

        let lineVariantIndex = lineStrokeOptions.findIndex((option) => {
            return option.name === variantName;
        });

        if (lineVariantIndex > -1) {
            lineVariant = lineStrokeOptions[lineVariantIndex];
            setReactLineVariant(lineVariant.name);
        }
    }

    const [processMousedOver, setProcessMousedOver] = useState(null);

    function objectHoveredIn(event) {}

    let styles = {
        color: {
            width: "36px",
            height: "14px",
            borderRadius: "2px",
            background: `rgba(${reactColor.rgb.r}, ${reactColor.rgb.g}, ${reactColor.rgb.b}, ${reactColor.rgb.a})`,
        },
        swatch: {
            padding: "5px",
            background: "#fff",
            borderRadius: "1px",
            boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
            display: "inline-block",
            cursor: "pointer",
        },
        popover: {
            position: "absolute",
            zIndex: "2",
        },
        cover: {
            position: "fixed",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
        },
    };

    function handleClick(event) {
        setDisplayColorPicker(!displayColorPicker);
    }

    function handleMessageClose(event) {
        setMessageOpen(false);
    }

    function handleColorClose(event) {
        setDisplayColorPicker(false);
    }

    function handleChange(newColor) {
        color = { hex: newColor.hex, rgb: newColor.rgb };
        setReactColor(color);
    }

    function getTemplates() {
        return new API().getProcessFlowDiagramTemplateAPI().listProcessFlowDiagramTemplates();
    }

    window.onbeforeunload = function (e) {
        if (isBlocking) {
            e.preventDefault();
            e.returnValue = "";
        }
    };

    function addProcessListeners() {
        canvas.forEachObject((obj) => {
            if (obj.haccp_type === "process") {
                let process = obj;

                process.on("moved", processMovedListener);

                process.on("mousedblclick", (event) => {
                    processDoubleClickedListener(event);
                });

                process.on("mousedown", (event) => {
                    processClickedListener(event);
                });

                process.bringToFront();
            }
        });
    }
    function addTextListeners() {
        canvas.forEachObject((obj) => {
            if (obj.haccp_type === "text") {
                let process = obj;

                process.on("mousedblclick", (event) => {
                    textDoubleClickedListener(event);
                });

                // process.on("mousedown", (event) => {
                //   processClickedListener(event);
                // });

                process.bringToFront();
            }
        });
    }

    function addCrossContaminationPoint() {
        new API()
            .getCrossContaminationPointAPI()
            .createCrossContaminationPoint({
                haccp_plan: props.haccpPlan.pk,
                organization: flowDiagram.organization,
                name: "",
                description: "",
            })
            .then((e) => {
                let cross_contamination_point;
                props.setFlowDiagram((prevState) => {
                    const diagram = { ...prevState };
                    diagram.cross_contamination_points.push(e.data.pk);
                    return diagram;
                });

                cross_contamination_point = new fabric.Rect({
                    pk: e.data.pk,
                    haccp_type: "cross_contamination_point",
                    width: 10,
                    height: 10,
                    fill: "red",
                    opacity: 1.0,
                    left: 100,
                    top: 100,
                    hoverCursor: canvasMode === "select" ? "grab" : "default",
                    selectable: canvasMode === "select",
                    hasControls: false,
                });

                cross_contamination_point.on("mousedblclick", crossContaminationPointDoubleClickedListener);
                canvas.add(cross_contamination_point);

                setIsBlocking(true);
                if (props.setIsBlocking) {
                    props.setIsBlocking(true);
                }
                //not able to undo and delete ccp
                //setUndoDisabled(false);
                //setRedoDisabled(false);
                //props.dispatch({ type: "addAction", event: "CreatedCCP", value: { cross_contamination_point } });

                setProcessSelected(null);
                setCrossContaminationPointSelected(cross_contamination_point.pk);
                setCrossContaminationModalOpen(true);

                canvas.requestRenderAll();
                saveData(canvas, props.haccpPlan, "TRAFFIC_FLOW", flowDiagram);
            })
            .catch((e) => {
                setStatus({
                    message: "Could not add a cross contamination point.",
                    severity: "error",
                });
            });
    }

    useEffect(() => {
        if (!crossContaminationModalOpen) {
            canvas.requestRenderAll();
        }
    }, [crossContaminationModalOpen]);

    function setCCPText(process_pk, name) {
        let objs = canvas.getActiveObjects();

        objs.forEach((object) => {
            if (object.pk === process_pk) {
                let processText = object._objects[1];
                processText.set("text", name);
            }
        });

        canvas.requestRenderAll();

        saveData(canvas, props.haccpPlan, props.processFlow ? "PROCESS_FLOW" : "TRAFFIC_FLOW", flowDiagram);
    }

    function handleCrossContaminationModalClosed() {
        if (!shouldClose) {
            setCrossContaminationModalOpen(false);
            canvas.requestRenderAll();
        } else {
            alert("Save changes before closing.");
        }
    }

    function handleDetailsModalClosed(check) {
        if (!shouldClose) {
            setDetailsModalOpen(false);
        } else {
            alert("Save changes before closing.");
        }
    }

    function openProcess() {
        setProcessModalOpen(true);
    }

    function setReturnStatus(message, status) {
        setStatus({ message: message, severity: status });
        setMessageOpen(true);
    }

    function descriptionSetflowDiagram(newDiagram) {
        flowDiagram = { ...newDiagram };
        props.setFlowDiagram(newDiagram);
    }

    function handleFileChangeFromServer(url, filename, pk) {
        setImageManagerOpen(false);
        setReturnStatus("Loading Image ...", "info");

        let flowDiagramPk = flowDiagram.pk;
        new API()
            .getImagesAPI()
            .addImageToTrafficFlowDiagram(pk, {
                pk: pk,
                traffic_flow_pk: flowDiagramPk,
            })
            .then((e) => {
                setReturnStatus("Image Choice Saved.", "success");
                addBackgroundImage(canvas, { image: url }, "TRAFFIC_FLOW");
                props.setFlowDiagram({ ...flowDiagram, image: url });
            })
            .catch((e) => {
                setReturnStatus("Error: " + e, "error");
            });
    }

    function useUploadedImage(file) {
        setReturnStatus("Adding Image to Traffic Flow Diagram", "info");
        let newImageUrl = URL.createObjectURL(file);

        let formData = new FormData();
        formData.append("image", file);

        new API()
            .getTrafficFlowDiagramAPI()
            .updateTrafficFlowDiagramImage(flowDiagram.pk, formData)
            .then((e) => {
                setReturnStatus("Image Choice Saved.", "success");
                addBackgroundImage(canvas, { image: newImageUrl }, "TRAFFIC_FLOW");
                props.setFlowDiagram({ ...flowDiagram, image: newImageUrl });
            })
            .catch((e) => {
                setStatus({
                    message: "Could set image to traffic flow diagram on server.",
                    severity: "error",
                });
            });

        setImageManagerOpen(false);
    }

    //todo add elses/maybe switch and a try/catch with an alert to the user
    function undoChange(event) {
        if (props.present != null && props.past.length > 0) {
            let lineToDelete = props.present;
            let allObjects = canvas.getObjects();

            let curX;
            let curY;
            let prevX;
            let prevY;

            switch (lineToDelete.event) {
                case "CreatedCCP":
                    new API()
                        .getCrossContaminationPointAPI()
                        .deleteCrossContaminationPoint(lineToDelete.present.cross_contamination_point.pk)
                        .then((response) => {
                            canvas.remove(lineToDelete.present.cross_contamination_point);

                            let updatedFlowDiagram = { ...flowDiagram };

                            let index = updatedFlowDiagram.cross_contamination_points.findIndex((ccpPk) => {
                                return ccpPk === lineToDelete.present.cross_contamination_point.pk;
                            });

                            if (index > -1) {
                                updatedFlowDiagram.cross_contamination_points.splice(index, 1);
                                saveData(
                                    canvas,
                                    props.haccpPlan,
                                    props.processFlow ? "PROCESS_FLOW" : "TRAFFIC_FLOW",
                                    flowDiagram
                                );
                            }
                        });
                    break;

                case "CreatedArrowGroup":
                    if (lineToDelete.present.arrowGroup1) {
                        canvas.setActiveObject(lineToDelete.present.arrowGroup1);
                        canvas.discardActiveObject();
                        canvas.remove(lineToDelete.present.arrowGroup1);
                        canvas.requestRenderAll();
                        props.dispatch({ type: "undo" });
                    }
                    break;

                case "CreatedPath":
                    canvas.setActiveObject(lineToDelete.present.path);
                    canvas.discardActiveObject();
                    canvas.remove(lineToDelete.present.path);
                    canvas.requestRenderAll();

                    break;

                case "CreatedText":
                    canvas.setActiveObject(lineToDelete.present.text);
                    canvas.discardActiveObject();
                    canvas.remove(lineToDelete.present.text);
                    canvas.requestRenderAll();
                    break;

                case "MovedText":
                    if (typeof lineToDelete.present.textMovedPos.left !== "undefined") {
                        curX = lineToDelete.present.text.left;
                        curY = lineToDelete.present.text.top;
                        prevX = lineToDelete.present.textMovedPos.left;
                        prevY = lineToDelete.present.textMovedPos.top;

                        canvas.setActiveObject(lineToDelete.present.text);
                        canvas.discardActiveObject();
                        canvas.remove(lineToDelete.present.text);

                        lineToDelete.present.textMovedPos.left = curX;
                        lineToDelete.present.textMovedPos.top = curY;
                        lineToDelete.present.text.left = prevX;
                        lineToDelete.present.text.top = prevY;
                        canvas.add(lineToDelete.present.text);
                        canvas.requestRenderAll();
                    }
                    break;

                case "MovedLine":
                    allObjects.forEach((object) => {
                        if (object.haccp_type !== "process") {
                            if (object.left === lineToDelete.present.line.left) {
                                canvas.remove(object);
                            }
                        }
                    });
                    canvas.remove(lineToDelete.present.line);

                    let points = [
                        lineToDelete.present.lineMovedPos.x1,
                        lineToDelete.present.lineMovedPos.y1,
                        lineToDelete.present.lineMovedPos.x2,
                        lineToDelete.present.lineMovedPos.y2,
                    ];

                    let start = `M ${lineToDelete.present.lineMovedPos.x1} ${lineToDelete.present.lineMovedPos.y1}`;
                    let end = `L ${lineToDelete.present.lineMovedPos.x2} ${lineToDelete.present.lineMovedPos.y2}`;

                    let activeLine = new fabric.Path("" + start + end, {
                        strokeWidth: 1,
                        strokeDashArray: lineToDelete.present.line.strokeDashArray,
                        fill: lineToDelete.present.line.fill,
                        stroke: lineToDelete.present.line.stroke,
                        originX: "center",
                        originY: "center",
                        selectable: canvasMode == "select",
                        perPixelTargetFind: true,
                        haccp_type: "line",
                        hoverCursor: canvasMode == "select" ? "grab" : null,
                        x1: lineToDelete.present.lineMovedPos.x1,
                        x2: lineToDelete.present.lineMovedPos.x2,
                        y1: lineToDelete.present.lineMovedPos.y1,
                        y2: lineToDelete.present.lineMovedPos.y2,
                    });

                    intersectionPoints = [...tempIntersectionPoints];
                    tempIntersectionPoints = [];

                    canvas.add(activeLine);

                    curX = lineToDelete.present.line.left;
                    curY = lineToDelete.present.line.top;
                    prevX = lineToDelete.present.lineMovedPos.left;
                    prevY = lineToDelete.present.lineMovedPos.top;

                    canvas.setActiveObject(lineToDelete.present.line);
                    canvas.discardActiveObject();
                    canvas.remove(lineToDelete.present.line);

                    lineToDelete.present.lineMovedPos.left = curX;
                    lineToDelete.present.lineMovedPos.top = curY;
                    lineToDelete.present.line.left = prevX;
                    lineToDelete.present.line.top = prevY;

                    canvas.requestRenderAll();
                    break;

                case "MovedLineGroup":
                    allObjects.forEach((object) => {
                        if (object.haccp_type !== "process") {
                            if (
                                object.left === lineToDelete.present.lgMovedPos.left ||
                                object.left === lineToDelete.present.lineGroup.left
                            ) {
                                canvas.remove(object);
                            }
                        }
                    });
                    canvas.remove(...lineToDelete.present.lineGroup._objects);
                    canvas.remove(lineToDelete.present.lineGroup);

                    let newGroup = new fabric.Group([...lineToDelete.present.lineGroup._objects], {
                        haccp_type: "lineGroup",
                        perPixelTargetFind: true,
                        top: lineToDelete.present.lgMovedPos.top,
                        left: lineToDelete.present.lgMovedPos.left,
                    });

                    canvas.add(newGroup);

                    curX = lineToDelete.present.lineGroup.left;
                    curY = lineToDelete.present.lineGroup.top;
                    prevX = lineToDelete.present.lgMovedPos.left;
                    prevY = lineToDelete.present.lgMovedPos.top;

                    canvas.setActiveObject(lineToDelete.present.lineGroup);
                    canvas.discardActiveObject();
                    canvas.remove(lineToDelete.present.lineGroup);
                    canvas.requestRenderAll();

                    lineToDelete.present.lgMovedPos.left = curX;
                    lineToDelete.present.lgMovedPos.top = curY;
                    lineToDelete.present.lineGroup.left = prevX;
                    lineToDelete.present.lineGroup.top = prevY;
                    canvas.add(lineToDelete.present.lineGroup);
                    updateArrowCoordinates(lineToDelete.present.lineGroup);
                    canvas.requestRenderAll();
                    break;

                case "MovedCCP":
                    curX = lineToDelete.present.ccp.left;
                    curY = lineToDelete.present.ccp.top;
                    prevX = lineToDelete.present.ccpMovedPos.left;
                    prevY = lineToDelete.present.ccpMovedPos.top;
                    canvas.setActiveObject(lineToDelete.present.ccp);
                    canvas.discardActiveObject();
                    canvas.remove(lineToDelete.present.ccp);
                    canvas.requestRenderAll();
                    lineToDelete.present.ccpMovedPos.left = curX;
                    lineToDelete.present.ccpMovedPos.top = curY;
                    lineToDelete.present.ccp.left = prevX;
                    lineToDelete.present.ccp.top = prevY;
                    canvas.add(lineToDelete.present.ccp);
                    canvas.requestRenderAll();

                    //props.dispatch({ type: "undo" });

                    break;

                case "MovedProcess":
                    curX = lineToDelete.present.proc.left;
                    curY = lineToDelete.present.proc.top;
                    prevX = lineToDelete.present.procMovedPos.left;
                    prevY = lineToDelete.present.procMovedPos.top;

                    canvas.setActiveObject(lineToDelete.present.proc);
                    canvas.discardActiveObject();
                    canvas.remove(lineToDelete.present.proc);
                    canvas.requestRenderAll();

                    lineToDelete.present.procMovedPos.left = curX;
                    lineToDelete.present.procMovedPos.top = curY;
                    lineToDelete.present.proc.left = prevX;
                    lineToDelete.present.proc.top = prevY;

                    canvas.add(lineToDelete.present.proc);
                    canvas.requestRenderAll();
                    break;

                case "CreatedRoomGroup":
                    canvas.remove(lineToDelete.present.roomGroup);
                    canvas.requestRenderAll();
                    break;

                case "MovedRoom":
                    let roomObject = lineToDelete.present.roomObject;
                    let newOriginLeft = roomObject.left;
                    let newOriginTop = roomObject.top;

                    roomObject.left = lineToDelete.present.roomOrigin.left;
                    roomObject.top = lineToDelete.present.roomOrigin.top;

                    lineToDelete.present.roomOrigin.left = newOriginLeft;
                    lineToDelete.present.roomOrigin.top = newOriginTop;

                    canvas.requestRenderAll();
                    break;

                default:
                    console.log("Unrecognized event in Undo: " + lineToDelete.event);
            }
        }
        setIsBlocking(true);
        if (props.setIsBlocking) {
            props.setIsBlocking(true);
        }

        if (props.past.length <= 1) {
            setUndoDisabled(true);
        }
        setRedoDisabled(false);
        props.dispatch({ type: "undo" });
    }

    //todo changing to switch
    function redoChange() {
        if (props.future.length > 0) {
            let curX;
            let curY;
            let nextX;
            let nextY;

            let allObjects = canvas.getObjects();

            let lineToAdd = props.future[props.future.length - 1];

            if (lineToAdd && lineToAdd.present && lineToAdd.present.cross_contamination_point) {
                if (lineToAdd.present.cross_contamination_point) {
                    addCrossContaminationPoint();
                }
            }
            if (lineToAdd.present.path != undefined && lineToAdd != undefined) {
                if (lineToAdd.present.path) {
                    canvas.add(lineToAdd.present.path);
                    canvas.requestRenderAll();
                }
            }

            switch (lineToAdd.event) {
                case "CreatedArrowGroup":
                    if (lineToAdd && lineToAdd.present.arrowGroup1) {
                        canvas.add(lineToAdd.present.arrowGroup1);
                        canvas.requestRenderAll();
                    }
                    break;

                case "CreatedText":
                    if (lineToAdd.present.text) {
                        canvas.add(lineToAdd.present.text);
                        canvas.requestRenderAll();
                        props.dispatch({ type: "redo" });
                    }
                    break;

                case "MovedText":
                    if (lineToAdd.present.text && lineToAdd.present.textMovedPos) {
                        curX = lineToAdd.present.text.left;
                        curY = lineToAdd.present.text.top;
                        nextX = lineToAdd.present.textMovedPos.left;
                        nextY = lineToAdd.present.textMovedPos.top;

                        canvas.setActiveObject(lineToAdd.present.text);
                        canvas.discardActiveObject();
                        canvas.remove(lineToAdd.present.text);
                        canvas.requestRenderAll();

                        lineToAdd.present.textMovedPos.left = curX;
                        lineToAdd.present.textMovedPos.top = curY;
                        lineToAdd.present.text.left = nextX;
                        lineToAdd.present.text.top = nextY;
                        canvas.add(lineToAdd.present.text);
                        canvas.requestRenderAll();
                        props.dispatch({ type: "redo" });
                    }
                    break;

                case "CreatedPath":
                    if (props.future[props.future.length - 1].event != "CreatedArrowGroup" && lineToAdd.present.path) {
                        if (props.future[props.future.length - 2].event === "CreatedArrowGroup") {
                            canvas.add(props.future[props.future.length - 2].present.arrowGroup1);
                            canvas.requestRenderAll();
                            if (props.future.length <= 2) {
                                setRedoDisabled(true);
                            }
                            props.dispatch({ type: "redo" });
                            props.dispatch({ type: "redo" });
                        } else {
                            canvas.add(lineToAdd.present.path);
                            canvas.requestRenderAll();
                            props.dispatch({ type: "redo" });
                        }

                        if (props.future.length <= 1) {
                            setRedoDisabled(true);
                        }
                    }
                    break;

                case "MovedLine":
                    allObjects.forEach((object) => {
                        if (object.haccp_type !== "process") {
                            if (
                                object.left === lineToAdd.present.line.left ||
                                object.left === lineToAdd.present.line.left
                            ) {
                                canvas.remove(object);
                            }
                        }
                    });

                    canvas.remove(lineToAdd.present.line);

                    let points = [
                        lineToAdd.present.lineMovedPos.x1,
                        lineToAdd.present.lineMovedPos.y1,
                        lineToAdd.present.lineMovedPos.x2,
                        lineToAdd.present.lineMovedPos.y2,
                    ];

                    curX = lineToAdd.present.line.left;
                    curY = lineToAdd.present.line.top;
                    nextX = lineToAdd.present.lineMovedPos.left;
                    nextY = lineToAdd.present.lineMovedPos.top;

                    let start = `M ${lineToAdd.present.lineMovedPos.x1} ${lineToAdd.present.lineMovedPos.y1}`;
                    let end = `L ${lineToAdd.present.lineMovedPos.x2} ${lineToAdd.present.lineMovedPos.y2}`;

                    let activeLine = new fabric.Path("" + start + end, {
                        strokeWidth: 1,
                        strokeDashArray: lineToAdd.present.line.strokeDashArray,
                        fill: lineToAdd.present.line.fill,
                        stroke: lineToAdd.present.line.stroke,
                        originX: "center",
                        originY: "center",
                        selectable: canvasMode == "select",
                        perPixelTargetFind: true,
                        haccp_type: "line",
                        hoverCursor: canvasMode == "select" ? "grab" : null,
                        x1: lineToAdd.present.lineMovedPos.x1,
                        x2: lineToAdd.present.lineMovedPos.x2,
                        y1: lineToAdd.present.lineMovedPos.y1,
                        y2: lineToAdd.present.lineMovedPos.y2,
                    });

                    canvas.setActiveObject(lineToAdd.present.line);
                    canvas.discardActiveObject();
                    canvas.remove(lineToAdd.present.line);
                    canvas.requestRenderAll();

                    lineToAdd.present.lineMovedPos.left = curX;
                    lineToAdd.present.lineMovedPos.top = curY;
                    lineToAdd.present.line.left = nextX;
                    lineToAdd.present.line.top = nextY;

                    canvas.add(lineToAdd.present.line);
                    canvas.requestRenderAll();
                    props.dispatch({ type: "redo" });
                    if (props.future.length <= 1) {
                        setRedoDisabled(true);
                    }

                    break;

                case "MovedLineGroup":
                    allObjects.forEach((object) => {
                        if (object.haccp_type !== "process") {
                            if (object.left === lineToAdd.present.lineGroup.left) {
                                canvas.remove(object);
                            }
                        }
                    });
                    canvas.remove(...lineToAdd.present.lineGroup._objects);
                    canvas.remove(lineToAdd.present.lineGroup);

                    let newGroup = new fabric.Group([...lineToAdd.present.lineGroup._objects], {
                        haccp_type: "lineGroup",
                        perPixelTargetFind: true,
                        top: lineToAdd.present.lgMovedPos.top,
                        left: lineToAdd.present.lgMovedPos.left,
                    });

                    // canvas.add(newGroup);

                    curX = lineToAdd.present.lineGroup.left;
                    curY = lineToAdd.present.lineGroup.top;
                    nextX = lineToAdd.present.lgMovedPos.left;
                    nextY = lineToAdd.present.lgMovedPos.top;

                    canvas.setActiveObject(lineToAdd.present.lineGroup);
                    canvas.discardActiveObject();
                    canvas.remove(lineToAdd.present.lineGroup);
                    canvas.requestRenderAll();

                    lineToAdd.present.lgMovedPos.left = curX;
                    lineToAdd.present.lgMovedPos.top = curY;
                    lineToAdd.present.lineGroup.left = nextX;
                    lineToAdd.present.lineGroup.top = nextY;

                    canvas.add(lineToAdd.present.lineGroup);
                    updateArrowCoordinates(lineToAdd.present.lineGroup);
                    canvas.requestRenderAll();
                    props.dispatch({ type: "redo" });
                    if (props.future.length <= 1) {
                        setRedoDisabled(true);
                    }
                    break;

                case "MovedCCP":
                    curX = lineToAdd.present.ccp.left;
                    curY = lineToAdd.present.ccp.top;
                    nextX = lineToAdd.present.ccpMovedPos.left;
                    nextY = lineToAdd.present.ccpMovedPos.top;

                    canvas.setActiveObject(lineToAdd.present.ccp);
                    canvas.discardActiveObject();
                    canvas.remove(lineToAdd.present.ccp);
                    canvas.requestRenderAll();

                    lineToAdd.present.ccpMovedPos.left = curX;
                    lineToAdd.present.ccpMovedPos.top = curY;
                    lineToAdd.present.ccp.left = nextX;
                    lineToAdd.present.ccp.top = nextY;

                    canvas.add(lineToAdd.present.ccp);
                    canvas.requestRenderAll();
                    props.dispatch({ type: "redo" });
                    if (props.future.length <= 1) {
                        setRedoDisabled(true);
                    }
                    break;

                case "MovedProcess":
                    curX = lineToAdd.present.proc.left;
                    curY = lineToAdd.present.proc.top;
                    nextX = lineToAdd.present.procMovedPos.left;
                    nextY = lineToAdd.present.procMovedPos.top;

                    canvas.setActiveObject(lineToAdd.present.proc);
                    canvas.discardActiveObject();
                    canvas.remove(lineToAdd.present.proc);
                    canvas.requestRenderAll();

                    lineToAdd.present.procMovedPos.left = curX;
                    lineToAdd.present.procMovedPos.top = curY;
                    lineToAdd.present.proc.left = nextX;
                    lineToAdd.present.proc.top = nextY;

                    canvas.add(lineToAdd.present.proc);
                    canvas.requestRenderAll();
                    props.dispatch({ type: "redo" });

                    if (props.future.length <= 1) {
                        setRedoDisabled(true);
                    }
                    break;

                case "CreatedRoomGroup":
                    canvas.add(lineToAdd.present.roomGroup);
                    props.dispatch({ type: "redo" });
                    canvas.requestRenderAll();
                    break;

                case "MovedRoom":
                    let roomObject = lineToAdd.present.roomObject;
                    let newOriginLeft = roomObject.left;
                    let newOriginTop = roomObject.top;

                    roomObject.left = lineToAdd.present.roomOrigin.left;
                    roomObject.top = lineToAdd.present.roomOrigin.top;

                    lineToAdd.present.roomOrigin.left = newOriginLeft;
                    lineToAdd.present.roomOrigin.top = newOriginTop;

                    props.dispatch({ type: "redo" });
                    canvas.requestRenderAll();
                    break;

                default:
                    console.log("Unrecognized event in Redo: " + lineToAdd.event);
            }
        }
        setIsBlocking(true);
        if (props.setIsBlocking) {
            props.setIsBlocking(true);
        }
        if (props.future.length <= 1) {
            setRedoDisabled(true);
        }
        setUndoDisabled(false);
    }

    const [toolTipsOpen, setToolTipsOpen] = useState(new Array(5).fill(false));

    //These function are necessary for none button components as otherwise the
    //tool tips don't work fully with them. Basically once the user interacts
    //with the component the tool tip stays open unless we time a close for it.
    //todo try and update this with onMouseEnter and onMouseLeave
    function closeToolTipSoon(index) {
        setTimeout(() => {
            let newArray = [...toolTipsOpen];
            newArray[index] = false;
            setToolTipsOpen(newArray);
        }, 1800);
    }

    function openToolTip(index) {
        if (toolTipsOpen[index]) {
            return;
        }

        let newArray = [...toolTipsOpen];
        newArray[index] = true;
        setToolTipsOpen(newArray);
        closeToolTipSoon(index);
    }

    function applyGridSettings(width, height, color) {
        if (color) {
            setGridColor(color.hex);
        }

        setGridWidth(width);
        setGridHeight(height);

        setGridMenuOpen(false);
    }
    const [newText, setNewText] = useState("Enter Text");
    function handleChangeText(event) {
        setNewText(event.target.value);
    }
    function saveAndContinue() {
        if (newText !== "Enter Text") {
            let text = new fabric.IText(newText, {
                editable: true,
                selectable: true,
                fontFamily: "arial sans-serif",
                fontSize: myTextObject.fontSize,
                left: myTextObject.left,
                top: myTextObject.top,
                id: Math.random(),
                haccp_type: "text",
            });

            canvas.remove(myTextObject);
            setMyTextObject(null);
            canvas.add(text);

            props.dispatch({
                type: "addAction",
                event: "CreatedText",
                value: { text },
            });
            setIsBlocking(true);
            if (props.setIsBlocking) {
                props.setIsBlocking(true);
            }

            setUndoDisabled(false);
            //setRedoDisabled(false);
        }
        setNewText("Enter Text");
        setEditTextModalOpen(false);
        addTextListeners();
    }

    return (
        <Grid item xs={12} style={{ width: "100%" }}>
            <Dialog open={editTextModalOpen} maxWidth={"md"}>
                <DialogContent>
                    <Grid container>
                        {" "}
                        <Grid item xs={12}>
                            {myTextObject && (
                                <TextField
                                    label="New Text"
                                    name="New Text"
                                    variant="outlined"
                                    defaultValue={myTextObject.text}
                                    onChange={handleChangeText}
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                            )}
                        </Grid>{" "}
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ margin: "12px",marginBottom: "4px", marginRight: "4px", float: "right" }}
                                onClick={saveAndContinue}
                            >
                                Save and Continue
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{ margin: "12px",marginBottom: "4px", marginRight: "0px", float: "right" }}
                                onClick={() => setEditTextModalOpen(false)}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Prompt
                message={(location, action) => {
                    //Prompt triggers on any nav event, in this case push and then replace
                    //if any are false, the chain stops and resolves.
                    //If one is true somewhere in the chain, the nav proceeds.
                    //The goal of this function is to ignore all of that and have this behave like window.confirm

                    //If they have already confirmed, or made no changes allow navigation
                    if (!isBlocking || confirmed) {
                        return true;
                    }
                    //If they haven't confirmed yet, or are are trying to nav again after previously declining, re-confirm
                    else if (confirmed === 0 || (action == "PUSH" && confirmed === false)) {
                        confirmed = window.confirm("You have unsaved changes, are you sure you want to leave?");
                    }
                    //If they've said no, and this still transitions to replace, reset confirmed and cancel nav
                    else if (action == "REPLACE" && confirmed === false) {
                        confirmed = 0;
                        return false;
                    }

                    return confirmed;
                }}
            />
            <AppBar position="sticky" color="primary">
                <Toolbar style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
                    <Grid container alignItems="center">
                    <Select value={mode} onChange={handleModeChange} style={{ margin: "8px", color: "white" }}>
                        <MenuItem value={"select"}>Select Mode</MenuItem>
                        <MenuItem value={"line"}>Line Mode</MenuItem>
                        <MenuItem value={"wall"}>Wall Mode</MenuItem>
                    </Select>
                    {(mode === "line" || mode === "wall") && (
                        <Select
                            value={reactLineVariant}
                            onChange={lineVariantChange}
                            style={{ margin: "8px", color: "white" }}
                        >
                            {lineStrokeOptions.map((variant, index) => {
                                if (mode === "line") {
                                    return (
                                        <MenuItem key={index} name={index} value={variant.name}>
                                            {variant.name}
                                        </MenuItem>
                                    );
                                } else {
                                    if (!variant.name.includes("(double-ended)")) {
                                        return (
                                            <MenuItem key={index} name={index} value={variant.name}>
                                                {variant.name}
                                            </MenuItem>
                                        );
                                    }
                                }
                            })}
                        </Select>
                    )}

                    {(mode === "line" || mode === "wall") && (
                        <div style={{ zIndex: 2 }}>
                            <div style={styles.swatch} onClick={handleClick}>
                                <div style={styles.color} />
                            </div>
                            {displayColorPicker ? (
                                <div style={styles.popover}>
                                    <div style={styles.cover} onClick={handleColorClose} />
                                    <SwatchesPicker onChangeComplete={handleChange} />
                                </div>
                            ) : null}
                        </div>
                    )}

                    <Tooltip
                        title="Toggle Canvas Grid"
                        placement="top-start"
                        disableFocusListener={true}
                        disableTouchListener={true}
                        open={toolTipsOpen[GRID_SWITCH_INDEX]}
                        onOpen={() => {
                            openToolTip(GRID_SWITCH_INDEX);
                        }}
                    >
                        <Switch checked={gridEnabledReact} onChange={gridModeChange} color="default" name="Grid" />
                    </Tooltip>
                    {props.trafficFlow && (
                        <Tooltip title="Add CCP" placement="top-start" disableTouchListener disableFocusListener>
                            <IconButton
                                className={classes.icon}
                                color="primary"
                                variant="contained"
                                style={{ margin: "8px" }}
                                onClick={addCrossContaminationPoint}
                            >
                                <AddBoxIcon />
                            </IconButton>
                        </Tooltip>
                    )}

                    <Tooltip title="Add Text Field" placement="top-start" disableTouchListener disableFocusListener>
                        <IconButton
                            className={classes.icon}
                            color="primary"
                            variant="contained"
                            style={{ margin: "8px" }}
                            onClick={addText}
                        >
                            <TextFieldsIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Delete Selection" placement="top-start" disableTouchListener disableFocusListener>
                        <IconButton
                            className={classes.icon}
                            color="primary"
                            variant="contained"
                            style={{ margin: "8px" }}
                            onClick={deleteLine}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>

                    {props.processFlow && processSelected && (
                        <Tooltip
                            title="Edit Selected Process"
                            placement="top-start"
                            disableTouchListener
                            disableFocusListener
                        >
                            <IconButton
                                className={classes.icon}
                                //color="primary"
                                variant="contained"
                                style={{ margin: "8px" }}
                                onClick={editProcess}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    )}

                    <Tooltip title="Save Changes" placement="top-start" disableTouchListener disableFocusListener>
                        <IconButton
                            className={classes.icon}
                            color="primary"
                            variant="contained"
                            style={{ margin: "8px" }}
                            onClick={() => {
                                saveData(
                                    canvas,
                                    props.haccpPlan,
                                    props.processFlow ? "PROCESS_FLOW" : "TRAFFIC_FLOW",
                                    flowDiagram
                                );
                            }}
                        >
                            <SaveIcon />
                        </IconButton>
                    </Tooltip>

                    {props.trafficFlow && (
                        <div>
                            <Tooltip
                                title="Edit Traffic Flow Diagram Description"
                                placement="top-start"
                                disableTouchListener
                                disableFocusListener
                            >
                                <IconButton
                                    className={classes.icon}
                                    onClick={() => {
                                        setDetailsModalOpen(true);
                                    }}
                                    variant="contained"
                                    color="primary"
                                >
                                    <DescriptionIcon />
                                </IconButton>
                            </Tooltip>

                            <Tooltip
                                title="Change Background Image"
                                placement="top-start"
                                disableTouchListener
                                disableFocusListener
                            >
                                <IconButton
                                    className={classes.icon}
                                    onClick={() => setImageManagerOpen(true)}
                                    variant="contained"
                                    color="primary"
                                >
                                    <PhotoLibraryIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )}

                    {gridEnabledReact && (
                        <Tooltip
                            title="Open Grid Settings"
                            placement="top-start"
                            disableTouchListener
                            disableFocusListener
                        >
                            <IconButton
                                className={classes.icon}
                                onClick={() => setGridMenuOpen(true)}
                                variant="contained"
                                color="primary"
                            >
                                <GridOnIcon />
                            </IconButton>
                        </Tooltip>
                    )}

                    {props.buttons &&
                        props.buttons.map((button) => {
                            //todo allow passing of tool tips
                            return React.cloneElement(button, {
                                className: classes.icon,
                                onClick: button.onClick(canvas),
                            });
                        })}

                    {isBlocking && (
                        <Tooltip title="Save changes" placement="top-start" disableTouchListener disableFocusListener>
                            <Button
                                variant="outlined"
                                color="inherit"
                                size="small"
                                style={{ marginLeft: "8px" }}
                                onClick={() => {
                                    saveData(
                                        canvas,
                                        props.haccpPlan,
                                        props.processFlow ? "PROCESS_FLOW" : "TRAFFIC_FLOW",
                                        flowDiagram
                                    );
                                }}
                            >
                                Click here to save
                            </Button>
                        </Tooltip>
                    )}

                    {!props.templateMode && (
                        <VerticalDotMenu
                            style={{ flex: 1, textAlign: "right" }}
                            white={true}
                            getTemplates={getTemplates}
                            createTemplate={() => {
                                props.createTemplate(flowDiagram);
                            }}
                            setProcessFlowDiagram={props.setFlowDiagram}
                            saveData={saveData}
                            setStatus={setStatus}
                            canvas={canvas}
                            gridEnabled={gridEnabledReact}
                            changeMode={changeMode}
                            setupCanvas={setupCanvas}
                            //selectTemplate={props.selectTemplate} unused for now
                            api={props.processFlow ? props.processFlowAPI : props.trafficFlowApi}
                            instance={flowDiagram || props.trafficFlow || props.processFlow}
                            instanceName="Formulation"
                            template_title="Process Flow Diagram Templates"
                            template_cols={[
                                { title: "Name", field: "name" },
                                {
                                    title: "Description",
                                    field: "description",
                                },
                                {
                                    title: "Weight",
                                    field: "weight",
                                    editable: "never",
                                },
                                {
                                    title: "Volume",
                                    field: "volume",
                                    editable: "never",
                                },
                            ]}
                        />
                    )}
                    </Grid>
                </Toolbar>
            </AppBar>

            {processMousedOver && (
                <div
                    style={{
                        width: "400px",
                        height: "40px",
                        backgroundColor: "transparent",
                        position: "fixed",
                        zIndex: 1,
                    }}
                >
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography
                                style={{
                                    float: "right",
                                    padding: "4px",
                                    paddingLeft: "8px",
                                    paddingTop: "6px",
                                    fontSize: "11px",
                                }}
                            >
                                Process Selected: {processMousedOver}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button size="small" variant="text" color="primary" onClick={openProcess}>
                                Edit Process
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            )}

            <div
                style={{
                    width: "100%",
                    padding: "16px",
                    overflow: "scroll",
                    zIndex: 2,
                }}
            >
                <Grid container direction="column" justify="space-between" alignItems="center">
                    <Grid item>
                        <div
                            style={{
                                width: "100%",
                                padding: "16px",
                            }}
                        >
                            <canvas style={canvas_styling} id="diagram" />
                        </div>
                    </Grid>
                </Grid>
            </div>

            <Message
                position={true}
                open={messageOpen}
                message={status.message}
                severity={status.severity}
                vertical="bottom"
                horizontal="right"
                handleClose={handleMessageClose}
            />
            {crossContaminationModalOpen && 
            <EditCrossContaminationPointModal
                setCCPText={setCCPText}
                cross_contamination_point_pk={crossContaminationPointSelected}
                open={crossContaminationModalOpen}
                setOpen={setCrossContaminationModalOpen}
                setShouldClose={setShouldClose}
                modelClose={handleCrossContaminationModalClosed}
                handleModalClosed={handleCrossContaminationModalClosed}
            />
            }
            {
                /* Double check the pencil icon goes away when the BE is hooked up*/
                <EditProcessModal
                    setShouldClose={setShouldClose}
                    setProcessText={setProcessText}
                    process_pk={processSelected}
                    open={processModalOpen}
                    loadProcessCCPs={loadActiveProcessCCPText}
                    handleModalClosed={handleModalClosed}
                    modelClose={() => {
                        setProcessModalOpen(false);
                    }}
                    templateMode={props.templateMode}
                />
            }

            <Dialog
                open={undoDialogOpen}
                onClose={handleUndoDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Are you sure you want to delete this process? </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This action will save your diagram and cannot be reversed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUndoDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={undoSelectedProcess} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {props.trafficFlow && detailsModalOpen && (
                <TrafficFlowDiagramDescription
                    plan={props.haccpPlan}
                    diagramType={props.processFlow ? "PROCESS_FLOW" : "TRAFFIC_FLOW"}
                    canvas={canvas}
                    onClose={handleDetailsModalClosed}
                    open={detailsModalOpen}
                    trafficFlowDiagram={flowDiagram}
                    setTrafficFlowDiagram={descriptionSetflowDiagram}
                    save={saveData}
                    cancel={() => {
                        setDetailsModalOpen(false);
                    }}
                    modalClose={() => {
                        setDetailsModalOpen(false);
                    }}
                    handleModalClosed={handleDetailsModalClosed}
                    setShouldClose={setShouldClose}
                    setBackgroundImage={addBackgroundImage}
                    setStatus={setReturnStatus}
                />
            )}
            {imageManagerOpen && (
                <TrafficFlowDiagramImageManager
                    open={imageManagerOpen}
                    cardStyle={{ width: "200px", height: "250px" }}
                    onClose={() => setImageManagerOpen(false)}
                    actions={[{ action: handleFileChangeFromServer, label: "Use" }]}
                    uploadAction={useUploadedImage}
                />
            )}
            <Dialog open={gridMenuOpen}>
                <DialogContent>
                    <GridMenu
                        open={gridMenuOpen}
                        width={gridWidth}
                        height={gridHeight}
                        squareGrid={gridHeight == gridWidth}
                        cancelGridSettings={() => {
                            setGridMenuOpen(false);
                        }}
                        onClose={() => {
                            setGridMenuOpen(false);
                        }}
                        applyGridSettings={applyGridSettings}
                    />
                </DialogContent>
            </Dialog>
        </Grid>
    );
}

const mapStateToProp = (state) => ({
    past: state.past,
    present: state.present,
    future: state.future,
});

export default connect(mapStateToProp)(DiagramDrawer);
