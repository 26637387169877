class FFormulationsAPI {
    constructor(api, version) {
      this.api = api;
      this.version = version;
    }
    
    listFFormulations(id) {
      if (typeof id === 'number') {
        return this.api.get(`${this.version}/f_formulations/${id}/`);
      }
   
      return this.api.get(`${this.version}/f_formulations/`)
    }
    
    createFFormulations(payload) {
      return this.api.post(`${this.version}/f_formulations/`, payload);
    }
    
    updateFFormulations(payload) {
      return this.api.put(`${this.version}/f_formulations/${payload.id}/`, payload);
    }
    
    deleteFFormulations(id) {
      return this.api.delete(`${this.version}/f_formulations/${id}/`);
    }
    
  }
    
  export default FFormulationsAPI;