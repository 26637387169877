import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

export default function Prompt({agree, disagree, open, title, description, promptData}) {

    return (
        <div>
            <Dialog
                open={open}
                onClose={disagree}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {description}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={disagree} color="primary">
                    No 
                </Button>
                <Button onClick={() => {agree(promptData)}} color="primary" autoFocus>
                    Yes 
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}