import { getItemFromLocalStorage } from "./Api";

const auth = "rest-auth";

class Auth {
  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  login(payload) {
    return this.api.post(`/api/token/`, payload);
  }
  verifyOtp(data){
    return this.api.post(`${this.version}/user_otp/verify/`, data)
  }


  refresh() {
    return new Promise((resolve, reject) => {
      let refresh_token = getItemFromLocalStorage('refresh_token');
      if (refresh_token) {
        this.api.post('/api/token/refresh/', {refresh: refresh_token}).then(response => {
          localStorage.setItem('access_token', response.data.access);
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      }
      else {
        reject("No token")
      }
    })
  }

  logout() {
    localStorage.clear();
    window.location.replace('/login');
    //return this.api.post(`${auth}/logout/`);
  }

  changePassword(email) {
    let payload = {
      email: email
    };

    return this.api.post(`${auth}/password/reset/`, payload);
  }

  updatePassword(pk, payload) {
    return this.api.post(`${this.version}/users/change_password/`, payload);
  }

  updateProfile(payload) {
    return this.api.put(`${this.version}/profiles/${payload.id}/`, payload);
  }

  changeProfile(payload) {
    return this.api.put(`${auth}/user/`, payload);
  }

  getAuthedProfile() {
    return this.api.get('get_user/');
  }

  getOrganizationCountForRedirect(){
    return this.api.get(`${this.version}/users/get_organization_count/`)
  }

  changeEmailRequest(data) {
    return this.api.post(`${this.version}/users/change_email_request/`, data)
  }

  resetPasswordRequest(data){
    return this.api.post(`${this.version}/users/reset_password_request/`, data)
  }
  checkPasswordResetRequest(data){
    return this.api.get(`${this.version}/user_password_reset/get_by_tokens/`, {params: data})
  }
  
  resetPasswordConfirm(data){
    return this.api.post(`${this.version}/user_password_reset/reset_password/`, data)
  }

  checkEmailChangeRequest(data){
    return this.api.get(`${this.version}/user_email_change/get_by_tokens/`, {params: data})
  }
  
  changeEmailConfirmation(data){
    return this.api.post(`${this.version}/user_email_change/confirm_email_change/`, data)
  }

  changeName(data){
    return this.api.put(`${this.version}/users/change_name/`, data)
  }

  changeSettings(data){
    return this.api.put(`${this.version}/users/change_settings/`, data)
  }

  acceptTerms(userId, data) {
    return this.api.put(`${this.version}/users/${userId}/accept_terms/`, data);
  }

  getAllUsers() {
    return this.api.get('get_all_users/');
  }
}


export default Auth;
