class CompanyInfoAPI {
  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  update(payload) {
    return this.api.put(`${this.version}/company_info/1/`, payload);
  }

  get() {
    return this.api.get(`${this.version}/company_info/`);
  }
}

export default CompanyInfoAPI