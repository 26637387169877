import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography, MenuItem, Select, TextField } from '@material-ui/core';
import GreyTextField from 'Components/WhiteTextField';
import AutocompleteSD from 'Components/AutocompleteSD';
import { StylesContext } from 'App';
import useOrganization from 'hooks/useOrganization';
import { Autocomplete } from '@material-ui/lab';

export const categoryOptions = [
    { label: 'Raw Ingredients', value: 'RAW' },
    { label: 'Packaging Materials', value: 'PKG' }
];

export const unitMetricOptions = [
    { label: 'Volume', value: 'VOL' },
    { label: 'Weight', value: 'WEI' },
    { label: 'Quantity', value: 'QUA' }
];

const AddFIngredientsDialog = ({ open, handleClose, handleSave, ingredientToEdit, editable }) => {
    const classes = React.useContext(StylesContext);
    const org = useOrganization();
    const [ingredient, setIngredient] = useState({
        organization: "",
        name: "",
        unit_metric: "",
        category: ""
    });

    useEffect(() => {
        if (open) {
            if (ingredientToEdit) {
                setIngredient(ingredientToEdit);
            } else {
                setIngredient({
                    organization: org.pk,
                    name: "",
                    unit_metric: "",
                    category: ""
                });
            }
        }
    }, [open, ingredientToEdit]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setIngredient((prevIngredient) => ({
            ...prevIngredient,
            [name]: value
        }));
    };

    const handleSaveIngredient = () => {
        // Check if required fields are not empty
        if (
            ingredient.name.trim() === "" ||
            ingredient.unit_metric.trim() === "" ||
            ingredient.category.trim() === ""
        ) {
            alert("Please fill in all required fields.");
            return;
        }
        // Call the handleSave prop function
        handleSave(ingredient);
    };

    const handleCategoryChange = (event, newValue) => {
        const selectedCategory = categoryOptions.find(option => option.label === newValue);
        if (selectedCategory) {
            handleChange({ target: { name: 'category', value: selectedCategory.value } });
        }
    };

    const handleUnitMetricChange = (event, newValue) => {
        const selectedUnitMetric = unitMetricOptions.find(option => option.label === newValue);
        if (selectedUnitMetric) {
            handleChange({ target: { name: 'unit_metric', value: selectedUnitMetric.value } });
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogContent style={{ backgroundColor: '#f8f8f8', height: '430px' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography style={{ fontSize: '16px', fontWeight: 500 }}>
                            {ingredientToEdit ? "Edit Ingredient" : "Add Ingredient"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ fontSize: '14px', fontWeight: 500 }}>
                            Category
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {/* <AutocompleteSD
                            value={ingredient.category}
                            id="category"
                            options={['Raw Ingredients', 'Packaging Materials']}
                            choices={['RAW', 'PKG']}
                            optionType={'rawObjects'}
                            onChange={(emptyEvent, newValue) => {
                                const choiceIndex = ['Raw Ingredients', 'Packaging Materials'].indexOf(newValue);
                                const abbreviation = ['RAW', 'PKG'][choiceIndex];
                                handleChange({ target: { name: 'category', value: abbreviation } });
                            }}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                            className={classes.generalFormTextField}
                            disabled={!editable}
                        /> */}
                        <Autocomplete
                            value={categoryOptions.find(option => option.value === ingredient.category)?.label || ''}
                            options={categoryOptions.map(option => option.label)}
                            onChange={(event, newValue) => handleCategoryChange(event, newValue)}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                            className={classes.generalFormTextField}
                            disabled={!editable}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ fontSize: '14px', fontWeight: 500 }}>
                            Name
                        </Typography>
                        </Grid>
                        <Grid item xs={12}>
                        <GreyTextField
                            name="name"
                            value={ingredient.name}
                            onChange={handleChange}
                            fullWidth
                            disabled={!editable}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ fontSize: '14px', fontWeight: 500 }}>
                            Unit Metric
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {/* <AutocompleteSD
                            value={ingredient.unit_metric}
                            id="unit_metric"
                            options={['Volume', 'Weight','Quantity']}
                            choices={['VOL', 'WEI','QUA']}
                            optionType={'rawObjects'}
                            onChange={(emptyEvent, newValue) => {
                                const choiceIndex = ['Volume', 'Weight', 'Quantity'].indexOf(newValue);
                                const abbreviation = ['VOL', 'WEI', 'QUA'][choiceIndex];
                                handleChange({ target: { name: 'unit_metric', value: abbreviation } });
                            }}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                            className={classes.generalFormTextField}
                            disabled={!editable}
                        /> */}
                        <Autocomplete
                            value={unitMetricOptions.find(option => option.value === ingredient.unit_metric)?.label || ''}
                            options={unitMetricOptions.map(option => option.label)}
                            onChange={(event, newValue) => handleUnitMetricChange(event, newValue)}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                            className={classes.generalFormTextField}
                            disabled={!editable}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ backgroundColor: '#f8f8f8' }}>
                <Button onClick={handleClose}>
                    Cancel
                </Button>
                <Button color="primary" variant="contained" onClick={handleSaveIngredient} disabled={!editable}>
                    {ingredientToEdit ? "Save" : "Create"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddFIngredientsDialog;
