import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import API from "../../Api/Api";
import Divider from "@material-ui/core/Divider";
import VerticalDotMenu from "../../Components/VerticalDotMenu";

import Message from "../../Components/Message";

import { Prompt } from "react-router";

let confirmed;

// Edit ccp
function CCP(props) {
  const [ccp, setCCP] = useState(null);
  let [isBlocking, setIsBlocking] = useState(false);
  const api = new API();

  let params = useParams();
  let history = useHistory();

  useEffect(() => {
    let pk = props.pk || params.pk;
    confirmed = 0;
    getCCP(pk);
  }, [params.pk, props.pk]);

  function getCCP(pk) {
    api
      .getCcpAPI()
      .retrieveCCP(pk)
      .then((e) => {
        setCCP(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const [status, setStatus] = useState({ message: null, severity: "success" });

  function handleChange(event) {
    setIsBlocking(true);
    setCCP({ ...ccp, [event.target.name]: event.target.value });
  }

  function handleSubmit(e) {
    setIsBlocking(false);
    e.preventDefault();
    updateCCP();
  }

  function saveAndContinue(e) {
    setIsBlocking(false);
    e.preventDefault();
    updateCCP();
    if (props.saveAndContinue) {
      props.saveAndContinue();
    } else {
      history.goBack();
    }
  }

  function updateCCP(callback) {
    api
      .getCcpAPI()
      .updateCCP(ccp)
      .then((e) => {
        setCCP(e.data);
      })
      .catch((e) => {
        console.log(e);
      });

    if (callback) {
      callback();
    }
  }

  function deleteCCP() {
    setMessageOpen(true);

    if (props.isCCP === true) {
      setStatus({
        message:
          "Please update the CCP determination worksheet before deleting this CCP.",
        severity: "warning",
      });
    } else {
      props.deleteCCP();
    }
  }

  function getTemplates() {
    return api.getCcpTemplateAPI().listCCPTemplates();
  }

  function createTemplate() {
    return api
      .getCcpTemplateAPI()
      .createCCPTemplate(ccp)
      .then((e) => {
        console.log(e);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function selectTemplate(template) {
    api
      .getCcpTemplateAPI()
      .selectTemplate(ccp.pk, template)
      .then((e) => {
        setCCP(e.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  window.onbeforeunload = function (e) {
    if (isBlocking || props.isBlocking) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  const [messageOpen, setMessageOpen] = useState(false);

  function handleMessageClose() {
    setMessageOpen(false);
  }

  return (
    <div style={{ padding: "16px" }}>
      <Message
        open={messageOpen}
        message={status.message}
        severity={status.severity}
        vertical="bottom"
        horizontal="right"
        handleClose={handleMessageClose}
      />
      <Prompt
        message={(location, action) => {
          //Prompt triggers on any nav event, in this case push and then replace
          //if any are false, the chain stops and resolves.
          //If one is true somewhere in the chain, the nav proceeds.
          //The goal of this function is to ignore all of that and have this behave like window.confirm

          //If they have already confirmed, or made no changes allow navigation
          if ((!isBlocking && !props.isBlocking) || confirmed) {
            return true;
          }
          //If they haven't confirmed yet, or are are trying to nav again after previously declining, re-confirm
          else if (
            confirmed === 0 ||
            (action === "PUSH" && confirmed === false)
          ) {
            confirmed = window.confirm(
              "You have unsaved changes, are you sure you want to leave?"
            );
          }
          //If they've said no, and this still transitions to replace, reset confirmed and cancel nav
          else if (action === "REPLACE" && confirmed === false) {
            confirmed = 0;
            return false;
          }

          return confirmed;
        }}
      />
     
      {ccp && (
        <Grid container spacing={3}>
          <Grid item xs={11}>
            <Typography variant="h6" noWrap>
              CCP
            </Typography>
            <Typography>Edit/view this Critical Control Point.</Typography>
          </Grid>
          <Grid item xs={1}>
            <VerticalDotMenu
              getTemplates={getTemplates}
              createTemplate={createTemplate}
              selectTemplate={selectTemplate}
              instanceName="CCP"
              deleteInstance={deleteCCP}
              api={api.getCcpAPI()}
              instance={ccp}
              template_title="CCP Templates"
              template_cols={[
                { title: "Name", field: "name" },
                { title: "Description", field: "description" },
                { title: "Type", field: "weight", editable: "never" },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Name"
              name="identifier"
              variant="outlined"
              defaultValue={ccp.identifier}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Control Measure"
              name="control_measure"
              variant="outlined"
              defaultValue={ccp.control_measure}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Critical Limits for each Control Measure"
              name="critical_limits"
              variant="outlined"
              defaultValue={ccp.critical_limits}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Monitoring</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="What"
              name="what"
              variant="outlined"
              defaultValue={ccp.what}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="How"
              name="how"
              variant="outlined"
              defaultValue={ccp.how}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Frequency"
              name="frequency"
              variant="outlined"
              defaultValue={ccp.frequency}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Who"
              name="who"
              variant="outlined"
              defaultValue={ccp.who}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Records"
              name="records"
              variant="outlined"
              defaultValue={ccp.records}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Corrective Action and Records"
              name="corrective_action"
              variant="outlined"
              defaultValue={ccp.corrective_action}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Verification"
              name="verification"
              variant="outlined"
              defaultValue={ccp.verification}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "8px", float:"right" }}
              onClick={saveAndContinue}
            >
              Save and Continue
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default CCP;
