import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Container, Paper, Typography, FormHelperText } from "@material-ui/core";



import API from '../../Api/Api'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8)
  },
  title: {
    padding: theme.spacing(4),
  },
  form: {
    display: "flex",
    padding: theme.spacing(4),
    flexDirection: "column",
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const CreateOrganizationForm = () => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [hasTraceability, setHasTraceability] = useState(false);

  const api = new API();

  const [successMessage, setSuccessMessage] = useState("");
  const [nameError, setNameError] = useState("");
  const [generalError, setGeneralError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const organization = {
      name: name,
      has_traceability: hasTraceability,
    };

    try {
      // Replace the URL with the actual API endpoint
      const response = await api.api.post('/v1/organizations/', organization);

      // Handle the response (e.g., display a success message or redirect)
      setSuccessMessage("Organization created successfully! Refresh to see the new organization.");
      setNameError("");
      setGeneralError("");
    } catch (error) {
      // Handle any errors (e.g., display an error message or log the error)
      if (error.response && error.response.data) {
        setNameError(error.response.data.name ? error.response.data.name.join(" ") : "");
        setGeneralError(error.response.data.non_field_errors ? error.response.data.non_field_errors.join(" ") : "");
      } else {
        setGeneralError("An error occurred while creating the organization.");
      }
      setSuccessMessage("");
    }
  };

  return (
    <Container className={classes.container} maxWidth="sm">
      <Paper className={classes.paper} variant="outlined" elevation={1}>
        <Typography className={classes.title} variant="h5" component="h1" gutterBottom>
          Create Organization
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            id="name"
            label="Name"
            variant="outlined"
            className={classes.textField}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            error={!!nameError}
            helperText={nameError}
          />
          <FormControlLabel
            control={
              <Checkbox
                id="hasTraceability"
                checked={hasTraceability}
                onChange={(e) => setHasTraceability(e.target.checked)}
                color="primary"
              />
            }
            label="Has Traceability"
          />
          {successMessage && (
            <FormHelperText style={{ color: "green", marginBottom: "1rem" }}>
              {successMessage}
            </FormHelperText>
          )}
          {generalError && (
            <FormHelperText style={{ color: "red", marginBottom: "1rem" }}>
              {generalError}
            </FormHelperText>
          )}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.button}
            disabled={!name} // Disable the button if the name is empty
          >
            Create Organization
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default CreateOrganizationForm;
