import API from "./Api";

class HaccpPlanTemplateAPI {

  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listHaccpPlanTemplates() {
    return this.api.get(`${this.version}/haccp_plan_templates/`)
  }

  createHaccpPlanTemplate(payload) {
    return this.api.post(`${this.version}/haccp_plan_templates/`, payload);
  }

  retrieveHaccpPlanTemplate(pk) {
    return this.api.get(`${this.version}/haccp_plan_templates/${pk}/`);
  }

  updateHaccpPlanTemplate(payload) {
    return this.api.put(`${this.version}/haccp_plan_templates/${payload.pk}/`, payload);
  }

  deleteHaccpPlanTemplate(pk) {
    return this.api.delete(`${this.version}/haccp_plan_templates/${pk}/`);
  }

  getHistory(pk) {
    return this.api.get(`${this.version}/haccp_plan_templates/${pk}/get_history/`);
  }
}


export default HaccpPlanTemplateAPI;
