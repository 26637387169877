class SupplierAPI {
  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  listSuppliers(pk) {
    if (typeof pk === 'number') {
      return this.api.get(`${this.version}/suppliers/${pk}/`);
    }

    return this.api.get(`${this.version}/suppliers/`);
  }

  listSuppliersDisplay(pk) {
    if (typeof pk === 'number') {
      return this.api.get(`${this.version}/suppliers_display/${pk}/`);
    }

    return this.api.get(`${this.version}/suppliers_display/`);
  }

  createSupplier(payload) {
    return this.api.post(`${this.version}/suppliers/`, payload);
  }

  updateSupplier(payload) {
    return this.api.put(`${this.version}/suppliers/${payload.pk}/`, payload);
  }

  softDeleteSupplier(payload) {
    return this.api.put(`${this.version}/suppliers/${payload.pk}/soft_delete_action/`, payload);
  }

  deleteSupplier(pk) {
    return this.api.delete(`${this.version}/suppliers/${pk}/`);
  }
}

export default SupplierAPI