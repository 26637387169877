import React, { useEffect, useState } from "react"

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

import API from "../../Api/Api";
import { DataGrid } from '@material-ui/data-grid';

import CancelIcon from '@material-ui/icons/Cancel';

export default function AddTasksDialog(props) {
  const [availableStandards, setAvailableStandards] = useState([]);
  const [standardsSelected, setStandardsSelected] = useState([]);

  const api = new API();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.open) {
      api.getPlanBuilderAPI().getProgramStandards(props.program.id).then(response => {
        setAvailableStandards(response.data);
        setLoading(false);
      }).catch(error => {
        console.log(error);
      });
    }
  }, [props.open]);

  return (
    <Dialog open={props.open} maxWidth="md" fullWidth>
      <DialogTitle style={{ borderBottom: '1px solid #eaeaea', padding: '16px' }}>
        <Grid container alignItems="center" >
          <Grid item xs={8}>
            Add Standards 
          </Grid>
          <Grid item container xs={4} justify="flex-end">
            <IconButton onClick={() => { props.setOpen(false) }}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {loading && <Grid item container xs={12} style={{height: '100%'}} justify="center" alignItems="center"><CircularProgress /></Grid>}

        {!loading && !availableStandards.length &&
          <DialogContentText style={{ padding: '16px' }}>
            No standards were found.
        </DialogContentText>
        }

        {!loading && availableStandards.length > 0 &&
          <DialogContentText style={{ padding: '16px' }}>
            Select available standards from the list below to attach them to the task.
        </DialogContentText>
        }

        <Grid container item xs={12} style={{ height: '500px' }}>
          {availableStandards.length > 0 &&
            <DataGrid
              checkboxSelection
              disableColumnMenu
              rows={[...availableStandards]}
              columns={[{ field: 'bullet', field: 'title', headerName: "Standards", flex: 1 }]}
              onSelectionModelChange={(newSelection) => {
                setStandardsSelected(newSelection.selectionModel);
              }}
            />
          }
        </Grid>
      </DialogContent>
      <DialogActions style={{ borderTop: '1px solid #eaeaea' }}>
        <Grid container item xs={12} alignItems="center" justify="flex-end" >
          <Button variant="outlined" color="secondary" style={{ margin: '4px' }} onClick={() => { props.setOpen(false) }}>
            Cancel
          </Button>
          <Button
            disabled={!standardsSelected.length}
            variant="contained" color="primary" style={{ margin: '4px' }}
            onClick={() => { props.setOpen(false); props.addStandards(standardsSelected) }}
          >
            Add Standards 
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
