class DeviationAPI {
    constructor(api, version) {
        this.api = api;
        this.version = version;
    }

    createDeviation(deviation) {
        return this.api.post(`${this.version}/deviation_form/`, deviation);
    }

    getDeviation(pk) {
        return this.api.get(`${this.version}/deviation_form/${pk}/`);
    }
    getDeviationNames(haccp_plan_pk) {
      if (typeof haccp_plan_pk === 'number') {
        return this.api.get(`${this.version}/deviation_form/get_event_names/?haccp_plan_pk=${haccp_plan_pk}`);
      }
  
      return this.api.post(`${this.version}/deviation_form/get_event_names/`)
    }

    updateDeviation(deviation) {
        return this.api.put(`${this.version}/deviation_form/${deviation.id}/`, deviation);
    }
    getMyDeviations() {
        return this.api.get(`${this.version}/deviation_form/`);
    }
    searchDeviations(payload, pageNumber, pageSize) {
        return this.api.post(`${this.version}/deviation_form_search/list_between_dates/?page=${pageNumber}&page_size=${pageSize}`, payload);
    }
    export(params) {
        return this.api.get(`${this.version}/deviation_form_search/export${this.objectToQueryParams(params)}`, { responseType: 'arraybuffer' });
    }
    getDeviationNames(haccp_plan_pk) {
        if (typeof haccp_plan_pk === "number") {
            return this.api.get(`${this.version}/deviation_form/get_event_names/?haccp_plan_pk=${haccp_plan_pk}`);
        }

        return this.api.post(`${this.version}/deviation_form/get_event_names/`);
    }
    objectToQueryParams(obj) {
          const queryParams = [];
          for (const key in obj) {
              if (Object.prototype.hasOwnProperty.call(obj, key)) {
                  queryParams.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
              }
          }
          return queryParams.length ? '?' + queryParams.join('&') : '';
      }
}

export default DeviationAPI;
