import React, { useEffect, useState, useContext} from "react"

import {
  Route,
  Switch,
  useParams,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from "@material-ui/core/LinearProgress";

import ContentHeader from "../../Components/Layouts/ContentHeader";


import API from "../../Api/Api"

const useStyles = makeStyles((theme) => { return {
  blue: {
    color: theme.palette.primary.main,
  }
}})

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
function Plan(props) {
  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(true);

  const classes = useStyles();

  let params = useParams();
  let history = useHistory();
  let match = useRouteMatch();

  useEffect(() => {
    let pk = params.pk || props.pk;

    if (pk) {
      new API().getPlanBuilderAPI().retrievePlan(pk).then(response => {
        setLoading(false);
        setPlan(response.data);
      }).catch(error => {
        console.log(error);
      });
    }
    else {
      new API().getPlanBuilderAPI().getActivePlan().then(response => {
        setPlan(response.data);
        setLoading(false)
      }).catch(error => {
        console.log(error);
      });
    }
  }, []);
  
  function deletePlan() {
    new API().getPlanBuilderAPI().deletePlan(params.plan_pk).then(response => {
      props.refreshPlans(true);
      history.replace("/plans");

      setLoading(true);
    }).catch(error => {
      console.log("error");
    });
  }

  function getPlanManager() {
    return (
      <Grid container style={{padding: "32px 32px 16px 32px"}}>
        <Grid item xs={12}>
          <Typography variant="h5">
            Plan Manager
          </Typography>
        </Grid>
      </Grid>
    )
  }

  function getActiveEventsProgress() {
    let progress = 0;
    
    if (plan.program_info.num_events) {
      progress = plan.program_info.num_active_events / plan.program_info.num_events;  
    }

    return progress *= 100; 
  }


  function getLinkedTasksProgress() {
    let progress = 0;

    if (plan.program_info.num_tasks) {
      progress = plan.program_info.num_linked_tasks / plan.program_info.num_tasks;
    }

    return progress *= 100;
  }

  function getProgram() {
    return (
      <Paper elevation={0} square style={{width: '100%', minHeight: '292px'}}>
        <Grid item container alignItems="center" xs={12} style={{borderBottom: '1px solid rgba(0, 0, 0, 0.12)', padding: '16px'}}>
          <Grid item xs={6}>
            <Typography variant="h6">
              {plan.program_info.name}
            </Typography>
          </Grid>
          <Grid item container xs={6} justify="flex-end" alignItems="center">
            <Button onClick={() => {history.push("/plans/manager/program")}} variant="outlined" color="primary">Edit Program</Button>
          </Grid>
        </Grid>
        <Grid container item spacing={1} xs={12} md={6} style={{padding: 16}}>
          <Grid item xs={12}>
            <Typography variant="h6">
              Overview
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {plan.program_info.description}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{marginTop: '16px'}}>
            <Typography style={{fontWeight: 500}} display="inline" variant="subtitle1">
              Events Active: {"    "}
            </Typography>
            <Typography display="inline" variant="body1">
              <span className="blue">{plan.program_info.num_active_events}</span> / {plan.program_info.num_events } 
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LinearProgress style={{maxWidth: '50%'}} variant="determinate" value={getActiveEventsProgress()}/>
          </Grid>
          <Grid item xs={12} style={{marginTop: '16px'}}>
            <Typography style={{fontWeight: 500}} display="inline" variant="subtitle1">
              Tasks Linked: { "    " }
            </Typography>
            <Typography display="inline" variant="body1">
              <span className="blue">{plan.program_info.num_linked_tasks}</span> / {plan.program_info.num_tasks}  
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LinearProgress style={{maxWidth: '50%'}} variant="determinate" value={getLinkedTasksProgress()}/>
          </Grid>
          <Grid item xs={12} style={{marginTop: '16px'}}>
            <Typography style={{fontWeight: 500}} display="inline" variant="subtitle1">
              Monitoring Forms: {"   "}
            </Typography>
            <Typography display="inline" variant="body1">
              {plan.program_info.num_completed_records} completed monitoring forms 
            </Typography>
          </Grid>
          <Grid item xs={12} >
            <Typography display="inline" variant="subtitle1">
              <b>Deviation Forms: </b>
            </Typography>
            <Typography display="inline" variant="body1">
              {plan.program_info.num_complete_deviations} complete / {plan.program_info.num_deviations} total
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    )
  }
  
  return (
    <div style={{width: '100%', height: '100%', padding: '0px'}}>
      <ContentHeader title="Plan Manager"/>

      {loading && 
      <Grid item container justify="center" alignItems="center" style={{height: '100vh'}}>
        <CircularProgress/>
      </Grid>
      }

      <Switch>
        <Route path={`${match.url}/`}>
          {/*!loading && 
          <Grid item xs={12} style={{padding: 16, borderBottom: "1px solid #eaeaea"}}>
            { getPlanManager() }
          </Grid>
          */}

          {!loading && plan &&
          <Grid item xs={12} style={{margin: 32}}>
            { getProgram() }
          </Grid>
          }
        </Route>
      </Switch>
    </div>
  )
}

export default Plan;

