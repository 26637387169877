import React, { useEffect, useState } from "react";

import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import { DialogContent } from "@material-ui/core";
import Hazard from "../Hazards/Hazard";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from '@material-ui/core/Button';
import ProductDescriptionModal from "./ProductDescriptionModal";
import HazardModal from "./HazardModal";
import IngredientModal from "./IngredientModal";
import FormulationModal from "./FormulationModal";
import ProcessModal from "./ProcessModal";
import ProcessFlowDiagramModal from "./ProcessFlowDiagramModal";
import CCPModal from "./CCPModal";
import TrafficFlowDiagramModal from "./TrafficFlowDiagramModal";
import API from "../../Api/Api";


function HistoryModal(props) {
  const [loading, setLoading] = useState(true);
  const [preview, setPreview] = useState(true);
  const [oldProductDescription, setOldProductDescription] = useState(true);

  const history_columns = [
    { title: "User", field: "profile", editable: 'never' },
    { title: "Date", field: "date", editable: 'never' },
    { title: "Type", field: "type", editable: 'never' },
  ];

  const [history, setHistory] = useState(null);
  useEffect(() => {
    if (props.open === true) {
      props.api.getHistory(props.pk).then(response => {
        setHistory(response.data);
        setLoading(false);
      }).catch(error => {
        console.log(error);
      });
    }

  }, [props.open]);

  function previewChanges(event, data) {
    if (props.api.constructor.name == "HazardAPI") {
      if (props.open === true) {
        let payload = {
          pk: props.pk,
          date: data.date
        }
        let api = new API();
        api.getHazardAPI().retrieveHazard(props.pk).then(response => {
          setOldHazard(response.data);
        }).catch(error => {
          console.log(error);
        });

        props.api.getRevertedHazard(props.pk, payload).then(response => {
          setPreview(response.data);
          changeBeforeHazard(response.data);

          handleModalOpen();
        }).catch(error => {
          console.log(error);
        });
      }
    }
    if (props.api.constructor.name == "ProductDescriptionsAPI") {
      if (props.open === true) {
        let payload = {
          pk: props.pk,
          date: data.date
        }
        let api = new API();
        api.getProductDescriptionAPI().retrieveProductDescription(props.pk).then(response => {
          setOldProductDescription(response.data);
        }).catch(error => {
          console.log(error);
        });

        props.api.getRevertedProductDescription(props.pk, payload).then(response => {
          setPreview(response.data);
          changeBeforeProductDescription(response.data);
          handleModalOpen();
        }).catch(error => {
          console.log(error);
        });
      }
    }
    if (props.api.constructor.name == "IngredientsAPI") {
      if (props.open === true) {
        let payload = {
          pk: props.pk,
          date: data.date
        }
        let api = new API();
        api.getIngredientsAPI().retrieveIngredient(props.pk).then(response => {
          setOldIngredient(response.data);
        }).catch(error => {
          console.log(error);
        });

        props.api.getRevertedIngredient(props.pk, payload).then(response => {
          setPreview(response.data);
          changeBeforeIngredient(response.data);

          handleModalOpen();
        }).catch(error => {
          console.log(error);
        });
      }
    }
    if (props.api.constructor.name == "FormulationsAPI") {
      if (props.open === true) {
        let payload = {
          pk: props.pk,
          date: data.date
        }
        let api = new API();
        api.getFormulationsAPI().retrieveFormulation(props.pk).then(response => {
          setOldFormulation(response.data);
        }).catch(error => {
          console.log(error);
        });

        props.api.getRevertedFormulation(props.pk, payload).then(response => {
          setPreview(response.data);
          changeBeforeFormulation(response.data);

          handleModalOpen();
        }).catch(error => {
          console.log(error);
        });
      }
    }
    if (props.api.constructor.name == "ProcessAPI") {
      if (props.open === true) {
        let payload = {
          pk: props.pk,
          date: data.date
        }
        let api = new API();
        api.getProcessAPI().retrieveProcess(props.pk).then(response => {
          setOldProcess(response.data);
        }).catch(error => {
          console.log(error);
        });

        props.api.getRevertedProcess(props.pk, payload).then(response => {
          setPreview(response.data);
          changeBeforeProcess(response.data);

          handleModalOpen();
        }).catch(error => {
          console.log(error);
        });
      }
    }
    if (props.api.constructor.name == "ProcessFlowDiagramAPI") {
      if (props.open === true) {
        let payload = {
          pk: props.pk,
          date: data.date
        }
        handleModalOpen();
        let api = new API();
        api.getProcessFlowDiagramAPI().retrieveProcessFlowDiagram(props.pk).then(response => {
          setOldProcessFlowDiagram(response.data);
        }).catch(error => {
          console.log(error);
        });

        props.api.getRevertedProcessFlowDiagram(props.pk, payload).then(res => {
          setPreview(res.data);
          changeBeforeProcessFlowDiagram(res.data);

          handleModalOpen();
        }).catch(error => {
          console.log(error);
        });
      }
    }
    if (props.api.constructor.name == "CrossContaminationPointAPI") {
      if (props.open === true) {
        let payload = {
          pk: props.pk,
          date: data.date
        }
        let api = new API();
        api.getCrossContaminationPointAPI().retrieveCrossContaminationPoint(props.pk).then(response => {
          setOldCCP(response.data);
        }).catch(error => {
          console.log(error);
        });

        props.api.getRevertedCCP(props.pk, payload).then(res => {
          setPreview(res.data);
          changeBeforeCCP(res.data);

          handleModalOpen();
        }).catch(error => {
          console.log(error);
        });
      }
    }
    if (props.api.constructor.name == "TrafficFlowDiagramAPI") {
      if (props.open === true) {
        let payload = {
          pk: props.pk,
          date: data.date
        }
        handleModalOpen();
        let api = new API();
        api.getTrafficFlowDiagramAPI().retrieveTrafficFlowDiagram(props.pk).then(response => {
          setOldTrafficFlowDiagram(response.data);
        }).catch(error => {
          console.log(error);
        });

        props.api.getRevertedTrafficFlowDiagram(props.pk, payload).then(res => {
          setPreview(res.data);
          changeBeforeTrafficFlowDiagram(res.data);
          handleModalOpen();
        }).catch(error => {
          console.log(error);
        });
      }
    }
  }
  const [productDescriptionModalOpen, setproductDescriptionModalOpen] = useState(false);
  const [hazardModalOpen, setHazardModalOpen] = useState(false);
  const [ingredientModalOpen, setIngredientModalOpen] = useState(false);
  const [formulationModalOpen, setFormulationModalOpen] = useState(false);
  const [processModalOpen, setProcessModalOpen] = useState(false);
  const [processFlowDiagramModalOpen, setProcessFlowDiagramModalOpen] = useState(false);
  const [CCPModalOpen, setCCPModalOpen] = useState(false);
  const [trafficFlowDiagramModalOpen, setTrafficFlowDiagramModalOpen] = useState(false);

  const [beforeProductDescription, changeBeforeProductDescription] = useState(false);
  const [beforeHazard, changeBeforeHazard] = useState(false);
  const [beforeIngredient, changeBeforeIngredient] = useState(false);
  const [beforeFormulation, changeBeforeFormulation] = useState(false);
  const [beforeProcess, changeBeforeProcess] = useState(false);
  const [beforeProcessFlowDiagram, changeBeforeProcessFlowDiagram] = useState(false);
  const [beforeCCP, changeBeforeCCP] = useState(false);
  const [beforeTrafficFlowDiagram, changeBeforeTrafficFlowDiagram] = useState(false);

  const [oldHazard, setOldHazard] = useState(false);
  const [oldIngredient, setOldIngredient] = useState(false);
  const [oldFormulation, setOldFormulation] = useState(false);
  const [oldProcess, setOldProcess] = useState(false);
  const [oldProcessFlowDiagram, setOldProcessFlowDiagram] = useState(false);
  const [oldCCP, setOldCCP] = useState(false);
  const [oldTrafficFlowDiagram, setOldTrafficFlowDiagram] = useState(false);


  let [shouldClose, setShouldClose] = useState(false);


  function handleModalOpen() {
    if (props.api.constructor.name === "ProductDescriptionsAPI") {
      setproductDescriptionModalOpen(true);
    }
    if (props.api.constructor.name === "HazardAPI") {
      setHazardModalOpen(true);
    }
    if (props.api.constructor.name === "IngredientsAPI") {
      setIngredientModalOpen(true);
    }
    if (props.api.constructor.name === "FormulationsAPI") {
      setFormulationModalOpen(true);
    }
    if (props.api.constructor.name === "ProcessAPI") {
      setProcessModalOpen(true);
    }
    if (props.api.constructor.name === "ProcessFlowDiagramAPI") {
      setProcessFlowDiagramModalOpen(true);
    }
    if (props.api.constructor.name === "CrossContaminationPointAPI") {
      setCCPModalOpen(true);
    }
    if (props.api.constructor.name === "TrafficFlowDiagramAPI") {
      setTrafficFlowDiagramModalOpen(true);
    }
  }

  function handleModalClosed() {
    if (props.api.constructor.name === "ProductDescriptionsAPI") {
      setproductDescriptionModalOpen(false);
      props.handleModalClosed();
    }
    if (props.api.constructor.name === "HazardAPI") {
      setHazardModalOpen(false);
      props.handleModalClosed();
    }
    if (props.api.constructor.name === "IngredientsAPI") {
      setIngredientModalOpen(false);
      props.handleModalClosed();
    }
    if (props.api.constructor.name === "FormulationsAPI") {
      setFormulationModalOpen(false);
      props.handleModalClosed();
    }
    if (props.api.constructor.name === "ProcessAPI") {
      setProcessModalOpen(false);
      props.handleModalClosed();
    }
    if (props.api.constructor.name === "ProcessFlowDiagramAPI") {
      setProcessFlowDiagramModalOpen(false);
      props.handleModalClosed();
    }
    if (props.api.constructor.name === "CrossContaminationPointAPI") {
      setCCPModalOpen(false);
      props.handleModalClosed();
    }
    if (props.api.constructor.name === "TrafficFlowDiagramAPI") {
      setTrafficFlowDiagramModalOpen(false);
      props.handleModalClosed();
    }
  }

  return (
    <div style={{ maxWidth: "100%" }}>

      <Dialog
        open={props.open}
        onClose={props.handleModalClosed}
      >
        <DialogContent>
          {loading &&
            <CircularProgress />
          }

          {!loading &&
            <MaterialTable
              actions={[
                {
                  icon: 'save',
                  tooltip: 'Revert Changes',
                  onClick: (event, data) => previewChanges(event, data)
                }
              ]}

              components={{
                Action: props => (
                  <Button
                    onClick={(event) => props.action.onClick(event, props.data)}
                    color="primary"
                    variant="contained"
                    style={{ textTransform: 'none' }}
                    size="small"
                  >
                    Preview
                  </Button>
                ),


                Container: props => <Paper {...props} elevation={0} />
              }} isLoading={loading} title="History" columns={history_columns} data={history}
              style={{ width: "100%" }}
            />
          }
        </DialogContent>
      </Dialog>
      <ProductDescriptionModal
        setShouldClose={setShouldClose}
        setProductDescription={props.setProductDescription}
        beforeProductDescription={beforeProductDescription}
        open={productDescriptionModalOpen}
        setStatus={props.setStatus}
        handleModalClosed={handleModalClosed}
        oldProductDescription={oldProductDescription}
      />
      <HazardModal
        setShouldClose={setShouldClose}
        setHazard={props.setHazard}
        beforeHazard={beforeHazard}
        open={hazardModalOpen}
        setStatus={props.setStatus}
        handleModalClosed={handleModalClosed}
        oldHazard={oldHazard}
      />
      <IngredientModal
        setShouldClose={setShouldClose}
        setIngredient={props.setIngredient}
        beforeIngredient={beforeIngredient}
        open={ingredientModalOpen}
        setStatus={props.setStatus}
        handleModalClosed={handleModalClosed}
        oldIngredient={oldIngredient}
      />
      <FormulationModal
        setShouldClose={setShouldClose}
        setFormulation={props.setFormulation}
        beforeFormulation={beforeFormulation}
        open={formulationModalOpen}
        setStatus={props.setStatus}
        handleModalClosed={handleModalClosed}
        oldFormulation={oldFormulation}
      />
      <ProcessModal
        setShouldClose={setShouldClose}
        setProcess={props.setProcess}
        beforeProcess={beforeProcess}
        save={props.save}
        open={processModalOpen}
        handleModalClosed={handleModalClosed}
        oldProcess={oldProcess}
      />
      <ProcessFlowDiagramModal
        setShouldClose={setShouldClose}
        setProcessFlowDiagram={props.setProcessFlowDiagram}
        beforeProcessFlowDiagram={beforeProcessFlowDiagram}
        saveData={props.saveData}
        setupCanvas={props.setupCanvas}
        canvas={props.canvas}
        open={processFlowDiagramModalOpen}
        initializeCanvas={props.initializeCanvas}
        changeMode={props.changeMode}
        setStatus={props.setStatus}
        gridEnabled={props.gridEnabled}
        addProcessListeners={props.addProcessListeners}
        handleModalClosed={handleModalClosed}
        handleModalOpen={handleModalOpen}
        oldProcessFlowDiagram={oldProcessFlowDiagram}
      />
      <TrafficFlowDiagramModal
        setTrafficFlowDiagram={props.setTrafficFlowDiagram}
        beforeTrafficFlowDiagram={beforeTrafficFlowDiagram}
        setupCanvas={props.setupCanvas}
        open={trafficFlowDiagramModalOpen}
        setStatus={props.setStatus}
        gridEnabled={props.gridEnabled}
        setupCanvas={props.setupCanvas}
        handleModalClosed={handleModalClosed}
        handleModalOpen={handleModalOpen}
        oldTrafficFlowDiagram={oldTrafficFlowDiagram}
      />
      <CCPModal
        setShouldClose={setShouldClose}
        setCCP={props.setCCP}
        beforeCCP={beforeCCP}
        save={props.save}
        setStatus={props.setStatus}
        open={CCPModalOpen}
        handleModalClosed={handleModalClosed}
        handleModalOpen={handleModalOpen}
        oldCCP={oldCCP}
      />
    </div>
  )
}

export default HistoryModal;
