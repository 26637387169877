import React, { useEffect, useState } from "react";

import GroupedTable from "../../Components/GroupedTable/GroupedTable";
import API from "../../Api/Api";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Traceability from "../Traceability";
import TraceabilityUtils from "../TraceabilityUtils";
import Message from "../../Components/Message";
import ItemInstanceForm from "./ItemInstanceForm";
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import {StylesContext} from "../../App";

const utils = new TraceabilityUtils();

const EMPTY_MESSAGE = {
  open: false,
  message: '',
  status: 'info',
};

export default function ItemInstanceTable(props) {
  const classes = React.useContext(StylesContext);

  const [itemInstances, setItemInstances] = useState(false);
  const [loading, setLoading] = useState(true);
  const [itemInstanceModalOpen, setItemInstanceModalOpen] = useState(false);
  const [itemInstanceSelected, setItemInstanceSelected] = useState(-1);
  const [shouldClose, setShouldClose] = useState(true);
  const [message, setMessage] = useState( {...EMPTY_MESSAGE} );
  const [user, setUser] = useState(null);
  const [skus, setSkus] = useState(false);
  const [units, setUnits] = useState(false);
  const [internalLotCodes, setInternalLotCodes] = useState(false);
  const [inventoryLocations, setInventoryLocations] = useState(false);

  useEffect(() => {
    const api = new Traceability().getInstanceItemAPI();
    const authApi = new API().getAuthAPI();
    const skuApi = new Traceability().getSkuApi();
    const unitApi = new Traceability().getUnitsAPI();
    const internalLotCodeApi = new Traceability().getInternalLotCodeAPI();
    const inventoryLocationApi = new Traceability().getInventoryLocationAPI();

    authApi.getAuthedProfile().then(e => {
      setUser(e.data);
    }).catch(e => {
      //console.log(e);
    })

    if(props.itemInstances) {
      let itemInstanceObjects = props.itemInstances;

      setItemInstances(itemInstanceObjects);
    }
    else {
      api.listItemInstances().then(e => {
        let itemInstanceObjects = e.data;
        setItemInstances(itemInstanceObjects);

        if(props.units) {
          setUnits(props.units);
        }
        else {
          utils.pkIndexObjectsFromApi(unitApi.listUnits.bind(unitApi), setUnits);
        }
      }).catch(e => {
        //console.log('BE Error: ' + e);
      })
    }

    if(props.skus) {
      let pkIndexedSkus = {};
      props.skus.forEach((skuObject) => { 
        pkIndexedSkus[skuObject.pk] = {...skuObject};
      });
      setSkus(pkIndexedSkus);
    }
    else {
      pkIndexObjectsFromApi(skuApi.listSkusDisplay.bind(skuApi), setSkus)
    }

    if(props.internalLotCodes) {
      let pkIndexedObjects = {};
      props.internalLotCodes.forEach((newObject) => {
        pkIndexedObjects[newObject.pk] = { ...newObject }
      });
      setInternalLotCodes(pkIndexedObjects);
    }
    else{
      pkIndexObjectsFromApi(internalLotCodeApi.listInternalLotCodesDisplay.bind(internalLotCodeApi), setInternalLotCodes);
    }

    if(props.inventoryLocations) {
      let pkIndexedObjects = {};
      props.inventoryLocations.forEach((newObject) => {
        pkIndexedObjects[newObject.pk] = { ...newObject }
      });
      setInventoryLocations(pkIndexedObjects);
    }
    else {
      pkIndexObjectsFromApi(inventoryLocationApi.listInventoryLocationsDisplay.bind(inventoryLocationApi), setInventoryLocations);
    }

    setLoading(false);

  }, []);

  useEffect(() => {
    if(loading) {
      return;
    }

    setItemInstanceModalOpen(true);
  }, [itemInstanceSelected])

  useEffect(() => {


    //console.log('xsd inventoryLocations: ' + JSON.stringify(inventoryLocations))
  }, [inventoryLocations])

  function pkIndexObjectsFromApi(listFunction, setFunction, dataMassageFunction) {
    listFunction().then(e => {
      let pkIndexedObjects = {};

      e.data.forEach((newObject) => {
        pkIndexedObjects[newObject.pk] = dataMassageFunction ? {...dataMassageFunction(newObject)} : {...newObject};
      });

      setFunction(pkIndexedObjects);
    }).catch(e => {
      //console.log(e);
    })
  }

  function formatTableData(tableData) {
    let formattedTableData = [];
    tableData.forEach((dataPoint) => {
      let formattedPoint = {};
      //console.log('xsd dataPoint: ' + JSON.stringify(dataPoint))
      
      formattedPoint.sku = dataPoint.sku && skus[dataPoint.sku] ? skus[dataPoint.sku].name : null;
      formattedPoint.quantity = dataPoint.unit_value_on_hand + ' ' + units[dataPoint.unit_type].abbreviation;
      formattedPoint.inTransit = dataPoint.unit_value_in_transit + ' ' + units[dataPoint.unit_type].abbreviation;
      formattedPoint.inReserve = dataPoint.unit_value_reserved + ' ' + units[dataPoint.unit_type].abbreviation;
      formattedPoint.inOutgoing = dataPoint.unit_value_in_outgoing + ' ' + units[dataPoint.unit_type].abbreviation;
      formattedPoint.inventory_location = dataPoint.inventory_location ? inventoryLocations[dataPoint.inventory_location].name : 'None';
      //formattedPoint.quantityInTransit = dataPoint.unit_value_in_transit + ' ' + units[dataPoint.unit_type].abbreviation;
      formattedPoint.pk = dataPoint.pk;
      formattedTableData.push(formattedPoint);
    })

    return formattedTableData;
  }

  function handleItemInstanceClosed() {
    if (shouldClose) {
      setItemInstanceModalOpen(false);
    } else {
      alert("Save changes before closing.");
    }
  }

  /**
   * Handles keeping the list up to date with the back end, including updating all lines.
   * 
   * @param {JSON} response - a response object from the server
   */
  function onSave(response) {
    setLoading(true)

    let updatedItemInstancePk = response.data.pk;
    let updatedItemInstances = [...itemInstances];
    let newInstance = { ...response.data }

    let itemIndex = updatedItemInstances.findIndex(itemInstance => {
      return itemInstance.pk === updatedItemInstancePk;
    });

    if (itemIndex > -1) {
      updatedItemInstances.splice(itemIndex, 1, newInstance);
    }
    else {
      updatedItemInstances.push(newInstance);
    }

    //setItemInstances([]);
    setItemInstances(updatedItemInstances);
    ////console.log('Setting to table: ' + JSON.stringify(updatedItemInstances));
    setMessage({
      open: true,
      message: 'Saved Successfully',
      status: 'success',
    });
  }

  return(
    <>
      { (!itemInstances || !skus || !internalLotCodes || !inventoryLocations || !units) &&
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginTop: "250px" }}>
            <Typography>
              <CircularProgress />
            </Typography>
          </Grid>
        </Grid>
      }

      { (itemInstances && skus && internalLotCodes && inventoryLocations && units) &&
        <Paper elevation={0} square variant="outlined" className={classes.generalListPaperContainer} >
          <GroupedTable 
            data={formatTableData(itemInstances)}
            formatData={formatTableData}
            draggable={!loading}
            title={'Inventory'}

            /*onRowClick={(event, rowData, togglePanel) => {
              return;// for now this isn't needed, but we can turn it on quickly.
              ////console.log(JSON.stringify(rowData));
              let newIndex = matchOnPk(itemInstances, rowData.pk, '_index_')
              ////console.log('newIndex: ' + newIndex);

              if(itemInstanceSelected != newIndex) {
                setItemInstanceSelected(newIndex);
              }
              else {
                setItemInstanceModalOpen(true);
              }
            }}*/ 
          />

          <Dialog open={itemInstanceModalOpen} onClose={handleItemInstanceClosed} maxWidth={"lg"}>
            <DialogContent>
              <ItemInstanceForm 
                //handleSubmit={() => {setShouldClose(true); //console.log('I am save, also finish fx')}}
                itemInstance={itemInstanceSelected >= 0 ? itemInstances[itemInstanceSelected] : {}}
                units={units}
                setShouldClose={setShouldClose}
                user={user}
                onSave={onSave}
                closeModal={() => { setItemInstanceModalOpen(false) }}
                activeTraceability={props.activeTraceability}
                skus={skus}
                internalLotCodes={internalLotCodes}
                inventoryLocations={inventoryLocations}
              />
            </DialogContent>
          </Dialog>

          <Message
            open={message.open}
            message={message.message}
            severity={message.status}
            vertical="bottom"
            horizontal="right"
            handleClose={() => {setMessage( {...message, open:false} ); setLoading(false) }}
          />
        </Paper>
      }
    </>
  )
}

ItemInstanceTable.propTypes = {
  itemInstances: PropTypes.arrayOf(PropTypes.object),
  skus: PropTypes.arrayOf(PropTypes.object),
  units: PropTypes.objectOf(PropTypes.object),
  internalLotCodes: PropTypes.arrayOf(PropTypes.object),
  inventoryLocations: PropTypes.arrayOf(PropTypes.object),
  activeTraceability : PropTypes.object.isRequired
}