import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import API from "../../Api/Api";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';
import ImageUploader from '../../Components/Images/ImageUploader';
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { useContext } from "react";
import { OrgContext } from "hooks/useOrganization"

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalDisplay: {
    padding: '20px',
    width: '50%',
    overflowY: 'scroll',
    height: '600px',
  }
}));

export default function TrafficFlowDiagramImageManager(props) {
  const classes = useStyles();
  const org = useContext(OrgContext);

  const [open, setOpen] = React.useState(props.open);
  const [actions, setActions] = React.useState(props.actions);


  return (
    
  <Dialog open={props.open}>
    <DialogContent>
    <Modal
      aria-labelledby='Image-manager'
      className={classes.modal}
      open={open}
      onClose={() => {/*setOpen(false);*/ props.onClose ? props.onClose() : setOpen(false)}}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{timeout:800}}
    >
      <Fade in={open}>
        <Paper elevation={3} className={classes.modalDisplay}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h6'>
                Facility Layout
              </Typography>
              <Typography>
                Upload an image of the facility layout to be used as the background for this diagram.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ImageUploader
                api={new API().getImagesAPI()}
                showManager={true}
                showUploader={true}
                cardStyle={props.cardStyle ? props.cardStyle : {width: '200px', height: '250px',}}
                actions={actions}
                uploadAction={props.uploadAction}
                filesLimit={1}
                org={org}
              />
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Modal>
    </DialogContent>
  </Dialog>
  )
}

TrafficFlowDiagramImageManager.propTypes = {
  open: PropTypes.bool,
  cardStyle: PropTypes.object, //CSS object
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func // receives (imageURL, imageName, pk)
  })),
}