import React, { useState } from "react";

import Alert from '@material-ui/lab/Alert';

import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

export default function ProgramManager(props) {
  return (
    <Grid container item xs={12}>
      <Paper elevation={0} style={{ padding: '16px', width: '100%', backgroundColor: '#F8F8F8', borderBottom: '1px solid lightgrey' }} square>
        <Grid container spacing={3} alignItems="center">
          <Grid container item xs={12} sm={6} alignItems="center">
            <Typography variant="h6">
              {props.program.name}
            </Typography>
          </Grid>
          <Grid container item xs={12} sm={6} alignItems="center" justify="flex-end" spacing={3}>
            {props.hasUnsavedChanges &&
              <Alert severity="info" style={{ marginRight: '8px' }}>This program has unsaved changes</Alert>
            }
            {!props.readOnly && props.editMode &&
            <>
              <Button startIcon={props.saving ? <CircularProgress size={20} style={{ color: 'white' }} /> : null}
                variant="contained" color="primary" size="small" onClick={props.save}>
                Save
              </Button>
              <IconButton color="secondary" aria-label="Cancel" onClick={() => {props.setEditMode(false)}}>
                <CloseIcon />
              </IconButton>
            </>
            }

            {!props.readOnly && !props.editMode &&
            <>
              <IconButton aria-label="Edit" size="small" style={{ padding: "8px" }} onClick={() => {props.setEditMode(true)}}>
                <EditIcon />
              </IconButton>             
            </>
            }
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}
