import React, { useEffect, useState } from 'react';
import { makeStyles} from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import PropTypes from "prop-types";
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import {StylesContext} from "../../App";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '95%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  cell: {
    border: '1px solid black',
    padding: '5px',
  },
  grid: {
    width: '100%',
  },
  select: {
    width: '100%',
  },
}));
  
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function DetailLine(props) {
  //const classes = useStyles();
  const classes = React.useContext(StylesContext);

  function handleChange(event)  {
    props.setlineData({...props.lineData, [event.target.name]: event.target.value});
  }

  function cancelLine() {
    props.setlineData({...props.lineData, detailSelected: false});
  }

  //Make fields for the line.
  function makeFields() {
    return props.lineInfo.map((fieldObj, i) => {
      if(fieldObj.type == 'text') {
        return (
          <Grid key={i} item xs>
            <TextField
              name={fieldObj.key}
              label={fieldObj.title}
              error={fieldObj.validate ? fieldObj.validate(props.lineData[fieldObj.key]) : false}
              fullWidth
              value={props.lineData[fieldObj.key] || ''}
              onChange={handleChange}
              disabled={props.disabled || fieldObj.disabled}
            />
          </Grid>
        )
      }
      else if(fieldObj.type == 'numeric') {
        return (
          <Grid key={i} item xs>
            <TextField
              name={fieldObj.key}
              label={fieldObj.title}
              fullWidth
              error={fieldObj.validate ? fieldObj.validate(props.lineData[fieldObj.key]) : false}
              value={props.lineData[fieldObj.key]}
              onChange={handleChange}
              disabled={props.disabled || fieldObj.disabled}
            />
          </Grid>
        )
      }
      else if(fieldObj.type == 'select') {
        return (
          <Grid item xs>
            <FormControl className={classes.detailFormControl}>
              <InputLabel id="demo-mutiple-chip-label">{fieldObj.title}</InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                id={fieldObj.key}
                name={fieldObj.key}
                error={fieldObj.validate ? fieldObj.validate(props.lineData[fieldObj.key]) : false}
                className={classes.detailSelect}
                value={props.lineData[fieldObj.key] || null}
                onChange={handleChange}
                disabled={props.disabled || fieldObj.disabled}
              >
                {Object.keys(fieldObj.choices).map((choiceKey) => (
                  <MenuItem key={choiceKey} value={choiceKey}>
                    {fieldObj.choices[choiceKey]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )
      }
      //A select field where the choices are based on another field on the line.
      else if(fieldObj.type == 'selectRelative') {
        return (
          <Grid item xs>
            <FormControl className={classes.detailFormControl}>
              <InputLabel id="demo-mutiple-chip-label">{fieldObj.title}</InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                id={fieldObj.key}
                name={fieldObj.key}
                error={fieldObj.validate ? fieldObj.validate(props.lineData[fieldObj.key]) : false}
                className={classes.detailSelect}
                value={props.lineData[fieldObj.key]}
                onChange={handleChange}
                disabled={props.disabled || fieldObj.disabled}
              >
                {Object.keys(props.lineData[fieldObj.choicesKey]).map((choiceKey) => (
                  <MenuItem key={choiceKey} value={choiceKey}>
                    {props.lineData[fieldObj.choicesKey][choiceKey]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )
      }
      else if(fieldObj.type == 'multiselect') {
        return (
          <Grid item xs>
          <FormControl className={classes.detailFormControl}>
            <InputLabel>{fieldObj.title}</InputLabel>
            <Select
              id={fieldObj.key}
              name={fieldObj.key}
              multiple
              className={classes.detailSelect}
              fullWidth
              value={props.lineData[fieldObj.key]}
              onChange={handleChange}
              error={fieldObj.validate ? fieldObj.validate(props.lineData[fieldObj.key]) : false}
              disabled={props.disabled || fieldObj.disabled}
              input={<Input id="select-multiple-chip" disabled={props.disabled} />}
              renderValue={(selected) => (
                <div className={classes.detailChips}>
                  {selected.map((value) => (
                    <Chip key={value} label={fieldObj.choices[value]} className={classes.detailChip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {Object.keys(fieldObj.choices).map((choiceKey) => (
                <MenuItem key={choiceKey} value={choiceKey}>
                  {fieldObj.choices[choiceKey]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Grid>
        )
      }
      else if(fieldObj.type == 'display') {
        return (
          <Grid item xs>
            <Typography noWrap>
              {fieldObj.title}
            </Typography>
            {props.lineData[fieldObj.key]}
          </Grid>
        )
      }
    })
  }

  return (
    <>
      {props.lineInfo &&
        <Paper elevation={0} style={{backgroundColor: props.faded ? 'grey' : '#FFF'}}>
          <Grid container spacing={3} className={classes.detailGrid}>
            {makeFields()}
            <Grid item xs={1}>
              <IconButton
                variant="contained"
                color="primary"
                style={{ margin: "8px" }}
                disabled={props.disabled}
                onClick={() => { cancelLine() }}
              >
                <CancelIcon />
              </IconButton>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                variant="contained"
                color="primary"
                style={{ margin: "8px" }} 
                disabled={props.disabled}
                onClick={() => {props.onDetailClick(props.rowData, props.setlineData, props.setRowData, props.lineData)}}
              >
                <EditIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      }
    </>
  )
}

DetailLine.propTypes = {
  lineData: PropTypes.object,
  rowData: PropTypes.object,
  setlineData: PropTypes.func,
  setRowData: PropTypes.func,
  onDetailClick: PropTypes.func,
  lineInfo: PropTypes.object,
  disabled: PropTypes.bool,
  faded: PropTypes.bool,
}