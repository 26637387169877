import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import API from "../../Api/Api";
import { useParams, Link } from "react-router-dom";
import GeneralApp404 from "../../Main/GeneralApp404"

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © FoodByte '}
            {/*<Link color="inherit" href="https://material-ui.com/">*/}
            {/*</Link>{' '}*/}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function PasswordReset(props) {
  const classes = useStyles();
  const api = new API();
  const currentParams = useParams();

  const [passwordData, setPasswordData] = useState({password: null, 
    confirm_password: null, 
    token: currentParams.uuid,
    hash: currentParams.hash});
  const [show404, setShow404] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState(null);
  const [confirmPasswordErrors, setConfirmPasswordErrors] = useState(null);
  const [success, setSuccess] = useState(false);

  function handleFieldChange(event) {
    const name = event.target.name;
    setPasswordData({
        ...passwordData,
        [name]: event.target.value,
    });
  }

  useEffect(() => {
    api.getAuthAPI().checkPasswordResetRequest({token: currentParams.uuid, hash: currentParams.hash}).then(() => {
      setShowForm(true);
    }).catch(e => {
      setShow404(true);
      console.log(e);
    });
  }, []);

  function handleSubmit(){
    api.getAuthAPI().resetPasswordConfirm(passwordData).then(() => {
      setSuccess(true);
      localStorage.clear();
    }).catch(e => {
      setPasswordErrors(e.response.data.password);
      setConfirmPasswordErrors(e.response.data.confirm_password);
    });
  }

    

  return (
    <React.Fragment>
      {show404 && <GeneralApp404></GeneralApp404>}
      {showForm &&
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
              <Avatar className={classes.avatar} alt="FoodByte Logo" src="logos/FB_Logo_Reversed_RGB_Icon.png"/>
              <Typography component="h1" variant="h5">
                  FoodByte
              </Typography>
              {success ? 
                  <React.Fragment>
                       <Typography component="h1" variant="h6" align="center">
                     Your password has be updated!
                 </Typography>
     
                 <Link to="/login">
     
                   <Button
                     fullWidth
                     variant="contained"
                     color="primary"
                     className={classes.submit}
                   >
                     Login Now
                   </Button>
                 </Link>
                  </React.Fragment>
                :
                
              <form className={classes.form}>
              <Typography component="h1" variant="h6" align="center">
                  Enter your new password below
              </Typography>
                <TextField
                      error={passwordErrors ? true : false}
                      helperText={passwordErrors}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      onChange={handleFieldChange}
                  />

                  <TextField
                      error={confirmPasswordErrors ? true :  false}
                      helperText={confirmPasswordErrors}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="confirm_password"
                      label="Confirm Password"
                      type="password"
                      id="confirm_password"
                      autoComplete="current-password"
                      onChange={handleFieldChange}
                  />

  

                  <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handleSubmit}
                  >
                      Change Password
                  </Button>
              </form>
          }
          </div>
          <Box mt={8}>
              <Copyright />
          </Box>
      </Container>
    }   
    
    </React.Fragment>
  );
}

