import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams, Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import API from "../../Api/Api";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    borderBottom: "1px solid #eaeaea",
  },
  cardHeaderName: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "21px",
    letterSpacing: "0.1px",
    color: "#687895",
  },
  cardHeaderDescription: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0.1px",
    color: "#687895",
  },
  actionButton: {
    marginLeft: "auto",
    marginRight: "16px",
    marginBottom: "12px",
  },
  cardStyle: {
    margin: "24px 0px 24px 0px",
  },
}));

export default function OnboardingTask(props) {
  const history = useHistory();
  const classes = useStyles();

  const [completed, setCompleted] = useState(false);

  function markAsComplete(task) {
    if (props.markAsComplete) {
      props.markAsComplete(task);
    }
    if (props.onboardingInputs) {
      props.onboardingInputs.forEach((input) => {
        if (input.task.id === props.task.id) {
          if (input.completed) {
            setCompleted(false);
          } else {
            setCompleted(true);
          }
        }
      });
    }
  }
  useEffect(() => {
    if (props.onboardingInputs) {
      props.onboardingInputs.forEach((input) => {
        if (input.task.id === props.task.id) {
          if (input.completed) {
            setCompleted(true);
          } else {
            setCompleted(false);
          }
        }
      });
    }
  }, [props.onboardingInputs]);

  return (
    <Card elevation={0} square className={classes.cardStyle}>
      <CardHeader
        title={
          <Typography className={classes.cardHeaderName}>
            {props.task.name}{" "}
          </Typography>
        }
        className={classes.cardHeader}
        action={
          <IconButton
            style={{ marginTop: "6px" }}
            onClick={() => markAsComplete(props.task)}
            aria-label="mark_as_complete"
          >
            {completed ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
          </IconButton>
        }
      />
      {props.task.description && (
        <CardContent>
          <Typography className={classes.cardHeaderDescription}>{props.task.description}</Typography>
        </CardContent>
      )}
      <CardActions>
        {props.task.action_url !== "#" && 
        <Button
          variant="outlined"
          color="primary"
          className={classes.actionButton}
          //target="_blank" 
          href={props.task.action_url}
        >
          {props.task.action_title}
        </Button>
        }
      </CardActions>
    </Card>
  );
}
