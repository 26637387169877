import React, {useState} from "react";
import {Document, Page, pdfjs} from 'react-pdf'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
  Grid,
  Typography,
  IconButton,
  Chip,
} from "@material-ui/core";
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;














/**
 * A pdf previewing component. Comes with a built in paging component.
 * 
 * @param {*} props - Object: An object of the form 
 *  {
 *    url: The url of the pdf to display.
 *    loader: A component to display while the document or it's pages are loading.
 *  }
 */
function ExportPreview(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);

  /**
   * Grabs the total number of pages in the document when it has completed loading
   * 
   * @param {*} numPages - Integer: The total number of pages from the document, passed in by the system.
   */
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  /**
   * Jumps the view by jump pages. Has modulo built in to ensure boudaries are respected.
   * 
   * @param {*} jump - Integer: Number of pages to jump. Accepts negatives for going backwards.
   */
  function jumpPage(jump) {
    setPageNumber( properModulo((pageNumber + jump), numPages) );
  }

  /**
   * Needed since default JS modulo doesn't correctly process negatives.
   * Returns n modulo m.
   * 
   * @param {*} n - Integer: An integer to be reduced
   * @param {*} m - Integer: An integer to be the modulo
   */
  function properModulo(n,m) {
    return ((n % m) + m) % m;
  }

  /**
   * Returns the pdf in it's previewer, or a text box saying there's no pdf currently loaded.
   */
  const loadPdf = () => {
    if(props.url) {
      return (
        <Grid alignItems='center' alignContent='center' justify='center'>
          <Grid item xs={12}>
            <div style={{height: '730px', overflowX: 'scroll',}}>
              <Document
                file={props.url}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={props.loader}
              >
                <Page 
                  pageNumber={pageNumber + 1} height={710}
                  loading={props.loader}
                />
              </Document>
            </div>
          </Grid>

          <Grid style={{width:'100%', textAlign:'center'}} item xs={12}>
            <IconButton aria-label="page down" onClick={() => {jumpPage(-1)}} >
              <ArrowBackIcon />
            </IconButton>
            <Chip label={'Page ' + (pageNumber + 1) + ' of ' + numPages}  />
            <IconButton aria-label="page up" onClick={() => {jumpPage(1)}}>
              <ArrowForwardIcon />
            </IconButton>
          </Grid>
        </Grid>
      );
    }
    else {
      return <Typography> No pdf loaded. </Typography>
    }
  }

  return loadPdf();
}

export default ExportPreview;