import React, { memo, useCallback } from "react";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";

import CustomTextField from "Monitoring/CustomFields/CustomTextField";

import { makeStyles } from "@material-ui/core/styles";

import debounce from 'lodash.debounce';

import CustomNumberField from "Monitoring/CustomFields/CustomNumberField";
import CustomRadioButtonField from "Monitoring/CustomFields/CustomRadioButtonField";
import CustomDateTimeField from "Monitoring/CustomFields/CustomDateTimeField";
import CustomSelectField from "Monitoring/CustomFields/CustomSelectField";
import CustomCheckboxField from "Monitoring/CustomFields/CustomCheckboxField";

const useStyles = makeStyles((theme) => ({
	fakeLink: {
		color: "blue",
		textDecoration: "underline",
		cursor: "pointer",
		fontSize: '12px'
	},
}));

function TaskRow({ task, submissions, overdue, taskErrors, fieldErrors, readOnly, handleRadioButton, handleObservations, handleCustomField, setTaskSelected }) {
	const taskError = taskErrors.includes(task.id);
	const classes = useStyles();

	const debouncedObservations = useCallback( // will be created only once initially
		debounce(event => handleObservations(event), 100), [],
	);

	const handleObservationsChange = event => {
		event.persist()
		debouncedObservations(event);
	};

	return (
		<tr valign="top" key={task.id}>
			<TableCell component="th" scope="row">
				<b>{task.name}</b>
				<br></br>
				<p>{task.description} {" "}
					<span
						className={classes.fakeLink}
						onClick={() => {
							setTaskSelected(task);
						}}
					>
						(Read SOP)
					</span>
				</p>
			</TableCell>
			<TableCell align="left">
				{task.read_custom_fields && task.read_custom_fields.map(customField => {
					return (<>
						{customField.field_type === "textfield" &&
							<CustomTextField
								style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
								error={fieldErrors && fieldErrors.includes(customField.id)}
								id={customField.id}
								name={customField.name}
								displayName={customField.name}
								description={customField.description}
								readOnly={readOnly}
								required={customField.required}
								fullWidth
								oneRow
								onChange={event => handleCustomField(event, task, customField)}
							/>
						}

						{customField.field_type === "number" &&
							<CustomNumberField
								style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
								textFieldClasses={classes}
								error={fieldErrors && fieldErrors.includes(customField.id)}
								id={customField.id}
								name={customField.name}
								displayName={customField.name}
								description={customField.description}
								readOnly={readOnly}
								required={customField.required}
								fullWidth
								oneRow
								onChange={event => handleCustomField(event, task, customField)}
							/>
						}

						{customField.field_type === "radio" &&
							<CustomRadioButtonField
								style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
								id={customField.id}
								error={fieldErrors && fieldErrors.includes(customField.id)}
								name={customField.name}
								readOnly={readOnly}
								displayName={customField.name}
								description={customField.description}
								options={customField.radio_options}
								required={customField.required}
								onChange={event => handleCustomField(event, task, customField)}
								oneRow
							/>
						}

						{customField.field_type === "datetime" &&
							<CustomDateTimeField
								style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
								id={customField.id}
								error={fieldErrors && fieldErrors.includes(customField.id)}
								name={customField.name}
								displayName={customField.name}
								description={customField.description}
								readOnly={readOnly}
								required={customField.required}
								onChange={datetime => { handleCustomField(datetime, task, customField) }}
								value={(submissions && submissions[task.id] && submissions[task.id]["custom_field_input"] && submissions[task.id]["custom_field_input"][customField.id]) || null}
								oneRow
							/>
						}

						{customField.field_type === "select" &&
							<CustomSelectField
								style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
								textFieldClasses={classes}
								id={customField.id}
								error={fieldErrors && fieldErrors.includes(customField.id)}
								name={customField.name}
								displayName={customField.name}
								description={customField.description}
								options={customField.select_options}
								required={customField.required}
								readOnly={readOnly}
								fullWidth
								oneRow
								onChange={event => handleCustomField(event, task, customField)}
							/>
						}

						{customField.field_type === "checkbox" &&
							<CustomCheckboxField
								style={{ borderBottom: '1px solid #E5E5E5', padding: '16px 0px' }}
								textFieldClasses={classes}
								customField={customField}
								id={customField.id}
								error={fieldErrors && fieldErrors.includes(customField.id)}
								name={customField.name}
								required={customField.required}
								displayName={customField.name}
								description={customField.description}
								options={customField.checkbox_options}
								onChange={checkedBox => handleCustomField(checkedBox, task, customField)}
								value={
									(submissions && submissions[task.id] && submissions[task.id]["custom_field_input"] && submissions[task.id]["custom_field_input"]) ?
										submissions[task.id]["custom_field_input"][customField.id] : null
								}
								readOnly={readOnly}
								fullWidth
								oneRow
							/>
						}
					</>)
				})}

				<FormControl component="fieldset">
					<RadioGroup
						aria-label="s/u"
						name={task.id.toString()}
						disabled={overdue}
						onChange={handleRadioButton}
					>
						<FormControlLabel
							value="Satisfactory"
							control={<Radio disabled={overdue} size="small" />}
							label="Satisfactory"
							style={{ color: taskError ? 'red' : 'black' }}
						/>
						<FormControlLabel
							value="Unsatisfactory"
							control={<Radio disabled={overdue} size="small" />}
							label="Unsatisfactory"
							style={{ color: taskError ? 'red' : 'black' }}
						/>
						<FormControlLabel
							value="N/A"
							control={<Radio disabled={overdue} size="small" />}
							label="N/A"
							style={{ color: taskError ? 'red' : 'black' }}
						/>
					</RadioGroup>
				</FormControl>
			</TableCell>
			<TableCell align="left">
				{!readOnly && (
					<TextField
						name={task.id.toString()}
						disabled={overdue}
						onChange={handleObservationsChange}
						fullWidth
						multiline
						variant="outlined"
						required
						rows={5}
					/>
				)}
			</TableCell>
		</tr>
	)
}

export default memo(TaskRow, (prevProps, nextProps) => {
	if (prevProps.fieldErrors.length !== nextProps.fieldErrors) {
		return false;
	}

	if (prevProps.taskErrors.length !== nextProps.fieldErrors.length) {
		return false;
	}

	return true;
})