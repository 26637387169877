import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import ContentHeader from "Components/Layouts/ContentHeader";

import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Chip from "@material-ui/core/Chip";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import SanitizeHtml from "Components/SanitizeHtml";

export default function MobileMyTasks({ user, title, tasks, searchKeys }) {
  const [filteredTasks, setFilteredTasks] = useState([]);
  const history = useHistory();
  const match = useRouteMatch();

  const getStatusCard = (task) => {
    if (task.status === "COMPLETE") {
      return <CheckCircleIcon fontSize="medium" style={{ color: '#18BFF6' }} />
    }

    return <Chip variant="outlined" size="small" label={`Due ${task.days_due}`} />
  }

  useEffect(() => {
    setFilteredTasks(tasks);
  }, [tasks]);


  const getCards = (tasksNeedingCards) => {
    if (tasksNeedingCards.length === 0) {
      return (
        <Grid item container xs={12} alignItems="center" justify="center" style={{ paddingTop: '32px' }}>
          <Typography variant="body1">You have no monitoring tasks.</Typography>
        </Grid>
      )
    }

    let cards = tasksNeedingCards.map(task => {
      return (
        <Grid item xs={12} style={{ padding: '8px' }}>
          <Card elevation={0}>
            <CardActionArea style={{ padding: '16px' }} onClick={() => history.push(`${match.path}/monitoring/${task.id}/`)}>
              <Grid container>
                <Grid item container xs={12} justify="flex-start" alignItems="center" style={{ padding: '8px' }}>
                  <Grid item container xs={6}>
                    <Typography style={{ fontWeight: 500 }}>
                      {task.event_name}
                    </Typography>
                  </Grid>
                  <Grid item container xs={6} justify="flex-end" alignItems="center">
                    {getStatusCard(task)}
                  </Grid>
                </Grid>
                <Grid item container xs={12} justify="flex-start" alignItems="center" style={{ padding: '8px' }}>
                  <Typography style={{ fontSize: '12px', fontWeight: 500 }}>
                    {task.status === "COMPLETE" && `Submitted by ${task.submitted_by} on ${task.date_submitted}`}
                    {task.status !== "COMPLETE" && `Assigned to ${task.responsible === user.user.first_name ? "Me" : task.responsible}`}
                  </Typography>
                </Grid>
                <Grid item container xs={12} style={{ padding: '8px' }}>
                  <SanitizeHtml value={task.event_details} />
                </Grid>
              </Grid>
            </CardActionArea>
          </Card>
        </Grid>
      )

    });

    return cards;
  }

  return (
    <>
      {filteredTasks && <>
        <ContentHeader
          user={user}
          title={title}
          search
          searchData={tasks}
          setSearchResults={setFilteredTasks}
          searchKeys={searchKeys}
          noBorder
        />
        <Grid item container xs={12}>
          {getCards(filteredTasks)}
        </Grid>
      </>
      }
    </>
  )
}