import React from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import GreyTextField from "Components/GreyTextField";


export default function CustomNumberField(props) {
	return (
		<Grid container item xs={12} style={props.style}>
			<Grid container item xs={12} md={props.oneRow ? 12 : 6}>
				<Grid item xs={12} style={{ paddingTop: '12px' }}>
					<Typography style={{ fontSize: '14px', fontWeight: 500 }}>
						{props.displayName ? props.displayName : "<title>"} {props.required === true ? <span style={{ color: 'red', marginLeft: '4px' }}>*</span> : null}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography style={{ fontSize: '12px', fontWeight: 400 }}>
						{props.description ? props.description : "<instructions>"}
					</Typography>
				</Grid>
			</Grid>
			<Grid container item xs={12} md={props.oneRow ? 12 : 6}>
				<Grid item xs={12} style={{ paddingTop: '8px', paddingBottom: '8px' }}>
					{!props.readOnly &&
						<GreyTextField
							id={props.id}
							name={props.name}
							fullWidth
							variant="filled"
							margin="dense"
							type="number"
							value={props.value}
							required={props.required}
							onChange={props.onChange}
						/>
					}

					{props.error &&
						<Typography style={{ fontSize: '12px', color: '#f44336' }}>{props.error}</Typography>
					}

					{props.readOnly &&
						<Typography style={{ fontSize: '14px' }}>
							{props.value ? props.value : "This field wasn't entered."}
						</Typography>
					}
				</Grid>
			</Grid>
		</Grid>
	)
}