
class OrganizationInviteAPI {

  constructor(api, version) {
    this.api = api;
    this.version = version;
  }

  getInviteByTokens(token, hash_token){
    return this.api.get(`${this.version}/organization_invite/get_by_tokens/?token=` + token + '&hash=' + hash_token)
  }

  updateInvite(data){
    return this.api.post(`${this.version}/organization_invite/update_invite/`, data)
  }

  cancelInvite(pk){
    return this.api.post(`${this.version}/organization_invite/`+pk+`/cancel_invite/`)
  }

  resendInvite(pk){
    return this.api.post(`${this.version}/organization_invite/`+pk+`/resend_invite/`)
  
  }

  deleteInvite(pk){
    return this.api.delete(`${this.version}/organization_invite/`+pk)
  }
}

export default OrganizationInviteAPI;
