import { Divider, LinearProgress, Slide } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import "chartjs-plugin-zoom";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import API from "Api/Api";
import EventGraphs from "Dashboard/Event/EventGraphs";
import ContentHeader from "Components/Layouts/ContentHeader";
import { OrgContext } from "hooks/useOrganization"
import AvatarComponent from "Components/AvatarComponent";
import CircularProgress from "@material-ui/core/CircularProgress";

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles((theme) => ({
    nextPageButton: {
        float: "right",
        color: "#18BFF6",
    },
    buttonText: {
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#18BFF6",
        marginBottom: "0px",
        float: "right",
        paddingLeft: "8px",
    },
    paperContainer: { width: "100%", border: "1px solid lightgrey", minHeight: "500px" },
    columnHeader: {
        marginTop: "8px",
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "18px",
        color: "#687895",
        opacity: 1,
    },
    userNameText: {
        paddingLeft: "16px",
        paddingTop: "8px",
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "18px",
        color: "#000000",
        opacity: 0.8,
    },
    tasksText: {
        [theme.breakpoints.down("md")]: {
            float: "right",
            marginTop: "18px",
            marginRight: "12px",
            fontFamily: "Roboto",
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "12px",
            color: "#687895",
            display: "inline-flex",
            opacity: 1,
        },
        [theme.breakpoints.up("md")]: {
            float: "right",
            marginTop: "18px",
            marginRight: "4px",
            fontFamily: "Roboto",
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "12px",
            color: "#687895",
            display: "inline-flex",
            opacity: 1,
        },
    },
    noTasksText: {
        float: "right",
        marginTop: "18px",
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "12px",
        color: "#687895",
        display: "inline-flex",
        opacity: 1,
    },
    addTeamMemberButton: {
        float: "left",
        marginRight: "28px",
        color: "#18BFF6",
        marginLeft: "8px",
    },
    addTeamMemberButtonText: {
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "21px",
        color: "#18BFF6",
        marginBottom: "0px",
        float: "right",
        paddingLeft: "8px",
    },
    taskNameText: {
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "18px",
        color: "#000000",
        opacity: 0.8,
        overflowWrap: "anywhere",
    },
    deadlineText: {
        marginTop: "16px",
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "12px",
        color: "#687895",
        opacity: 1,
        overflowWrap: "anywhere",
    },
    eventProgressText: {
        position: "relative",
        top: 46,
        left: 32,
        bottom: 20,
        fontStyle: "Roboto",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "24px",
        color: "#687895",
    },
    progressContainer: {
        [theme.breakpoints.down("md")]: {
            minHeight: "400px",
        },
        [theme.breakpoints.up("md")]: {
            borderRight: "1px solid lightgrey",
            minHeight: "400px",
        },
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function MonitoringDashboard(props) {
    const org = useContext(OrgContext);
    let api = new API();
    const classes = useStyles();

    const [user, setUser] = useState(null);
    const [activePlan, setActivePlan] = useState(null);
    const [teamMembers, setTeamMembers] = useState(null);
    const [filteredTeamMembers, setFilteredTeamMembers] = useState(null);
    const [filteredTeamMembersWithNoTasks, setFilteredTeamMembersWithNoTasks] = useState(null);
    const [allTasks, setAllTasks] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.getAuthAPI()
            .getAuthedProfile()
            .then((e) => {
                setUser(e.data);
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);

    useEffect(() => {
        api.getPlanBuilderAPI()
            .getActiveProgram()
            .then((response) => {
                setActivePlan(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        api.getOrganizationAPI()
            .getTeamMembers()
            .then((response) => {
                setTeamMembers(response.data);
                setSearchData(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
        api.getMonitoringAPI()
            .getTeamTasksThisMonth()
            .then((response) => {
                setAllTasks(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        if (allTasks && teamMembers) {
            let teamMembersWithTasks = [];
            let teamMembersWithNoTasks = [];
            let totalTasks = 0;
            let hasMonitoredTask = 0;
            teamMembers.forEach((member) => {
                allTasks.forEach((task) => {
                
                    if (member.email === task.responsible_email) {
                        if (task.event_details_full) {
                            totalTasks++;
                        }
                        if (task.event_details_primary_monitor === member.pk) {
                            hasMonitoredTask++;
                        }
                    }
                });
                if (totalTasks > 0) {
                    teamMembersWithTasks.push(member);
                    totalTasks = 0;
                } else if (hasMonitoredTask > 0) {
                    teamMembersWithNoTasks.push(member);
                    hasMonitoredTask = 0;
                }
            });
            setLoading(false);
            setFilteredTeamMembers(teamMembersWithTasks);
            setFilteredTeamMembersWithNoTasks(teamMembersWithNoTasks);
        }
    }, [allTasks, teamMembers]);

    function getTasksPercentage(member) {
        let totalTasks = 0;
        let completedTasks = 0;
        if (allTasks) {
            allTasks.forEach((task) => {
                if (member.email === task.responsible_email) {
                    totalTasks++;
                }
                if (task.status === "COMPLETE" && member.email === task.responsible_email) {
                    completedTasks++;
                }
            });
        }
        if (totalTasks === 0) {
            return { tasks: `${completedTasks}/${totalTasks}`, value: 100 };
        } else {
            return { tasks: `${completedTasks}/${totalTasks}`, value: (completedTasks / totalTasks) * 100 };
        }
    }

    function getDateForTask(task) {
        if (task.due_date) {
            return new Date(task.due_date).toLocaleString();
        }
    }

    function getDaysRemaining(task) {
        if (task.days_remaining) {
            if (isNaN(task.days_remaining)) {
                return 0;
            } else {
                return task.days_remaining;
            }
        }
    }

    const history = useHistory();

    function addNewTeamMember() {
        history.push(`/team`);
    }

    const [page, setPage] = useState(1);
    const [searchResults, setSearchResults] = useState(null);
    const [searchData, setSearchData] = useState(null);
    const searchKeys = ["first_name", "last_name", "username", "email"];

    function prevPage() {
        setPage(1);
    }

    useEffect(() => {
        if (searchResults) {
            setTeamMembers(searchResults);
        }
    }, [searchResults]);

    return (
        <Grid container>
            {page == 1 && (
                <Grid container>
                    <Grid item container xs={12}>
                        {" "}
                        <ContentHeader
                            title="Monitoring Dashboard"
                            description="An overview of your teams monitoring performance from this month."
                            search={true}
                            search
                            setSearchResults={setSearchResults}
                            searchData={searchData}
                            searchKeys={searchKeys}
                        />
                    </Grid>{" "}
                    <Grid container spacing={2} style={{ margin: "24px" }}>
                        <Paper elevation={0} className={classes.paperContainer}>
                            <Grid container>
                                <EventGraphs prevPage={prevPage}></EventGraphs>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    style={{ borderBottom: "1px solid lightgrey", height: "90px" }}
                                >
                                    <Typography className={classes.eventProgressText}>Event Progress</Typography>
                                </Grid>
                                <Grid
                                    alignContent="flex-start"
                                    container
                                    item
                                    md={6}
                                    xs={12}
                                    className={classes.progressContainer}
                                >
                                    <Grid item xs={12} style={{ padding: "16px" }}>
                                        <Typography className={classes.columnHeader}>Progress</Typography>
                                    </Grid>
                                    {loading && (
                                        <Grid container item justify="center" style={{ width: "100%" }}>
                                            <CircularProgress />
                                        </Grid>
                                    )}{" "}
                                    {filteredTeamMembers &&
                                        !loading &&
                                        filteredTeamMembers.map((member) => {
                                            return (
                                                <Grid key={member.pk}  item container xs={12} style={{ padding: "16px" }}>
                                                    <Grid item xs={10} style={{ display: "inline-flex" }}>
                                                        {" "}
                                                        {user && (
                                                            <AvatarComponent user={member} rounded></AvatarComponent>
                                                        )}
                                                        <Typography className={classes.userNameText}>
                                                            {member.first_name
                                                                ? member.first_name
                                                                : member.username
                                                                ? member.username
                                                                : member.email}{" "}
                                                            {member.last_name ? member.last_name : ""}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2} style={{ marginTop: "-12px" }}>
                                                        <Typography container className={classes.tasksText}>
                                                            Tasks {getTasksPercentage(member).tasks}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ marginTop: "8px" }}>
                                                        <LinearProgressWithLabel
                                                            variant="determinate"
                                                            value={getTasksPercentage(member).value}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} style={{ marginTop: "24px" }}>
                                                        <Divider />
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                    {filteredTeamMembersWithNoTasks &&
                                        !loading &&
                                        filteredTeamMembersWithNoTasks.map((member) => {
                                            return (
                                                <Grid item container xs={12} style={{ padding: "16px" }}>
                                                    <Grid item container xs={10} style={{ display: "inline-flex" }}>
                                                        {" "}
                                                        {user && (
                                                            <AvatarComponent user={member} rounded></AvatarComponent>
                                                        )}
                                                        <Typography className={classes.userNameText}>
                                                            {member.first_name
                                                                ? member.first_name
                                                                : member.username
                                                                ? member.username
                                                                : member.email}{" "}
                                                            {member.last_name ? member.last_name : ""}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2} style={{ marginTop: "-12px" }}>
                                                        <Typography container  className={classes.noTasksText}>
                                                            No tasks due today
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ marginTop: "8px" }}>
                                                        <LinearProgressWithLabel variant="determinate" value={0} />
                                                    </Grid>
                                                    <Grid item xs={12} style={{ marginTop: "24px" }}>
                                                        <Divider />
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                    <Grid item xs={12} style={{ marginBottom: "24px" }}>
                                        <IconButton
                                            disableRipple
                                            style={{
                                                backgroundColor: "transparent",
                                            }}
                                            onClick={addNewTeamMember}
                                            className={classes.addTeamMemberButton}
                                        >
                                            <AddCircleIcon></AddCircleIcon>
                                            <Typography className={classes.addTeamMemberButtonText}>
                                                ADD A TEAM MEMBER
                                            </Typography>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    md={6}
                                    alignContent="flex-start"
                                    style={{ minHeight: "400px" }}
                                >
                                    {" "}
                                    <Grid item xs={12} style={{ padding: "16px" }}>
                                        <Typography className={classes.columnHeader}>Upcoming Events</Typography>
                                    </Grid>
                                    {allTasks &&
                                        allTasks.map((task) => {
                                            if (task.status === "UPCOMING") {
                                                return (
                                                    <Grid key={task.id}  item container xs={12} style={{ padding: "16px" }}>
                                                        <Grid item xs={9}>
                                                            <Typography className={classes.taskNameText}>
                                                                {task.event_name ? task.event_name : "Undefined"}
                                                            </Typography>
                                                            <Typography className={classes.deadlineText}>
                                                                Deadline: {getDateForTask(task)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3} style={{ marginTop: "-4px" }}>
                                                            <Chip
                                                                style={{
                                                                    float: "right",
                                                                    color: "#18BFF6",
                                                                    background: "rgba(255, 62, 62, 0.1)",
                                                                }}
                                                                label={`${getDaysRemaining(task)} Day(s) left`}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} style={{ marginTop: "8px" }}>
                                                            <LinearProgressWithLabel
                                                                variant="determinate"
                                                                value={100}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} style={{ marginTop: "24px" }}>
                                                            <Divider />
                                                        </Grid>
                                                    </Grid>
                                                );
                                            }
                                        })}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

export default MonitoringDashboard;
