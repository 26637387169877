import React, { forwardRef, useEffect, useState } from "react";
import API from "../../Api/Api";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import HazardDatasetTable from "../Hazards/HazardDatasetTable";
import Grid from "@material-ui/core/Grid";

export default function FormulationSelectTable(props) {
  function getAPI() {
    return new API().getFormulationsAPI();
  }

  const [formulationDataset, setFormulationDataset] = useState([]);
  const [formulationSelected, setFormulationSelected] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  function getDataset() {
    getAPI()
      .listFormulations()
      .then((response) => {
        setFormulationDataset(response.data);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getDataset();
  }, []);

  const formulationColumns = [{ title: "Name", field: "name" }];

  const [selectedRow, setSelectedRow] = useState(null);

  const [formulationHazards, setFormulationHazards] = useState([]);
  useEffect(() => {
    if (selectedRow) {
      let formulationSelected = formulationDataset.find((formulation) => {
        return formulation.id === selectedRow;
      });

      setFormulationSelected(formulationSelected);
    }
  }, [selectedRow]);

  const [hazardsSelected, setHazardsSelected] = useState([]);

  function cancel() {
    if (props.cancel) {
      props.cancel();
    }
  }

  return (
    <div style={{ maxWidth: "100%", overflow: "hidden", marginLeft: "-12px" }}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <MaterialTable
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
            title="Formulation Database"
            columns={formulationColumns}
            data={formulationDataset}
            isLoading={isLoading}
            onRowClick={(evt, selectedRow) => {
              setSelectedRow(selectedRow.id);
            }}
            options={{
              rowStyle: (rowData) => ({
                backgroundColor:
                  selectedRow === rowData.id
                    ? "rgba(24, 191, 247, 0.1)"
                    : "#FFF",
              }),
            }}
          />
        </Grid>
        {props.selectformulation && (
          <Grid item xs={12}>
            <div style={{ float: "right" }}>
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: "8px" }}
                onClick={cancel}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  props.selectformulation(formulationSelected);
                }}
                variant="contained"
                color="primary"
              >
                Select Formulation
              </Button>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
