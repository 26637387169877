import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch, Route, Switch } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import EventRecordForm from "Monitoring/Events/EventRecordForm";
import DeviationForm from "Monitoring/Deviations/DeviationForm";
import VerificationForm from "Monitoring/Verification/VerificationForm";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell, TableRow } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import Chip from "@material-ui/core/Chip";
import useDesktop from "../../hooks/useDesktop";

import CustomTable from "Components/CustomTable";
import Message from "Components/Message";
import API from "../../Api/Api";
import ContentHeader from "../../Components/Layouts/ContentHeader";
import SearchModal from "./SearchModal";
import { saveAs } from 'file-saver';

const useStyles = makeStyles((theme) => ({
    recordIDCell: { paddingLeft: "16px", paddingRight: "8px" },
    circularProgress: {
        margin: "64px",
        marginLeft: "33%",
        marginTop: "36px",
    },
    taskNameContainer: {
        maxWidth: "15vw",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    item1: {
        order: 1,
        [theme.breakpoints.down("xs")]: {
            order: 2,
        },
    },
    item2: {
        order: 2,
        padding: "16px",
        [theme.breakpoints.down("xs")]: {
            order: 1,
            padding: "14px",
        },
    },
    container: { paddingLeft: "28px", paddingTop: "32px", display: "inline-flex", alignItems: "baseline" },
    searchTypography: {
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: "20px",
        lineHeight: "20px",
        letterSpacing: "0.15px",
        color: "black",
    },
    recordsFoundTypography: {
        paddingLeft: "12px",
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "14px",
        letterSpacing: "0.1px",
        color: "#687895",
    },
    cardContainer: {
        marginLeft: "28px",
        marginRight: "32px",
        marginTop: "16px",
    },
    card: { width: "100%", height: "64px" },
    createSearch: {
        paddingTop: "6px",
        float: "center",
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        letterSpacing: "0.1px",
        color: "#687895",
    },
    eventCard: {
        width: "100%",
        minHeight: "64px",
        maxHeight: "200px",
    },
    showingCardText: {
        paddingTop: "7px",
        float: "left",
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "21px",
        letterSpacing: "0.1px",
        color: "#687895",
    },
    editFilters: { marginRight: "8px", float: "right", marginTop: "-1px" },
    tableHeader: { marginTop: "24px", marginLeft: "28px", width: "calc(100% - 80px)" },
    tableText: {
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: "12px",
        lineHeight: "21px",
        letterSpacing: "0.1px",
        color: "rgba(0,0,0, 0.4)",
    },
    matchedCards: {
        marginLeft: "28px",
        marginRight: "32px",
        width: "calc(100% - 60px)",
        minHeight: "64px",
        maxHeight: "90px",
        paddingRight: "90px",
        marginTop: "12px",
    },
    matchedCardsActionArea: { width: "calc(100% + 90px)", height: "calc(100% + 10px)" },
    recordText: {
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: "12px",
        paddingLeft: "8px",
        lineHeight: "21px",
        letterSpacing: "0.1px",
    },
    matchedText: {
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: "12px",
        lineHeight: "21px",
        letterSpacing: "0.1px",
    },
    eventsMatchedContainer: {
        height: "440px",
        overFlow: "scroll",
        overflowY: "auto",
        paddingTop: "6px",
        alignContent: "flex-start",
        paddingBottom: "12px",
    },
    recordTypeContainer: { alignItems: "center", flexFlow: "row" },
    errorOutline: {
        marginTop: "-2px",
        marginRight: "4px",
        color: "#687895",
    },
}));
const monitoringHeadCells = [
    {
        id: "Record ID",
        field: "form_num",
        numeric: false,
        disablePadding: false,
        label: "Record ID",
        align: "left",
        style: {
            paddingLeft: "16px",
            paddingBottom: "8px",
            backgroundColor: "#FFFFFF",
            paddingTop: "12px",
        },
        width: "30%",
    },
    {
        id: "Event Name",
        numeric: true,
        disablePadding: false,
        label: "Event Name",
        align: "left",
        width: "20%",
        field: "event_name",
        style: { paddingBottom: "8px", backgroundColor: "#FFFFFF", paddingTop: "12px" },
    },
    {
        id: "Record Type",
        numeric: true,
        disablePadding: false,
        label: "Record Type",
        align: "left",
        width: "25%",
        field: "formatted_status",
        style: {
            paddingBottom: "8px",
            backgroundColor: "#FFFFFF",
            paddingTop: "12px",
        },
    },
    {
        id: "Assigned Monitor",
        label: "Assigned Monitor",
        field: "responsible",
        //submitted_by
        align: "left",
        numeric: false,
        disablePadding: false,
        style: { paddingBottom: "8px", backgroundColor: "#FFFFFF", paddingTop: "12px" },
        width: "25%",
    },
    {
        id: "Date Completed",
        label: "Date Completed",
        field: "date_submitted",
        align: "left",
        numeric: false,
        disablePadding: false,
        style: {
            paddingLeft: "4px",
            paddingBottom: "8px",
            backgroundColor: "#FFFFFF",
            paddingTop: "12px",
        },
        width: "15%",
    },
];

export default function DeviationRecords(props) {
    const [loading, setLoading] = useState(true);
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const classes = useStyles();
    const [eventNames, setEventNames] = useState([]);
    const [matchedEvents, setMatchedEvents] = useState([]);
    const [matchedEventsStatic, setMatchedEventsStatic] = useState([]);
    const [totalMatchedEvents, setTotalMatchedEvents] = useState(0);
    const [allUsers, setAllUsers] = useState([]);
    const [records, setRecords] = useState([]);
    const [search, setSearch] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const match = useRouteMatch();
    const onDesktop = useDesktop();

    const api = new API();

    function createSearch() {
        setSearchModalOpen(true);
    }
    function handleClose() {
        setSearchModalOpen(false);
    }
    const [activePlan, setActivePlan] = useState(props.plan ? props.plan : null);

    useEffect(() => {
        api.getPlanBuilderAPI()
            .getActiveProgram()
            .then((response) => {
                setActivePlan(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
        // .getDeviationNames()
        api.getDeviationAPI()
            .getMyDeviations()
            .then((e) => {
                let newNames = e.data;
                newNames.unshift({ name: "All Records", id: 1 });
                setEventNames(newNames);
            })
            .catch((e) => {
                console.log(e);
            });
        api.getOrganizationAPI()
            .getTeamMembers()
            .then((response) => {
                setAllUsers(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);

    function cancelSearch() {
        setSearchModalOpen(false);
    }

    const options = { year: "numeric", month: "long", day: "numeric" };
    function performSearch(search, pageNumber, pageSize) {
        setSearchLoading(true);
        setSearch(search);
        setSearchModalOpen(false);
        setPageNumber(pageNumber);

        let payload = {
            name: search.name,
            start_date: search.start,
            end_date: search.end,
            user: search.monitor,
            completed: search.type,
            plan: activePlan.id,
        };
        if (activePlan) {
            payload = {
                name: search.name,
                start_date: search.start,
                end_date: search.end,
                user: search.monitor,
                completed: search.type,
                plan: activePlan.id,
            };
        }
        api.getDeviationAPI()
            .searchDeviations(payload, pageNumber, pageSize)
            .then((response) => {
                setRecords(response.data.results);
                setMatchedEvents(response.data.results);
                setMatchedEventsStatic(response.data.results);
                setTotalMatchedEvents(response.data.count);
                setSearchLoading(false);
                if (response.data.results.length == 0) {
                    setReturnStatus("No Results Found", "error");
                    setPageNumber(1);
                }
            })
            .catch((error) => {
                setSearchLoading(false);
                setReturnStatus("Could Not Complete Search", "error");
                console.log(error);
            });
    }

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState({
        message: "",
        status: "info",
    }); // error, warning, info, info

    function setReturnStatus(message, status) {
        setMessage({ message: message, status: status });
        setOpen(true);
    }

    const handleReturnClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const history = useHistory();

    function goToRecord(record) {
        history.push(`${match.path}/my_tasks/deviations/deviation/${record.id}/`);
    }

    const handleChange = (event, index, task) => {
        let tempArray = [...checked];
        if (event.target.checked) {
            let tempDataToSend = [...dataToSend];
            tempDataToSend.push(task);
            setDataToSend(tempDataToSend);
        } else {
            let tempDataToSend = [...dataToSend];
            if (tempDataToSend.length) {
                let taskIndex = tempDataToSend.findIndex((updatedForm) => {
                    return updatedForm.id === task.id;
                });
                if (taskIndex > -1) {
                    tempDataToSend.splice(taskIndex, 1);
                }
                setDataToSend(tempDataToSend);
            }
        }
        tempArray[index] = !tempArray[index];
        setChecked(tempArray);
        customTableRows();
    };

    const handleChangePage = (newPage) => {
        setChecked([]);
        setPageNumber(newPage);
        performSearch(search, newPage, currentPageSize);
        setClearText(true);
    };

    function changeTab() { }
    function handleMessageClose() { }
    function exportSelected() {
        exportAll()
        console.log(dataToSend);
    }
    function exportAll() {
        setLoading(true);
        let params = {
            name: search.name,
            start_date: search.start,
            end_date: search.end,
            user: search.monitor,
            completed: search.type,

        };
        if (activePlan)
            params.plan = activePlan.id;
        if (dataToSend.length) {
            let ids = dataToSend.map(obj => obj.id);
            params.selected = ids
        }
        api.getDeviationAPI()
            .export(params)
            .then((response) => {
                setLoading(false);
                let blob = new Blob([response.data], { type: "application/zip" });
                saveAs(blob, "deviation_records.zip");
                // if (response.data.results.length == 0) {
                //     setReturnStatus("No Results Found", "error");
                //     setPageNumber(1);
                // }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setReturnStatus("Could Not Complete Search", "error");
            });
    }
    function sort(tasks, order = null, field = null) {
        if (Number.isInteger(parseInt(field))) {
            field = headCells[field].field;
        }
        setField(field);
        if (order === "asc") {
            setOrder("asc");
        } else {
            setOrder("desc");
        }

        let tasksInOrder = [];
        if (tasks.length > 0 && field) {
            if (order === "asc") {
                tasksInOrder = tasks.sort(function (a, b) {
                    if (a[field] && b[field]) {
                        if (Number.isInteger(a[field]) || Number.isInteger(b[field])) {
                            return a[field] - b[field];
                        } else {
                            return a[field].toLowerCase().localeCompare(b[field].toLowerCase());
                        }
                    }
                });
                setMatchedEvents(tasksInOrder);
                setMatchedEventsStatic(tasksInOrder);
                setLoading(false);
            } else {
                tasksInOrder = tasks
                    .sort(function (a, b) {
                        if (a[field] && b[field]) {
                            if (Number.isInteger(a[field]) || Number.isInteger(b[field])) {
                                return a[field] - b[field];
                            } else {
                                return a[field].toLowerCase().localeCompare(b[field].toLowerCase());
                            }
                        }
                    })
                    .reverse();
                setMatchedEvents(tasksInOrder);
                setMatchedEventsStatic(tasksInOrder);
                setLoading(false);
            }
        }
        setLoading(false);
    }

    function clearSelected() {
        setDataToSend([]);
        setChecked([false]);
    }
    function setClosed() {
        performSearch(search, pageNumber, currentPageSize);
    }

    const selectAllRows = (checkedValue) => {
        if (checkedValue) {
            let tempDataToSend = matchedEvents;
            setDataToSend(tempDataToSend);
        } else {
            let tempDataToSend = [];
            setDataToSend(tempDataToSend);
        }
        let tempArray = [...checked];
        for (let count = 0; count <= currentPageSize; count++) {
            tempArray[count] = checkedValue;
        }
        setChecked(tempArray);
        customTableRows();
    };

    const handleClickNoSelect = (event, task) => {
        goToRecord(task);
    };
    const handleChangeRowsPerPage = (event) => {
        setPageNumber(1);
        setClearText(true);
        setChecked([]);
        setPageNumber(1);
        performSearch(search, 1, event.target.value);
        setCurrentPageSize(event.target.value);
    };

    const [currentPageSize, setCurrentPageSize] = useState(5);
    const [clearText, setClearText] = useState(false);
    const [field, setField] = useState(null);
    const [order, setOrder] = useState(null);
    const [checked, setChecked] = useState([]);
    const [dataToSend, setDataToSend] = useState([]);
    const [headCells, setHeadCells] = useState(monitoringHeadCells);
    function setSearchResults(tasks) {
        if (tasks.length) {
            setMatchedEvents(tasks);
        } else {
            setMatchedEvents([]);
        }
    }

    function customTableRows() {
        if (matchedEvents.length === totalMatchedEvents) {
            return (
                <>
                    {searchLoading && (
                        <Grid container style={{ position: "fixed" }}>
                            <CircularProgress className={classes.circularProgress} />
                        </Grid>
                    )}
                    {matchedEvents &&
                        !searchLoading &&
                        matchedEvents.map((task, index) => (
                            <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={index}
                                className={loading ? classes.loadingTableRow : classes.tableRow}
                            >
                                {props.selectMode && (
                                    <TableCell
                                        width="5%"
                                        component="th"
                                        scope="row"
                                        key={index}
                                        padding="none"
                                        align="left"
                                    >
                                        <Checkbox
                                            key={index}
                                            checked={checked[index] ? true : !checked[index] ? false : true} //this covers undefined cases, it looks unnecessary though
                                            onChange={(event) => handleChange(event, index, task)}
                                            inputProps={{ "aria-label": "controlled" }}
                                        />
                                    </TableCell>
                                )}
                                <TableCell
                                    align="left"
                                    component="th"
                                    scope="row"
                                    onClick={(event) => handleClickNoSelect(event, task)}
                                    padding="none"
                                    className={classes.recordIDCell}
                                >
                                    <div> Record #{task.id} </div>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    component="th"
                                    scope="row"
                                    onClick={(event) => handleClickNoSelect(event, task)}
                                    padding="none"
                                    style={{ paddingRight: "8px" }}
                                >
                                    <Grid item container className={classes.recordTypeContainer} xs={12}>
                                        <div className={classes.taskNameContainer}>{task.name}</div>
                                    </Grid>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    style={{ paddingLeft: "0px" }}
                                    onClick={(event) => handleClickNoSelect(event, task)}
                                >
                                    <Chip
                                        style={{
                                            color: task.status === "Complete" ? "#20C372" : "#FF3E3E",

                                            background:
                                                task.status === "Complete"
                                                    ? "rgba(24, 191, 247, 0.1)"
                                                    : "rgba(255,154,62, 0.1)",
                                        }}
                                        label={task.status ? task.status : task.status}
                                    ></Chip>
                                </TableCell>

                                <TableCell
                                    align="left"
                                    component="th"
                                    scope="row"
                                    onClick={(event) => handleClickNoSelect(event, task)}
                                    padding="none"
                                    style={{ paddingRight: "8px" }}
                                >
                                    {task.submitted_by ? task.submitted_by : "Unknown"}
                                </TableCell>
                                <TableCell
                                    align="left"
                                    component="th"
                                    scope="row"
                                    onClick={(event) => handleClickNoSelect(event, task)}
                                    padding="none"
                                    style={{ paddingRight: "8px" }}
                                >
                                    {task.time_submitted ? task.time_submitted : "---"}
                                </TableCell>
                            </TableRow>
                        ))}
                </>
            );
        }
        if (matchedEvents.length < totalMatchedEvents) {
            let emptyRows = 0;
            if (totalMatchedEvents === 5) {
                emptyRows = totalMatchedEvents - matchedEvents.length;
            } else {
                emptyRows = 5 - matchedEvents.length;
            }
            return (
                <>
                    {searchLoading && (
                        <Grid container style={{ position: "fixed" }}>
                            <CircularProgress className={classes.circularProgress} />
                        </Grid>
                    )}
                    {matchedEvents &&
                        !searchLoading &&
                        matchedEvents.map((task, index) => (
                            <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={index}
                                className={loading ? classes.loadingTableRow : classes.tableRow}
                            >
                                {props.selectMode && (
                                    <TableCell
                                        width="5%"
                                        component="th"
                                        scope="row"
                                        key={index}
                                        padding="none"
                                        align="left"
                                    >
                                        <Checkbox
                                            key={index}
                                            checked={checked[index] ? true : !checked[index] ? false : true} //this covers undefined cases, it looks unnecessary though
                                            onChange={(event) => handleChange(event, index, task)}
                                            inputProps={{ "aria-label": "controlled" }}
                                        />
                                    </TableCell>
                                )}
                                <TableCell
                                    align="left"
                                    component="th"
                                    scope="row"
                                    onClick={(event) => handleClickNoSelect(event, task)}
                                    padding="none"
                                    className={classes.recordIDCell}
                                >
                                    <div> Record #{task.form_num}</div>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    component="th"
                                    scope="row"
                                    onClick={(event) => handleClickNoSelect(event, task)}
                                    padding="none"
                                    style={{ paddingRight: "8px" }}
                                >
                                    <Grid item container className={classes.recordTypeContainer} xs={12}>
                                        <div className={classes.taskNameContainer}>{task.name}</div>
                                    </Grid>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    style={{ paddingLeft: "0px" }}
                                    onClick={(event) => handleClickNoSelect(event, task)}
                                >
                                    <Chip
                                        style={{
                                            color: task.status === "Complete" ? "#20C372" : "#FF3E3E",
                                            background:
                                                task.status === "Complete"
                                                    ? "rgba(24, 191, 247, 0.1)"
                                                    : "rgba(255,154,62, 0.1)",
                                        }}
                                        label={task.status ? task.status : task.status}
                                    ></Chip>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    component="th"
                                    scope="row"
                                    onClick={(event) => handleClickNoSelect(event, task)}
                                    padding="none"
                                    style={{ paddingRight: "8px" }}
                                >
                                    {task.submitted_by ? task.submitted_by : "Unknown"}
                                </TableCell>
                                <TableCell
                                    align="left"
                                    component="th"
                                    scope="row"
                                    onClick={(event) => handleClickNoSelect(event, task)}
                                    padding="none"
                                    style={{ paddingRight: "8px" }}
                                >
                                    {task.time_submitted ? task.time_submitted : "---"}
                                </TableCell>
                            </TableRow>
                        ))}
                    {emptyRows > 0 && (
                        <TableRow
                            style={{
                                height: 70 * emptyRows,
                            }}
                        >
                            <TableCell colSpan={7} />
                        </TableRow>
                    )}
                </>
            );
        }
    }

    return (
        <>
            {loading && (
                <Grid container item xs={12} alignItems="center" justify="center" style={{ height: "100%" }}>
                    <CircularProgress />
                </Grid>
            )}

            {searchModalOpen && (
                <SearchModal
                    currentPageSize={currentPageSize}
                    cancel={cancelSearch}
                    save={performSearch}
                    recordNames={eventNames}
                    handleClose={handleClose}
                    open={searchModalOpen}
                    allUsers={allUsers}
                    search={search}
                    records={records}
                    recordType={props.recordType}
                    resultsFound={totalMatchedEvents}
                ></SearchModal>
            )}

            {!loading && (
                <Grid container style={{ marginBottom: onDesktop ? "0px" : "30px" }}>
                    <Grid item xs={12}>
                        <ContentHeader
                            title={"Deviation Records"}
                            search
                            searchData={matchedEventsStatic}
                            searchKeys={["name", "status", "submitted_by", "time_submitted"]}
                            clearText={clearText}
                            setSearchResults={setSearchResults}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.cardContainer}>
                        <CardActionArea onClick={createSearch}>
                            {matchedEvents.length < 1 && (
                                <Card elevation={0} className={classes.card}>
                                    <CardContent style={{ textAlignLast: "center" }}>
                                        <Grid item xs={12}>
                                            <Typography className={classes.createSearch}>
                                                Create a new search +
                                            </Typography>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            )}
                            {matchedEvents.length >= 1 && (
                                <Card elevation={0} className={classes.eventCard}>
                                    <CardContent style={{ textAlignLast: "center" }}>
                                        <Grid item xs={12}>
                                            {" "}
                                            {search.end !== "null" && search.start !== "null" && (
                                                <Typography className={classes.showingCardText}>
                                                    Showing{" "}
                                                    {search.type.charAt(0).toUpperCase() +
                                                        search.type.slice(1).toLowerCase()}{" "}
                                                    {"Deviation Record(s)"} from {search.name} between{" "}
                                                    {new Date(search.start).toLocaleDateString("en-US", options)} and{" "}
                                                    {new Date(search.end).toLocaleDateString("en-US", options)}
                                                </Typography>
                                            )}
                                            {search.end === "null" && search.start !== "null" && (
                                                <Typography className={classes.showingCardText}>
                                                    Showing{" "}
                                                    {search.type.charAt(0).toUpperCase() +
                                                        search.type.slice(1).toLowerCase()}{" "}
                                                    {"Deviation Record(s)"} from {search.name} beginning at{" "}
                                                    {new Date(search.start).toLocaleDateString("en-US", options)}
                                                </Typography>
                                            )}
                                            {search.end !== "null" && search.start === "null" && (
                                                <Typography className={classes.showingCardText}>
                                                    Showing{" "}
                                                    {search.type.charAt(0).toUpperCase() +
                                                        search.type.slice(1).toLowerCase()}{" "}
                                                    {"Deviation Record(s)"} from {search.name} ending at{" "}
                                                    {new Date(search.end).toLocaleDateString("en-US", options)}
                                                </Typography>
                                            )}
                                            {search.end === "null" && search.start === "null" && (
                                                <Typography className={classes.showingCardText}>
                                                    Showing{" "}
                                                    {search.type.charAt(0).toUpperCase() +
                                                        search.type.slice(1).toLowerCase()}{" "}
                                                    {"Deviation Record(s)"} from {search.name}
                                                </Typography>
                                            )}
                                            <Typography
                                                style={{
                                                    fontWeight: 500,
                                                    fontSize: "16px",
                                                    color: "#18bff6",
                                                    float: "right",
                                                    padding: "8px",
                                                }}
                                            >
                                                Edit Filters
                                            </Typography>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            )}
                        </CardActionArea>
                    </Grid>
                    {searchLoading && !matchedEvents.length && (
                        <Grid
                            container
                            item
                            xs={12}
                            alignItems="center"
                            justify="center"
                            style={{ height: "100%", paddingTop: "32px" }}
                        >
                            <CircularProgress />
                        </Grid>
                    )}
                    <Grid container>
                        <CustomTable
                            selectMode={true}
                            page={pageNumber}
                            allTasks={matchedEvents}
                            allTasksStatic={matchedEvents}
                            loading={searchLoading}
                            selectedTab="all"
                            totalTasks={totalMatchedEvents}
                            currentPageSize={currentPageSize}
                            title="Search Results"
                            hideDivider
                            hideHeader
                            noTableHead
                            fullWidth
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleChangePage={handleChangePage}
                            searchKeys={["form_num", "name", "status", "submitted_by", "time_submitted"]}
                            tabs={[
                                { name: "Due", url: "due", count: 1 },
                                { name: "Upcoming", url: "upcoming", count: 1 },
                                { name: "Complete", url: "complete", count: 1 },
                                {
                                    name: "All",
                                    url: "all",
                                    count: 1,
                                },
                            ]}
                            changeTab={changeTab}
                            headCells={headCells}
                            clearText={clearText}
                            customTableRows={customTableRows}
                            setSearchResults={setSearchResults}
                            sort={sort}
                            order={order}
                            selectAllRows={selectAllRows}
                            exportSelected={exportSelected}
                            checked={checked}
                            sendButtonName={"Export Selected"}
                            exportAllButtonName={'Export All'}
                            exportAll={exportAll}
                            clearSelected={clearSelected}
                            dataToSend={dataToSend}
                            field={field}
                            message={message}
                            open={open}
                            handleMessageClose={handleMessageClose}
                            records={true}
                        ></CustomTable>
                    </Grid>
                    <Switch>
                        <Route path={`${match.path}/all_tasks/monitoring/:pk/`}>
                            <EventRecordForm user={props.user} setClosed={setClosed} />
                        </Route>
                    </Switch>
                    <Switch>
                        <Route path={`${match.path}/my_tasks/verification/verification/:pk/`}>
                            <VerificationForm user={props.user} setClosed={setClosed} />
                        </Route>
                    </Switch>
                    <Switch>
                        <Route path={`${match.path}/my_tasks/deviations/deviation/:pk/`}>
                            <DeviationForm user={props.user} setClosed={setClosed} />
                        </Route>
                    </Switch>
                    <Message
                        open={open}
                        message={message.message}
                        severity={message.status}
                        vertical="bottom"
                        horizontal="right"
                        handleClose={handleReturnClose}
                    />
                </Grid>
            )}
        </>
    );
}
