import React, { useEffect, useState, useContext } from "react";
import API from '../../Api/Api'

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';
import { blue, red, green } from '@material-ui/core/colors';
import useOrganization from "hooks/useOrganization";
import usePermissions from "hooks/usePermissions"; 
import AvatarComponent from "../../Components/AvatarComponent";
import ContentHeader from "../../Components/Layouts/ContentHeader";

const useStyles = makeStyles({
  robotoFont: {fontStyle: 'normal', fontWeight: 400, fontSize: '14px', lineHeight: '21px'}, 
});

export default function OrgInvite(props) { 
  const classes = useStyles();
  const api = new API();

  const org = useOrganization();
  const orgPermission = usePermissions();

  const [open, setOpen] = useState(false);
  const [inviteData, setInviteData] = useState({ email: "", organization: null})
  const [inviteList, setInviteList] = useState([]);
  const [cancelOpen, setCancleOpen] = useState(false);
  const [resendOpen, setResendOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [currentInvite, setCurrentInvite] = useState(null);
  const [inviteErrors, setInviteErrors] = useState(null);
  const [loading, setLoading] = useState(true);

  const [userPermissionSelected, setUserPermissionSelected] = useState(null);
  const [editPermissionsDialogOpen, setEditPermissionsDialogOpen] = useState(false);
  
  const [anchorEl, setAnchorEl] = useState(null);

  const [readOnly, setReadOnly] = useState(true);

  const [alertOpen, setAlertOpen] = useState(false);

  const handleMenuClick = (event, invite) => {
    setAnchorEl(event.currentTarget);
    setCurrentInvite(invite);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  function handleAlertClose() {
    setAlertOpen(false);
  }

  useEffect(() => {
    if (orgPermission) {
      if (orgPermission.team_admin) {
        setReadOnly(false);
      } 
      else {
        setReadOnly(true)
      }
    }
  }, [orgPermission]);

  useEffect(() => {
    if (org) {
      getInvites();
      getTeamPermissions();
      setInviteData({email: "", organization: org.pk});
    }
  }, [org]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setInviteErrors(null);
  };

  const [teamPermissions, setTeamPermissions] = useState(null);
  function getTeamPermissions() {
    api.getOrganizationAPI().getTeamPermissions().then(response => {
      setTeamPermissions(response.data);
    }).catch(error => {
      console.log(error);
    })
  }

  function getInvites() {
    api.getOrganizationAPI().getOrganizationInvites(org.pk).then(e => {
      setInviteList(e.data);
      setLoading(false);
    }).catch(e => {
        console.log(e);
    });
  }

  function handlePermissionChange(event) {
    if (event.target) {
      setUserPermissionSelected({...userPermissionSelected, team_admin: event.target.checked})
    }
  }

  function submitPermissionChange(event) {
    api.getOrganizationAPI().updateUserPermissions(userPermissionSelected).then(response => {
      let updatedPermissions = [...teamPermissions];
      let updatedPermission = updatedPermissions.find(perm => perm.pk === userPermissionSelected.pk);

      if (updatedPermission) {
        updatedPermission.team_admin = response.data.team_admin;
      }

      setTeamPermissions(updatedPermissions);
      setUserPermissionSelected(null)

      setAlertOpen(true);

      window.location.reload(); // A hack for now to immediately apply any new permissions for the current user
    }).catch(error => {
      setUserPermissionSelected(null)
      console.log(error);
    })
  }

  function handleInviteChange(event) {
    const name = event.target.name;

    if (name === "team_admin") {
      setInviteData({
        ...inviteData,
        team_admin: event.target.checked,
      })
    }
    else {
      setInviteData({
        ...inviteData,
        [name]: event.target.value,
      });
    }
  }

  function inviteUser() {
    setOpen(false);

    api.getOrganizationAPI().inviteUser(inviteData).then(() => {
      setSuccessOpen(true);
      getInvites();
    }).catch(error => {
        setInviteErrors(error.response.data.email)
    });
  }

  function handleCancelOpen(invite){
    setCancleOpen(true);
    setCurrentInvite(invite);
  }

  function handleCancelClose(){
    setCancleOpen(false);
    setCurrentInvite(null);
  }

  function handleResendOpen(invite){
    setResendOpen(true);
    setCurrentInvite(invite);
  }

  function handleResendClose(){
    setResendOpen(false);
    setCurrentInvite(null);
  }

  function handleDeleteOpen(invite){
    setDeleteOpen(true);
    setCurrentInvite(invite);
  }

  function handleDeleteClose(){
    setDeleteOpen(false);
    setCurrentInvite(null);
  }

  function handleResend(){
    api.getOrganizationInviteAPI().resendInvite(currentInvite.pk).then(() => {
      getInvites();
      setSuccessOpen(true);
    }).catch(e => {
      console.log(e);
    });
    handleResendClose();
  }
  
  function handleCancel() {

    api.getOrganizationInviteAPI().cancelInvite(currentInvite.pk).then(() => {
      getInvites();
    }).catch(e => {
      console.log(e);
    });
    handleCancelClose();
  }

  function handleDelete() {
    api.getOrganizationInviteAPI().deleteInvite(currentInvite.pk).then(() => {
      getInvites();
    }).catch(e => {
      console.log(e);
    });
    handleDeleteClose();
  }
  
  function getTeamMemberCard(invite) {
    return (
      <Paper key={invite.uuid} elevation={0} style={{width: '100%', padding: '12px', marginBottom: '6px'}}>
        <Grid container>
          <Grid container item xs={8} alignItems="center" spacing={3}>
            <Grid item>
              {invite.status !== "accepted" && <Avatar variant="rounded"/> }
              {invite.status === "accepted" && invite.read_user && invite.read_user.user && 
                <AvatarComponent rounded user={invite.read_user}/>
              }
            </Grid>
            <Grid item style={{minWidth: '200px'}}>
              <Typography style={{fontStyle: 'Roboto', fontWeight: 500, fontSize: '18px', lineHeight: '21px'}}>
                {/*invite.status !== "accepted" && 
                  "Invite" 
                */}

                {invite.status === "accepted" && invite.read_user && invite.read_user.user &&
                  invite.read_user.user.first_name + " " + invite.read_user.user.last_name
                }
              </Typography>
            </Grid>
            <Grid item>
              <Chip 
                color={invite.status === "cancelled" ? "secondary" : "primary"} 
                label={invite.status.charAt(0).toUpperCase() + invite.status.slice(1)}
                size="small" 
              />
            </Grid>
            <Grid item>
              <Typography className={classes.robotoFont}>
                {invite.email}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={4} justify="flex-end" alignItems="center">
            {invite.status === "accepted" && 
              <Button disabled={readOnly} color="primary" style={{marginRight: '16px'}} onClick={() => {
                let teamPermission = teamPermissions.find(perm => perm.user === invite.user)
                if (teamPermission) {
                  setUserPermissionSelected(teamPermission);
                  setEditPermissionsDialogOpen(true);
                }
              }}>
                Edit User 
              </Button>
            }

            {(invite.status === "pending" || invite.status === "cancelled") && 
              <Button disabled={readOnly} color="primary" onClick={() => handleResendOpen(invite)} style={{marginRight: '16px'}}>
                Resend Invite
              </Button>
            }

            <IconButton disabled={readOnly} onClick={(event) => handleMenuClick(event, invite)}><MoreHorizIcon/></IconButton> 
          </Grid>
        </Grid>
      </Paper>
    )
  }

  return (
    <>
      <ContentHeader title='Team' />

      {loading && 
        <Grid container item xs={12} alignItems="center" justify="center" style={{width: '100%', height: '100%'}}>
          <CircularProgress/>
        </Grid>
      }

      <Grid container item xs={12} variant="outlined" justify="flex-end" alignItems="center" style={{padding: '16px 27px 16px 0px'}}>
        <Button disabled={readOnly || loading} variant='outlined' color='primary' onClick={handleClickOpen} style={{width: '165px'}}>
          Add User
        </Button>
      </Grid>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {currentInvite && currentInvite.status === "accepted" && 
          <MenuItem onClick={() => { handleMenuClose(); handleDeleteOpen(currentInvite); }}>
            Remove User from Team
          </MenuItem>
        }

        {currentInvite && currentInvite.status === "pending" && 
          <MenuItem onClick={() => { handleMenuClose(); handleCancelOpen(currentInvite); }}>
            Cancel Invite
          </MenuItem>
        }

        {currentInvite && currentInvite.status === "cancelled" && 
          <MenuItem onClick={() => { handleMenuClose(); handleDeleteOpen(currentInvite); }}>
            Remove Invite 
          </MenuItem>
        }
      </Menu>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Invite User</DialogTitle>
        <DialogContent>
          <DialogContentText>
              Enter the email of the person you wish to invite to your team.
          </DialogContentText>
          <TextField
              autoFocus
              error={inviteErrors ? true : false}
              margin="dense"
              id="email"
              name="email"
              label="Email"
              fullWidth
              required={true}
              onChange={handleInviteChange}
              inputProps={{
                  maxLength: 50,
              }}
              helperText={inviteErrors}
          />
          <DialogContentText style={{marginTop: '32px'}}>
            Select the permissions for this user (optional)
          </DialogContentText>
          <FormControlLabel
            control={<Checkbox color="primary" />}
            value="Team Admin"
            name="team_admin"
            label="Team Admin"
            labelPlacement="end"
            onChange={handleInviteChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
              Cancel
          </Button>
          <Button color="primary" onClick={inviteUser}>
              Invite
          </Button>
        </DialogActions>
      </Dialog>
                
      <Dialog
        open={cancelOpen}
        onClose={handleCancelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{zIndex: 99999}}
      >
        <DialogTitle id="alert-dialog-title-">Cancel Invite?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will cancel the current invite. Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="secondary" name="test">
            Yes 
          </Button>
          <Button onClick={handleCancelClose} color="primary" autoFocus>
            No 
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={resendOpen}
        onClose={handleResendClose}
        aria-labelledby="alert-dialog-title1"
        aria-describedby="alert-dialog-description1"
        style={{zIndex: 99999}}
      >
        <DialogTitle id="alert-dialog-title-1">Resend Invite?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description1">
            This will resend an invite email to the user. Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResend} color="primary" name="test">
            Resend
          </Button>
          <Button onClick={handleResendClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title1"
        aria-describedby="alert-dialog-description1"
        style={{zIndex: 99999}}
      >
        <DialogTitle id="alert-dialog-title-1">Delete Invite</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description1">
            This will remove team access to anyone who has accepted the invite. Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} color="secondary" name="test">
            Yes
          </Button>
          <Button onClick={handleDeleteClose} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
        aria-labelledby="alert-dialog-title1"
        aria-describedby="alert-dialog-description1"
        style={{zIndex: 99999}}
      >
        <DialogTitle id="alert-dialog-title-1">Success</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description1">
            A email has been sent to the provided email to finish the invite process.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuccessOpen(false)} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={editPermissionsDialogOpen}
        onClose={() => setEditPermissionsDialogOpen(false)}
        style={{zIndex: 99999}}
      >
        <DialogTitle id="permissions-dialog">Edit User</DialogTitle>
        <DialogContent>
          <DialogContentText id="permissions-dialog-contents">
            Add or remove user permissions 
          </DialogContentText>
          <FormControlLabel
            control={<Checkbox color="primary" checked={userPermissionSelected ? userPermissionSelected.team_admin : false} />}
            value="Team Admin"
            name="team_admin"
            label="Team Admin"
            labelPlacement="end"
            onChange={handlePermissionChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setEditPermissionsDialogOpen(false); submitPermissionChange();  }} color="primary">
            Submit
          </Button>
          <Button onClick={() => {setEditPermissionsDialogOpen(false); }} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container>
        <Grid item xs={12} style={{padding: '32px', paddingBottom: "56px",paddingTop: "16px"}}>
          {inviteList && teamPermissions && inviteList.map(invite => {
            return getTeamMemberCard(invite);
          })}
        </Grid>
      </Grid>

      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>

        <Alert severity="info" onClose={() => { setAlertOpen(false) }}>
          Permissions have been updated
        </Alert>
      </Snackbar>
    </>
  );
}
