import React from 'react';
import API from "../../Api/Api";
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ImageUploader from './ImageUploader';
import { saveAs } from 'file-saver';
import * as fileSaver from 'file-saver';
const FileSaver = require('file-saver');

class Images extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      api: new API().getImagesAPI(),
    }
  }

  /**
   * Downloads or previews the image, based on how the browser handles
   * <a download> tags. (often a preview)
   */
  downloadImage(url, imageName) {
    FileSaver.saveAs(url, imageName);
  }

  render() {
    return (
      <div>
        <Switch>
          <Route exact>
            <ImageUploader
              updateParent={() => this.rerenderParent()}
              actions={[{ action: this.downloadImage, label: 'View' }]}
              showManager={true}
              allowDelete={true}
              api={this.state.api}
              cardStyle={{ width: '200px', height: '250px', }}
            />
          </Route>
        </Switch>
      </div>
    )
  }
}

export default Images;
