import React, { useState, useEffect } from "react";
import { useParams, useRouteMatch, Switch, Route } from "react-router-dom";
import HaccpPlanDescription from "./HaccpPlanDescription";
import Message from "../../Components/Message";
import API from "../../Api/Api";
import HaccpSideMenu from "./HaccpSideMenu";
import useDesktop from "../../hooks/useDesktop";

function HaccpPlan(props) {
  let params = useParams();
  let match = useRouteMatch();
  const [haccpPlan, setHaccpPlan] = useState(null);
  const [user, setUser] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(props.drawerOpen);

  useEffect(() => {
    setDrawerOpen(props.drawerOpen);
  }, [props.drawerOpen]);

  let api = new API();
  //let timer = api.getTimerAPI();
  //
  useEffect(() => {
    if (params.pk !== "haccp_plans") {
      api
        .getHaccpPlanAPI()
        .retrieveHaccpPlan(params.pk)
        .then((e) => {
          setHaccpPlan(e.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  useEffect(() => {
    api
      .getAuthAPI()
      .getAuthedProfile()
      .then((e) => {
        setUser(e.data.user);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  
  function updateSeconds(seconds) {
    //timer.startTime(seconds);
  }

  function stopSeconds(seconds) {
    //timer.endTime(seconds);
  }

  function successMessage(props) {
    successMessage = "Updated HACCP Plan!";
    setReturnStatus(successMessage, "info");
  }

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState({
    message: "",
    status: "info",
  });

  // error, warning, info, info
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  function setReturnStatus(message, status) {
    setMessage({ message: message, status: status });
    setOpen(true);
  }
  const [width, setWidth] = useState("60px");
  const onDesktop = useDesktop();

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Switch>
        <Route
          path={`${match.url}/:pk/product`}
          render={(props2) => (
            <div style={{ display: "flex", height: "100%" }}>
              <div
                style={{
                  flex: props.sideMenuOpen ? onDesktop ? "0 0 360px" : "0 0 100%" : "0 0 0",
                  borderRight: "1px solid #eaeaea",
                  height: "100%",
                  width: onDesktop ? "360px" : "100%",
                }}
              >
              {props.sideMenuOpen &&
                <HaccpSideMenu
                  pk={params.pk}
                  haccpPlan={haccpPlan}
                  user={user}
                  setHaccpPlan={setHaccpPlan}
                  successMessage={successMessage}
                  message={message}
                  drawerOpen={drawerOpen}
                  width={width}
                  setWidth={setWidth}
                  closeSideMenu={props.closeSideMenu}
                />
              }
              </div> 
              <div
                style={{
                  flex: props.sideMenuOpen ? "0 0 calc(100vw - 360px - 64px)" : "0 0 calc(100vw - 64px)",
                  height: "100%",
                  overflow: "scroll",
                }}
              >
                <HaccpPlanDescription
                  pk={params.pk}
                  haccpPlan={haccpPlan}
                  user={user}
                  updateSeconds={updateSeconds}
                  stopSeconds={stopSeconds}
                  successMessage={successMessage}
                  message={message}
                  drawerOpen={drawerOpen}
                  width={width}
                  setWidth={setWidth}
                  sideMenuOpen={props.sideMenuOpen}
                />
              </div>
            </div>
          )}
        />
      </Switch>
      <Message
        position="200px"
        open={open}
        message={message.message}
        severity={message.status}
        marginBottom="36px"
        vertical="bottom"
        horizontal="right"
        handleClose={handleClose}
      />
    </div>
  );
}

export default HaccpPlan;
