import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import GreyTextField from "../../../Components/GreyTextField";

import AuditAPI from "./api/AuditAPI";
import { OrgContext } from "hooks/useOrganization"

export default function NewAuditDialog(props) {
	
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");

	const history = useHistory();

	const org = useContext(OrgContext);

	function createAudit() {
		new AuditAPI().createAudit({
			organization: org.pk,
			title: name,
			description: description,
		}).then(response => {
			history.replace(`/records/audit/${response.data.id}/edit`);
		}).catch(error => {
			console.error(error);
		})
	}

	return (
		<Dialog open={true} maxWidth="sm" fullWidth >
			<DialogContent style={{backgroundColor: '#f8f8f8', height: '400px'}}>
				<Grid container spacing={3} >
					<Grid item xs={12}>
						<Typography style={{fontSize: '16px', fontWeight: 500}}>
							Start an Audit
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography style={{fontSize: '14px', fontWeight: 500}}>
					 		Audit Name	
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<GreyTextField 
							value={name} 
							onChange={event => {setName(event.target.value)}} 
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography style={{fontSize: '14px', fontWeight: 500}}>
							Audit Notes
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<GreyTextField 
							multiline 
							rows={3}
							fullWidth
							value={description} 
							onChange={event => {setDescription(event.target.value)}} 
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions style={{backgroundColor: '#f8f8f8'}}>
				<Button onClick={event => props.cancel()}>
					Cancel
				</Button>
				<Button color="primary" variant="contained" onClick={event => createAudit()}>
					Create
				</Button>
			</DialogActions>
		</Dialog>
	)
}

